import 'array-flat-polyfill'
import 'core-js/es/array/flat-map'
import 'core-js/es/array/from'
import 'core-js/es/object/assign'

const { detect } = require('detect-browser')

export const Browser = detect() && detect().name

if (Browser === 'ie' || Browser === 'edge') {
  containsPolyfill()
}

function containsPolyfill() {
  // fixes b-tooltip
  document.contains = Element.prototype.contains = function contains(node) {
    do {
      if (this === node) return true
    } while ((node = node && node.parentNode))
    return false
  }
}
