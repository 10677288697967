<script>
export default {}
</script>
<template>
  <b-container class="text-center">
    <base-inline-svg
      :id="'new-window'"
      :branded="true"
      :svg="require('@assets/images/new-window.svg?inline')" />
    <h5 class="mt-4">
      {{ $t('course.launch_popup.new_window') }}
    </h5>
    <p class="text-muted">
      {{ $t('course.launch_popup.instructions') }}
    </p>
  </b-container>
</template>
