import { graphqlAxios } from '@state/api/client/graphqlAxiosClient'
import { axios } from '@state/api/client/axiosClient'

/** GQL Queries **/
import ProfileQuery from '@gql/queries/profile.gql'

export function validate() {
  return axios.get('/me')
}

export function userSession() {
  return graphqlAxios.post('/graphql', {
    query: ProfileQuery,
  })
}
