





























































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import CampaignTable from '@components/dashboard/campaign-table.vue'
import { mapEnrollment } from '@state/modules/enrollments'
import PhishingTests from '@components/dashboard/phishing-tests.vue'
import Enrollment from '@src/models/enrollment'
import { ManagerReport } from '@gql/graphql'
import {
  managerUserEnrollments,
  managerUserStatistics,
} from '@gql/client/team-dashboard'
import EmptyTeamTable from '@components/dashboard/empty-team-table.vue'
import { teamDashboard } from '@src/lib/analytics/events/team-dashboard'

// @ts-ignore
import Loader from '@components/global/loader'
import SessionStore from '@state/modules/session'
import sortBy from 'lodash/sortBy'
import orderBy from 'lodash/orderBy'

@Component({
  components: {
    CampaignTable,
    PhishingTests,
    EmptyTeamTable,
    Loader,
  },
  page: {
    title: 'Team Dashboard',
    meta: [{ name: 'description', content: 'My Dashboard' }],
  },
})
export default class TeamTable extends Vue {
  @Prop({ required: true, default: [] }) tableFields!: Array<any>
  @Prop({ required: true, default: {} }) managerStatistics!: ManagerReport
  @Prop({ required: false }) managerId!: number
  @Prop({ required: false }) currentUserId!: number

  selectedFilter: any = {}
  currentTableFields = this.tableFields
  showAll = false
  loaded = false
  // Mutating a string is fully reactive
  detailsLoaded = ''

  get allEnrollmentFields() {
    return [
      'title',
      'templateName',
      'failureType',
      'failureDate',
      'optionalTitle',
      'status',
      'expiresAt',
      'completedAt',
    ].map(key => {
      return { key: key, label: this.$t('manager_dashboard.team_table.' + key) }
    })
  }

  setCurrentTableFields(all?: boolean) {
    if (all) {
      this.currentTableFields = this.tableFields
      return
    }
    if (this.selectedFilter.translationKey !== 'phishing_failures') {
      this.currentTableFields = this.tableFields
        .filter(f => f.key !== 'phishingFailures')
        .filter(f => f.key !== 'phishingPercentReported')
    } else if (this.selectedFilter.translationKey === 'phishing_failures') {
      this.currentTableFields = this.tableFields.filter(
        f => f.key !== 'trainingPercentComplete'
      )
    } else {
      this.currentTableFields = this.tableFields
    }
  }

  isEmpty(): boolean {
    return this.selectedFilter.count === 0
  }

  enrollmentFields() {
    return this.allEnrollmentFields.filter(column => {
      return this.selectedFilter.tableColumns.includes(column.key)
    })
  }

  active(filter): boolean {
    return this.selectedFilter.translationKey === filter.translationKey
  }

  async mounted() {
    this.selectedFilter = this.filters[0]
    this.setCurrentTableFields()
    await this.getMembers(this.selectedFilter)
  }

  @Watch('managerId')
  async setManagerValue(newVal, oldVal) {
    if (newVal === oldVal) return
    await this.getMembers(this.selectedFilter)
    this.showAll = true
    this.toggleShowAll()
    this.setCurrentTableFields()
  }

  loadKey(user) {
    return '#' + user.id + this.selectedFilter.translationKey + '#'
  }

  async getEnrollments(user) {
    // Get primitives from selectedFilter
    // If a user clicks quickly selectedFilter changes after the await
    const translationKey = this.selectedFilter.translationKey
    const enKey = this.selectedFilter.detailRows
    const loadKey = this.loadKey(user)
    this.detailsLoaded = this.detailsLoaded.replace(loadKey, '')

    const variables: { [key: string]: any } = {}
    variables[translationKey] = true
    variables.id = user.id
    variables.userId = this.managerId

    const result = await managerUserEnrollments(variables)

    user[enKey] = result.data.data.managerReport.directReports[0][enKey]
    if (enKey === 'completeEnrollments') {
      user[enKey] = orderBy(
        result.data.data.managerReport.directReports[0][enKey],
        item => item.completedAt,
        'desc'
      )
    }

    this.detailsLoaded = this.detailsLoaded + loadKey
  }

  async getMembers(filter) {
    this.loaded = false
    const variables = {}
    variables[filter.translationKey] = true
    const result = await managerUserStatistics({
      userId: this.managerId,
      ...variables,
    })
    this.managerStatistics[filter.membersKey] =
      result.data.data.managerReport[filter.membersKey]
    this.loaded = true
  }

  async selectFilter(filter) {
    this.$analytics.trackEvent(teamDashboard.selectFilter, {
      filterSelected: filter.membersKey,
    })
    if (this.managerUserInfoEnabled) {
      await this.getMembers(filter)
    }
    this.selectedFilter = filter
    this.setCurrentTableFields()
    this.showAll = false
  }

  toggleShowAll() {
    this.$analytics.trackEvent(teamDashboard.toggleShowAllButton)
    if (!this.showAll) {
      this.getMembers({ translationKey: 'all', membersKey: 'directReports' })
    }
    this.selectedFilter = this.showAll ? this.filters[0] : {}
    this.setCurrentTableFields(!this.showAll)
    this.showAll = !this.showAll
  }

  get filters(): { [key: string]: any }[] {
    const hasUsersWithPhishingFailuresCount =
      !!this.managerStatistics?.usersWithPhishingFailuresCount ||
      this.managerStatistics?.usersWithPhishingFailuresCount === 0
    const filters: { [key: string]: any }[] = [
      {
        translationKey: 'overdue',
        count: this.managerStatistics?.usersWithOverDueEnrollmentsCount,
        tableColumns: ['title', 'status', 'expiresAt'],
        membersKey: 'usersWithOverDueEnrollments',
        detailRows: 'pastDueEnrollments',
        showDetails: true,
        emptyStateMessage: '',
      },
      {
        translationKey: 'due_soon',
        count: this.managerStatistics?.usersWithDueSoonEnrollmentsCount,
        tableColumns: ['title', 'status', 'expiresAt'],
        membersKey: 'usersWithDueSoonEnrollments',
        detailRows: 'dueSoonEnrollments',
        showDetails: true,
      },
      {
        translationKey: 'complete',
        count: this.managerStatistics?.usersWithCompleteEnrollmentsCount,
        tableColumns: ['title', 'completedAt'],
        membersKey: 'usersWithCompleteEnrollments',
        detailRows: 'completeEnrollments',
        showDetails: true,
        emptyStateMessage: this.$t('manager_dashboard.no_complete'),
      },
    ]

    if (this.optionalTrainingEnabled) {
      filters.push({
        translationKey: 'optional',
        count:
          this.managerStatistics?.usersWithCompleteOptionalEnrollmentsCount,
        tableColumns: ['optionalTitle', 'status', 'completedAt'],
        membersKey: 'usersWithCompleteOptionalEnrollments',
        detailRows: 'completeOptionalEnrollments',
        showDetails: true,
      })
    }

    if (hasUsersWithPhishingFailuresCount) {
      filters.push({
        translationKey: 'phishing_failures',
        count: this.managerStatistics?.usersWithPhishingFailuresCount,
        tableColumns: ['templateName', 'failureType', 'failureDate'],
        membersKey: 'usersWithPhishingFailures',
        showDetails: true,
        detailRows: 'failures',
      })
    }
    return filters
  }

  get optionalTrainingEnabled() {
    return (
      SessionStore.optionalTrainingEnabled ||
      SessionStore.aidaOptionalTrainingEnabled
    )
  }

  get managerUserInfoEnabled(): boolean | null | undefined {
    return SessionStore.managerUserInfoEnabled
  }

  get tableData() {
    if (this.showAll) {
      // TODO add to query
      return this.managerStatistics?.directReports
    }
    return this.managerStatistics[this.selectedFilter.membersKey]
  }

  mapDetailRows(rows) {
    if (rows.length === 0) {
      return []
    }
    // eslint-disable-next-line
    if (rows[0].hasOwnProperty('emailTemplate')) {
      // TODO get latest
      return rows.map(e => {
        const failure = sortBy(e.failureDetails, item => item.date)[0]
        return {
          templateName: e.emailTemplate.name,
          failureType: this.$t(
            'manager_dashboard.team_table.failures.' + failure.type
          ),
          failureDate: failure.date,
        }
      })
    }
    return rows.map(mapEnrollment).map((e: Enrollment) => {
      return {
        title: e.localizedTitle,
        optionalTitle: e.localizedTitle,
        completedAt: e.completedAt,
        status: e.state,
        expiresAt: e.expiresAt,
      }
    })
  }

  async detailsRowClicked(row) {
    this.$analytics.trackEvent(teamDashboard.expandedTeamMemberRow, {
      hasSelectedAManager: this.managerId !== this.currentUserId,
    })
    await this.getEnrollments(row)
  }
}
