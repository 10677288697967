const prefix = 'LX:'
export const LX = {
  launchLearnerExperience: `${prefix} Launch Learner Experience`,
  viewBadges: `${prefix} View Badges`,
  viewLeaderboard: `${prefix} View Leaderboard`,
  viewLibrary: `${prefix} View Library`,
  downloadCertificate: `${prefix} Download Certificate`,
  startTour: `${prefix} Start Tour`,
  skipTour: `${prefix} Skip Tour`,
  completeTour: `${prefix} Complete Tour`,
  launchTraining: `${prefix} Launch Training`,
  startTraining: `${prefix} Start Training`,
  resumeTraining: `${prefix} Resume Training`,
  reviewContent: `${prefix} Review Content`,
  stopTraining: `${prefix} Stop Training`,
  changeInterfaceLanguage: `${prefix} Change Interface Language`,
  skipSurvey: `${prefix} Skip Survey`,
  completeSurvey: `${prefix} Complete Survey`,
  useHelpButton: `${prefix} Use Help Button`,
  openReadMessage: `${prefix} Open Read Message`,
  markMessageAsRead: `${prefix} Mark Message As Read`,
  closeMessagesTabWithoutReading: `${prefix} Close Tab Without Marking Unread Message As Read`,
  viewCompletedTrainingBanner: `${prefix} View Completed Training Banner`,
  showMobileSignUpLinks: `${prefix} Show Mobile Sign Up Links`,
  showMobileSignUpQRCodes: `${prefix} Show Mobile Sign Up QR Codes`,
  viewIndividualLeaderboard: `${prefix} View Individual Leaderboard`,
  viewLinkDevices: `${prefix} View Link Devices`,
  viewMessages: `${prefix} View Messages`,
  viewMoreLearnerAppInfo: `${prefix} View More Learner App Information`,
  dismissLearnerAppBanner: `${prefix} Dismiss Learner App Banner`,
  unlinkDevice: `${prefix} Unlink a Device`,
  showLearnerAppInstallInstructions: `${prefix} Show Learner App Install Instructions`,
  showLearnerAppDeviceLinkingInstructions: `${prefix} Show Device Linking Instructions`,
  showSignInBanner: `${prefix} Show Learner App Sign In Banner`,
  viewKnowledgeRefreshers: `${prefix} Viewing Knowledge Refreshers`,
}

export const lxSection = {
  NoSection: 'No Section',
}
