import { render, staticRenderFns } from "./empty-team-table.vue?vue&type=template&id=333fd9d2&scoped=true&lang=pug&"
import script from "./empty-team-table.vue?vue&type=script&lang=ts&"
export * from "./empty-team-table.vue?vue&type=script&lang=ts&"
import style0 from "./empty-team-table.vue?vue&type=style&index=0&id=333fd9d2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "333fd9d2",
  null
  
)

export default component.exports