import SessionStore from '@state/modules/session'
import shajs from 'sha.js'
import { Mixpanel } from 'mixpanel-browser'
import LaunchDarkly from '../feature-flags/launch-darkly'

export default class Analytics {
  private PROJECT_KEY: string | undefined = undefined
  private API_HOST: string | undefined = undefined
  mixpanel: Mixpanel | undefined

  constructor(projectKey?: string, apiHost?: string) {
    this.PROJECT_KEY = projectKey
    this.API_HOST = apiHost
  }

  async initialize() {
    if (this.enabled && !this.mixpanel) {
      try {
        this.mixpanel = (
          await import(/* webpackChunkName: "mx" */ 'mixpanel-browser')
        ).default
        this.mixpanel &&
          this.mixpanel.init(this.PROJECT_KEY!, { api_host: this.API_HOST }, '')
      } catch (e) {
        console.error(e)
        this.mixpanel = undefined
      }
    }
  }

  get enabled() {
    const optOut = LaunchDarkly.getInstance().get('telemetry-opt-out')
    const allowedAnalytics = SessionStore.allowAnalytics && !optOut
    return !!(this.PROJECT_KEY && this.API_HOST && allowedAnalytics)
  }

  trackEvent(event, extraParameters) {
    if (!this.enabled || !this.mixpanel) return
    this.mixpanel.track(event, {
      ...extraParameters,
      ...this.requiredProperties(),
    })
  }

  identify(identifyId) {
    if (!this.enabled || !this.mixpanel) return
    this.mixpanel.identify(identifyId)
  }

  people(email, properties = {}) {
    if (!this.enabled || !this.mixpanel) return
    this.mixpanel.people.set({
      $email: this.hash(email),
      ...properties,
    })
  }

  getUserId(): string {
    const userId = SessionStore.currentUser?.id
    const site = window.location.host
    return `${site}:${userId}`
  }

  getEmail(): string {
    return this.hash(SessionStore.currentUser?.email || '')
  }

  hash(input) {
    return shajs('sha256').update(input).digest('hex')
  }

  getTrainingModuleProperties() {
    if (!this.enabled) return undefined

    return {
      user_id: this.getUserId(),
      email: this.getEmail(),
      account: this.getAcccountInfo(),
      is_od: process.env.VUE_APP_IS_ONDEMAND,
    }
  }

  getAcccountInfo() {
    const site = window.location.host
    return {
      domain_name: SessionStore.currentUser?.domain,
      id: `${site}:${SessionStore.currentUser?.accountId}`,
      name: SessionStore.currentUser?.companyName,
    }
  }

  requiredProperties() {
    const site = window.location.host

    return {
      'Account Domain Name': SessionStore.currentUser?.domain,
      'Domain Name': SessionStore.currentUser?.domain,
      'User ID': SessionStore.currentUser?.id,
      'Account ID': `${site}:${SessionStore.currentUser?.accountId}`,
      'Account Name': SessionStore.currentUser?.companyName,
      'Admin ID': SessionStore.currentAdmin?.id,
      'Reseller ID': SessionStore.currentPartnerAdmin?.id,
      Instance: site,
      'RID Email Address': SessionStore.currentPartnerAdmin?.email,
      'AID Email Address': SessionStore.currentAdmin?.email,
      'Reseller Parent Account ID': SessionStore.currentPartnerAdmin?.partnerId,
      'Admin Selected Language':
        SessionStore.currentUser?.languageSettings?.trainingLocale,
      'Default Language':
        SessionStore.currentUser?.accountLanguageSettings?.trainingLocale,
      'User Selected Language':
        SessionStore.currentUser?.languageSettings?.trainingLocale,
      'Browser Language': window.navigator.language,
      'Subscription Level': SessionStore.currentUser?.subscriptionLevel,
      'Billing Type': SessionStore.currentUser?.billingType,
      'Badges Enabled': SessionStore.currentUser?.gamification?.badgesEnabled,
      'Leaderboards Enabled':
        !!SessionStore.currentUser?.gamification?.leaderboard,
    }
  }
}
