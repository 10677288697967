<script>
import map from 'lodash/map'
import BadgesStore from '@src/state/modules/badges'

export default {
  data() {
    return {
      active: false,
    }
  },
  computed: {
    earnedBadgesNotAcknowledged() {
      return BadgesStore.earnedBadgesNotAcknowledged
    },
    earnedBadgesNotAcknowledgedIds() {
      return map(BadgesStore.earnedBadgesNotAcknowledged, badge => badge.id)
    },
  },
  watch: {
    earnedBadgesNotAcknowledged() {
      // badges get updated in this component when the LMS closes as well
      // as when a certificate gets downloaded, so watch for any changes
      // in the earnedBadgesNotAcknowledged property (mapped to store)
      if (this.earnedBadgesNotAcknowledged.length > 0) {
        this.active = true
      }
    },
  },
  methods: {
    acknowledgeBadges() {
      this.active = false
      return BadgesStore.markAsAcknowledged(this.earnedBadgesNotAcknowledgedIds)
    },
    redirectToBadges() {
      this.acknowledgeBadges().then(() => {
        this.$router.push({ name: 'badges' })
      })
    },
  },
}
</script>

<template>
  <div class="wrap">
    <b-modal
      ref="badgeModal"
      v-model="active"
      class="badge-modal"
      hide-header
      hide-footer
      hide-header-close
      lazy
      body-class="px-5 py-4"
      size="md"
      @hide="acknowledgeBadges()">
      <div
        v-if="earnedBadgesNotAcknowledged.length > 0"
        class="new-badges d-flex flex-row justify-content-center">
        <div
          v-for="badge in earnedBadgesNotAcknowledged.slice(0, 3)"
          slot="default"
          :key="badge.id"
          class="new-badge card text-center col-4 py-4 px-2">
          <base-inline-svg
            :id="'badges'"
            :branded="true"
            :svg="require(`@assets/images/badges/${badge.icon}.svg?inline`)"
            class="animated bounce" />
        </div>
      </div>
      <h4 class="pt-3 text-center">
        {{ $t('badges.congratulations') }}
      </h4>
      <p class="pb-3 text-center text-muted">
        {{
          $t('badges.badges_earned', {
            badge_amount: earnedBadgesNotAcknowledged.length,
            badge_word: $options.filters.pluralize(
              earnedBadgesNotAcknowledged.length,
              [$t('common.badge'), $t('common.badge_plural')]
            ),
          })
        }}
      </p>
      <div class="text-center pt-2">
        <b-button variant="primary" @click="redirectToBadges()">
          {{ $t('badges.show_my_badges') }}
        </b-button>
        <base-branded-link
          :text="$t('common.later')"
          @click="acknowledgeBadges()" />
      </div>
    </b-modal>
  </div>
</template>
