













import { Component, Prop, Vue } from 'vue-property-decorator'
import EnrollmentTileInfoListCourse from './enrollment-tile-info-list-course.vue'
import EnrollmentTileInfoListPolicy from './enrollment-tile-info-list-policy.vue'
import Course from '@src/models/course'
import Policy from '@src/models/policy'
import dateTime from '@mixins/dateTime'
import secondsToTimeDistance from '@utils/seconds-to-time-distance'

@Component({
  components: { EnrollmentTileInfoListCourse, EnrollmentTileInfoListPolicy },
  mixins: [dateTime],
})
export default class EnrollmentTileInfoList extends Vue {
  @Prop() readonly enrollment!: Course | Policy

  get duration() {
    return this.enrollment?.duration
  }

  get completedAt() {
    return this.enrollment?.completedAt
  }

  get timeSpentInSeconds() {
    return this.enrollment?.timeSpentInSeconds
  }

  private timeSpent

  get time() {
    return this.timeSpent(
      this.enrollment?.timeSpentInSeconds,
      this.enrollment?.enrollmentType
    )
  }

  get enrollmentTimeSpent() {
    return this.timeSpentInSeconds
      ? secondsToTimeDistance(this.timeSpentInSeconds)
      : ''
  }

  private get enrollmentCompletedType() {
    switch (this.enrollment?.enrollmentType) {
      case 'Course':
        return this.$t('enrollment.details.completed_training')
      case 'Policy':
        return this.$t('enrollment.details.accepted_policy')
      case 'Assessment':
        return this.$t('enrollment.details.completed_assessment')
      default:
        return null
    }
  }

  private get enrollmentComponent() {
    switch (this.enrollment?.enrollmentType) {
      case 'Course':
      case 'Assessment':
        return EnrollmentTileInfoListCourse
      case 'Policy':
        return EnrollmentTileInfoListPolicy
      default:
        return EnrollmentTileInfoListCourse
    }
  }
}
