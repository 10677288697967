









































import { Vue, Component, Prop } from 'vue-property-decorator'
import Course from '@src/models/course'
import Policy from '@src/models/policy'
import RespondedRequest from '@src/models/responded-request'

// Components
import EnrollmentActions from './enrollment-actions.vue'
import EnrollmentTimelineCard from '@components/enrollments/enrollment-timeline/enrollment-timeline-card.vue'
import EnrollmentTile from '@components/enrollments/enrollment-tile/enrollment-tile.vue'

// modals
import PopupBlockedNotification from '@components/enrollments/popup-blocked-notification.vue'
import CourseLaunchedNotification from '@components/enrollments/course-launched-notification.vue'
import CourseLaunchedNoTimeLeft from '@components/enrollments/course-launched-no-time-left.vue'
import SurveyModal from '@/src/components/enrollments/modals/survey-modal.vue'
import EnrollmentCreated from '@components/enrollments/enrollment-created.vue'
import SessionErrorModal from '@components/enrollments/session-error-modal.vue'

// extra
import isEmpty from 'lodash/isEmpty'
import EnrollmentStore, { mapEnrollment } from '@state/modules/enrollments'
import { LX } from '@src/lib/analytics/events/LearnerExperience'
import { Enrollments as apiEnrollment } from '@state/api'
import launchScormRuntime from '@npm/kb4-scorm-runtime'
import { authComputed } from '@/src/state/helpers'
import SessionStore from '@state/modules/session'
import BadgesStore from '@src/state/modules/badges'
import OptionEnrollment from '@src/models/optional-enrollment'
import { lxSources } from '../../utils/enrollments'

@Component({
  components: {
    EnrollmentTimelineCard,
    EnrollmentTile,
    EnrollmentActions,
    PopupBlockedNotification,
    CourseLaunchedNotification,
    CourseLaunchedNoTimeLeft,
    SurveyModal,
    EnrollmentCreated,
  },
  computed: {
    ...authComputed,
  },
})
export default class Enrollment extends Vue {
  @Prop({ required: true }) enrollment!: Course | Policy | OptionEnrollment
  @Prop({ default: () => EnrollmentTile }) layout
  @Prop({ default: false }) firstEnrollment!: boolean

  readonly MAX_LANGUAGES_IN_DROP_DOWN: number = 5

  busyWithCourse: boolean = false
  private popupBlocked: boolean = false
  private respondedRequest: RespondedRequest | null = null
  private showSurveyModal: boolean = false
  private acceptUrlPolicyClicked: boolean = false
  private updatedEnrollment!: Course | Policy | OptionEnrollment | null
  private launchedEnrollment!: Course | Policy | OptionEnrollment | null
  private selectedLanguage: any = null
  private userName
  enrollmentJustCreated = false

  created() {
    this.selectedLanguage = this.enrollment?.languageOptions
      ? this.enrollment?.languageOptions[0]
      : null
    if (
      !this.enrollment?.hasTrainingCampaign &&
      this.enrollment?.hasNotStarted &&
      !!this.$route.query.created
    ) {
      this.enrollmentJustCreated = true
      this.busyWithCourse = true
    }
  }

  get modalContentComponent() {
    if (this.enrollmentJustCreated) return EnrollmentCreated
    if (this.noTimeLeft) return CourseLaunchedNoTimeLeft
    if (this.popupBlocked) return PopupBlockedNotification
    if (this.hasSessionError()) return SessionErrorModal

    return CourseLaunchedNotification
  }

  get noTimeLeft() {
    // @ts-ignore
    const abt = window.absolute_session_timeout
    const duration = this.enrollment?.duration
    if (abt && duration) {
      return (
        abt < Math.floor(new Date().getTime() / 1000) + (duration + 10) * 60
      )
    } else {
      return false // Still an old session without absolute_session_timeout
    }
  }

  get enrollmentTitle() {
    return this.selectedLanguage
      ? this.selectedLanguage.title
      : this.enrollment?.title
  }

  get selectedLanguageCode() {
    return !isEmpty(this.selectedLanguage) ? this.selectedLanguage.code : null
  }

  get enrollmentId() {
    return this.enrollment?.enrollmentId
  }

  get scopedEnrollmentId() {
    const site = window.location.host
    return `${site}:${this.enrollment?.enrollmentId}`
  }

  get optionalTrainingEnabled() {
    return !!SessionStore.optionalTrainingEnabled
  }

  startCourse() {
    this.popupBlocked = false
    if (this.enrollment?.hasNotEnrolled) {
      EnrollmentStore.createEnrollment({
        enrollmentUUID: this.enrollment?.uuid,
        languageCode: this.selectedLanguageCode,
        contentSource: this.enrollment?.contentSource,
      }).then(enrollment => {
        this.$router.replace({
          name: 'library_detail_view',
          // @ts-ignore
          params: { id: enrollment.enrollmentId },
          query: {
            uuid: enrollment.uuid,
            created: 'true',
            source: lxSources.LIBRARY,
          },
        })
        this.busyWithCourse = true
        this.enrollmentJustCreated = true
      })
    } else {
      this.enrollmentJustCreated = false
      this.busyWithCourse = true
      if (this.noTimeLeft) {
        return
      }
      this.trackLaunchAnalytics()
      EnrollmentStore.startEnrollment({
        enrollmentId: this.enrollment?.enrollmentId,
        languageCode: this.selectedLanguageCode,
      }).then(enrollment => this.launchEnrollment(enrollment))
    }
  }

  launchEnrollment(enrollment: Course | Policy | OptionEnrollment) {
    this.listenToApiExposedEvent()
    this.launchedEnrollment = enrollment
    this.launchRuntime()
  }

  launchRuntime() {
    this.popupBlocked = false

    launchScormRuntime({
      enrollmentId: this.launchedEnrollment?.enrollmentId,
      userName: this.userName,
      userId: this.launchedEnrollment?.userId,
      launchPath: this.launchedEnrollment?.launchPath,
      enrollmentType: this.launchedEnrollment?.enrollmentType,
      theme: this.launchedEnrollment?.theme,
      kb4Info: this.$analytics.getTrainingModuleProperties(),
    })
      .then(this.scormPlayerDone)
      .catch(e => {
        console.error(`ERROR while launching content: ${e.message}`)
        this.popupBlocked = true
      })
  }

  startSurvey() {
    this.showSurveyModal = true
  }

  finishSurvey() {
    this.showSurveyModal = false
    this.updateEnrollment()
  }

  changeLanguage(lang) {
    this.selectedLanguage = lang
  }

  setLanguageAndCloseModal(lang) {
    this.selectedLanguage = lang
    ;(this.$refs as any).languageSelectionModal.hide()
  }

  updateEnrollment() {
    if (this.updatedEnrollment && this.enrollmentId) {
      if (this.updatedEnrollment.isAssessment) {
        EnrollmentStore.refreshEnrollment(this.updatedEnrollment.id)
      } else {
        EnrollmentStore.updateEnrollment(this.updatedEnrollment)
      }
      BadgesStore.earnBadges(this.enrollmentId)
    }
  }

  shouldShowSurvey() {
    if (!this.enrollment?.allowSurveys) {
      return false
    }
    // Only Courses can have surveys
    if (this.enrollment?.enrollmentType !== 'Course') {
      return false
    }
    // enrollment was completed after this SCORM session
    if (!this.updatedEnrollment?.state.match(/completed|do_policy/i)) {
      return false
    }
    // and it was incomplete before
    if (this.enrollment?.state === this.updatedEnrollment?.state) {
      return false
    }
    // and the enrollment doesn't already have a survey
    return !(
      this.enrollment?.surveyCompletedAt ||
      this.updatedEnrollment?.surveyCompletedAt
    )
  }

  scormPlayerDone() {
    if (this.hasSessionError()) return

    // Only trigger actions for launched course
    this.busyWithCourse = false
    if (this.enrollment?.isUrlPolicy && !this.acceptUrlPolicyClicked) return

    apiEnrollment.fetchEnrollment(this.enrollmentId).then(response => {
      // we defer updating the enrollment in the store,
      // because as soon as it is updated, this component will be replaced
      // and we might want to show the survey before that happens
      this.updatedEnrollment = mapEnrollment(response.data.data.enrollment)
      this.trackCompletedEvent()

      if (this.shouldShowSurvey()) {
        this.startSurvey()
      } else {
        this.updateEnrollment()
      }
    })
  }

  acceptUrlPolicy() {
    if (this.enrollment?.isComplete || this.acceptUrlPolicyClicked) return

    this.acceptUrlPolicyClicked = true
    apiEnrollment
      .acceptUrlPolicy(this.enrollmentId)
      .then(this.scormPlayerDone)
      .catch(err => {
        console.error(`ERROR while accepting URL policy: ${err.message}`)
        this.acceptUrlPolicyClicked = false
      })
  }

  downloadCertificate() {
    this.$analytics.trackEvent(
      LX.downloadCertificate,
      this.enrollment?.mixPanelData
    )
    setTimeout(() => {
      BadgesStore.fetchBadges()
    }, 1000) // The badge allocation happens async, so we need to give it a bit of time
  }

  trackCompletedEvent() {
    // Do not track the completed event again if already in completed/do_policy state
    if (this.enrollment?.state.match(/completed|do_policy/i)) {
      return
    }

    const status = this.updatedEnrollment?.state.match(/completed|do_policy/i)
      ? 'Complete'
      : 'In Progress'
    this.$analytics.trackEvent(LX.stopTraining, {
      'Enrollment ID': this.scopedEnrollmentId,
      Status: status,
      'Optional Training Active': this.optionalTrainingEnabled,
      ...this.enrollment?.mixPanelData,
    })
  }

  trackLaunchAnalytics() {
    let status = 'Start'
    if (this.enrollment?.state === 'completed') {
      status = 'Review'
    } else if (this.enrollment?.state === 'in_progress') {
      status = 'Resume'
    }

    this.$analytics.trackEvent(LX.launchTraining, {
      'Enrollment ID': this.scopedEnrollmentId,
      Status: status,
      'Optional Training Active': this.optionalTrainingEnabled,
      ...this.enrollment?.mixPanelData,
    })
  }

  private hasSessionError() {
    return this.respondedRequest?.isSessionError()
  }

  private listenToApiExposedEvent() {
    window.addEventListener('lms-api-exposed', this.registerPostErrorCallback)
  }

  private registerPostErrorCallback() {
    ;(window as any).API_1484_11.onPostErrorResponse(
      this.handleLmsPostErrorResponse
    )
  }

  private handleLmsPostErrorResponse(request: XMLHttpRequest) {
    this.respondedRequest = new RespondedRequest(request)
  }

  private hideSessionErrorModal() {
    this.busyWithCourse = false
    this.respondedRequest = null
  }
}
