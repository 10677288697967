<script>
export default {
  name: 'StarRating',

  props: {
    value: {
      type: Number,
      default: 0,
    },
    prefix: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentValue: 0,
    }
  },
  watch: {
    currentValue: function (newVal, oldVal) {
      this.$emit('input', this.currentValue)
    },
  },
  mounted() {
    this.currentValue = this.value
  },
}
</script>

<template>
  <fieldset class="starability-basic" :aria-label="description">
    <input
      :id="`${prefix}_no-rate`"
      v-model="currentValue"
      type="radio"
      class="input-no-rate"
      :name="prefix"
      :value="0"
      aria-label="No rating."
      checked="checked">
    <input
      :id="`${prefix}_first-rate1`"
      v-model="currentValue"
      type="radio"
      :name="prefix"
      :value="1">
    <label :for="`${prefix}_first-rate1`" title="Terrible"> 1 </label>
    <input
      :id="`${prefix}_first-rate2`"
      v-model="currentValue"
      type="radio"
      :name="prefix"
      :value="2">
    <label :for="`${prefix}_first-rate2`" title="Not good"> 2 </label>
    <input
      :id="`${prefix}_first-rate3`"
      v-model="currentValue"
      type="radio"
      :name="prefix"
      :value="3">
    <label :for="`${prefix}_first-rate3`" title="Average"> 3 </label>
    <input
      :id="`${prefix}_first-rate4`"
      v-model="currentValue"
      type="radio"
      :name="prefix"
      :value="4">
    <label :for="`${prefix}_first-rate4`" title="Very good"> 4 </label>
    <input
      :id="`${prefix}_first-rate5`"
      v-model="currentValue"
      type="radio"
      :name="prefix"
      :value="5">
    <label :for="`${prefix}_first-rate5`" title="Amazing"> 5 </label>
    <span class="starability-focus-ring" />
  </fieldset>
</template>

<style lang="scss" scoped>
@import 'src/design/components/enrollments/modals/_star-rating.scss';
</style>
