<script>
export default {
  components: {},
  mounted() {
    document.getElementById('loader-wrap').style.display = 'none'
  },
}
</script>

<template>
  <div class="container d-flex h-100">
    <slot />
  </div>
</template>
