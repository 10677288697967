import { graphqlAxios } from '@state/api/client/graphqlAxiosClient'

/** GQL Mutations **/
import UpdateMessageRecipientMutation from '@gql/mutations/update_message_recipient.gql'

export function markMessageRead(messageAttributes) {
  return graphqlAxios.post('/graphql', {
    query: UpdateMessageRecipientMutation,
    variables: {
      id: messageAttributes.id,
      attributes: messageAttributes.attributes,
    },
  })
}
