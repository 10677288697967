import { Profile as apiProfile } from '@state/api'
import omit from 'lodash/omit'

export const state = {
  cached: [],
}

export const getters = {}

export const mutations = {
  CACHE_USER(state, newUser) {
    state.cached.push(newUser)
  },
}

export const actions = {
  updateUser({ commit, _state, _rootState }, user) {
    user = user && omit(user, ['id, otp_secret'])
    return apiProfile.updateUser(user).then(response => {
      commit('auth/SET_CURRENT_USER', response.data, { root: true })
    })
  },
}
