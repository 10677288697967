import { Session as apiSession, Profile as apiProfile } from '@/src/state/api'

export const state = {
  currentUser: null,
}

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue
    if (newValue) {
      window.authenticity_token = newValue.authenticity_token
      window.absolute_session_timeout = newValue.absolute_session_timeout
    }
  },
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser
  },
  currentUser(state) {
    return state.currentUser
  },
  badgesEnabled(state) {
    return state.currentUser && state.currentUser.badges_enabled
  },
}

export const actions = {
  // Updates password
  updatePassword({ _commit, _dispatch, _getters }, { password, newPassword }) {
    return apiProfile.updatePassword(password, newPassword).then(response => {
      return response.status
    })
  },

  disableMfa({ commit, _dispatch, _getters }, { password }) {
    return apiProfile.disableMfa(password).then(response => {
      commit('SET_CURRENT_USER', response.data)
      return response.status === 200
    })
  },

  enableMfa({ commit, _dispatch, _getters }, { otp }) {
    return apiProfile.enableMfa(otp).then(response => {
      commit('SET_CURRENT_USER', response.data)
      return response
    })
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  validate({ commit, _state }) {
    return apiSession.validate().then(response => {
      const user = response.data
      commit('SET_CURRENT_USER', user)
      return user
    })
  },
}
