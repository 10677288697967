












import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { enrollmentsComputed } from '@state/helpers'

@Component({
  computed: {
    ...enrollmentsComputed,
  },
})
export default class IntroGamified extends Vue {}
