


























import { authComputed } from '@state/helpers'
import Branding from '@mixins/branding.js'
import endsWith from 'lodash/endsWith'
import { LX } from '@src/lib/analytics/events/LearnerExperience'
import EnrollmentsTimeline from '@components/enrollments/enrollment-timeline.vue'
import EnrollmentStore from '@state/modules/enrollments'
import WelcomeModal from '@/src/components/global/welcome-modal.vue'
import SessionStore from '@state/modules/session'
import BadgesStore from '@src/state/modules/badges'
import MobileNotification from '@components/mobile/mobile-notification.vue'
import LearnerAppBanner from '@components/learner-app/learner-app-banner.vue'

import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component<Vue>({
  page: {
    title: 'My Training',
    meta: [{ name: 'description', content: 'Your Enrollments' }],
  },
  computed: {
    ...authComputed,
  },
  components: {
    EnrollmentsTimeline,
    WelcomeModal,
    MobileNotification,
    LearnerAppBanner,
  },
  mixins: [Branding],
})
export default class Training extends Vue {
  private keysPressed: string | null = null
  private busyFetchingEnrollments: boolean = false
  mobileNotificationHiddenByUser: boolean = false

  created() {
    document.addEventListener('keydown', this.keyPressed)
  }

  destroyed() {
    document.removeEventListener('keydown', this.keyPressed)
  }

  async mounted() {
    this.busyFetchingEnrollments = true
    await EnrollmentStore.fetchEnrollments()
    this.busyFetchingEnrollments = false

    this.$analytics.trackEvent(LX.launchLearnerExperience, {
      'Optional Training Active': this.optionalTrainingEnabled,
      'Mobile App Enabled': SessionStore.mobileAppEnabled,
    })

    if (this.$store.getters['auth/badgesEnabled']) {
      BadgesStore.fetchBadges()
    }

    if (this.$route.params && this.$route.params.enrollment) {
      this.$nextTick(() => {
        const top = (document as any).getElementById(
          `enrollment-${this.$route.params.enrollment}`
        ).offsetParent.offsetTop
        window.scrollTo(0, top)
      })
    }
  }

  get reduceMotion() {
    if (!this.$store.getters['auth/currentUser']) {
      return false
    }
    const reduceMotion = this.$store.getters['auth/currentUser'].reduce_motion
    return reduceMotion === undefined ? false : reduceMotion
  }

  keyPressed(key) {
    this.keysPressed += key.key
    endsWith(this.keysPressed, 'iddqd') &&
      this.$store.commit('globals/allowAllLanguages')
  }

  get enrollments() {
    return EnrollmentStore.enrollments
  }

  get optionalTrainingEnabled() {
    return SessionStore.optionalTrainingEnabled
  }

  hideMobileNotification() {
    this.mobileNotificationHiddenByUser = true
  }

  get linkedDevices() {
    return SessionStore.currentUser?.linkedDevices ?? []
  }

  get showMobileNotification() {
    if (
      this.mobileNotificationHiddenByUser ||
      this.linkedDevices.length > 0 ||
      this.$cookie.get('mobile-notification-hidden')
    ) {
      return false
    }
    return SessionStore.mobileAppEnabled
  }

  get showNewModals() {
    if (this.linkedDevices.length > 0) return false

    return SessionStore.mobileAppEnabled
  }

  get mobileExperiment() {
    return this.$launchDarkly.get('mobile-linking-improvements', 'original')
  }
}
