

























import { Vue, Component, Prop } from 'vue-property-decorator'
import TopArrow from '@components/enrollments/enrollment-timeline/top-arrow.vue'
import TimelineCircle from '@components/enrollments/enrollment-timeline/timeline-circle.vue'
import Timeline from '@components/enrollments/timeline.vue'
import Enrollment from '@components/enrollments/enrollment.vue'
import EnrollmentTimelineCard from '@components/enrollments/enrollment-timeline/enrollment-timeline-card.vue'
import Course from '@src/models/course'
import Policy from '@src/models/policy'
import every from 'lodash/every'
import TrainingCompletedTile from './training-completed-tile.vue'

@Component({
  components: {
    Enrollment,
    EnrollmentTimelineCard,
    TopArrow,
    Timeline,
    TimelineCircle,
    TrainingCompletedTile,
  },
})
export default class EnrollmentTimeline extends Vue {
  @Prop({ required: true }) enrollments!: (Course | Policy)[]
  @Prop({ default: 0 }) index!: number
  @Prop({ default: false }) enrollmentComplete!: boolean

  get layout() {
    return EnrollmentTimelineCard
  }

  get showTopArrow() {
    return this.index === 0
  }

  get firstEnrollment() {
    return this.enrollments[0]
  }

  get allEnrollmentsComplete() {
    return every(this.enrollments, {
      state: 'completed',
    })
  }

  get numberOfEnrollments() {
    return this.enrollments.length
  }

  get enrollmentsWithTimeline() {
    if (this.allEnrollmentsComplete) {
      return this.enrollments
    }

    return this.enrollments.slice(1)
  }
}
