<script>
export default {
  props: {
    message: { type: String, default: 'Please reload the page' },
  },
  methods: {
    forceRefresh() {
      window.location.reload(true)
    },
  },
}
</script>

<template lang="pug">
  div.snotifyToast.snotify-info.alert-container
    .content {{ message }}
    b-button.btn.btn-sm(variant="primary" @click="forceRefresh")
      fa(icon="redo-alt")
      span {{ $t('reload.refresh') }}
</template>
