<script>
import starRating from '@components/enrollments/modals/star-rating.vue'
import { mapActions } from 'vuex'
import { LX } from '@src/lib/analytics/events/LearnerExperience'

export default {
  components: {
    starRating,
  },

  props: {
    enrollment: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
    selectedLanguage: {
      type: String,
      required: true,
      default: () => {
        return ''
      },
    },
    static: {
      type: Boolean,
      required: false,
      default: () => {
        return true
      },
    },
  },

  data() {
    return {
      valueRating: 0,
      lengthRating: 0,
      styleRating: 0,
      comments: '',
      maxChars: 255,
      isShowing: true, // force modal to be open when the component is created - cannot use a prop
      formHasErrors: false,
      showThanks: false,
    }
  },

  computed: {
    canSubmit() {
      return (
        this.valueRating > 0 && this.lengthRating > 0 && this.styleRating > 0
      )
    },
  },

  methods: {
    ...mapActions('enrollments', ['submitSurvey']),

    countChars(evt) {
      if (evt.key.match(/arrow|delete|back/i)) {
        return true
      }
      if (this.comments.length >= this.maxChars) {
        evt.preventDefault()
        return false
      }
    },

    formValid() {
      // require the three star ratings
      this.formHasErrors = !this.canSubmit
      return !this.formHasErrors
    },

    skip() {
      this.trackAnalyticsEvent(LX.skipSurvey, this.enrollment.mixPanelData)
      this.hideSurveyModal()
    },

    hideSurveyModal() {
      this.$emit('hidden')
    },

    submit() {
      if (this.formValid()) {
        this.submitSurvey({
          enrollmentId: this.enrollment.id,
          valueRating: this.valueRating,
          styleRating: this.styleRating,
          lengthRating: this.lengthRating,
          comments: this.comments,
        }).then(() => {
          this.trackAnalyticsEvent(LX.completeSurvey, {
            'Length Rating': this.lengthRating,
            'Helpfulness Rating': this.valueRating,
            'Style Rating': this.styleRating,
            ...this.enrollment.mixPanelData,
          })
          this.thanks()
        })
      }
    },
    contentTitle() {
      if (this.enrollment.storeItem && this.enrollment.storeItem.translations) {
        return (
          this.enrollment.storeItem.translations.filter(
            t => t.language.code === 'en-us'
          ).title || this.enrollment.title
        )
      }

      return this.enrollment.title
    },
    thanks() {
      this.showThanks = true
      window.setTimeout(this.hideSurveyModal, 2500)
    },
    trackAnalyticsEvent(event, properties) {
      this.$analytics.trackEvent(event, properties)
    },
  },
}
</script>

<template>
  <b-modal
    ref="survey-modal"
    v-model="isShowing"
    :body-class="'d-flex align-items-center'"
    class="survey-modal"
    no-close-on-backdrop
    hide-footer
    hide-header
    :static="static">
    <b-container>
      <h4 class="mt-4 text-center">
        {{ $t('survey.rate_following') }}
      </h4>
      <p class="text-center">
        {{ enrollment.title }}
      </p>

      <hr class="dashed mt-4">

      <b-alert fade variant="danger" :show="formHasErrors">
        {{ $t('survey.rate_all_three') }}
      </b-alert>

      <b-alert fade variant="success" :show="showThanks">
        {{ $t('survey.thanks_for_feedback') }}
      </b-alert>

      <div class="rating-rows">
        <div class="row">
          <a
            role="heading"
            href="#"
            :aria-label="$t('survey.helpfulness_of_content')"
            class="col">
            {{ $t('survey.helpfulness_of_content') }}
          </a>
          <div class="col text-right">
            <star-rating
              v-model="valueRating"
              prefix="helpfulness"
              :description="$t('survey.helpfulness_of_content')" />
          </div>
        </div>
        <div class="row">
          <a role="heading" href="#" class="col">
            {{ $t('survey.length_of_content') }}
          </a>
          <div class="col text-right">
            <star-rating
              v-model="lengthRating"
              prefix="length"
              :description="$t('survey.length_of_content')" />
          </div>
        </div>
        <div class="row">
          <a role="heading" href="#" class="col">
            {{ $t('survey.presentation_of_content') }}
          </a>
          <div class="col text-right">
            <star-rating
              v-model="styleRating"
              prefix="presentation"
              :description="$t('survey.presentation_of_content')" />
          </div>
        </div>
      </div>

      <div v-if="enrollment.allowSurveyComments" class="mt-3">
        <textarea
          id="survey-comments"
          v-model="comments"
          class="form-control"
          :aria-label="$t('survey.other_comments')"
          :placeholder="$t('survey.other_comments')"
          :maxlength="maxChars"
          rows="5"
          @keydown="countChars" />
        <div class="counter text-right">
          <small>
            {{ comments.length + '/' + maxChars }}
          </small>
        </div>
      </div>

      <p class="mt-5">
        <button
          class="btn btn-primary w-100"
          type="button"
          :disabled="!canSubmit"
          @click="submit">
          {{ $t('common.submit') }}
        </button>
      </p>
      <base-branded-link
        class="skip-survey-button"
        :text="$t('common.skip')"
        @click="skip" />
    </b-container>
  </b-modal>
</template>

<style lang="scss">
@import 'src/design/components/enrollments/modals/_survey-modal.scss';
</style>
