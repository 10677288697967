import { AvailableLanguages, messages } from '@src/available_languages'
import get from 'lodash/get'

export const DEFAULT_LANGUAGE = 'en-us'
export const LOCAL_STORAGE_KEY_FOR_LANGUAGE =
  'learner_experience:selected_language'

export function getLocale() {
  const storedLocaleCode = getStoredLocaleCode()
  const storedInterfaceLanguage = AvailableLanguages.find(
    lang => lang.code === storedLocaleCode
  )
  return (
    storedInterfaceLanguage ||
    findBestLocaleMatch(window.navigator.language) ||
    defaultLocale()
  )
}

export function setLocale(locale) {
  const storedLocaleCode = getStoredLocaleCode()
  if (locale.code !== storedLocaleCode && storedLocaleCode !== 'en-dev') {
    setStoredLocaleCode(locale.code)
    window.location.reload()
  }
}

export function clearLocale() {
  setStoredLocaleCode('')
}

export function describeLocale(locale, displayLocale) {
  const upCode = locale.toUpperCase().replace(/-/g, '_')
  const desc = get(messages, `${displayLocale}.languages.${upCode}`, null)
  if (desc) {
    return desc
  }
  const fallback = get(messages, `en-us.languages.${upCode}`, null)
  if (fallback) {
    return fallback
  }
  return locale
}

export function languageOptions(showAll = false) {
  return AvailableLanguages.filter(
    language => language.active === true || showAll
  )
}

// Finds a reasonable match for the requested language
// We expect languageCode to be either just a language ('en')
// Or language plus region ('en-us')
// If the former, we require an exact match
// If the latter, we return the first matching regional variant
function findBestLocaleMatch(languageCode) {
  if (!languageCode) {
    return false
  }

  const code = languageCode.toLowerCase()
  const _activeLanguages = languageOptions(false)

  // Find exact match for the locale
  const exactMatch = _activeLanguages.find(lang => lang.code === code)
  if (exactMatch) {
    return exactMatch
  }

  // first regional variant of requested language
  // (if requested language has no regional specifier)
  const approximateMatch = _activeLanguages.find(lang =>
    lang.code.toLowerCase().startsWith(code)
  )
  if (approximateMatch) {
    return approximateMatch
  }

  // No match in available languages at all
  return false
}

function defaultLocale() {
  return AvailableLanguages.find(lang => lang.code === 'en-us')
}

function getStoredLocaleCode() {
  try {
    return (
      JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY_FOR_LANGUAGE)) ||
      null
    )
  } catch (e) {
    return null
  }
}

function setStoredLocaleCode(code) {
  window.localStorage.setItem(
    LOCAL_STORAGE_KEY_FOR_LANGUAGE,
    JSON.stringify(code)
  )
}
