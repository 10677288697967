<script>
export default {
  props: {
    iso: { type: String, default: null },
    title: { type: String, default: null },
    squared: { type: Boolean, default: true },
  },
  computed: {
    country: function () {
      return this.iso.toLowerCase()
    },
    flagIconClass: function () {
      return (
        (this.squared ? 'flag-icon-squared ' : '') + 'flag-icon-' + this.country
      )
    },
    backgroundImage: function () {
      return this.squared
        ? `./flags/1x1/${this.country}.svg`
        : `./flags/4x3/${this.country}.svg`
    },
  },
}
</script>
<template>
  <img
    v-if="iso"
    :class="flagIconClass"
    :title="title || iso"
    :alt="title || iso"
    :src="backgroundImage"
    class="flag-icon">
</template>
<style lang="scss" scoped>
@import 'src/design/components/_base-flag.scss';
</style>
