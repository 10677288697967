/* eslint-disable */
// @ts-nocheck
import {
  User,
  Reseller,
  Admin
} from '@src/state/api/graphql'
import LaunchDarkly from '@/src/lib/feature-flags/launch-darkly'
import Session from '../state/modules/session'

interface Sessions {
  user: User
  admin?: Admin | null
  reseller?: Reseller | null
}

const site = process.env.VUE_APP_API_HOST
  ? process.env.VUE_APP_API_HOST.replace(/^https?:\/\//, '') : ''

const injectPendo = () => {
  const key = process.env.VUE_APP_PENDO_KEY
  if (!key) return

  const pendoScript = document.createElement('script')
  pendoScript.setAttribute('type', 'text/javascript')
  pendoScript.innerHTML = (function (apiKey) {
    ; (function (p, e, n, d, o) {
      var v, w, x, y, z
      o = p[d] = p[d] || {}
      o._q = o._q || []
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)))
            }
        })(v[w])
      y = e.createElement(n)
      y.async = !0
      y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js'
      z = e.getElementsByTagName(n)[0]
      z.parentNode.insertBefore(y, z)
    })(window, document, 'script', 'pendo')
  })(key)
  /* eslint-enable */
  document.head.appendChild(pendoScript)
}

const removeScript = (name) => {
  const scripts = document.querySelectorAll(`script[src*="${name}"]`)
  for (const script of scripts) { script.remove() }
}

const attributes = ({ user, reseller, admin }: Sessions) => {
  if (user.id) return userAttributes(user, reseller, admin)
  if (reseller.id) return partnerAdminAttributes(reseller, admin)
  return {}
}

const userAttributes = (user: User) => {
  const {
    accountId,
    billingType,
    domain,
    accountLanguageSettings,
    email,
    passlessEnabled,
    subscriptionLevel,
    languageSettings: { trainingLocale },
    adminLocale,
    samlEnabled
  } = user
  const instance = window.location.host
  const userId = Session.currentUser?.id
  const siteAdmin = Session.currentAdmin?.id || 0
  const resellerId = Session.currentReseller?.resellerId || 0
  const locale = localStorage.getItem('adminLocale') || adminLocale || 'en-us'
  return {
    visitor: {
      email: email,
      id: `${instance}:${siteAdmin}:${resellerId}:${userId}`,
      trainingLocale: trainingLocale,
      locale,
      type: 'User',
    },
    account: {
      billing_type: billingType,
      default_locale: accountLanguageSettings?.trainingLocale,
      domain: domain,
      id: `${instance}:${accountId}`,
      has_passless: passlessEnabled,
      partner_id: `${instance}:partner:${resellerId}`,
      saml_enabled: samlEnabled,
      subscription_level: subscriptionLevel,
      team_dashboard: Session.teamDashboardEnabled,
    },
  }
}

const partnerAdminAttributes = (partnerAdmin: Reseller, admin: Admin) => {
  const {
    email,
    id,
    confirmedAt,
    currentSignInAt,
    resellerType,
    subscriptionId,
    canManageSubaccounts,
    canEditAccountInfo,
    parentPartnerId,
    parentAccountId,
  } = partnerAdmin
  const aid = admin.id || 0
  return {
    visitor: {
      id: `${site}:partner_admin:${aid}:${id}`,
      email,
      confirmed_at: confirmedAt,
      last_login: currentSignInAt,
      type: 'PartnerAdmin',
    },
    account: {
      id: `${site}:partner:${parentAccountId}`,
      parent_partner_id: `${site}:partner:${parentPartnerId || 0}`,
      partner_type: resellerType,
      subscription_id: subscriptionId,
      can_edit_account_info: canEditAccountInfo,
      can_manage_partners: canManageSubaccounts,
    },
  }
}

function isPolicyPreview() {
  // If the new page is a policy preview (through admin console) or actual viewing (LX uses this page), don't show Pendo
  return new RegExp(/ui\/training\/policies\/\d+\/(preview|document)/).test(
    window.location.pathname,
  )
}

export const PendoSetup = (sessions: Sessions) => {
  const ld = LaunchDarkly.getInstance()
  const enablePendo: boolean = !!(!isPolicyPreview() && (sessions.user?.accountId || sessions.reseller?.id))
  const { allowAnalytics } = Session
  ld.onReady().finally(() => {
    if (!allowAnalytics) {
      removeScript('pendo')
      removeScript('zdassets')
      window.pendo = undefined
      return
    }
    if (enablePendo) injectPendo()
    if (window.pendo) {
      // Somehow, some users have 'undefined' as their 'adminLocale' in localStorage. This is to clear it.
      if (localStorage.getItem('adminLocale') === 'undefined') {
        localStorage.removeItem('adminLocale')
      }
      window.pendo.initialize(attributes(sessions))
    }
  })
}
