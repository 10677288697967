<template lang="pug">
  div.training-statistics.d-flex.flex-column(qa-id="training-progress")
    div.progress-container
      div.progress-labels
        label {{ $t('dashboard.training.completed') }}
        label {{ completionPercentage }}%
      div.progress
        div.progress-bar(id="training-progress" role="progressbar" aria-labelledby="progress-text" :aria-valuenow="completionPercentage" :style="`width: ${completionPercentage}%`" aria-valuemin="0" aria-valuemax="100")
    div.training-status.light-brand
      b-container
        div.training-checklist
          div.svg-container
            base-inline-svg(
              aria-label=""
              :id="'training-progress'"
              :svg="require('@assets/images/dashboard/checklist.svg?inline')"
              :branded="true")
          p.progress-text {{ $tc('dashboard.training.you_have_completed', trainingStatistics.total, { progress: trainingStatistics.complete, total: trainingStatistics.total }) }}
        div.training-button
          b-button(
            variant="primary"
            qa-id="navigate-to-training"
            @click="routeTo()") {{ $t('dashboard.training.go_to_training') }}
</template>

<script>
import { myDashboard } from '@src/lib/analytics/events/my-dashboard'

export default {
  props: {
    trainingStatistics: { type: Object, default: () => {} },
  },
  computed: {
    completionPercentage() {
      return Math.round(
        (this.trainingStatistics.complete / this.trainingStatistics.total) * 100
      )
    },
  },
  methods: {
    routeTo() {
      this.$analytics.trackEvent(myDashboard.goToTrainingButton)
      this.$router.push({ name: 'home' })
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/design/components/dashboard/_completed-training.scss';
</style>
