// https://date-fns.org/docs/distanceInWords
import parseISO from 'date-fns/parseISO'
import formatDistance from 'date-fns/formatDistance'
import { getLocale } from '@src/locale'

export default function formatDateRelative(
  fromDate,
  toDate = new Date(),
  customSentences,
  defaultRelativity = false
) {
  toDate = typeof toDate === 'string' ? parseISO(toDate) : toDate

  if (fromDate > toDate) {
    if (customSentences && customSentences.overdue) {
      return customSentences.overdue.replace(
        '{time}',
        formatDistance(fromDate, toDate, {
          locale: getLocale().dateFnsLocale,
        })
      )
    }

    return `Overdue by ${formatDistance(fromDate, toDate, {
      locale: getLocale().dateFnsLocale,
    })}`
  }

  if (customSentences && customSentences.time_left) {
    return customSentences.time_left.replace(
      '{time}',
      formatDistance(fromDate, toDate, {
        locale: getLocale().dateFnsLocale,
      })
    )
  }

  if (defaultRelativity) {
    return formatDistance(fromDate, toDate, {
      locale: getLocale().dateFnsLocale,
    })
  }

  return `${formatDistance(fromDate, toDate, {
    locale: getLocale().dateFnsLocale,
  })} left`
}
