


















import { Vue, Component } from 'vue-property-decorator'
import MobileNotificationModal from '@/src/components/mobile/mobile-notification-modal.vue'
import { LX } from '@src/lib/analytics/events/LearnerExperience'

@Component({
  components: {
    MobileNotificationModal,
  },
})
export default class MobileNotification extends Vue {
  private preventProfileMenuFromHiding: boolean = false

  mounted() {
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (this.preventProfileMenuFromHiding) {
        bvEvent.preventDefault()
      }
    })

    this.$analytics.trackEvent(LX.showSignInBanner, {
      Layout: 'original',
    })
  }

  hideNotificationAndSetCookie(duration) {
    this.$analytics.trackEvent(LX.dismissLearnerAppBanner)
    this.$emit('hideMobileNotification')
    this.$cookie.set('mobile-notification-hidden', 'true', {
      expires: duration,
    })
  }
}
