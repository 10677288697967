<script>
import LanguageSelectionModal from './language-selection-modal'
import { describeLocale, getLocale } from '@src/locale'
export default {
  components: {
    LanguageSelectionModal,
  },
  props: {
    languageOptions: { type: Array, required: true },
    defaultLanguage: { type: Object, required: true, default: () => ({}) },
    dropup: { type: Boolean, required: false, default: false },
    id: {
      type: String,
      required: false,
      default: `language-select-dropdown-${Math.floor(Math.random() * 100)}`,
    },
  },
  data() {
    return {
      MAX_LANGUAGES_IN_DROP_DOWN: 5,
      selectedLanguage: this.defaultLanguage,
      showModal: false,
    }
  },
  methods: {
    selectLanguage(lang) {
      this.hideModal()
      this.selectedLanguage = lang
      this.$emit('selectLanguage', lang)
    },
    desc(code) {
      return describeLocale(code, getLocale().code)
    },
    hideModal() {
      this.showModal = false
    },
    showLanguageModal(e) {
      e.stopPropagation() // Prevent dropdown from closing which hides the modal
      this.showModal = true
    },
  },
}
</script>
<template>
  <div class="px-0">
    <div v-if="showModal">
      <language-selection-modal
        :language-options="languageOptions"
        :selected-language="selectedLanguage"
        @setLanguageAndCloseModal="selectLanguage"
        @hideModal="hideModal" />
    </div>
    <b-dropdown
      v-if="selectedLanguage"
      :id="`${id}-language-select-dropdown`"
      variant="link"
      :dropup="dropup"
      :class="['language-select-dropdown col-lg-auto']"
      toggle-class="px-2">
      <template slot="button-content">
        <fa icon="globe" class="mx-1" />
        {{ desc(selectedLanguage.code) }}
      </template>
      <div>
        <b-dropdown-item
          v-for="(lang, index) in languageOptions.slice(
            0,
            MAX_LANGUAGES_IN_DROP_DOWN
          )"
          :key="index"
          :class="lang.code"
          :active="selectedLanguage.code === lang.code"
          @click.prevent="selectLanguage(lang)">
          {{ desc(lang.code) }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="languageOptions.length > MAX_LANGUAGES_IN_DROP_DOWN"
          class="link branded"
          block
          @click="showLanguageModal">
          {{ $t('enrollment.language_options.select_another') }}
        </b-dropdown-item>
      </div>
    </b-dropdown>
  </div>
</template>

<style lang="scss">
@import 'src/design/components/enrollments/_language-selection-dropdown.scss';
</style>
