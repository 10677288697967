import Enrollment from './enrollment'
import {
  Enrollment as GqlEnrollment,
  Policy as GqlPolicy,
} from '@state/api/graphql'

export default class Policy extends Enrollment {
  minimumPolicyReviewTime: string | null

  constructor(enrollment: GqlEnrollment, languageOrder: string[]) {
    super(enrollment, languageOrder)
    const enrollmentItem = enrollment.enrollmentItem as GqlPolicy
    const user = enrollment.user
    if (enrollmentItem.id) {
      this.uuid = enrollmentItem.id.toString()
    }
    this.title = enrollmentItem.title
    this.publishedAt = enrollmentItem.publishedAt
    this.description = enrollmentItem.description || ''
    this.enrollmentType = 'Policy'
    this.duration = enrollmentItem.minimumTime || null
    this.policyType = enrollmentItem.type
    this.publisher = user?.companyName || ''
    this.contentSource = 'kmsat'
    this.minimumPolicyReviewTime = enrollmentItem.minimumTime
      ? enrollmentItem.minimumTime.toString()
      : null
    this.originalLanguageOptions = enrollmentItem.translations.map(tr =>
      this.mapTranslation(tr, enrollment),
    )
  }

  get defaultImage() {
    return require('@assets/images/accept-policy.svg?inline')
  }

  get analyticsEnrollmentType() {
    return 'Policy'
  }

  get defaultHeaderImage() {
    return require('@assets/images/policy-header-placeholder.svg?inline')
  }

  get hasReviewTime(): boolean {
    return !!this.minimumPolicyReviewTime
  }

  get showRequiredReviewTime() {
    return this.minimumPolicyReviewTime && !this.isComplete && !this.isUrlPolicy
  }

  get showEnrollmentTimeSpent(): boolean {
    return !!(
      this.timeSpentInSeconds &&
      this.minimumPolicyReviewTime &&
      !this.isUrlPolicy
    )
  }
}
