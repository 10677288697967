








import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import DashboardNavigation from '@components/dashboard/dashboard-navigation.vue'

@Component({
  components: {
    DashboardNavigation,
  },
})
export default class Dashboard extends Vue {
  @Prop({ required: false }) enrollmentError!: boolean

  created() {
    if (this.enrollmentError) {
      // @ts-ignore
      this.$snotify.error(this.$t('dashboard.enrollmentError'))
    }
  }
}
