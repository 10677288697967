<script>
import { setInterval } from 'timers'
import ReloadButton from '@components/global/reload-button'

import axios from 'axios'

export default {
  components: { ReloadButton },
  data() {
    return {
      updateAvailable: false,
      testBypass: process.env.VUE_APP_RELOAD_BUTTON_BYPASS || false,
    }
  },
  computed: {
    version() {
      return process.env.VUE_APP_VERSION || 'PLACEHOLDER'
    },
  },
  mounted() {
    if (!this.testBypass) {
      this.checkVersion()
      setInterval(
        this.checkVersion.bind(this),
        process.env.VUE_APP_REFRESH_TIME
          ? process.env.VUE_APP_REFRESH_TIME
          : 120000
      )
    }
  },
  methods: {
    forceRefresh() {
      window.location.reload(true)
    },
    checkVersion() {
      const env = process.env.NODE_ENV
      const baseUrl = env === 'production' ? '/learner/' : '/'
      const uniqueInt = new Date().getTime()
      axios({
        url: `version?${uniqueInt}`,
        baseURL: baseUrl,
      })
        .then(response => {
          if (response?.status === 0) return
          const newestVersion = response?.data
          if (
            newestVersion &&
            newestVersion.toString().trim() !==
              `version-${this.version.toString().trim()}`
          ) {
            this.updateAvailable = true
          }
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code
            throw new Error(
              `Error status code out of the range of 2xx: ${error}`
            )
          } else if (error.request) {
            // The request was made but no response was received
            throw new Error(`Error with no response: ${error}`)
          } else {
            throw new Error(`Generic Error: ${error}`)
          }
        })
    },
  },
}
</script>

<template lang="pug">
  div#reload-version
    div(v-if="updateAvailable")
      reload-button(:message="$t('reload.title')")
</template>
