<template lang="pug">
  div.container.px-0
    div.row
      div(
        v-for="(group, index) in groupedAlphabeticalLanguages"
        :key="index"
        class="col-md-4 col-sm-12 px-0")
        div.languageBtnContainer(
          v-for="(lang, langIndex) in group"
          :key="langIndex"
          class="col-md-12 px-4 my-1")
          b-button(
            block
            translate="no"
            :class="[{ active: selectedLanguage.code === lang.code }]"
            @click="languageSelected(lang)")
            span {{ desc(lang.code) }} &#x200E;
            fa.m-1(
              v-if="userHasSelectedLanguage && selectedLanguage.code === lang.code"
              icon="check")
            //- HTML Entity &#x200E; for correcting direction of () in RTL
            //- Vue escapes it in {{}} and assigning it a variable will render as a string
</template>

<script>
import chunk from 'lodash/chunk'
import sortBy from 'lodash/sortBy'
import { describeLocale, getLocale } from '@src/locale'
import SessionStore from '@state/modules/session'

export default {
  props: {
    displayLocale: { type: String, required: false, default: null },
    languageOptions: { type: Array, required: true },
    selectedLanguage: { type: Object, required: true, default: () => ({}) },
    rtl: { type: Boolean, required: false, default: getLocale().rtl },
  },
  computed: {
    groupedAlphabeticalLanguages: function () {
      const sortedLanguages = sortBy(this.languageOptions, l =>
        this.desc(l.code)
      )
      const groupSize = Math.ceil(sortedLanguages.length / 3)
      return chunk(sortedLanguages, groupSize)
    },
    userHasSelectedLanguage: function () {
      return SessionStore.userHasSelectedLanguage
    },
  },
  methods: {
    languageSelected(lang) {
      this.$emit('languageSelected', lang)
    },
    desc(code) {
      return describeLocale(code, this.displayLocale || code)
    },
  },
}
</script>

<style lang="scss">
@import 'src/design/components/global/_language-selection-options-list.scss';
</style>
