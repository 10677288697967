<template lang="pug">
  div.container
    div.image
      img.stu(:alt="$t('errors.error_code')" src="/img/404-stu.png")
      div.description
        h1.mb-0
          strong {{ $t('errors.heading') }}
            br
          span {{ $t('errors.subheading') }}
        a(href="/") {{ $t('errors.home_ref') }}
        p {{ $t('errors.error_code') }} {{ $t('errors.404') }}
</template>

<script>
export default {
  mounted() {
    const el = document.getElementById('loader-wrap')
    if (!el) return
    el.style.display = 'none'
  },
}
</script>

<style lang="scss" scoped>
// This contains a bunch of very custom CSS
// so that it matches up to the existing error pages
.container {
  padding-top: 50px;
  padding-bottom: 50px;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  .image {
    display: inline-block;
    text-align: left;
  }
  .description {
    display: inline-block;
    text-align: left;
  }
  .stu {
    padding-right: 50px;
    width: 200px;
  }
  h1 {
    font-size: 22px;
    margin: 10px 0;
    font-weight: 400;
    color: #444;
    padding-bottom: 12px;
  }
  p {
    font-size: 12px;
    color: #929292;
    font-weight: bold;
    padding-top: 12px;
  }
  a {
    border: none;
    border-radius: 3px;
    padding: 12px 24px;
    font-size: 14px;
    background-color: #f26721;
    color: white;
    text-decoration: none;
    display: inline-block;
    &:hover {
      background-color: #e05f1e;
    }
  }
}

@media screen and (max-width: 650px) {
  .image {
    width: 100%;
  }
  .stu {
    padding-right: 0px;
    width: 80px;
    margin-bottom: 0px;
  }

  .container .description {
    text-align: center;
  }

  .container .image {
    text-align: center;
  }
}
</style>
