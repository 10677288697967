































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { authComputed, sessionComputed } from '@state/helpers'
import InterfaceLanguageSelectionOpener from '@/src/components/global/interface-language-selection-opener.vue'
import truncate from '@mixins/truncate'
import { clearLocale, getLocale } from '@src/locale'
import SessionStore from '@state/modules/session'
import BadgesStore from '@src/state/modules/badges'

@Component({
  components: { InterfaceLanguageSelectionOpener },
  computed: {
    ...authComputed,
    ...sessionComputed,
  },
  mixins: [truncate],
})
export default class NavBar extends Vue {
  private brand
  private currentUser
  private optionalTrainingEnabled
  private userName
  private earnedBadgesNotViewed
  private truncate
  private companyName
  private rtl: boolean | undefined = getLocale()?.rtl || false

  get leaderboardEnabledLdFlagOff() {
    return SessionStore.leaderboardEnabledWhenLdFlagOff
  }

  get leaderboardEnabledLdFlagOn() {
    return SessionStore.leaderboardEnabledWhenLdFlagOn
  }

  showWelcomeModal() {
    this.$root.$emit('show-welcome-modal')
  }

  goToPhishER() {
    window.open(this.phishERUrl, '_blank')
  }

  goToLogout() {
    clearLocale()
    window.location.href = this.siteUrl + '/spa/auth/logout?resource_type=user'
  }

  goToMainContent() {
    // Not crazy about this solution however, linking to
    // anchor tags in Vue Router's default hash mode is
    // a known pain: https://github.com/vuejs/vue-router/issues/1668
    const mainContentElement = document.getElementById('main')
    // @ts-ignore
    mainContentElement.setAttribute('tabindex', -1)
    // @ts-ignore
    mainContentElement.focus()
  }

  get badgeCount() {
    return (
      (BadgesStore.earnedBadgesNotViewed &&
        BadgesStore.earnedBadgesNotViewed.length) ||
      null
    )
  }

  get mobileAppIsEnabled() {
    return SessionStore.mobileAppEnabled
  }

  get userNameTruncated() {
    return this.truncate(this.userName, 60)
  }

  get adminUrl() {
    return this.currentUser.administration_url
  }

  get accountSettingsUrl() {
    return this.currentUser.account_settings_url
  }

  get siteUrl() {
    return this.currentUser.site_url
  }

  get phishERUrl() {
    return `${this.siteUrl}${this.currentUser.users_goto_phisher_path}`
  }

  get phishEREnabled() {
    return this.currentUser.phisher_enabled
  }

  get brandLogo() {
    if (this.brand) {
      return this.brand.logo
    }
    return '/learner/logo.png'
  }

  get badgesEnabled() {
    return this.currentUser.badges_enabled
  }

  get dashboardEnabled() {
    return SessionStore.dashboardEnabled
  }

  get doNotShowTrainingTab() {
    return (
      this.badgesEnabled ||
      this.leaderboardEnabledLdFlagOff ||
      this.dashboardEnabled ||
      this.showLibraryTab
    )
  }

  get showLibraryTab() {
    return (
      this.optionalTrainingEnabled || SessionStore.aidaOptionalTrainingEnabled
    )
  }

  get messagesEnabled() {
    return SessionStore.messagesEnabled
  }

  get unreadMessagesCount() {
    return SessionStore.unreadMessagesCount
  }

  get primaryColor() {
    return this.brand.primary
  }

  activeNavigationStyle(path) {
    return this.isActive(path)
      ? { style: `border-color: ${this.primaryColor} !important;` }
      : {}
  }

  isActive(path) {
    return this.$route.path.includes(path)
  }
}
