



































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import tour from '@mixins/tour.js'
import IntroGamified from '@components/global/intro-gamified.vue'
import IntroGeneric from '@components/global/intro-generic.vue'
import InterfaceLanguageSelectionOpener from '@/src/components/global/interface-language-selection-opener.vue'
import {
  authComputed,
  enrollmentsComputed,
  sessionComputed,
  unifiedLeaderboardComputed,
} from '@state/helpers'
import { LX } from '@src/lib/analytics/events/LearnerExperience'
import SessionStore from '@state/modules/session'

@Component({
  components: {
    IntroGamified,
    IntroGeneric,
    InterfaceLanguageSelectionOpener,
  },
  computed: {
    ...enrollmentsComputed,
    ...unifiedLeaderboardComputed,
    ...authComputed,
    ...sessionComputed,
  },
  mixins: [tour],
})
export default class WelcomeModal extends Vue {
  private active: boolean = false

  // mixins & mapped getters
  private currentUser
  private startIntroTour

  mounted() {
    if (this.currentUser) {
      if (this.showOnMount()) {
        this.active = true
      }

      this.$root.$on('show-welcome-modal', () => {
        this.active = true
      })
    }
  }

  showOnMount(): Boolean {
    const alreadyDisplayed = this.$cookie.get('knowbe4-intro-shown')
    const disabledByAccountLearnerExperienceSettings = SessionStore.disableTour

    return !alreadyDisplayed && !disabledByAccountLearnerExperienceSettings
  }

  hideModal() {
    this.active = false
    this.setWelcomeModalCookie()
    this.trackAnalytics(LX.skipTour, {
      Step: 0,
    })
  }

  startTourAndHideModal() {
    this.setWelcomeModalCookie()
    this.startIntroTour() // start the tour
    this.trackAnalytics(LX.startTour)
    this.active = false
  }

  setWelcomeModalCookie() {
    this.$cookie.set('knowbe4-intro-shown', 'true', { expires: '3M' })
  }

  trackAnalytics(event, params?) {
    this.$analytics.trackEvent(event, params)
  }
}
