<script>
import GamifiedTrainingComplete from '@components/dashboard/gamified-training-complete.vue'
import SessionStore from '@state/modules/session'
export default {
  components: { GamifiedTrainingComplete },
  props: {
    gamificationEnabled: { type: Boolean, default: false },
    motionReduced: { type: Boolean, default: false },
  },
  computed: {
    trainingCompletedMessage() {
      if (this.showLibraryTab) {
        return this.$t(
          'dashboard.training.all_training_completed_library_enabled'
        )
      }
      return this.$t('dashboard.training.all_training_completed')
    },
    showLibraryTab() {
      return SessionStore.aidaOptionalTrainingEnabled
    },
  },
}
</script>

<template>
  <div class="complete-wrapper" qa-id="all-training-complete">
    <div v-if="gamificationEnabled">
      <gamified-training-complete :motion-reduced="motionReduced" />
    </div>
    <div v-else>
      <div
        class="w-100 h-100 d-flex align-items-center justify-content-center position-absolute light-brand">
        <div class="text-center">
          <div class="training-complete mx-auto mb-3">
            <base-inline-svg
              :id="'training-complete'"
              :svg="
                require('@assets/images/dashboard/training-complete.svg?inline')
              "
              :branded="true" />
          </div>
          <p class="px-5 text-container">
            {{ trainingCompletedMessage }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/design/components/dashboard/_all-training-complete.scss';
</style>
