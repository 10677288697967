<template lang="pug">
  div.phishing-test-results(qa-id="phishing-test-results")
    div.phising-results(v-if="hasPhishingResults")
      div.progress-labels
        label.text-muted.progress-label.px-1 {{ $t('dashboard.phishing.wins') }}
        span.info(id="wins-tooltip")
          fa(icon="question-circle")
        b-tooltip(
          triggers="hover click blur"
          target="wins-tooltip"
          :title="this.$t('dashboard.phishing.wins_description')")
      div.progress.mb-2
        div.progress-bar(id="phishing-wins" role="progressbar" aria-labelledby="phish-wins" :aria-valuenow="calculatePercentage(reported)" :style="`width: ${calculatePercentage(reported)}%`" aria-valuemin="0" aria-valuemax="100")
      label#phish-wins.sr-only {{ $t('dashboard.phishing.wins') }} {{ clicked }} / {{ delivered }}
      label.text-muted.mb-3 {{ reported }} / {{ delivered }}

      div.progress-labels
        label.progress-label.text-muted.px-1 {{ $t('dashboard.phishing.losses') }}
        span.info(id="losses-tooltip")
          fa(icon="question-circle")
        b-tooltip(
          triggers="hover click blur"
          target="losses-tooltip"
          :title="this.$t('dashboard.phishing.losses_description')")
      div.progress.mb-2
        div.progress-bar(id="phishing-losses" role="progressbar" aria-labelledby="phish-failures" :aria-valuenow="calculatePercentage(clicked)" :style="`width: ${calculatePercentage(clicked)}%`" aria-valuemin="0" aria-valuemax="100")
      label#phish-failures.sr-only {{ $t('dashboard.phishing.losses') }} {{ clicked }} / {{ delivered }}
      label.text-muted {{ clicked }} / {{ delivered }}
    div(v-else)
      div.p-4.text-center
        div.phishing-envelope.mx-auto.mb-3
          base-inline-svg(
            :id="'phishing-envelope'"
            :svg="require('@assets/images/dashboard/phish-envelope.svg?inline')"
            :branded="true")
        p.text-muted {{ $t('dashboard.phishing.no_phishing') }}
</template>

<script>
export default {
  props: {
    phishingStatistics: { type: Object, default: () => {} },
  },
  computed: {
    clicked() {
      return this.phishingStatistics.totalFailures
    },
    reported() {
      return this.phishingStatistics.emailReported
    },
    delivered() {
      return this.phishingStatistics.totalDelivered
    },
    misses() {
      return this.phishingStatistics.totalMisses
    },
    hasPhishingResults() {
      return !!this.delivered
    },
  },
  methods: {
    calculatePercentage(total) {
      return (
        Math.round((total / this.phishingStatistics.totalDelivered) * 100) || 0
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/design/components/dashboard/_phishing-tests.scss';
</style>
