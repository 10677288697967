import { graphqlAxios } from '@state/api/client/graphqlAxiosClient'

/** GQL Queries **/
import DashboardStatisticsQuery from '@gql/queries/dashboard-statistics.gql'

export const enrollmentsRecentlyFetched = false
export let statisticsRecentlyFetched = false

export function dashboardStatistics() {
  statisticsRecentlyFetched = true
  window.setTimeout(() => {
    statisticsRecentlyFetched = false
  }, 60000)
  return graphqlAxios.post('/graphql', {
    query: DashboardStatisticsQuery,
  })
}
