import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '../store'
import { Badges as apiBadges } from '@state/api'
import Badge from '@src/models/badge'

@Module({
  namespaced: true,
  store: store,
  dynamic: true,
  name: 'badges',
})
class Badges extends VuexModule {
  badges: Badge[] = []

  // Mutations
  @Mutation
  cacheBadges(badges: Badge[]) {
    this.badges = badges
  }

  @Action
  async earnBadges(enrollmentId: number | null) {
    if (!enrollmentId) {
      return
    }
    const response = await apiBadges.earnBadges(enrollmentId)
    this.cacheBadges(responseToBadges(response.data?.data?.badgesEarn?.nodes))
  }

  @Action
  async fetchBadges() {
    const response = await apiBadges.badges()
    this.cacheBadges(
      responseToBadges(
        response.data?.data?.currentUser?.gamification?.badgesList,
      ),
    )
  }

  @Action
  async markAcknowledgedBadgesAsViewed() {
    const response = await apiBadges.markAcknowledgedBadgesAsViewed()
    this.cacheBadges(
      responseToBadges(response.data?.data?.badgesMarkAsViewed?.nodes),
    )
  }

  @Action
  async markAsAcknowledged(badgeIds: number[]) {
    const response = await apiBadges.markBadgeAsAcknowledged(badgeIds)
    this.cacheBadges(
      responseToBadges(response.data?.data?.badgesMarkAsAcknowledged?.nodes),
    )
  }

  get all() {
    return this.badges
  }

  get earnedBadges() {
    return this.badges?.filter(badge => badge.earnedAt) || []
  }

  get earnedBadgesNotViewed() {
    return (
      this.badges?.filter(
        badge => badge.earnedAt && badge.acknowledgedAt && !badge.viewedAt,
      ) || []
    )
  }

  get earnedBadgesNotAcknowledged() {
    return (
      this.badges?.filter(badge => badge.earnedAt && !badge.acknowledgedAt) ||
      []
    )
  }
}

// helpers
function responseToBadges(response): Badge[] {
  return response.map(d => new Badge(d))
}

export default getModule(Badges)
