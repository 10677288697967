<script>
import NavBar from '@components/global/nav-bar'
import FooterBar from '@components/global/footer'
import Loader from '@components/global/loader'
import HelpButton from '@components/global/help-button'
import BadgeAcquired from '@/src/components/badges/badge-acquired.vue'
import SessionStore from '@state/modules/session'
import ImpersonatingBar from '@components/global/impersonating-bar'

export default {
  components: {
    NavBar,
    FooterBar,
    HelpButton,
    Loader,
    BadgeAcquired,
    ImpersonatingBar,
  },
  computed: {
    loading() {
      return this.$store.getters['globals/loading'] === true
    },
    isImpersonatingUser() {
      return SessionStore.currentImpersonatingUser?.id !== undefined
    },
  },
  mounted() {
    document.getElementById('loader-wrap').style.display = 'none'
  },
}
</script>

<template>
  <div class="wrap test-ondemand">
    <impersonating-bar v-if="isImpersonatingUser" />
    <nav-bar />
    <badge-acquired />

    <transition name="fade">
      <div v-if="!loading" class="container">
        <slot />
        <help-button />
      </div>
    </transition>

    <transition name="fade">
      <footer-bar v-if="!loading" />
    </transition>

    <transition name="fade">
      <loader v-if="loading" :large="true" />
    </transition>
  </div>
</template>

<style lang="scss" module>
@import '@design';
</style>
