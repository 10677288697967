<script>
export default {
  props: {
    motionReduced: { type: Boolean, default: false },
  },
  methods: {
    randomCloudYPosition() {
      const MAX_HEIGHT = 200
      return `${Math.floor(Math.random() * MAX_HEIGHT) + 1}px`
    },
    randomCloudXPosition() {
      const MAX_WIDTH = 1200
      return `${Math.floor(Math.random() * MAX_WIDTH) + 1}px`
    },
  },
}
</script>

<template>
  <div class="w-100 h-100">
    <div
      v-for="i in 5"
      :key="i"
      :class="[`x${i} position-absolute`, { animated: !motionReduced }]"
      :style="{
        top: randomCloudYPosition(),
        marginLeft: randomCloudXPosition(),
      }">
      <div class="cloud" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/design/components/dashboard/_clouds.scss';
</style>
