<template lang="pug">
  div
    span.sr-only(id="table-description") {{  $t('dashboard.training_table.description') }}
    b-table(
      hover
      borderless
      responsive
      small
      class="mb-0"
      :items="tableData"
      :fields="tableFields"
      :aria-label="$t('dashboard.training_table.label')"
      :aria-describedby="'table-description'"
      qa-id="enrollment-table"
      :aria-rowcount="tableFields.length"
      @row-clicked="enrollmentRowClicked")
      template(#cell(title)="data")
        span
          | &#x200E; {{ data.item.title }} &#x200E;
      //- HTML Entity &#x200E; for correcting direction of () in RTL
      //- Vue escapes it in {{}} and assigning it a variable will render as a string
      template(#cell(status)="data")
        span.status-indicator(:style="{ backgroundColor: statusColor(data.item.status) }")
        span.status-title.mx-2 {{ $t(`enrollment.status.${data.item.status}`) }}
      template(#cell(expiresAt)="data")
        span {{ relativeDate(data.item) }}
      template(#cell(completedAt)="data")
        span {{ convertDate(data.item.completedAt) }}
      template(#cell(failureDate)="data")
        span {{ convertDate(data.item.failureDate) }}
</template>

<script>
import formatDateRelative from '@utils/format-date-relative'
import { parseISO } from 'date-fns'
import formatDate from '@utils/format-date'

export default {
  props: {
    tableFields: { type: Array, default: () => [] },
    tableData: { type: Array, default: () => [] },
    clickRow: { type: Boolean, default: () => true },
  },
  methods: {
    statusColor: function (status) {
      switch (status) {
        case 'not_started':
          return this.colorPrimaryDarkenMore
        case 'in_progress':
          return this.colorPrimaryLighten
        case 'completed':
          return '#afafaf'
        case 'past_due':
          return this.colorPrimary
        default:
          break
      }
    },
    convertDate(date) {
      if (date) {
        return formatDate(new Date(date))
      }
      return ''
    },
    relativeDate(item, _status) {
      const date = item.expiresAt
      const sentences = {
        overdue: this.$t('enrollment.time.overdue'),
        time_left: this.$t('enrollment.time.time_left'),
      }

      if (date && item.status !== 'completed') {
        return formatDateRelative(new Date(), parseISO(date), sentences)
      }
      return '-'
    },
    enrollmentRowClicked(rowData) {
      if (this.clickRow) {
        this.$router.push({ name: 'home', params: { enrollment: rowData.id } })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/design/components/dashboard/_campaign-table.scss';
</style>
