import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '../store'
import { UnifiedLeaderboard as UnifiedLeaderboardModel } from '@/src/models/unified-leaderboard'
import { Leaderboard as apiLeaderboard } from '@state/api'
import { LeaderboardTypes } from '../api/graphql'

@Module({
  namespaced: true,
  store,
  dynamic: true,
  name: 'leaderboard-unified',
})
class UnifiedLeaderboard extends VuexModule {
  currentLeaderboard?: UnifiedLeaderboardModel | null = null


  get enabledLeaderboardTypes(): LeaderboardTypes[] {
    return this.currentLeaderboard?.leaderboardTypes || []
  }

  get leaderboards() {
    return this.currentLeaderboard?.leaderboards
  }

  // Mutations
  @Mutation
  cacheLeaderboard(leaderboardResponse) {
    this.currentLeaderboard = new UnifiedLeaderboardModel(leaderboardResponse.data)
  }

  @Action
  async fetchLeaderboard() {
    const response = await apiLeaderboard.leaderboard()
    this.cacheLeaderboard(response.data)
  }
}

export default getModule(UnifiedLeaderboard)
