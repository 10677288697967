import { lmsApiPath } from '@/src/app.config'
import { axios, determineBaseURL } from '@state/api/client/axiosClient'

const baseURL = determineBaseURL(lmsApiPath)

export function init(enrollmentId: number) {
  return axios.get('/init.json', config(enrollmentId))
}

export function commit(enrollmentId: number, data: { [key: string]: any }) {
  return axios.post('/commit.json', { data }, config(enrollmentId))
}
export function terminate(enrollmentId: number, data: { [key: string]: any }) {
  return axios.post('/terminate.json', { data }, config(enrollmentId))
}

function config(enrollmentId: number) {
  return {
    params: { enrollment_id: enrollmentId },
    baseURL,
  }
}
