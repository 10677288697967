import Enrollment from './enrollment'
import {
  EnrollmentTypes,
  Enrollment as GqlEnrollment,
  StoreItem,
  Topic,
} from '@state/api/graphql'

export default class OptionEnrollment extends Enrollment {
  storeItem: any = null

  constructor(
    enrollment?: GqlEnrollment,
    languageOrder?: string[],
    storeItem?: StoreItem,
    rating?: number,
    contentSource?: string,

  ) {
    super(enrollment, languageOrder, storeItem, rating)
    if (storeItem) {
      if (this.languageOrder.length === 0 && languageOrder) {
        this.languageOrder = languageOrder
      }
      this.storeItem = storeItem
      this.title = this.storeItem.title
      this.description = this.storeItem.description
      if (enrollment) {
        this.enrollmentType = enrollment.type == EnrollmentTypes.KnowledgeRefresher ? 'KnowledgeRefresher' :
        (this.storeItem.type === 'Assessment' ? 'Assessment' : 'Course')
      }

      this.duration = this.storeItem.expectedDurationInMinutes
      this.publisher = this.storeItem.publisher?.name
      this.publishedAt = this.storeItem.publishedAt
      this.allowSurveyComments = true
      this.allowSurveys = true
      this.originalLanguageOptions = this.storeItem.translations.map(tr =>
        this.mapTranslation(tr),
      )
      this.storeItem = storeItem
      this.uuid = storeItem.uuid
      this.coverImage = this.storeItem.artwork.coverImage
      this.contentSource = contentSource || 'modstore'
      this.headerImage = this.storeItem.artwork.headerImage || undefined
    }
  }

  get topics(): Topic[] {
    return this.storeItem.topics || []
  }

  get analyticsEnrollmentType() {
    return this.storeItem.type.replace(/\s/g, '') // Remove spaces because of CCM
  }

  get defaultImage() {
    return require('@assets/images/enrollment-default.svg?inline')
  }

  get defaultHeaderImage() {
    return require('@assets/images/header-placeholder.svg?inline')
  }

  get hasNotEnrolled() {
    return true
  }

  get showEnrollmentTimeSpent(): boolean {
    return false
  }

  get subscriptions() {
    return this.storeItem?.subscriptions?.map(sub => sub.code)
  }
}
