export default class Badge {
  id: number
  type: string
  title: string
  description: string
  icon: string
  acknowledgedAt: Date | null
  viewedAt: Date | null
  earnedAt: Date | null

  constructor(data: any) {
    this.id = data.id
    this.type = data.type
    this.title = data.title
    this.description = data.description
    this.icon = data.icon
    this.acknowledgedAt = data.acknowledgedAt
      ? new Date(data.acknowledgedAt)
      : null
    this.viewedAt = data.viewedAt ? new Date(data.viewedAt) : null
    this.earnedAt = data.earnedAt ? new Date(data.earnedAt) : null
  }
}
