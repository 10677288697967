<template>
  <!-- eslint-disable vue/no-v-html -->
  <span
    :class="{ branded: branded }"
    class="svg"
    v-html="branded ? brandedSvg : svg" />
  <!--eslint-enable-->
</template>

<script>
export default {
  props: {
    branded: { type: Boolean, required: false, default: false },
    svg: { type: String, required: true },
    svgClass: { type: String, required: false, default: '' },
    id: { type: String, required: true, default: 'inline-svg' },
  },
  computed: {
    brandedSvg() {
      const xmlDoc = new DOMParser().parseFromString(this.svg, 'text/xml')
      try {
        xmlDoc.firstChild.setAttribute('class', `branded ${this.svgClass}`)
        xmlDoc.firstChild.setAttribute('id', `svg-${this.id}`)
        xmlDoc.firstChild.setAttribute('role', 'img')
        xmlDoc.firstChild.setAttribute('aria-label', `svg-${this.id}`)
        xmlDoc.firstChild.setAttribute('alt', '')
        // push SVG data into the dom element
        return new XMLSerializer().serializeToString(xmlDoc.documentElement)
      } catch (e) {
        return null
      }
      // mark the SVG as being branded
    },
  },
}
</script>
