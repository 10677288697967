import EnrollmentStore from '@state/modules/enrollments'
import SessionStore from '@state/modules/session'
import findIndex from 'lodash/findIndex'

const TRAINING_PATH: string = 'training'
const LIBRARY_PATH: string = 'library'
export enum lxSources {
  LIBRARY = 'library'
}
export enum enrollmentVerificationState {
  OPTIONAL = 'optional',
  MANDATORY = 'mandatory'
}
let verifiedEnrollment: {
  verified: lxSources,
  enrollmentId: number,
  mandatory?: boolean,
  optional?: boolean
} | null = null

export async function redirectVerifiedEnrollment(next: any, enrollmentId: number, uuid: string | number): Promise<Function> {
  const hasMandatoryEnrollments: boolean = !!EnrollmentStore.enrollments.length
  const hasOptionalEnrollments: boolean = !!EnrollmentStore.optionalEnrollments.length
  const mandatory: boolean = hasMandatoryEnrollments
    ? hasEnrollmentIndex(EnrollmentStore.enrollments, { enrollmentId })
    : await verifyEnrollmentMandatoryStatus(enrollmentId)
  if (mandatory) {
    verifiedEnrollment = {
      verified: lxSources.LIBRARY,
      mandatory: true,
      enrollmentId: enrollmentId,
      optional: false,
    }
    return next(enrollmentRedirectObj(TRAINING_PATH, enrollmentId, uuid))
  }
  const optional = hasOptionalEnrollments
    ? hasEnrollmentIndex(EnrollmentStore.enrollments, { enrollmentId })
    : await verifyEnrollmentOptionalStatus(enrollmentId)
  if (optional) {
    verifiedEnrollment = {
      verified: lxSources.LIBRARY,
      mandatory: false,
      enrollmentId: enrollmentId,
      optional: true,
    }
    return next(enrollmentRedirectObj(LIBRARY_PATH, enrollmentId, uuid))
  }
  return next({ name: 'dashboard', params: { enrollmentError: true } })
}

async function verifyEnrollmentMandatoryStatus(enrollmentId: number): Promise<boolean> {
  const isAMandatoryEnrollments = EnrollmentStore.fetchEnrollments().then(() => {
    const mandatory: boolean = hasEnrollmentIndex(EnrollmentStore.enrollments, { enrollmentId })
    return mandatory
  })
  return isAMandatoryEnrollments
}

async function verifyEnrollmentOptionalStatus(enrollmentId: number): Promise<boolean> {
  if (!SessionStore?.optionalTrainingEnabled) {
    return false
  }
  const isAnOptionalEnrollments = EnrollmentStore.fetchOptionalEnrollments({ per: 100, page: 1 }).then(() => {
    const optional: boolean = hasEnrollmentIndex(EnrollmentStore.optionalEnrollments, { enrollmentId })
    return optional
  })
  return isAnOptionalEnrollments
}

function enrollmentRedirectObj(path: string, enrollmentId: number, uuid: string | number) {
  return {
    path: `/${path}/details/${enrollmentId}`,
    query: {
      uuid: uuid,
    },
  }
}

function hasEnrollmentIndex(enrollmentArray, enrollment): boolean {
  const i = findIndex(enrollmentArray, e => e.enrollmentId === enrollment.enrollmentId)
  return (i !== -1) ? true : false
}

// link has already been verified
export function enrollmentHasBeenVerified(enrollmentState: enrollmentVerificationState, enrollmentId: string, source?: lxSources): boolean {
  // link does not come from external source i.e email or manual URL manipulation
  if (source === lxSources.LIBRARY) return true
  if (!verifiedEnrollment) return false
  if (verifiedEnrollment?.[enrollmentState] && parseInt(enrollmentId) === verifiedEnrollment.enrollmentId) return true
  return false
}
