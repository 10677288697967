<script>
import { Chart } from 'highcharts-vue'
export default {
  components: { highcharts: Chart },
  props: { chartData: { type: Array, default: () => [] } },
  computed: {
    getChartOptions() {
      return {
        chart: {
          type: 'pie',
          height: 111,
          borderRadius: 25,
          spacingTop: 0,
          spacingBottom: 0,
        },
        plotOptions: {
          pie: {
            innerSize: '55%',
            size: '100%',
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
          },
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          verticalAlign: 'middle',
          margin: 0,
          itemMarginTop: 5,
          itemMarginBottom: 5,
          itemStyle: {
            color: '#7C7C80',
            fontWeight: 'regular',
            fontSize: '13px',
          },
        },
        series: [
          {
            type: 'pie',
            name: '',
            data: this.chartData,
          },
        ],
        colors: [
          this.colorPrimary,
          this.colorPrimaryHalfOpacity,
          this.colorPrimaryLightenMore,
        ],
        tooltip: {
          headerFormat: '<table>',
          pointFormat: `<tr><td style="padding:0">
                {point.name}: </td>
                <td style="padding:0"><b>{point.y}</b></td></tr>`,
          footerFormat: '</table>',
          shared: false,
          useHTML: true,
        },
        responsive: {
          rules: [
            {
              chartOptions: {
                legend: {
                  align: 'center',
                  layout: 'horizontal',
                  verticalAlign: 'bottom',
                },
              },
              condition: {
                maxWidth: 290,
              },
            },
          ],
        },
        title: {
          text: null,
        },
        credits: {
          enabled: false,
        },
      }
    },
  },
}
</script>

<template>
  <span qa-id="incomplete-training-graph">
    <highcharts :options="getChartOptions" />
  </span>
</template>
