
























import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import SessionStore from '@src/state/modules/session'
import { myDashboard } from '@src/lib/analytics/events/my-dashboard'

@Component
export default class MessagesTiles extends Vue {
  get unreadMessageCount() {
    return SessionStore.unreadMessagesCount
  }

  get noUnreadMessages() {
    return this.unreadMessageCount === 0
  }

  viewMessages() {
    this.$analytics.trackEvent(myDashboard.viewMessagesButton)
    this.$router.push({ name: 'messages' })
  }
}
