import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import Vue from 'vue'
import { getLocale } from '@src/locale'

function lenientlyParseDate(date) {
  if (typeof date !== 'string') {
    return date
  }
  const isoDate = parseISO(date)
  // isoDate returns an "Invalid Date" object which is Date, but also a NaN
  // :heart: Javascript :heart:
  if (isoDate instanceof Date && isFinite(isoDate)) {
    return isoDate
  }
  return new Date(date) // compatibility with old badge date-times.
}

export default function formatDate(date, explicitFormat) {
  const parsed = lenientlyParseDate(date)
  return format(parsed, explicitFormat || 'PP', {
    locale: getLocale().dateFnsLocale,
  })
}

// Also add filter so we can do {{ badge.date_earned | formatDate }}
Vue.filter('formatDate', function (date) {
  return formatDate(date)
})
