







import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class EnrollmentTileBadges extends Vue {
  @Prop() readonly status!: string

  private get isComplete() {
    return this.status === 'completed'
  }

  private get isInProgress() {
    return this.status === 'in_progress' || this.status === 'do_policy'
  }
}
