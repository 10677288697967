












































import { Vue, Component, Prop, Inject } from 'vue-property-decorator'
import Course from '@src/models/course'
import Policy from '@src/models/policy'
import EnrollmentTileBadges from './enrollment-tile-badges.vue'
import EnrollmentTileInfoList from './enrollment-tile-info-list.vue'
import truncate from '@mixins/truncate'
import OptionEnrollment from '@src/models/optional-enrollment'
import { lxSources } from '../../../utils/enrollments'

@Component({
  components: { EnrollmentTileBadges, EnrollmentTileInfoList },
  mixins: [truncate],
})
export default class EnrollmentTile extends Vue {
  @Inject('isRTL') rtl!: boolean | undefined
  @Prop() enrollment!: Course | Policy | OptionEnrollment
  @Prop() selectedLanguage
  private truncate // mixin

  private get enrollmentImage() {
    return this.enrollment?.coverImage
  }

  private get enrollmentTitleTruncated() {
    return this.truncate(
      this.selectedLanguage.title || this.enrollment?.title,
      50
    )
  }

  get enrollmentRedirect() {
    return {
      name: 'library_detail_view',
      params: { id: this.enrollment?.id },
      query: { uuid: this.enrollment?.uuid, source: lxSources.LIBRARY },
    }
  }

  get viewDetailsAria() {
    return `${this.$t('enrollment.actions.view_details')} ${
      this.enrollment.title
    }`
  }
}
