



































import { Vue, Component, Prop } from 'vue-property-decorator'
import LearnerAppModal from '@/src/components/learner-app/learner-app-modal.vue'
import LearnerAppImageModal from '@/src/components/learner-app/learner-app-image-modal.vue'
import LearnerAppFunImageModal from '@/src/components/learner-app/learner-app-fun-image-modal.vue'
import SessionStore from '@src/state/modules/session'
import { LX } from '@src/lib/analytics/events/LearnerExperience'

const ONE_HOUR = 1000 * 60 * 60 * 1

@Component({
  components: {
    LearnerAppModal,
    LearnerAppImageModal,
    LearnerAppFunImageModal,
  },
})
export default class MobileNotification extends Vue {
  @Prop() banner!: string

  private refreshDeviceLinkTokenInterval: any | null = null
  private qrCodeWidth: number = 150
  private showLinkTokenLoadingIndicator: boolean = false

  mounted() {
    this.$analytics.trackEvent(LX.showSignInBanner, {
      Layout: this.banner,
    })

    this.refreshDeviceLinkToken()
    this.refreshDeviceLinkTokenInterval = setInterval(
      this.refreshDeviceLinkToken,
      ONE_HOUR
    )
  }

  onHide() {
    this.$cookie.set('mobile-modal-auto', 'true', { expires: '1D' })
  }

  beforeDestroy() {
    if (this.refreshDeviceLinkTokenInterval) {
      clearInterval(this.refreshDeviceLinkTokenInterval)
    }
  }

  async refreshDeviceLinkToken() {
    this.showLinkTokenLoadingIndicator = true
    await SessionStore.refreshDeviceLinkToken()
    this.showLinkTokenLoadingIndicator = false
  }

  get linkedDevices() {
    if (
      SessionStore.currentUser === null ||
      SessionStore.currentUser.linkedDevices === null
    ) {
      return []
    }
    return SessionStore.currentUser.linkedDevices
  }

  get deviceLinkUri() {
    if (
      SessionStore.currentUser === null ||
      SessionStore.currentUser.deviceLinkToken === null
    ) {
      return ''
    }
    return `knowbe4://device-link/${SessionStore.currentUser.deviceLinkToken}${this.experimentUrl}`
  }

  get experimentUrl() {
    if (this.$launchDarkly.get('mobile-linking-new-link', 'false')) {
      return `/${this.banner}`
    }

    return ''
  }
}
