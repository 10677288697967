import * as LDClient from 'launchdarkly-js-client-sdk'
import SessionStore from '@state/modules/session'

export default class LaunchDarkly {
  private ldclient: any = null
  private static _instance: LaunchDarkly

  public static getInstance() {
    return this._instance || (this._instance = new this())
  }

  private getClient() {
    try {
      // LD freaks out if the API key is empty
      const clientSideId = process.env.VUE_APP_LAUNCHDARKLY_CLIENT_SIDE_ID
      if (!clientSideId) {
        return null
      }

      // Can't init before we have a session
      if (!SessionStore.currentUser?.accountId) {
        return null
      }

      const site = window.location.hostname
      const key =
        process.env.NODE_ENV === 'production'
          ? `${site}:${SessionStore.currentUser?.accountId}`
          : `${process.env.VUE_APP_USER}:${SessionStore.currentUser.accountId}`
      const account = {
        key,
        custom: this.customAttrs(
          site,
          SessionStore.currentUser,
          SessionStore.currentPartnerAdmin,
        ),
      }

      return LDClient.initialize(clientSideId, account) // will be a "user" in LD UI
    } catch (err) {
      // If LD doesn't init, we don't want to take down the whole page
      return null
    }
  }

  private customAttrs(site, currentUser, currentPartnerAdmin) {
    return {
      environment: site,
      id: currentUser?.accountId as number,
      reseller_id: currentPartnerAdmin?.id as number,
      subscription_level: currentUser?.subscriptionLevel,
      billing_type: currentUser?.billingType,
      primary_domain: currentUser?.domain,
      // allowed_domains: account.allowedDomains,
      // user_count: account.userCount as number,
      // has_own_lms: account.hasOwnLms,
      // number_of_seats: account.numberOfSeats as number,
      // region: account.region,
      default_locale: currentUser?.defaultLocale,
      // has_phishing: account.hasPhishing,
      // has_training: account.hasTraining,
      // phishalert_enabled: account.phishalertEnabled,
      // saml_enabled: account.samlEnabled,
      // ldap_enabled: account.ldapEnabled,
      // scim_status: account.scimStatus,
      // has_passless: account.hasPassless,
      // percentage_users_phished: account.percentageUsersPhished as number,
      // percentage_users_trained: account.percentageUsersTrained as number,
      test_account: currentUser?.allowAnalytics,
    }
  }

  public initialize() {
    return new Promise<void>(resolve => {
      if (this.ldclient) {
        return resolve()
      }
      // Otherwise we have to init the client,
      // then resolve the flag when the client is ready
      const _ldclient = this.getClient()
      if (_ldclient) {
        // In case LaunchDarkly doesn't init properly, we'll fall back to defaults after 5 seconds
        const timeoutHandle = setTimeout(() => {
          resolve()
        }, 5000)
        _ldclient.on('ready', () => {
          clearTimeout(timeoutHandle)
          this.ldclient = _ldclient
          resolve()
        })
      } else {
        resolve()
      }
    })
  }

  // Returns a feature flag value or nothing
  public get(featureKey, defaultValue = false) {
    if (this.ldclient) {
      return this.ldclient.variation(featureKey, defaultValue)
    } else {
      return defaultValue
    }
  }

  public async onReady() {
    const client = this.getClient()
    if (!client) return
    await client.waitUntilReady()
  }
}
