















import Policy from '@src/models/policy'
import { Component, Prop, Vue, Inject } from 'vue-property-decorator'

@Component
export default class EnrollmentTileInfoListPolicy extends Vue {
  @Prop() readonly enrollment!: Policy
  @Prop() readonly timeSpent: any
  @Prop() readonly completedAt: any
  @Prop() readonly isComplete: any
  @Prop() readonly contentType!: string
  @Prop() readonly enrollmentTimeSpent!: string
  @Inject('isRTL') rtl: boolean | undefined

  private get getTimeSpent(): string | null {
    return this.enrollment?.isInProgress ? this.timeSpent : null
  }

  private get getEnrollmentTimeSpent(): string | null {
    return this.enrollment?.isComplete ? this.enrollmentTimeSpent : null
  }

  private get getCompletedAt(): string | null {
    return this.enrollment?.isComplete ? this.completedAt : null
  }
}
