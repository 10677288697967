<script>
export default {
  props: {
    enrollmentComplete: { type: Boolean, default: false },
  },
}
</script>

<template>
  <svg
    :class="{ branded: enrollmentComplete }"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="30px"
    height="30px"
    version="1.1"
    viewBox="0 0 30 30"
    focusable="false">
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1">
      <g>
        <circle
          cx="15.0000002"
          cy="15.0000002"
          r="14.1891892"
          stroke="#D2D2D3"
          stroke-width="0.810810811" />
        <g
          id="primary"
          fill="#D2D2D3"
          transform="translate(5.918919, 5.918919)">
          <circle cx="9.08108108" cy="9.08108108" r="8.67567568" />
        </g>
        <g
          v-if="enrollmentComplete"
          fill="#FFF"
          transform="translate(9.810811, 9.810811)">
          <path
            d="M10.0853795,2.62834821 C10.1999634,2.74293212 10.2572545,2.88206763 10.2572545,3.04575893 C10.2572545,3.20945022 10.1999634,3.34858574 10.0853795,3.46316964 L4.80636161,8.7421875 C4.6917777,8.85677141 4.55264219,8.9140625 4.38895089,8.9140625 C4.2252596,8.9140625 4.08612408,8.85677141 3.97154018,8.7421875 L0.914620536,5.68526786 C0.800036629,5.57068395 0.742745536,5.43154844 0.742745536,5.26785714 C0.742745536,5.10416585 0.800036629,4.96503033 0.914620536,4.85044643 L1.74944196,4.015625 C1.86402587,3.90104109 2.00316138,3.84375 2.16685268,3.84375 C2.33054397,3.84375 2.46967949,3.90104109 2.58426339,4.015625 L4.38895089,5.82645089 L8.41573661,1.79352679 C8.53032051,1.67894288 8.66945603,1.62165179 8.83314732,1.62165179 C8.99683862,1.62165179 9.13597413,1.67894288 9.25055804,1.79352679 L10.0853795,2.62834821 Z" />
        </g>
      </g>
    </g>
  </svg>
</template>
