import chroma from 'chroma-js'

export default {
  computed: {
    brand: function () {
      try {
        return this.$store.getters['auth/currentUser'].brand
      } catch (err) {
        // This is a fallback for tests so we don't have to mock branding
        // for every unit test. Branding probalby needs to be
        // rearchitected to properly fix this
        return {
          primary: '#F35800',
          logo: '',
        }
      }
    },
    svgFillColor: function () {
      return this.brand.primary
    },
    svgForeGroundColor: function () {
      if (chroma(this.brand.primary).luminance() > 0.5) {
        return chroma(this.brand.primary).luminance(0.5).hex()
      }
      return this.brand.primary
    },
    svgWhiteForeGroundColor: function () {
      return this.contrastedBWColor(this.colorPrimary)
    },
    colorPrimary: function () {
      return this.brand.primary
    },
    colorPrimaryDarken() {
      return chroma(this.colorPrimary).darken(0.4).hex()
    },
    colorPrimaryDarkenMore() {
      return chroma(this.colorPrimary).darken(2.6).hex()
    },
    colorPrimaryLighten() {
      return chroma(this.colorPrimary).alpha(0.15).css()
    },
    colorPrimaryLightenNonAlpha() {
      return chroma(this.colorPrimary).darken(-0.3).hex()
    },
    colorPrimaryLightenMoreNonAlpha() {
      return chroma(this.colorPrimary).darken(-0.6).hex()
    },
    colorPrimaryLightenMostNonAlpha() {
      return chroma(this.colorPrimary).darken(-0.9).hex()
    },
    colorPrimaryLightenMore() {
      return chroma(this.colorPrimary).alpha(0.1).css()
    },
    colorPrimaryHalfOpacity() {
      return chroma(this.colorPrimary).alpha(0.5).css()
    },
    brandedBgWithContrastedFg() {
      return {
        background: this.colorPrimary,
        color: this.contrastedBWColor(this.colorPrimary),
      }
    },
  },
  methods: {
    contrastedColor(fgColor, backgroundColor) {
      // Returns contrasted fg color relative to the background
      const bgLuminance = chroma(backgroundColor).luminance()
      const fgLuminance = chroma(fgColor).luminance()
      const difference = bgLuminance - fgLuminance
      if (difference > 0.6) {
        // If difference is pretty big, don't do anything to it
        return fgColor
      }
      return difference > -0.1
        ? chroma(fgColor)
            .darken(difference ^ 1.9)
            .hex()
        : chroma(fgColor)
            .brighten(difference ^ 1.1)
            .hex()
    },
    contrastedBWColor(bgColor) {
      // Returns black or white depending on background color brightness
      return chroma(bgColor).luminance() < 0.5 ? '#fff' : '#3e3e3f'
    },
    contrastedPrimaryBlackColor(bgColor) {
      // Returns primary or black depending on primary color brightness
      return chroma(bgColor).luminance() < 0.5 ? this.colorPrimary : '#3e3e3f'
    },
    highlightColor: function () {
      // Returns solid or transparent color depending on primary color brightness
      if (chroma(this.brand.primary).luminance() < 0.5) {
        return chroma(this.colorPrimary).alpha(0.15).css()
      }
      return this.colorPrimary
    },
    setupBrandingCssVariables() {
      const css = `
      :root {
        --brand-color-primary: ${this.colorPrimary};
        --brand-color-primary-text: ${this.contrastedBWColor(
          this.colorPrimary
        )};
        --brand-color-primary-lighten: ${this.colorPrimaryLighten};
        --brand-color-primary-lighten-non-alpha: ${
          this.colorPrimaryLightenNonAlpha
        };
        --brand-color-primary-lighten-more-non-alpha: ${
          this.colorPrimaryLightenMoreNonAlpha
        };
        --brand-color-primary-lighten-most-non-alpha: ${
          this.colorPrimaryLightenMostNonAlpha
        };
        --brand-color-primary-darken: ${this.colorPrimaryDarken};
        --brand-color-primary-half-opacity: ${this.colorPrimaryHalfOpacity};
      }
      `
      const style =
        document.querySelector("[data-type='theme']") ||
        document.createElement('style')
      style.type = 'text/css'
      style.dataset.type = 'theme'
      style.appendChild(document.createTextNode(css))
      document.getElementsByTagName('head')[0].appendChild(style)
    },
    addBranding() {
      // eslint-disable
      const css = `
      body .btn-primary {
        color: ${this.contrastedBWColor(this.colorPrimary)} !important;
        background-color: ${this.colorPrimary} !important;
        border-color: ${this.colorPrimaryDarken} !important;
        font-size: 1.125em;
        font-weight: 600;
      }
      body .btn-secondary {
        font-size: 1.125em;
        font-weight: 600;
      }
      body .btn-secondary.disabled, .btn-secondary:disabled {
        color: #6B6B6B;
        background-color: #EDEDEE;
      }
      body .btn.disabled {
        opacity: 0.65;
      }
      body .btn:focus {
        box-shadow: 0 0 0 0.2em ${this.colorPrimaryHalfOpacity} !important;
      }
      body *:focus {
        outline: ${this.colorPrimaryHalfOpacity} solid 0.2em;
      }
      body .brand-highlight {
        color: ${this.contrastedBWColor(this.colorPrimary)} !important;
        background-color: ${this.colorPrimary} !important;
        border-color: ${this.colorPrimary} !important;
      }
      body .light-brand-highlight {
        color: ${this.contrastedPrimaryBlackColor(
          this.colorPrimary
        )} !important;
        background-color: ${this.highlightColor(this.colorPrimary)} !important;
      }
      body .light-brand {
        background-color: ${this.colorPrimaryLighten} !important;
      }
      body .light-hover:hover {
        background-color: ${this.colorPrimaryLightenMore} !important;
      }
      body .btn-primary:hover {
        background-color: ${this.colorPrimaryDarken} !important;
      }
      body .alert-primary {
        color: ${this.colorPrimaryDarken} !important;
        background-color: ${this.colorPrimaryLightenMore} !important;
        border-color: ${this.colorPrimaryLighten} !important;
      }
      body .complete-label {
        color: ${this.contrastedBWColor(this.colorPrimary)} !important;
        background-color: ${this.colorPrimary} !important;
      }
      body .tile-badge {
        color: ${this.contrastedBWColor(this.colorPrimary)} !important;
        background-color: ${this.colorPrimaryDarken} !important;
      }
      body .card-footer {
        background-color: ${this.colorPrimaryLightenMore} !important;
      }
      body .custom-control-input:checked ~ .custom-control-label::before {
         background-color: ${this.colorPrimary}!important;
       }
      body .card-footer a {
        color: ${this.contrastedColor(
          this.colorPrimary,
          this.colorPrimaryLightenMore
        )} !important;
      }
      body a.branded {
        color: ${this.contrastedColor(this.colorPrimary, '#FFF')} !important;
        cursor: pointer !important;
      }
      body .text-primary {
        color: ${this.contrastedColor(this.colorPrimary, '#FFF')} !important;
      }
      body .btn-link:hover {
        color: ${this.colorPrimaryDarken} !important;
      }
      body .btn-link {
        color: ${this.contrastedColor(this.colorPrimary, '#FFF')};
      }
      body .badge-primary {
        color: ${this.contrastedBWColor(this.colorPrimary)} !important;
        background-color: ${this.colorPrimary} !important;
      }
      body #nprogress .bar {
        background: ${this.colorPrimary} !important;
      }
      body #nprogress .peg {
        box-shadow: 0 0 10px ${this.colorPrimary},
        0 0 5px ${this.colorPrimary} !important;
      }
      body .progress-bar {
        background-color:${this.colorPrimary} !important;
      }
      svg.branded #primary * {
        fill: ${this.svgFillColor} !important;
      }
      svg.branded [fill="#fff"] *, /* IE11 changes #FFF to #fff and then ignores the styles */
      svg.branded [fill="#FFF"] *
      {
        fill: ${this.svgWhiteForeGroundColor} !important;
      }
      svg.branded .foreground * {
        fill: ${this.svgForeGroundColor} !important;
      }
      .snotify-success,
      .snotify-info,
      .snotify-warning,
      .snotify-error{
        border-left: 4px solid ${this.colorPrimary} !important;
      }
      .introjs-button.introjs-nextbutton,
      .introjs-button.introjs-donebutton{
        color: ${this.contrastedBWColor(this.colorPrimary)} !important;
        background-color: ${this.colorPrimary} !important;
        border: 1px solid ${this.colorPrimaryDarken} !important;
      }
      .introjs-button.introjs-nextbutton:hover,
      .introjs-button.introjs-donebutton:hover {
        color: ${this.contrastedBWColor(this.colorPrimary)} !important;
        background-color: ${this.colorPrimaryDarken} !important;
        border: 1px solid ${this.colorPrimaryDarken} !important;
      }
      .introjs-button.introjs-skipbutton {
        color: ${this.contrastedColor(this.colorPrimary, '#FFF')};
      }
      body .branded-box {
        background-color: ${this.colorPrimaryLightenMore} !important;
        border: 1px solid ${this.colorPrimaryLighten} !important;
      }
      body .branded-bg {
        background: linear-gradient(90.54deg, ${this.colorPrimary} 0%, ${
        this.colorPrimaryDarkenMore
      } 100%) !important;
        background-color: ${this.colorPrimaryLightenMore} !important;
      }
      body .branded-bg.rtl {
        background: linear-gradient(90.54deg, ${
          this.colorPrimaryDarkenMore
        } 0%, ${this.colorPrimary} 100%) !important;
        background-color: ${this.colorPrimaryLightenMore} !important;
      }
      `
      // eslint-enable
      this.setupBrandingCssVariables()

      // Write branded styles to body
      const style =
        document.querySelector("[data-type='theme']") ||
        document.createElement('style')
      style.type = 'text/css'
      style.dataset.type = 'theme'
      style.appendChild(document.createTextNode(css))
      document.getElementsByTagName('head')[0].appendChild(style)
    },
  },
}
