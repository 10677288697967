




















import { Component, Prop, Vue, Inject } from 'vue-property-decorator'

@Component
export default class EnrollmentTileInfoListCourse extends Vue {
  @Prop() readonly enrollment: any
  @Prop() readonly duration!: string
  @Prop() readonly timeSpent!: string
  @Prop() readonly completedAt!: string
  @Prop() readonly isComplete!: boolean
  @Prop() readonly enrollmentTimeSpent!: string
  @Inject('isRTL') rtl: boolean | undefined

  private get isCourse(): boolean {
    return this.enrollment.isCourse
  }

  private get certificateUrl() {
    return this.enrollment.certificateUrl
  }

  private get getDuration(): string | null {
    return !this.enrollment.isComplete ? this.duration : null
  }

  private get getTimeSpent(): string | null {
    return this.enrollment.isInProgress ? this.timeSpent : null
  }

  private get getEnrollmentTimeSpent(): string | null {
    return this.enrollment.isComplete || this.enrollment.doPolicy
      ? this.enrollmentTimeSpent
      : null
  }

  private get getCompletedAt(): string | null {
    return this.enrollment.isComplete ? this.completedAt : null
  }
}
