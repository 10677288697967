<template lang="pug">
  a.d-inline-block.float-left(
    :aria-label="$t('enrollment.details.view_details')"
    :aria-describedby="`tooltip-description-${enrollmentId}`"
    href="#"
    role="tooltip"
    @click.prevent="toggleTooltip")
    div.btn-link.mb-3(:id="`view-details-tooltip-${enrollmentId}`")
      fa.mx-2(icon="search")
      span {{ $t('enrollment.details.view_details') }}
      b-tooltip.view-details(
        :triggers="getTooltipTriggers()"
        :target="`view-details-tooltip-${enrollmentId}`"
        :interactive="true")
        div.mb-1(v-if="enrollmentExpiresAt")
          fa.mx-1.icon(icon="calendar")
          span
            strong {{ $t('enrollment.details.due_date') }}
            |  {{ enrollmentDueDate }}
        div.mb-1
          fa.mx-1.icon(icon="check-circle")
          span
            strong {{ enrollmentCompletedType }}
            |  {{ enrollmentCompletedDate }}
        div(v-if="enrollmentTimeSpent")
          fa.mx-1.icon(icon="hourglass")
          span
            strong {{ $t('enrollment.details.time_spent') }}
            |  {{ enrollmentTimeSpent }}
        desc(:id="`tooltip-description-${enrollmentId}`")
          ul
            li(v-if="enrollmentExpiresAt") {{ $t('enrollment.details.due_date') }} {{ enrollmentDueDate }},
            li {{ enrollmentCompletedType }} {{ enrollmentCompletedDate }},
            li(v-if="enrollmentTimeSpent") {{ $t('enrollment.details.time_spent') }} {{ enrollmentTimeSpent }}
</template>

<script>
import secondsToTimeDistance from '@utils/seconds-to-time-distance'
import formatDate from '@utils/format-date'

export default {
  props: {
    enrollmentExpiresAt: { type: String, required: true },
    enrollmentTimeSpentInSeconds: { type: String, required: true },
    enrollmentType: { type: String, required: true },
    enrollmentCompletedAt: { type: String, required: true },
    enrollmentId: { type: Number, required: true },
  },
  data: () => ({
    tooltipShowing: false,
  }),
  computed: {
    enrollmentDueDate() {
      return formatDate(this.enrollmentExpiresAt)
    },
    enrollmentCompletedType() {
      switch (this.enrollmentType) {
        case 'Course':
          return this.$t('enrollment.details.completed_training')
        case 'Policy':
          return this.$t('enrollment.details.accepted_policy')
        case 'Assessment':
          return this.$t('enrollment.details.completed_assessment')
        default:
          return null
      }
    },
    enrollmentCompletedDate() {
      return this.enrollmentCompletedAt
        ? formatDate(this.enrollmentCompletedAt)
        : this.$t('common.n_a')
    },
    enrollmentTimeSpent() {
      return this.enrollmentTimeSpentInSeconds
        ? secondsToTimeDistance(this.enrollmentTimeSpentInSeconds)
        : ''
    },
  },
  methods: {
    isTouchScreen() {
      return !!(
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i)
      )
    },
    getTooltipTriggers() {
      return this.isTouchScreen() ? 'click' : 'hover click blur'
    },
    toggleTooltip() {
      if (this.tooltipShowing) {
        this.tooltipShowing = false
        this.$root.$emit(
          'bv::hide::tooltip',
          `view-details-tooltip-${this.enrollmentId}`
        )
      } else {
        this.tooltipShowing = true
        this.$root.$emit(
          'bv::show::tooltip',
          `view-details-tooltip-${this.enrollmentId}`
        )
      }
    },
  },
}
</script>

<style lang="scss">
@import 'src/design/components/enrollments/_enrollment-details.scss';
</style>
