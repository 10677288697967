import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core'
import * as icons from '@src/utils/font-awesome/icons'
export { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
export class IconList {
  static get list() {
    return Object.values(icons) as IconDefinition[]
  }

  static register() {
    library.add(...this.list)
  }
}
