import { mapState, mapGetters } from 'vuex'
import EnrollmentStore from '@state/modules/enrollments'
import UnifiedLeaderboardStore from '@/src/state/modules/unified_leaderboard'
import SessionStore from '@state/modules/session'
import { LeaderboardTypes, User } from '@gql/graphql'

export const authComputed = {
  ...mapState('auth', {
    isAdmin: (state: { [key: string]: any }) =>
      state.currentUser.administration_url &&
      state.currentUser.administration_url.length > 0,
    currentUser: (state: { [key: string]: any }) => state.currentUser,
    companyName: (state: { [key: string]: any }) =>
      state.currentUser.company_name,
    minPasswordLength: (state: { [key: string]: any }) =>
      state.currentUser.minimum_password_length || 8,
    userName: (state: { [key: string]: any }) =>
      state.currentUser.first_name && state?.currentUser?.last_name
        ? `${state.currentUser.first_name} ${state?.currentUser?.last_name}`
        : state.currentUser.email,
  }),
  ...mapGetters('auth', ['loggedIn', 'badgesEnabled']),
}

export const enrollmentsComputed = {
  enrollments: () => EnrollmentStore.enrollments,
  numberOfEnrollments: () => EnrollmentStore.numberOfEnrollments,
  noEnrollments: () => EnrollmentStore.noEnrollments,
  numberOfIncompleteEnrollments: () =>
    EnrollmentStore.numberOfIncompleteEnrollments,
  optionalEnrollments: () => EnrollmentStore.optionalEnrollments,
  numberOfOptionalEnrollments: () =>
    EnrollmentStore.numberOfOptionalEnrollments,
}

export const sessionComputed = {
  optionalTrainingEnabled: () => !!SessionStore.optionalTrainingEnabled,
}

export const unifiedLeaderboardComputed = {
  leaderboardEnabled: () => UnifiedLeaderboardStore.enabledLeaderboardTypes.length > 0,
  enabledLeaderboardTypes: () => UnifiedLeaderboardStore.enabledLeaderboardTypes,
  leaderboards: () => UnifiedLeaderboardStore.leaderboards,
  hasGroupLeaderboard: () => UnifiedLeaderboardStore.leaderboards?.some((leaderboard => {
    return leaderboard.leaderboardType === LeaderboardTypes.GroupLeaderboard && leaderboard.leaders.some(leader => leader.includesCurrentUser)
  })),
  hasIndividualLeaderboard: () => UnifiedLeaderboardStore.leaderboards?.some((leaderboard => {
    return leaderboard.leaderboardType === LeaderboardTypes.IndividualLeaderboard && leaderboard.leaders.some(leader => leader.includesCurrentUser)
  })),
  groupLeaderboard: () => UnifiedLeaderboardStore.leaderboards?.find(l => l.leaderboardType === LeaderboardTypes.GroupLeaderboard),
  individualLeaderboard: () => UnifiedLeaderboardStore.leaderboards?.find(l => l.leaderboardType === LeaderboardTypes.IndividualLeaderboard),
}

export function createUserName(
  email: User['email'],
  firstName?: User['firstName'],
  lastName?: User['lastName'],
): string {
  if (firstName?.trim() && !lastName) return firstName.trim()
  if (firstName && lastName) return `${firstName} ${lastName}`
  return email
}

export const leaderboardHelper = {
  levelNames: () => [
    'Diamond',
    'Jadeite',
    'Ruby',
    'Sapphire',
    'Topaz',
    'Beryl',
    'Emerald',
    'Amethyst',
    'Platinum',
    'Gold',
    'Silver',
    'Palladium',
    'Bronze',
  ],
  defaultLevel(leadersCount) {
    return this.levelNames()[this.calculateNumberOfLevels(leadersCount) - 1]
  },
  getLocalStorage: key => {
    return window.localStorage.getItem(key)
  },
  setLocalStorage: (key, content) => {
    window.localStorage.setItem(key, content)
  },
  hasLevelImproved(previousLevel, newLevel) {
    return (
      this.levelNames().indexOf(newLevel) <
      this.levelNames().indexOf(previousLevel)
    )
  },
  addLeagueInformationToLeaders(leadersArray, isLeague) {
    if (isLeague) {
      let currentLevelIdx = 0
      let currentLevel = this.levelNames()[currentLevelIdx]
      const levelSpaces = this.calculateSpacesPerLevel(
        leadersArray.length,
        this.calculateNumberOfLevels(leadersArray.length),
      )
      let currentLevelCount = 0
      let previousRank = 0
      let previousLevelRank = 0
      let levelRankTies = 0
      let zeroScoreInitiated = false
      const zeroLevel = this.defaultLevel(leadersArray.length)

      leadersArray.forEach(leader => {
        if (
          currentLevelCount + 1 > levelSpaces &&
          leader.rank !== previousRank &&
          leader.score !== 0
        ) {
          currentLevelCount = 0
          currentLevelIdx++
          previousLevelRank = 0
          levelRankTies = 0
          currentLevel = this.levelNames()[currentLevelIdx]
          if (!currentLevel) {
            currentLevel = this.defaultLevel(leadersArray.length)
          }
        }

        if (leader.score === 0) {
          if (!zeroScoreInitiated && zeroLevel !== currentLevel) {
            currentLevel = this.defaultLevel(leadersArray.length)
            currentLevelCount = 0
            currentLevelIdx = this.levelNames().indexOf(currentLevel)
            previousLevelRank = 0
            levelRankTies = 0
          } else {
            currentLevel = this.defaultLevel(leadersArray.length)
            currentLevelIdx = this.levelNames().indexOf(currentLevel)
          }
          zeroScoreInitiated = true
        }

        leader.level = currentLevel
        if (leader.score !== 0) {
          if (leader.rank === previousRank) {
            levelRankTies++
          } else {
            previousLevelRank += levelRankTies + 1
            levelRankTies = 0
          }

          leader.levelRank = previousLevelRank
          previousRank = leader.rank
          currentLevelCount++
        } else {
          leader.levelRank = previousLevelRank + 1
          currentLevelCount++
        }
      })
    } else {
      leadersArray.forEach(leader => {
        leader.levelRank = leader.rank
      })
    }
  },
  isLeague(leaderboardTypeFlag) {
    if (leaderboardTypeFlag !== 'league') {
      return false
    } else {
      return true
    }
  },
  calculateNumberOfLevels(leadersCount) {
    const aimPerLevel = 15
    if (leadersCount <= aimPerLevel) {
      return 1
    } else if (
      Math.ceil(leadersCount / aimPerLevel) >
      this.levelNames().length - 1
    ) {
      return this.levelNames().length
    }
    return Math.ceil(leadersCount / aimPerLevel)
  },
  calculateSpacesPerLevel(leadersCount, numberOfLevels) {
    return Math.ceil(leadersCount / numberOfLevels)
  },
}
