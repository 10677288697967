









import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class EmptyTeamTable extends Vue {
  @Prop({ required: true, default: {} }) selectedFilter: any
}
