import axios from 'axios'
import format from 'date-fns/format'
import loginRedirectPath from '@utils/login-redirect-path'
import { apiPath } from '@src/app.config'
import { de } from 'date-fns/locale'

// Allow base URL to be configured at build time (only VUE_APP_ vars are inlined)
const baseURL = determineBaseURL(apiPath)
if (baseURL) {
  axios.defaults.baseURL = baseURL
}

// This allows the AJAX requests to use a pre-existing session on KMSAT
// even if KMSAT is on a different host/port
// see https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#Requests_with_credentials
axios.defaults.withCredentials = true

axios.interceptors.request.use(config => {
  config.headers.CurrentTime = format(
    new Date(),
    "yyyy-MM-dd'T'HH:mm:ss.sss'Z'"
  ) // ISO8601 date
  config.headers['X-Requested-With'] = 'XMLHttpRequest'
  config.headers['X-CSRF-TOKEN'] = window.authenticity_token
  return config
})

export function determineBaseURL(apiPath) {
  if (process.env.VUE_APP_API_HOST) {
    return `${process.env.VUE_APP_API_HOST}${apiPath}`
  } else if (process.env.NODE_ENV === 'production') {
    return apiPath
  }
}

// If we get a 401 or 403 response from the API server, redirect to KMSAT login
function handleReject(error) {
  if (error.response && [401, 403].includes(error.response.status)) {
    return (window.location = loginRedirectPath())
  }
  if (error.message === 'Network Error') {
    return window.postMessage('NETWORK_ERROR', '*')
  }
  return Promise.reject(error)
}

axios.interceptors.response.use(response => response, handleReject)

export { axios }
