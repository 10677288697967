<script>
import { authComputed } from '@state/helpers'
export default {
  computed: {
    ...authComputed,
  },
}
</script>
<template>
  <b-container class="text-center">
    <base-inline-svg
      :id="'popup-blocker'"
      :branded="true"
      :svg="require('@assets/images/popup-blocker.svg?inline')" />
    <h5 class="mt-4">
      {{ $t('course.popup_prevention.blocker_preventing') }}
    </h5>
    <p class="text-muted">
      {{ $t('course.popup_prevention.blocker_instructions') }}<br>
      <b-button class="btn btn-primary mt-3" @click="$emit('launchRuntime')">
        {{ $t('enrollment.actions.start') }}<br>
      </b-button>
      <button
        class="btn btn-link btn-skip w-100"
        type="button"
        @click="$emit('scormPlayerDone')">
        {{ $t('common.cancel') }}
      </button>
    </p>
  </b-container>
</template>
