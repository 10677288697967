import Vue from 'vue'
import './iefix'
import App from './app.vue'
import router from '@router'
import store from '@state/store'
import '@components/_globals'
import VeeValidate from 'vee-validate'
import VueFloatLabel from 'vue-float-label'
import VueTreeselect from '@riophae/vue-treeselect'
import VueCookie from 'vue-cookie'
import Vue2Filters from 'vue2-filters'
import HighchartsVue from 'highcharts-vue'
import More from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsGauge from 'highcharts/modules/solid-gauge.js'
import i18n from './i18n'
import AnalyticsPlugin from '@src/plugins/analytics'
import LaunchDarklyPlugin from '@src/plugins/launch-darkly'
import { IconList, FontAwesomeIcon } from '@src/utils/font-awesome'
import Snotify from 'vue-snotify'
import {
  BootstrapVue,
  BCarousel,
  BCarouselSlide,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import '@src/filters'
// import { AirbrakeSetup } from '@src/airbrake'
import DetectMobile from '@src/plugins/mobileDetector'
import { datadogLogs } from '@datadog/browser-logs'

More(Highcharts)
HighchartsGauge(Highcharts)

const token = process.env.DATADOG_CLIENT_TOKEN
const mode = process.env.NODE_ENV

// https://docs.datadoghq.com/logs/log_collection/javascript/
if (token && mode === 'production') {
  datadogLogs.init({
    clientToken: token,
    site: 'datadoghq.com',
    service: 'popcorn-frontend',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100
    // beforeSend: (error) => {
    //   if ((error?.message || error?.toString())?.match(/Extension context invalidated/i))
    //     // this particular error is thrown by a buggy chrome extension, not our code
    //     return false
    // }
  })
  datadogLogs.setGlobalContextProperty('env', mode)
}

Vue.config.errorHandler = async function (err, vm, info) {
  const errorMsg = {
    error: err,
    params: { info: info },
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log(errorMsg)
  }
  const token = process.env.DATADOG_CLIENT_TOKEN
  if (token) {
    datadogLogs.logger.error(err.message, errorMsg, err)
  }
}

const validationConfig = {
  aria: false,
  events: 'blur',
  inject: false,
}

IconList.register()
Vue.component('Fa', FontAwesomeIcon)
Vue.use(VeeValidate, validationConfig)
Vue.use(VueFloatLabel)
Vue.use(DetectMobile)
Vue.component('Treeselect', VueTreeselect)
Vue.component('BCarousel', BCarousel)
Vue.component('BCarouselSlide', BCarouselSlide)
Vue.component('BDropdown', BDropdown)
Vue.component('BDropdownItem', BDropdownItem)

/* Vue.use(Modal)
Vue.use(Alert)
Vue.use(Card)
Vue.use(Layout)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Dropdown)
Vue.use(Navbar)
Vue.use(Form)
Vue.use(FormGroup)
Vue.use(FormInput)
Vue.use(FormSelect)
Vue.use(Table)
Vue.use(Badge)
Vue.use(Tooltip)
Vue.use(Progress)
Vue.use(FormCheckbox) */
const snotifyConfig = {
  toast: {
    showProgressBar: false,
    timeout: 5000,
    position: 'rightTop',
  },
}

Vue.use(BootstrapVue)
Vue.use(HighchartsVue)
Vue.use(Snotify, snotifyConfig)
Vue.use(VueCookie)
Vue.use(Vue2Filters)
Vue.use(AnalyticsPlugin)
Vue.use(LaunchDarklyPlugin)

// Don't warn about using the dev version of Vue in development
Vue.config.productionTip = process.env.NODE_ENV === 'production'
if (process.env.VUE_APP_IS_ONDEMAND === 'true') {
  Vue.config.devtools = true
}

require('es6-promise/auto')

window.i18n = i18n

const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

// If running inside Cypress
if (window.Cypress || process.env.NODE_ENV === 'development') {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`
  window.__app__ = app
}
