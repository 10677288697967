import secondsToTimeDistance from '@utils/seconds-to-time-distance'

export default {
  methods: {
    timeSpent(timeSpentInSeconds, enrollmentType) {
      if (!timeSpentInSeconds) return null
      return (this as any).$t('enrollment.time.time_spent', {
        time: secondsToTimeDistance(timeSpentInSeconds),
        enrollment_type: (this as any).$t(
          `enrollment.types.${enrollmentType?.toLowerCase()}_lowercase`,
        ),
      })
    },
  },
}
