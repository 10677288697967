// App-specific config

// Using CommonJS instead of ES2015+ export, because we also need to
// provide this object to Webpack in vue.config.js.
module.exports = {
  title: 'KnowBe4',
  description:
    'A nice interface that makes clients feel warm and fuzzy about corporate training.',
  apiPath: '/spa/learner',
  lmsApiPath: '/lms',
}
