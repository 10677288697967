<template>
  <div class="top-arrow" />
</template>

<script>
export default {}
</script>

<style lang="scss">
@import 'src/design/components/enrollments/enrollment-timeline/_top-arrow.scss';
</style>
