<script>
import Layout from '@layouts/main'

export default {
  page: {
    title: 'Page timeout',
    meta: [
      { name: 'description', content: 'The page timed out while loading.' },
    ],
  },
  components: { Layout },
}
</script>

<template>
  <layout>
    <h1 :class="$style.title">
      {{ $t('common.timeout_explanation') }}
    </h1>
  </layout>
</template>

<style lang="scss" module>
.title {
  text-align: center;
}
</style>
