









import { Component, Prop, Vue } from 'vue-property-decorator'
import trainingCompletedTileTimeTheme from './training-completed-tile-time-theme.vue'
import trainingCompletedTileConfettiTheme from './training-completed-tile-confetti-theme.vue'
import SessionStore from '@state/modules/session'

@Component({
  components: {
    trainingCompletedTileConfettiTheme,
    trainingCompletedTileTimeTheme,
  },
})
export default class TrainingCompletedTile extends Vue {
  @Prop() readonly enrollmentCount!: Number
  @Prop() readonly primaryColor!: String

  get trainingCompletedComponent() {
    // If library tab is not available, show confetti
    if (!SessionStore.aidaOptionalTrainingEnabled) {
      return trainingCompletedTileConfettiTheme
    }

    // For users whom have reduceMotion on, historically for performance reasons
    if (this.reduceMotion()) {
      return trainingCompletedTileConfettiTheme
    }

    return trainingCompletedTileTimeTheme
  }

  reduceMotion() {
    if (!this.$store.getters['auth/currentUser']) {
      return false
    }
    const reduceMotion = this.$store.getters['auth/currentUser'].reduce_motion
    return reduceMotion === undefined ? false : reduceMotion
  }
}
