<script>
import Clouds from '@components/dashboard/clouds.vue'
import SessionStore from '@state/modules/session'
export default {
  components: { Clouds },
  props: {
    motionReduced: { type: Boolean, default: false },
  },
  computed: {
    zIndexForAnimated() {
      return this.motionReduced ? 0 : 2
    },
    trainingCompletedMessage() {
      if (this.showLibraryTab) {
        return this.$t(
          'dashboard.training.all_training_completed_library_enabled'
        )
      }
      return this.$t('dashboard.training.all_training_completed')
    },
    showLibraryTab() {
      return SessionStore.aidaOptionalTrainingEnabled
    },
  },
}
</script>

<template>
  <div qa-id="gamified-training-complete">
    <div
      class="w-100 h-100 d-flex align-items-center justify-content-center position-absolute overflow-hidden light-brand">
      <div class="text-center plane-wrapper">
        <div
          :class="['plane mx-auto mb-3', { 'plane-animated': !motionReduced }]">
          <base-inline-svg
            :id="'plane'"
            :svg="require('@assets/images/dashboard/plane.svg?inline')"
            :branded="true" />
        </div>
        <p class="px-5">
          {{ trainingCompletedMessage }}
        </p>
      </div>
    </div>
    <clouds
      :motion-reduced="motionReduced"
      class="overflow-hidden h-100 w-100 position-absolute"
      :style="`top: 0px; left: 0px; z-index: ${zIndexForAnimated}`" />
  </div>
</template>

<style lang="scss" scoped>
@import 'src/design/components/dashboard/_gamified-training-complete.scss';
</style>
