





































































import { Vue, Component } from 'vue-property-decorator'
import qrCode from '@chenfengyuan/vue-qrcode'
import SessionStore from '@state/modules/session'
import { LX } from '@src/lib/analytics/events/LearnerExperience'

@Component({
  components: {
    qrCode,
  },
})
export default class MobileNotificationModal extends Vue {
  private showStep1: boolean = true
  private showStep2: boolean = false
  private showMobileLinks: boolean = false

  switchLayout() {
    this.showMobileLinks = !this.showMobileLinks
    if (this.showMobileLinks) {
      this.$analytics.trackEvent(LX.showMobileSignUpLinks)
    } else {
      this.$analytics.trackEvent(LX.showMobileSignUpQRCodes)
    }
  }

  // @ts-ignore
  get isTouchScreen() {
    return (
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      // @ts-ignore
      navigator.msMaxTouchPoints > 0
    )
  }

  showNextStep() {
    this.showStep1 = false
    this.showStep2 = true
  }

  showPreviousStep() {
    this.showStep1 = true
    this.showStep2 = false
  }

  get deviceLinkUri() {
    if (
      SessionStore.currentUser === null ||
      SessionStore.currentUser.deviceLinkToken === null
    ) {
      return ''
    }
    return `knowbe4://device-link/${SessionStore.currentUser.deviceLinkToken}`
  }
}
