<script>
import Loader from '@components/global/loader'
import formatDate from '@utils/format-date'

export default {
  components: { Loader },
  props: {
    policyUrl: { type: String, default: null },
    enrollmentId: { type: Number, default: null },
    acceptedAt: { type: String, default: null },
  },
  data: function () {
    return {
      loading: false,
    }
  },
  computed: {
    needToAccept: function () {
      return !this.acceptedAt
    },
    acceptDate() {
      return formatDate(this.acceptedAt)
    },
  },
  methods: {
    acceptPolicy() {
      this.loading = true
      this.$store
        .dispatch('enrollments/acceptPolicy', this.enrollmentId)
        .then(() => {
          this.$store
            .dispatch('enrollments/refreshEnrollment', this.enrollmentId)
            .then(() => {
              this.loading = false
            })
        })
    },
  },
}
</script>

<template>
  <footer :class="{ 'card-footer': true, policy: true, action: needToAccept }">
    <transition name="fade" mode="out-in">
      <div v-if="needToAccept" key="action" class="row">
        <div class="col">
          {{ $t('enrollment.policy_details.completion_instructions') }}
          <a :href="policyUrl" target="_blank">
            {{ policyUrl }}
          </a>
          {{ $t('enrollment.policy_details.acknowledgement_description') }}
        </div>
        <div class="col-12 col-lg-auto actions">
          <button
            :disabled="loading"
            :class="{ 'loading-text': loading }"
            type="button"
            class="btn btn-primary brand-btn-primary accept-policy-button"
            @click="acceptPolicy()">
            <loader v-if="loading" class="mt-2" fg="#FFF" />
            {{ $t('enrollment.policy_details.acknowledge_button') }}
          </button>
        </div>
      </div>
      <div v-else key="done" class="row m-3 text-center">
        <div class="col">
          {{ $t('enrollment.policy_details.accepted_at') }}
          {{ policyUrl ? ' ' : '' }}
          <a :href="policyUrl" target="_blank">
            {{ policyUrl }}
          </a>
          {{ $t('enrollment.policy_details.on') }} {{ acceptDate }}.
        </div>
      </div>
    </transition>
  </footer>
</template>

<style lang="scss" scoped>
@import 'src/design/components/enrollments/_accept-policy.scss';
</style>
