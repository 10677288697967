<template lang="pug">
  b-modal(
    id="language-selection-modal"
    ref="languageSelectionModal"
    v-model="active"
    lazy
    no-close-on-backdrop
    no-close-on-esc
    ok-variant="success"
    :title="$t('enrollment.language_options.preferred_language')"
    size="md"
    role="document"
    @show="show()"
    @hide="hide()")
    template(slot="modal-header")
      h5.m-0 {{ $t('enrollment.language_options.preferred_language')}}
      button.m-0.p-0.close(type="button" aria-label="Close" @click="close()")
        fa.fa-xs(icon='times')
    div.container.px-0
      language-selection-options-list(
        :language-options="languageOptions"
        :selected-language="selectedLanguage"
        @languageSelected="selectLanguage")
    template(slot="modal-footer")
      b-button(
        id="cancel-button"
        class="btn-secondary"
        @click="close()") {{ $t('common.cancel') }}
      b-button(
        id="confirm-button"
        class="btn-primary"
        @click="confirm()") {{ $t('common.save') }}
</template>
<script>
import { languageOptions, getLocale, describeLocale } from '@src/locale'
import LanguageSelectionOptionsList from './language-selection-options-list'
import { LX } from '@src/lib/analytics/events/LearnerExperience'
import SessionStore from '@state/modules/session'

export default {
  components: { LanguageSelectionOptionsList },
  data() {
    return {
      selectedLanguage: null,
      initialLanguage: null,
      active: false,
      resumeTour: false,
    }
  },
  computed: {
    languageOptions: function () {
      return languageOptions(this.$store.getters['globals/allowAllLanguages'])
    },
    interfaceLanguageSelected() {
      return SessionStore.userHasSelectedLanguage
    },
  },
  mounted() {
    this.$root.$on('show-locale-selection-modal', e => {
      this.active = true
      this.resumeTour = e.resumeTour
    })
  },
  methods: {
    selectLanguage(lang) {
      this.$i18n.locale = lang.code
      this.selectedLanguage = lang
    },
    show() {
      this.initialLanguage = getLocale()
      this.selectedLanguage = this.initialLanguage
    },
    confirm() {
      this.$analytics.trackEvent(LX.changeInterfaceLanguage, {
        'Language Code': this.selectedLanguage.code,
        'Language Description': describeLocale(
          this.selectedLanguage.code,
          'en-us'
        ),
      })
      SessionStore.updateUserLanguage(this.selectedLanguage).then(() => {
        this.initialLanguage = this.selectedLanguage
        this.close()
      })
    },
    hide() {
      this.selectLanguage(this.initialLanguage)
      this.resumeTour && this.$root.$emit('show-welcome-modal')
    },
    close() {
      this.active = false
    },
  },
}
</script>
