import Enrollment from './enrollment'
import {
  Enrollment as GqlEnrollment,
  PurchasedCourse,
  StoreItem,
  Topic,
} from '@state/api/graphql'

export default class Course extends Enrollment {
  storeItem: any = null

  constructor(
    enrollment?: GqlEnrollment,
    languageOrder?: string[],
    storeItem?: StoreItem,
    rating?: number,
  ) {
    super(enrollment, languageOrder, storeItem, rating)
    if (enrollment) {
      const enrollmentItem = enrollment.enrollmentItem as PurchasedCourse
      this.storeItem = enrollmentItem.storeItem
      this.title = this.storeItem.title
      this.description = this.storeItem.description
      this.enrollmentType = this.getEnrollmentType(this.storeItem.type)
      this.publishedAt = this.storeItem.publishedAt
      this.duration = this.storeItem.expectedDurationInMinutes
      this.lastUsedLanguage = enrollment.lastUsedLanguage || ''
      this.publisher = this.getPublisher(enrollment)
      this.originalLanguageOptions = this.storeItem.translations.map(tr =>
        this.mapTranslation(tr, enrollment),
      )
      this.policyUrl = enrollmentItem.policyLocation || undefined
      if (enrollment.theme) {
        this.theme = enrollment.theme
      }
      this.uuid = enrollmentItem.storeItem?.uuid || undefined
      this.coverImage =
        enrollmentItem.storeItem?.artwork.coverImage || undefined

      this.contentSource = enrollmentItem.contentSource || 'unknown'
      this.headerImage =
        enrollmentItem.storeItem?.artwork?.headerImage || undefined
    }
  }

  get certificateUrl() {
    if (this.isComplete && this.enrollmentType === 'Course') {
      return `/enrollments/${this.enrollmentId}/certificate.pdf`
    }
    return null
  }

  get topics(): Topic[] {
    return this.storeItem.topics || []
  }

  private getPublisher(enrollment: GqlEnrollment): string {
    const enrollmentItem = enrollment.enrollmentItem as PurchasedCourse
    if (enrollmentItem.contentSource === 'ccm') {
      return enrollment.user?.companyName || 'unknown'
    } else {
      return (
        (enrollmentItem.storeItem as StoreItem).publisher?.name || 'unknown'
      )
    }
  }

  private getEnrollmentType(type: string) {
    if (type === 'Assessment') {
      return 'Assessment'
    } else {
      return 'Course'
    }
  }

  get analyticsEnrollmentType() {
    return this.storeItem.type.replace(/\s/g, '') // Remove spaces because of CCM
  }

  get defaultImage() {
    return require('@assets/images/enrollment-default.svg?inline')
  }

  get defaultHeaderImage() {
    return require('@assets/images/header-placeholder.svg?inline')
  }

  get showEnrollmentTimeSpent(): boolean {
    return !!this.timeSpentInSeconds
  }

  get subscriptions() {
    return this.storeItem?.subscriptions?.map(sub => sub.code)
  }
}
