import { graphqlAxios } from '@state/api/client/graphqlAxiosClient'

/** GQL Mutations **/
import LeaderboardsQuery from '@gql/queries/leaderboards.gql'

export function leaderboard() {
  return graphqlAxios.post('/graphql', {
    query: LeaderboardsQuery,
  })
}
