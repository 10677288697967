<template>
  <div id="loader-wrapper" :class="$style.wrapper">
    <div :class="large === true ? $style.loaderLarge : $style.loader">
      <div v-for="(b, index) in bars" :key="index" :style="barStyle" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fg: {
      type: String,
      default: null,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bars: 3,
    }
  },
  computed: {
    color() {
      return this.fg || this.$style.color
    },
    barStyle() {
      return { 'border-color': `${this.color} transparent transparent` }
    },
  },
}
</script>

<style lang="scss" module>
@import 'src/design/components/global/_loader.scss';
</style>
