import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=62469419&scoped=true&lang=pug&"
import script from "./dashboard.vue?vue&type=script&lang=ts&"
export * from "./dashboard.vue?vue&type=script&lang=ts&"
import style0 from "./dashboard.vue?vue&type=style&index=0&id=62469419&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62469419",
  null
  
)

export default component.exports