
import { setInterval } from 'timers'
import LogoutButton from '@/src/components/global/logout-button.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({ components: { LogoutButton } })
export default class ReloadSessionTimeOut extends Vue {
  sessionTimingOut: boolean = false

  mounted() {
    setInterval(this.checkSessionTime.bind(this), 1000)
  }

  checkSessionTime() {
    // @ts-ignore
    const abt = window.absolute_session_timeout
    if (abt && abt < Math.floor(new Date().getTime() / 1000) + 10 * 60) {
      this.sessionTimingOut = true
    }
  }
}
