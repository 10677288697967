
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class TeamTraining extends Vue {
  @Prop({ required: true, default: 10 }) total!: number
  @Prop({ required: true, default: 35 }) complete!: number

  get trainingPercentComplete() {
    if (this.total) {
      return ((this.complete / this.total) * 100).toFixed()
    }

    return 0
  }
}
