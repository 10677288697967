import store from '@state/store'
import Home from '@views/home'
import Badges from '@views/badges'
import Dashboard from '@views/dashboard'
import MyDashboard from '@components/dashboard/my-dashboard.vue'
import TeamDashboard from '@components/dashboard/team-dashboard.vue'
import KnowledgeRefreshers from '@components/enrollments/knowledge_refreshers/knowledge-refreshers.vue'
import EnrollmentStore from '@state/modules/enrollments'
import {
  enrollmentVerificationState,
  enrollmentHasBeenVerified,
  redirectVerifiedEnrollment,
} from '../utils/enrollments'
import SessionStore from '@state/modules/session'
import BadgesStore from '@src/state/modules/badges'
import UnifiedLeaderboardStore from '@/src/state/modules/unified_leaderboard'

export const LEADERS_PATH = '/leaders'

export default [
  {
    path: '/dashboard',
    component: Dashboard,
    title: 'Dashboard',
    props: true,
    meta: [{ name: 'description', content: 'My Dashboard' }],
    beforeEnter(_routeTo, _routeFrom, next) {
      Promise.all([
        EnrollmentStore.fetchEnrollments(),
        SessionStore.fetchDashboardStatistics(),
        UnifiedLeaderboardStore.fetchLeaderboard(),
        BadgesStore.fetchBadges(),
      ]).then(() => {
        if (!SessionStore.dashboardEnabled) {
          next({ name: 'home', params: { enrollmentError: false } })
        }
        next()
      })
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: MyDashboard,
        beforeEnter(_routeTo, _routeFrom, next) {
          if (!SessionStore.myDashboardEnabled) {
            next('/dashboard/team')
          }
          next()
        },
      },
      {
        path: 'team',
        name: 'team',
        component: TeamDashboard,
        beforeEnter(_routeTo, _routeFrom, next) {
          if (!SessionStore.teamDashboardEnabled) {
            next('/dashboard')
          }
          next()
        },
      },
    ],
  },
  {
    path: '/training',
    component: () => import('@views/training'),
    props: true,
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
        meta: {
          allowIntroTour: true,
        },
      },
      {
        path: 'refreshers',
        name: 'knowledge_refreshers',
        component: KnowledgeRefreshers,
      },
      {
        path: 'details/:id',
        name: 'training_detail_view',
        component: () => import('@views/library/library-detail-view'),
        props: route => ({ uuid: route.query.uuid }),
        beforeEnter(routeTo, _routeFrom, next) {
          const {
            params: { id },
          } = routeTo
          if (
            enrollmentHasBeenVerified(enrollmentVerificationState.MANDATORY, id)
          ) {
            return next()
          }
          next({ name: 'dashboard', params: { enrollmentError: true } })
        },
      },
    ],
  },
  {
    path: '/library',
    name: '',
    component: () => import('@views/library'),
    children: [
      {
        path: '',
        name: 'library',
        component: () => import('@views/library/optional-training'),
      },
      {
        // Todo: remove this once ticket aml-678 is merged
        path: 'optional',
        name: 'optional_training',
        redirect: '/library',
      },
      {
        path: 'details/:id',
        name: 'library_detail_view',
        component: () => import('@views/library/library-detail-view'),
        props: route => ({ uuid: route.query.uuid }),
        beforeEnter(routeTo, _routeFrom, next) {
          const {
            params: { id },
            query: { uuid, source },
          } = routeTo
          if (
            enrollmentHasBeenVerified(
              enrollmentVerificationState.OPTIONAL,
              id,
              source
            )
          ) {
            return next()
          }
          redirectVerifiedEnrollment(next, parseInt(id), uuid)
        },
      },
    ],
  },
  {
    path: '/badges',
    name: 'badges',
    component: Badges,
    beforeEnter(routeTo, routeFrom, next) {
      BadgesStore.fetchBadges().then(() => next())
      BadgesStore.markAcknowledgedBadgesAsViewed()
    },
    props: () => ({
      badges: BadgesStore.all,
      earnedBadges: BadgesStore.earnedBadges,
    }),
  },
  {
    path: LEADERS_PATH,
    name: 'leaders',
    component: () => import('@views/leaderboard'),
    beforeEnter(routeTo, routeFrom, next) {
      UnifiedLeaderboardStore.fetchLeaderboard().then(() => next())
    },
  },
  {
    name: 'messages',
    path: '/messages',
    component: () => import('@views/messages'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@views/profile'), // Profile,
    props: () => ({
      user: store.state.auth.currentUser,
    }),
  },
  {
    path: '/link-devices',
    name: 'link_devices',
    component: () => import('@views/devices'), // Learner App,
    props: () => ({
      user: store.state.auth.currentUser,
    }),
  },
  {
    path: '/404',
    name: '404',
    meta: {
      layout: require('@layouts/minimal').default,
    },
    component: require('@views/404').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  {
    path: '/colors',
    name: 'Colors',
    component: () => lazyLoadView(import('@views/colors')),
  },
  {
    path: '/',
    redirect: '/dashboard',
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@views/loading').default,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@views/timeout').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}
