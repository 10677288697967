import { render, staticRenderFns } from "./kr-badges-banner.vue?vue&type=template&id=57c05ba2&scoped=true&lang=pug&"
import script from "./kr-badges-banner.vue?vue&type=script&lang=ts&"
export * from "./kr-badges-banner.vue?vue&type=script&lang=ts&"
import style0 from "./kr-badges-banner.vue?vue&type=style&index=0&id=57c05ba2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57c05ba2",
  null
  
)

export default component.exports