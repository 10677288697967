























































import { Vue, Component, Provide, Prop } from 'vue-property-decorator'
import { getLocale } from '@src/locale'
import qrCode from '@chenfengyuan/vue-qrcode'
import { LX } from '@src/lib/analytics/events/LearnerExperience'

@Component({
  components: {
    qrCode,
  },
})
export default class MobileNotificationModal extends Vue {
  @Prop() onHide
  @Prop({ type: String, required: true }) lxExperiment
  @Prop({ type: Array }) linkedDevices
  @Prop({ type: String }) deviceLinkUri

  private qrCodeWidth: number = 150
  private showMobileLinks: boolean = false
  private showSignInButton: boolean = (this as any).$isMobile()

  @Provide('isRTL') rtl: boolean | undefined = getLocale()?.rtl

  toggleSignInLayout() {
    this.showSignInButton = !this.showSignInButton
  }

  switchLayout() {
    this.showMobileLinks = !this.showMobileLinks
    if (this.showMobileLinks) {
      this.$analytics.trackEvent(LX.showMobileSignUpLinks)
    } else {
      this.$analytics.trackEvent(LX.showMobileSignUpQRCodes)
    }
  }
}
