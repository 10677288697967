<template lang="pug">
  b-card(
    class="text-center confetti-container mb-4"
    id="trainingCompletedTileConfettiTheme"
    :title="trainingStateMessage"
    :sub-title="trainingCompletedMessage"
    vertical-align="center")
      div(
        v-for="index in 149"
        :key="index"
        :class="[`confetti-${index}`,!reduceMotion && `confetti-animated-${index}`,]"
        :style="{ backgroundColor: colorVarients[index % 15] }"
      )
</template>
<script>
import chroma from 'chroma-js'
import SessionStore from '@state/modules/session'
import { LX } from '@src/lib/analytics/events/LearnerExperience'
export default {
  props: {
    primaryColor: { type: String, default: '#f26721' },
    secondaryColor: { type: String, default: '#676767' },
    enrollmentCount: { type: Number, required: true },
    reduceMotion: { type: Boolean, default: true },
  },
  data() {
    return {
      colorVarients: [],
      colorWeights: [
        this.primaryColor,
        this.primaryColor,
        this.primaryColor,
        this.secondaryColor,
        this.secondaryColor,
      ],
    }
  },
  computed: {
    trainingStateMessage() {
      return this.enrollmentCount !== 0
        ? this.$t('confetti.completed_all_training')
        : this.$t('confetti.no_training_available')
    },
    trainingCompletedMessage() {
      if (this.showLibraryTab) {
        return this.$t('confetti.upcoming_assignments_library_enabled')
      }
      return this.$t('confetti.upcoming_assignments')
    },
    showLibraryTab() {
      return SessionStore.aidaOptionalTrainingEnabled
    },
  },
  beforeMount() {
    this.colorWeights.forEach(color => {
      ;[0, 1, 2].forEach(brightenBy => {
        this.colorVarients.push(chroma(color).brighten(brightenBy).hex())
      })
    })
  },
  mounted() {
    this.$analytics.trackEvent(LX.viewCompletedTrainingBanner, {
      Theme: 'Confetti',
      Action: 'View Completed Training Banner',
    })
  },
}
</script>
<style lang="scss">
@import 'src/design/components/enrollments/_training-completed-tile-confetti-theme.scss';
</style>
