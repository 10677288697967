<script>
export default {}
</script>
<template lang="pug">
  b-container.text-center
    base-inline-svg(:id="'session-expiring'" :branded="true" :svg="require('@assets/images/session-expiring.svg?inline')")
    h5.mt-4 {{ $t('course.not_time_popup.warning') }}
    p.text-muted {{ $t('course.not_time_popup.instructions') }}
    b-button.mt-3(href="/spa/auth/logout?resource_type=user" variant="primary") {{ $t('course.not_time_popup.button_text') }}
</template>
