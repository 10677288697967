
import { Vue, Component, Prop } from 'vue-property-decorator'

import RespondedRequest from '@src/models/responded-request'

@Component({ components: {} })
export default class SessionErrorModal extends Vue {
  @Prop({ required: true }) respondedRequest!: RespondedRequest

  readonly TIME_TO_HIDE_SESSION_ERROR_MODAL: number = 10_000 // ten seconds
  readonly KNOWN_SESSION_ERRORS: string[] = [
    'session_interrupted',
    'session_expired',
    'multiple_windows',
    'signed_out',
  ]

  readonly DEFAULT_SESSION_ERROR: string = 'session_interrupted'

  get sessionErrorWithDefault(): string {
    if (
      this.sessionError &&
      this.KNOWN_SESSION_ERRORS.includes(this.sessionError)
    ) {
      return this.sessionError
    }

    return this.DEFAULT_SESSION_ERROR
  }

  get sessionError(): string {
    return this.respondedRequest?.error
  }

  created() {
    this.closeScormWindow()
  }

  mounted() {
    this.refreshPageOrCloseModalAfterAWhile()
  }

  private closeScormWindow() {
    ;(window as any).scormWindow.close()
  }

  private refreshPageOrCloseModalAfterAWhile() {
    if (this.respondedRequest?.isUserSessionError()) {
      return this.refreshPageAfterUserSessionError()
    }
    if (this.respondedRequest?.isLearnerSessionError()) {
      return this.hideModalAfterLearnerSessionError()
    }
  }

  // Refresh the page if there's an issue with the user session so that we either
  // show the login page (after a 401 error) or fetch a new CSRF token from the
  // backend (after a 403 error).
  private refreshPageAfterUserSessionError() {
    setTimeout(() => location.reload(), this.TIME_TO_HIDE_SESSION_ERROR_MODAL)
  }

  // Just hide the modals if this is a learner session-related error (409 error).
  // I.e., the user session is still good.
  private hideModalAfterLearnerSessionError() {
    setTimeout(() => {
      this.$emit('hideSessionErrorModal')
    }, this.TIME_TO_HIDE_SESSION_ERROR_MODAL)
  }
}
