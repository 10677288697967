
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class TeamTrainingOptional extends Vue {
  @Prop({ required: true, default: 10 }) totalMembers!: Number | any
  @Prop({ required: true, default: 5 }) membersCompletedOptional!: Number
  @Prop({ required: true, default: 10 }) people!: Number | any

  hasFill(index): boolean {
    if (this.membersCompletedOptional === 0) return false
    return (
      index + 1 <=
      Math.round(
        this.people *
          (((this.membersCompletedOptional as number) /
            this.totalMembers) as number)
      )
    )
  }
}
