<template lang="pug">
div.card.mb-4.mx-auto.shadow(id="trainingCompletedTileTimeTheme")
  div.card-body.p-0.bg-white
    div.row.no-gutters.justify-content-center
      div.col.primary.p-0.col-12.align-items-center.justify-content-center.svg-image-container(:style="{backgroundColor:colorPrimary}")
        div.svg-image-padded-container.p-5
          base-inline-svg(
            :id="'timer'"
            :branded="true"
            :svg="require('@assets/images/training/timer.svg?inline')")
      div.enrollment-div.col.m-5.my-lg-0.mx-lg-6.d-flex.align-content-center.flex-wrap
        h2.enrollment-title.w-100.font-weight-bold.text-primary.text-center.text-md-left {{ $t('training_completed.training_completed_title_time') }}
        h6.description.text-muted.pt-1.pb-3.w-100.text-center.text-md-left {{ $t('training_completed.training_completed_sub_title') }}
        b-button.mx-auto.mx-md-0(
          variant="primary"
          :aria-label="browseLibrary"
          @click.prevent="browseLibrary") {{ $t('training_completed.browse_library') }}
</template>
<script>
import { LX } from '@src/lib/analytics/events/LearnerExperience'
export default {
  mounted() {
    this.$analytics.trackEvent(LX.viewCompletedTrainingBanner, {
      Theme: 'Time',
      Action: 'View Completed Training Banner',
    })
  },
  methods: {
    browseLibrary() {
      this.$analytics.trackEvent(LX.viewCompletedTrainingBanner, {
        Theme: 'Time',
        Action: 'Click Browse Library',
      })
      return this.$router.push({ name: 'library' })
    },
  },
}
</script>
<style lang="scss" scoped>
@import 'src/design/components/enrollments/_training-completed-tile-time-theme.scss';
</style>
