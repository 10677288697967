import { authComputed } from '@state/helpers'
import createFocusTrap from 'focus-trap'
import filter from 'lodash/filter'
import introJS from 'intro.js'
import { LX } from '@src/lib/analytics/events/LearnerExperience'

const enterPressed = e => e.keyCode === 13

export default {
  data() {
    return {
      defaultOptions: {
        disableInteraction: true,
        showStepNumbers: false,
        skipLabel: this.$t('welcome.skip_tour'),
        exitOnOverlayClick: false,
        nextLabel: this.$t('tours.next'),
        doneLabel: this.$t('tours.done'),
        overlayOpacity: '0.5',
        tour: null,
        onbeforeexit: null,
      },
      focusTrap: null,
      callback: null,
    }
  },
  computed: {
    ...authComputed,
    profileIntroMessage() {
      return this.isAdmin
        ? `<h3>${this.$t('tours.intro.step_five.title')}</h3>${this.$t(
            'tours.intro.step_five.description_admin'
          )}`
        : `<h3>${this.$t('tours.intro.step_five.title')}</h3>${this.$t(
            'tours.intro.step_five.description_non_admin'
          )}`
    },
    introSteps() {
      return [
        {
          element: document.getElementById('first-enrollment-start-button'),
          intro: `<h3>${this.$t('tours.intro.step_one.title')}</h3>${this.$t(
            'tours.intro.step_one.description'
          )}`,
        },
        {
          element: document.getElementById('nav-item-dashboard'),
          intro: `<h3>${this.$t('tours.intro.step_eight.title')}</h3>${this.$t(
            'tours.intro.step_eight.description'
          )}`,
        },
        {
          element: document.getElementById('nav-item-training'),
          intro: `<h3>${this.$t('tours.intro.step_two.title')}</h3>${this.$t(
            'tours.intro.step_two.description'
          )}`,
        },
        {
          element: document.getElementById('nav-item-library'),
          intro: `<h3>${this.$t('tours.intro.step_nine.title')}</h3>${this.$t(
            'tours.intro.step_nine.description'
          )}`,
        },
        {
          element: document.getElementById('nav-item-leaderboard'),
          intro: `<h3>${this.$t('tours.intro.step_three.title')}</h3>${this.$t(
            'tours.intro.step_three.description'
          )}`,
        },
        {
          element: document.getElementById('nav-item-badges'),
          intro: `<h3>${this.$t('tours.intro.step_four.title')}</h3>${this.$t(
            'tours.intro.step_four.description'
          )}`,
        },
        {
          element: document.getElementById('nav-item-language-selection'),
          intro: `<h3>${this.$t('tours.intro.step_seven.title')}</h3>${this.$t(
            'tours.intro.step_seven.description'
          )}`,
        },
        {
          element: document.getElementById('nav-item-dropdown-profile'),
          intro: this.profileIntroMessage,
          position: 'bottom-right-aligned',
        },
        {
          element: document.getElementById('main-help-button'),
          intro: `<h3>${this.$t('tours.intro.step_six.title')}</h3>${this.$t(
            'tours.intro.step_six.description'
          )}`,
        },
      ]
    },
    profileSteps() {
      return [
        {
          element: document.getElementById('main-profile-heading'),
          intro: `<h3>${this.$t('tours.profile.step_one.title')}</h3>${this.$t(
            'tours.profile.step_one.description'
          )}`,
          position: 'left',
        },
        {
          element: document.getElementById('form-group-time-zone-input'),
          intro: `<h3>${this.$t('tours.profile.step_two.title')}</h3>${this.$t(
            'tours.profile.step_two.description'
          )}`,
          position: 'left',
        },
        {
          element: document.getElementById('form-group-language-input'),
          intro: `<h3>${this.$t(
            'tours.profile.step_three.title'
          )}</h3>${this.$t('tours.profile.step_three.description')}`,
          position: 'left',
        },
        {
          element: document.getElementById('form-group-mfa-config'),
          intro: `<h3>${this.$t('tours.profile.step_four.title')}</h3>
          ${this.$t('tours.profile.step_four.description')}`,
          position: 'left',
        },
      ]
    },
  },
  methods: {
    startTour(steps, options, callback) {
      this.tour = introJS().setOptions({
        ...this.defaultOptions,
        ...options,
        showBullets: steps.length > 1,
        ...{
          steps: steps,
        },
      })

      if (callback) this.callback = callback
      this.tour.start()
      this.trapFocusInTour()
      this.initTourListeners()
    },
    stepsForValidElements(steps) {
      return filter(steps, step => step.element)
    },
    startIntroTour(options) {
      this.startTour(this.stepsForValidElements(this.introSteps), options)
    },
    startProfileTour(options) {
      this.startTour(this.stepsForValidElements(this.profileSteps), options)
    },
    startLinkDeviceTour(callback, options) {
      this.startTour(
        this.stepsForValidElements(this.linkDeviceSteps),
        {
          ...options,
          doneLabel: this.$t('tours.link_devices.tour_button'),
        },
        callback // allows us to close the profile dropdown again
      )
    },
    // Trap keyboard focus in tour dialog
    trapFocusInTour() {
      const tourContainer = document.querySelector('.introjs-tooltip')
      const skipTourButton = document.querySelector('.introjs-skipbutton')

      this.focusTrap = createFocusTrap(tourContainer, {
        initialFocus() {
          return skipTourButton
        },
      })
      this.focusTrap.activate()
    },
    // For IE keyboard accessibility
    initTourListeners() {
      let nextStepButton = document.querySelector('.introjs-nextbutton')
      let skipTourButton = document.querySelector('.introjs-skipbutton')

      if (nextStepButton !== null) {
        nextStepButton.addEventListener('keypress', e => {
          enterPressed(e) && this.tour.nextStep()
        })
      }

      if (skipTourButton !== null) {
        skipTourButton.addEventListener('keypress', e => {
          enterPressed(e) && this.tour.exit()
        })
      }

      this.tour.onbeforeexit(() => {
        let { _currentStep, _introItems } = this.tour
        _currentStep += 1
        if (_currentStep < _introItems.length) {
          this.trackTourAnalytics(LX.skipTour, {
            Step: _currentStep,
          })
        } else {
          this.trackTourAnalytics(LX.completeTour, { Step: _currentStep })
        }

        if (this.callback) this.callback()
      })

      // On exit of tour, remove references to dom
      // so that listeners are garbage collected
      this.tour.onexit(() => {
        nextStepButton = null
        skipTourButton = null
        this.focusTrap.deactivate()
      })
    },
    trackTourAnalytics(event, extraProperties) {
      this.$analytics.trackEvent(event, extraProperties)
    },
  },
}
