export const state = {
  loading: true,
  flash: null,
  allowAllLanguages: false,
}

export const getters = {
  loading() {
    return state.loading
  },
  flash() {
    return state.flash
  },
  allowAllLanguages() {
    return state.allowAllLanguages
  },
}

export const mutations = {
  loading(state, isLoading) {
    state.loading = isLoading
  },
  resetFlash(state) {
    state.flash = null
  },
  setFlash(state, flashMessage) {
    state.flash = flashMessage
  },
  allowAllLanguages(state) {
    state.allowAllLanguages = true
  },
}
