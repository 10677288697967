<script>
export default {
  props: {
    title: { type: String, default: '' },
    icon: { type: String, default: '' },
  },
}
</script>

<template lang="pug">
  div.heading.p-3
    div.avatar.text-center.align-middle.light-brand-highlight
      fa(
        :icon="icon"
        size="lg"
        focusable="false")
    label.title {{ title }}

</template>

<style lang="scss" scoped>
@import 'src/design/components/dashboard/_dashboard-tile-heading.scss';
</style>
