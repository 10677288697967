export default class Cache {
  private cacheForDuration: number // seconds
  private cachedAt: Date | null = null

  constructor(durationInSeconds: number = 60) {
    this.cacheForDuration = durationInSeconds
  }

  get hasCacheExpired() {
    return (
      !this.cachedAt ||
      this.secondsSinceLastCached > this.cacheForDurationInSeconds
    )
  }

  get secondsSinceLastCached() {
    return Math.round((this.now() - this.cachedAtTime()) / 1000)
  }

  get cacheForDurationInSeconds() {
    return this.cacheForDuration
  }

  set cacheDuration(timeInSeconds: number) {
    this.cacheForDuration = timeInSeconds
  }

  public cache(date: Date) {
    this.cachedAt = date
  }

  // private
  private now() {
    return new Date().getTime()
  }

  private cachedAtTime() {
    if (!this.cachedAt) {
      return new Date().getTime()
    }

    return this.cachedAt.getTime()
  }
}
