<template>
  <b-dropdown-item @click="showLanguageModal">
    <fa icon="globe" />
    <span class="label">
      {{ localeDesc }}
    </span>
  </b-dropdown-item>
</template>

<script>
import { getLocale, describeLocale } from '@src/locale'
export default {
  props: {
    resumeTour: { type: Boolean, required: false, default: false },
  },
  computed: {
    localeDesc() {
      const code = getLocale().code
      return describeLocale(code, code)
    },
  },
  methods: {
    showLanguageModal() {
      this.$root.$emit('show-locale-selection-modal', {
        resumeTour: this.resumeTour,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/design/components/global/_interface-language-selection-opener.scss';
</style>
