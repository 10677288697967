


















import { Vue, Component, Watch } from 'vue-property-decorator'
import { authComputed, sessionComputed } from '@state/helpers'
import InterfaceLanguageSelectionOpener from '@/src/components/global/interface-language-selection-opener.vue'
import truncate from '@mixins/truncate'
import { clearLocale, getLocale } from '@src/locale'
import SessionStore from '@state/modules/session'
import BadgesStore from '@src/state/modules/badges'
import { graphqlAxios } from '@/src/state/api/client/graphqlAxiosClient'
import SignOutMutation from '@gql/mutations/sign_out_actors.gql'

@Component({
  components: { InterfaceLanguageSelectionOpener },
  computed: {
    ...authComputed,
    ...sessionComputed,
  },
  mixins: [truncate],
})
export default class ImpersonatingBar extends Vue {
  private currentUser

  get email() {
    return SessionStore.currentImpersonatingUser?.email
  }

  get adminUrl() {
    return this.currentUser.impersonating_return_url
  }

  async signOut() {
    const resp = await graphqlAxios.post('/graphql', {
      query: SignOutMutation,
      variables: { actors: ['impersonatedUser'] },
    })
    if (resp.status === 200) {
      window.location.href = this.adminUrl
    }
  }
}
