import {
  enUS,
  enGB,
  arSA,
  ptBR,
  frCA,
  de,
  enAU,
  zhCN,
  es,
  fr,
  ja,
  sv,
  hu,
  it,
  nl,
  nb,
  ru,
  vi,
  cs,
  hi,
  id,
  ms,
  pl,
  pt,
  ko,
  zhTW,
  da,
  fi,
  ro,
  th,
  uk,
  he,
  tr,
} from 'date-fns/locale'

export const AvailableLanguages = [
  {
    code: 'en-us',
    dateFnsLocale: enUS,
    active: true,
  },
  {
    code: 'en-dev',
    dateFnsLocale: enUS,
    active: false,
  },
  {
    code: 'en-gb',
    dateFnsLocale: enGB,
    active: true,
  },
  {
    code: 'es-419',
    dateFnsLocale: es,
    active: true,
  },
  {
    code: 'fr-ca',
    dateFnsLocale: frCA,
    active: true,
  },
  {
    code: 'fr-fr',
    dateFnsLocale: fr,
    active: true,
  },
  {
    code: 'ja-jp',
    dateFnsLocale: ja,
    active: true,
  },
  {
    code: 'pl-pl',
    dateFnsLocale: pl,
    active: true,
  },
  {
    code: 'sv-se',
    dateFnsLocale: sv,
    active: true,
  },
  {
    code: 'pt-br',
    dateFnsLocale: ptBR,
    active: true,
  },
  {
    code: 'de-de',
    dateFnsLocale: de,
    active: true,
  },
  {
    code: 'zh-cn',
    dateFnsLocale: zhCN,
    active: true,
  },
  {
    code: 'zh-hk',
    dateFnsLocale: zhCN,
    active: true,
  },
  {
    code: 'ko-kr',
    dateFnsLocale: ko,
    active: true,
  },
  {
    code: 'hu-hu',
    dateFnsLocale: hu,
    active: true,
  },
  {
    code: 'it-it',
    dateFnsLocale: it,
    active: true,
  },
  {
    code: 'es-es',
    dateFnsLocale: es,
    active: true,
  },
  {
    code: 'nl-nl',
    dateFnsLocale: nl,
    active: true,
  },
  {
    code: 'nb-no',
    dateFnsLocale: nb,
    active: true,
  },
  {
    code: 'ru-ru',
    dateFnsLocale: ru,
    active: true,
  },
  {
    code: 'vi-vn',
    dateFnsLocale: vi,
    active: true,
  },
  {
    code: 'ar-mod',
    dateFnsLocale: arSA,
    active: true,
    rtl: true,
  },
  {
    code: 'cs-cz',
    dateFnsLocale: cs,
    active: true,
  },
  {
    code: 'hi-in',
    dateFnsLocale: hi,
    active: true,
  },
  {
    code: 'id-id',
    dateFnsLocale: id,
    active: true,
  },
  {
    code: 'ms-my',
    dateFnsLocale: ms,
    active: true,
  },
  {
    code: 'zh-tw',
    dateFnsLocale: zhTW,
    active: true,
  },
  {
    code: 'da-dk',
    dateFnsLocale: da,
    active: true,
  },
  {
    code: 'fi-fi',
    dateFnsLocale: fi,
    active: true,
  },
  {
    code: 'he-il',
    dateFnsLocale: he,
    active: true,
    rtl: true,
  },
  {
    code: 'ro-ro',
    dateFnsLocale: ro,
    active: true,
  },
  {
    code: 'th-th',
    dateFnsLocale: th,
    active: true,
  },
  {
    code: 'tr-tr',
    dateFnsLocale: tr,
    active: true,
  },
  {
    code: 'uk-ua',
    dateFnsLocale: uk,
    active: true,
  },
  {
    code: 'pt-pt',
    dateFnsLocale: pt,
    active: true,
  },
  {
    code: 'en-au',
    dateFnsLocale: enAU,
    active: true,
  },
]

export function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export const messages = loadLocaleMessages()
