































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import RiskScore from '@components/dashboard/risk-score.vue'
import Ppp from '@components/dashboard/ppp.vue'
import TeamTraining from '@components/dashboard/team-training.vue'
import TeamTrainingOptional from '@components/dashboard/team-training-optional.vue'

import DashboardTile from '@components/dashboard/dashboard-tile.vue'
import TeamTable from '@components/dashboard/team-table.vue'
import TeamSelect from '@components/dashboard/team-select.vue'
import SessionStore from '@state/modules/session'
import { managerStatistics } from '@gql/client/team-dashboard'
import { ManagerReport } from '@gql/graphql'
import { teamDashboard } from '@src/lib/analytics/events/team-dashboard'

@Component({
  components: {
    DashboardTile,
    TeamTable,
    TeamSelect,
    RiskScore,
    Ppp,
    TeamTraining,
    TeamTrainingOptional,
  },
  page: {
    title: 'Team Dashboard',
  },
})
export default class TeamDashboard extends Vue {
  loading: boolean = true
  searchQuery: string = ''
  tilePermissionCount: number = 0

  allFields: { [key: string]: any }[] = []

  managerStatistics!: ManagerReport | null
  managerId: number | null = null
  currentUserId: number | undefined | null = null

  async mounted() {
    if (SessionStore?.currentUser?.id) {
      this.currentUserId = SessionStore?.currentUser?.id
    }

    this.initAllFields()

    this.updateLoader(true)
    await this.loadManagerStatistics()
    this.updateLoader(false)
  }

  initAllFields() {
    this.allFields = [
      {
        label: this.$t('manager_dashboard.team_table.tableHeaders.name'),
        key: 'name',
      },
      {
        label: this.$t('manager_dashboard.team_table.tableHeaders.email'),
        key: 'email',
      },
      {
        label: this.$t(
          'manager_dashboard.team_table.tableHeaders.phishingFailures'
        ),
        key: 'phishingFailures',
      },
      {
        label: this.$t(
          'manager_dashboard.team_table.tableHeaders.phishingPercentReported'
        ),
        key: 'phishingPercentReported',
      },
      {
        label: this.$t(
          'manager_dashboard.team_table.tableHeaders.trainingPercentComplete'
        ),
        key: 'trainingPercentComplete',
      },
      { label: '', key: 'showDetails' },
    ]
  }

  async loadManagerStatistics(selectedManagerId?: number) {
    const {
      data: {
        data: { managerReport },
      },
    } = await managerStatistics({ userId: selectedManagerId })
    this.managerStatistics = managerReport
    // @ts-ignore
    this.managerId = selectedManagerId
  }

  get teamSelectEnabled() {
    return this.$launchDarkly.get('lx-team-select-on-manager-dashboard')
  }

  get teamDashboardEnabled() {
    return SessionStore.teamDashboardEnabled
  }

  get fields() {
    return this.allFields.filter(field => {
      if (field.key.includes('phishing')) return this.managerPhishingEnabled
      return true
    })
  }

  get managerPhishingEnabled(): boolean | null | undefined {
    return this.managerStatistics && SessionStore.managerPhishingEnabled
  }

  get managerRiskScoreEnabled(): boolean | null | undefined {
    return this.managerStatistics && SessionStore.managerRiskScoreEnabled
  }

  get managerTrainingEnabled(): boolean | null | undefined {
    return this.managerStatistics && SessionStore.teamDashboardEnabled
  }

  get optionalTrainingEnabled(): boolean | null | undefined {
    return this.managerStatistics && SessionStore?.optionalTrainingEnabled
  }

  get managerUserInfoEnabled(): boolean | null | undefined {
    return this.managerStatistics && SessionStore.managerUserInfoEnabled
  }

  get numberOfPeople() {
    if (this.additionalTileSize === 4) return 7
    return 10
  }

  get additionalTileSize(): Number {
    if (this.managerPhishingEnabled) this.tilePermissionCount++
    if (this.managerRiskScoreEnabled) this.tilePermissionCount++
    if (this.managerTrainingEnabled) this.tilePermissionCount++
    if (this.optionalTrainingEnabled) this.tilePermissionCount++
    if (this.tilePermissionCount === 4 || this.tilePermissionCount === 2) {
      return 6
    }
    if (this.tilePermissionCount === 3) return 4
    if (this.tilePermissionCount === 1) return 12
    return 6
  }

  updateLoader(state: boolean) {
    this.loading = state
    const mountedDocumentLoader: HTMLElement | null =
      window.document.getElementById('loader-wrap')
    if (mountedDocumentLoader !== null) {
      mountedDocumentLoader.style.background = 'none'
      mountedDocumentLoader.style.display = this.loading ? 'block' : 'none'
    }
  }

  riskScore(): number | null | undefined {
    if (!this.managerStatistics?.riskScore) return undefined
    // @ts-ignore
    return parseInt(parseFloat(this.managerStatistics?.riskScore).toFixed(0))
  }

  managerTotalDirectReports(): number {
    return this.managerStatistics?.totalMembersCount || 0
  }

  managerNumberReportsWarning(): boolean {
    const totalDirectReports: number = this.managerTotalDirectReports()
    const betweenFiftyAndAHundredUsersWarning: boolean =
      totalDirectReports >= 50 && totalDirectReports < 100
    if (betweenFiftyAndAHundredUsersWarning)
      this.$analytics.trackEvent(
        teamDashboard.betweenFiftyAndAHundredUsersWarning
      )
    return betweenFiftyAndAHundredUsersWarning
  }

  managerNumberReportsError(): boolean {
    const moreThanAHundredUsersWarning: boolean =
      this.managerTotalDirectReports() >= 100
    if (moreThanAHundredUsersWarning)
      this.$analytics.trackEvent(teamDashboard.moreThanAHundredUsersWarning)
    return moreThanAHundredUsersWarning
  }
}
