<script>
import { myDashboard } from '@src/lib/analytics/events/my-dashboard'
import { LeaderboardTypes } from '@/src/state/api/graphql'
export default {
  props: {
    leaderboardType: {
      type: String,
      default: LeaderboardTypes.GroupLeaderboard,
    },
    totalParticipants: { type: Number, default: 0 },
    currentUserLeaderboardScore: { type: Number, default: 0 },
    position: { type: Number, default: 0 },
    loadingLeaderboardLeadersFromBackEnd: { type: Boolean, default: false },
  },
  computed: {
    leaderboardTypes: () => LeaderboardTypes,
    showBadge: function () {
      if (this.leaderboardType === LeaderboardTypes.GroupLeaderboard)
        return true
      if (
        this.currentUserLeaderboardScore > 0 &&
        this.leaderboardType === LeaderboardTypes.IndividualLeaderboard
      ) {
        return true
      }

      return false
    },
    showLeaderboardEmptyState: function () {
      if (this.leaderboardType === LeaderboardTypes.GroupLeaderboard)
        return false
      return (
        this.leaderboardType === LeaderboardTypes.IndividualLeaderboard &&
        (this.currentUserLeaderboardScore === 0 ||
          this.currentUserLeaderboardScore === null)
      )
    },
  },
  methods: {
    routeTo() {
      this.$analytics.trackEvent(myDashboard.viewLeaderboardButton)
      this.$router.push({ name: 'leaders' })
    },
  },
}
</script>

<template>
  <div class="p-4 h-100" qa-id="leaderboard-position">
    <div class="d-flex flex-column align-items-middle h-100">
      <div
        v-if="loadingLeaderboardLeadersFromBackEnd"
        class="d-flex justify-content-center align-items-center h-100">
        <b-spinner />
      </div>
      <div v-else>
        <div class="leaderboard-container pb-3 mx-auto position-relative">
          <b-badge v-if="showBadge" class="position-absolute badge-primary">
            {{ position }}
          </b-badge>
          <base-inline-svg
            :id="'podium'"
            :svg="require('@assets/images/dashboard/podium.svg?inline')"
            :branded="true" />
        </div>
        <p
          v-if="leaderboardType === leaderboardTypes.GroupLeaderboard"
          class="text-center">
          {{
            $t('dashboard.leaderboard.description', {
              leaderboard_position: position,
            })
          }}
        </p>
        <p
          v-if="
            leaderboardType === leaderboardTypes.IndividualLeaderboard &&
              currentUserLeaderboardScore > 0
          "
          class="text-center">
          {{
            $t('dashboard.leaderboard.individual_description', {
              leaderboard_position: position,
              total_leaderboard_participants: totalParticipants,
            })
          }}
        </p>
        <span v-if="showLeaderboardEmptyState">
          <p class="text-center">{{ $t('leaderboard.empty_state_heading') }}</p>
          <p class="more-info text-center text-muted">
            {{ $t('leaderboard.empty_state_description') }}
          </p>
        </span>
      </div>
      <div class="mt-auto">
        <b-button block qa-id="navigate-to-leaderboard" @click="routeTo()">
          {{ $t('dashboard.leaderboard.button') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/design/components/dashboard/_leaderboard-position.scss';
</style>
