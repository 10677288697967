import Vue from 'vue'
import secondsToTimeDistance from '@utils/seconds-to-time-distance'
import formatDate from '@utils/format-date'

export const relativeDuration = (duration: number): string => {
  return duration ? secondsToTimeDistance(duration * 60) : ''
}

export const completedDate = (
  completedAt: string,
  notAvailableTranslatedString: string,
): string => {
  return completedAt ? formatDate(completedAt) : notAvailableTranslatedString
}

Vue.filter('relativeDuration', relativeDuration)
Vue.filter('completedDate', completedDate)
