import Vue from 'vue'
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '../store'
import { Enrollments as apiEnrollment } from '@state/api'
import IEnrollment from '@src/models/enrollment'
import Policy from '@/src/models/policy'
import Course from '@/src/models/course'
import sortBy from 'lodash/sortBy'
import findIndex from 'lodash/findIndex'
import session from '@state/modules/session'
import cloneDeep from 'lodash/cloneDeep'
import Cache from '@/src/utils/cache'
import OptionEnrollment from '@src/models/optional-enrollment'
import uniqBy from 'lodash/uniqBy'
import intersection from 'lodash/intersection'
import { EnrollmentTypes } from '../api/graphql'

export function mapEnrollment(enrollment): Course | OptionEnrollment | Policy {
  if (enrollment.storeItem) {
    if (enrollment.enrollment) {
      return new Course(
        enrollment.enrollment,
        session.trainingLanguagePreferences,
        enrollment.storeItem,
        enrollment.rating
      )
    } else {
      return new OptionEnrollment(
        undefined,
        session.trainingLanguagePreferences,
        enrollment.storeItem,
        enrollment.rating
      )
    }
  }

  if (enrollment.type === EnrollmentTypes.KnowledgeRefresher) {
    return new OptionEnrollment(
      enrollment,
      session.trainingLanguagePreferences,
      enrollment.enrollmentItem.storeItem,
      enrollment.rating,
      undefined
    )
  }

  return enrollment.enrollmentItem.__typename === 'Policy'
    ? new Policy(enrollment, session.trainingLanguagePreferences)
    : new Course(enrollment, session.trainingLanguagePreferences)
}

function mapUploadedContent(item): OptionEnrollment {
  return new OptionEnrollment(
    undefined,
    session.trainingLanguagePreferences,
    item,
    undefined,
    'ccm'
  )
}

// All region based targeted training
const EXCLUDED_MODSTORE_TARGETS = [103, 101, 37, 2, 36, 68, 100, 102, 3]
// All regulatory
const REGULATORY_MODSTORE_TOPICS = [
  11, 12, 15, 138, 20, 19, 18, 17, 16, 137, 13, 151,
]
// All risk management
const RISK_MANAGEMENT_MODSTORE_TOPICS = [134, 136, 100, 23, 24]

const EXCLUDED_MODSTORE_TOPICS = RISK_MANAGEMENT_MODSTORE_TOPICS.concat(
  REGULATORY_MODSTORE_TOPICS
)

const DIVERSITY_EQUITY_INCLUSION = [133]

const TOPICS_TO_RECOMMEND = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 35, 135, 25, 34, 32, 26, 27, 33, 29, 36, 67,
  21, 22, 31,
]

const SOCIAL_MEDIA_TOPIC = [30]

const SOCIAL_MEDIA_QUERY = {
  topics: {
    ids: SOCIAL_MEDIA_TOPIC,
    excludedIds: EXCLUDED_MODSTORE_TOPICS.concat(DIVERSITY_EQUITY_INCLUSION),
  },
  targets: { excludedIds: EXCLUDED_MODSTORE_TARGETS },
}

const RECOMMENDED_FOR_YOU_QUERY = {
  topics: {
    ids: TOPICS_TO_RECOMMEND,
    excludedIds: EXCLUDED_MODSTORE_TOPICS.concat(
      DIVERSITY_EQUITY_INCLUSION,
      SOCIAL_MEDIA_TOPIC
    ),
  },
  targets: { excludedIds: EXCLUDED_MODSTORE_TARGETS },
}

const QUERIES = {
  forYou: RECOMMENDED_FOR_YOU_QUERY,
  socialMedia: SOCIAL_MEDIA_QUERY,
}

@Module({
  namespaced: true,
  store: store,
  dynamic: true,
  name: 'enrollments',
})
class Enrollments extends VuexModule {
  enrollments: IEnrollment[] = []
  knowledgeRefresherEnrollments: OptionEnrollment[] = []
  optionalEnrollments: IEnrollment[] = []
  optionalEnrollmentsCache: Cache = new Cache(180)
  recommendationsCache: Cache = new Cache(180)
  electedEnrollments: IEnrollment[] = []
  allRecommendations: {
    forYou: IEnrollment[]
    socialMedia: IEnrollment[]
  } = {
    forYou: [],
    socialMedia: [],
  }

  uploadedContent: IEnrollment[] = []
  searches: IEnrollment[] = []
  searchState: {
    topics: string[]
    searchText: string
    contentTypes: string[]
  } = {
    topics: [],
    searchText: '',
    contentTypes: [],
  }

  // Mutations
  @Mutation
  setOptionalEnrollmentCache() {
    this.optionalEnrollmentsCache.cache(new Date())
  }

  // Mutations
  @Mutation
  setRecommendationsCache() {
    this.recommendationsCache.cache(new Date())
  }

  @Mutation
  setSearchState(value) {
    this.searchState = { ...this.searchState, ...value }
  }

  @Mutation
  setEnrollments(enrollments) {
    this.enrollments = enrollments
  }

  @Mutation
  setKnowledgeRefresherEnrollments(enrollments) {
    this.knowledgeRefresherEnrollments = enrollments
  }

  @Mutation
  setSearches(enrollments) {
    this.searches = enrollments
  }

  @Mutation
  setOptionalEnrollments({ page, enrollments }) {
    if (page === 1) {
      this.optionalEnrollments = enrollments
    } else {
      this.optionalEnrollments = [
        ...cloneDeep(this.optionalEnrollments),
        ...enrollments,
      ]
    }
  }

  @Mutation
  setAllRecommendations({ page, enrollments, query }) {
    if (page === 1) {
      this.allRecommendations[query] = enrollments
    } else {
      this.allRecommendations[query] = [
        ...cloneDeep(this.allRecommendations[query]),
        ...enrollments,
      ].sort((a, b) => {
        return b.rating - a.rating
      })
    }
  }

  @Mutation
  setElectedEnrollments({ page, enrollments }) {
    if (page === 1) {
      this.electedEnrollments = enrollments
    } else {
      this.electedEnrollments = [
        ...cloneDeep(this.electedEnrollments),
        ...enrollments,
      ]
    }
  }

  @Mutation
  setUploadedContent({ page, enrollments }) {
    if (page === 1) {
      this.uploadedContent = enrollments
    } else {
      this.uploadedContent = [
        ...cloneDeep(this.uploadedContent),
        ...enrollments,
      ]
    }
  }

  @Mutation
  updateEnrollment(enrollment: IEnrollment) {
    if (!enrollment) {
      return false
    }

    if (enrollment.isKnowledgeRefresher) {
      findAndSetEnrollment(this.knowledgeRefresherEnrollments, enrollment)
    } else if (enrollment.isMandatory || !session.optionalTrainingEnabled) {
      findAndSetEnrollment(this.enrollments, enrollment)
    } else if (enrollment.hasTrainingCampaign) {
      findAndSetEnrollment(this.optionalEnrollments, enrollment)
    } else {
      findAndSetEnrollment(this.electedEnrollments, enrollment)
      this.allRecommendations.forYou = this.allRecommendations.forYou.filter(
        item => item.uuid !== enrollment.uuid
      )
      this.allRecommendations.socialMedia =
        this.allRecommendations.socialMedia.filter(
          item => item.uuid !== enrollment.uuid
        )
    }
  }

  // Actions
  @Action({ rawError: true })
  async fetchEnrollment(enrollmentId: number) {
    const response = await apiEnrollment.fetchEnrollment(enrollmentId)
    this.updateEnrollment(mapEnrollment(response.data.data.enrollment))
  }

  @Action({ rawError: true })
  async fetchEnrollments() {
    if (apiEnrollment.enrollmentsRecentlyFetched) {
      return Promise.resolve(this.enrollments)
    }

    const response = await apiEnrollment.enrollments()
    const { mandatoryEnrollments, knowledgeRefresherEnrollments } =
      response.data.data.currentUser
    const enrollments = mandatoryEnrollments.map(enrollment => {
      return mapEnrollment(enrollment)
    })
    const krEnrollments = knowledgeRefresherEnrollments.map(enrollment => {
      return mapEnrollment(enrollment)
    })

    this.context.commit('setEnrollments', enrollments)
    this.context.commit('setKnowledgeRefresherEnrollments', krEnrollments)
  }

  @Action({ rawError: true })
  async fetchOptionalEnrollments({ page, per }) {
    const response = await apiEnrollment.optionalEnrollments(page, per)
    const { optionalEnrollments } = response.data.data.currentUser
    const enrollments = optionalEnrollments.map(enrollment => {
      return mapEnrollment(enrollment)
    })

    this.context.commit('setOptionalEnrollments', {
      page: page,
      enrollments: enrollments,
    })

    // Received a full amount of enrollments, do another call
    return optionalEnrollments.length >= per
  }

  @Action({ rawError: true })
  async fetchElectedEnrollments({ page, per }) {
    const response = await apiEnrollment.electedEnrollments(page, per)
    const { electedEnrollments } = response.data.data.currentUser
    const enrollments = electedEnrollments.map(enrollment => {
      return mapEnrollment(enrollment)
    })

    this.setElectedEnrollments({ page: page, enrollments: enrollments })

    // Received a full amount of enrollments, do another call
    return electedEnrollments.length >= per
  }

  @Action
  async fetchRecommendations({ page, per, query }) {
    const response = await apiEnrollment.recommendations(
      page,
      per,
      QUERIES[query]
    )
    const { lxRecommendations } = response.data.data.currentUser
    const enrollments = lxRecommendations.map(enrollment => {
      return mapEnrollment(enrollment)
    })

    this.setAllRecommendations({
      page: page,
      enrollments: enrollments,
      query: query,
    })

    // Received a full amount of enrollments, do another call
    return lxRecommendations.length
  }

  @Action
  async fetchUploadedContent({ page, per }) {
    const response = await apiEnrollment.uploadedContent(page, per)
    const { ccmItems } = response.data.data
    const enrollments = ccmItems.map(item => {
      return mapUploadedContent(item)
    })

    this.setUploadedContent({ page: page, enrollments: enrollments })

    // Received a full amount of items, do another call
    return ccmItems.length >= per
  }

  @Action
  refreshEnrollment(enrollmentId) {
    return apiEnrollment.fetchEnrollment(enrollmentId).then(response => {
      const enrollment = mapEnrollment(response.data.data.enrollment)
      this.updateEnrollment(enrollment)
      return enrollment
    })
  }

  @Action
  startEnrollment({ enrollmentId, languageCode }) {
    return apiEnrollment
      .startEnrollment(enrollmentId, languageCode)
      .then(response => {
        const enrollment = mapEnrollment(
          response.data.data.enrollmentStart.node
        )
        enrollment.launchPath =
          enrollment.languageOptions.find(e => {
            return e.code === languageCode
          })?.launchPath || '' // not cool

        this.updateEnrollment(enrollment)
        return enrollment
      })
  }

  @Action
  createEnrollment({ enrollmentUUID, languageCode, contentSource }) {
    return apiEnrollment
      .createEnrollment(enrollmentUUID, languageCode, contentSource)
      .then(response => {
        const enrollment = mapEnrollment(
          response.data.data.enrollmentCreate.node
        )
        enrollment.launchPath =
          enrollment.languageOptions.find(e => {
            return e.code === languageCode
          })?.launchPath || '' // not cool

        this.updateEnrollment(enrollment)
        return enrollment
      })
  }

  @Action
  acceptPolicy(enrollmentId) {
    return apiEnrollment.acceptPolicy(enrollmentId).then(response => {
      return response.data
    })
  }

  @Action
  loadKnowledgeRefresherQuiz(enrollmentId) {
    return apiEnrollment.knowledgeRefresherQuiz(enrollmentId).then(response => {
      return response.data
    })
  }

  @Action
  loadKnowledgeRefresherQuizQuestionDetails({ enrollmentId, questionId }) {
    return apiEnrollment
      .knowledgeRefresherQuizQuestionDetails(enrollmentId, questionId)
      .then(response => {
        return response.data
      })
  }

  @Action
  submitSurvey(data) {
    return apiEnrollment.submitSurvey(data).then(response => {
      return response.data
    })
  }

  @Action
  useCachedOptionalEnrollments() {
    return !this.optionalEnrollmentsCache.hasCacheExpired
  }

  @Action
  useCachedRecommendations() {
    return !this.recommendationsCache.hasCacheExpired
  }

  get numberOfEnrollments(): number {
    return this.enrollments.length
  }

  get numberOfOptionalEnrollments(): number {
    return this.optionalEnrollments.length
  }

  get noEnrollments(): boolean {
    return this.enrollments.length === 0
  }

  get noOptionalEnrollments(): boolean {
    return this.optionalEnrollments.length === 0
  }

  get incompleteEnrollments(): IEnrollment[] {
    return this.enrollments.filter(item => item.state !== 'completed')
  }

  get incompleteKnowledgeRefreshers(): IEnrollment[] {
    return this.knowledgeRefresherEnrollments.filter(
      e => e.state !== 'completed'
    )
  }

  get allInProgressEnrollments(): IEnrollment[] {
    return this.electedEnrollments
      .concat(
        this.optionalEnrollments.filter(item =>
          ['in_progress', 'do_policy'].includes(item.state)
        )
      )
      .filter(item => item.state !== 'completed')
  }

  get allEnrollments(): IEnrollment[] {
    return this.enrollments.concat(
      this.electedEnrollments,
      this.optionalEnrollments
    )
  }

  get searchEnrollments() {
    const topicSearch = this.searchState.topics.length
    const typeSearch = this.searchState.contentTypes.length
    const textSearch = this.searchState.searchText.length > 2
    if (textSearch || topicSearch || typeSearch) {
      const lowerCaseSearch = this.searchState.searchText.toLowerCase()
      return uniqBy(
        this.enrollments
          .concat(
            this.allRecommendations.forYou,
            this.allRecommendations.socialMedia,
            this.optionalEnrollments,
            this.electedEnrollments,
            this.uploadedContent
          )
          .filter(en => {
            let titleMatch = false
            let typeMatch = false
            let descriptionMatch = false
            let topicMatch = false

            if (this.searchState.contentTypes.includes(en.typeTranslationKey)) {
              typeMatch = true
            }
            if (
              intersection(
                this.searchState.topics,
                en.topics.map(t => t.translationKey)
              ).length
            ) {
              topicMatch = true
            }

            if (textSearch) {
              en.originalLanguageOptions.map(tr => {
                if (
                  tr.title &&
                  tr.title.toLowerCase().includes(lowerCaseSearch)
                ) {
                  titleMatch = true
                }
                if (
                  tr.description &&
                  tr.description.toLowerCase().includes(lowerCaseSearch)
                ) {
                  descriptionMatch = true
                }
              })
            }

            return (
              (!topicSearch || topicMatch) &&
              (!typeSearch || typeMatch) &&
              (!textSearch || titleMatch || descriptionMatch)
            )
          }),
        x => x.uuid
      )
    } else {
      return []
    }
  }

  get numberOfIncompleteEnrollments(): number {
    return this.incompleteEnrollments.length
  }

  get lastFiveEnrollments(): IEnrollment[] {
    return sortBy(this.enrollments, (e: IEnrollment) => e.createdAt)
      .reverse()
      .slice(0, 5)
  }

  get recommendations() {
    return this.allRecommendations.forYou.concat(
      this.allRecommendations.socialMedia
    )
  }

  get recommendationsAndUploadedContent() {
    return this.recommendations.concat(this.uploadedContent)
  }
}

function findAndSetEnrollment(enrollmentArray, enrollment) {
  let i = findIndex(enrollmentArray, e => e.id === enrollment.id)

  // needed when the item does not exists in array (ie. directly linking to detail view)
  if (i === -1) {
    i = 0
  }
  Vue.set(enrollmentArray, i, enrollment)
}

export default getModule(Enrollments)
