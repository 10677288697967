export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  BigInt: any
  CoercedBigInt: any
  CoercedInt: any
  File: any
  ISO8601Date: any
  ISO8601DateTime: any
  JSON: any
  Time: any
  Url: any
}

/** An account */
export type Account = Identifier &
  Timestamps & {
    __typename?: 'Account'
    /** The time in minutes before a user is logged out due to inactivity */
    accountAdminTimeout: Scalars['Int']
    /** The first "full admin" of your account */
    accountOwner?: Maybe<User>
    /** The full admins of your account */
    accountOwners: Array<User>
    /** Get the risk score history for the account */
    accountRiskScoreHistories: Array<AccountRiskScoreHistory>
    accountSeatOverageThreshold?: Maybe<Scalars['Int']>
    accountSettingsFlagNames: Array<Scalars['String']>
    accountSettingsKcm?: Maybe<Kcm>
    accountType?: Maybe<Scalars['String']>
    /** The actual subscription for the account */
    actualSubscription?: Maybe<Subscription>
    adiRateLimited: Scalars['Boolean']
    allowAnalytics: Scalars['Boolean']
    /** whether users are allowed to sign up while logging in */
    allowUsersToSignup: Scalars['Boolean']
    /** a list of allowed domains */
    allowedDomains: Array<AllowedDomain>
    anonymizePhishing: Scalars['Boolean']
    anonymizedGroupSize: Scalars['Int']
    /** whether the API (V1) is enabled */
    apiEnabled?: Maybe<Scalars['Boolean']>
    apiLimit?: Maybe<ApiLimit>
    /** The account's old API token */
    apiToken?: Maybe<Scalars['String']>
    apiv2Enabled: Scalars['Boolean']
    /** whether your account is archived */
    archived: Scalars['Boolean']
    /** Descriptive information for displaying audit logs */
    auditLogDisplay?: Maybe<Scalars['String']>
    auditLogEnabled: Scalars['Boolean']
    /** The "Company size" selected when viewing benchmark stats on dashboard */
    benchmarkCompanySize: BenchmarkCompanySize
    /** The "Industry" selected when viewing benchmark stats on dashboard */
    benchmarkIndustryId?: Maybe<Scalars['Int']>
    /** The "Timeframe" selected when viewing benchmark stats on dashboard */
    benchmarkTimeframe?: Maybe<BenchmarkTimeframes>
    betaEnabled?: Maybe<Scalars['Boolean']>
    billingType: AccountBillingTypes
    /** The days of the week designated as business days */
    businessDays: Array<Scalars['Int']>
    /** when your default business hours end in the format of an hour in 24-hour format */
    businessHoursEndHour: Scalars['Time']
    /** when your default business hours start in the format of an hour in 24-hour format */
    businessHoursStartHour: Scalars['Time']
    canDownloadModules?: Maybe<Scalars['Boolean']>
    cannotBeDeleted: Scalars['Boolean']
    ccmUrl: Scalars['String']
    certBackgroundFileName?: Maybe<Scalars['String']>
    /** the certificate background image url */
    certBackgroundUrl?: Maybe<Scalars['String']>
    /** the certificate preview url */
    certPreviewUrl: Scalars['String']
    /** the certificate size */
    certSize: Scalars['String']
    /** the billing address city */
    city?: Maybe<Scalars['String']>
    /** the company name */
    companyName?: Maybe<Scalars['String']>
    /** the billing address country */
    country?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    csmSettings?: Maybe<Csm>
    /** The current Phish Prone Percentage for the account */
    currentPpp: Scalars['Float']
    /** whether the custom phishing header is enabled */
    customPhishingHeaderEnabled?: Maybe<Scalars['Boolean']>
    /** the name of the custom phishing header */
    customPhishingHeaderName?: Maybe<Scalars['String']>
    /** the value of the custom phishing header */
    customPhishingHeaderValue?: Maybe<Scalars['String']>
    /** whether the custom training header is enabled */
    customTrainingHeaderEnabled?: Maybe<Scalars['Boolean']>
    /** the name of the custom training header */
    customTrainingHeaderName?: Maybe<Scalars['String']>
    /** the value of the custom training header */
    customTrainingHeaderValue?: Maybe<Scalars['String']>
    /** The default landing domain for the account */
    defaultLandingDomain: LandingDomain
    /** The default landing page for the account */
    defaultLandingPage: LandingPage
    /** The default locale for the account (en or en-GB) */
    defaultLocale: Scalars['String']
    deletionRequestedAt?: Maybe<Scalars['ISO8601DateTime']>
    deletionRequestedByEmail?: Maybe<Scalars['String']>
    deletionRequestedById?: Maybe<Scalars['Int']>
    /** Disable Email Open Tracking */
    disableOpenTracking?: Maybe<Scalars['Boolean']>
    /** whether passwordless logins for admins is disabled */
    disablePasslessForAdmins: Scalars['Boolean']
    /** whether the phishing test header is disabled */
    disablePhishTestHeader?: Maybe<Scalars['Boolean']>
    /** whether the phishing return path header is disabled */
    disableReturnPathHeader?: Maybe<Scalars['Boolean']>
    /** whether the training return path header is disabled */
    disableReturnPathHeaderForTraining: Scalars['Boolean']
    /** The display name of the account */
    displayName: Scalars['String']
    /** The display name of the account with the domain */
    displayNameWithDomain: Scalars['String']
    /** Whether DMI is enabled */
    dmiEnabled?: Maybe<Scalars['Boolean']>
    /** Whether DMI failed */
    dmiFailed?: Maybe<Scalars['Boolean']>
    domain: Scalars['String']
    /** The EEC settings for the account */
    eecSettings: AccountSettingsEec
    /** Reurns whether your phishing security text token header is enabled */
    enablePstTokenHeader?: Maybe<Scalars['Boolean']>
    /** The environment questionnaire for the account */
    environmentQuestionnaire?: Maybe<EnvironmentQuestionnaire>
    /** Phone extension for reaching a specific department or person */
    extension?: Maybe<Scalars['String']>
    /** The policy for enforcing multi-factor authentication */
    forceMfa: ForceMfaOptions
    freeDeletable: Scalars['Boolean']
    /** Whether the free PRT has started */
    freePrtStarted: Scalars['Boolean']
    /** Whether the free PST has started */
    freePstStarted: Scalars['Boolean']
    /** Whether the free QRT has started */
    freeQrtStarted: Scalars['Boolean']
    /** Whether the free SPT has started */
    freeSptStarted: Scalars['Boolean']
    /** The gamification settings for the account */
    gamification?: Maybe<Gamification>
    groups: Array<Group>
    /** Whether the account has AIDA Blue (Deprecated?) */
    hasAidaBlue: Scalars['Boolean']
    hasApi: Scalars['Boolean']
    hasApiv2: Scalars['Boolean']
    /** Whether the free ASAP has started */
    hasAsapStarted?: Maybe<Scalars['Boolean']>
    /** whether your account has our free phishing reply test */
    hasFreePrt: Scalars['Boolean']
    /** whether your account has our free phishing security test */
    hasFreePst: Scalars['Boolean']
    /** whether your account has our free QR test */
    hasFreeQrt: Scalars['Boolean']
    /** whether your account has our free social media phishing test */
    hasFreeSpt: Scalars['Boolean']
    /** whether your account has our free USB test */
    hasFreeUsb: Scalars['Boolean']
    hasLdap: Scalars['Boolean']
    /** whether your account has only stale content available */
    hasOnlyStaleContent?: Maybe<Scalars['Boolean']>
    /** whether passwordless logins are enabled */
    hasPassless: Scalars['Boolean']
    hasPasswordIq?: Maybe<Scalars['Boolean']>
    hasPermissions: Scalars['Boolean']
    hasPhishing?: Maybe<Scalars['Boolean']>
    hasPhysicalQr?: Maybe<Scalars['Boolean']>
    hasSapaScores: Scalars['Boolean']
    hasScsScores: Scalars['Boolean']
    /** whether the account has second chance enabled */
    hasSecondChance?: Maybe<Scalars['Boolean']>
    hasSei?: Maybe<Scalars['Boolean']>
    hasSharedDomains?: Maybe<Scalars['Boolean']>
    hasTraining?: Maybe<Scalars['Boolean']>
    hasUsb?: Maybe<Scalars['Boolean']>
    hasUserEventApi: Scalars['Boolean']
    hasWebhooks: Scalars['Boolean']
    hiddenCategoriesUuids?: Maybe<Array<Scalars['String']>>
    hiddenEmailTemplatesUuids?: Maybe<Array<Scalars['String']>>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The fingerprint of the IDP certificate */
    idpCertFingerprint?: Maybe<Scalars['String']>
    /** The format of the IDP certificate */
    idpCertFormat: IdpCertificationFormats
    /** The target URL for the IDP SSO */
    idpSsoTargetUrl?: Maybe<Scalars['String']>
    /** whether archived users are included in reports */
    includeArchivedUsersInReports: Scalars['Boolean']
    industry?: Maybe<Industry>
    industryId?: Maybe<Scalars['Int']>
    /** The IP restriction settings for the account */
    ipRestrictionSettings?: Maybe<AccountSettingsIpRestriction>
    /** The language settings for the account */
    languageSettings?: Maybe<AccountSettingsLanguageDefault>
    /** Get language stats based on group selection */
    languageStats?: Maybe<Array<AccountLanguageStats>>
    /** The latest risk score for the account */
    latestRiskScore?: Maybe<Scalars['Float']>
    /** whether LDAP is enabled */
    ldapEnabled?: Maybe<Scalars['Boolean']>
    /** The LDAP token */
    ldapToken?: Maybe<Scalars['String']>
    /** The learner experience settings for the account */
    learnerExperienceSettings?: Maybe<AccountSettingsLearnerExperience>
    limitLandingPagesHits: Scalars['Boolean']
    lmsForceAvailable: Scalars['Boolean']
    /** A shorthand reference to the company's logo */
    logo?: Maybe<Scalars['String']>
    /** Coordinates for the logo's positioning */
    logoCoords?: Maybe<Scalars['String']>
    /** URL to download the company's logo */
    logoDownloadUrl?: Maybe<Scalars['String']>
    /** Original URL to the company's logo before any modifications */
    logoOriginalUrl?: Maybe<Scalars['String']>
    /** URL to the company's logo */
    logoUrl?: Maybe<Scalars['String']>
    maxLandingPagesHits: Scalars['Int']
    /** The minimum password length for the account */
    minimumPasswordLength: Scalars['Int']
    notesSettings?: Maybe<Notes>
    numberOfSeats?: Maybe<Scalars['Int']>
    /** whether override out of office messages is enabled */
    oofFromOverride: Scalars['Boolean']
    /** The ID of the first "full admin" of your account */
    ownerId?: Maybe<Scalars['Int']>
    /** When the partner access expires */
    partnerAccessExpiration?: Maybe<Scalars['ISO8601DateTime']>
    /** The display name of the parent partner */
    partnerDisplayName?: Maybe<Scalars['String']>
    /** The domain of the parent partner */
    partnerDomain?: Maybe<Scalars['String']>
    partnerHasSfid: Scalars['Boolean']
    partnerId: Scalars['String']
    /** Whether the account has API access through the parent partner */
    partnerSubscriptionHasApi?: Maybe<Scalars['Boolean']>
    /** Whether the account has APIv2 access through the parent partner */
    partnerSubscriptionHasApiv2?: Maybe<Scalars['Boolean']>
    /** Whether the account has User Event API access through the parent partner */
    partnerSubscriptionHasUserEventApi?: Maybe<Scalars['Boolean']>
    /** The type of subscription for the parent partner TODO: DOCS CHANGE THIS!! */
    partnerSubscriptionType?: Maybe<Scalars['String']>
    partnersUsableSfid?: Maybe<Scalars['String']>
    /** The time in minutes before a passwordless login link expires */
    passlessExpiresIn: Scalars['String']
    /** whether PasswordIQ is enabled */
    passwordIqEnabled?: Maybe<Scalars['Boolean']>
    /** The percentage of users phished */
    percentageUsersPhished: Scalars['String']
    /** The percentage of users trained */
    percentageUsersTrained: Scalars['String']
    /** The phish prone percentage of the account */
    phishPronePercentage: Scalars['String']
    /** whether PhishAlert is enabled */
    phishalertEnabled?: Maybe<Scalars['Boolean']>
    /** The settings for PhishAlert */
    phishalertSettings: Array<PhishalertSetting>
    phisherBeta: Scalars['Boolean']
    phisherEnabled: Scalars['Boolean']
    phisherPlusEnabled: Scalars['Boolean']
    phisherSubscriptionEndDate?: Maybe<Scalars['ISO8601Date']>
    /** The number of PSTs run */
    phishingCampaignRunCount: Scalars['Int']
    phishingCampaigns: Array<PhishingCampaign>
    /** The DKIM configuration for phishing emails */
    phishingDkimConfig?: Maybe<DkimConfig>
    /** The phishing settings for the account */
    phishingSettings?: Maybe<AccountSettingsPhishing>
    /** The main contact phone number for the company */
    phoneNumber?: Maybe<Scalars['String']>
    /** The placeholder settings for the account */
    placeholderSettings?: Maybe<Placeholder>
    /** whether the popcorn theater reduces motion lol */
    popcornTheaterReduceMotion: Scalars['Boolean']
    /** The Phish Prone Percentage for the account rounded to 1 decimal place */
    ppp: Scalars['Float']
    /** The primary color for the account */
    primaryColor: Scalars['String']
    prtMax: Scalars['String']
    /** The sender email for the PRT */
    prtSenderEmail?: Maybe<Scalars['String']>
    /** The sender name for the PRT */
    prtSenderName?: Maybe<Scalars['String']>
    /** The number of PSTs */
    pstCount?: Maybe<Scalars['Int']>
    pstMax: Scalars['String']
    /** The token for the PST */
    pstToken?: Maybe<Scalars['String']>
    /** The number of purchased courses for the account */
    purchasedCourseCount?: Maybe<Scalars['Int']>
    purchasedSkus: Array<AccountPurchasedSku>
    qrtMax: Scalars['String']
    refid?: Maybe<Scalars['String']>
    /** The current risk score of the account */
    riskScore: Scalars['String']
    salesforceSettings?: Maybe<Salesforce>
    /** whether SAML is enabled */
    samlEnabled: Scalars['Boolean']
    /** The SAML settings for the account */
    samlSettings?: Maybe<AccountSettingsSaml>
    /** whether SAML user provisioning is enabled */
    samlUserProvisioningEnabled: Scalars['Boolean']
    /** The second chance settings for the account */
    secondChanceSettings?: Maybe<AccountSettingsSecondChanceSetting>
    sfid?: Maybe<Scalars['String']>
    smartGroupsEnabled: Scalars['Boolean']
    sptMax: Scalars['String']
    /** The SSO bypass URL */
    ssoBypass: Scalars['String']
    /** The SSO callback URL */
    ssoCallback?: Maybe<Scalars['String']>
    /** The SSO in URL */
    ssoIn?: Maybe<Scalars['String']>
    /** The SSO metadata URL */
    ssoMetadata?: Maybe<Scalars['String']>
    ssoOriginalStatus: Scalars['Boolean']
    /** The SSO out URL */
    ssoOut?: Maybe<Scalars['String']>
    /** The SSO slug */
    ssoSlug?: Maybe<Scalars['String']>
    /** The state or region where the company is located */
    state?: Maybe<Scalars['String']>
    /** The primary street address of the company */
    streetAddress1?: Maybe<Scalars['String']>
    /** Additional street address information for the company */
    streetAddress2?: Maybe<Scalars['String']>
    studentAccountSeatOverageThreshold?: Maybe<Scalars['Int']>
    /** The number of student edition seats */
    studentEditionSeats?: Maybe<Scalars['Int']>
    /** The number of student users in the account */
    studentUserCount?: Maybe<Scalars['Int']>
    subscriptionEndDate?: Maybe<Scalars['ISO8601Date']>
    subscriptionId?: Maybe<Scalars['Int']>
    subscriptionObject?: Maybe<Subscription>
    subscriptionObjectId?: Maybe<Scalars['Int']>
    /** Suite or unit number for the company's address */
    suiteNumber?: Maybe<Scalars['String']>
    /** The time zone for the account */
    timeZone: TimeZones
    /** The number of training campaigns */
    trainingCampaignCount: Scalars['Int']
    trainingCampaigns: Array<TrainingCampaign>
    /** The DKIM configuration for training emails */
    trainingDkimConfig?: Maybe<DkimConfig>
    /** The training settings for the account */
    trainingSettings?: Maybe<AccountSettingsTraining>
    trialExpirationDate?: Maybe<Scalars['ISO8601Date']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** Whether UPN failed */
    upnFailed?: Maybe<Scalars['Boolean']>
    /** The UPN failed timeframe */
    upnFailedTimeframe?: Maybe<Scalars['Int']>
    userCategoriesUuids?: Maybe<Array<Scalars['String']>>
    /** The number of users in the account */
    userCount?: Maybe<Scalars['Int']>
    userHasPhisherAccount: Scalars['Boolean']
    /** The user moves settings for the account */
    userMoves?: Maybe<AccountSettingsUserMoves>
    /** The user provisioning settings for the account */
    userProvisioning: UserProvisioning
    /** The user provisioning notification settings for the account */
    userProvisioningNotification?: Maybe<UserProvisioningNotification>
    /** The time in minutes before a user is logged out due to inactivity */
    userTimeout: Scalars['Int']
    /** The user settings for the account */
    usersSettings?: Maybe<Users>
    usingPhishingTopics?: Maybe<Scalars['Boolean']>
    validDeletionRequest: Scalars['Boolean']
    /** The postal code for the company's address */
    zipCode?: Maybe<Scalars['String']>
  }

/** An account */
export type AccountAccountRiskScoreHistoriesArgs = {
  fullHistory?: Maybe<Scalars['Boolean']>
}

/** An account */
export type AccountLanguageStatsArgs = {
  groupIds?: Maybe<Array<Scalars['Int']>>
}

export type AccountAttributes = {
  /** The account admin timeout */
  accountAdminTimeout?: Maybe<Scalars['Int']>
  accountOwner?: Maybe<UserAttributes>
  accountSettingsFlagNames?: Maybe<Array<Scalars['String']>>
  accountSettingsKcm?: Maybe<KcmAttributes>
  accountType?: Maybe<Scalars['String']>
  adiRateLimited?: Maybe<Scalars['Boolean']>
  allowAnalytics?: Maybe<Scalars['Boolean']>
  /** Allow users to sign up */
  allowUsersToSignup?: Maybe<Scalars['Boolean']>
  anonymizePhishing?: Maybe<Scalars['Boolean']>
  anonymizedGroupSize?: Maybe<Scalars['Int']>
  /** API enabled */
  apiEnabled?: Maybe<Scalars['Boolean']>
  apiLimit?: Maybe<ApiLimitSettingsAttributes>
  apiv2Enabled?: Maybe<Scalars['Boolean']>
  auditLogEnabled?: Maybe<Scalars['Boolean']>
  /** Benchmark company size */
  benchmarkCompanySize?: Maybe<BenchmarkCompanySize>
  /** Benchmark industry ID */
  benchmarkIndustryId?: Maybe<Scalars['Int']>
  /** Benchmark timeframe */
  benchmarkTimeframe?: Maybe<BenchmarkTimeframes>
  betaEnabled?: Maybe<Scalars['Boolean']>
  billingType?: Maybe<AccountBillingTypes>
  /** Business days */
  businessDays?: Maybe<Array<Scalars['Int']>>
  /** Business hours end hour */
  businessHoursEndHour?: Maybe<Scalars['Time']>
  /** Business hours start hour */
  businessHoursStartHour?: Maybe<Scalars['Time']>
  canDownloadModules?: Maybe<Scalars['Boolean']>
  certBackground?: Maybe<Scalars['String']>
  certBackgroundFileName?: Maybe<Scalars['String']>
  /** City */
  city?: Maybe<Scalars['String']>
  /** Company name */
  companyName?: Maybe<Scalars['String']>
  /** Country */
  country?: Maybe<Scalars['String']>
  csmSettings?: Maybe<CsmAttributes>
  /** Custom phishing header enabled */
  customPhishingHeaderEnabled?: Maybe<Scalars['Boolean']>
  /** Custom phishing header name */
  customPhishingHeaderName?: Maybe<Scalars['String']>
  /** Custom phishing header value */
  customPhishingHeaderValue?: Maybe<Scalars['String']>
  /** Custom training header enabled */
  customTrainingHeaderEnabled?: Maybe<Scalars['Boolean']>
  /** Custom training header name */
  customTrainingHeaderName?: Maybe<Scalars['String']>
  /** Custom training header value */
  customTrainingHeaderValue?: Maybe<Scalars['String']>
  /** Default landing domain ID */
  defaultLandingDomainId?: Maybe<Scalars['Int']>
  /** Default landing page ID */
  defaultLandingPageId?: Maybe<Scalars['Int']>
  /** Default locale */
  defaultLocale?: Maybe<Scalars['String']>
  /** Disable open tracking */
  disableOpenTracking?: Maybe<Scalars['Boolean']>
  /** Disable passless for admins */
  disablePasslessForAdmins?: Maybe<Scalars['Boolean']>
  /** Disable phish test header */
  disablePhishTestHeader?: Maybe<Scalars['Boolean']>
  /** Disable return path header */
  disableReturnPathHeader?: Maybe<Scalars['Boolean']>
  /** Disable return path header for training */
  disableReturnPathHeaderForTraining?: Maybe<Scalars['Boolean']>
  domain?: Maybe<Scalars['String']>
  /** EEC settings */
  eecSettings?: Maybe<AccountEecSettingsAttributes>
  /** Enable PST token header */
  enablePstTokenHeader?: Maybe<Scalars['Boolean']>
  /** Extension */
  extension?: Maybe<Scalars['String']>
  /** Gamification */
  gamification?: Maybe<GamificationAttributes>
  hasApi?: Maybe<Scalars['Boolean']>
  hasApiv2?: Maybe<Scalars['Boolean']>
  hasLdap?: Maybe<Scalars['Boolean']>
  /** Has passless */
  hasPassless?: Maybe<Scalars['Boolean']>
  hasPasswordIq?: Maybe<Scalars['Boolean']>
  hasPermissions?: Maybe<Scalars['Boolean']>
  hasPhishing?: Maybe<Scalars['Boolean']>
  hasPhysicalQr?: Maybe<Scalars['Boolean']>
  /** Has second chance */
  hasSecondChance?: Maybe<Scalars['Boolean']>
  hasSei?: Maybe<Scalars['Boolean']>
  hasSharedDomains?: Maybe<Scalars['Boolean']>
  hasTraining?: Maybe<Scalars['Boolean']>
  hasUsb?: Maybe<Scalars['Boolean']>
  hasUserEventApi?: Maybe<Scalars['Boolean']>
  hasWebhooks?: Maybe<Scalars['Boolean']>
  /** IDP cert fingerprint */
  idpCertFingerprint?: Maybe<Scalars['String']>
  /** IDP cert format */
  idpCertFormat?: Maybe<IdpCertificationFormats>
  /** IDP SSO target URL */
  idpSsoTargetUrl?: Maybe<Scalars['String']>
  /** Include archived users in reports */
  includeArchivedUsersInReports?: Maybe<Scalars['Boolean']>
  industryId?: Maybe<Scalars['Int']>
  /** IP restriction settings */
  ipRestrictionSettings?: Maybe<AccountIpRestrictionSettingsAttributes>
  /** Language settings */
  languageSettings?: Maybe<DefaultLanguages>
  /** LDAP enabled */
  ldapEnabled?: Maybe<Scalars['Boolean']>
  /** LDAP test mode */
  ldapTestMode?: Maybe<Scalars['Boolean']>
  /** Learner experience settings */
  learnerExperienceSettings?: Maybe<AccountLearnerExperienceSettingsAttributes>
  limitLandingPagesHits?: Maybe<Scalars['Boolean']>
  lmsForceAvailable?: Maybe<Scalars['Boolean']>
  /** Logo */
  logo?: Maybe<Scalars['String']>
  /** Logo coords */
  logoCoords?: Maybe<Scalars['String']>
  /** Logo file name */
  logoFileName?: Maybe<Scalars['String']>
  /** Logo original URL */
  logoOriginalUrl?: Maybe<Scalars['String']>
  /** Logo URL */
  logoUrl?: Maybe<Scalars['String']>
  maxLandingPagesHits?: Maybe<Scalars['Int']>
  /** Minimum password length */
  minimumPasswordLength?: Maybe<Scalars['Int']>
  notesSettings?: Maybe<NotesAttributes>
  numberOfSeats?: Maybe<Scalars['Int']>
  /** OOF from override */
  oofFromOverride?: Maybe<Scalars['Boolean']>
  partnerId?: Maybe<Scalars['String']>
  /** Passless expires in */
  passlessExpiresIn?: Maybe<Scalars['String']>
  /** Password IQ enabled */
  passwordIqEnabled?: Maybe<Scalars['Boolean']>
  /** Phish Alert enabled */
  phishalertEnabled?: Maybe<Scalars['Boolean']>
  /** Phish Alert settings */
  phishalertSettings?: Maybe<Array<PhishalertSettingAttributes>>
  phisherBeta?: Maybe<Scalars['Boolean']>
  phisherEnabled?: Maybe<Scalars['Boolean']>
  phisherPlusEnabled?: Maybe<Scalars['Boolean']>
  phisherSubscriptionEndDate?: Maybe<Scalars['String']>
  /** Phishing settings */
  phishingSettings?: Maybe<AccountPhishingSettingsAttributes>
  /** Phone number */
  phoneNumber?: Maybe<Scalars['String']>
  /** Placeholder settings */
  placeholderSettings?: Maybe<PlaceholderSettingsAttributes>
  /** Popcorn theater reduce motion */
  popcornTheaterReduceMotion?: Maybe<Scalars['Boolean']>
  /** Primary color */
  primaryColor?: Maybe<Scalars['String']>
  prtMax?: Maybe<Scalars['String']>
  pstMax?: Maybe<Scalars['String']>
  purchasedSkus?: Maybe<Array<AccountPurchasedSkusAttributes>>
  qrtMax?: Maybe<Scalars['String']>
  salesforceSettings?: Maybe<SalesforceAttributes>
  /** SAML enabled */
  samlEnabled?: Maybe<Scalars['Boolean']>
  /** SAML settings */
  samlSettings?: Maybe<SamlSettingsAttributes>
  /** SAML user provisioning enabled */
  samlUserProvisioningEnabled?: Maybe<Scalars['Boolean']>
  /** Second Chance settings */
  secondChanceSettings?: Maybe<SecondChanceSettingAttributes>
  sfid?: Maybe<Scalars['String']>
  smartGroupsEnabled?: Maybe<Scalars['Boolean']>
  sptMax?: Maybe<Scalars['String']>
  /** State */
  state?: Maybe<Scalars['String']>
  /** Street address 1 */
  streetAddress1?: Maybe<Scalars['String']>
  /** Street address 2 */
  streetAddress2?: Maybe<Scalars['String']>
  subscriptionEndDate?: Maybe<Scalars['String']>
  subscriptionObjectId?: Maybe<Scalars['Int']>
  /** Suite number */
  suiteNumber?: Maybe<Scalars['String']>
  /** Time zone */
  timeZone?: Maybe<TimeZones>
  /** Training settings */
  trainingSettings?: Maybe<AccountTrainingSettingsAttributes>
  trialExpirationDate?: Maybe<Scalars['String']>
  /** User moves */
  userMoves?: Maybe<UserMovesSettingAttributes>
  /** User provisioning */
  userProvisioning?: Maybe<UserProvisioningAttributes>
  /** User provisioning notification */
  userProvisioningNotification?: Maybe<UserProvisioningNotificationAttributes>
  /** User timeout */
  userTimeout?: Maybe<Scalars['Int']>
  /** Users settings */
  usersSettings?: Maybe<UsersAttributes>
  /** Zip code */
  zipCode?: Maybe<Scalars['String']>
}

export enum AccountBillingTypes {
  Any = 'ANY',
  Free = 'FREE',
  Paid = 'PAID',
  Trial = 'TRIAL',
}

export type AccountEecSettingsAttributes = {
  id?: Maybe<Scalars['Int']>
  lastScannedAt?: Maybe<Scalars['ISO8601DateTime']>
  scanDayOfMonth: Scalars['Int']
}

export type AccountIpRestrictionSettingsAttributes = {
  enforceSameIp?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['Int']>
  ipAllowList?: Maybe<Scalars['String']>
  restrictConsoleSession?: Maybe<Scalars['Boolean']>
}

/** The aggregated language stats of an account */
export type AccountLanguageStats = {
  __typename?: 'AccountLanguageStats'
  /** The code of the language */
  languageCode?: Maybe<Scalars['String']>
  /** The number of users associated with the language */
  numberOfUsers?: Maybe<Scalars['Int']>
}

export type AccountLearnerExperienceSettingsAttributes = {
  aidaOptionalTrainingAvailable?: Maybe<Scalars['Boolean']>
  aidaOptionalTrainingEnabled?: Maybe<Scalars['Boolean']>
  customHelpEnabled?: Maybe<Scalars['Boolean']>
  customHelpUrl?: Maybe<Scalars['String']>
  dashboardEnabled?: Maybe<Scalars['Boolean']>
  dashboardShowsPhishingStatistics?: Maybe<Scalars['Boolean']>
  dashboardShowsRiskStatistics?: Maybe<Scalars['Boolean']>
  disableTour?: Maybe<Scalars['Boolean']>
  enableUsersToRequestLearnerAppOnboardingEmails?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['Int']>
  managerPhishingEnabled?: Maybe<Scalars['Boolean']>
  managerRiskScoreEnabled?: Maybe<Scalars['Boolean']>
  managerTrainingEnabled?: Maybe<Scalars['Boolean']>
  managerUserInfoEnabled?: Maybe<Scalars['Boolean']>
  mobileAppEnabled?: Maybe<Scalars['Boolean']>
  optionalTrainingEnabled?: Maybe<Scalars['Boolean']>
}

/** An account */
export type AccountMultiInstance = Identifier &
  Timestamps & {
    __typename?: 'AccountMultiInstance'
    /** The time in minutes before a user is logged out due to inactivity */
    accountAdminTimeout: Scalars['Int']
    /** The first "full admin" of your account */
    accountOwner?: Maybe<User>
    /** The full admins of your account */
    accountOwners: Array<User>
    /** Get the risk score history for the account */
    accountRiskScoreHistories: Array<AccountRiskScoreHistory>
    accountSeatOverageThreshold?: Maybe<Scalars['Int']>
    accountSettingsFlagNames: Array<Scalars['String']>
    accountSettingsKcm?: Maybe<Kcm>
    accountType?: Maybe<Scalars['String']>
    /** The actual subscription for the account */
    actualSubscription?: Maybe<Subscription>
    adiRateLimited: Scalars['Boolean']
    allowAnalytics: Scalars['Boolean']
    /** whether users are allowed to sign up while logging in */
    allowUsersToSignup: Scalars['Boolean']
    /** a list of allowed domains */
    allowedDomains: Array<AllowedDomain>
    anonymizePhishing: Scalars['Boolean']
    anonymizedGroupSize: Scalars['Int']
    /** whether the API (V1) is enabled */
    apiEnabled?: Maybe<Scalars['Boolean']>
    apiLimit?: Maybe<ApiLimit>
    /** The account's old API token */
    apiToken?: Maybe<Scalars['String']>
    apiv2Enabled: Scalars['Boolean']
    /** whether your account is archived */
    archived: Scalars['Boolean']
    /** Descriptive information for displaying audit logs */
    auditLogDisplay?: Maybe<Scalars['String']>
    auditLogEnabled: Scalars['Boolean']
    /** The "Company size" selected when viewing benchmark stats on dashboard */
    benchmarkCompanySize: BenchmarkCompanySize
    /** The "Industry" selected when viewing benchmark stats on dashboard */
    benchmarkIndustryId?: Maybe<Scalars['Int']>
    /** The "Timeframe" selected when viewing benchmark stats on dashboard */
    benchmarkTimeframe?: Maybe<BenchmarkTimeframes>
    betaEnabled?: Maybe<Scalars['Boolean']>
    billingType: AccountBillingTypes
    /** The days of the week designated as business days */
    businessDays: Array<Scalars['Int']>
    /** when your default business hours end in the format of an hour in 24-hour format */
    businessHoursEndHour: Scalars['Time']
    /** when your default business hours start in the format of an hour in 24-hour format */
    businessHoursStartHour: Scalars['Time']
    canDownloadModules?: Maybe<Scalars['Boolean']>
    cannotBeDeleted: Scalars['Boolean']
    ccmUrl: Scalars['String']
    certBackgroundFileName?: Maybe<Scalars['String']>
    /** the certificate background image url */
    certBackgroundUrl?: Maybe<Scalars['String']>
    /** the certificate preview url */
    certPreviewUrl: Scalars['String']
    /** the certificate size */
    certSize: Scalars['String']
    /** the billing address city */
    city?: Maybe<Scalars['String']>
    /** the company name */
    companyName?: Maybe<Scalars['String']>
    /** the billing address country */
    country?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    csmSettings?: Maybe<Csm>
    /** The current Phish Prone Percentage for the account */
    currentPpp: Scalars['Float']
    /** whether the custom phishing header is enabled */
    customPhishingHeaderEnabled?: Maybe<Scalars['Boolean']>
    /** the name of the custom phishing header */
    customPhishingHeaderName?: Maybe<Scalars['String']>
    /** the value of the custom phishing header */
    customPhishingHeaderValue?: Maybe<Scalars['String']>
    /** whether the custom training header is enabled */
    customTrainingHeaderEnabled?: Maybe<Scalars['Boolean']>
    /** the name of the custom training header */
    customTrainingHeaderName?: Maybe<Scalars['String']>
    /** the value of the custom training header */
    customTrainingHeaderValue?: Maybe<Scalars['String']>
    /** The default landing domain for the account */
    defaultLandingDomain: LandingDomain
    /** The default landing page for the account */
    defaultLandingPage: LandingPage
    /** The default locale for the account (en or en-GB) */
    defaultLocale: Scalars['String']
    deletionRequestedAt?: Maybe<Scalars['ISO8601DateTime']>
    deletionRequestedByEmail?: Maybe<Scalars['String']>
    deletionRequestedById?: Maybe<Scalars['Int']>
    /** Disable Email Open Tracking */
    disableOpenTracking?: Maybe<Scalars['Boolean']>
    /** whether passwordless logins for admins is disabled */
    disablePasslessForAdmins: Scalars['Boolean']
    /** whether the phishing test header is disabled */
    disablePhishTestHeader?: Maybe<Scalars['Boolean']>
    /** whether the phishing return path header is disabled */
    disableReturnPathHeader?: Maybe<Scalars['Boolean']>
    /** whether the training return path header is disabled */
    disableReturnPathHeaderForTraining: Scalars['Boolean']
    /** The display name of the account */
    displayName: Scalars['String']
    /** The display name of the account with the domain */
    displayNameWithDomain: Scalars['String']
    /** Whether DMI is enabled */
    dmiEnabled?: Maybe<Scalars['Boolean']>
    /** Whether DMI failed */
    dmiFailed?: Maybe<Scalars['Boolean']>
    domain: Scalars['String']
    /** The EEC settings for the account */
    eecSettings: AccountSettingsEec
    /** Reurns whether your phishing security text token header is enabled */
    enablePstTokenHeader?: Maybe<Scalars['Boolean']>
    /** The environment questionnaire for the account */
    environmentQuestionnaire?: Maybe<EnvironmentQuestionnaire>
    /** Phone extension for reaching a specific department or person */
    extension?: Maybe<Scalars['String']>
    /** The policy for enforcing multi-factor authentication */
    forceMfa: ForceMfaOptions
    freeDeletable: Scalars['Boolean']
    /** Whether the free PRT has started */
    freePrtStarted: Scalars['Boolean']
    /** Whether the free PST has started */
    freePstStarted: Scalars['Boolean']
    /** Whether the free QRT has started */
    freeQrtStarted: Scalars['Boolean']
    /** Whether the free SPT has started */
    freeSptStarted: Scalars['Boolean']
    /** The gamification settings for the account */
    gamification?: Maybe<Gamification>
    groups: Array<Group>
    /** Whether the account has AIDA Blue (Deprecated?) */
    hasAidaBlue: Scalars['Boolean']
    hasApi: Scalars['Boolean']
    hasApiv2: Scalars['Boolean']
    /** Whether the free ASAP has started */
    hasAsapStarted?: Maybe<Scalars['Boolean']>
    /** whether your account has our free phishing reply test */
    hasFreePrt: Scalars['Boolean']
    /** whether your account has our free phishing security test */
    hasFreePst: Scalars['Boolean']
    /** whether your account has our free QR test */
    hasFreeQrt: Scalars['Boolean']
    /** whether your account has our free social media phishing test */
    hasFreeSpt: Scalars['Boolean']
    /** whether your account has our free USB test */
    hasFreeUsb: Scalars['Boolean']
    hasLdap: Scalars['Boolean']
    /** whether your account has only stale content available */
    hasOnlyStaleContent?: Maybe<Scalars['Boolean']>
    /** whether passwordless logins are enabled */
    hasPassless: Scalars['Boolean']
    hasPasswordIq?: Maybe<Scalars['Boolean']>
    hasPermissions: Scalars['Boolean']
    hasPhishing?: Maybe<Scalars['Boolean']>
    hasPhysicalQr?: Maybe<Scalars['Boolean']>
    hasSapaScores: Scalars['Boolean']
    hasScsScores: Scalars['Boolean']
    /** whether the account has second chance enabled */
    hasSecondChance?: Maybe<Scalars['Boolean']>
    hasSei?: Maybe<Scalars['Boolean']>
    hasSharedDomains?: Maybe<Scalars['Boolean']>
    hasTraining?: Maybe<Scalars['Boolean']>
    hasUsb?: Maybe<Scalars['Boolean']>
    hasUserEventApi: Scalars['Boolean']
    hasWebhooks: Scalars['Boolean']
    hiddenCategoriesUuids?: Maybe<Array<Scalars['String']>>
    hiddenEmailTemplatesUuids?: Maybe<Array<Scalars['String']>>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The fingerprint of the IDP certificate */
    idpCertFingerprint?: Maybe<Scalars['String']>
    /** The format of the IDP certificate */
    idpCertFormat: IdpCertificationFormats
    /** The target URL for the IDP SSO */
    idpSsoTargetUrl?: Maybe<Scalars['String']>
    /** whether archived users are included in reports */
    includeArchivedUsersInReports: Scalars['Boolean']
    industry?: Maybe<Industry>
    industryId?: Maybe<Scalars['Int']>
    /** The IP restriction settings for the account */
    ipRestrictionSettings?: Maybe<AccountSettingsIpRestriction>
    /** The language settings for the account */
    languageSettings?: Maybe<AccountSettingsLanguageDefault>
    /** Get language stats based on group selection */
    languageStats?: Maybe<Array<AccountLanguageStats>>
    /** The latest risk score for the account */
    latestRiskScore?: Maybe<Scalars['Float']>
    /** whether LDAP is enabled */
    ldapEnabled?: Maybe<Scalars['Boolean']>
    /** The LDAP token */
    ldapToken?: Maybe<Scalars['String']>
    /** The learner experience settings for the account */
    learnerExperienceSettings?: Maybe<AccountSettingsLearnerExperience>
    limitLandingPagesHits: Scalars['Boolean']
    lmsForceAvailable: Scalars['Boolean']
    /** A shorthand reference to the company's logo */
    logo?: Maybe<Scalars['String']>
    /** Coordinates for the logo's positioning */
    logoCoords?: Maybe<Scalars['String']>
    /** URL to download the company's logo */
    logoDownloadUrl?: Maybe<Scalars['String']>
    /** Original URL to the company's logo before any modifications */
    logoOriginalUrl?: Maybe<Scalars['String']>
    /** URL to the company's logo */
    logoUrl?: Maybe<Scalars['String']>
    maxLandingPagesHits: Scalars['Int']
    /** The minimum password length for the account */
    minimumPasswordLength: Scalars['Int']
    notesSettings?: Maybe<Notes>
    numberOfSeats?: Maybe<Scalars['Int']>
    /** whether override out of office messages is enabled */
    oofFromOverride: Scalars['Boolean']
    /** The ID of the first "full admin" of your account */
    ownerId?: Maybe<Scalars['Int']>
    /** When the partner access expires */
    partnerAccessExpiration?: Maybe<Scalars['ISO8601DateTime']>
    /** The display name of the parent partner */
    partnerDisplayName?: Maybe<Scalars['String']>
    /** The domain of the parent partner */
    partnerDomain?: Maybe<Scalars['String']>
    partnerHasSfid: Scalars['Boolean']
    partnerId: Scalars['String']
    /** Whether the account has API access through the parent partner */
    partnerSubscriptionHasApi?: Maybe<Scalars['Boolean']>
    /** Whether the account has APIv2 access through the parent partner */
    partnerSubscriptionHasApiv2?: Maybe<Scalars['Boolean']>
    /** Whether the account has User Event API access through the parent partner */
    partnerSubscriptionHasUserEventApi?: Maybe<Scalars['Boolean']>
    /** The type of subscription for the parent partner TODO: DOCS CHANGE THIS!! */
    partnerSubscriptionType?: Maybe<Scalars['String']>
    partnersUsableSfid?: Maybe<Scalars['String']>
    /** The time in minutes before a passwordless login link expires */
    passlessExpiresIn: Scalars['String']
    /** whether PasswordIQ is enabled */
    passwordIqEnabled?: Maybe<Scalars['Boolean']>
    /** The percentage of users phished */
    percentageUsersPhished: Scalars['String']
    /** The percentage of users trained */
    percentageUsersTrained: Scalars['String']
    /** The phish prone percentage of the account */
    phishPronePercentage: Scalars['String']
    /** whether PhishAlert is enabled */
    phishalertEnabled?: Maybe<Scalars['Boolean']>
    /** The settings for PhishAlert */
    phishalertSettings: Array<PhishalertSetting>
    phisherBeta: Scalars['Boolean']
    phisherEnabled: Scalars['Boolean']
    phisherPlusEnabled: Scalars['Boolean']
    phisherSubscriptionEndDate?: Maybe<Scalars['ISO8601Date']>
    /** The number of PSTs run */
    phishingCampaignRunCount: Scalars['Int']
    phishingCampaigns: Array<PhishingCampaign>
    /** The DKIM configuration for phishing emails */
    phishingDkimConfig?: Maybe<DkimConfig>
    /** The phishing settings for the account */
    phishingSettings?: Maybe<AccountSettingsPhishing>
    /** The main contact phone number for the company */
    phoneNumber?: Maybe<Scalars['String']>
    /** The placeholder settings for the account */
    placeholderSettings?: Maybe<Placeholder>
    /** whether the popcorn theater reduces motion lol */
    popcornTheaterReduceMotion: Scalars['Boolean']
    /** The Phish Prone Percentage for the account rounded to 1 decimal place */
    ppp: Scalars['Float']
    /** The primary color for the account */
    primaryColor: Scalars['String']
    prtMax: Scalars['String']
    /** The sender email for the PRT */
    prtSenderEmail?: Maybe<Scalars['String']>
    /** The sender name for the PRT */
    prtSenderName?: Maybe<Scalars['String']>
    /** The number of PSTs */
    pstCount?: Maybe<Scalars['Int']>
    pstMax: Scalars['String']
    /** The token for the PST */
    pstToken?: Maybe<Scalars['String']>
    /** The number of purchased courses for the account */
    purchasedCourseCount?: Maybe<Scalars['Int']>
    purchasedSkus: Array<AccountPurchasedSku>
    qrtMax: Scalars['String']
    refid?: Maybe<Scalars['String']>
    /** The current risk score of the account */
    riskScore: Scalars['String']
    salesforceSettings?: Maybe<Salesforce>
    /** whether SAML is enabled */
    samlEnabled: Scalars['Boolean']
    /** The SAML settings for the account */
    samlSettings?: Maybe<AccountSettingsSaml>
    /** whether SAML user provisioning is enabled */
    samlUserProvisioningEnabled: Scalars['Boolean']
    /** The second chance settings for the account */
    secondChanceSettings?: Maybe<AccountSettingsSecondChanceSetting>
    sfid?: Maybe<Scalars['String']>
    smartGroupsEnabled: Scalars['Boolean']
    sptMax: Scalars['String']
    /** The SSO bypass URL */
    ssoBypass: Scalars['String']
    /** The SSO callback URL */
    ssoCallback?: Maybe<Scalars['String']>
    /** The SSO in URL */
    ssoIn?: Maybe<Scalars['String']>
    /** The SSO metadata URL */
    ssoMetadata?: Maybe<Scalars['String']>
    ssoOriginalStatus: Scalars['Boolean']
    /** The SSO out URL */
    ssoOut?: Maybe<Scalars['String']>
    /** The SSO slug */
    ssoSlug?: Maybe<Scalars['String']>
    /** The state or region where the company is located */
    state?: Maybe<Scalars['String']>
    /** The primary street address of the company */
    streetAddress1?: Maybe<Scalars['String']>
    /** Additional street address information for the company */
    streetAddress2?: Maybe<Scalars['String']>
    studentAccountSeatOverageThreshold?: Maybe<Scalars['Int']>
    /** The number of student edition seats */
    studentEditionSeats?: Maybe<Scalars['Int']>
    /** The number of student users in the account */
    studentUserCount?: Maybe<Scalars['Int']>
    subscriptionEndDate?: Maybe<Scalars['ISO8601Date']>
    subscriptionId?: Maybe<Scalars['Int']>
    subscriptionObject?: Maybe<Subscription>
    subscriptionObjectId?: Maybe<Scalars['Int']>
    /** Suite or unit number for the company's address */
    suiteNumber?: Maybe<Scalars['String']>
    /** The time zone for the account */
    timeZone: TimeZones
    /** The number of training campaigns */
    trainingCampaignCount: Scalars['Int']
    trainingCampaigns: Array<TrainingCampaign>
    /** The DKIM configuration for training emails */
    trainingDkimConfig?: Maybe<DkimConfig>
    /** The training settings for the account */
    trainingSettings?: Maybe<AccountSettingsTraining>
    trialExpirationDate?: Maybe<Scalars['ISO8601Date']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** Whether UPN failed */
    upnFailed?: Maybe<Scalars['Boolean']>
    /** The UPN failed timeframe */
    upnFailedTimeframe?: Maybe<Scalars['Int']>
    userCategoriesUuids?: Maybe<Array<Scalars['String']>>
    /** The number of users in the account */
    userCount?: Maybe<Scalars['Int']>
    userHasPhisherAccount: Scalars['Boolean']
    /** The user moves settings for the account */
    userMoves?: Maybe<AccountSettingsUserMoves>
    /** The user provisioning settings for the account */
    userProvisioning: UserProvisioning
    /** The user provisioning notification settings for the account */
    userProvisioningNotification?: Maybe<UserProvisioningNotification>
    /** The time in minutes before a user is logged out due to inactivity */
    userTimeout: Scalars['Int']
    /** The user settings for the account */
    usersSettings?: Maybe<Users>
    usingPhishingTopics?: Maybe<Scalars['Boolean']>
    validDeletionRequest: Scalars['Boolean']
    /** The postal code for the company's address */
    zipCode?: Maybe<Scalars['String']>
  }

/** An account */
export type AccountMultiInstanceAccountRiskScoreHistoriesArgs = {
  fullHistory?: Maybe<Scalars['Boolean']>
}

/** An account */
export type AccountMultiInstanceLanguageStatsArgs = {
  groupIds?: Maybe<Array<Scalars['Int']>>
}

export enum AccountPartnerAccessPeriods {
  Disabled = 'DISABLED',
  FiveDays = 'FIVE_DAYS',
  Forever = 'FOREVER',
  FourHours = 'FOUR_HOURS',
  OneDay = 'ONE_DAY',
}

export enum AccountPartnerInclusions {
  All = 'ALL',
  ChildrenOnly = 'CHILDREN_ONLY',
  GrandchildrenOnly = 'GRANDCHILDREN_ONLY',
  GreatGrandchildrenOnly = 'GREAT_GRANDCHILDREN_ONLY',
  NonChildren = 'NON_CHILDREN',
}

export type AccountPhishingSettingsAttributes = {
  aidaSelectedAvailable?: Maybe<Scalars['Boolean']>
  callbackPhishingEnabled?: Maybe<Scalars['Boolean']>
  customDomainPlaceholder?: Maybe<Scalars['String']>
  customDomainPlaceholderEnabled?: Maybe<Scalars['Boolean']>
  disabledTemplateAttackVectors?: Maybe<Array<PhishingAttackVectors>>
  id?: Maybe<Scalars['Int']>
  overrideRphAddress?: Maybe<Scalars['Boolean']>
  templateExclusions?: Maybe<Scalars['JSON']>
}

export enum AccountPurchaseStatuses {
  Paid = 'paid',
  Preview = 'preview',
  Trial = 'trial',
}

/** Purchased SKU added to an account */
export type AccountPurchasedSku = {
  __typename?: 'AccountPurchasedSku'
  /** Indicates whether the SKU is active */
  active: Scalars['Boolean']
  /** The expiration date of the SKU */
  expiresAt?: Maybe<Scalars['ISO8601Date']>
  /** Indicates whether the SKU is within its grace period */
  gracePeriod: Scalars['Boolean']
  /** The code for the SKU */
  skuCode: Scalars['String']
  /** The purchase status of the account SKU */
  status?: Maybe<AccountPurchaseStatuses>
  /** The title of the purchased SKU */
  title: Scalars['String']
}

export type AccountPurchasedSkusAttributes = {
  expiresAt?: Maybe<Scalars['ISO8601DateTime']>
  skuCode: Scalars['String']
  status: AccountPurchaseStatuses
  title: Scalars['String']
}

/** Risk Score for an account */
export type AccountRiskScoreHistory = Identifier & {
  __typename?: 'AccountRiskScoreHistory'
  /** The account associated with this Risk Score history */
  account: Account
  /** The timestamp when the record was created */
  createdAt: Scalars['ISO8601DateTime']
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The Risk Score of the account at the created at time */
  riskScore?: Maybe<Scalars['Float']>
}

/** EEC settings */
export type AccountSettingsEec = Identifier &
  Timestamps & {
    __typename?: 'AccountSettingsEec'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Indicates if the setting is enabled */
    enabled?: Maybe<Scalars['Boolean']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The date and time of the last scan */
    lastScannedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** Specifies the day of the month for the scan */
    scanDayOfMonth: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

/** IP restriction settings */
export type AccountSettingsIpRestriction = Identifier &
  Timestamps & {
    __typename?: 'AccountSettingsIpRestriction'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Determines if the same IP address enforcement is active */
    enforceSameIp: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** List of IP addresses that are allowed access */
    ipAllowList?: Maybe<Scalars['String']>
    /** Indicates if console sessions are restricted */
    restrictConsoleSession: Scalars['Boolean']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

/** Account language settings */
export type AccountSettingsLanguageDefault = Identifier &
  Timestamps & {
    __typename?: 'AccountSettingsLanguageDefault'
    /** The default administrative interface language for the account */
    adminLocale?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The default phishing simulation language for the account */
    phishingLocale?: Maybe<Scalars['String']>
    /** The default training language for the account */
    trainingLocale?: Maybe<Scalars['String']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

/** The Learner Experience account settings */
export type AccountSettingsLearnerExperience = Identifier &
  Timestamps & {
    __typename?: 'AccountSettingsLearnerExperience'
    /** Indicates if AIDA optional training is available */
    aidaOptionalTrainingAvailable?: Maybe<Scalars['Boolean']>
    /** Indicates if AIDA optional training is enabled */
    aidaOptionalTrainingEnabled?: Maybe<Scalars['Boolean']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Indicates if custom help is enabled for the account */
    customHelpEnabled?: Maybe<Scalars['Boolean']>
    /** The URL for custom help, if applicable */
    customHelpUrl?: Maybe<Scalars['String']>
    /** Indicates if the dashboard feature is enabled */
    dashboardEnabled: Scalars['Boolean']
    /** Indicates if phishing statistics are displayed on the dashboard */
    dashboardShowsPhishingStatistics: Scalars['Boolean']
    /** Indicates if risk statistics are displayed on the dashboard */
    dashboardShowsRiskStatistics: Scalars['Boolean']
    /** Indicates if the tour feature is disabled */
    disableTour?: Maybe<Scalars['Boolean']>
    /** Allows users to request learner app onboarding emails */
    enableUsersToRequestLearnerAppOnboardingEmails?: Maybe<Scalars['Boolean']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Indicates if phishing information is enabled for managers */
    managerPhishingEnabled?: Maybe<Scalars['Boolean']>
    /** Indicates if managers can view Risk Scores */
    managerRiskScoreEnabled?: Maybe<Scalars['Boolean']>
    /** Indicates if managers can access training information */
    managerTrainingEnabled?: Maybe<Scalars['Boolean']>
    /** Indicates if user information is accessible to managers */
    managerUserInfoEnabled?: Maybe<Scalars['Boolean']>
    /** Indicates if the mobile app feature is enabled */
    mobileAppEnabled?: Maybe<Scalars['Boolean']>
    /** Indicates if optional training is enabled */
    optionalTrainingEnabled?: Maybe<Scalars['Boolean']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

/** The phishing account settings */
export type AccountSettingsPhishing = Identifier &
  Timestamps & {
    __typename?: 'AccountSettingsPhishing'
    /** Indicates if AIDA-selected options are available */
    aidaSelectedAvailable?: Maybe<Scalars['Boolean']>
    /** Indicates if callback phishing is enabled */
    callbackPhishingEnabled?: Maybe<Scalars['Boolean']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Custom domain placeholder text */
    customDomainPlaceholder?: Maybe<Scalars['String']>
    /** Indicates if the custom domain placeholder feature is enabled */
    customDomainPlaceholderEnabled: Scalars['Boolean']
    /** Defines phishing attack vectors that are disabled */
    disabledTemplateAttackVectors: Array<PhishingAttackVectors>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Indicates if the reply-to phishing address is overridden */
    overrideRphAddress: Scalars['Boolean']
    /** Lists any exclusions to phishing template selection */
    templateExclusions?: Maybe<Scalars['JSON']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

/** Autogenerated return type of AccountSettingsPrivacyUpdateAccess. */
export type AccountSettingsPrivacyUpdateAccessPayload = {
  __typename?: 'AccountSettingsPrivacyUpdateAccessPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The account with the updated partner access expiration */
  node?: Maybe<Account>
}

/** Autogenerated return type of AccountSettingsSalesforceDisconnect. */
export type AccountSettingsSalesforceDisconnectPayload = {
  __typename?: 'AccountSettingsSalesforceDisconnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Account>
}

/** Autogenerated return type of AccountSettingsSalesforceFetch. */
export type AccountSettingsSalesforceFetchPayload = {
  __typename?: 'AccountSettingsSalesforceFetchPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Account>
}

/** Autogenerated return type of AccountSettingsSalesforceScheduleFetch. */
export type AccountSettingsSalesforceScheduleFetchPayload = {
  __typename?: 'AccountSettingsSalesforceScheduleFetchPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Account>
}

/** The SAML account settings */
export type AccountSettingsSaml = Identifier &
  Timestamps & {
    __typename?: 'AccountSettingsSaml'
    /** Allows admins with MFA to bypass login restrictions */
    allowAdminWithMfaLoginBypass?: Maybe<Scalars['Boolean']>
    /** Indicates if certificate notifications are enabled */
    certificateNotificationEnabled?: Maybe<Scalars['Boolean']>
    /** The email addresses to send certificate notifications to */
    certificateNotificationRecipients?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Determines if non-SAML logins should be disabled */
    disableNonSamlLogins?: Maybe<Scalars['Boolean']>
    /** The SAML entity ID */
    entityId?: Maybe<Scalars['String']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Indicates if SAML requests should be signed */
    signSamlRequests?: Maybe<Scalars['Boolean']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** Indicates if the latest certificate should be used */
    useLatestCertificate?: Maybe<Scalars['Boolean']>
  }

/** Autogenerated return type of AccountSettingsSamlUseLatestCertificate. */
export type AccountSettingsSamlUseLatestCertificatePayload = {
  __typename?: 'AccountSettingsSamlUseLatestCertificatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The account settings SAML object */
  node?: Maybe<AccountSettingsSaml>
}

/** Second Chance account settings */
export type AccountSettingsSecondChanceSetting = Identifier &
  Timestamps & {
    __typename?: 'AccountSettingsSecondChanceSetting'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Whether the Second Chance feature is enabled */
    enabled: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The number of days for the overview period */
    overviewDays: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

/** The Training account settings */
export type AccountSettingsTraining = Identifier &
  Timestamps & {
    __typename?: 'AccountSettingsTraining'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Indicates if localized notifications are enabled */
    enableLocalizedNotifications?: Maybe<Scalars['Boolean']>
    /** Indicates if direct links in notifications are enabled */
    enableNotificationDirectLinks?: Maybe<Scalars['Boolean']>
    /** Indicates if SCORM interactions are enabled */
    enableScormInteractions?: Maybe<Scalars['Boolean']>
    /** Indicates if surveys are enabled by default */
    enableSurveysDefault: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The number of months to retain SCORM interactions */
    scormInteractionsRetentionMonths?: Maybe<Scalars['Int']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

/** User moves settings */
export type AccountSettingsUserMoves = Identifier & {
  __typename?: 'AccountSettingsUserMoves'
  /** Determines if the user duplication error is automatically fixed */
  autofixUserDupError: Scalars['Boolean']
  /** Unique identifier for the object */
  id: Scalars['Int']
}

/** Autogenerated return type of AccountSettingsUserProvisioningForceSyncScim. */
export type AccountSettingsUserProvisioningForceSyncScimPayload = {
  __typename?: 'AccountSettingsUserProvisioningForceSyncScimPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The user provisioning settings */
  node?: Maybe<UserProvisioning>
}

/** Autogenerated return type of AccountSettingsUserProvisioningGooglePauseSync. */
export type AccountSettingsUserProvisioningGooglePauseSyncPayload = {
  __typename?: 'AccountSettingsUserProvisioningGooglePauseSyncPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of AccountSettingsUserProvisioningGoogleRemoveConfig. */
export type AccountSettingsUserProvisioningGoogleRemoveConfigPayload = {
  __typename?: 'AccountSettingsUserProvisioningGoogleRemoveConfigPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of AccountSettingsUserProvisioningGoogleSaveConfig. */
export type AccountSettingsUserProvisioningGoogleSaveConfigPayload = {
  __typename?: 'AccountSettingsUserProvisioningGoogleSaveConfigPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of AccountSettingsUserProvisioningGoogleSaveCredentials. */
export type AccountSettingsUserProvisioningGoogleSaveCredentialsPayload = {
  __typename?: 'AccountSettingsUserProvisioningGoogleSaveCredentialsPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of AccountSettingsUserProvisioningGoogleStartSync. */
export type AccountSettingsUserProvisioningGoogleStartSyncPayload = {
  __typename?: 'AccountSettingsUserProvisioningGoogleStartSyncPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of AccountSettingsUserProvisioningPurgeScimV2. */
export type AccountSettingsUserProvisioningPurgeScimV2Payload = {
  __typename?: 'AccountSettingsUserProvisioningPurgeScimV2Payload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The user provisioning settings */
  node?: Maybe<UserProvisioning>
}

/** Autogenerated return type of AccountSettingsUserProvisioningRegenerateScimToken. */
export type AccountSettingsUserProvisioningRegenerateScimTokenPayload = {
  __typename?: 'AccountSettingsUserProvisioningRegenerateScimTokenPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The user provisioning settings */
  node?: Maybe<UserProvisioning>
}

/** Autogenerated return type of AccountSettingsUserProvisioningRevokeScimToken. */
export type AccountSettingsUserProvisioningRevokeScimTokenPayload = {
  __typename?: 'AccountSettingsUserProvisioningRevokeScimTokenPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The user provisioning settings */
  node?: Maybe<UserProvisioning>
}

export enum AccountSortFields {
  AdminLocale = 'ADMIN_LOCALE',
  BillingType = 'BILLING_TYPE',
  Courses = 'COURSES',
  DateCreated = 'DATE_CREATED',
  Domain = 'DOMAIN',
  EndDate = 'END_DATE',
  Organization = 'ORGANIZATION',
  PhisherEnabled = 'PHISHER_ENABLED',
  PhishProne = 'PHISH_PRONE',
  Psts = 'PSTS',
  PurchasedAddons = 'PURCHASED_ADDONS',
  RiskScore = 'RISK_SCORE',
  Seats = 'SEATS',
  Users = 'USERS',
  UsersPhished = 'USERS_PHISHED',
  UsersTrained = 'USERS_TRAINED',
  ValidDeletionRequest = 'VALID_DELETION_REQUEST',
}

export enum AccountStatuses {
  Active = 'ACTIVE',
  All = 'ALL',
  Archived = 'ARCHIVED',
}

export enum AccountTokens {
  EntityId = 'entity_id',
  LdapToken = 'ldap_token',
  PstToken = 'pst_token',
}

export type AccountTrainingSettingsAttributes = {
  enableLocalizedNotifications?: Maybe<Scalars['Boolean']>
  enableNotificationDirectLinks?: Maybe<Scalars['Boolean']>
  enableScormInteractions?: Maybe<Scalars['Boolean']>
  enableSurveysDefault?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['Int']>
  scormInteractionsRetentionMonths?: Maybe<Scalars['Int']>
}

/** Autogenerated return type of AccountsConfirmDeletion. */
export type AccountsConfirmDeletionPayload = {
  __typename?: 'AccountsConfirmDeletionPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Account>
}

/** Autogenerated return type of AccountsCreatePabInstance. */
export type AccountsCreatePabInstancePayload = {
  __typename?: 'AccountsCreatePabInstancePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The newly created PAB instance */
  node?: Maybe<PhishalertSetting>
}

/** Autogenerated return type of AccountsCreate. */
export type AccountsCreatePayload = {
  __typename?: 'AccountsCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Account>
}

export type AccountsCursor = {
  __typename?: 'AccountsCursor'
  /** The paginated nodes */
  nodes: Array<Account>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of AccountsDeleteCert. */
export type AccountsDeleteCertPayload = {
  __typename?: 'AccountsDeleteCertPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The account with the completion certificate removed */
  node?: Maybe<Account>
}

/** Autogenerated return type of AccountsDeleteFree. */
export type AccountsDeleteFreePayload = {
  __typename?: 'AccountsDeleteFreePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Account>
}

/** Autogenerated return type of AccountsDeleteLogo. */
export type AccountsDeleteLogoPayload = {
  __typename?: 'AccountsDeleteLogoPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The account with the logo removed */
  node?: Maybe<Account>
}

/** Autogenerated return type of AccountsDeletePabIcon. */
export type AccountsDeletePabIconPayload = {
  __typename?: 'AccountsDeletePabIconPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The Phish Alert setting with the icon removed */
  node?: Maybe<PhishalertSetting>
}

/** Autogenerated return type of AccountsDeletePabInstance. */
export type AccountsDeletePabInstancePayload = {
  __typename?: 'AccountsDeletePabInstancePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PhishalertSetting>
}

/** Autogenerated return type of AccountsDeletePhisher. */
export type AccountsDeletePhisherPayload = {
  __typename?: 'AccountsDeletePhisherPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Account>
}

/** Autogenerated return type of AccountsInvokeReseed. */
export type AccountsInvokeReseedPayload = {
  __typename?: 'AccountsInvokeReseedPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of AccountsRefreshAssessmentScores. */
export type AccountsRefreshAssessmentScoresPayload = {
  __typename?: 'AccountsRefreshAssessmentScoresPayload'
  errors?: Maybe<Array<Error>>
  node: Scalars['Boolean']
}

/** Autogenerated return type of AccountsRefreshData. */
export type AccountsRefreshDataPayload = {
  __typename?: 'AccountsRefreshDataPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Account>
}

/** Autogenerated return type of AccountsRegenerateToken. */
export type AccountsRegenerateTokenPayload = {
  __typename?: 'AccountsRegenerateTokenPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The account with the token regenerated */
  node?: Maybe<Account>
}

/** Autogenerated return type of AccountsRequestDeletion. */
export type AccountsRequestDeletionPayload = {
  __typename?: 'AccountsRequestDeletionPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Account>
}

/** Autogenerated return type of AccountsResetFreeCampaign. */
export type AccountsResetFreeCampaignPayload = {
  __typename?: 'AccountsResetFreeCampaignPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Account>
}

/** Autogenerated return type of AccountsRevokeToken. */
export type AccountsRevokeTokenPayload = {
  __typename?: 'AccountsRevokeTokenPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The account with the token revoked */
  node?: Maybe<Account>
}

/** Autogenerated return type of AccountsStartEec. */
export type AccountsStartEecPayload = {
  __typename?: 'AccountsStartEecPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The account that the breach scan was started for */
  node?: Maybe<Account>
}

/** Autogenerated return type of AccountsToggleArchived. */
export type AccountsToggleArchivedPayload = {
  __typename?: 'AccountsToggleArchivedPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Account>
}

/** Autogenerated return type of AccountsUpdate. */
export type AccountsUpdatePayload = {
  __typename?: 'AccountsUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Account>
}

export type ActiveRulesReport = {
  __typename?: 'ActiveRulesReport'
  /** CSV formatted content of the active rules report */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type ActiveRulesStatistics = {
  __typename?: 'ActiveRulesStatistics'
  /** The number of users who have repeatedly triggered the rule */
  repeatedUsers?: Maybe<Scalars['Int']>
  /** The total number of detections for the rule */
  ruleDetections?: Maybe<Scalars['Int']>
  /** The total number of detections across all rules */
  totalDetections?: Maybe<Scalars['Int']>
  /** The total number of users who have triggered the rule */
  totalUsers?: Maybe<Scalars['Int']>
}

export type ActiveRulesTopRisky = {
  __typename?: 'ActiveRulesTopRisky'
  /** Whether the entity is archived. */
  archived?: Maybe<Scalars['Boolean']>
  /** The count of occurrences or instances. */
  count?: Maybe<Scalars['Int']>
  /** The email address associated with the entity. */
  email?: Maybe<Scalars['String']>
  /** The ID of the entity. */
  id?: Maybe<Scalars['Int']>
}

export enum ActiveStatuses {
  Active = 'ACTIVE',
  All = 'ALL',
  Archived = 'ARCHIVED',
}

/** A generic actor interface for users and partners */
export type Actor = Identifier &
  Timestamps & {
    __typename?: 'Actor'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The first name of the actor */
    firstName?: Maybe<Scalars['String']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The last name of the actor */
    lastName?: Maybe<Scalars['String']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type AggregateCampaignDataCursor = {
  __typename?: 'AggregateCampaignDataCursor'
  /** The paginated nodes */
  nodes: Array<SecurityCoachAggregateCampaignData>
  /** Information about the current paginated set */
  pagination: Pagination
}

export type AggregateChart = {
  __typename?: 'AggregateChart'
  /** Categories for the aggregate phishing chart */
  categories: Array<AggregateChartCategories>
  /** Settings related to partner accounts */
  partnerAccountSettings?: Maybe<Array<PartnerAccountSettings>>
  /** The Phish-failure Percentage */
  pfp: Scalars['Float']
  /** The Phish-prone Percentage */
  ppp: Scalars['Float']
  /** The PAB reported percentage */
  prp: Scalars['Float']
  /** Data series for the aggregate phishing chart */
  series: AggregateChartSeries
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** Total counts for various metrics in the phishing chart */
  totals: AggregateChartTotals
  /** Authorization token for Zenka integration */
  zenkaToken: Scalars['String']
  /** URL for Zenka integration */
  zenkaUrl: Scalars['String']
}

export type AggregateChartCategories = {
  __typename?: 'AggregateChartCategories'
  /** The number of items that bounced */
  bounced?: Maybe<Scalars['Int']>
  /** The categories within the aggregate chart */
  categories?: Maybe<Scalars['String']>
  /** The number of successfully delivered items */
  delivered?: Maybe<Scalars['Int']>
  /** The frequency period of the category */
  frequencyPeriod?: Maybe<Frequencies>
  /** Groups within the aggregate chart categories */
  groups?: Maybe<Scalars['String']>
  /** The name of the chart category */
  name?: Maybe<Scalars['String']>
  /** The start date of the data range for the chart */
  startedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type AggregateChartSeries = {
  __typename?: 'AggregateChartSeries'
  /** Number of attachments opened in phishing email simulations */
  attachments?: Maybe<Array<AggregateChartSeriesData>>
  /** Data entered by users in callback phishing campaigns */
  callbackDataEntered?: Maybe<Array<AggregateChartSeriesData>>
  /** Number of calls made in response to PSTs */
  called?: Maybe<Array<AggregateChartSeriesData>>
  /** Number of clicks recorded on PSTs */
  clicks?: Maybe<Array<AggregateChartSeriesData>>
  /** General data related to PSTs */
  data?: Maybe<Array<AggregateChartSeriesData>>
  /** Number of macros enabled in PSTs */
  macros?: Maybe<Array<AggregateChartSeriesData>>
  /** Phish-prone Percentage */
  ppp?: Maybe<Array<AggregateChartSeriesData>>
  /** Number of QR codes scanned in PSTs */
  qrCodes?: Maybe<Array<AggregateChartSeriesData>>
  /** Number of replies to phishing emails in PSTs */
  replies?: Maybe<Array<AggregateChartSeriesData>>
  /** Number of phishing attempts reported by users */
  reports?: Maybe<Array<AggregateChartSeriesData>>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type AggregateChartSeriesData = {
  __typename?: 'AggregateChartSeriesData'
  /** The unique identifier for the series data */
  id?: Maybe<Scalars['Int']>
  /** The identifier of the run associated with the series data */
  runId?: Maybe<Scalars['Int']>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** The data point value for the series */
  y?: Maybe<Scalars['Int']>
}

export type AggregateChartTotals = {
  __typename?: 'AggregateChartTotals'
  /** The number of attachments opened */
  attachments?: Maybe<Scalars['Int']>
  /** The number of callbacks with data entered */
  callbackDataEntered?: Maybe<Scalars['Int']>
  /** The number of calls made */
  called?: Maybe<Scalars['Int']>
  /** The number of clicks recorded */
  clicks?: Maybe<Scalars['Int']>
  /** General data count related to the report */
  data?: Maybe<Scalars['Int']>
  /** The number of macros executed */
  macros?: Maybe<Scalars['Int']>
  /** The number of QR codes scanned */
  qrCodes?: Maybe<Scalars['Int']>
  /** The number of replies recorded */
  replies?: Maybe<Scalars['Int']>
  /** The total number of reports */
  reports?: Maybe<Scalars['Int']>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type AggregateColumns = {
  __typename?: 'AggregateColumns'
  /** Columns pertaining to aggregate detection data */
  aggregateDetectionColumns?: Maybe<
    Array<ReportsSecurityCoachActivityRtcAggregateAggregateDetectionColumns>
  >
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** Columns related to the user involved in the report */
  userColumns?: Maybe<Array<ReportsUserColumns>>
  /** Columns containing user identity provider data */
  userIdpDataColumns?: Maybe<Array<ReportsUserIdpDataColumns>>
}

export type AggregateCsv = {
  __typename?: 'AggregateCsv'
  /** CSV formatted data representing the aggregation of global phishing reports */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type AggregateData = {
  __typename?: 'AggregateData'
  /** The unique identifier of the aggregate data */
  id?: Maybe<Scalars['String']>
  /** The maximum risk level observed */
  maxRiskLevel?: Maybe<Scalars['String']>
  /** The number of users associated with SAB */
  sabUser?: Maybe<Scalars['Int']>
  /** The count of unique workflows */
  uniqueWorkflowCount?: Maybe<Scalars['Int']>
  /** The user associated with this data */
  user?: Maybe<User>
  /** The count of workflow user logs */
  workflowuserlogCount?: Maybe<Scalars['Int']>
}

export type AggregateResponse = {
  __typename?: 'AggregateResponse'
  /** The columns selected and returned for the aggregated data */
  columns?: Maybe<SecurityCoachDetectionRulesActivityAggregateColumns>
  /** The total number of individual records available */
  count: Scalars['Int']
  /** The page number for the next page of results */
  next?: Maybe<Scalars['String']>
  /** The number of items to display per page */
  pageSize: Scalars['Int']
  /** Pagination details for the report */
  pagination: Pagination
  /** The page number for the previous page of results */
  previous?: Maybe<Scalars['String']>
  /** A collection of aggregated data for security coach detection rules activity */
  results: Array<AggregateData>
  /** The timeframe covered by the aggregated data */
  timeframe: ReportTimeframe
  /** The total number of pages available */
  totalPages: Scalars['Int']
}

export type AggregateTable = {
  __typename?: 'AggregateTable'
  /** Number of times attachments were opened in PSTs */
  attachmentOpenCount: Scalars['Int']
  /** Number of PSTs bounced */
  bouncedCount: Scalars['Int']
  /** Number of times data was entered during callback phishing campaigns */
  callbackDataEnteredCount: Scalars['Int']
  /** Number of times calls were made in PSTs */
  calledCount: Scalars['Int']
  /** Number of PSTs where links were clicked */
  clickedCount: Scalars['Int']
  /** Number of times data was entered on PST landing pages */
  dataEnteredCount: Scalars['Int']
  /** Number of PSTs delivered */
  deliveredCount: Scalars['Int']
  /** Number of failures in PSTs */
  failuresCount: Scalars['Int']
  /** Number of times macros were enabled in PSTs */
  macroEnabledCount: Scalars['Int']
  /** Number of PSTs opened by the recipient */
  openedCount: Scalars['Int']
  /** Phish-prone Percentage */
  ppp: Scalars['Float']
  /** Number of times QR codes were scanned in PSTs */
  qrCodeScannedCount: Scalars['Int']
  /** Number of PSTs where the recipient replied */
  repliedCount: Scalars['Int']
  /** Number of PSTs reported by the recipients */
  reportedCount: Scalars['Int']
  /** Number of PSTs scheduled */
  scheduledCount: Scalars['Int']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** User associated with the report */
  user?: Maybe<User>
}

/** An AIDA Campaign */
export type AidaCampaign = Identifier &
  Timestamps & {
    __typename?: 'AidaCampaign'
    aidaTriggerEvents: Array<AidaTriggerEvent>
    allUsers: Scalars['Boolean']
    allowPastDueCompletions: Scalars['Boolean']
    allowSurveys: Scalars['Boolean']
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    disabled: Scalars['Boolean']
    enrollmentDuration: Scalars['Int']
    enrollmentDurationType: TrainingCampaignDurations
    failuresCount: Scalars['Int']
    groups: Array<Group>
    id: Scalars['Int']
    missedEnrollments: Scalars['Int']
    notifications: Array<Notification>
    selectedGroups: Array<Scalars['Int']>
    settings: AidaCampaignSetting
    status: TrainingCampaignStatuses
    testMode: Scalars['Boolean']
    timeZone: TimeZones
    topTenUserAssignmentCount: Array<UserAssignmentCount>
    trackScores: Scalars['Boolean']
    trainingAssigned: Scalars['Int']
    trainingAssignedByDate: Array<TimePoint>
    trainingCompleted: Scalars['Int']
    trainingCompletedByDate: Array<TimePoint>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    usersEnrolled: Scalars['Int']
  }

export type AidaCampaignAttributes = {
  allUsers: Scalars['Boolean']
  allowPastDueCompletions: Scalars['Boolean']
  allowSurveys: Scalars['Boolean']
  enrollmentDuration: Scalars['Int']
  enrollmentDurationType: TrainingCampaignDurations
  groups: Array<Scalars['Int']>
  notifications: Array<NotificationAttributes>
  settings: AidaCampaignSettingAttributes
  timeZone?: Maybe<TimeZones>
  trackScores: Scalars['Boolean']
}

/** A Aida Campaign setting */
export type AidaCampaignSetting = Identifier &
  Timestamps & {
    __typename?: 'AidaCampaignSetting'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    requiredFailures: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type AidaCampaignSettingAttributes = {
  requiredFailures: Scalars['Int']
}

export type AidaCampaignSummaryTableCsv = {
  __typename?: 'AidaCampaignSummaryTableCsv'
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

/** Autogenerated return type of AidaCampaignsDisable. */
export type AidaCampaignsDisablePayload = {
  __typename?: 'AidaCampaignsDisablePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<AidaCampaign>
}

/** Autogenerated return type of AidaCampaignsEnable. */
export type AidaCampaignsEnablePayload = {
  __typename?: 'AidaCampaignsEnablePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<AidaCampaign>
}

/** AIDA Determination */
export type AidaDetermination = Identifier &
  Timestamps & {
    __typename?: 'AidaDetermination'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    determinationEvents: Array<AidaDeterminationEvent>
    enrollment?: Maybe<Enrollment>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    user: User
  }

/** AIDA determination event */
export type AidaDeterminationEvent = {
  __typename?: 'AidaDeterminationEvent'
  failureType: AidaFailureTypes
}

export type AidaDeterminationsCursor = {
  __typename?: 'AidaDeterminationsCursor'
  /** The paginated nodes */
  nodes: Array<AidaDetermination>
  /** Information about the current paginated set */
  pagination: Pagination
}

export enum AidaFailureTypes {
  KmsatCampaignAttachmentOpened = 'KMSAT_CAMPAIGN_ATTACHMENT_OPENED',
  KmsatCampaignCallbackDataEntered = 'KMSAT_CAMPAIGN_CALLBACK_DATA_ENTERED',
  KmsatCampaignClicked = 'KMSAT_CAMPAIGN_CLICKED',
  KmsatCampaignDataEntered = 'KMSAT_CAMPAIGN_DATA_ENTERED',
  KmsatCampaignMacroEnabled = 'KMSAT_CAMPAIGN_MACRO_ENABLED',
  KmsatCampaignQrCodeScanned = 'KMSAT_CAMPAIGN_QR_CODE_SCANNED',
  KmsatCampaignReplied = 'KMSAT_CAMPAIGN_REPLIED',
}

export enum AidaLanguages {
  ArMod = 'AR_MOD',
  CsCz = 'CS_CZ',
  DaDk = 'DA_DK',
  DeDe = 'DE_DE',
  EnAu = 'EN_AU',
  EnGb = 'EN_GB',
  EnUs = 'EN_US',
  Es_419 = 'ES_419',
  EsEs = 'ES_ES',
  FiFi = 'FI_FI',
  FrCa = 'FR_CA',
  FrFr = 'FR_FR',
  HeIl = 'HE_IL',
  HiIn = 'HI_IN',
  HuHu = 'HU_HU',
  IdId = 'ID_ID',
  ItIt = 'IT_IT',
  JaJp = 'JA_JP',
  KoKr = 'KO_KR',
  MsMy = 'MS_MY',
  NbNo = 'NB_NO',
  NlNl = 'NL_NL',
  PlPl = 'PL_PL',
  PtBr = 'PT_BR',
  PtPt = 'PT_PT',
  RoRo = 'RO_RO',
  RuRu = 'RU_RU',
  SvSe = 'SV_SE',
  ThTh = 'TH_TH',
  TrTr = 'TR_TR',
  UkUa = 'UK_UA',
  ViVn = 'VI_VN',
  ZhCn = 'ZH_CN',
  ZhHk = 'ZH_HK',
  ZhTw = 'ZH_TW',
}

export enum AidaPhishingTemplateAttachmentTypes {
  Docx = 'docx',
  Pdf = 'pdf',
  Xlsx = 'xlsx',
}

export enum AidaPhishingTemplateAttackVectors {
  Attachment = 'attachment',
  Qrcode = 'qrcode',
  ReplyOnly = 'reply_only',
  UrlLink = 'url_link',
}

export enum AidaPhishingTemplateDifficulties {
  Advanced = 'advanced',
  Basic = 'basic',
  Moderate = 'moderate',
  Obvious = 'obvious',
  Sophisticated = 'sophisticated',
}

export enum AidaPhishingTemplateFlags {
  AttachmentType = 'attachment_type',
  DistractingDetail = 'distracting_detail',
  GenericGreeting = 'generic_greeting',
  GrammarIrregularities = 'grammar_irregularities',
  GreedBias = 'greed_bias',
  HumanitarianAppeal = 'humanitarian_appeal',
  ImprobableOffer = 'improbable_offer',
  Inconsistency = 'inconsistency',
  KnownSender = 'known_sender',
  LegalLanguage = 'legal_language',
  LimitedTime = 'limited_time',
  LossAversion = 'loss_aversion',
  MimicsWork = 'mimics_work',
  SignerDetailsAbsent = 'signer_details_absent',
  SpecialAdmiration = 'special_admiration',
  Urgency = 'urgency',
  UrlMasking = 'url_masking',
}

export enum AidaPhishingTemplateGreetings {
  DisplayName = 'display_name',
  Generic = 'generic',
  OfficialName = 'official_name',
}

/** AIDA Phishing Template Generator */
export type AidaPhishingTemplateSettings = Identifier &
  Timestamps & {
    __typename?: 'AidaPhishingTemplateSettings'
    aidaTemplateId: Scalars['String']
    attackVec: AidaPhishingTemplateAttackVectors
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    flags?: Maybe<Array<AidaPhishingTemplateFlags>>
    greeting?: Maybe<AidaPhishingTemplateGreetings>
    /** Unique identifier for the object */
    id: Scalars['Int']
    region?: Maybe<Scalars['String']>
    signatureAddress?: Maybe<AidaPhishingTemplateSignatureAddresses>
    signatureName?: Maybe<AidaPhishingTemplateSignatureNames>
    theme?: Maybe<Scalars['String']>
    tone: AidaPhishingTemplateTones
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type AidaPhishingTemplateSettingsAttributes = {
  aidaTemplateId: Scalars['String']
  attackVec: AidaPhishingTemplateAttackVectors
  flags?: Maybe<Array<AidaPhishingTemplateFlags>>
  greeting?: Maybe<AidaPhishingTemplateGreetings>
  region?: Maybe<Scalars['String']>
  signatureAddress?: Maybe<AidaPhishingTemplateSignatureAddresses>
  signatureName?: Maybe<AidaPhishingTemplateSignatureNames>
  theme?: Maybe<Scalars['String']>
  tone: AidaPhishingTemplateTones
}

export enum AidaPhishingTemplateSignatureAddresses {
  Default = 'default',
  Missing = 'missing',
  Signature = 'signature',
}

export enum AidaPhishingTemplateSignatureNames {
  Manager = 'manager',
  Missing = 'missing',
  Sender = 'sender',
}

export enum AidaPhishingTemplateTones {
  Angry = 'angry',
  Business = 'business',
  Confident = 'confident',
  Formal = 'formal',
  Humorous = 'humorous',
  Informal = 'informal',
  Joyful = 'joyful',
  Positive = 'positive',
  Sarcastic = 'sarcastic',
  Serious = 'serious',
}

/** Autogenerated return type of AidaPolicyQuizQuestionOptionsUpdate. */
export type AidaPolicyQuizQuestionOptionsUpdatePayload = {
  __typename?: 'AidaPolicyQuizQuestionOptionsUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PolicyQuizQuestionOption>
}

/** Autogenerated return type of AidaPolicyQuizQuestionsUpdate. */
export type AidaPolicyQuizQuestionsUpdatePayload = {
  __typename?: 'AidaPolicyQuizQuestionsUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PolicyQuizQuestion>
}

/** Autogenerated return type of AidaPolicyQuizzesRegenerate. */
export type AidaPolicyQuizzesRegeneratePayload = {
  __typename?: 'AidaPolicyQuizzesRegeneratePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PolicyQuiz>
}

/** Autogenerated return type of AidaPolicyQuizzesUpdate. */
export type AidaPolicyQuizzesUpdatePayload = {
  __typename?: 'AidaPolicyQuizzesUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PolicyQuiz>
}

export enum AidaSummaryColumns {
  AssignedAt = 'ASSIGNED_AT',
  AttackVector = 'ATTACK_VECTOR',
  ContentName = 'CONTENT_NAME',
  Email = 'EMAIL',
  Score = 'SCORE',
  Status = 'STATUS',
  TimeRemaining = 'TIME_REMAINING',
  TimeSpent = 'TIME_SPENT',
}

export enum AidaSummarySortFields {
  AssignedAt = 'ASSIGNED_AT',
  AssignedAtTestmode = 'ASSIGNED_AT_TESTMODE',
  AttackVector = 'ATTACK_VECTOR',
  Email = 'EMAIL',
  Score = 'SCORE',
  Status = 'STATUS',
  TimeRemaining = 'TIME_REMAINING',
  TimeSpent = 'TIME_SPENT',
}

/** A Aida Campaign trigger event */
export type AidaTriggerEvent = Identifier &
  Timestamps & {
    __typename?: 'AidaTriggerEvent'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    eventName: Scalars['String']
    id: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type AliasData = {
  __typename?: 'AliasData'
  /** The unique alias of the user */
  alias?: Maybe<Scalars['String']>
  /** The type of alias assigned to the user */
  aliasType?: Maybe<Scalars['String']>
}

export type AllDetectionStatistics = {
  __typename?: 'AllDetectionStatistics'
  /** Number of active integrations */
  activeIntegrations?: Maybe<Scalars['Int']>
  /** Total number of detections */
  allDetections?: Maybe<Scalars['Int']>
  /** Total number of distinct threat categories */
  allThreatCategories?: Maybe<Scalars['Int']>
  /** Average number of detections per day */
  avgDetectionsPerDay?: Maybe<Scalars['Int']>
}

export type AllDetections = {
  __typename?: 'AllDetections'
  /** A list of all detection events */
  events?: Maybe<Array<DetectionEvent>>
  /** The total number of detection events */
  totalEvents?: Maybe<Scalars['Int']>
}

export type AllDetectionsSummary = {
  __typename?: 'AllDetectionsSummary'
  /** The count of all detections for the specified field */
  count?: Maybe<Scalars['Int']>
  /** The specific field of detection */
  field?: Maybe<Scalars['String']>
}

/** Allowed domain */
export type AllowedDomain = Identifier &
  Timestamps & {
    __typename?: 'AllowedDomain'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Indicates whether the domain is the primary domain or not */
    isPrimary: Scalars['Boolean']
    /** The name of the domain */
    name: Scalars['String']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The current verification status of the domain */
    verificationStatus: AllowedDomainVerificationStatuses
  }

export enum AllowedDomainTypes {
  Domain = 'domain',
  Subdomain = 'subdomain',
}

export enum AllowedDomainVerificationStatuses {
  Pending = 'PENDING',
  Verified = 'VERIFIED',
}

/** Autogenerated return type of AllowedDomainsCreate. */
export type AllowedDomainsCreatePayload = {
  __typename?: 'AllowedDomainsCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<AllowedDomain>
}

export type AllowedDomainsCursor = {
  __typename?: 'AllowedDomainsCursor'
  /** The paginated nodes */
  nodes: Array<AllowedDomain>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of AllowedDomainsDelete. */
export type AllowedDomainsDeletePayload = {
  __typename?: 'AllowedDomainsDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The deleted allowed domain */
  node?: Maybe<AllowedDomain>
}

/** Autogenerated return type of AllowedDomainsImport. */
export type AllowedDomainsImportPayload = {
  __typename?: 'AllowedDomainsImportPayload'
  errors?: Maybe<Array<Error>>
  node: Array<Scalars['Int']>
}

/** Autogenerated return type of AllowedDomainsVerify. */
export type AllowedDomainsVerifyPayload = {
  __typename?: 'AllowedDomainsVerifyPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The allowed domain that was verified */
  node?: Maybe<AllowedDomain>
}

export type ApiEndpointData = {
  __typename?: 'ApiEndpointData'
  /** List of cloud endpoints */
  cloudEndpointList: Array<Scalars['String']>
}

/** A API key basic authentication configuration */
export type ApiKey = {
  __typename?: 'ApiKey'
  /** The key part of the API key */
  key: Scalars['String']
  /** The type of the API key */
  type: ApiKeyTypes
  /** The value part of the API key */
  value: Scalars['String']
}

export enum ApiKeyTypes {
  Headers = 'headers',
  QueryParams = 'query_params',
}

/** The API limit account settings */
export type ApiLimit = Identifier &
  Timestamps & {
    __typename?: 'ApiLimit'
    /** The maximum number of requests that can be made in a burst */
    burstLimit?: Maybe<Scalars['Int']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The maximum number of requests that can be made in a day */
    dailyLimit?: Maybe<Scalars['Int']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type ApiLimitSettingsAttributes = {
  burstLimit?: Maybe<Scalars['Int']>
  dailyLimit?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
}

export enum ApiScopes {
  DeltaShare = 'delta_share',
  Elvis = 'elvis',
  Kcm = 'kcm',
  Kmsat = 'kmsat',
  Mobile = 'mobile',
  Phisher = 'phisher',
  Piq = 'piq',
  PublicKmsat = 'public_kmsat',
}

/** An API token */
export type ApiToken = Timestamps & {
  __typename?: 'ApiToken'
  /** Indicates whether the API token is active */
  active: Scalars['Boolean']
  /** The display information for audit logs */
  auditLogDisplay?: Maybe<Scalars['String']>
  /** The timestamp when the record was created */
  createdAt: Scalars['ISO8601DateTime']
  /** The user who created the API token */
  createdBy?: Maybe<User>
  /** The expiration date of the API token */
  expiresOn?: Maybe<Scalars['ISO8601DateTime']>
  /** The unique identifier of the API token */
  id: Scalars['Int']
  /** The JWT associated with the API token */
  jwt: Scalars['String']
  /** The name of the API token */
  name: Scalars['String']
  /** The scopes associated with the API token */
  scopes: Array<ApiScopes>
  /** The share profile information of the API token */
  shareProfile?: Maybe<Scalars['JSON']>
  /** The shared secret of the API token */
  sharedSecret?: Maybe<Scalars['String']>
  /** The timestamp when the record was last updated */
  updatedAt: Scalars['ISO8601DateTime']
  /** The user associated with this API token */
  user?: Maybe<User>
  /** The UUID of the API token */
  uuid: Scalars['String']
}

export type ApiTokenAttributes = {
  /** Whether the API token is active */
  active: Scalars['Boolean']
  /** The date the API token expires */
  expiresOn?: Maybe<Scalars['ISO8601Date']>
  /** The name of the API token */
  name: Scalars['String']
  /** The scopes of the API token */
  scopes?: Maybe<Array<ApiScopes>>
  /** The ID of the user that the API token belongs to */
  userId?: Maybe<Scalars['Int']>
}

export enum ApiTokenTypes {
  Product = 'PRODUCT',
  Reporting = 'REPORTING',
}

/** An API token */
export type ApiTokenValidation = {
  __typename?: 'ApiTokenValidation'
  /** The ID of the account associated with the token */
  accountId?: Maybe<Scalars['Int']>
  /** The name of the account associated with the token */
  accountName?: Maybe<Scalars['String']>
  /** JWT associated with the pet, if applicable */
  petJwt?: Maybe<Scalars['String']>
  /** The reason for the token’s validity status */
  reason?: Maybe<Scalars['String']>
  /** List of purchased SKUs associated with the account */
  skus: Array<AccountPurchasedSku>
  /** The ID of the user associated with the token */
  userId?: Maybe<Scalars['Int']>
  /** Indicates if the API token is valid */
  valid: Scalars['Boolean']
}

/** Autogenerated return type of ApiTokensCreate. */
export type ApiTokensCreatePayload = {
  __typename?: 'ApiTokensCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The API token that was created */
  node?: Maybe<NewApiToken>
}

export type ApiTokensCursor = {
  __typename?: 'ApiTokensCursor'
  /** The paginated nodes */
  nodes: Array<ApiToken>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of ApiTokensDelete. */
export type ApiTokensDeletePayload = {
  __typename?: 'ApiTokensDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The deleted API Token */
  node?: Maybe<ApiToken>
}

/** Autogenerated return type of ApiTokensReassign. */
export type ApiTokensReassignPayload = {
  __typename?: 'ApiTokensReassignPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The API tokens that were reassigned */
  nodes?: Maybe<Array<ApiToken>>
}

/** Autogenerated return type of ApiTokensUpdate. */
export type ApiTokensUpdatePayload = {
  __typename?: 'ApiTokensUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated API token */
  node?: Maybe<ApiToken>
}

/** A group criterion argument */
export type Argument =
  | Assessments
  | BreachEvent
  | CustomEvent
  | PasswordIqEvent
  | PasswordIqState
  | PhishEvent
  | PhishFlipEvent
  | PostTrainingEvent
  | SecurityCoach
  | SecurityCoachRealTimeCoaching
  | Training
  | UserDate
  | UserField

/** ModStore store item artwork */
export type Artwork = {
  __typename?: 'Artwork'
  /** The URL of the cover image for the artwork */
  coverImage?: Maybe<Scalars['String']>
  /** The URL of the header image for the artwork */
  headerImage?: Maybe<Scalars['String']>
}

/** Autogenerated return type of AsapCreateProgram. */
export type AsapCreateProgramPayload = {
  __typename?: 'AsapCreateProgramPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<AsapProgram>
}

/** Autogenerated return type of AsapCreateProgramTask. */
export type AsapCreateProgramTaskPayload = {
  __typename?: 'AsapCreateProgramTaskPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<AsapProgramTask>
}

/** Autogenerated return type of AsapDeleteProgram. */
export type AsapDeleteProgramPayload = {
  __typename?: 'AsapDeleteProgramPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<AsapProgram>
}

/** ASAP program */
export type AsapProgram = Identifier &
  Timestamps & {
    __typename?: 'AsapProgram'
    auditLogDisplay?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The end date of the ASAP program */
    endDate?: Maybe<Scalars['ISO8601DateTime']>
    /** Indicates if the ASAP program has any tasks */
    hasProgramTasks?: Maybe<Scalars['Boolean']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The profile details of the ASAP program */
    programProfile: AsapProgramProfile
    /** A list of tasks associated with the ASAP program */
    programTasks: Array<AsapProgramTask>
    /** The start date of the ASAP program */
    startDate?: Maybe<Scalars['ISO8601DateTime']>
    /** The title of the ASAP program */
    title?: Maybe<Scalars['String']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type AsapProgramAttributes = {
  endDate?: Maybe<Scalars['ISO8601Date']>
  programProfile?: Maybe<AsapProgramProfileAttributes>
  programTasks?: Maybe<Array<AsapProgramTaskAttributes>>
  startDate?: Maybe<Scalars['ISO8601Date']>
}

/** ASAP program profile */
export type AsapProgramProfile = Timestamps & {
  __typename?: 'AsapProgramProfile'
  answers?: Maybe<Scalars['JSON']>
  /** List of behavior changes identified in the ASAP program profile */
  behaviorChanges?: Maybe<Array<Scalars['Int']>>
  /** The size of the company associated with the ASAP program profile */
  companySize?: Maybe<Scalars['String']>
  /** Compliance numbers associated with the ASAP program profile */
  compliance?: Maybe<Array<Scalars['Int']>>
  /** The timestamp when the record was created */
  createdAt: Scalars['ISO8601DateTime']
  defaultAssessmentPolymorphicId?: Maybe<Scalars['String']>
  defaultCultureSurveyPolymorphicId?: Maybe<Scalars['String']>
  /** The default locales for the ASAP program profile */
  defaultLocales?: Maybe<AccountSettingsLanguageDefault>
  /** The end date of the ASAP program profile */
  endDate?: Maybe<Scalars['ISO8601DateTime']>
  /** The goals set within the ASAP program profile */
  goals?: Maybe<Array<Scalars['String']>>
  /** The ID of the ASAP program profile */
  id?: Maybe<Scalars['Int']>
  /** The industry associated with the ASAP program profile */
  industry?: Maybe<Industry>
  /** The maturity level of the ASAP program profile */
  maturity?: Maybe<Scalars['Float']>
  /** The number of seats associated with the ASAP program profile */
  numberOfSeats?: Maybe<Scalars['Int']>
  /** The types of presentations available in the ASAP program profile */
  presentationTypes?: Maybe<Array<Scalars['Int']>>
  /** The tasks associated with the ASAP program profile */
  programTasks: Array<AsapProgramTask>
  /** The readiness level of the ASAP program profile */
  readiness?: Maybe<Scalars['Float']>
  /** The roles associated with the ASAP program profile */
  roles?: Maybe<Array<Scalars['Int']>>
  /** The start date of the ASAP program profile */
  startDate?: Maybe<Scalars['ISO8601DateTime']>
  /** The timestamp when the record was last updated */
  updatedAt: Scalars['ISO8601DateTime']
  /** The languages available for phishing training in the ASAP program profile */
  userPhishingLanguages?: Maybe<Array<Languages>>
  /** The languages available for user training in the ASAP program profile */
  userTrainingLanguages?: Maybe<Array<Languages>>
}

export type AsapProgramProfileAttributes = {
  answers?: Maybe<Scalars['JSON']>
  behaviorChanges?: Maybe<Array<Scalars['Int']>>
  compliance?: Maybe<Array<Scalars['Int']>>
  goals?: Maybe<Array<Scalars['String']>>
  maturity?: Maybe<Scalars['Float']>
  presentationTypes?: Maybe<Array<Scalars['Int']>>
  readiness?: Maybe<Scalars['Float']>
  roles?: Maybe<Array<Scalars['Int']>>
}

/** One ASAP program task */
export type AsapProgramTask = Identifier &
  Timestamps & {
    __typename?: 'AsapProgramTask'
    auditLogDisplay?: Maybe<Scalars['String']>
    /** The number of calendar days allocated for the task */
    calendarDays?: Maybe<Scalars['Int']>
    /** The date on which the task was completed */
    completedDate?: Maybe<Scalars['ISO8601Date']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The description of the task */
    description?: Maybe<Scalars['String']>
    /** The end date of the task */
    endDate?: Maybe<Scalars['ISO8601Date']>
    /** The estimated duration of the task as a string */
    estimatedDuration?: Maybe<Scalars['String']>
    /** The estimated duration of the task in minutes */
    estimatedDurationInMinutes?: Maybe<Scalars['Int']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The task’s position within its program */
    position: Scalars['Int']
    programId: Scalars['Int']
    /** The actions associated with the program task */
    programTaskActions: Array<AsapProgramTaskAction>
    /** The start date of the task */
    startDate?: Maybe<Scalars['ISO8601Date']>
    /** The current status of the task */
    status: AsapTaskStatuses
    /** The subtitle of the task */
    subtitle?: Maybe<Scalars['String']>
    /** The category of the task */
    taskCategory: AsapTaskCategories
    /** The class of the task */
    taskClass?: Maybe<Scalars['String']>
    /** The title of the task */
    title?: Maybe<Scalars['String']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The variant of the task */
    variant?: Maybe<Scalars['Int']>
  }

/** An action associated with a program task */
export type AsapProgramTaskAction = {
  __typename?: 'AsapProgramTaskAction'
  /** The ID of the campaign */
  campaignId?: Maybe<Scalars['Int']>
  /** The unique identifier of the task action */
  id: Scalars['Int']
  /** The ID of the training campaign */
  trainingCampaignId?: Maybe<Scalars['Int']>
  /** The type of the task action */
  type: Scalars['String']
  /** The ID of the USB drive test campaign */
  usbCampaignId?: Maybe<Scalars['Int']>
}

export type AsapProgramTaskActionAttributes = {
  _destroy?: Maybe<Scalars['Boolean']>
  campaignId?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
  trainingCampaignId?: Maybe<Scalars['Int']>
  type: Scalars['String']
  usbCampaignId?: Maybe<Scalars['Int']>
}

export type AsapProgramTaskAttributes = {
  calendarDays?: Maybe<Scalars['Int']>
  completedDate?: Maybe<Scalars['ISO8601Date']>
  description?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['ISO8601Date']>
  estimatedDuration?: Maybe<Scalars['String']>
  estimatedDurationInMinutes?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
  position?: Maybe<Scalars['Int']>
  programTaskActions?: Maybe<Array<AsapProgramTaskActionAttributes>>
  startDate?: Maybe<Scalars['ISO8601Date']>
  status?: Maybe<AsapTaskStatuses>
  subtitle?: Maybe<Scalars['String']>
  taskCategory?: Maybe<AsapTaskCategories>
  taskClass?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  variant?: Maybe<Scalars['Int']>
}

export enum AsapTaskCategories {
  KickOff = 'KICK_OFF',
  MeasurementsAndReview = 'MEASUREMENTS_AND_REVIEW',
  OngoingTraining = 'ONGOING_TRAINING',
  PreWork = 'PRE_WORK',
  SocialEngineering = 'SOCIAL_ENGINEERING',
}

export enum AsapTaskStatuses {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED',
}

/** Autogenerated return type of AsapUpdateProgram. */
export type AsapUpdateProgramPayload = {
  __typename?: 'AsapUpdateProgramPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<AsapProgram>
}

/** Autogenerated return type of AsapUpdateProgramTask. */
export type AsapUpdateProgramTaskPayload = {
  __typename?: 'AsapUpdateProgramTaskPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<AsapProgramTask>
}

/** An assessment domain */
export type AssessmentDomain = Identifier &
  Timestamps & {
    __typename?: 'AssessmentDomain'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The name of the assessment domain */
    name: Scalars['String']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type AssessmentDomainArgument = {
  id: Scalars['Int']
  name: Scalars['String']
}

/** An assessment domain result */
export type AssessmentDomainResult = {
  __typename?: 'AssessmentDomainResult'
  /** The name of the assessment domain */
  name: Scalars['String']
  /** The score of the assessment domain */
  score: Scalars['Float']
}

export enum AssessmentDomains {
  Attitudes = 'ATTITUDES',
  Behavior = 'BEHAVIOR',
  Cognition = 'COGNITION',
  Communication = 'COMMUNICATION',
  Compliance = 'COMPLIANCE',
  EmailSecurity = 'EMAIL_SECURITY',
  FrequencyOfTraining = 'FREQUENCY_OF_TRAINING',
  HumanFirewall = 'HUMAN_FIREWALL',
  IncidentReporting = 'INCIDENT_REPORTING',
  Indcol = 'INDCOL',
  InternetUse = 'INTERNET_USE',
  LevelOfConfidence = 'LEVEL_OF_CONFIDENCE',
  LevelOfConfidenceHumanFirewall = 'LEVEL_OF_CONFIDENCE_HUMAN_FIREWALL',
  MobileDevices = 'MOBILE_DEVICES',
  Norms = 'NORMS',
  PasswordsAuthentication = 'PASSWORDS_AUTHENTICATION',
  Responsibility = 'RESPONSIBILITY',
  SocialMedia = 'SOCIAL_MEDIA',
}

export type AssessmentDomainsCursor = {
  __typename?: 'AssessmentDomainsCursor'
  /** The paginated nodes */
  nodes: Array<AssessmentDomain>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Assessment benchmarking data for an industry */
export type AssessmentIndustryBenchmark = Identifier &
  Timestamps & {
    __typename?: 'AssessmentIndustryBenchmark'
    /** Domains related to the assessment industry benchmark */
    assessmentIndustryBenchmarkDomains: Array<AssessmentIndustryBenchmarkDomain>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The specific industry for the benchmark */
    industry: Industries
    /** The unique ID of the industry average */
    industryAverageId?: Maybe<Scalars['Int']>
    /** The benchmark score for large companies */
    scoreLarge: Scalars['Float']
    /** The benchmark score for medium companies */
    scoreMedium: Scalars['Float']
    /** The benchmark score for small companies */
    scoreSmall: Scalars['Float']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

/** Assessment benchmarking domain */
export type AssessmentIndustryBenchmarkDomain = Identifier &
  Timestamps & {
    __typename?: 'AssessmentIndustryBenchmarkDomain'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The domain of the assessment industry benchmark */
    domain: AssessmentDomains
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The score for large-sized entities in the assessment industry benchmark */
    scoreLarge: Scalars['Float']
    /** The score for medium-sized entities in the assessment industry benchmark */
    scoreMedium: Scalars['Float']
    /** The score for small-sized entities in the assessment industry benchmark */
    scoreSmall: Scalars['Float']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type AssessmentIndustryBenchmarksCursor = {
  __typename?: 'AssessmentIndustryBenchmarksCursor'
  /** The paginated nodes */
  nodes: Array<AssessmentIndustryBenchmark>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Security Culture Survey results */
export type AssessmentResults = {
  __typename?: 'AssessmentResults'
  /** A list of domain-specific results within the survey */
  domains: Array<AssessmentDomainResult>
  /** The total score achieved in the survey */
  score: Scalars['Float']
}

export enum AssessmentTypes {
  Culture = 'CULTURE',
  Knowledge = 'KNOWLEDGE',
}

export type Assessments = Identifier & {
  __typename?: 'Assessments'
  /** The boolean value indicating a specific condition */
  boolean: Booleans
  /** The type of comparison being made */
  comparison: EventComparisons
  /** The count of assessments */
  count: Scalars['Int']
  /** The domains being assessed */
  domains: Array<AssessmentDomain>
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** Specifies the inclusion criteria for the training assessment */
  inclusion: TrainingAssessmentInclusions
  /** The timeframe for the assessment */
  timeframe: Timeframe
}

export type AssessmentsArgument = {
  bool: Booleans
  count: Scalars['Int']
  countType: EventComparisons
  domains: Array<AssessmentDomainArgument>
  inclusionType: TrainingAssessmentInclusions
  timeframe: TimeframeArgument
}

/** ModStore asset property */
export type AssetProperty = {
  __typename?: 'AssetProperty'
  /** Describes the capability of the asset property */
  capability?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  /** The unique identifier of the asset property */
  id: Scalars['Int']
  /** The name of the asset property */
  name?: Maybe<Scalars['String']>
}

/** A type of attachment */
export type Attachment = {
  __typename?: 'Attachment'
  /** The file extension of the attachment */
  ext: Scalars['String']
  /** The name of the attachment */
  name: Scalars['String']
}

/** Audit event details */
export type AuditDetails = {
  __typename?: 'AuditDetails'
  /** The action performed in the audit */
  action?: Maybe<Scalars['String']>
  eventTypeDescription?: Maybe<Scalars['String']>
  /** The name of the audit event type */
  eventTypeName?: Maybe<Scalars['String']>
}

/** An audit log entry of an event that occurred in the system */
export type AuditLog = {
  __typename?: 'AuditLog'
  /** Data content of the audit log entry */
  data: AuditLogData
  /** Extended information of the audit log entry, if available */
  extended?: Maybe<AuditLogExtended>
  /** Indicates whether the audit log entry has extended information */
  hasExtended: Scalars['Boolean']
  /** The unique identifier of the audit log entry */
  id: Scalars['String']
  /** Metadata associated with the audit log entry */
  metadata: AuditLogMetadata
}

export enum AuditLogActivities {
  CcmCourseArchived = 'CCM_COURSE_ARCHIVED',
  CcmCourseCreated = 'CCM_COURSE_CREATED',
  CcmCoursePublished = 'CCM_COURSE_PUBLISHED',
  CcmCourseUnarchived = 'CCM_COURSE_UNARCHIVED',
  CcmCourseUpdated = 'CCM_COURSE_UPDATED',
  CcmTranslatedCourseAssetApproved = 'CCM_TRANSLATED_COURSE_ASSET_APPROVED',
  CcmTranslatedCourseAssetCreated = 'CCM_TRANSLATED_COURSE_ASSET_CREATED',
  CcmTranslatedCourseAssetDeleted = 'CCM_TRANSLATED_COURSE_ASSET_DELETED',
  CcmTranslatedCourseAssetPublished = 'CCM_TRANSLATED_COURSE_ASSET_PUBLISHED',
  CcmTranslatedCourseAssetUpdated = 'CCM_TRANSLATED_COURSE_ASSET_UPDATED',
  KmsatAccountCategoryHidden = 'KMSAT_ACCOUNT_CATEGORY_HIDDEN',
  KmsatAccountCategoryUnhidden = 'KMSAT_ACCOUNT_CATEGORY_UNHIDDEN',
  KmsatAccountEmailTemplateHidden = 'KMSAT_ACCOUNT_EMAIL_TEMPLATE_HIDDEN',
  KmsatAccountEmailTemplateUnhidden = 'KMSAT_ACCOUNT_EMAIL_TEMPLATE_UNHIDDEN',
  KmsatAccountGupConfigRemoved = 'KMSAT_ACCOUNT_GUP_CONFIG_REMOVED',
  KmsatAccountGupConfigSaved = 'KMSAT_ACCOUNT_GUP_CONFIG_SAVED',
  KmsatAccountGupCredentialsSaved = 'KMSAT_ACCOUNT_GUP_CREDENTIALS_SAVED',
  KmsatAccountGupSyncPaused = 'KMSAT_ACCOUNT_GUP_SYNC_PAUSED',
  KmsatAccountGupSyncStarted = 'KMSAT_ACCOUNT_GUP_SYNC_STARTED',
  KmsatAccountPhishingBetaDisabled = 'KMSAT_ACCOUNT_PHISHING_BETA_DISABLED',
  KmsatAccountPhishingBetaEnabled = 'KMSAT_ACCOUNT_PHISHING_BETA_ENABLED',
  KmsatAccountPhishDomainHidden = 'KMSAT_ACCOUNT_PHISH_DOMAIN_HIDDEN',
  KmsatAccountPhishDomainUnhidden = 'KMSAT_ACCOUNT_PHISH_DOMAIN_UNHIDDEN',
  KmsatAccountUpdated = 'KMSAT_ACCOUNT_UPDATED',
  KmsatAccountUpdatedDefaultContentTheme = 'KMSAT_ACCOUNT_UPDATED_DEFAULT_CONTENT_THEME',
  KmsatAccountUpdatedPartnerAccess = 'KMSAT_ACCOUNT_UPDATED_PARTNER_ACCESS',
  KmsatAccountUpdatedToken = 'KMSAT_ACCOUNT_UPDATED_TOKEN',
  KmsatApiTokensReassigned = 'KMSAT_API_TOKENS_REASSIGNED',
  KmsatApiTokenCreated = 'KMSAT_API_TOKEN_CREATED',
  KmsatApiTokenDeleted = 'KMSAT_API_TOKEN_DELETED',
  KmsatApiTokenUpdated = 'KMSAT_API_TOKEN_UPDATED',
  KmsatAsapProgramCreated = 'KMSAT_ASAP_PROGRAM_CREATED',
  KmsatAsapProgramDeleted = 'KMSAT_ASAP_PROGRAM_DELETED',
  KmsatAsapProgramTaskUpdated = 'KMSAT_ASAP_PROGRAM_TASK_UPDATED',
  KmsatCallbackPhishingGreetingTemplateCloned = 'KMSAT_CALLBACK_PHISHING_GREETING_TEMPLATE_CLONED',
  KmsatCallbackPhishingGreetingTemplateCreated = 'KMSAT_CALLBACK_PHISHING_GREETING_TEMPLATE_CREATED',
  KmsatCallbackPhishingGreetingTemplateDeleted = 'KMSAT_CALLBACK_PHISHING_GREETING_TEMPLATE_DELETED',
  KmsatCallbackPhishingGreetingTemplateTopicAddedTo = 'KMSAT_CALLBACK_PHISHING_GREETING_TEMPLATE_TOPIC_ADDED_TO',
  KmsatCallbackPhishingGreetingTemplateTopicRemovedFrom = 'KMSAT_CALLBACK_PHISHING_GREETING_TEMPLATE_TOPIC_REMOVED_FROM',
  KmsatCallbackPhishingGreetingTemplateUpdated = 'KMSAT_CALLBACK_PHISHING_GREETING_TEMPLATE_UPDATED',
  KmsatCallbackPhishingResponseTemplateCloned = 'KMSAT_CALLBACK_PHISHING_RESPONSE_TEMPLATE_CLONED',
  KmsatCallbackPhishingResponseTemplateCreated = 'KMSAT_CALLBACK_PHISHING_RESPONSE_TEMPLATE_CREATED',
  KmsatCallbackPhishingResponseTemplateDeleted = 'KMSAT_CALLBACK_PHISHING_RESPONSE_TEMPLATE_DELETED',
  KmsatCallbackPhishingResponseTemplateTopicAddedTo = 'KMSAT_CALLBACK_PHISHING_RESPONSE_TEMPLATE_TOPIC_ADDED_TO',
  KmsatCallbackPhishingResponseTemplateTopicRemovedFrom = 'KMSAT_CALLBACK_PHISHING_RESPONSE_TEMPLATE_TOPIC_REMOVED_FROM',
  KmsatCallbackPhishingResponseTemplateUpdated = 'KMSAT_CALLBACK_PHISHING_RESPONSE_TEMPLATE_UPDATED',
  KmsatCallbackPhishingTemplateCloned = 'KMSAT_CALLBACK_PHISHING_TEMPLATE_CLONED',
  KmsatCallbackPhishingTemplateCreated = 'KMSAT_CALLBACK_PHISHING_TEMPLATE_CREATED',
  KmsatCallbackPhishingTemplateSoftDeleted = 'KMSAT_CALLBACK_PHISHING_TEMPLATE_SOFT_DELETED',
  KmsatCallbackPhishingTemplateTopicAddedTo = 'KMSAT_CALLBACK_PHISHING_TEMPLATE_TOPIC_ADDED_TO',
  KmsatCallbackPhishingTemplateTopicRemovedFrom = 'KMSAT_CALLBACK_PHISHING_TEMPLATE_TOPIC_REMOVED_FROM',
  KmsatCallbackPhishingTemplateUpdated = 'KMSAT_CALLBACK_PHISHING_TEMPLATE_UPDATED',
  KmsatCampaignCreated = 'KMSAT_CAMPAIGN_CREATED',
  KmsatCampaignDeactivated = 'KMSAT_CAMPAIGN_DEACTIVATED',
  KmsatCampaignDeleted = 'KMSAT_CAMPAIGN_DELETED',
  KmsatCampaignHiddenFromReports = 'KMSAT_CAMPAIGN_HIDDEN_FROM_REPORTS',
  KmsatCampaignReactivated = 'KMSAT_CAMPAIGN_REACTIVATED',
  KmsatCampaignUnhiddenFromReports = 'KMSAT_CAMPAIGN_UNHIDDEN_FROM_REPORTS',
  KmsatCampaignUpdated = 'KMSAT_CAMPAIGN_UPDATED',
  KmsatContentThemeCloned = 'KMSAT_CONTENT_THEME_CLONED',
  KmsatContentThemeCreated = 'KMSAT_CONTENT_THEME_CREATED',
  KmsatContentThemeDeleted = 'KMSAT_CONTENT_THEME_DELETED',
  KmsatContentThemeUpdated = 'KMSAT_CONTENT_THEME_UPDATED',
  KmsatDkimConfigCreated = 'KMSAT_DKIM_CONFIG_CREATED',
  KmsatDkimConfigDeleted = 'KMSAT_DKIM_CONFIG_DELETED',
  KmsatDkimConfigUpdated = 'KMSAT_DKIM_CONFIG_UPDATED',
  KmsatDkimConfigValidated = 'KMSAT_DKIM_CONFIG_VALIDATED',
  KmsatEnvironmentQuestionnaireCleared = 'KMSAT_ENVIRONMENT_QUESTIONNAIRE_CLEARED',
  KmsatEnvironmentQuestionnaireSaved = 'KMSAT_ENVIRONMENT_QUESTIONNAIRE_SAVED',
  KmsatExecReportsDashboardCreated = 'KMSAT_EXEC_REPORTS_DASHBOARD_CREATED',
  KmsatExecReportsDashboardDeleted = 'KMSAT_EXEC_REPORTS_DASHBOARD_DELETED',
  KmsatExecReportsDashboardUpdated = 'KMSAT_EXEC_REPORTS_DASHBOARD_UPDATED',
  KmsatGroupsBulkDeleted = 'KMSAT_GROUPS_BULK_DELETED',
  KmsatGroupArchived = 'KMSAT_GROUP_ARCHIVED',
  KmsatGroupArchivedUsers = 'KMSAT_GROUP_ARCHIVED_USERS',
  KmsatGroupCloned = 'KMSAT_GROUP_CLONED',
  KmsatGroupCreated = 'KMSAT_GROUP_CREATED',
  KmsatGroupDeleted = 'KMSAT_GROUP_DELETED',
  KmsatGroupRemovedUsers = 'KMSAT_GROUP_REMOVED_USERS',
  KmsatGroupUnarchived = 'KMSAT_GROUP_UNARCHIVED',
  KmsatGroupUpdated = 'KMSAT_GROUP_UPDATED',
  KmsatGroupUpdatedCriterions = 'KMSAT_GROUP_UPDATED_CRITERIONS',
  KmsatIpRangeCreated = 'KMSAT_IP_RANGE_CREATED',
  KmsatIpRangeDeleted = 'KMSAT_IP_RANGE_DELETED',
  KmsatIpRangePurged = 'KMSAT_IP_RANGE_PURGED',
  KmsatIpRangeUpdated = 'KMSAT_IP_RANGE_UPDATED',
  KmsatLandingPagesImported = 'KMSAT_LANDING_PAGES_IMPORTED',
  KmsatLandingPageCategoriesReordered = 'KMSAT_LANDING_PAGE_CATEGORIES_REORDERED',
  KmsatLandingPageCategoryCreated = 'KMSAT_LANDING_PAGE_CATEGORY_CREATED',
  KmsatLandingPageCategoryDeleted = 'KMSAT_LANDING_PAGE_CATEGORY_DELETED',
  KmsatLandingPageCategoryUpdated = 'KMSAT_LANDING_PAGE_CATEGORY_UPDATED',
  KmsatLandingPageCreated = 'KMSAT_LANDING_PAGE_CREATED',
  KmsatLandingPageDeleted = 'KMSAT_LANDING_PAGE_DELETED',
  KmsatLandingPageMoved = 'KMSAT_LANDING_PAGE_MOVED',
  KmsatLandingPageUpdated = 'KMSAT_LANDING_PAGE_UPDATED',
  KmsatMessagesCampaignActivated = 'KMSAT_MESSAGES_CAMPAIGN_ACTIVATED',
  KmsatMessagesCampaignCreated = 'KMSAT_MESSAGES_CAMPAIGN_CREATED',
  KmsatMessagesCampaignDeactivated = 'KMSAT_MESSAGES_CAMPAIGN_DEACTIVATED',
  KmsatMessagesCampaignUpdated = 'KMSAT_MESSAGES_CAMPAIGN_UPDATED',
  KmsatNotificationTemplatesImported = 'KMSAT_NOTIFICATION_TEMPLATES_IMPORTED',
  KmsatNotificationTemplateCategoriesReordered = 'KMSAT_NOTIFICATION_TEMPLATE_CATEGORIES_REORDERED',
  KmsatNotificationTemplateCategoryCreated = 'KMSAT_NOTIFICATION_TEMPLATE_CATEGORY_CREATED',
  KmsatNotificationTemplateCategoryDeleted = 'KMSAT_NOTIFICATION_TEMPLATE_CATEGORY_DELETED',
  KmsatNotificationTemplateCategoryUpdated = 'KMSAT_NOTIFICATION_TEMPLATE_CATEGORY_UPDATED',
  KmsatNotificationTemplateCreated = 'KMSAT_NOTIFICATION_TEMPLATE_CREATED',
  KmsatNotificationTemplateMoved = 'KMSAT_NOTIFICATION_TEMPLATE_MOVED',
  KmsatNotificationTemplateSoftDeleted = 'KMSAT_NOTIFICATION_TEMPLATE_SOFT_DELETED',
  KmsatNotificationTemplateUpdated = 'KMSAT_NOTIFICATION_TEMPLATE_UPDATED',
  KmsatPhishalertLanguageCreated = 'KMSAT_PHISHALERT_LANGUAGE_CREATED',
  KmsatPhishalertLanguageDeleted = 'KMSAT_PHISHALERT_LANGUAGE_DELETED',
  KmsatPhishalertLanguageUpdated = 'KMSAT_PHISHALERT_LANGUAGE_UPDATED',
  KmsatPhishalertSettingCreated = 'KMSAT_PHISHALERT_SETTING_CREATED',
  KmsatPhishalertSettingUpdated = 'KMSAT_PHISHALERT_SETTING_UPDATED',
  KmsatPhishingTemplatesImported = 'KMSAT_PHISHING_TEMPLATES_IMPORTED',
  KmsatPhishingTemplatesShared = 'KMSAT_PHISHING_TEMPLATES_SHARED',
  KmsatPhishingTemplateCategoriesReordered = 'KMSAT_PHISHING_TEMPLATE_CATEGORIES_REORDERED',
  KmsatPhishingTemplateCategoryCreated = 'KMSAT_PHISHING_TEMPLATE_CATEGORY_CREATED',
  KmsatPhishingTemplateCategoryDeleted = 'KMSAT_PHISHING_TEMPLATE_CATEGORY_DELETED',
  KmsatPhishingTemplateCategoryUpdated = 'KMSAT_PHISHING_TEMPLATE_CATEGORY_UPDATED',
  KmsatPhishingTemplateCloned = 'KMSAT_PHISHING_TEMPLATE_CLONED',
  KmsatPhishingTemplateCreated = 'KMSAT_PHISHING_TEMPLATE_CREATED',
  KmsatPhishingTemplateMoved = 'KMSAT_PHISHING_TEMPLATE_MOVED',
  KmsatPhishingTemplateSoftDeleted = 'KMSAT_PHISHING_TEMPLATE_SOFT_DELETED',
  KmsatPhishingTemplateTopicAddedTo = 'KMSAT_PHISHING_TEMPLATE_TOPIC_ADDED_TO',
  KmsatPhishingTemplateTopicRemovedFrom = 'KMSAT_PHISHING_TEMPLATE_TOPIC_REMOVED_FROM',
  KmsatPhishingTemplateUpdated = 'KMSAT_PHISHING_TEMPLATE_UPDATED',
  KmsatPhishDomainCreated = 'KMSAT_PHISH_DOMAIN_CREATED',
  KmsatPhishDomainDeleted = 'KMSAT_PHISH_DOMAIN_DELETED',
  KmsatPhysicalQrCampaignCompleted = 'KMSAT_PHYSICAL_QR_CAMPAIGN_COMPLETED',
  KmsatPhysicalQrCampaignCreated = 'KMSAT_PHYSICAL_QR_CAMPAIGN_CREATED',
  KmsatPhysicalQrCampaignDeleted = 'KMSAT_PHYSICAL_QR_CAMPAIGN_DELETED',
  KmsatPhysicalQrCampaignLocationCreated = 'KMSAT_PHYSICAL_QR_CAMPAIGN_LOCATION_CREATED',
  KmsatPhysicalQrCampaignLocationDeleted = 'KMSAT_PHYSICAL_QR_CAMPAIGN_LOCATION_DELETED',
  KmsatPhysicalQrCampaignLocationTemplateDownloaded = 'KMSAT_PHYSICAL_QR_CAMPAIGN_LOCATION_TEMPLATE_DOWNLOADED',
  KmsatPhysicalQrCampaignLocationUpdated = 'KMSAT_PHYSICAL_QR_CAMPAIGN_LOCATION_UPDATED',
  KmsatPhysicalQrCampaignStarted = 'KMSAT_PHYSICAL_QR_CAMPAIGN_STARTED',
  KmsatPhysicalQrCampaignUpdated = 'KMSAT_PHYSICAL_QR_CAMPAIGN_UPDATED',
  KmsatPhysicalQrTemplateCloned = 'KMSAT_PHYSICAL_QR_TEMPLATE_CLONED',
  KmsatPhysicalQrTemplateCreated = 'KMSAT_PHYSICAL_QR_TEMPLATE_CREATED',
  KmsatPhysicalQrTemplateSoftDeleted = 'KMSAT_PHYSICAL_QR_TEMPLATE_SOFT_DELETED',
  KmsatPhysicalQrTemplateTopicAddedTo = 'KMSAT_PHYSICAL_QR_TEMPLATE_TOPIC_ADDED_TO',
  KmsatPhysicalQrTemplateTopicRemovedFrom = 'KMSAT_PHYSICAL_QR_TEMPLATE_TOPIC_REMOVED_FROM',
  KmsatPhysicalQrTemplateUpdated = 'KMSAT_PHYSICAL_QR_TEMPLATE_UPDATED',
  KmsatPolicyAssetCreated = 'KMSAT_POLICY_ASSET_CREATED',
  KmsatPolicyAssetDeleted = 'KMSAT_POLICY_ASSET_DELETED',
  KmsatPolicyAssetUpdated = 'KMSAT_POLICY_ASSET_UPDATED',
  KmsatPolicyCreated = 'KMSAT_POLICY_CREATED',
  KmsatPolicySoftDeleted = 'KMSAT_POLICY_SOFT_DELETED',
  KmsatPolicyUpdated = 'KMSAT_POLICY_UPDATED',
  KmsatReportCategoriesReordered = 'KMSAT_REPORT_CATEGORIES_REORDERED',
  KmsatReportCategoryCreated = 'KMSAT_REPORT_CATEGORY_CREATED',
  KmsatReportCategoryDeleted = 'KMSAT_REPORT_CATEGORY_DELETED',
  KmsatReportCategoryUpdated = 'KMSAT_REPORT_CATEGORY_UPDATED',
  KmsatSavedReportCloned = 'KMSAT_SAVED_REPORT_CLONED',
  KmsatSavedReportCreated = 'KMSAT_SAVED_REPORT_CREATED',
  KmsatSavedReportDeleted = 'KMSAT_SAVED_REPORT_DELETED',
  KmsatSavedReportMoved = 'KMSAT_SAVED_REPORT_MOVED',
  KmsatSavedReportUpdated = 'KMSAT_SAVED_REPORT_UPDATED',
  KmsatScheduledReportCreated = 'KMSAT_SCHEDULED_REPORT_CREATED',
  KmsatScheduledReportDeactivated = 'KMSAT_SCHEDULED_REPORT_DEACTIVATED',
  KmsatScheduledReportDeleted = 'KMSAT_SCHEDULED_REPORT_DELETED',
  KmsatScheduledReportSent = 'KMSAT_SCHEDULED_REPORT_SENT',
  KmsatScheduledReportUpdated = 'KMSAT_SCHEDULED_REPORT_UPDATED',
  KmsatSecurityRoleCreated = 'KMSAT_SECURITY_ROLE_CREATED',
  KmsatSecurityRoleDeleted = 'KMSAT_SECURITY_ROLE_DELETED',
  KmsatSecurityRoleUpdated = 'KMSAT_SECURITY_ROLE_UPDATED',
  KmsatSmartHostDeleted = 'KMSAT_SMART_HOST_DELETED',
  KmsatSmartHostSaved = 'KMSAT_SMART_HOST_SAVED',
  KmsatStorePurchaseCreated = 'KMSAT_STORE_PURCHASE_CREATED',
  KmsatStorePurchaseDeleted = 'KMSAT_STORE_PURCHASE_DELETED',
  KmsatStorePurchaseDownloaded = 'KMSAT_STORE_PURCHASE_DOWNLOADED',
  KmsatStorePurchaseUpdated = 'KMSAT_STORE_PURCHASE_UPDATED',
  KmsatTemplateTopicCreated = 'KMSAT_TEMPLATE_TOPIC_CREATED',
  KmsatTemplateTopicDeleted = 'KMSAT_TEMPLATE_TOPIC_DELETED',
  KmsatTemplateTopicUpdated = 'KMSAT_TEMPLATE_TOPIC_UPDATED',
  KmsatTrainingCampaignAddedUser = 'KMSAT_TRAINING_CAMPAIGN_ADDED_USER',
  KmsatTrainingCampaignAddedUserCsv = 'KMSAT_TRAINING_CAMPAIGN_ADDED_USER_CSV',
  KmsatTrainingCampaignClosed = 'KMSAT_TRAINING_CAMPAIGN_CLOSED',
  KmsatTrainingCampaignCreated = 'KMSAT_TRAINING_CAMPAIGN_CREATED',
  KmsatTrainingCampaignEnrollmentsForcePassed = 'KMSAT_TRAINING_CAMPAIGN_ENROLLMENTS_FORCE_PASSED',
  KmsatTrainingCampaignEnrollmentsForcePassedCsv = 'KMSAT_TRAINING_CAMPAIGN_ENROLLMENTS_FORCE_PASSED_CSV',
  KmsatTrainingCampaignEnrollmentsReEnrolled = 'KMSAT_TRAINING_CAMPAIGN_ENROLLMENTS_RE_ENROLLED',
  KmsatTrainingCampaignEnrollmentsReEnrolledCsv = 'KMSAT_TRAINING_CAMPAIGN_ENROLLMENTS_RE_ENROLLED_CSV',
  KmsatTrainingCampaignEnrollmentsSoftDeleted = 'KMSAT_TRAINING_CAMPAIGN_ENROLLMENTS_SOFT_DELETED',
  KmsatTrainingCampaignNotificationCreated = 'KMSAT_TRAINING_CAMPAIGN_NOTIFICATION_CREATED',
  KmsatTrainingCampaignNotificationDeleted = 'KMSAT_TRAINING_CAMPAIGN_NOTIFICATION_DELETED',
  KmsatTrainingCampaignNotificationUpdated = 'KMSAT_TRAINING_CAMPAIGN_NOTIFICATION_UPDATED',
  KmsatTrainingCampaignRemovedUser = 'KMSAT_TRAINING_CAMPAIGN_REMOVED_USER',
  KmsatTrainingCampaignRemovedUserCsv = 'KMSAT_TRAINING_CAMPAIGN_REMOVED_USER_CSV',
  KmsatTrainingCampaignSoftDeleted = 'KMSAT_TRAINING_CAMPAIGN_SOFT_DELETED',
  KmsatTrainingCampaignUpdated = 'KMSAT_TRAINING_CAMPAIGN_UPDATED',
  KmsatUsbCampaignCompleted = 'KMSAT_USB_CAMPAIGN_COMPLETED',
  KmsatUsbCampaignCreated = 'KMSAT_USB_CAMPAIGN_CREATED',
  KmsatUsbCampaignDeleted = 'KMSAT_USB_CAMPAIGN_DELETED',
  KmsatUsbCampaignRestarted = 'KMSAT_USB_CAMPAIGN_RESTARTED',
  KmsatUsbCampaignStarted = 'KMSAT_USB_CAMPAIGN_STARTED',
  KmsatUsbCampaignUpdated = 'KMSAT_USB_CAMPAIGN_UPDATED',
  KmsatUsersArchived = 'KMSAT_USERS_ARCHIVED',
  KmsatUsersDeleted = 'KMSAT_USERS_DELETED',
  KmsatUsersImported = 'KMSAT_USERS_IMPORTED',
  KmsatUserAddedToGroups = 'KMSAT_USER_ADDED_TO_GROUPS',
  KmsatUserArchived = 'KMSAT_USER_ARCHIVED',
  KmsatUserCreated = 'KMSAT_USER_CREATED',
  KmsatUserDeleted = 'KMSAT_USER_DELETED',
  KmsatUserGrantAdmin = 'KMSAT_USER_GRANT_ADMIN',
  KmsatUserImpersonated = 'KMSAT_USER_IMPERSONATED',
  KmsatUserMerged = 'KMSAT_USER_MERGED',
  KmsatUserRevokeAdmin = 'KMSAT_USER_REVOKE_ADMIN',
  KmsatUserUnarchived = 'KMSAT_USER_UNARCHIVED',
  KmsatUserUpdated = 'KMSAT_USER_UPDATED',
}

/** The users responsible for an audit log */
export type AuditLogActors = {
  __typename?: 'AuditLogActors'
  /** The admin who impersonated another user associated with the audit log */
  impersonatingUser?: Maybe<User>
  /** The parent partner admin associated with the audit log */
  parentPartnerAdmin?: Maybe<PartnerAdmin>
  /** Indicates if a parent partner admin is present in the audit log */
  parentPartnerAdminPresent?: Maybe<Scalars['Boolean']>
  /** The partner admin associated with the audit log */
  partnerAdmin?: Maybe<PartnerAdmin>
  /** Indicates if a partner admin is present in the audit log */
  partnerAdminPresent?: Maybe<Scalars['Boolean']>
  /** The site admin associated with the audit log */
  siteAdmin?: Maybe<SiteAdmin>
  /** Indicates if a site admin is present in the audit log */
  siteAdminPresent?: Maybe<Scalars['Boolean']>
  /** The user associated with the audit log */
  user?: Maybe<User>
}

/** A change of a field inside of an audit log */
export type AuditLogChange = {
  __typename?: 'AuditLogChange'
  /** The current value of the field after the change */
  current?: Maybe<Scalars['String']>
  /** The name of the field that was changed */
  fieldName?: Maybe<Scalars['String']>
  /** The page number for the previous page of results */
  previous?: Maybe<Scalars['String']>
}

export enum AuditLogChangeFields {
  AccessPermissionAccountSettings = 'ACCESS_PERMISSION_ACCOUNT_SETTINGS',
  AccessPermissionAdvancedReporting = 'ACCESS_PERMISSION_ADVANCED_REPORTING',
  AccessPermissionAsap = 'ACCESS_PERMISSION_ASAP',
  AccessPermissionAuditLog = 'ACCESS_PERMISSION_AUDIT_LOG',
  AccessPermissionBrandedContents = 'ACCESS_PERMISSION_BRANDED_CONTENTS',
  AccessPermissionCanAddUsersAndGroupsToTrainingCampaign = 'ACCESS_PERMISSION_CAN_ADD_USERS_AND_GROUPS_TO_TRAINING_CAMPAIGN',
  AccessPermissionCanSeeTrainingCampaignsWithRestrictedUsers = 'ACCESS_PERMISSION_CAN_SEE_TRAINING_CAMPAIGNS_WITH_RESTRICTED_USERS',
  AccessPermissionDashboardPhishing = 'ACCESS_PERMISSION_DASHBOARD_PHISHING',
  AccessPermissionDashboardTraining = 'ACCESS_PERMISSION_DASHBOARD_TRAINING',
  AccessPermissionExecutiveReports = 'ACCESS_PERMISSION_EXECUTIVE_REPORTS',
  AccessPermissionPasswordIq = 'ACCESS_PERMISSION_PASSWORD_IQ',
  AccessPermissionPhishingCampaigns = 'ACCESS_PERMISSION_PHISHING_CAMPAIGNS',
  AccessPermissionPhishingLandingPages = 'ACCESS_PERMISSION_PHISHING_LANDING_PAGES',
  AccessPermissionPhishingReports = 'ACCESS_PERMISSION_PHISHING_REPORTS',
  AccessPermissionPhishingTemplates = 'ACCESS_PERMISSION_PHISHING_TEMPLATES',
  AccessPermissionPhysicalQrCampaigns = 'ACCESS_PERMISSION_PHYSICAL_QR_CAMPAIGNS',
  AccessPermissionProvisioning = 'ACCESS_PERMISSION_PROVISIONING',
  AccessPermissionReportsDetectionRulesActivity = 'ACCESS_PERMISSION_REPORTS_DETECTION_RULES_ACTIVITY',
  AccessPermissionReportsEmailTemplatesSent = 'ACCESS_PERMISSION_REPORTS_EMAIL_TEMPLATES_SENT',
  AccessPermissionReportsFailuresByPhishingTemplate = 'ACCESS_PERMISSION_REPORTS_FAILURES_BY_PHISHING_TEMPLATE',
  AccessPermissionReportsFailureTypes = 'ACCESS_PERMISSION_REPORTS_FAILURE_TYPES',
  AccessPermissionReportsGroupAndUserCards = 'ACCESS_PERMISSION_REPORTS_GROUP_AND_USER_CARDS',
  AccessPermissionReportsLearnerPlatform = 'ACCESS_PERMISSION_REPORTS_LEARNER_PLATFORM',
  AccessPermissionReportsPhishingActivity = 'ACCESS_PERMISSION_REPORTS_PHISHING_ACTIVITY',
  AccessPermissionReportsPhishingResultsByUser = 'ACCESS_PERMISSION_REPORTS_PHISHING_RESULTS_BY_USER',
  AccessPermissionReportsPhishFailurePercentage = 'ACCESS_PERMISSION_REPORTS_PHISH_FAILURE_PERCENTAGE',
  AccessPermissionReportsPhishPronePercentage = 'ACCESS_PERMISSION_REPORTS_PHISH_PRONE_PERCENTAGE',
  AccessPermissionReportsQuizInteractions = 'ACCESS_PERMISSION_REPORTS_QUIZ_INTERACTIONS',
  AccessPermissionReportsRealTimeCoachingActivity = 'ACCESS_PERMISSION_REPORTS_REAL_TIME_COACHING_ACTIVITY',
  AccessPermissionReportsRiskScore = 'ACCESS_PERMISSION_REPORTS_RISK_SCORE',
  AccessPermissionReportsSecurityAwarenessProficiencyAssessment = 'ACCESS_PERMISSION_REPORTS_SECURITY_AWARENESS_PROFICIENCY_ASSESSMENT',
  AccessPermissionReportsSecurityCultureSurvey = 'ACCESS_PERMISSION_REPORTS_SECURITY_CULTURE_SURVEY',
  AccessPermissionReportsTrainingActivity = 'ACCESS_PERMISSION_REPORTS_TRAINING_ACTIVITY',
  AccessPermissionReportsTrainingComparison = 'ACCESS_PERMISSION_REPORTS_TRAINING_COMPARISON',
  AccessPermissionReportsTrainingContentSurvey = 'ACCESS_PERMISSION_REPORTS_TRAINING_CONTENT_SURVEY',
  AccessPermissionReportsTrainingHours = 'ACCESS_PERMISSION_REPORTS_TRAINING_HOURS',
  AccessPermissionReportsTrainingStatusByCampaign = 'ACCESS_PERMISSION_REPORTS_TRAINING_STATUS_BY_CAMPAIGN',
  AccessPermissionReportsTrainingStatusOverview = 'ACCESS_PERMISSION_REPORTS_TRAINING_STATUS_OVERVIEW',
  AccessPermissionReportsUnphishedAndUntrainedUser = 'ACCESS_PERMISSION_REPORTS_UNPHISHED_AND_UNTRAINED_USER',
  AccessPermissionReportsUserCountAndLogin = 'ACCESS_PERMISSION_REPORTS_USER_COUNT_AND_LOGIN',
  AccessPermissionSecondChanceSettings = 'ACCESS_PERMISSION_SECOND_CHANCE_SETTINGS',
  AccessPermissionSecurityCoachDashboardAndReports = 'ACCESS_PERMISSION_SECURITY_COACH_DASHBOARD_AND_REPORTS',
  AccessPermissionSecurityCoachDetectionRules = 'ACCESS_PERMISSION_SECURITY_COACH_DETECTION_RULES',
  AccessPermissionSecurityCoachRtcAndCatalog = 'ACCESS_PERMISSION_SECURITY_COACH_RTC_AND_CATALOG',
  AccessPermissionSecurityCoachSetup = 'ACCESS_PERMISSION_SECURITY_COACH_SETUP',
  AccessPermissionSendReports = 'ACCESS_PERMISSION_SEND_REPORTS',
  AccessPermissionStoreItems = 'ACCESS_PERMISSION_STORE_ITEMS',
  AccessPermissionTrainingCampaigns = 'ACCESS_PERMISSION_TRAINING_CAMPAIGNS',
  AccessPermissionTrainingPolicies = 'ACCESS_PERMISSION_TRAINING_POLICIES',
  AccessPermissionTrainingPurchases = 'ACCESS_PERMISSION_TRAINING_PURCHASES',
  AccessPermissionTrainingReports = 'ACCESS_PERMISSION_TRAINING_REPORTS',
  AccessPermissionTrainingTemplates = 'ACCESS_PERMISSION_TRAINING_TEMPLATES',
  AccessPermissionTrainingUploadedContent = 'ACCESS_PERMISSION_TRAINING_UPLOADED_CONTENT',
  AccessPermissionUsbCampaigns = 'ACCESS_PERMISSION_USB_CAMPAIGNS',
  AccessPermissionUsers = 'ACCESS_PERMISSION_USERS',
  AccessProfileName = 'ACCESS_PROFILE_NAME',
  AccountAccountAdminTimeout = 'ACCOUNT_ACCOUNT_ADMIN_TIMEOUT',
  AccountAllowUsersToSignup = 'ACCOUNT_ALLOW_USERS_TO_SIGNUP',
  AccountApiv2Enabled = 'ACCOUNT_APIV2_ENABLED',
  AccountApiEnabled = 'ACCOUNT_API_ENABLED',
  AccountApiToken = 'ACCOUNT_API_TOKEN',
  AccountBenchmarkCompanySize = 'ACCOUNT_BENCHMARK_COMPANY_SIZE',
  AccountBenchmarkIndustry = 'ACCOUNT_BENCHMARK_INDUSTRY',
  AccountBenchmarkTimeframe = 'ACCOUNT_BENCHMARK_TIMEFRAME',
  AccountBusinessDays = 'ACCOUNT_BUSINESS_DAYS',
  AccountBusinessHoursEnd = 'ACCOUNT_BUSINESS_HOURS_END',
  AccountBusinessHoursStart = 'ACCOUNT_BUSINESS_HOURS_START',
  AccountCertBackgroundFileName = 'ACCOUNT_CERT_BACKGROUND_FILE_NAME',
  AccountCity = 'ACCOUNT_CITY',
  AccountCompanyName = 'ACCOUNT_COMPANY_NAME',
  AccountCountry = 'ACCOUNT_COUNTRY',
  AccountCustomPhishingHeaderEnabled = 'ACCOUNT_CUSTOM_PHISHING_HEADER_ENABLED',
  AccountCustomPhishingHeaderName = 'ACCOUNT_CUSTOM_PHISHING_HEADER_NAME',
  AccountCustomPhishingHeaderValue = 'ACCOUNT_CUSTOM_PHISHING_HEADER_VALUE',
  AccountCustomTrainingHeaderEnabled = 'ACCOUNT_CUSTOM_TRAINING_HEADER_ENABLED',
  AccountCustomTrainingHeaderName = 'ACCOUNT_CUSTOM_TRAINING_HEADER_NAME',
  AccountCustomTrainingHeaderValue = 'ACCOUNT_CUSTOM_TRAINING_HEADER_VALUE',
  AccountDefaultContentTheme = 'ACCOUNT_DEFAULT_CONTENT_THEME',
  AccountDefaultLandingDomain = 'ACCOUNT_DEFAULT_LANDING_DOMAIN',
  AccountDefaultLandingPage = 'ACCOUNT_DEFAULT_LANDING_PAGE',
  AccountDefaultLocale = 'ACCOUNT_DEFAULT_LOCALE',
  AccountDisableOpenTracking = 'ACCOUNT_DISABLE_OPEN_TRACKING',
  AccountDisablePasslessForAdmins = 'ACCOUNT_DISABLE_PASSLESS_FOR_ADMINS',
  AccountDisablePhishTestHeader = 'ACCOUNT_DISABLE_PHISH_TEST_HEADER',
  AccountDisableReturnPathHeader = 'ACCOUNT_DISABLE_RETURN_PATH_HEADER',
  AccountDisableReturnPathHeaderForTraining = 'ACCOUNT_DISABLE_RETURN_PATH_HEADER_FOR_TRAINING',
  AccountEnablePstTokenHeader = 'ACCOUNT_ENABLE_PST_TOKEN_HEADER',
  AccountExtension = 'ACCOUNT_EXTENSION',
  AccountHasApiv2 = 'ACCOUNT_HAS_APIV2',
  AccountHasPassless = 'ACCOUNT_HAS_PASSLESS',
  AccountHasSecondChance = 'ACCOUNT_HAS_SECOND_CHANCE',
  AccountHiddenEmailTemplates = 'ACCOUNT_HIDDEN_EMAIL_TEMPLATES',
  AccountHiddenTemplateCategories = 'ACCOUNT_HIDDEN_TEMPLATE_CATEGORIES',
  AccountIdpCertFingerprint = 'ACCOUNT_IDP_CERT_FINGERPRINT',
  AccountIdpCertFormat = 'ACCOUNT_IDP_CERT_FORMAT',
  AccountIdpSsoTargetUrl = 'ACCOUNT_IDP_SSO_TARGET_URL',
  AccountIncludeArchivedUsersInReports = 'ACCOUNT_INCLUDE_ARCHIVED_USERS_IN_REPORTS',
  AccountLdapTestMode = 'ACCOUNT_LDAP_TEST_MODE',
  AccountLdapToken = 'ACCOUNT_LDAP_TOKEN',
  AccountLogoFileName = 'ACCOUNT_LOGO_FILE_NAME',
  AccountLogoUrl = 'ACCOUNT_LOGO_URL',
  AccountMinimumPasswordLength = 'ACCOUNT_MINIMUM_PASSWORD_LENGTH',
  AccountOofFromOverride = 'ACCOUNT_OOF_FROM_OVERRIDE',
  AccountOverriddenPhishDomains = 'ACCOUNT_OVERRIDDEN_PHISH_DOMAINS',
  AccountPartnerAccessExpiration = 'ACCOUNT_PARTNER_ACCESS_EXPIRATION',
  AccountPasslessExpiresIn = 'ACCOUNT_PASSLESS_EXPIRES_IN',
  AccountPasswordIqEnabled = 'ACCOUNT_PASSWORD_IQ_ENABLED',
  AccountPhishalertEnabled = 'ACCOUNT_PHISHALERT_ENABLED',
  AccountPhoneNumber = 'ACCOUNT_PHONE_NUMBER',
  AccountPopcornTheaterReduceMotion = 'ACCOUNT_POPCORN_THEATER_REDUCE_MOTION',
  AccountPrimaryColor = 'ACCOUNT_PRIMARY_COLOR',
  AccountPrtSenderEmail = 'ACCOUNT_PRT_SENDER_EMAIL',
  AccountPrtSenderName = 'ACCOUNT_PRT_SENDER_NAME',
  AccountPstToken = 'ACCOUNT_PST_TOKEN',
  AccountSamlEnabled = 'ACCOUNT_SAML_ENABLED',
  AccountSamlUserProvisioningEnabled = 'ACCOUNT_SAML_USER_PROVISIONING_ENABLED',
  AccountSettingsEecScanDayOfMonth = 'ACCOUNT_SETTINGS_EEC_SCAN_DAY_OF_MONTH',
  AccountSettingsIpRestrictionEnforceSameIp = 'ACCOUNT_SETTINGS_IP_RESTRICTION_ENFORCE_SAME_IP',
  AccountSettingsIpRestrictionIpAllowList = 'ACCOUNT_SETTINGS_IP_RESTRICTION_IP_ALLOW_LIST',
  AccountSettingsIpRestrictionRestrictConsoleSession = 'ACCOUNT_SETTINGS_IP_RESTRICTION_RESTRICT_CONSOLE_SESSION',
  AccountSettingsLanguageDefaultAdminLocale = 'ACCOUNT_SETTINGS_LANGUAGE_DEFAULT_ADMIN_LOCALE',
  AccountSettingsLanguageDefaultPhishingLocale = 'ACCOUNT_SETTINGS_LANGUAGE_DEFAULT_PHISHING_LOCALE',
  AccountSettingsLanguageDefaultTrainingLocale = 'ACCOUNT_SETTINGS_LANGUAGE_DEFAULT_TRAINING_LOCALE',
  AccountSettingsLearnerExperienceAidaOptionalTrainingEnabled = 'ACCOUNT_SETTINGS_LEARNER_EXPERIENCE_AIDA_OPTIONAL_TRAINING_ENABLED',
  AccountSettingsLearnerExperienceCustomHelpEnabled = 'ACCOUNT_SETTINGS_LEARNER_EXPERIENCE_CUSTOM_HELP_ENABLED',
  AccountSettingsLearnerExperienceCustomHelpUrl = 'ACCOUNT_SETTINGS_LEARNER_EXPERIENCE_CUSTOM_HELP_URL',
  AccountSettingsLearnerExperienceDashboardEnabled = 'ACCOUNT_SETTINGS_LEARNER_EXPERIENCE_DASHBOARD_ENABLED',
  AccountSettingsLearnerExperienceDashboardShowsPhishingStatistics = 'ACCOUNT_SETTINGS_LEARNER_EXPERIENCE_DASHBOARD_SHOWS_PHISHING_STATISTICS',
  AccountSettingsLearnerExperienceDashboardShowsRiskStatistics = 'ACCOUNT_SETTINGS_LEARNER_EXPERIENCE_DASHBOARD_SHOWS_RISK_STATISTICS',
  AccountSettingsLearnerExperienceDisableTour = 'ACCOUNT_SETTINGS_LEARNER_EXPERIENCE_DISABLE_TOUR',
  AccountSettingsLearnerExperienceEnableUsersToRequestLearnerAppOnboardingEmails = 'ACCOUNT_SETTINGS_LEARNER_EXPERIENCE_ENABLE_USERS_TO_REQUEST_LEARNER_APP_ONBOARDING_EMAILS',
  AccountSettingsLearnerExperienceManagerPhishingEnabled = 'ACCOUNT_SETTINGS_LEARNER_EXPERIENCE_MANAGER_PHISHING_ENABLED',
  AccountSettingsLearnerExperienceManagerRiskScoreEnabled = 'ACCOUNT_SETTINGS_LEARNER_EXPERIENCE_MANAGER_RISK_SCORE_ENABLED',
  AccountSettingsLearnerExperienceManagerTrainingEnabled = 'ACCOUNT_SETTINGS_LEARNER_EXPERIENCE_MANAGER_TRAINING_ENABLED',
  AccountSettingsLearnerExperienceManagerUserInfoEnabled = 'ACCOUNT_SETTINGS_LEARNER_EXPERIENCE_MANAGER_USER_INFO_ENABLED',
  AccountSettingsLearnerExperienceMobileAppEnabled = 'ACCOUNT_SETTINGS_LEARNER_EXPERIENCE_MOBILE_APP_ENABLED',
  AccountSettingsLearnerExperienceOptionalTrainingEnabled = 'ACCOUNT_SETTINGS_LEARNER_EXPERIENCE_OPTIONAL_TRAINING_ENABLED',
  AccountSettingsPhishingCustomDomainPlaceholder = 'ACCOUNT_SETTINGS_PHISHING_CUSTOM_DOMAIN_PLACEHOLDER',
  AccountSettingsPhishingCustomDomainPlaceholderEnabled = 'ACCOUNT_SETTINGS_PHISHING_CUSTOM_DOMAIN_PLACEHOLDER_ENABLED',
  AccountSettingsPhishingDisabledTemplateAttackVectors = 'ACCOUNT_SETTINGS_PHISHING_DISABLED_TEMPLATE_ATTACK_VECTORS',
  AccountSettingsPhishingOverrideRphAddress = 'ACCOUNT_SETTINGS_PHISHING_OVERRIDE_RPH_ADDRESS',
  AccountSettingsPhishingTemplateExclusions = 'ACCOUNT_SETTINGS_PHISHING_TEMPLATE_EXCLUSIONS',
  AccountSettingsPlaceholderAutoPrependEmailBannerPhishing = 'ACCOUNT_SETTINGS_PLACEHOLDER_AUTO_PREPEND_EMAIL_BANNER_PHISHING',
  AccountSettingsPlaceholderAutoPrependSubjectPrefixPhishing = 'ACCOUNT_SETTINGS_PLACEHOLDER_AUTO_PREPEND_SUBJECT_PREFIX_PHISHING',
  AccountSettingsPlaceholderEmailBanner = 'ACCOUNT_SETTINGS_PLACEHOLDER_EMAIL_BANNER',
  AccountSettingsPlaceholderNameFormat = 'ACCOUNT_SETTINGS_PLACEHOLDER_NAME_FORMAT',
  AccountSettingsPlaceholderSignatureBlock = 'ACCOUNT_SETTINGS_PLACEHOLDER_SIGNATURE_BLOCK',
  AccountSettingsPlaceholderSubjectPrefix = 'ACCOUNT_SETTINGS_PLACEHOLDER_SUBJECT_PREFIX',
  AccountSettingsSamlAllowAdminWithMfaLoginBypass = 'ACCOUNT_SETTINGS_SAML_ALLOW_ADMIN_WITH_MFA_LOGIN_BYPASS',
  AccountSettingsSamlCertificateNotificationEnabled = 'ACCOUNT_SETTINGS_SAML_CERTIFICATE_NOTIFICATION_ENABLED',
  AccountSettingsSamlCertificateNotificationRecipients = 'ACCOUNT_SETTINGS_SAML_CERTIFICATE_NOTIFICATION_RECIPIENTS',
  AccountSettingsSamlDisableNonSamlLogins = 'ACCOUNT_SETTINGS_SAML_DISABLE_NON_SAML_LOGINS',
  AccountSettingsSamlEntityId = 'ACCOUNT_SETTINGS_SAML_ENTITY_ID',
  AccountSettingsSamlSignSamlRequests = 'ACCOUNT_SETTINGS_SAML_SIGN_SAML_REQUESTS',
  AccountSettingsSamlUseLatestCertificate = 'ACCOUNT_SETTINGS_SAML_USE_LATEST_CERTIFICATE',
  AccountSettingsSecondChanceSettingEnabled = 'ACCOUNT_SETTINGS_SECOND_CHANCE_SETTING_ENABLED',
  AccountSettingsTrainingEnableLocalizedNotifications = 'ACCOUNT_SETTINGS_TRAINING_ENABLE_LOCALIZED_NOTIFICATIONS',
  AccountSettingsTrainingEnableNotificationDirectLinks = 'ACCOUNT_SETTINGS_TRAINING_ENABLE_NOTIFICATION_DIRECT_LINKS',
  AccountSettingsTrainingEnableScormInteractions = 'ACCOUNT_SETTINGS_TRAINING_ENABLE_SCORM_INTERACTIONS',
  AccountSettingsTrainingEnableSurveysDefault = 'ACCOUNT_SETTINGS_TRAINING_ENABLE_SURVEYS_DEFAULT',
  AccountSettingsTrainingScormInteractionsRetentionMonths = 'ACCOUNT_SETTINGS_TRAINING_SCORM_INTERACTIONS_RETENTION_MONTHS',
  AccountSettingsUsersDisablePasslessEmailAutogen = 'ACCOUNT_SETTINGS_USERS_DISABLE_PASSLESS_EMAIL_AUTOGEN',
  AccountSettingsUsersForceMfa = 'ACCOUNT_SETTINGS_USERS_FORCE_MFA',
  AccountSettingsUsersForcePasswordChangeOnFirstLogin = 'ACCOUNT_SETTINGS_USERS_FORCE_PASSWORD_CHANGE_ON_FIRST_LOGIN',
  AccountSettingsUsersMfaRememberDeviceDays = 'ACCOUNT_SETTINGS_USERS_MFA_REMEMBER_DEVICE_DAYS',
  AccountSettingsUsersMfaRememberDeviceEnabled = 'ACCOUNT_SETTINGS_USERS_MFA_REMEMBER_DEVICE_ENABLED',
  AccountSettingsUserMovesAutofixUserDupError = 'ACCOUNT_SETTINGS_USER_MOVES_AUTOFIX_USER_DUP_ERROR',
  AccountSettingsUserProvisioningGoogleCustomerId = 'ACCOUNT_SETTINGS_USER_PROVISIONING_GOOGLE_CUSTOMER_ID',
  AccountSettingsUserProvisioningGoogleSubjectEmail = 'ACCOUNT_SETTINGS_USER_PROVISIONING_GOOGLE_SUBJECT_EMAIL',
  AccountSettingsUserProvisioningNotificationEmailToNotify = 'ACCOUNT_SETTINGS_USER_PROVISIONING_NOTIFICATION_EMAIL_TO_NOTIFY',
  AccountSettingsUserProvisioningNotificationEnabled = 'ACCOUNT_SETTINGS_USER_PROVISIONING_NOTIFICATION_ENABLED',
  AccountSettingsUserProvisioningNotificationTimeframeDuration = 'ACCOUNT_SETTINGS_USER_PROVISIONING_NOTIFICATION_TIMEFRAME_DURATION',
  AccountSettingsUserProvisioningScimPublicKey = 'ACCOUNT_SETTINGS_USER_PROVISIONING_SCIM_PUBLIC_KEY',
  AccountSettingsUserProvisioningSource = 'ACCOUNT_SETTINGS_USER_PROVISIONING_SOURCE',
  AccountShowGroupDomain = 'ACCOUNT_SHOW_GROUP_DOMAIN',
  AccountState = 'ACCOUNT_STATE',
  AccountStreetAddress_1 = 'ACCOUNT_STREET_ADDRESS_1',
  AccountStreetAddress_2 = 'ACCOUNT_STREET_ADDRESS_2',
  AccountSuiteNumber = 'ACCOUNT_SUITE_NUMBER',
  AccountTimeZone = 'ACCOUNT_TIME_ZONE',
  AccountUserTimeout = 'ACCOUNT_USER_TIMEOUT',
  AccountZipCode = 'ACCOUNT_ZIP_CODE',
  ApiTokenActive = 'API_TOKEN_ACTIVE',
  ApiTokenCreatedBy = 'API_TOKEN_CREATED_BY',
  ApiTokenEncryptedJwt = 'API_TOKEN_ENCRYPTED_JWT',
  ApiTokenExpiresOn = 'API_TOKEN_EXPIRES_ON',
  ApiTokenName = 'API_TOKEN_NAME',
  ApiTokenPartnerAdminId = 'API_TOKEN_PARTNER_ADMIN_ID',
  ApiTokenPartnerId = 'API_TOKEN_PARTNER_ID',
  ApiTokenUser = 'API_TOKEN_USER',
  CallbackPhishingGreetingTemplateLanguageCode = 'CALLBACK_PHISHING_GREETING_TEMPLATE_LANGUAGE_CODE',
  CallbackPhishingGreetingTemplatePersona = 'CALLBACK_PHISHING_GREETING_TEMPLATE_PERSONA',
  CallbackPhishingGreetingTemplateTitle = 'CALLBACK_PHISHING_GREETING_TEMPLATE_TITLE',
  CallbackPhishingResponseTemplateLanguageCode = 'CALLBACK_PHISHING_RESPONSE_TEMPLATE_LANGUAGE_CODE',
  CallbackPhishingResponseTemplatePersona = 'CALLBACK_PHISHING_RESPONSE_TEMPLATE_PERSONA',
  CallbackPhishingResponseTemplateSecondaryCodeLength = 'CALLBACK_PHISHING_RESPONSE_TEMPLATE_SECONDARY_CODE_LENGTH',
  CallbackPhishingResponseTemplateTitle = 'CALLBACK_PHISHING_RESPONSE_TEMPLATE_TITLE',
  CampaignActive = 'CAMPAIGN_ACTIVE',
  CampaignAllUsers = 'CAMPAIGN_ALL_USERS',
  CampaignBusinessDays = 'CAMPAIGN_BUSINESS_DAYS',
  CampaignBusinessHoursEnd = 'CAMPAIGN_BUSINESS_HOURS_END',
  CampaignBusinessHoursStart = 'CAMPAIGN_BUSINESS_HOURS_START',
  CampaignCallbackPhishingGreetingTemplate = 'CAMPAIGN_CALLBACK_PHISHING_GREETING_TEMPLATE',
  CampaignCallbackPhishingPhoneNumberRegion = 'CAMPAIGN_CALLBACK_PHISHING_PHONE_NUMBER_REGION',
  CampaignClickedGroup = 'CAMPAIGN_CLICKED_GROUP',
  CampaignEmailTemplate = 'CAMPAIGN_EMAIL_TEMPLATE',
  CampaignFrequencyPeriod = 'CAMPAIGN_FREQUENCY_PERIOD',
  CampaignHideFromReports = 'CAMPAIGN_HIDE_FROM_REPORTS',
  CampaignLandingPage = 'CAMPAIGN_LANDING_PAGE',
  CampaignName = 'CAMPAIGN_NAME',
  CampaignPhishDomain = 'CAMPAIGN_PHISH_DOMAIN',
  CampaignReplyDomainPrefix = 'CAMPAIGN_REPLY_DOMAIN_PREFIX',
  CampaignReplyPhishDomain = 'CAMPAIGN_REPLY_PHISH_DOMAIN',
  CampaignSelectedGroups = 'CAMPAIGN_SELECTED_GROUPS',
  CampaignSelectedTemplateCategories = 'CAMPAIGN_SELECTED_TEMPLATE_CATEGORIES',
  CampaignSelectedTemplateTopics = 'CAMPAIGN_SELECTED_TEMPLATE_TOPICS',
  CampaignSendingDuration = 'CAMPAIGN_SENDING_DURATION',
  CampaignSendingDurationUnits = 'CAMPAIGN_SENDING_DURATION_UNITS',
  CampaignSendEmailAfterRun = 'CAMPAIGN_SEND_EMAIL_AFTER_RUN',
  CampaignSophistication = 'CAMPAIGN_SOPHISTICATION',
  CampaignSpreadEmails = 'CAMPAIGN_SPREAD_EMAILS',
  CampaignStoreFullCallbackPhoneNumber = 'CAMPAIGN_STORE_FULL_CALLBACK_PHONE_NUMBER',
  CampaignStoreReplyContent = 'CAMPAIGN_STORE_REPLY_CONTENT',
  CampaignTimeZone = 'CAMPAIGN_TIME_ZONE',
  CampaignTrackingDuration = 'CAMPAIGN_TRACKING_DURATION',
  CampaignTrackingDurationUnits = 'CAMPAIGN_TRACKING_DURATION_UNITS',
  CampaignTrackOutOfOfficeReplies = 'CAMPAIGN_TRACK_OUT_OF_OFFICE_REPLIES',
  CampaignTrackReplies = 'CAMPAIGN_TRACK_REPLIES',
  CcmCourseArchivedAt = 'CCM_COURSE_ARCHIVED_AT',
  CcmCourseDefaultLanguage = 'CCM_COURSE_DEFAULT_LANGUAGE',
  CcmCourseDescription = 'CCM_COURSE_DESCRIPTION',
  CcmCourseDisplayInLxLibrary = 'CCM_COURSE_DISPLAY_IN_LX_LIBRARY',
  CcmCourseExpectedDurationInMinutes = 'CCM_COURSE_EXPECTED_DURATION_IN_MINUTES',
  CcmCourseNotSuitableForMobile = 'CCM_COURSE_NOT_SUITABLE_FOR_MOBILE',
  CcmCoursePublishedAt = 'CCM_COURSE_PUBLISHED_AT',
  CcmCourseTitle = 'CCM_COURSE_TITLE',
  CcmCourseUploadType = 'CCM_COURSE_UPLOAD_TYPE',
  CcmTranslatedCourseAssetAasmState = 'CCM_TRANSLATED_COURSE_ASSET_AASM_STATE',
  CcmTranslatedCourseAssetDescription = 'CCM_TRANSLATED_COURSE_ASSET_DESCRIPTION',
  CcmTranslatedCourseAssetLanguage = 'CCM_TRANSLATED_COURSE_ASSET_LANGUAGE',
  CcmTranslatedCourseAssetPublishedAt = 'CCM_TRANSLATED_COURSE_ASSET_PUBLISHED_AT',
  CcmTranslatedCourseAssetTermsAcceptedAt = 'CCM_TRANSLATED_COURSE_ASSET_TERMS_ACCEPTED_AT',
  CcmTranslatedCourseAssetTitle = 'CCM_TRANSLATED_COURSE_ASSET_TITLE',
  CcmTranslatedCourseAssetUploadType = 'CCM_TRANSLATED_COURSE_ASSET_UPLOAD_TYPE',
  ContentThemeColorPrimary = 'CONTENT_THEME_COLOR_PRIMARY',
  ContentThemeLogo = 'CONTENT_THEME_LOGO',
  ContentThemeName = 'CONTENT_THEME_NAME',
  DkimConfigCustom = 'DKIM_CONFIG_CUSTOM',
  DkimConfigEmailType = 'DKIM_CONFIG_EMAIL_TYPE',
  DkimConfigEnabled = 'DKIM_CONFIG_ENABLED',
  DkimConfigFqdn = 'DKIM_CONFIG_FQDN',
  DkimConfigValidatedAt = 'DKIM_CONFIG_VALIDATED_AT',
  EmailTemplateArchived = 'EMAIL_TEMPLATE_ARCHIVED',
  EmailTemplateAttachmentFilename = 'EMAIL_TEMPLATE_ATTACHMENT_FILENAME',
  EmailTemplateAttachmentType = 'EMAIL_TEMPLATE_ATTACHMENT_TYPE',
  EmailTemplateCallbackPhishingGreetingTemplate = 'EMAIL_TEMPLATE_CALLBACK_PHISHING_GREETING_TEMPLATE',
  EmailTemplateCallbackPhishingResponseTemplate = 'EMAIL_TEMPLATE_CALLBACK_PHISHING_RESPONSE_TEMPLATE',
  EmailTemplateContentHtml = 'EMAIL_TEMPLATE_CONTENT_HTML',
  EmailTemplateFrom = 'EMAIL_TEMPLATE_FROM',
  EmailTemplateFromDisplayName = 'EMAIL_TEMPLATE_FROM_DISPLAY_NAME',
  EmailTemplateIsAida = 'EMAIL_TEMPLATE_IS_AIDA',
  EmailTemplateLandingDomain = 'EMAIL_TEMPLATE_LANDING_DOMAIN',
  EmailTemplateLandingPage = 'EMAIL_TEMPLATE_LANDING_PAGE',
  EmailTemplateLanguageCode = 'EMAIL_TEMPLATE_LANGUAGE_CODE',
  EmailTemplateName = 'EMAIL_TEMPLATE_NAME',
  EmailTemplateReplyTo = 'EMAIL_TEMPLATE_REPLY_TO',
  EmailTemplateReplyToDisplayName = 'EMAIL_TEMPLATE_REPLY_TO_DISPLAY_NAME',
  EmailTemplateSophistication = 'EMAIL_TEMPLATE_SOPHISTICATION',
  EmailTemplateSubject = 'EMAIL_TEMPLATE_SUBJECT',
  EmailTemplateTemplateCategory = 'EMAIL_TEMPLATE_TEMPLATE_CATEGORY',
  EnvironmentQuestionnaireAntivirus = 'ENVIRONMENT_QUESTIONNAIRE_ANTIVIRUS',
  EnvironmentQuestionnaireAntivirusCustom = 'ENVIRONMENT_QUESTIONNAIRE_ANTIVIRUS_CUSTOM',
  EnvironmentQuestionnaireCollaboration = 'ENVIRONMENT_QUESTIONNAIRE_COLLABORATION',
  EnvironmentQuestionnaireCollaborationCustom = 'ENVIRONMENT_QUESTIONNAIRE_COLLABORATION_CUSTOM',
  EnvironmentQuestionnaireEmailSecurity = 'ENVIRONMENT_QUESTIONNAIRE_EMAIL_SECURITY',
  EnvironmentQuestionnaireEmailSecurityCustom = 'ENVIRONMENT_QUESTIONNAIRE_EMAIL_SECURITY_CUSTOM',
  EnvironmentQuestionnaireLms = 'ENVIRONMENT_QUESTIONNAIRE_LMS',
  EnvironmentQuestionnaireLmsCustom = 'ENVIRONMENT_QUESTIONNAIRE_LMS_CUSTOM',
  EnvironmentQuestionnaireNetworkSecurity = 'ENVIRONMENT_QUESTIONNAIRE_NETWORK_SECURITY',
  EnvironmentQuestionnaireNetworkSecurityCustom = 'ENVIRONMENT_QUESTIONNAIRE_NETWORK_SECURITY_CUSTOM',
  EnvironmentQuestionnaireOtherSecurityProducts = 'ENVIRONMENT_QUESTIONNAIRE_OTHER_SECURITY_PRODUCTS',
  EnvironmentQuestionnaireSiem = 'ENVIRONMENT_QUESTIONNAIRE_SIEM',
  EnvironmentQuestionnaireSiemCustom = 'ENVIRONMENT_QUESTIONNAIRE_SIEM_CUSTOM',
  EnvironmentQuestionnaireSso = 'ENVIRONMENT_QUESTIONNAIRE_SSO',
  EnvironmentQuestionnaireSsoCustom = 'ENVIRONMENT_QUESTIONNAIRE_SSO_CUSTOM',
  EnvironmentQuestionnaireUserManagement = 'ENVIRONMENT_QUESTIONNAIRE_USER_MANAGEMENT',
  EnvironmentQuestionnaireUserManagementCustom = 'ENVIRONMENT_QUESTIONNAIRE_USER_MANAGEMENT_CUSTOM',
  EnvironmentQuestionnaireWebSecurity = 'ENVIRONMENT_QUESTIONNAIRE_WEB_SECURITY',
  EnvironmentQuestionnaireWebSecurityCustom = 'ENVIRONMENT_QUESTIONNAIRE_WEB_SECURITY_CUSTOM',
  ExecReportsDashboardAccountIds = 'EXEC_REPORTS_DASHBOARD_ACCOUNT_IDS',
  ExecReportsDashboardCreatorType = 'EXEC_REPORTS_DASHBOARD_CREATOR_TYPE',
  ExecReportsDashboardFontColor = 'EXEC_REPORTS_DASHBOARD_FONT_COLOR',
  ExecReportsDashboardFontSize = 'EXEC_REPORTS_DASHBOARD_FONT_SIZE',
  ExecReportsDashboardFontStyle = 'EXEC_REPORTS_DASHBOARD_FONT_STYLE',
  ExecReportsDashboardGroupIds = 'EXEC_REPORTS_DASHBOARD_GROUP_IDS',
  ExecReportsDashboardName = 'EXEC_REPORTS_DASHBOARD_NAME',
  ExecReportsDashboardPartnerId = 'EXEC_REPORTS_DASHBOARD_PARTNER_ID',
  ExecReportsDashboardPdfType = 'EXEC_REPORTS_DASHBOARD_PDF_TYPE',
  GamificationBadgesEnabled = 'GAMIFICATION_BADGES_ENABLED',
  GamificationBadgesEnabledList = 'GAMIFICATION_BADGES_ENABLED_LIST',
  GamificationLeaderboardsEnabled = 'GAMIFICATION_LEADERBOARDS_ENABLED',
  GamificationLeaderboardsIgnoreOptionalTraining = 'GAMIFICATION_LEADERBOARDS_IGNORE_OPTIONAL_TRAINING',
  GamificationLeaderboardGroupIds = 'GAMIFICATION_LEADERBOARD_GROUP_IDS',
  GamificationLeaderboardTimeWindow = 'GAMIFICATION_LEADERBOARD_TIME_WINDOW',
  GamificationLeaderboardType = 'GAMIFICATION_LEADERBOARD_TYPE',
  GamificationLeaderboardTypes = 'GAMIFICATION_LEADERBOARD_TYPES',
  GroupDeleted = 'GROUP_DELETED',
  GroupIsDynamic = 'GROUP_IS_DYNAMIC',
  GroupName = 'GROUP_NAME',
  GroupRiskBooster = 'GROUP_RISK_BOOSTER',
  IdpDataCompanyName = 'IDP_DATA_COMPANY_NAME',
  IdpDataCountry = 'IDP_DATA_COUNTRY',
  IdpDataEmployeeType = 'IDP_DATA_EMPLOYEE_TYPE',
  IdpDataLastPasswordChangeDateTime = 'IDP_DATA_LAST_PASSWORD_CHANGE_DATE_TIME',
  IdpDataMailNickname = 'IDP_DATA_MAIL_NICKNAME',
  IdpDataOnPremisesSamAccountName = 'IDP_DATA_ON_PREMISES_SAM_ACCOUNT_NAME',
  IdpDataOnPremisesSecurityIdentifier = 'IDP_DATA_ON_PREMISES_SECURITY_IDENTIFIER',
  IdpDataUserPrincipalName = 'IDP_DATA_USER_PRINCIPAL_NAME',
  IpRangeCreatedBy = 'IP_RANGE_CREATED_BY',
  IpRangeIpEnd = 'IP_RANGE_IP_END',
  IpRangeIpStart = 'IP_RANGE_IP_START',
  IpRangeLabel = 'IP_RANGE_LABEL',
  LandingPageCategoryName = 'LANDING_PAGE_CATEGORY_NAME',
  LandingPageContentHtml = 'LANDING_PAGE_CONTENT_HTML',
  LandingPageLandingPageCategory = 'LANDING_PAGE_LANDING_PAGE_CATEGORY',
  LandingPageTitle = 'LANDING_PAGE_TITLE',
  MessagesCampaignActive = 'MESSAGES_CAMPAIGN_ACTIVE',
  MessagesCampaignAllUsers = 'MESSAGES_CAMPAIGN_ALL_USERS',
  MessagesCampaignCustomBody = 'MESSAGES_CAMPAIGN_CUSTOM_BODY',
  MessagesCampaignCustomLanguage = 'MESSAGES_CAMPAIGN_CUSTOM_LANGUAGE',
  MessagesCampaignCustomSubject = 'MESSAGES_CAMPAIGN_CUSTOM_SUBJECT',
  MessagesCampaignFrequency = 'MESSAGES_CAMPAIGN_FREQUENCY',
  MessagesCampaignFromEmail = 'MESSAGES_CAMPAIGN_FROM_EMAIL',
  MessagesCampaignFromName = 'MESSAGES_CAMPAIGN_FROM_NAME',
  MessagesCampaignName = 'MESSAGES_CAMPAIGN_NAME',
  MessagesCampaignRecurringDayOfWeek = 'MESSAGES_CAMPAIGN_RECURRING_DAY_OF_WEEK',
  MessagesCampaignSendEmail = 'MESSAGES_CAMPAIGN_SEND_EMAIL',
  MessagesCampaignUseCompanyLogo = 'MESSAGES_CAMPAIGN_USE_COMPANY_LOGO',
  PhishalertLanguageConfirmationMessage = 'PHISHALERT_LANGUAGE_CONFIRMATION_MESSAGE',
  PhishalertLanguageDisplayPhishingDescription = 'PHISHALERT_LANGUAGE_DISPLAY_PHISHING_DESCRIPTION',
  PhishalertLanguageDisplaySpamDescription = 'PHISHALERT_LANGUAGE_DISPLAY_SPAM_DESCRIPTION',
  PhishalertLanguageDisplayUnknownDescription = 'PHISHALERT_LANGUAGE_DISPLAY_UNKNOWN_DESCRIPTION',
  PhishalertLanguageEmailForwardSubject = 'PHISHALERT_LANGUAGE_EMAIL_FORWARD_SUBJECT',
  PhishalertLanguageIsDefault = 'PHISHALERT_LANGUAGE_IS_DEFAULT',
  PhishalertLanguageLanguage = 'PHISHALERT_LANGUAGE_LANGUAGE',
  PhishalertLanguageMessageReportNonPst = 'PHISHALERT_LANGUAGE_MESSAGE_REPORT_NON_PST',
  PhishalertLanguageMessageReportPst = 'PHISHALERT_LANGUAGE_MESSAGE_REPORT_PST',
  PhishalertLanguagePhishingDescription = 'PHISHALERT_LANGUAGE_PHISHING_DESCRIPTION',
  PhishalertLanguagePhishingLabel = 'PHISHALERT_LANGUAGE_PHISHING_LABEL',
  PhishalertLanguageReportButtonText = 'PHISHALERT_LANGUAGE_REPORT_BUTTON_TEXT',
  PhishalertLanguageReportGroupText = 'PHISHALERT_LANGUAGE_REPORT_GROUP_TEXT',
  PhishalertLanguageShowMessageReportNonPst = 'PHISHALERT_LANGUAGE_SHOW_MESSAGE_REPORT_NON_PST',
  PhishalertLanguageShowMessageReportPst = 'PHISHALERT_LANGUAGE_SHOW_MESSAGE_REPORT_PST',
  PhishalertLanguageSpamDescription = 'PHISHALERT_LANGUAGE_SPAM_DESCRIPTION',
  PhishalertLanguageSpamLabel = 'PHISHALERT_LANGUAGE_SPAM_LABEL',
  PhishalertLanguageTimeoutReportPst = 'PHISHALERT_LANGUAGE_TIMEOUT_REPORT_PST',
  PhishalertLanguageUnknownDescription = 'PHISHALERT_LANGUAGE_UNKNOWN_DESCRIPTION',
  PhishalertLanguageUnknownLabel = 'PHISHALERT_LANGUAGE_UNKNOWN_LABEL',
  PhishalertSettingAddHeadersAsAttachment = 'PHISHALERT_SETTING_ADD_HEADERS_AS_ATTACHMENT',
  PhishalertSettingAllowUsersToComment = 'PHISHALERT_SETTING_ALLOW_USERS_TO_COMMENT',
  PhishalertSettingAlsoCc = 'PHISHALERT_SETTING_ALSO_CC',
  PhishalertSettingDefaultDisposition = 'PHISHALERT_SETTING_DEFAULT_DISPOSITION',
  PhishalertSettingDisableCridValidation = 'PHISHALERT_SETTING_DISABLE_CRID_VALIDATION',
  PhishalertSettingDisableUnknownDisposition = 'PHISHALERT_SETTING_DISABLE_UNKNOWN_DISPOSITION',
  PhishalertSettingEmailFormat = 'PHISHALERT_SETTING_EMAIL_FORMAT',
  PhishalertSettingEmailForward = 'PHISHALERT_SETTING_EMAIL_FORWARD',
  PhishalertSettingEmailForwardMicrosoftAddin = 'PHISHALERT_SETTING_EMAIL_FORWARD_MICROSOFT_ADDIN',
  PhishalertSettingEmailForwardPhishing = 'PHISHALERT_SETTING_EMAIL_FORWARD_PHISHING',
  PhishalertSettingEmailForwardSpam = 'PHISHALERT_SETTING_EMAIL_FORWARD_SPAM',
  PhishalertSettingEmailForwardUnknown = 'PHISHALERT_SETTING_EMAIL_FORWARD_UNKNOWN',
  PhishalertSettingEnableForwarding = 'PHISHALERT_SETTING_ENABLE_FORWARDING',
  PhishalertSettingExcludeEmailBodyInReport = 'PHISHALERT_SETTING_EXCLUDE_EMAIL_BODY_IN_REPORT',
  PhishalertSettingGlobalDefault = 'PHISHALERT_SETTING_GLOBAL_DEFAULT',
  PhishalertSettingIncludeHeadersInEmailBody = 'PHISHALERT_SETTING_INCLUDE_HEADERS_IN_EMAIL_BODY',
  PhishalertSettingName = 'PHISHALERT_SETTING_NAME',
  PhishalertSettingPabIcon = 'PHISHALERT_SETTING_PAB_ICON',
  PhishalertSettingPullLocaleForPhishing = 'PHISHALERT_SETTING_PULL_LOCALE_FOR_PHISHING',
  PhishalertSettingSaveToSentItems = 'PHISHALERT_SETTING_SAVE_TO_SENT_ITEMS',
  PhishalertSettingSendToMicrosoft = 'PHISHALERT_SETTING_SEND_TO_MICROSOFT',
  PhishalertSettingSendToMicrosoftAddin = 'PHISHALERT_SETTING_SEND_TO_MICROSOFT_ADDIN',
  PhishDomainName = 'PHISH_DOMAIN_NAME',
  PhysicalQrCampaignCompletedAt = 'PHYSICAL_QR_CAMPAIGN_COMPLETED_AT',
  PhysicalQrCampaignEmailTemplate = 'PHYSICAL_QR_CAMPAIGN_EMAIL_TEMPLATE',
  PhysicalQrCampaignLandingPage = 'PHYSICAL_QR_CAMPAIGN_LANDING_PAGE',
  PhysicalQrCampaignLocationLocation = 'PHYSICAL_QR_CAMPAIGN_LOCATION_LOCATION',
  PhysicalQrCampaignLocationNotes = 'PHYSICAL_QR_CAMPAIGN_LOCATION_NOTES',
  PhysicalQrCampaignName = 'PHYSICAL_QR_CAMPAIGN_NAME',
  PhysicalQrCampaignNotes = 'PHYSICAL_QR_CAMPAIGN_NOTES',
  PhysicalQrCampaignPhishDomain = 'PHYSICAL_QR_CAMPAIGN_PHISH_DOMAIN',
  PhysicalQrCampaignStartedAt = 'PHYSICAL_QR_CAMPAIGN_STARTED_AT',
  PhysicalQrCampaignStatus = 'PHYSICAL_QR_CAMPAIGN_STATUS',
  PolicyAssetAcceptanceRequirements = 'POLICY_ASSET_ACCEPTANCE_REQUIREMENTS',
  PolicyAssetAcceptanceText = 'POLICY_ASSET_ACCEPTANCE_TEXT',
  PolicyAssetDescription = 'POLICY_ASSET_DESCRIPTION',
  PolicyAssetDocumentPrompt = 'POLICY_ASSET_DOCUMENT_PROMPT',
  PolicyAssetFileName = 'POLICY_ASSET_FILE_NAME',
  PolicyAssetLanguage = 'POLICY_ASSET_LANGUAGE',
  PolicyAssetPagePrompt = 'POLICY_ASSET_PAGE_PROMPT',
  PolicyAssetTitle = 'POLICY_ASSET_TITLE',
  PolicyAssetUrl = 'POLICY_ASSET_URL',
  PolicyAssetUuid = 'POLICY_ASSET_UUID',
  PolicyDefaultLanguage = 'POLICY_DEFAULT_LANGUAGE',
  PolicyDeleted = 'POLICY_DELETED',
  PolicyDescription = 'POLICY_DESCRIPTION',
  PolicyDownloadable = 'POLICY_DOWNLOADABLE',
  PolicyMinimumTime = 'POLICY_MINIMUM_TIME',
  PolicyPublishedAt = 'POLICY_PUBLISHED_AT',
  PolicyStatus = 'POLICY_STATUS',
  PolicyTitle = 'POLICY_TITLE',
  PolicyType = 'POLICY_TYPE',
  ProgramCreatedAt = 'PROGRAM_CREATED_AT',
  ProgramTaskCompletedDate = 'PROGRAM_TASK_COMPLETED_DATE',
  ProgramTaskEndDate = 'PROGRAM_TASK_END_DATE',
  ProgramTaskStartDate = 'PROGRAM_TASK_START_DATE',
  PurchasedContentOptionsContentTheme = 'PURCHASED_CONTENT_OPTIONS_CONTENT_THEME',
  PurchasedContentOptionsDefaultCaptions = 'PURCHASED_CONTENT_OPTIONS_DEFAULT_CAPTIONS',
  PurchasedContentOptionsNonSkip = 'PURCHASED_CONTENT_OPTIONS_NON_SKIP',
  PurchasedContentOptionsPassingScore = 'PURCHASED_CONTENT_OPTIONS_PASSING_SCORE',
  PurchasedContentOptionsReferenceMaterialOrder = 'PURCHASED_CONTENT_OPTIONS_REFERENCE_MATERIAL_ORDER',
  PurchasedContentOptionsReferenceMaterialUrl = 'PURCHASED_CONTENT_OPTIONS_REFERENCE_MATERIAL_URL',
  PurchasedContentOptionsTestoutPassingScore = 'PURCHASED_CONTENT_OPTIONS_TESTOUT_PASSING_SCORE',
  PurchasedContentOptionsTestOut = 'PURCHASED_CONTENT_OPTIONS_TEST_OUT',
  ReportCategoryName = 'REPORT_CATEGORY_NAME',
  SavedReportName = 'SAVED_REPORT_NAME',
  SavedReportReportCategory = 'SAVED_REPORT_REPORT_CATEGORY',
  ScheduledReportCreatorChangedAt = 'SCHEDULED_REPORT_CREATOR_CHANGED_AT',
  ScheduledReportFrequencyPeriod = 'SCHEDULED_REPORT_FREQUENCY_PERIOD',
  ScheduledReportName = 'SCHEDULED_REPORT_NAME',
  ScheduledReportOriginalCreatorId = 'SCHEDULED_REPORT_ORIGINAL_CREATOR_ID',
  ScheduledReportOriginalCreatorType = 'SCHEDULED_REPORT_ORIGINAL_CREATOR_TYPE',
  ScheduledReportRetryCount = 'SCHEDULED_REPORT_RETRY_COUNT',
  ScheduledReportSendToCreator = 'SCHEDULED_REPORT_SEND_TO_CREATOR',
  ScheduledReportStartDate = 'SCHEDULED_REPORT_START_DATE',
  ScheduledReportTime = 'SCHEDULED_REPORT_TIME',
  ScheduledReportTimeZone = 'SCHEDULED_REPORT_TIME_ZONE',
  SmartHostDomain = 'SMART_HOST_DOMAIN',
  SmartHostHost = 'SMART_HOST_HOST',
  SmartHostPassword = 'SMART_HOST_PASSWORD',
  SmartHostPort = 'SMART_HOST_PORT',
  SmartHostPriority = 'SMART_HOST_PRIORITY',
  SmartHostRequiretls = 'SMART_HOST_REQUIRETLS',
  SmartHostUsername = 'SMART_HOST_USERNAME',
  StorePurchaseHidden = 'STORE_PURCHASE_HIDDEN',
  StorePurchasePurchasedAt = 'STORE_PURCHASE_PURCHASED_AT',
  StorePurchaseStoreItemUuid = 'STORE_PURCHASE_STORE_ITEM_UUID',
  TemplateCategoryName = 'TEMPLATE_CATEGORY_NAME',
  TemplateTopicName = 'TEMPLATE_TOPIC_NAME',
  ThemeTranslationEmail = 'THEME_TRANSLATION_EMAIL',
  ThemeTranslationPhoneNumber = 'THEME_TRANSLATION_PHONE_NUMBER',
  TrainingCampaignAddPassedToGroups = 'TRAINING_CAMPAIGN_ADD_PASSED_TO_GROUPS',
  TrainingCampaignAllowPastDueCompletions = 'TRAINING_CAMPAIGN_ALLOW_PAST_DUE_COMPLETIONS',
  TrainingCampaignAllowSurveys = 'TRAINING_CAMPAIGN_ALLOW_SURVEYS',
  TrainingCampaignAllowSurveyComments = 'TRAINING_CAMPAIGN_ALLOW_SURVEY_COMMENTS',
  TrainingCampaignAllUsers = 'TRAINING_CAMPAIGN_ALL_USERS',
  TrainingCampaignAnonymizeAssessmentScores = 'TRAINING_CAMPAIGN_ANONYMIZE_ASSESSMENT_SCORES',
  TrainingCampaignAutoEnroll = 'TRAINING_CAMPAIGN_AUTO_ENROLL',
  TrainingCampaignAutoUnenroll = 'TRAINING_CAMPAIGN_AUTO_UNENROLL',
  TrainingCampaignClosedAt = 'TRAINING_CAMPAIGN_CLOSED_AT',
  TrainingCampaignContentTheme = 'TRAINING_CAMPAIGN_CONTENT_THEME',
  TrainingCampaignDeleted = 'TRAINING_CAMPAIGN_DELETED',
  TrainingCampaignEndCampaignAt = 'TRAINING_CAMPAIGN_END_CAMPAIGN_AT',
  TrainingCampaignEnrollmentDuration = 'TRAINING_CAMPAIGN_ENROLLMENT_DURATION',
  TrainingCampaignEnrollmentDurationType = 'TRAINING_CAMPAIGN_ENROLLMENT_DURATION_TYPE',
  TrainingCampaignKnowledgeRefreshersDelayInDays = 'TRAINING_CAMPAIGN_KNOWLEDGE_REFRESHERS_DELAY_IN_DAYS',
  TrainingCampaignKnowledgeRefreshersEnabled = 'TRAINING_CAMPAIGN_KNOWLEDGE_REFRESHERS_ENABLED',
  TrainingCampaignName = 'TRAINING_CAMPAIGN_NAME',
  TrainingCampaignNotificationAdminEmailTemplate = 'TRAINING_CAMPAIGN_NOTIFICATION_ADMIN_EMAIL_TEMPLATE',
  TrainingCampaignNotificationDeliveryMethod = 'TRAINING_CAMPAIGN_NOTIFICATION_DELIVERY_METHOD',
  TrainingCampaignNotificationEmailTemplate = 'TRAINING_CAMPAIGN_NOTIFICATION_EMAIL_TEMPLATE',
  TrainingCampaignNotificationEnabled = 'TRAINING_CAMPAIGN_NOTIFICATION_ENABLED',
  TrainingCampaignNotificationManagerEmailTemplate = 'TRAINING_CAMPAIGN_NOTIFICATION_MANAGER_EMAIL_TEMPLATE',
  TrainingCampaignNotificationMessageType = 'TRAINING_CAMPAIGN_NOTIFICATION_MESSAGE_TYPE',
  TrainingCampaignNotificationOffsetNumDays = 'TRAINING_CAMPAIGN_NOTIFICATION_OFFSET_NUM_DAYS',
  TrainingCampaignNotificationRecipientUserIds = 'TRAINING_CAMPAIGN_NOTIFICATION_RECIPIENT_USER_IDS',
  TrainingCampaignNotificationRescheduleOffset = 'TRAINING_CAMPAIGN_NOTIFICATION_RESCHEDULE_OFFSET',
  TrainingCampaignNotificationScheduledAt = 'TRAINING_CAMPAIGN_NOTIFICATION_SCHEDULED_AT',
  TrainingCampaignRemovePassedFromGroups = 'TRAINING_CAMPAIGN_REMOVE_PASSED_FROM_GROUPS',
  TrainingCampaignResetAutoEnrollments = 'TRAINING_CAMPAIGN_RESET_AUTO_ENROLLMENTS',
  TrainingCampaignSelectedContent = 'TRAINING_CAMPAIGN_SELECTED_CONTENT',
  TrainingCampaignSelectedGroups = 'TRAINING_CAMPAIGN_SELECTED_GROUPS',
  TrainingCampaignStartCampaignAt = 'TRAINING_CAMPAIGN_START_CAMPAIGN_AT',
  TrainingCampaignTimeZone = 'TRAINING_CAMPAIGN_TIME_ZONE',
  TrainingCampaignTrackScores = 'TRAINING_CAMPAIGN_TRACK_SCORES',
  TrainingCampaignType = 'TRAINING_CAMPAIGN_TYPE',
  UsbCampaignCompletedAt = 'USB_CAMPAIGN_COMPLETED_AT',
  UsbCampaignLocation = 'USB_CAMPAIGN_LOCATION',
  UsbCampaignName = 'USB_CAMPAIGN_NAME',
  UsbCampaignNotes = 'USB_CAMPAIGN_NOTES',
  UsbCampaignStartedAt = 'USB_CAMPAIGN_STARTED_AT',
  UsbCampaignStatus = 'USB_CAMPAIGN_STATUS',
  UserAccountOwner = 'USER_ACCOUNT_OWNER',
  UserArchived = 'USER_ARCHIVED',
  UserArchivedAt = 'USER_ARCHIVED_AT',
  UserComment = 'USER_COMMENT',
  UserCustomDate_1 = 'USER_CUSTOM_DATE_1',
  UserCustomDate_2 = 'USER_CUSTOM_DATE_2',
  UserCustomField_1 = 'USER_CUSTOM_FIELD_1',
  UserCustomField_2 = 'USER_CUSTOM_FIELD_2',
  UserCustomField_3 = 'USER_CUSTOM_FIELD_3',
  UserCustomField_4 = 'USER_CUSTOM_FIELD_4',
  UserDepartment = 'USER_DEPARTMENT',
  UserDivision = 'USER_DIVISION',
  UserEmail = 'USER_EMAIL',
  UserEmployeeNumber = 'USER_EMPLOYEE_NUMBER',
  UserEmployeeStartDate = 'USER_EMPLOYEE_START_DATE',
  UserExtension = 'USER_EXTENSION',
  UserFirstName = 'USER_FIRST_NAME',
  UserJobTitle = 'USER_JOB_TITLE',
  UserLanguage = 'USER_LANGUAGE',
  UserLastName = 'USER_LAST_NAME',
  UserLdapEnabled = 'USER_LDAP_ENABLED',
  UserLocation = 'USER_LOCATION',
  UserManagerEmail = 'USER_MANAGER_EMAIL',
  UserManagerName = 'USER_MANAGER_NAME',
  UserMfaEnabled = 'USER_MFA_ENABLED',
  UserMobilePhoneNumber = 'USER_MOBILE_PHONE_NUMBER',
  UserOrganization = 'USER_ORGANIZATION',
  UserOutOfOfficeUntil = 'USER_OUT_OF_OFFICE_UNTIL',
  UserPhisherEnabled = 'USER_PHISHER_ENABLED',
  UserPhoneNumber = 'USER_PHONE_NUMBER',
  UserRiskBooster = 'USER_RISK_BOOSTER',
  UserRole = 'USER_ROLE',
  UserSettingsLanguageAdminLocale = 'USER_SETTINGS_LANGUAGE_ADMIN_LOCALE',
  UserSettingsLanguagePhishingLocale = 'USER_SETTINGS_LANGUAGE_PHISHING_LOCALE',
  UserSettingsLanguageTrainingLocale = 'USER_SETTINGS_LANGUAGE_TRAINING_LOCALE',
  UserSettingsPreferenceDefaultExecReportsDashboard = 'USER_SETTINGS_PREFERENCE_DEFAULT_EXEC_REPORTS_DASHBOARD',
  UserTimeZone = 'USER_TIME_ZONE',
}

/** The data of an audit log */
export type AuditLogData = {
  __typename?: 'AuditLogData'
  /** Users involved in the audit log event */
  actors?: Maybe<AuditLogActors>
  /** The metadata within the data of an audit log event */
  auditMetadata?: Maybe<AuditLogDataMetadata>
  /** A list of changes made in the audit log */
  changes?: Maybe<Array<AuditLogChange>>
  /** The category of the object affected in the audit log */
  objectCategory?: Maybe<AuditLogObjectCategories>
  /** The type of the object affected in the audit log */
  objectType?: Maybe<AuditLogObjectTypes>
}

/** The metadata within the data of an audit log */
export type AuditLogDataMetadata = {
  __typename?: 'AuditLogDataMetadata'
  /** The API token used to generate the audit log entry */
  apiToken?: Maybe<ApiToken>
}

/** The extended section of an audit log */
export type AuditLogExtended = {
  __typename?: 'AuditLogExtended'
  /** List of changes in the audit log */
  changes?: Maybe<Array<AuditLogChange>>
}

/** The metadata of an audit log event */
export type AuditLogMetadata = {
  __typename?: 'AuditLogMetadata'
  /** The account ID associated with the audit log entry */
  accountId?: Maybe<Scalars['String']>
  /** The type of activity recorded in the audit log */
  activity?: Maybe<AuditLogActivities>
  /** The context ID associated with the audit log */
  contextId?: Maybe<Scalars['String']>
  /** The environment where the audit log was generated */
  environment?: Maybe<Scalars['String']>
  /** A unique key for the log entry ensuring idempotency */
  idempotencyKey?: Maybe<Scalars['String']>
  /** The resource affected by the audit log activity */
  resource?: Maybe<AuditLogResource>
  /** The schema version of the audit log */
  schemaVersion?: Maybe<Scalars['String']>
  /** The timestamp when the audit log entry was created */
  timestamp: Scalars['ISO8601DateTime']
}

export enum AuditLogObjectCategories {
  Account = 'account',
  Asap = 'asap',
  CallbackPhishing = 'callback_phishing',
  Dashboard = 'dashboard',
  Modstore = 'modstore',
  Phishing = 'phishing',
  Physical = 'physical',
  Reports = 'reports',
  Training = 'training',
  Users = 'users',
}

export type AuditLogObjectSearchCursor = {
  __typename?: 'AuditLogObjectSearchCursor'
  /** The paginated nodes */
  nodes: Array<AuditLogResource>
  /** Information about the current paginated set */
  pagination: Pagination
}

export enum AuditLogObjectTypes {
  Account = 'account',
  AidaPhishingTemplateSettings = 'aida_phishing_template_settings',
  ApiToken = 'api_token',
  AsapProgram = 'asap_program',
  AsapProgramTask = 'asap_program_task',
  CallbackPhishingGreetingTemplate = 'callback_phishing_greeting_template',
  CallbackPhishingResponseTemplate = 'callback_phishing_response_template',
  CallbackPhishingTemplate = 'callback_phishing_template',
  CcmCourse = 'ccm_course',
  CcmTranslatedCourseAsset = 'ccm_translated_course_asset',
  ContentTheme = 'content_theme',
  DkimConfig = 'dkim_config',
  EnvironmentQuestionnaire = 'environment_questionnaire',
  ExecReportsExecReportsDashboard = 'exec_reports__exec_reports_dashboard',
  Group = 'group',
  IpRange = 'ip_range',
  LandingPage = 'landing_page',
  LandingPageCategory = 'landing_page_category',
  MessagesCampaign = 'messages__campaign',
  Notification = 'notification',
  NotificationTemplate = 'notification_template',
  NotificationTemplateCategory = 'notification_template_category',
  PhishDomain = 'phish_domain',
  PhishalertLanguage = 'phishalert_language',
  PhishalertSetting = 'phishalert_setting',
  PhishingCampaign = 'phishing_campaign',
  PhishingTemplate = 'phishing_template',
  PhishingTemplateCategory = 'phishing_template_category',
  PhysicalQrCampaign = 'physical_qr_campaign',
  PhysicalQrCampaignLocation = 'physical_qr_campaign_location',
  PhysicalQrTemplate = 'physical_qr_template',
  Policy = 'policy',
  PolicyAsset = 'policy_asset',
  ReportCategory = 'report_category',
  SavedReport = 'saved_report',
  ScheduledReport = 'scheduled_report',
  SecurityRole = 'security_role',
  SmartHost = 'smart_host',
  StorePurchase = 'store_purchase',
  TemplateTopic = 'template_topic',
  TrainingCampaign = 'training_campaign',
  UsbCampaign = 'usb_campaign',
  User = 'user',
}

export type AuditLogResource =
  | Account
  | ApiToken
  | AsapProgram
  | AsapProgramTask
  | CallbackPhishingGreetingTemplate
  | CallbackPhishingResponseTemplate
  | CallbackPhishingTemplate
  | Campaign
  | CcmCourse
  | CcmTranslatedCourseAsset
  | ContentTheme
  | DkimConfig
  | EnvironmentQuestionnaire
  | ExecReportsExecReportsDashboard
  | Group
  | IpRange
  | LandingPage
  | LandingPageCategory
  | Notification
  | NotificationTemplate
  | NotificationTemplateCategory
  | PhishDomain
  | PhishalertLanguage
  | PhishalertSetting
  | PhishingCampaign
  | PhishingTemplate
  | PhishingTemplateCategory
  | PhysicalQrCampaign
  | PhysicalQrCampaignLocation
  | PhysicalQrTemplate
  | Policy
  | PolicyAsset
  | ReportCategory
  | SavedReport
  | ScheduledReport
  | SecurityRole
  | SmartHost
  | StorePurchase
  | TemplateTopic
  | TrainingCampaign
  | UsbCampaign
  | User

export type AuditLogS3Data = {
  __typename?: 'AuditLogS3Data'
  /** The data associated with the S3 audit log */
  data?: Maybe<Scalars['String']>
  /** Indicates if there were any errors in the S3 audit log */
  errors?: Maybe<Scalars['Boolean']>
}

export enum AuditLogSearchPeriods {
  Custom = 'CUSTOM',
  Last_7Days = 'LAST_7_DAYS',
  Last_30Days = 'LAST_30_DAYS',
  Last_90Days = 'LAST_90_DAYS',
}

export type AuditLogs = {
  __typename?: 'AuditLogs'
  /** A CSV format of the audit logs */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type AuditLogsCursor = {
  __typename?: 'AuditLogsCursor'
  nodes: Array<AuditLog>
  pagination: CursorPagination
}

export type AuditLogsDateRange = {
  endDate: Scalars['ISO8601Date']
  startDate: Scalars['ISO8601Date']
}

export type AuditLogsPaginationAttributes = {
  per: Scalars['Int']
  searchAfter?: Maybe<Scalars['ISO8601DateTime']>
}

/** Audit metadata for an event */
export type AuditMetadata = {
  __typename?: 'AuditMetadata'
  /** Detailed information regarding the audit */
  auditDetails?: Maybe<AuditDetails>
  /** The name of the campaign related to the audit */
  campaignName?: Maybe<Scalars['String']>
  /** The name of the company related to the audit */
  companyName?: Maybe<Scalars['String']>
  /** The email address of the user involved in the audit */
  userEmail?: Maybe<Scalars['String']>
  /** The type of user involved in the audit */
  userType?: Maybe<Scalars['String']>
}

/** A type of webhook auth */
export type AuthMethod = ApiKey | Basic | Bearer

export enum AutoPrependEmailBannerTypes {
  Always = 'ALWAYS',
  DmiOnly = 'DMI_ONLY',
  Manual = 'MANUAL',
}

export enum AutoPrependSubjectPrefixTypes {
  Always = 'ALWAYS',
  DmiOnly = 'DMI_ONLY',
  Manual = 'MANUAL',
}

/** A badge for gamification */
export type Badge = {
  __typename?: 'Badge'
  /** The timestamp at which the badge was acknowledged by the user */
  acknowledgedAt?: Maybe<Scalars['ISO8601DateTime']>
  description: Scalars['String']
  /** The timestamp at which the badge was earned by the user */
  earnedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The icon representing the badge */
  icon: Scalars['String']
  /** The unique identifier of the badge */
  id?: Maybe<Scalars['Int']>
  /** The title of the badge */
  title: Scalars['String']
  /** The type of the badge */
  type?: Maybe<Scalars['String']>
  /** The timestamp at which the badge was viewed by the user */
  viewedAt?: Maybe<Scalars['ISO8601DateTime']>
}

export enum Badges {
  Cyberherobadge = 'CYBERHEROBADGE',
  Eagerbeaverbadge = 'EAGERBEAVERBADGE',
  Eagleeyebadge = 'EAGLEEYEBADGE',
  Earlybirdbadge = 'EARLYBIRDBADGE',
  Graduatebadge = 'GRADUATEBADGE',
  Hattrickbadge = 'HATTRICKBADGE',
  Hyperdriveherobadge = 'HYPERDRIVEHEROBADGE',
  Lightningfastbadge = 'LIGHTNINGFASTBADGE',
  Linguistbadge = 'LINGUISTBADGE',
  Newrecruitbadge = 'NEWRECRUITBADGE',
  Nightowlbadge = 'NIGHTOWLBADGE',
  Onthegobadge = 'ONTHEGOBADGE',
  Pioneerbadge = 'PIONEERBADGE',
  Policyprofessionalbadge = 'POLICYPROFESSIONALBADGE',
}

/** Autogenerated return type of BadgesAcknowledge. */
export type BadgesAcknowledgePayload = {
  __typename?: 'BadgesAcknowledgePayload'
  errors?: Maybe<Array<Error>>
  nodes?: Maybe<Array<Badge>>
}

/** Autogenerated return type of BadgesEarn. */
export type BadgesEarnPayload = {
  __typename?: 'BadgesEarnPayload'
  errors?: Maybe<Array<Error>>
  nodes?: Maybe<Array<Badge>>
}

/** Autogenerated return type of BadgesView. */
export type BadgesViewPayload = {
  __typename?: 'BadgesViewPayload'
  errors?: Maybe<Array<Error>>
  nodes?: Maybe<Array<Badge>>
}

/** A webhook basic authentication configuration */
export type Basic = {
  __typename?: 'Basic'
  /** The password required for authentication */
  password: Scalars['String']
  /** The username required for authentication */
  username: Scalars['String']
}

/** A Bearer authentication configuration */
export type Bearer = {
  __typename?: 'Bearer'
  /** The authorization token */
  token: Scalars['String']
}

export enum BenchmarkCompanySize {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL',
}

export enum BenchmarkTimeframes {
  Baseline = 'BASELINE',
  Days90 = 'DAYS90',
  Year = 'YEAR',
}

export enum Booleans {
  Must = 'MUST',
  MustNot = 'MUST_NOT',
}

/** Breach metadata for an event */
export type BounceMetadata = EventMetadataInterface & {
  __typename?: 'BounceMetadata'
  /** The code representing the reason for the bounce */
  bounceCode?: Maybe<Scalars['String']>
  /** A description of the reason for the bounce */
  bounceReason?: Maybe<Scalars['String']>
  templateId?: Maybe<Scalars['Int']>
  templateName?: Maybe<Scalars['String']>
  templateType?: Maybe<Scalars['String']>
  templateUuid?: Maybe<Scalars['String']>
}

/** Breach event details */
export type BreachDetails = {
  __typename?: 'BreachDetails'
  description?: Maybe<Scalars['String']>
  /** The email targeted in the breach */
  targetEmail?: Maybe<Scalars['String']>
  /** The title of the breach */
  title?: Maybe<Scalars['String']>
  /** The type or category of the breach */
  type?: Maybe<Scalars['String']>
}

export type BreachEvent = Identifier & {
  __typename?: 'BreachEvent'
  /** Boolean value for the breach event criterion */
  boolean: Booleans
  /** Comparison operator for evaluating the breach event */
  comparison: EventComparisons
  /** The count of breach events for the criterion */
  count: Scalars['Int']
  /** Type of breach event */
  event: BreachEvents
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** Timeframe context for the breach event */
  timeframe: Timeframe
}

export type BreachEventArgument = {
  bool: Booleans
  count: Scalars['Int']
  countType: EventComparisons
  event: BreachEvents
  timeframe: TimeframeArgument
}

export enum BreachEvents {
  Breached = 'BREACHED',
}

/** Breach metadata for an event */
export type BreachMetadata = {
  __typename?: 'BreachMetadata'
  /** Detailed information about the breach */
  breach?: Maybe<BreachDetails>
}

/** Browser metadata for an event */
export type BrowserMetadata = EventMetadataInterface & {
  __typename?: 'BrowserMetadata'
  /** The name of the browser used */
  browser?: Maybe<Scalars['String']>
  /** The version of the browser used */
  browserVersion?: Maybe<Scalars['String']>
  templateId?: Maybe<Scalars['Int']>
  templateName?: Maybe<Scalars['String']>
  templateType?: Maybe<Scalars['String']>
  templateUuid?: Maybe<Scalars['String']>
}

export type BulkVendorStatusData = {
  __typename?: 'BulkVendorStatusData'
  /** A list of errors encountered during the process */
  errors?: Maybe<Array<Error>>
  /** A list of vendors with their registration status */
  vendors?: Maybe<Array<VendorRegistrationStatus>>
}

export type ByCampaign = {
  __typename?: 'ByCampaign'
  /** CSV format of the training surveys data */
  csv: Scalars['String']
  /** Data for the training surveys grouped by campaign */
  data: Array<ByCampaignData>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type ByCampaignData = {
  __typename?: 'ByCampaignData'
  /** The name of the course associated with the survey */
  courseName?: Maybe<Scalars['String']>
  /** The length of the training */
  length: Scalars['Float']
  /** The name of the survey */
  name?: Maybe<Scalars['String']>
  /** The style score of the training from the survey */
  style: Scalars['Float']
  /** The number of surveys completed for the campaign */
  surveyCount: Scalars['Int']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** The unique identifier for the training campaign */
  trainingCampaignId?: Maybe<Scalars['Int']>
  /** The value score of the training from the survey */
  value: Scalars['Float']
}

export type ByCourse = {
  __typename?: 'ByCourse'
  /** A CSV representation of the training survey report by course */
  csv: Scalars['String']
  /** The data for training surveys grouped by course */
  data: Array<ByCourseData>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type ByCourseData = {
  __typename?: 'ByCourseData'
  /** The name of the course */
  courseName?: Maybe<Scalars['String']>
  /** The unique identifier of the course data */
  id?: Maybe<Scalars['Int']>
  /** The length of the course */
  length: Scalars['Float']
  /** The style score of the course from surveys */
  style: Scalars['Float']
  /** The number of surveys collected for the course */
  surveyCount: Scalars['Int']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** The value score of the course from surveys */
  value: Scalars['Float']
}

export type CallbackPhishingAudioTemplate =
  | CallbackPhishingGreetingTemplate
  | CallbackPhishingResponseTemplate

/** Common Fields for callback phishing audio templates */
export type CallbackPhishingAudioTemplateInterface = {
  auditLogDisplay?: Maybe<Scalars['String']>
  /** The messages of the audio template */
  callbackPhishingAudioTemplateMessages: Array<CallbackPhishingAudioTemplateMessage>
  /** The language code of the audio template */
  languageCode: Scalars['String']
  /** The persona of the audio template */
  persona: Scalars['String']
  /** The store item UUID of the audio template */
  storeItemUuid?: Maybe<Scalars['String']>
  /** The template translation UUID of the audio template */
  templateTranslationUuid?: Maybe<Scalars['String']>
  /** The type of audio template */
  templateType: CallbackPhishingAudioTemplateTypes
  /** The title of the audio template */
  title: Scalars['String']
  /** The topics of the audio template */
  topics?: Maybe<Array<TemplateTopic>>
}

/** A callback phishing audio template message */
export type CallbackPhishingAudioTemplateMessage = Identifier &
  Timestamps & {
    __typename?: 'CallbackPhishingAudioTemplateMessage'
    /** The URL to the audio file */
    attachmentUrl?: Maybe<Scalars['String']>
    audioStepCode: Scalars['String']
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The original file name of the audio file */
    originalFileName?: Maybe<Scalars['String']>
    /** The stage of the callback phishing audio template message */
    stage: Scalars['String']
    /** The text that will be converted into speech for the callback phishing message */
    textToSpeech: Scalars['String']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type CallbackPhishingAudioTemplateMessageAttributes = {
  /** The attachment for the audio message */
  attachment?: Maybe<Scalars['String']>
  /** The audio step code for the audio message */
  audioStepCode?: Maybe<Scalars['String']>
  /** The ID of the callback phishing audio message */
  id?: Maybe<Scalars['Int']>
  /** The text-to-speech message for the audio message */
  textToSpeech?: Maybe<Scalars['String']>
}

/** Autogenerated return type of CallbackPhishingAudioTemplateMessagesDeleteAudioFile. */
export type CallbackPhishingAudioTemplateMessagesDeleteAudioFilePayload = {
  __typename?: 'CallbackPhishingAudioTemplateMessagesDeleteAudioFilePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The result of the mutation */
  node?: Maybe<Scalars['Boolean']>
}

export enum CallbackPhishingAudioTemplateSortFields {
  SecondaryCodeLength = 'SECONDARY_CODE_LENGTH',
  Title = 'TITLE',
  TopicName = 'TOPIC_NAME',
  UpdatedAt = 'UPDATED_AT',
}

export enum CallbackPhishingAudioTemplateTypes {
  System = 'SYSTEM',
  User = 'USER',
}

export enum CallbackPhishingDedicatedOptions {
  All = 'ALL',
  Dedicated = 'DEDICATED',
  NotDedicated = 'NOT_DEDICATED',
}

export enum CallbackPhishingDeleteOptions {
  All = 'ALL',
  Deleteable = 'DELETEABLE',
  DoNotDelete = 'DO_NOT_DELETE',
}

/** A callback phishing greeting message audio template */
export type CallbackPhishingGreetingTemplate =
  CallbackPhishingAudioTemplateInterface &
    Identifier &
    Timestamps & {
      __typename?: 'CallbackPhishingGreetingTemplate'
      auditLogDisplay?: Maybe<Scalars['String']>
      /** The messages of the audio template */
      callbackPhishingAudioTemplateMessages: Array<CallbackPhishingAudioTemplateMessage>
      /** The timestamp when the record was created */
      createdAt: Scalars['ISO8601DateTime']
      /** Unique identifier for the object */
      id: Scalars['Int']
      /** The language code of the audio template */
      languageCode: Scalars['String']
      /** The persona of the audio template */
      persona: Scalars['String']
      /** The store item UUID of the audio template */
      storeItemUuid?: Maybe<Scalars['String']>
      /** The template translation UUID of the audio template */
      templateTranslationUuid?: Maybe<Scalars['String']>
      /** The type of audio template */
      templateType: CallbackPhishingAudioTemplateTypes
      /** The title of the audio template */
      title: Scalars['String']
      /** The topics of the audio template */
      topics?: Maybe<Array<TemplateTopic>>
      /** The timestamp when the record was last updated */
      updatedAt: Scalars['ISO8601DateTime']
    }

export type CallbackPhishingGreetingTemplateAttributes = {
  /** The audio messages for the callback phishing greeting template */
  callbackPhishingAudioTemplateMessages?: Maybe<
    Array<CallbackPhishingAudioTemplateMessageAttributes>
  >
  /** The language code of the callback phishing greeting template */
  languageCode?: Maybe<Scalars['String']>
  /** The persona of the callback phishing greeting template */
  persona?: Maybe<Scalars['String']>
  /** The title of the callback phishing greeting template */
  title?: Maybe<Scalars['String']>
}

export type CallbackPhishingGreetingTemplateLanguagesCursor = {
  __typename?: 'CallbackPhishingGreetingTemplateLanguagesCursor'
  /** The paginated nodes */
  nodes: Array<Language>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of CallbackPhishingGreetingTemplatesAddTopics. */
export type CallbackPhishingGreetingTemplatesAddTopicsPayload = {
  __typename?: 'CallbackPhishingGreetingTemplatesAddTopicsPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The result of the mutation */
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of CallbackPhishingGreetingTemplatesCopy. */
export type CallbackPhishingGreetingTemplatesCopyPayload = {
  __typename?: 'CallbackPhishingGreetingTemplatesCopyPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The copied greeting templates */
  nodes?: Maybe<Array<CallbackPhishingGreetingTemplate>>
}

/** Autogenerated return type of CallbackPhishingGreetingTemplatesCreate. */
export type CallbackPhishingGreetingTemplatesCreatePayload = {
  __typename?: 'CallbackPhishingGreetingTemplatesCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created callback phishing greeting template */
  node?: Maybe<CallbackPhishingGreetingTemplate>
}

export type CallbackPhishingGreetingTemplatesCursor = {
  __typename?: 'CallbackPhishingGreetingTemplatesCursor'
  /** The paginated nodes */
  nodes: Array<CallbackPhishingGreetingTemplate>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of CallbackPhishingGreetingTemplatesDelete. */
export type CallbackPhishingGreetingTemplatesDeletePayload = {
  __typename?: 'CallbackPhishingGreetingTemplatesDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The result of the mutation */
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of CallbackPhishingGreetingTemplatesRemoveTopics. */
export type CallbackPhishingGreetingTemplatesRemoveTopicsPayload = {
  __typename?: 'CallbackPhishingGreetingTemplatesRemoveTopicsPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The result of the mutation */
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of CallbackPhishingGreetingTemplatesUpdate. */
export type CallbackPhishingGreetingTemplatesUpdatePayload = {
  __typename?: 'CallbackPhishingGreetingTemplatesUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated callback phishing greeting template */
  node?: Maybe<CallbackPhishingGreetingTemplate>
}

/** A phone number for callback phishing */
export type CallbackPhishingPhoneNumber = Identifier &
  Timestamps & {
    __typename?: 'CallbackPhishingPhoneNumber'
    /** The area code of the callback phishing phone number */
    areaCode?: Maybe<Scalars['String']>
    awsArn?: Maybe<Scalars['String']>
    /** The country code of the callback phishing phone number */
    countryCode?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    dedicated?: Maybe<Scalars['Boolean']>
    doNotDelete: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    lastUsedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The actual callback phishing phone number */
    phoneNumber?: Maybe<Scalars['String']>
    recipientCount?: Maybe<Scalars['Int']>
    /** The region name of the callback phishing phone number */
    regionName: Scalars['String']
    status?: Maybe<CallbackPhishingPhoneNumberStatuses>
    /** Indicates if the callback phishing phone number is toll-free */
    tollFree?: Maybe<Scalars['Boolean']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export enum CallbackPhishingPhoneNumberCountryCodes {
  Ar = 'AR',
  Au = 'AU',
  Be = 'BE',
  Br = 'BR',
  Ca = 'CA',
  Ch = 'CH',
  Cl = 'CL',
  Co = 'CO',
  Cz = 'CZ',
  De = 'DE',
  Es = 'ES',
  Fr = 'FR',
  Gb = 'GB',
  Ie = 'IE',
  Mx = 'MX',
  Nl = 'NL',
  Se = 'SE',
  Us = 'US',
}

export enum CallbackPhishingPhoneNumberRegions {
  DidAr = 'DID_AR',
  DidAu = 'DID_AU',
  DidBe = 'DID_BE',
  DidBr = 'DID_BR',
  DidCa = 'DID_CA',
  DidCl = 'DID_CL',
  DidCo = 'DID_CO',
  DidCz = 'DID_CZ',
  DidDe = 'DID_DE',
  DidEs = 'DID_ES',
  DidFr = 'DID_FR',
  DidGb = 'DID_GB',
  DidMx = 'DID_MX',
  DidNl = 'DID_NL',
  DidUs = 'DID_US',
  TfCh = 'TF_CH',
  TfEs = 'TF_ES',
  TfIe = 'TF_IE',
  TfSe = 'TF_SE',
  TfUs = 'TF_US',
}

export enum CallbackPhishingPhoneNumberSortFields {
  AreaCode = 'AREA_CODE',
  AwsArn = 'AWS_ARN',
  CountryCode = 'COUNTRY_CODE',
  CreatedAt = 'CREATED_AT',
  Dedicated = 'DEDICATED',
  DoNotDelete = 'DO_NOT_DELETE',
  LastUsedAt = 'LAST_USED_AT',
  PhoneNumber = 'PHONE_NUMBER',
  RecipientCount = 'RECIPIENT_COUNT',
  Status = 'STATUS',
  TollFree = 'TOLL_FREE',
}

export enum CallbackPhishingPhoneNumberStatuses {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Available = 'AVAILABLE',
  Cooldown = 'COOLDOWN',
  Full = 'FULL',
}

export type CallbackPhishingPhoneNumbersCursor = {
  __typename?: 'CallbackPhishingPhoneNumbersCursor'
  /** The paginated nodes */
  nodes: Array<CallbackPhishingPhoneNumber>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of CallbackPhishingPhoneNumbersLinkOrphan. */
export type CallbackPhishingPhoneNumbersLinkOrphanPayload = {
  __typename?: 'CallbackPhishingPhoneNumbersLinkOrphanPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<CallbackPhishingPhoneNumber>
}

/** Autogenerated return type of CallbackPhishingPhoneNumbersRelease. */
export type CallbackPhishingPhoneNumbersReleasePayload = {
  __typename?: 'CallbackPhishingPhoneNumbersReleasePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<CallbackPhishingPhoneNumber>
}

/** Autogenerated return type of CallbackPhishingPhoneNumbersToggleDeletion. */
export type CallbackPhishingPhoneNumbersToggleDeletionPayload = {
  __typename?: 'CallbackPhishingPhoneNumbersToggleDeletionPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<CallbackPhishingPhoneNumber>
}

/** A callback phishing response message audio template */
export type CallbackPhishingResponseTemplate =
  CallbackPhishingAudioTemplateInterface &
    Identifier &
    Timestamps & {
      __typename?: 'CallbackPhishingResponseTemplate'
      auditLogDisplay?: Maybe<Scalars['String']>
      /** The messages of the audio template */
      callbackPhishingAudioTemplateMessages: Array<CallbackPhishingAudioTemplateMessage>
      /** The timestamp when the record was created */
      createdAt: Scalars['ISO8601DateTime']
      /** Unique identifier for the object */
      id: Scalars['Int']
      /** The language code of the audio template */
      languageCode: Scalars['String']
      /** The persona of the audio template */
      persona: Scalars['String']
      /** The length of the secondary callback code */
      secondaryCodeLength: Scalars['Int']
      /** The store item UUID of the audio template */
      storeItemUuid?: Maybe<Scalars['String']>
      /** The template translation UUID of the audio template */
      templateTranslationUuid?: Maybe<Scalars['String']>
      /** The type of audio template */
      templateType: CallbackPhishingAudioTemplateTypes
      /** The title of the audio template */
      title: Scalars['String']
      /** The topics of the audio template */
      topics?: Maybe<Array<TemplateTopic>>
      /** The timestamp when the record was last updated */
      updatedAt: Scalars['ISO8601DateTime']
    }

export type CallbackPhishingResponseTemplateAttributes = {
  callbackPhishingAudioTemplateMessages?: Maybe<
    Array<CallbackPhishingAudioTemplateMessageAttributes>
  >
  languageCode?: Maybe<Scalars['String']>
  persona?: Maybe<Scalars['String']>
  secondaryCodeLength?: Maybe<Scalars['Int']>
  title?: Maybe<Scalars['String']>
}

export type CallbackPhishingResponseTemplateLanguagesCursor = {
  __typename?: 'CallbackPhishingResponseTemplateLanguagesCursor'
  /** The paginated nodes */
  nodes: Array<Language>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of CallbackPhishingResponseTemplatesAddTopics. */
export type CallbackPhishingResponseTemplatesAddTopicsPayload = {
  __typename?: 'CallbackPhishingResponseTemplatesAddTopicsPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The result of the mutation */
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of CallbackPhishingResponseTemplatesCopy. */
export type CallbackPhishingResponseTemplatesCopyPayload = {
  __typename?: 'CallbackPhishingResponseTemplatesCopyPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The copied callback phishing response templates */
  nodes?: Maybe<Array<CallbackPhishingResponseTemplate>>
}

/** Autogenerated return type of CallbackPhishingResponseTemplatesCreate. */
export type CallbackPhishingResponseTemplatesCreatePayload = {
  __typename?: 'CallbackPhishingResponseTemplatesCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created callback phishing response template */
  node?: Maybe<CallbackPhishingResponseTemplate>
}

export type CallbackPhishingResponseTemplatesCursor = {
  __typename?: 'CallbackPhishingResponseTemplatesCursor'
  /** The paginated nodes */
  nodes: Array<CallbackPhishingResponseTemplate>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of CallbackPhishingResponseTemplatesDelete. */
export type CallbackPhishingResponseTemplatesDeletePayload = {
  __typename?: 'CallbackPhishingResponseTemplatesDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The result of the mutation */
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of CallbackPhishingResponseTemplatesRemoveTopics. */
export type CallbackPhishingResponseTemplatesRemoveTopicsPayload = {
  __typename?: 'CallbackPhishingResponseTemplatesRemoveTopicsPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The result of the mutation */
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of CallbackPhishingResponseTemplatesUpdate. */
export type CallbackPhishingResponseTemplatesUpdatePayload = {
  __typename?: 'CallbackPhishingResponseTemplatesUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated callback phishing response template */
  node?: Maybe<CallbackPhishingResponseTemplate>
}

/** Email templates for callback phishing */
export type CallbackPhishingTemplate = Identifier &
  Timestamps & {
    __typename?: 'CallbackPhishingTemplate'
    /** Indicates if the template is archived */
    archived: Scalars['Boolean']
    auditLogDisplay?: Maybe<Scalars['String']>
    auditLogName?: Maybe<Scalars['String']>
    /** The greeting template used in callback phishing */
    callbackPhishingGreetingTemplate?: Maybe<CallbackPhishingGreetingTemplate>
    callbackPhishingGreetingTemplateTranslationUuid?: Maybe<Scalars['String']>
    callbackPhishingGreetingTemplateUuid?: Maybe<Scalars['String']>
    /** The response template used in callback phishing */
    callbackPhishingResponseTemplate?: Maybe<CallbackPhishingResponseTemplate>
    callbackPhishingResponseTemplateTranslationUuid?: Maybe<Scalars['String']>
    /** The HTML content of the template */
    contentHtml?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The email address the email template is being sent from */
    from: Scalars['String']
    /** The display name of the sender in the template */
    fromDisplayName?: Maybe<Scalars['String']>
    /** The email address with the domain the email template is being sent from */
    fromWithDomain: Scalars['String']
    /** Indicates if the template is hidden */
    hidden: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The language code of the template */
    languageCode?: Maybe<Scalars['String']>
    /** The time when the template was marked as updated */
    markedAsUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The name of the template */
    name?: Maybe<Scalars['String']>
    /** The difficulty rating of the email template */
    rating?: Maybe<EmailTemplateDifficultyRatings>
    /** The subject of the email template */
    subject: Scalars['String']
    /** The sanitized subject of the email template, excluding potentially malicious content */
    subjectSanitized: Scalars['String']
    /** UUID of the template translation */
    templateTranslationUuid: Scalars['String']
    /** List of topics associated with the template */
    topics?: Maybe<Array<TemplateTopic>>
    /** Translations of the callback phishing template */
    translations?: Maybe<Array<PhishingTemplateTranslation>>
    /** The type of callback phishing template */
    type: CallbackPhishingTemplateTypes
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type CallbackPhishingTemplateAttributes = {
  /** The UUID of the callback phishing greeting template translation */
  callbackPhishingGreetingTemplateTranslationUuid?: Maybe<Scalars['String']>
  /** The UUID of the callback phishing response template translation */
  callbackPhishingResponseTemplateTranslationUuid?: Maybe<Scalars['String']>
  /** The HTML content of the callback phishing email */
  contentHtml: Scalars['String']
  /** The email address that the callback phishing email will be sent from */
  from: Scalars['String']
  /** The display name of the email address the callback phishing email will be sent from */
  fromDisplayName?: Maybe<Scalars['String']>
  /** The language code of the callback phishing email */
  languageCode?: Maybe<Scalars['String']>
  /** The name of the callback phishing template */
  name?: Maybe<Scalars['String']>
  /** The difficulty rating of the callback phishing email */
  rating?: Maybe<EmailTemplateDifficultyRatings>
  /** The subject of the callback phishing email */
  subject: Scalars['String']
}

export enum CallbackPhishingTemplateTypes {
  CallbackPhishing = 'CALLBACK_PHISHING',
  CallbackPhishingSystem = 'CALLBACK_PHISHING_SYSTEM',
}

/** Autogenerated return type of CallbackPhishingTemplatesCopy. */
export type CallbackPhishingTemplatesCopyPayload = {
  __typename?: 'CallbackPhishingTemplatesCopyPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The copied callback phishing templates */
  nodes?: Maybe<Array<CallbackPhishingTemplate>>
}

/** Autogenerated return type of CallbackPhishingTemplatesCreate. */
export type CallbackPhishingTemplatesCreatePayload = {
  __typename?: 'CallbackPhishingTemplatesCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created callback phishing template */
  node?: Maybe<CallbackPhishingTemplate>
}

export type CallbackPhishingTemplatesCursor = {
  __typename?: 'CallbackPhishingTemplatesCursor'
  /** The paginated nodes */
  nodes: Array<CallbackPhishingTemplate>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of CallbackPhishingTemplatesUpdate. */
export type CallbackPhishingTemplatesUpdatePayload = {
  __typename?: 'CallbackPhishingTemplatesUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated callback phishing template */
  node?: Maybe<CallbackPhishingTemplate>
}

/** Autogenerated return type of CallbackPhishingTestEmailsCreate. */
export type CallbackPhishingTestEmailsCreatePayload = {
  __typename?: 'CallbackPhishingTestEmailsCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Int']>
}

export enum CallbackPhishingTollFreeOptions {
  All = 'ALL',
  Did = 'DID',
  TollFree = 'TOLL_FREE',
}

/** A message campaign */
export type Campaign = Identifier &
  Timestamps & {
    __typename?: 'Campaign'
    /** A flag indicating if the campaign is active or not */
    active: Scalars['Boolean']
    /** A flag indicating if the campaign targets all users */
    allUsers: Scalars['Boolean']
    /** Display information for audit logs associated with the campaign */
    auditLogDisplay?: Maybe<Scalars['String']>
    /** The user who authored the campaign */
    authoredBy?: Maybe<User>
    /** Topics associated with the campaign */
    campaignTopics?: Maybe<Array<MessageTopic>>
    /** The type of campaign */
    campaignType?: Maybe<MessagesCampaignTypes>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Custom body content for the campaign messages */
    customBody?: Maybe<Scalars['String']>
    /** Custom language for the campaign messages */
    customLanguage?: Maybe<Scalars['String']>
    /** An identifier for custom runs of the campaign */
    customRunId?: Maybe<Scalars['Int']>
    /** Custom subject line for the campaign messages */
    customSubject?: Maybe<Scalars['String']>
    /** The frequency of the campaign */
    frequency?: Maybe<MessagesCampaignFrequencyTypes>
    /** The email address from which the campaign messages are sent */
    fromEmail?: Maybe<Scalars['String']>
    /** The name associated with the from email address for the campaign messages */
    fromName?: Maybe<Scalars['String']>
    /** User groups associated with the campaign */
    groups?: Maybe<Array<Group>>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Contents of messages within the campaign */
    messageContents?: Maybe<Array<MessageContent>>
    /** Runs associated with the campaign */
    messagesRuns?: Maybe<Array<Run>>
    /** The name of the campaign */
    name?: Maybe<Scalars['String']>
    /** The date and time for the next run of the campaign */
    nextRun?: Maybe<Scalars['ISO8601DateTime']>
    /** A flag indicating if the campaign is recurring */
    recurring: Scalars['Boolean']
    /** The day of the week on which the campaign recurs */
    recurringDayOfWeek?: Maybe<Scalars['Int']>
    /** A flag indicating if emails are sent as part of the campaign */
    sendEmail: Scalars['Boolean']
    /** The current status of the campaign */
    status?: Maybe<MessagesCampaignStatuses>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** A flag indicating if the company logo is used in the campaign */
    useCompanyLogo: Scalars['Boolean']
  }

export type CampaignCount = {
  __typename?: 'CampaignCount'
  /** The count of reports for the campaign */
  count?: Maybe<Scalars['Int']>
  /** The name of the campaign */
  name?: Maybe<Scalars['String']>
}

export type CampaignDataCursor = {
  __typename?: 'CampaignDataCursor'
  /** The paginated nodes */
  nodes: Array<SecurityCoachCampaignData>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Campaign delivery method type */
export type CampaignDeliveryMethod = {
  __typename?: 'CampaignDeliveryMethod'
  /** The ID for the deliverable associated with the campaign delivery method */
  deliverableId: Scalars['Int']
  /** The type of deliverable associated with the campaign delivery method */
  deliverableType: Scalars['String']
  /** The method of delivery used for the campaign */
  deliveryMethod: CampaignDeliveryMethodTypes
  /** The domain associated with the campaign delivery method */
  domain: Scalars['String']
}

/** CampaignDeliveryMethodTypes */
export enum CampaignDeliveryMethodTypes {
  Dmi = 'DMI',
  Psm = 'PSM',
}

export type CampaignOverview = {
  __typename?: 'CampaignOverview'
  /** The total number of coaching sessions delivered */
  coachingDelivered?: Maybe<Scalars['Int']>
  /** Data related to detections that triggered the coaching */
  detectionData?: Maybe<Array<DetectionData>>
  /** The number of detections that were held for review TODO: DOCS CHANGE THIS!!! */
  heldDetections?: Maybe<Scalars['Int']>
  /** The number of test detections that were held for review TODO: DOCS CHANGE THIS!!! */
  heldTestDetections?: Maybe<Scalars['Int']>
  /** The number of users who received coaching for the first time */
  newUsersCoached?: Maybe<Scalars['Int']>
  /** The number of users who have been coached multiple times */
  repeatedOffenders?: Maybe<Scalars['Int']>
  /** A list of the riskiest users based on their behaviors and actions */
  riskiestUsers?: Maybe<Array<TopRiskyUsers>>
  /** Summary data for rules indicating missed opportunities for coaching */
  ruleSummaryData?: Maybe<Array<MissedOpportunity>>
  /** The total number of users who received coaching */
  totalUsersCoached?: Maybe<Scalars['Int']>
}

export enum CampaignRecipientStatuses {
  AttachmentOpen = 'ATTACHMENT_OPEN',
  Bounced = 'BOUNCED',
  CallbackDataEntered = 'CALLBACK_DATA_ENTERED',
  Called = 'CALLED',
  Clicked = 'CLICKED',
  DataEntered = 'DATA_ENTERED',
  Delivered = 'DELIVERED',
  Dropped = 'DROPPED',
  MacroEnabled = 'MACRO_ENABLED',
  Opened = 'OPENED',
  Processed = 'PROCESSED',
  QrCodeScanned = 'QR_CODE_SCANNED',
  Replied = 'REPLIED',
  Reported = 'REPORTED',
}

export enum CampaignStatuses {
  Active = 'active',
  Inactive = 'inactive',
  TestMode = 'test_mode',
}

export type CampaignsCursor = {
  __typename?: 'CampaignsCursor'
  /** The paginated nodes */
  nodes: Array<Campaign>
  /** Information about the current paginated set */
  pagination: Pagination
}

export type CcmCourse = Identifier & {
  __typename?: 'CcmCourse'
  /** Display information for the audit log associated with this course */
  auditLogDisplay?: Maybe<Scalars['String']>
  /** Unique identifier for the object */
  id: Scalars['Int']
}

/** CCM store item */
export type CcmItem = StoreItemInterface & {
  __typename?: 'CcmItem'
  artwork: Artwork
  description?: Maybe<Scalars['String']>
  expectedDurationInMinutes?: Maybe<Scalars['Int']>
  id: Scalars['Int']
  /** Indicates if the CCM item is compatible with the mobile app */
  mobileAppCompatible: Scalars['Boolean']
  publishedAt?: Maybe<Scalars['ISO8601DateTime']>
  title: Scalars['String']
  translations: Array<Translation>
  /** The type of the CCM item */
  type: Scalars['String']
  uuid: Scalars['String']
}

export type CcmTranslatedCourseAsset = Identifier & {
  __typename?: 'CcmTranslatedCourseAsset'
  /** Display representation of the audit log associated with the course asset */
  auditLogDisplay?: Maybe<Scalars['String']>
  /** Unique identifier for the object */
  id: Scalars['Int']
}

export type CdrmsBucket = {
  __typename?: 'CdrmsBucket'
  displayName?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type CdrmsCategories = {
  __typename?: 'CdrmsCategories'
  /** The total number of CDRMS categories */
  count?: Maybe<Scalars['Int']>
  /** The page number for the next page of results */
  next?: Maybe<Scalars['String']>
  /** The page number for the previous page of results */
  previous?: Maybe<Scalars['String']>
  /** The list of CDRMS categories */
  results?: Maybe<Array<CdrmsCategory>>
}

export type CdrmsCategory = {
  __typename?: 'CdrmsCategory'
  /** The creation timestamp of the CDRMS category */
  created?: Maybe<Scalars['String']>
  /** The unique identifier of the CDRMS category */
  id?: Maybe<Scalars['String']>
  /** The last modification timestamp of the CDRMS category */
  modified?: Maybe<Scalars['String']>
  /** The name of the CDRMS category */
  name?: Maybe<Scalars['String']>
}

export type CdrmsRule = {
  __typename?: 'CdrmsRule'
  /** Bucket ID */
  bucketId?: Maybe<Scalars['String']>
  /** Category of the CDRMS rule */
  category?: Maybe<CompositeField>
  /** The specific code associated with the rule */
  code?: Maybe<Scalars['String']>
  /** Configuration frequency of the rule */
  configureFrequency?: Maybe<Scalars['Int']>
  /** Creation time of the rule */
  created?: Maybe<Scalars['String']>
  /** Delivery frequency of the rule */
  deliveryFrequency?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  /** Unique identifier of the rule */
  id?: Maybe<Scalars['String']>
  /** Last modified time of the rule */
  modified?: Maybe<Scalars['String']>
  /** Name of the rule */
  name?: Maybe<Scalars['String']>
  /** Risk level associated with the rule */
  riskLevel?: Maybe<Scalars['String']>
  /** Real Time Coaching message */
  rtcCoachingMessage?: Maybe<Scalars['String']>
  /** Reference to the teachable moment rule */
  rule?: Maybe<TeachableMomentRule>
  /** Severity level of the rule */
  severity?: Maybe<Scalars['String']>
  /** Stage of the rule */
  stage?: Maybe<Scalars['String']>
  /** Super bucket ID */
  superBucketId?: Maybe<Scalars['String']>
  /** Name of the technical content reviewer */
  techContentReviewedName?: Maybe<Scalars['String']>
  /** List of topics related to the CDRMS rule */
  topics?: Maybe<Array<CompositeField>>
  /** The vendor code name associated with the rule */
  vendorCodeName?: Maybe<Scalars['String']>
}

export type CdrmsRuleDispute = {
  __typename?: 'CdrmsRuleDispute'
  comment?: Maybe<Scalars['String']>
  created?: Maybe<Scalars['ISO8601DateTime']>
  falsePostiveSystemWorkflow?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  modified?: Maybe<Scalars['ISO8601DateTime']>
  name?: Maybe<Scalars['String']>
  orgId?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  status?: Maybe<RuleDisputeStatus>
  vendorCodeName?: Maybe<Scalars['String']>
  wulData?: Maybe<CdrmsWulData>
}

export type CdrmsRuleDisputes = {
  __typename?: 'CdrmsRuleDisputes'
  count?: Maybe<Scalars['Int']>
  next?: Maybe<Scalars['String']>
  previous?: Maybe<Scalars['String']>
  results?: Maybe<Array<CdrmsRuleDispute>>
}

export type CdrmsRuleDisputesUi = {
  __typename?: 'CdrmsRuleDisputesUi'
  distinctOrgIds?: Maybe<Array<Scalars['String']>>
  distinctRules?: Maybe<Array<CompositeField>>
}

export type CdrmsRules = {
  __typename?: 'CdrmsRules'
  /** The total count of items available */
  count?: Maybe<Scalars['Int']>
  /** The page number for the next page of results */
  next?: Maybe<Scalars['String']>
  /** The page number for the previous page of results */
  previous?: Maybe<Scalars['String']>
  /** A list of CdrmsRule objects */
  results?: Maybe<Array<CdrmsRule>>
}

export type CdrmsTopic = {
  __typename?: 'CdrmsTopic'
  /** The creation date of the CDRMS topic */
  created?: Maybe<Scalars['String']>
  /** The unique identifier for the CDRMS topic */
  id?: Maybe<Scalars['String']>
  /** The last modification date of the CDRMS topic */
  modified?: Maybe<Scalars['String']>
  /** The name of the CDRMS topic */
  name?: Maybe<Scalars['String']>
}

export type CdrmsTopics = {
  __typename?: 'CdrmsTopics'
  /** The total number of CDRMS topics */
  count?: Maybe<Scalars['Int']>
  /** The page number for the next page of results */
  next?: Maybe<Scalars['String']>
  /** The page number for the previous page of results */
  previous?: Maybe<Scalars['String']>
  /** An array of CDRMS topics */
  results?: Maybe<Array<CdrmsTopic>>
}

export type CdrmsVendor = {
  __typename?: 'CdrmsVendor'
  /** The creation date of the vendor record */
  created?: Maybe<Scalars['String']>
  /** The unique ID of the vendor */
  id?: Maybe<Scalars['String']>
  /** The type of log */
  logType?: Maybe<Scalars['String']>
  /** The last modification date of the vendor record */
  modified?: Maybe<Scalars['String']>
  /** The name of the vendor */
  name?: Maybe<Scalars['String']>
  /** The code name of the vendor */
  vendorCodeName?: Maybe<Scalars['String']>
  /** The type of vendor */
  vendorType?: Maybe<Scalars['String']>
}

export type CdrmsVendors = {
  __typename?: 'CdrmsVendors'
  /** The total number of CDRMS vendors available */
  count?: Maybe<Scalars['Int']>
  /** The page number for the next page of results */
  next?: Maybe<Scalars['String']>
  /** The page number for the previous page of results */
  previous?: Maybe<Scalars['String']>
  /** An array of CDRMS vendor objects */
  results?: Maybe<Array<CdrmsVendor>>
}

export type CdrmsWulData = {
  __typename?: 'CdrmsWulData'
  batchId?: Maybe<Scalars['String']>
  saUuids?: Maybe<Array<Scalars['String']>>
  sabUser?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  workflow?: Maybe<Scalars['String']>
}

export type Channel = {
  __typename?: 'Channel'
  /** Display name of the bot associated with the team channel */
  botDisplayName?: Maybe<Scalars['String']>
  /** Indicates if the bot associated with the team channel needs an update */
  botNeedsUpdate?: Maybe<Scalars['Boolean']>
  /** Scope of installation for the team channel */
  installationScope?: Maybe<Scalars['String']>
  /** Indicates if the app is blocked in the team channel */
  isAppBlocked?: Maybe<Scalars['Boolean']>
  /** Timestamp of the last synchronization with the team channel */
  lastSync?: Maybe<Scalars['String']>
  /** Indicates if required permissions are granted for the team channel */
  permissionsGranted?: Maybe<Scalars['Boolean']>
  /** Current synchronization status of the team channel */
  syncStatus?: Maybe<Scalars['String']>
  /** List of teams associated with the team channel */
  teams?: Maybe<Array<Team>>
  /** Total number of users in the team channel */
  totalUserCount?: Maybe<Scalars['Int']>
}

export type ChildrensLibraryAccountsCursor = {
  __typename?: 'ChildrensLibraryAccountsCursor'
  /** The paginated nodes */
  nodes: Array<Account>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** A Clickwrap */
export type Clickwrap = Identifier &
  Timestamps & {
    __typename?: 'Clickwrap'
    /** The account ID associated with the Clickwrap */
    accountId?: Maybe<Scalars['Int']>
    /** The date and time when the Clickwrap was acknowledged */
    acknowledgedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The name of the company associated with the Clickwrap */
    companyName?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The display name associated with the Clickwrap */
    displayName?: Maybe<Scalars['String']>
    /** The domain associated with the Clickwrap */
    domain?: Maybe<Scalars['String']>
    /** The email address associated with the Clickwrap */
    email?: Maybe<Scalars['String']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The job title of the individual who acknowledged the Clickwrap */
    jobTitle?: Maybe<Scalars['String']>
    /** The status of the Clickwrap */
    status?: Maybe<ClickwrapStatuses>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The user ID of the individual who acknowledged the Clickwrap */
    userId?: Maybe<Scalars['Int']>
  }

export enum ClickwrapFormerAdminStatuses {
  All = 'ALL',
  Current = 'CURRENT',
  Former = 'FORMER',
}

export enum ClickwrapSortFields {
  AccountId = 'ACCOUNT_ID',
  AcknowledgedAt = 'ACKNOWLEDGED_AT',
  CompanyName = 'COMPANY_NAME',
  DisplayName = 'DISPLAY_NAME',
  Domain = 'DOMAIN',
  Email = 'EMAIL',
  JobTitle = 'JOB_TITLE',
  Status = 'STATUS',
}

export enum ClickwrapStatuses {
  Accepted = 'ACCEPTED',
  Any = 'ANY',
  Exempt = 'EXEMPT',
  Forced = 'FORCED',
  Initial = 'INITIAL',
  Renewal = 'RENEWAL',
}

export type ClickwrapsCursor = {
  __typename?: 'ClickwrapsCursor'
  /** The paginated nodes */
  nodes: Array<Clickwrap>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of ClickwrapsExempt. */
export type ClickwrapsExemptPayload = {
  __typename?: 'ClickwrapsExemptPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Int']>
}

/** Autogenerated return type of ClickwrapsForce. */
export type ClickwrapsForcePayload = {
  __typename?: 'ClickwrapsForcePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Int']>
}

export type CloneRuleResponse = {
  __typename?: 'CloneRuleResponse'
  /** A list of new teachable moment rules created by the cloning process */
  newTms?: Maybe<Array<TeachableMomentRules>>
  /** A list of updated teachable moment rules after the cloning process */
  updatedTms?: Maybe<Array<TeachableMomentRules>>
}

export type Columns =
  | AggregateColumns
  | GlobalPhishingAggregateColumns
  | GlobalPhishingRawColumns
  | GlobalTrainingAggregateColumns
  | GlobalTrainingRawColumns
  | LearnerPlatformRawColumns
  | RawColumns
  | SecurityCoachDetectionRulesActivityAggregateColumns
  | SecurityCoachDetectionRulesActivityRawColumns

/** Common metadata for an event */
export type CommonMetadata = EventMetadataInterface & {
  __typename?: 'CommonMetadata'
  templateId?: Maybe<Scalars['Int']>
  templateName?: Maybe<Scalars['String']>
  templateType?: Maybe<Scalars['String']>
  templateUuid?: Maybe<Scalars['String']>
}

export enum Comparisons {
  Contains = 'CONTAINS',
  EndsWith = 'ENDS_WITH',
  Equal = 'EQUAL',
  GreaterThan = 'GREATER_THAN',
  LessThan = 'LESS_THAN',
  StartsWith = 'STARTS_WITH',
}

export type CompositeField = {
  __typename?: 'CompositeField'
  /** The unique identifier of the composite field */
  id?: Maybe<Scalars['String']>
  /** The name of the composite field */
  name?: Maybe<Scalars['String']>
}

export type ConfigAttributes = {
  domains: Array<DomainAttributes>
  fieldMappings: Array<FieldMappingAttributes>
}

export type Configuration = {
  __typename?: 'Configuration'
  /** Indicates if the GChat configuration is successfully integrated */
  integrated?: Maybe<Scalars['Boolean']>
  /** The number of users in the GChat configuration */
  users?: Maybe<Scalars['Int']>
}

export type Configurations =
  | ReportsConfigurationsAccountReportCard
  | ReportsConfigurationsActiveRulesReport
  | ReportsConfigurationsAidaCampaignSummaryTable
  | ReportsConfigurationsAssessmentScoreByKnowledgeArea
  | ReportsConfigurationsAssessmentScoreByKnowledgeAreaOverTime
  | ReportsConfigurationsAuditLogs
  | ReportsConfigurationsEmailsSentByGroup
  | ReportsConfigurationsEmailsSentByTemplate
  | ReportsConfigurationsFailureTypeUserCounts
  | ReportsConfigurationsFailureTypesBarChart
  | ReportsConfigurationsFailuresByPhishingTemplate
  | ReportsConfigurationsGlobalPhishingAggregate
  | ReportsConfigurationsGlobalPhishingRaw
  | ReportsConfigurationsGlobalTrainingAggregate
  | ReportsConfigurationsGlobalTrainingRaw
  | ReportsConfigurationsGroupReportCard
  | ReportsConfigurationsIncompleteTraining
  | ReportsConfigurationsInitialPpp
  | ReportsConfigurationsLearnerPlatformRaw
  | ReportsConfigurationsNoTrainingAssigned
  | ReportsConfigurationsOverallPppByAccount
  | ReportsConfigurationsOverallPppByCampaign
  | ReportsConfigurationsOverallPppByGroup
  | ReportsConfigurationsOverallPppByTrainingTime
  | ReportsConfigurationsPabComparison
  | ReportsConfigurationsPabUserActivity
  | ReportsConfigurationsPhishFailurePercentageByCampaigns
  | ReportsConfigurationsPhishFailurePercentageOverall
  | ReportsConfigurationsPhishingAllClickers
  | ReportsConfigurationsPhishingSecurityTests
  | ReportsConfigurationsPppDistribution
  | ReportsConfigurationsRiskScoreByAssessment
  | ReportsConfigurationsRiskScoreByGroup
  | ReportsConfigurationsRiskScoreByLocation
  | ReportsConfigurationsRiskScoreBySurvey
  | ReportsConfigurationsRiskScoreByUser
  | ReportsConfigurationsRiskScoreV2ByGroup
  | ReportsConfigurationsRiskScoreV2ByManager
  | ReportsConfigurationsRiskScoreV2ByUser
  | ReportsConfigurationsSecurityCoachActivityRtcAggregate
  | ReportsConfigurationsSecurityCoachActivityRtcRaw
  | ReportsConfigurationsSecurityCoachDetectionRulesActivityAggregate
  | ReportsConfigurationsSecurityCoachDetectionRulesActivityRaw
  | ReportsConfigurationsSurveyScoreByDimension
  | ReportsConfigurationsSurveyScoreByDimensionOverTime
  | ReportsConfigurationsTrainingCampaignEnrollments
  | ReportsConfigurationsTrainingCampaignSurveys
  | ReportsConfigurationsTrainingComparisonByPpp
  | ReportsConfigurationsTrainingComparisonByRiskScore
  | ReportsConfigurationsTrainingHours
  | ReportsConfigurationsTrainingInteractionsByGroup
  | ReportsConfigurationsTrainingInteractionsByPurchasedCourse
  | ReportsConfigurationsTrainingNotStarted
  | ReportsConfigurationsTrainingStatusByCampaign
  | ReportsConfigurationsTrainingStatusByCourse
  | ReportsConfigurationsTrainingStatusByGroup
  | ReportsConfigurationsTrainingStatusesByCampaign
  | ReportsConfigurationsTrainingSurveysByCampaign
  | ReportsConfigurationsTrainingSurveysByCourse
  | ReportsConfigurationsUnphishedUsers
  | ReportsConfigurationsUserCountByAssessment
  | ReportsConfigurationsUserCountBySurvey
  | ReportsConfigurationsUserCounts
  | ReportsConfigurationsUserPhishingCampaigns
  | ReportsConfigurationsUserReportCard
  | ReportsConfigurationsUserSignIns
  | ReportsConfigurationsUserTrainingCampaigns
  | ReportsConfigurationsUsersList

/** ModStore content type */
export type Content = {
  __typename?: 'Content'
  /** A code representing the content type */
  code: Scalars['String']
  description?: Maybe<Scalars['String']>
  /** The icon associated with the content type */
  icon?: Maybe<Scalars['String']>
  /** The unique identifier of the content type */
  id?: Maybe<Scalars['Int']>
  /** The name of the content type */
  name?: Maybe<Scalars['String']>
  /** The translation key for the content type name */
  translationKey: Scalars['String']
}

export type ContentTheme = Identifier & {
  __typename?: 'ContentTheme'
  /** The display name of the content theme for audit logs */
  auditLogDisplay?: Maybe<Scalars['String']>
  /** The primary color of the content theme */
  colorPrimary: Scalars['String']
  /** Indicates if this is the default content theme for the account */
  defaultForAccount: Scalars['Boolean']
  /** The default translation of the content theme */
  defaultTranslation?: Maybe<ThemeTranslation>
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The data URL for the content theme logo */
  logoDataUrl: Scalars['String']
  /** The URL for the content theme logo */
  logoUrl: Scalars['String']
  /** The name of the content theme */
  name: Scalars['String']
  /** The training campaigns associated with the content theme */
  trainingCampaigns: Array<TrainingCampaign>
  /** The translations for the content theme */
  translations?: Maybe<Array<ThemeTranslation>>
  /** The last update timestamp of the content theme */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>
}

/** Autogenerated return type of ContentThemeAssignToTrainingCampaign. */
export type ContentThemeAssignToTrainingCampaignPayload = {
  __typename?: 'ContentThemeAssignToTrainingCampaignPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ContentTheme>
}

export type ContentThemeAttributes = {
  colorPrimary: Scalars['String']
  documentLinks?: Maybe<Array<DocumentsLinkAttributes>>
  email?: Maybe<Scalars['String']>
  introSlide?: Maybe<ThemeSlideAttributes>
  logo?: Maybe<Scalars['File']>
  name: Scalars['String']
  outroSlide?: Maybe<ThemeSlideAttributes>
  phoneNumber?: Maybe<Scalars['String']>
  socialMediaLinks?: Maybe<Array<SocialMediaLinkAttributes>>
}

/** Autogenerated return type of ContentThemeClone. */
export type ContentThemeClonePayload = {
  __typename?: 'ContentThemeClonePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ContentTheme>
}

/** Autogenerated return type of ContentThemeCreate. */
export type ContentThemeCreatePayload = {
  __typename?: 'ContentThemeCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ContentTheme>
}

/** Autogenerated return type of ContentThemeDelete. */
export type ContentThemeDeletePayload = {
  __typename?: 'ContentThemeDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ContentTheme>
}

/** Autogenerated return type of ContentThemeRemoveDefault. */
export type ContentThemeRemoveDefaultPayload = {
  __typename?: 'ContentThemeRemoveDefaultPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ContentTheme>
}

/** Autogenerated return type of ContentThemeRemoveFromTrainingCampaign. */
export type ContentThemeRemoveFromTrainingCampaignPayload = {
  __typename?: 'ContentThemeRemoveFromTrainingCampaignPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<TrainingCampaign>
}

/** Autogenerated return type of ContentThemeSetDefault. */
export type ContentThemeSetDefaultPayload = {
  __typename?: 'ContentThemeSetDefaultPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ContentTheme>
}

/** Autogenerated return type of ContentThemeUpdate. */
export type ContentThemeUpdatePayload = {
  __typename?: 'ContentThemeUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ContentTheme>
}

export type ContentThemesCursor = {
  __typename?: 'ContentThemesCursor'
  /** The paginated nodes */
  nodes: Array<ContentTheme>
  /** Information about the current paginated set */
  pagination: Pagination
}

export type ContentTypeUnion = CustomContent | StoreItem

export enum ContentTypes {
  Custom = 'custom',
  System = 'system',
}

/** A training campaign asset */
export type Course = KnowledgeRefresher | Policy | PurchasedCourse

/** Course alternative for archived or retired course */
export type CourseAlternative = Identifier &
  Timestamps & {
    __typename?: 'CourseAlternative'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The unique identifier of the course alternative */
    id: Scalars['Int']
    /** The current status of the course alternative */
    status?: Maybe<Scalars['String']>
    /** The title of the course alternative */
    title: Scalars['String']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The universally unique identifier of the course alternative */
    uuid: Scalars['String']
  }

export enum CourseTypes {
  Assessment = 'ASSESSMENT',
  Course = 'COURSE',
  Policy = 'POLICY',
}

/** The CSM account settings */
export type Csm = Identifier &
  Timestamps & {
    __typename?: 'Csm'
    /** The email address of the account manager */
    accountManagerEmail?: Maybe<Scalars['String']>
    /** The name of the account manager */
    accountManagerName?: Maybe<Scalars['String']>
    /** The email address of the account owner */
    accountOwnerEmail?: Maybe<Scalars['String']>
    /** The name of the account owner */
    accountOwnerName?: Maybe<Scalars['String']>
    /** The status of the account owner */
    accountOwnerStatus?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The nature of the customer’s relationship with the partner */
    customerRelationshipWithPartner?: Maybe<Scalars['String']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type CsmAttributes = {
  accountManagerEmail?: Maybe<Scalars['String']>
  accountManagerName?: Maybe<Scalars['String']>
  accountOwnerEmail?: Maybe<Scalars['String']>
  accountOwnerName?: Maybe<Scalars['String']>
  accountOwnerStatus?: Maybe<Scalars['String']>
  customerRelationshipWithPartner?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
}

export type CsvError = {
  __typename?: 'CsvError'
  /** The codes of the errors encountered */
  codes: Array<Scalars['String']>
  /** Details of the CSV row where the error was found */
  row?: Maybe<CsvRowDetail>
  /** The index of the row in the CSV file where the error occurred */
  rowIndex: Scalars['Int']
}

export type CsvRowDetail = {
  __typename?: 'CsvRowDetail'
  /** The email address associated with the CSV row */
  email?: Maybe<Scalars['String']>
  /** The hostname associated with the CSV row */
  hostname?: Maybe<Scalars['String']>
  /** The username associated with the CSV row */
  username?: Maybe<Scalars['String']>
}

export type CsvStatusDetails = {
  __typename?: 'CsvStatusDetails'
  /** List of errors encountered during the CSV processing */
  errors: Array<CsvError>
  /** Number of rows that failed to process */
  failedRowCount?: Maybe<Scalars['Int']>
  /** Number of rows processed successfully */
  successRowCount: Scalars['Int']
  /** Total number of rows processed */
  totalRowCount: Scalars['Int']
}

/** Autogenerated return type of CurrentActorsSignOut. */
export type CurrentActorsSignOutPayload = {
  __typename?: 'CurrentActorsSignOutPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Information about the current cursor paginated set */
export type CursorPagination = {
  __typename?: 'CursorPagination'
  /** The cursor that will retrieve the next page of results */
  endCursor?: Maybe<CursorPaginationCursor>
  /** Indicates whether there's a next page */
  hasNextPage: Scalars['Boolean']
  /** Indicates whether there's a previous page */
  hasPreviousPage: Scalars['Boolean']
  /** The cursor that will retrieve the previous page of results */
  previousCursor?: Maybe<CursorPaginationCursor>
  /** The cursor used to retrieve the returned page */
  startCursor?: Maybe<CursorPaginationCursor>
}

/** Information about the current cursor paginated cursor. */
export type CursorPaginationCursor = {
  __typename?: 'CursorPaginationCursor'
  /** ID of the cursor */
  id?: Maybe<Scalars['Int']>
  /** Timestamp of the cursor */
  ts?: Maybe<Scalars['ISO8601DateTime']>
}

export type CursorPaginationCursorAttributes = {
  /** ID */
  id?: Maybe<Scalars['Int']>
  /** Timestamp */
  ts?: Maybe<Scalars['ISO8601DateTime']>
}

/** Custom content */
export type CustomContent = {
  __typename?: 'CustomContent'
  /** The creation date and time of the custom content */
  createdAt: Scalars['ISO8601DateTime']
  /** The topics associated with the custom content */
  customContentTopics: Array<CustomContentTopic>
  /** The translations for the custom content */
  customContentTranslations?: Maybe<Array<CustomContentTranslation>>
  /** The default language code for the custom content */
  defaultLanguageCode?: Maybe<Scalars['String']>
  description: Scalars['String']
  /** The unique identifier of the custom content */
  id: Scalars['String']
  /** The published status of the custom content */
  published?: Maybe<Scalars['Boolean']>
  /** The ranking of the custom content */
  ranking?: Maybe<Scalars['Int']>
  /** The Real-Time Coaching campaigns associated with the custom content */
  rtcCampaigns?: Maybe<Array<SecurityCoachCampaign>>
  /** The URL of the tile image for the custom content */
  tileImageUrl: Scalars['String']
  /** The title of the custom content */
  title: Scalars['String']
  /** The last update date and time of the custom content */
  updatedAt: Scalars['ISO8601DateTime']
}

export type CustomContentFormAttributes = {
  defaultLanguageCode?: Maybe<Languages>
  description: Scalars['String']
  tileImage?: Maybe<Scalars['File']>
  title: Scalars['String']
  topics: Array<ModstoreTopic>
}

/** Custom content topic */
export type CustomContentTopic = {
  __typename?: 'CustomContentTopic'
  /** The timestamp when the custom content topic was created */
  createdAt: Scalars['ISO8601DateTime']
  /** The unique identifier of the custom content topic */
  id: Scalars['String']
  /** The name of the custom content topic */
  name: Scalars['String']
  /** The translation key for the custom content topic's name */
  translationKey: Scalars['String']
  /** The timestamp when the custom content topic was last updated */
  updatedAt: Scalars['ISO8601DateTime']
}

/** Custom content translation */
export type CustomContentTranslation = {
  __typename?: 'CustomContentTranslation'
  /** The alternative text for the content image */
  altText: Scalars['String']
  /** The creation date and time of the custom content translation */
  createdAt: Scalars['ISO8601DateTime']
  /** The unique identifier of the custom content translation */
  id: Scalars['String']
  /** The URL of the image associated with this content */
  imageUrl: Scalars['String']
  /** Indicates if this is the default translation */
  isDefault: Scalars['Boolean']
  /** The language code of the translation */
  languageCode: Languages
  /** The status of the custom content translation */
  status: CustomContentTranslationStatuses
  /** The last update date and time of the custom content translation */
  updatedAt: Scalars['ISO8601DateTime']
}

export type CustomContentTranslationFormAttributes = {
  altText?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['File']>
  languageCode?: Maybe<Languages>
  status?: Maybe<CustomContentTranslationStatuses>
}

export enum CustomContentTranslationStatuses {
  Archived = 'archived',
  Draft = 'draft',
  Published = 'published',
}

export type CustomContentsListAttributes = {
  languages?: Maybe<Array<Languages>>
  languagesCombined?: Maybe<Scalars['Boolean']>
  publishedTranslationsOnly?: Maybe<Scalars['Boolean']>
  search?: Maybe<Scalars['String']>
  topics?: Maybe<Array<Scalars['String']>>
}

export type CustomEvent = Identifier & {
  __typename?: 'CustomEvent'
  /** A boolean value to set specific criterion logic */
  boolean: Booleans
  /** Type of comparison to apply on event data */
  comparison: EventComparisons
  /** The count factor of the event for the criterion */
  count: Scalars['Int']
  description?: Maybe<Scalars['String']>
  /** The specified event to evaluate in the group criterion */
  event: CustomEventDefinition
  /** List of external IDs associated with the criterion */
  externalIds: Array<Scalars['String']>
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The source of the event data, optional */
  source?: Maybe<CustomEventDefinition>
  /** Timeframe configuration for the criterion */
  timeframe: Timeframe
}

export type CustomEventArgument = {
  bool: Booleans
  count: Scalars['Int']
  countType: EventComparisons
  description?: Maybe<Scalars['String']>
  event: CustomEventDefinitionArgument
  externalId: Array<Scalars['String']>
  source?: Maybe<CustomEventDefinitionArgument>
  timeframe: TimeframeArgument
}

export type CustomEventDefinition = {
  __typename?: 'CustomEventDefinition'
  /** The unique ID of the custom event definition */
  id: Scalars['BigInt']
  /** The label of the custom event definition */
  label: Scalars['String']
}

export type CustomEventDefinitionArgument = {
  id?: Maybe<Scalars['BigInt']>
  label?: Maybe<Scalars['String']>
}

/** Custom event */
export type CustomEventTypesAndSources = {
  __typename?: 'CustomEventTypesAndSources'
  /** A list of event sources */
  sources?: Maybe<Array<EventSource>>
  /** A list of custom event types */
  types?: Maybe<Array<Event>>
}

/** Custom notification */
export type CustomNotification = {
  __typename?: 'CustomNotification'
  /** The creation date and time of the custom notification */
  createdAt: Scalars['ISO8601DateTime']
  /** The custom translations of the notification */
  customTranslations?: Maybe<Array<CustomTranslation>>
  /** The unique identifier of the custom notification */
  id: Scalars['String']
  /** The count of languages available for the custom notification */
  languageCount: Scalars['Int']
  /** The name of the custom notification */
  name: Scalars['String']
  /** The type of the custom notification */
  notificationType: Scalars['String']
  /** The current status of the custom notification */
  status: CustomNotificationStatuses
  /** The last updated date and time of the custom notification */
  updatedAt: Scalars['ISO8601DateTime']
}

export type CustomNotificationFormAttributes = {
  name?: Maybe<Scalars['String']>
  status?: Maybe<CustomNotificationFormStatuses>
}

export enum CustomNotificationFormStatuses {
  Active = 'active',
  Archived = 'archived',
  Draft = 'draft',
}

export enum CustomNotificationSortFields {
  LanguageCount = 'language_count',
  Name = 'name',
  UpdatedAt = 'updated_at',
}

export enum CustomNotificationStatuses {
  Active = 'active',
  All = 'all',
  Archived = 'archived',
  Draft = 'draft',
}

export type CustomNotificationsCursor = {
  __typename?: 'CustomNotificationsCursor'
  /** The paginated nodes */
  nodes: Array<CustomNotification>
  /** Information about the current paginated set */
  pagination: Pagination
}

export type CustomNotificationsListAttributes = {
  languages?: Maybe<Array<Languages>>
  languagesCombined?: Maybe<Scalars['Boolean']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<CustomNotificationSortFields>
  status?: Maybe<CustomNotificationStatuses>
}

export type CustomTerm = {
  __typename?: 'CustomTerm'
  /** The base term used in the custom term mapping */
  baseTerm: Scalars['String']
  /** The unique identifier of the custom term */
  id: Scalars['ID']
  /** The term to be swapped with the base term */
  swapTerm: Scalars['String']
}

export type CustomTermAttributes = {
  /** The base term of the custom term */
  baseTerm: Scalars['String']
  /** The swap term of the custom term */
  swapTerm: Scalars['String']
}

/** Custom translation */
export type CustomTranslation = {
  __typename?: 'CustomTranslation'
  /** The content of the translation in HTML format */
  contentHtml?: Maybe<Scalars['String']>
  /** The content of the translation in text format */
  contentText?: Maybe<Scalars['String']>
  /** The date and time when the custom translation was created */
  createdAt: Scalars['ISO8601DateTime']
  /** The identifier of the custom notification associated with this translation */
  customNotificationId: Scalars['Int']
  /** The email address from which the custom translation is sent */
  from: Scalars['String']
  /** The display name of the sender of the custom translation */
  fromDisplayName?: Maybe<Scalars['String']>
  /** Unique identifier for the custom translation */
  id: Scalars['Int']
  /** Language code of the custom translation */
  languageCode: Languages
  /** Email address to which replies should be sent */
  replyTo?: Maybe<Scalars['String']>
  /** The display name for the reply-to email address */
  replyToDisplayName?: Maybe<Scalars['String']>
  /** The subject of the custom translation */
  subject: Scalars['String']
  /** The date and time when the custom translation was last updated */
  updatedAt: Scalars['ISO8601DateTime']
}

export type CustomTranslationFormAttributes = {
  contentHtml: Scalars['String']
  from: Scalars['String']
  fromDisplayName?: Maybe<Scalars['String']>
  languageCode?: Maybe<Languages>
  replyTo?: Maybe<Scalars['String']>
  replyToDisplayName?: Maybe<Scalars['String']>
  subject: Scalars['String']
}

export type DataPoint = {
  __typename?: 'DataPoint'
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** The X coordinate or value of the data point */
  x?: Maybe<Scalars['Float']>
  /** The Y coordinate or value of the data point */
  y?: Maybe<Scalars['Float']>
}

export type DataSeries = {
  __typename?: 'DataSeries'
  /** An array of data points belonging to the data series */
  data: Array<DataPoint>
  /** The name of the data series */
  name: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export enum DateFields {
  CreatedAt = 'CREATED_AT',
  CustomDate_1 = 'CUSTOM_DATE_1',
  CustomDate_2 = 'CUSTOM_DATE_2',
  EmployeeStartDate = 'EMPLOYEE_START_DATE',
  LastPasswordChangeDateTime = 'LAST_PASSWORD_CHANGE_DATE_TIME',
  LastSignInAt = 'LAST_SIGN_IN_AT',
}

export enum DateUnits {
  Day = 'DAY',
  Hour = 'HOUR',
  Minute = 'MINUTE',
  Month = 'MONTH',
  Week = 'WEEK',
}

export type DefaultLanguages = {
  adminLocale?: Maybe<Scalars['String']>
  phishingLocale?: Maybe<Scalars['String']>
  trainingLocale?: Maybe<Scalars['String']>
}

export enum Delimiters {
  CommaAndSpace = 'comma_and_space',
  Empty = 'empty',
  Hyphen = 'hyphen',
  Period = 'period',
  Space = 'space',
  Underscore = 'underscore',
}

/** Delivery channels */
export type DeliveryChannel = {
  __typename?: 'DeliveryChannel'
  /** The method of delivery for the security message */
  channel: DeliveryMethods
  /** The ID for the delivery channel */
  deliveryChannelId: Scalars['String']
  /** The ID of the workspace associated with the delivery channel */
  workspaceId: Scalars['String']
}

export enum DeliveryErrors {
  AuthenticationError = 'authentication_error',
  BlockedByAdmin = 'blocked_by_admin',
  BlockedByUser = 'blocked_by_user',
  ConversationNotFound = 'conversation_not_found',
  FormatError = 'format_error',
  GoogleServerUnavailable = 'google_server_unavailable',
  MessageSizeLimit = 'message_size_limit',
  MicrosoftServerUnavailable = 'microsoft_server_unavailable',
  MissingAuthentication = 'missing_authentication',
  PolicyError = 'policy_error',
  ServiceUnavailable = 'service_unavailable',
  SlackServerUnavailable = 'slack_server_unavailable',
  UserNotFound = 'user_not_found',
}

export enum DeliveryMethods {
  Gchat = 'gchat',
  Slack = 'slack',
  Teams = 'teams',
}

export enum DeliveryStatus {
  Delivered = 'delivered',
  Failed = 'failed',
  Held_20Minutes = 'held_20_minutes',
  Held_24Hours = 'held_24_hours',
  Pending = 'pending',
  TestModeDelivered = 'test_mode_delivered',
  TestModeHeld = 'test_mode_held',
}

/** Status of a demo seed in Sidekiq */
export type DemoSeedStatus = {
  __typename?: 'DemoSeedStatus'
  /** The arguments used for the demo seed */
  args: Scalars['String']
  /** The class associated with the demo seed */
  klass: Scalars['String']
  /** The current status of the demo seed */
  status: Scalars['String']
}

export type DemoSeedStatusesCursor = {
  __typename?: 'DemoSeedStatusesCursor'
  /** The paginated nodes */
  nodes: Array<DemoSeedStatus>
  /** Information about the current paginated set */
  pagination: Pagination
}

export enum DetectionCountFilters {
  Any = 'ANY',
  EqualsOne = 'EQUALS_ONE',
  GreaterThanFive = 'GREATER_THAN_FIVE',
  GreaterThanOne = 'GREATER_THAN_ONE',
}

export type DetectionData = {
  __typename?: 'DetectionData'
  /** The count of detection events */
  count?: Maybe<Scalars['Int']>
  /** The creation date of the detection data */
  createdAt?: Maybe<Scalars['ISO8601Date']>
}

export enum DetectionDeliveryMethods {
  Email = 'email',
  Gchat = 'gchat',
  Slack = 'slack',
  Teams = 'teams',
}

export type DetectionEvent = {
  __typename?: 'DetectionEvent'
  alertType?: Maybe<Scalars['String']>
  appClass?: Maybe<Scalars['String']>
  appUsed?: Maybe<Scalars['String']>
  campaignName?: Maybe<Scalars['String']>
  clientIpaddress?: Maybe<Scalars['String']>
  detectionCmdline?: Maybe<Scalars['String']>
  detectionCount?: Maybe<Scalars['String']>
  detectionDatetime?: Maybe<Scalars['String']>
  detectionDescription?: Maybe<Scalars['String']>
  detectionDeviceClass?: Maybe<Scalars['String']>
  detectionDstCountry?: Maybe<Scalars['String']>
  detectionEventName?: Maybe<Scalars['String']>
  detectionFilePasswdProtected?: Maybe<Scalars['String']>
  detectionFilename?: Maybe<Scalars['String']>
  detectionFilepath?: Maybe<Scalars['String']>
  detectionId?: Maybe<Scalars['String']>
  detectionProcess?: Maybe<Scalars['String']>
  detectionScenario?: Maybe<Scalars['String']>
  detectionSource?: Maybe<Scalars['String']>
  detectionSourceIp?: Maybe<Scalars['String']>
  detectionTactic?: Maybe<Scalars['String']>
  detectionThreatCategory?: Maybe<Scalars['String']>
  detectionThreatCategoryLevel2?: Maybe<Scalars['String']>
  detectionThreatName?: Maybe<Scalars['String']>
  detectionThreatSeverity?: Maybe<Scalars['String']>
  detectionThreatTechnique?: Maybe<Scalars['String']>
  detectionThreatType?: Maybe<Scalars['String']>
  detectionUrl?: Maybe<Scalars['String']>
  detectionUserId?: Maybe<Scalars['String']>
  detectionWebCountry?: Maybe<Scalars['String']>
  deviceId?: Maybe<Scalars['String']>
  directionality?: Maybe<Scalars['String']>
  dlpRule?: Maybe<Scalars['String']>
  emailSubject?: Maybe<Scalars['String']>
  eventType?: Maybe<Scalars['String']>
  fileSize?: Maybe<Scalars['String']>
  fileType?: Maybe<Scalars['String']>
  hostIpaddress?: Maybe<Scalars['String']>
  hostName?: Maybe<Scalars['String']>
  hostOsName?: Maybe<Scalars['String']>
  hostOsPlatform?: Maybe<Scalars['String']>
  hostOsVersion?: Maybe<Scalars['String']>
  objectName?: Maybe<Scalars['String']>
  policy?: Maybe<Scalars['String']>
  securityProductContentDetails?: Maybe<Scalars['String']>
  securityProductLastSeenDate?: Maybe<Scalars['String']>
  securityProductName?: Maybe<Scalars['String']>
  securityProductStatus?: Maybe<Scalars['String']>
  securityProductThreatAction?: Maybe<Scalars['String']>
  securityProductVendor?: Maybe<Scalars['String']>
  securityProductVersion?: Maybe<Scalars['String']>
  senderEmailAddress?: Maybe<Scalars['String']>
  usedProtocol?: Maybe<Scalars['String']>
  userActivity?: Maybe<Scalars['String']>
  userEmail?: Maybe<Scalars['String']>
  userLocation?: Maybe<Scalars['String']>
  userName?: Maybe<Scalars['String']>
  vendorCodeName?: Maybe<Scalars['String']>
  visitedDomainName?: Maybe<Scalars['String']>
  visitedUrl?: Maybe<Scalars['String']>
}

export enum DetectionTypes {
  AdPwClearText = 'AD_PW_CLEAR_TEXT',
  AdPwEmpty = 'AD_PW_EMPTY',
  AdPwFoundInBreach = 'AD_PW_FOUND_IN_BREACH',
  AdPwNeverExpires = 'AD_PW_NEVER_EXPIRES',
  AdPwNotReqd = 'AD_PW_NOT_REQD',
  AdPwShared = 'AD_PW_SHARED',
  AdPwWeak = 'AD_PW_WEAK',
  AdUserAesEncryptionNotSet = 'AD_USER_AES_ENCRYPTION_NOT_SET',
  AdUserDesOnlyEncryption = 'AD_USER_DES_ONLY_ENCRYPTION',
  AdUserHasPreauthentication = 'AD_USER_HAS_PREAUTHENTICATION',
  AdUserUsesLmHash = 'AD_USER_USES_LM_HASH',
}

export type DetectionsCursor = {
  __typename?: 'DetectionsCursor'
  /** The paginated nodes */
  nodes: Array<SecurityCoachDetection>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Linked device details */
export type DeviceDetails = Identifier &
  Timestamps & {
    __typename?: 'DeviceDetails'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** ID for the device */
    deviceId: Scalars['String']
    /** Firebase Cloud Messaging token for the device */
    fcmToken?: Maybe<Scalars['String']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** A user-defined label for the device */
    label?: Maybe<Scalars['String']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type DeviceDetailsAttributes = {
  apiTokenId: Scalars['Int']
  deviceId: Scalars['String']
  fcmToken?: Maybe<Scalars['String']>
  label: Scalars['String']
}

/** Autogenerated return type of DeviceDetailsCreate. */
export type DeviceDetailsCreatePayload = {
  __typename?: 'DeviceDetailsCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<DeviceDetails>
}

/** Autogenerated return type of DeviceDetailsDelete. */
export type DeviceDetailsDeletePayload = {
  __typename?: 'DeviceDetailsDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<DeviceDetails>
}

/** Autogenerated return type of DeviceDetailsUpdate. */
export type DeviceDetailsUpdatePayload = {
  __typename?: 'DeviceDetailsUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<DeviceDetails>
}

export type DimensionPoint = {
  __typename?: 'DimensionPoint'
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** The X coordinate or survey dimension value in the report */
  x: SurveyDimensions
  /** The Y coordinate or float value representing the measurement in the report */
  y: Scalars['Float']
}

export type DimensionSeries = {
  __typename?: 'DimensionSeries'
  /** Data points within the dimension series */
  data: Array<DimensionPoint>
  /** The name of the dimension series */
  name: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type DimensionSeriesCollection = {
  __typename?: 'DimensionSeriesCollection'
  /** CSV format of the report data */
  csv: Scalars['String']
  /** Collection of data series for the report */
  dataseries: Array<DimensionSeries>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type DimensionTimeSeries = {
  __typename?: 'DimensionTimeSeries'
  /** The data points for the time series of this dimension */
  data: Array<TimePoint>
  /** The name of the survey dimension */
  name: SurveyDimensions
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type DimensionTimeSeriesCollection = {
  __typename?: 'DimensionTimeSeriesCollection'
  /** A CSV representation of the time series data */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** A collection of time series data for a specific dimension */
  timeseries: Array<DimensionTimeSeries>
}

/** Direct report statistics */
export type DirectReport = Identifier &
  Timestamps & {
    __typename?: 'DirectReport'
    /** Enrollments that have been completed */
    completeEnrollments: Array<Enrollment>
    /** Optional enrollments that have been completed */
    completeOptionalEnrollments: Array<Enrollment>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Enrollments that are due soon */
    dueSoonEnrollments: Array<Enrollment>
    /** The email address of the direct report */
    email: Scalars['String']
    /** Failed phishing simulation attempts by the direct report */
    failures?: Maybe<Array<PhishingCampaignRecipient>>
    /** Indicates if the user has direct reports */
    hasDirectReports: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The job title of the direct report */
    jobTitle?: Maybe<Scalars['String']>
    /** The name of the direct report */
    name: Scalars['String']
    /** Enrollments that are past their due date */
    pastDueEnrollments: Array<Enrollment>
    /** Phishing statistics associated with the direct report */
    phishingStatistics?: Maybe<UserPhishingStatistics>
    /** The percentage of training completed by the direct report */
    trainingPercentComplete: Scalars['Float']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type DirectReportsCursor = {
  __typename?: 'DirectReportsCursor'
  /** The paginated nodes */
  nodes: Array<DirectReport>
  /** Information about the current paginated set */
  pagination: Pagination
}

export type DistributionCollection = {
  __typename?: 'DistributionCollection'
  /** CSV representation of the report data */
  csv: Scalars['String']
  /** An array of data series for reports */
  dataseries: Array<DataSeries>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

/** DKIM configuration */
export type DkimConfig = Identifier &
  Timestamps & {
    __typename?: 'DkimConfig'
    /** The ID of the allowed domain associated with this DKIM configuration */
    allowedDomainId?: Maybe<Scalars['Int']>
    /** Display information for audit logs */
    auditLogDisplay?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Indicates if the DKIM configuration is custom */
    custom: Scalars['Boolean']
    /** The DNS TXT record value for the DKIM */
    dnsTxt?: Maybe<Scalars['String']>
    /** The domain associated with this DKIM configuration */
    domain: Scalars['String']
    /** The type of email associated with this DKIM configuration */
    emailType: DkimEmailTypes
    /** Indicates if the DKIM is enabled */
    enabled: Scalars['Boolean']
    /** The fully-qualified domain name associated with the DKIM configuration */
    fqdn?: Maybe<Scalars['String']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The selector value used in the DKIM configuration */
    selector: Scalars['String']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The timestamp when the DKIM configuration was validated */
    validatedAt?: Maybe<Scalars['ISO8601DateTime']>
  }

export type DkimConfigAttributes = {
  /** The ID of the allowed domain */
  allowedDomainId?: Maybe<Scalars['Int']>
  custom?: Maybe<Scalars['Boolean']>
  /** The type of email the DKIM configuration is for */
  emailType?: Maybe<DkimEmailTypes>
  /** Whether DKIM is enabled */
  enabled?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of DkimConfigsCreate. */
export type DkimConfigsCreatePayload = {
  __typename?: 'DkimConfigsCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created DKIM configuration */
  node?: Maybe<DkimConfig>
}

/** Autogenerated return type of DkimConfigsDelete. */
export type DkimConfigsDeletePayload = {
  __typename?: 'DkimConfigsDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The deleted DKIM configuration */
  node?: Maybe<DkimConfig>
}

/** Autogenerated return type of DkimConfigsUpdate. */
export type DkimConfigsUpdatePayload = {
  __typename?: 'DkimConfigsUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated DKIM configuration */
  node?: Maybe<DkimConfig>
}

/** Autogenerated return type of DkimConfigsValidate. */
export type DkimConfigsValidatePayload = {
  __typename?: 'DkimConfigsValidatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The DKIM configuration */
  node?: Maybe<DkimConfig>
}

export enum DkimEmailTypes {
  Phishing = 'phishing',
  Training = 'training',
}

/** DMI configuration */
export type DmiConfig = Identifier &
  Timestamps & {
    __typename?: 'DmiConfig'
    /** List of domain IDs allowed for this DMI configuration */
    allowedDomainIds?: Maybe<Array<Scalars['Int']>>
    /** The type of connection used by this DMI configuration */
    connectionType: DmiConfigConnectionTypes
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** List of DMI domains associated with this configuration */
    dmiDomains?: Maybe<Array<DmiDomain>>
    /** Email address used for testing the configuration */
    emailToTest?: Maybe<Scalars['String']>
    /** Indicates if the DMI configuration is enabled */
    enabled: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The name of the DMI configuration */
    name: Scalars['String']
    /** Email addresses to notify in case of important updates or issues */
    notifyEmails?: Maybe<Array<Scalars['String']>>
    /** The current status code of the DMI configuration */
    statusCode?: Maybe<Scalars['String']>
    /** Detailed information about the current status */
    statusInfo?: Maybe<Scalars['String']>
    /** The timestamp of the last status update */
    statusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The identifier of the tenant associated with this configuration */
    tenantId: Scalars['String']
    /** The status of the tenant associated with this DMI configuration */
    tenantStatus?: Maybe<Scalars['String']>
    /** The timestamp when a test email was last sent */
    testDeliveredAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type DmiConfigAttributes = {
  /** The IDs of the allowed domains */
  allowedDomainIds?: Maybe<Array<Scalars['Int']>>
  /** The connection type of the DMI configuration */
  connectionType?: Maybe<DmiConfigConnectionTypes>
  /** The email to test the DMI configuration */
  emailToTest?: Maybe<Scalars['String']>
  /** Whether DMI is enabled */
  enabled: Scalars['Boolean']
  /** The name of the DMI configuration */
  name: Scalars['String']
  /** The emails to notify */
  notifyEmails?: Maybe<Array<Scalars['String']>>
}

export enum DmiConfigConnectionTypes {
  Google = 'GOOGLE',
  M365 = 'M365',
}

/** Autogenerated return type of DmiConfigsCreate. */
export type DmiConfigsCreatePayload = {
  __typename?: 'DmiConfigsCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created DMI configuration */
  node?: Maybe<DmiConfig>
}

export type DmiConfigsCursor = {
  __typename?: 'DmiConfigsCursor'
  /** The paginated nodes */
  nodes: Array<DmiConfig>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of DmiConfigsDelete. */
export type DmiConfigsDeletePayload = {
  __typename?: 'DmiConfigsDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The deleted DMI configuration */
  node?: Maybe<DmiConfig>
}

/** Autogenerated return type of DmiConfigsReconnect. */
export type DmiConfigsReconnectPayload = {
  __typename?: 'DmiConfigsReconnectPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The reconnected DMI configuration */
  node?: Maybe<DmiConfig>
}

/** Autogenerated return type of DmiConfigsUpdate. */
export type DmiConfigsUpdatePayload = {
  __typename?: 'DmiConfigsUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated DMI configuration */
  node?: Maybe<DmiConfig>
}

/** DMI configured domain */
export type DmiDomain = Identifier &
  Timestamps & {
    __typename?: 'DmiDomain'
    /** The ID of the allowed domain */
    allowedDomainId: Scalars['Int']
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The name of the domain */
    name: Scalars['String']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type Docs = {
  __typename?: 'Docs'
  /** The email address of the user */
  email?: Maybe<Scalars['String']>
  /** The organization to which the user belongs */
  organization?: Maybe<Organization>
  /** The unique identifier of the team */
  teamId?: Maybe<Scalars['String']>
  /** The unique identifier of the user */
  userId?: Maybe<Scalars['String']>
  /** The username associated with the user */
  userName?: Maybe<Scalars['String']>
}

export type DocumentLink = {
  __typename?: 'DocumentLink'
  /** Timestamp when the document link was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Type of document the link refers to */
  documentType?: Maybe<Scalars['String']>
  /** Icon representing the document link */
  icon?: Maybe<Scalars['String']>
  /** ID of the document link */
  id?: Maybe<Scalars['Int']>
  /** Text associated with the document link */
  linkText?: Maybe<Scalars['String']>
  /** URL of the document link */
  linkUrl?: Maybe<Scalars['String']>
  /** Timestamp of the last update of the document link */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>
}

export enum DocumentLinkIcon {
  File = 'file',
  FileAlt = 'file_alt',
  FilePdf = 'file_pdf',
}

export type DocumentsLinkAttributes = {
  documentType: DocumentLinkIcon
  icon?: Maybe<Scalars['String']>
  linkText: Scalars['String']
  linkUrl: Scalars['String']
}

export type DomainAttributes = {
  excludedGroups: Array<GoogleGroupAttributes>
  excludedUsers: Array<Scalars['String']>
  includedGroups: Array<GoogleGroupAttributes>
  includedUsers: Array<Scalars['String']>
  name: Scalars['String']
  syncAllUsers?: Maybe<Scalars['Boolean']>
}

/** Download */
export type Download = Identifier &
  Timestamps & {
    __typename?: 'Download'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The number of times the file has been downloaded */
    downloadCount: Scalars['Int']
    /** The time the download was first downloaded */
    downloadedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The time the download failed, if it did */
    failedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The reason for the download failure, if any */
    failureReason?: Maybe<Scalars['String']>
    /** The type of file for the download */
    fileType: DownloadFileTypes
    /** The prefix for the filename of the download */
    filenamePrefix?: Maybe<Scalars['String']>
    /** The time the download file was generated */
    generatedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The ID of the download */
    id: Scalars['Int']
    /** A flag indicating if the download is completed */
    isCompleted: Scalars['Boolean']
    /** A flag indicating if the file has been downloaded */
    isDownloaded: Scalars['Boolean']
    /** A flag indicating if the file has been viewed */
    isViewed: Scalars['Boolean']
    /** The unique identifier of the origin from which the download was initiated */
    originId?: Maybe<Scalars['Int']>
    /** The type of origin from which the download was initiated */
    originType?: Maybe<Scalars['String']>
    /** The path to the downloadable file */
    path?: Maybe<Scalars['String']>
    /** A flag indicating if a retry is available for the download */
    retryAvailable: Scalars['Boolean']
    /** The number of retries that have been attempted */
    retryCount?: Maybe<Scalars['Int']>
    /** A flag indicating if the scheduled report has been reassigned */
    scheduledReportReassigned: Scalars['Boolean']
    /** The start time of the download process */
    startedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The current status of the download */
    status: DownloadStatusTypes
    /** The downloadable content type */
    type: Downloadable
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The time the download was first viewed */
    viewedAt?: Maybe<Scalars['ISO8601DateTime']>
  }

/** Autogenerated return type of DownloadCenterCustomReportsActiveRulesReport. */
export type DownloadCenterCustomReportsActiveRulesReportPayload = {
  __typename?: 'DownloadCenterCustomReportsActiveRulesReportPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of DownloadCenterCustomReportsAidaCampaignSummaryTable. */
export type DownloadCenterCustomReportsAidaCampaignSummaryTablePayload = {
  __typename?: 'DownloadCenterCustomReportsAidaCampaignSummaryTablePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of DownloadCenterCustomReportsAuditLogs. */
export type DownloadCenterCustomReportsAuditLogsPayload = {
  __typename?: 'DownloadCenterCustomReportsAuditLogsPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of DownloadCenterCustomReportsPabComparison. */
export type DownloadCenterCustomReportsPabComparisonPayload = {
  __typename?: 'DownloadCenterCustomReportsPabComparisonPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of DownloadCenterCustomReportsPabUserActivity. */
export type DownloadCenterCustomReportsPabUserActivityPayload = {
  __typename?: 'DownloadCenterCustomReportsPabUserActivityPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of DownloadCenterCustomReportsPhishingAllClickers. */
export type DownloadCenterCustomReportsPhishingAllClickersPayload = {
  __typename?: 'DownloadCenterCustomReportsPhishingAllClickersPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of DownloadCenterCustomReportsPhishingSecurityTests. */
export type DownloadCenterCustomReportsPhishingSecurityTestsPayload = {
  __typename?: 'DownloadCenterCustomReportsPhishingSecurityTestsPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of DownloadCenterCustomReportsTrainingCampaignEnrollments. */
export type DownloadCenterCustomReportsTrainingCampaignEnrollmentsPayload = {
  __typename?: 'DownloadCenterCustomReportsTrainingCampaignEnrollmentsPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of DownloadCenterCustomReportsTrainingCampaignSurveys. */
export type DownloadCenterCustomReportsTrainingCampaignSurveysPayload = {
  __typename?: 'DownloadCenterCustomReportsTrainingCampaignSurveysPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of DownloadCenterCustomReportsUserPhishingCampaigns. */
export type DownloadCenterCustomReportsUserPhishingCampaignsPayload = {
  __typename?: 'DownloadCenterCustomReportsUserPhishingCampaignsPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of DownloadCenterCustomReportsUserTrainingCampaigns. */
export type DownloadCenterCustomReportsUserTrainingCampaignsPayload = {
  __typename?: 'DownloadCenterCustomReportsUserTrainingCampaignsPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of DownloadCenterCustomReportsUsersList. */
export type DownloadCenterCustomReportsUsersListPayload = {
  __typename?: 'DownloadCenterCustomReportsUsersListPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of DownloadCenterDownloadDelete. */
export type DownloadCenterDownloadDeletePayload = {
  __typename?: 'DownloadCenterDownloadDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Array<Download>>
}

/** Autogenerated return type of DownloadCenterDownloadReportRetry. */
export type DownloadCenterDownloadReportRetryPayload = {
  __typename?: 'DownloadCenterDownloadReportRetryPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Download>
}

/** Autogenerated return type of DownloadCenterDownloadViewed. */
export type DownloadCenterDownloadViewedPayload = {
  __typename?: 'DownloadCenterDownloadViewedPayload'
  ids: Array<Scalars['Int']>
}

export enum DownloadFileTypes {
  Csv = 'CSV',
  Pdf = 'PDF',
}

export enum DownloadStatusTypes {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Generating = 'GENERATING',
  Retrying = 'RETRYING',
}

export type Downloadable =
  | ExecReportsReport
  | PasswordIqReport
  | Report
  | SecurityCoachUnmappedEventsReport

export enum DownloadablesCategories {
  AccountPhishFailurePercentage = 'ACCOUNT_PHISH_FAILURE_PERCENTAGE',
  AccountPhishPronePercentage = 'ACCOUNT_PHISH_PRONE_PERCENTAGE',
  AccountReportCard = 'ACCOUNT_REPORT_CARD',
  ActiveRulesReport = 'ACTIVE_RULES_REPORT',
  AidaCampaignSummaryTable = 'AIDA_CAMPAIGN_SUMMARY_TABLE',
  AuditLogs = 'AUDIT_LOGS',
  CultureSurveyReports = 'CULTURE_SURVEY_REPORTS',
  EmailsSent = 'EMAILS_SENT',
  ExecReports = 'EXEC_REPORTS',
  FailuresByPhishingTemplate = 'FAILURES_BY_PHISHING_TEMPLATE',
  FailureTypes = 'FAILURE_TYPES',
  GlobalPhishing = 'GLOBAL_PHISHING',
  GlobalTraining = 'GLOBAL_TRAINING',
  KnowledgeAssessmentReports = 'KNOWLEDGE_ASSESSMENT_REPORTS',
  LearnerPlatform = 'LEARNER_PLATFORM',
  OverallUserCounts = 'OVERALL_USER_COUNTS',
  PabComparison = 'PAB_COMPARISON',
  PabUserActivity = 'PAB_USER_ACTIVITY',
  PasswordIq = 'PASSWORD_IQ',
  PhishingAllClickers = 'PHISHING_ALL_CLICKERS',
  PhishingSecurityTests = 'PHISHING_SECURITY_TESTS',
  PhishFailurePercentage = 'PHISH_FAILURE_PERCENTAGE',
  PhishPronePercentage = 'PHISH_PRONE_PERCENTAGE',
  PhishPronePercentageByUserCount = 'PHISH_PRONE_PERCENTAGE_BY_USER_COUNT',
  ReportCards = 'REPORT_CARDS',
  RiskScore = 'RISK_SCORE',
  RiskScoreV2 = 'RISK_SCORE_V2',
  SecurityCoach = 'SECURITY_COACH',
  SecurityCoachActivityRtc = 'SECURITY_COACH_ACTIVITY_RTC',
  SecurityCoachDetectionRulesActivity = 'SECURITY_COACH_DETECTION_RULES_ACTIVITY',
  TemplateFailures = 'TEMPLATE_FAILURES',
  TrainingCampaignEnrollments = 'TRAINING_CAMPAIGN_ENROLLMENTS',
  TrainingCampaignSurveys = 'TRAINING_CAMPAIGN_SURVEYS',
  TrainingComparison = 'TRAINING_COMPARISON',
  TrainingCoverage = 'TRAINING_COVERAGE',
  TrainingHours = 'TRAINING_HOURS',
  TrainingInteractions = 'TRAINING_INTERACTIONS',
  TrainingStatus = 'TRAINING_STATUS',
  TrainingStatusesByCampaign = 'TRAINING_STATUSES_BY_CAMPAIGN',
  TrainingSurveys = 'TRAINING_SURVEYS',
  UsersList = 'USERS_LIST',
  UserCountByPhishPronePercentage = 'USER_COUNT_BY_PHISH_PRONE_PERCENTAGE',
  UserListings = 'USER_LISTINGS',
  UserPhishingCampaigns = 'USER_PHISHING_CAMPAIGNS',
  UserTrainingCampaigns = 'USER_TRAINING_CAMPAIGNS',
}

export enum DownloadablesTypes {
  AccountReportCard = 'ACCOUNT_REPORT_CARD',
  ActiveRulesReport = 'ACTIVE_RULES_REPORT',
  AidaCampaignSummaryTable = 'AIDA_CAMPAIGN_SUMMARY_TABLE',
  AssessmentScoreByKnowledgeArea = 'ASSESSMENT_SCORE_BY_KNOWLEDGE_AREA',
  AssessmentScoreByKnowledgeAreaOverTime = 'ASSESSMENT_SCORE_BY_KNOWLEDGE_AREA_OVER_TIME',
  AuditLogs = 'AUDIT_LOGS',
  EmailsSentByGroup = 'EMAILS_SENT_BY_GROUP',
  EmailsSentByTemplate = 'EMAILS_SENT_BY_TEMPLATE',
  ExecReportsPdf = 'EXEC_REPORTS_PDF',
  FailuresByPhishingTemplate = 'FAILURES_BY_PHISHING_TEMPLATE',
  FailureTypesBarChart = 'FAILURE_TYPES_BAR_CHART',
  FailureTypeUserCounts = 'FAILURE_TYPE_USER_COUNTS',
  GlobalPhishingAggregate = 'GLOBAL_PHISHING_AGGREGATE',
  GlobalPhishingRaw = 'GLOBAL_PHISHING_RAW',
  GlobalTrainingAggregate = 'GLOBAL_TRAINING_AGGREGATE',
  GlobalTrainingRaw = 'GLOBAL_TRAINING_RAW',
  GroupReportCard = 'GROUP_REPORT_CARD',
  IncompleteTraining = 'INCOMPLETE_TRAINING',
  InitialPpp = 'INITIAL_PPP',
  LearnerPlatformRaw = 'LEARNER_PLATFORM_RAW',
  NoTrainingAssigned = 'NO_TRAINING_ASSIGNED',
  OverallPppByAccount = 'OVERALL_PPP_BY_ACCOUNT',
  OverallPppByCampaign = 'OVERALL_PPP_BY_CAMPAIGN',
  OverallPppByGroup = 'OVERALL_PPP_BY_GROUP',
  OverallPppByTrainingTime = 'OVERALL_PPP_BY_TRAINING_TIME',
  PabComparison = 'PAB_COMPARISON',
  PabUserActivity = 'PAB_USER_ACTIVITY',
  PasswordIqCsv = 'PASSWORD_IQ_CSV',
  PasswordIqPdf = 'PASSWORD_IQ_PDF',
  PhishingAllClickers = 'PHISHING_ALL_CLICKERS',
  PhishingSecurityTests = 'PHISHING_SECURITY_TESTS',
  PhishFailurePercentageByCampaigns = 'PHISH_FAILURE_PERCENTAGE_BY_CAMPAIGNS',
  PhishFailurePercentageOverall = 'PHISH_FAILURE_PERCENTAGE_OVERALL',
  PppDistribution = 'PPP_DISTRIBUTION',
  RiskScoreByAssessment = 'RISK_SCORE_BY_ASSESSMENT',
  RiskScoreByGroup = 'RISK_SCORE_BY_GROUP',
  RiskScoreByLocation = 'RISK_SCORE_BY_LOCATION',
  RiskScoreBySurvey = 'RISK_SCORE_BY_SURVEY',
  RiskScoreByUser = 'RISK_SCORE_BY_USER',
  RiskScoreV2ByGroup = 'RISK_SCORE_V2_BY_GROUP',
  RiskScoreV2ByManager = 'RISK_SCORE_V2_BY_MANAGER',
  RiskScoreV2ByUser = 'RISK_SCORE_V2_BY_USER',
  SecurityCoachActivityRtcAggregate = 'SECURITY_COACH_ACTIVITY_RTC_AGGREGATE',
  SecurityCoachActivityRtcRaw = 'SECURITY_COACH_ACTIVITY_RTC_RAW',
  SecurityCoachDetectionRulesActivityAggregate = 'SECURITY_COACH_DETECTION_RULES_ACTIVITY_AGGREGATE',
  SecurityCoachDetectionRulesActivityRaw = 'SECURITY_COACH_DETECTION_RULES_ACTIVITY_RAW',
  SecurityCoachUnmappedEventsCsv = 'SECURITY_COACH_UNMAPPED_EVENTS_CSV',
  SurveyScoreByDimension = 'SURVEY_SCORE_BY_DIMENSION',
  SurveyScoreByDimensionOverTime = 'SURVEY_SCORE_BY_DIMENSION_OVER_TIME',
  TrainingCampaignEnrollments = 'TRAINING_CAMPAIGN_ENROLLMENTS',
  TrainingCampaignSurveys = 'TRAINING_CAMPAIGN_SURVEYS',
  TrainingComparisonByPpp = 'TRAINING_COMPARISON_BY_PPP',
  TrainingComparisonByRiskScore = 'TRAINING_COMPARISON_BY_RISK_SCORE',
  TrainingHours = 'TRAINING_HOURS',
  TrainingInteractionsByGroup = 'TRAINING_INTERACTIONS_BY_GROUP',
  TrainingInteractionsByPurchasedCourse = 'TRAINING_INTERACTIONS_BY_PURCHASED_COURSE',
  TrainingNotStarted = 'TRAINING_NOT_STARTED',
  TrainingStatusesByCampaign = 'TRAINING_STATUSES_BY_CAMPAIGN',
  TrainingStatusByCampaign = 'TRAINING_STATUS_BY_CAMPAIGN',
  TrainingStatusByCourse = 'TRAINING_STATUS_BY_COURSE',
  TrainingStatusByGroup = 'TRAINING_STATUS_BY_GROUP',
  TrainingSurveysByCampaign = 'TRAINING_SURVEYS_BY_CAMPAIGN',
  TrainingSurveysByCourse = 'TRAINING_SURVEYS_BY_COURSE',
  UnphishedUsers = 'UNPHISHED_USERS',
  UsersList = 'USERS_LIST',
  UserCounts = 'USER_COUNTS',
  UserCountByAssessment = 'USER_COUNT_BY_ASSESSMENT',
  UserCountBySurvey = 'USER_COUNT_BY_SURVEY',
  UserPhishingCampaigns = 'USER_PHISHING_CAMPAIGNS',
  UserReportCard = 'USER_REPORT_CARD',
  UserSignIns = 'USER_SIGN_INS',
  UserTrainingCampaigns = 'USER_TRAINING_CAMPAIGNS',
}

export type DownloadsReportConfigFiltersCursor = {
  __typename?: 'DownloadsReportConfigFiltersCursor'
  /** The paginated nodes */
  nodes: Array<Group>
  /** Information about the current paginated set */
  pagination: Pagination
}

export enum DownloadsSortFields {
  Category = 'CATEGORY',
  CreatedAt = 'CREATED_AT',
  DownloadedAt = 'DOWNLOADED_AT',
  GeneratedAt = 'GENERATED_AT',
  Name = 'NAME',
  ViewedAt = 'VIEWED_AT',
}

export enum Durations {
  /** Business days */
  Days = 'DAYS',
  /** months */
  Months = 'MONTHS',
  /** weeks */
  Weeks = 'WEEKS',
}

export type EaiPhishingTemplateAttributes = {
  attachmentType?: Maybe<AidaPhishingTemplateAttachmentTypes>
  attackVec: Array<AidaPhishingTemplateAttackVectors>
  batchId: Scalars['String']
  difficulty?: Maybe<AidaPhishingTemplateDifficulties>
  greeting: AidaPhishingTemplateGreetings
  language: Scalars['String']
  redFlag: Array<AidaPhishingTemplateFlags>
  region: Scalars['String']
  signatureAddress: AidaPhishingTemplateSignatureAddresses
  signatureName: AidaPhishingTemplateSignatureNames
  templateModel?: Maybe<Scalars['String']>
  theme: Scalars['String']
  tone: AidaPhishingTemplateTones
}

export type EaiPhishingTemplateFeedbackAttributes = {
  batchId: Scalars['String']
  feedback: EaiPhishingTemplateFeedbackObjectAttributes
  templateId: Scalars['String']
}

export type EaiPhishingTemplateFeedbackObjectAttributes = {
  feedback: Scalars['String']
  feedbackOptions: Array<Scalars['String']>
  otherFeedback?: Maybe<Scalars['String']>
}

/** Autogenerated return type of EaiPhishingTemplateGenerateTemplate. */
export type EaiPhishingTemplateGenerateTemplatePayload = {
  __typename?: 'EaiPhishingTemplateGenerateTemplatePayload'
  errors?: Maybe<Array<Error>>
  results?: Maybe<Template>
}

export type EaiPhishingTemplateSaveAttributes = {
  batchId: Scalars['String']
  category: Scalars['String']
  kmsatId: Scalars['String']
  savedTemplate: Scalars['String']
  templateId: Scalars['String']
}

/** Autogenerated return type of EaiPhishingTemplateSaveTemplate. */
export type EaiPhishingTemplateSaveTemplatePayload = {
  __typename?: 'EaiPhishingTemplateSaveTemplatePayload'
  errors?: Maybe<Array<Error>>
  results?: Maybe<Save>
}

/** Autogenerated return type of EaiPhishingTemplateSubmitFeedback. */
export type EaiPhishingTemplateSubmitFeedbackPayload = {
  __typename?: 'EaiPhishingTemplateSubmitFeedbackPayload'
  errors?: Maybe<Array<Error>>
  results?: Maybe<Feedback>
}

export enum EaiPhishingTemplateTopicActions {
  Add = 'add',
  Remove = 'remove',
}

export type EaiPhishingTemplateUpdateAttributes = {
  isUsedInCampaign?: Maybe<Scalars['Boolean']>
  templateIds: Array<Scalars['String']>
  topicAction?: Maybe<EaiPhishingTemplateTopicActions>
  topics?: Maybe<Array<Scalars['String']>>
}

/** Autogenerated return type of EaiPhishingTemplateUpdateTemplate. */
export type EaiPhishingTemplateUpdateTemplatePayload = {
  __typename?: 'EaiPhishingTemplateUpdateTemplatePayload'
  errors?: Maybe<Array<Error>>
  results?: Maybe<Update>
}

/** Autogenerated return type of EchelonApiCreateKey. */
export type EchelonApiCreateKeyPayload = {
  __typename?: 'EchelonApiCreateKeyPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<NewKey>
}

/** Autogenerated return type of EchelonApiDeleteKey. */
export type EchelonApiDeleteKeyPayload = {
  __typename?: 'EchelonApiDeleteKeyPayload'
  id?: Maybe<Scalars['Int']>
}

/** Email alias */
export type EmailAlias = Identifier &
  Timestamps & {
    __typename?: 'EmailAlias'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The email address of the alias */
    email: Scalars['String']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Indicates if this is the primary email alias */
    primary?: Maybe<Scalars['Boolean']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

/** Autogenerated return type of EmailAliasesCreate. */
export type EmailAliasesCreatePayload = {
  __typename?: 'EmailAliasesCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created email alias */
  node?: Maybe<EmailAlias>
}

/** Autogenerated return type of EmailAliasesDelete. */
export type EmailAliasesDeletePayload = {
  __typename?: 'EmailAliasesDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The deleted email alias */
  node?: Maybe<EmailAlias>
}

export enum EmailForwardFormats {
  Eml = 'EML',
  Msg = 'MSG',
}

/** Autogenerated return type of EmailTemplateDelete. */
export type EmailTemplateDeletePayload = {
  __typename?: 'EmailTemplateDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Int']>
}

export enum EmailTemplateDifficultyRatings {
  Advanced = 'ADVANCED',
  Basic = 'BASIC',
  Limited = 'LIMITED',
  Moderate = 'MODERATE',
  Significant = 'SIGNIFICANT',
  Unrated = 'UNRATED',
}

/** Email templates for common fields */
export type EmailTemplateInterface = {
  /** Indicates if the email template is archived */
  archived: Scalars['Boolean']
  auditLogDisplay?: Maybe<Scalars['String']>
  auditLogName?: Maybe<Scalars['String']>
  /** The HTML content of the email template */
  contentHtml?: Maybe<Scalars['String']>
  contentHtmlSanitized?: Maybe<Scalars['String']>
  /** The from address of the email template */
  from: Scalars['String']
  /** The display name of the from address of the email template */
  fromDisplayName?: Maybe<Scalars['String']>
  fromDisplayNameSanitized?: Maybe<Scalars['String']>
  /** The from address of the email template with the domain */
  fromWithDomain: Scalars['String']
  /** Indicates if the email template is hidden */
  hidden: Scalars['Boolean']
  /** The language code of the email template */
  languageCode?: Maybe<Scalars['String']>
  markedAsUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The name of the email template */
  name?: Maybe<Scalars['String']>
  /** The reply to address of the email template */
  replyTo?: Maybe<Scalars['String']>
  /** The display name of the reply to address of the email template */
  replyToDisplayName?: Maybe<Scalars['String']>
  /** The subject of the email template */
  subject: Scalars['String']
  subjectSanitized: Scalars['String']
  /** The UUID for the email template translation */
  templateTranslationUuid: Scalars['String']
}

export enum EmailTemplateSortFields {
  CategoryName = 'CATEGORY_NAME',
  Difficulty = 'DIFFICULTY',
  Subject = 'SUBJECT',
  TopicName = 'TOPIC_NAME',
  Updated = 'UPDATED',
}

export enum EmailTemplateTypes {
  CallbackPhishing = 'CALLBACK_PHISHING',
  CallbackPhishingSystem = 'CALLBACK_PHISHING_SYSTEM',
  Community = 'COMMUNITY',
  Managed = 'MANAGED',
  Messages = 'MESSAGES',
  MessagesSystem = 'MESSAGES_SYSTEM',
  PhysicalQr = 'PHYSICAL_QR',
  PhysicalQrSystem = 'PHYSICAL_QR_SYSTEM',
  System = 'SYSTEM',
  Training = 'TRAINING',
  TrainingManaged = 'TRAINING_MANAGED',
  TrainingSystem = 'TRAINING_SYSTEM',
  User = 'USER',
}

export type EmailsSentByGroupCursor = {
  __typename?: 'EmailsSentByGroupCursor'
  columns?: Maybe<Columns>
  nodes: Array<EmailsSentCounts>
  pagination: Pagination
  timeframe?: Maybe<ReportTimeframe>
}

export type EmailsSentByTemplateCursor = {
  __typename?: 'EmailsSentByTemplateCursor'
  columns?: Maybe<Columns>
  nodes: Array<EmailsSentCountsByTemplate>
  pagination: Pagination
  timeframe?: Maybe<ReportTimeframe>
}

export type EmailsSentCounts = {
  __typename?: 'EmailsSentCounts'
  /** The total number of clicked emails */
  clickedCount: Scalars['Int']
  /** The rate of clicks received for the emails */
  clickedRate?: Maybe<Scalars['Float']>
  /** The total number of delivered emails */
  deliveredCount: Scalars['Int']
  /** The total number of user failures for the campaign */
  failureCount: Scalars['Int']
  /** The unique identifier of the email report */
  id?: Maybe<Scalars['Int']>
  /** The name associated with the email report */
  name?: Maybe<Scalars['String']>
  /**
   * The Phish-prone Percentage
   *
   */
  phishPronePercentage?: Maybe<Scalars['Float']>
  /** The total number of reported emails */
  reportedCount: Scalars['Int']
  /** The total number of sent emails */
  sentCount: Scalars['Int']
  /** The count of users the emails were sent to */
  userCount?: Maybe<Scalars['Int']>
}

export type EmailsSentCountsByTemplate = {
  __typename?: 'EmailsSentCountsByTemplate'
  /** The total number of clicked emails */
  clickedCount: Scalars['Int']
  /** The rate of clicks received for the emails */
  clickedRate?: Maybe<Scalars['Float']>
  /** The total number of delivered emails */
  deliveredCount: Scalars['Int']
  /** The total number of user failures for the campaign */
  failureCount: Scalars['Int']
  /** The unique identifier of the email report */
  id?: Maybe<Scalars['Int']>
  /** The name associated with the email report */
  name?: Maybe<Scalars['String']>
  /**
   * The Phish-prone Percentage
   *
   */
  phishPronePercentage?: Maybe<Scalars['Float']>
  /** The total number of reported emails */
  reportedCount: Scalars['Int']
  /** The total number of sent emails */
  sentCount: Scalars['Int']
  /** The count of users the emails were sent to */
  userCount?: Maybe<Scalars['Int']>
}

export type EmailsSentCountsByTemplateCsv = {
  __typename?: 'EmailsSentCountsByTemplateCsv'
  /** The CSV data for the report of emails sent counts by template */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type EmailsSentCountsCsv = {
  __typename?: 'EmailsSentCountsCsv'
  /** CSV formatted data for the email sent counts report */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type EmployeesReceivingDetections = {
  __typename?: 'EmployeesReceivingDetections'
  /** Number of employees receiving at least 1 detection */
  atleast1?: Maybe<Scalars['Int']>
  /** Number of employees receiving more than 1 detection */
  greaterThan1?: Maybe<Scalars['Int']>
  /** Number of employees receiving more than 5 detections */
  greaterThan5?: Maybe<Scalars['Int']>
}

export type EngineStatus = {
  __typename?: 'EngineStatus'
  /** Indicates if the anomaly detection or active directory (AD) engine is active */
  ad_engine: Scalars['Boolean']
  /** Indicates if the alias engine is active */
  alias_engine: Scalars['Boolean']
  /** Indicates if the correlation engine is active */
  correlation_engine: Scalars['Boolean']
  /** The unique identifier of the engine status */
  id: Scalars['Int']
  /** Indicates if the natural language processing (NLP) engine is active */
  nlp_engine: Scalars['Boolean']
  /** The organization identifier associated with the engine status */
  orgId?: Maybe<Scalars['Int']>
  /** Indicates if the rule engine is active */
  rule_engine: Scalars['Boolean']
}

export enum Engines {
  AdEngine = 'ad_engine',
  AliasEngine = 'alias_engine',
  CorrelationEngine = 'correlation_engine',
  NlpEngine = 'nlp_engine',
  RuleEngine = 'rule_engine',
}

/** An enrollment */
export type Enrollment = Identifier &
  Timestamps & {
    __typename?: 'Enrollment'
    /** Whether the enrollment requires policy acknowledgement */
    acknowledgeable: Scalars['Boolean']
    /** Whether the enrollment is currently active */
    active: Scalars['Boolean']
    /** Whether the enrollment is eligible for a certificate */
    certifiable: Scalars['Boolean']
    /** The URL of the completion certificate */
    certificateUrl?: Maybe<Scalars['String']>
    /** Whether the enrollment is completed */
    completed: Scalars['Boolean']
    /** Completion date and time of the enrollment */
    completedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** Whether the enrollment was completed by impersonation */
    completedByImpersonation: Scalars['Boolean']
    /** The completion status of the enrollment */
    completionStatus: EnrollmentCompletionStatuses
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The duration of the enrollment */
    duration?: Maybe<Scalars['Int']>
    /** The item associated with the enrollment */
    enrollmentItem: EnrollmentItem
    /** The type of item associated with the enrollment */
    enrollmentItemType: Scalars['String']
    /** The platforms on which the enrollment is available */
    enrollmentPlatforms?: Maybe<EnrollmentPlatformsActual>
    /** Expiration date and time of the enrollment */
    expiresAt?: Maybe<Scalars['ISO8601DateTime']>
    /** Extended expiration date and time of the enrollment */
    extendedExpiresAt?: Maybe<Scalars['ISO8601DateTime']>
    /** Whether an admin manually passed the user */
    forcePassed: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The last date and time when the enrollment was started */
    lastStartedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The last language used by the user in the enrollment */
    lastUsedLanguage?: Maybe<Scalars['String']>
    /** Ordered list of language options available for the enrollment */
    orderedLanguageOptions: Array<Languages>
    /** The score required to pass the enrollment */
    passingScore?: Maybe<Scalars['Float']>
    /** Whether the enrollment is past due */
    pastDue: Scalars['Boolean']
    /** Whether the enrollment has an extension past due */
    pastDueExtended: Scalars['Boolean']
    /** Whether the policy for the enrollment has been acknowledged */
    policyAcknowledged: Scalars['Boolean']
    /** The date and time when the policy was acknowledged */
    policyAcknowledgedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The policy associated with the enrollment, if any */
    policyEnrollment?: Maybe<PolicyEnrollment>
    /** The score achieved by the user */
    score?: Maybe<Scalars['Float']>
    /** The raw score before any adjustments or scaling */
    scoreRaw?: Maybe<Scalars['Int']>
    /** The score to be displayed to the user */
    scoreToDisplay?: Maybe<Scalars['Float']>
    /** The language selected by the user for the enrollment */
    selectedLanguage?: Maybe<Scalars['String']>
    /** Whether to show the score to the user */
    showScore?: Maybe<Scalars['Boolean']>
    /** Whether the enrollment has been started */
    started: Scalars['Boolean']
    /** Start date and time of the enrollment */
    startedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The state of the enrollment, which may differ from the status */
    state?: Maybe<Scalars['String']>
    /** The overall status of the enrollment */
    status: EnrollmentStatuses
    /** A string representation of the enrollment status */
    statusString: Scalars['String']
    /** The success status of the enrollment */
    successStatus?: Maybe<Scalars['String']>
    /** The survey associated with the enrollment, if any */
    survey?: Maybe<Survey>
    /** The theme settings for the enrollment */
    theme?: Maybe<Scalars['JSON']>
    /** The total time spent by the user in seconds */
    timeSpentInSeconds?: Maybe<Scalars['String']>
    /** The total score achieved in the enrollment */
    totalScore?: Maybe<Scalars['Float']>
    /** Alias for time spent in seconds field */
    totalTime?: Maybe<Scalars['String']>
    /** Whether the course associated with the enrollment is trackable */
    trackableCourse?: Maybe<Scalars['Boolean']>
    /** The training campaign associated with the enrollment, if any */
    trainingCampaign?: Maybe<TrainingCampaign>
    /** The type of the enrollment */
    type: EnrollmentTypes
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The user associated with the enrollment */
    user?: Maybe<User>
  }

/** Autogenerated return type of EnrollmentAcceptUrlPolicy. */
export type EnrollmentAcceptUrlPolicyPayload = {
  __typename?: 'EnrollmentAcceptUrlPolicyPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Enrollment>
}

/** A basic enrollment for a trainee */
export type EnrollmentBasic = Identifier & {
  __typename?: 'EnrollmentBasic'
  /** The completion status of the enrollment */
  completionStatus: EnrollmentCompletionStatuses
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** Basic details related to the policy enrollment */
  policyEnrollment?: Maybe<PolicyEnrollmentBasic>
  /** The total time spent on the enrollment */
  totalTime?: Maybe<Scalars['String']>
}

/** Authentication information required by non-web clients to display enrollments */
export type EnrollmentClientAuth = {
  __typename?: 'EnrollmentClientAuth'
  /** Cookies for CloudFront distribution access */
  cloudfrontCookies: Scalars['JSON']
  /** JSON Web Token for client authentication */
  jwt: Scalars['String']
}

export enum EnrollmentCompletionStatuses {
  Completed = 'COMPLETED',
  Incomplete = 'INCOMPLETE',
  Unknown = 'UNKNOWN',
}

/** Autogenerated return type of EnrollmentCreate. */
export type EnrollmentCreatePayload = {
  __typename?: 'EnrollmentCreatePayload'
  errors?: Maybe<Array<Error>>
  mobileAuthentication?: Maybe<EnrollmentClientAuth>
  node?: Maybe<Enrollment>
}

/** An enrollment event for an enrollment */
export type EnrollmentEvent = Identifier & {
  __typename?: 'EnrollmentEvent'
  /** The unique identifier of the enrollment */
  enrollmentId: Scalars['Int']
  /** The specific event related to enrollment */
  event: EnrollmentEvents
  /** Unique identifier for the object */
  id: Scalars['Int']
}

export enum EnrollmentEvents {
  BtbTestOutFailed = 'BTB_TEST_OUT_FAILED',
  BtbTestOutRequested = 'BTB_TEST_OUT_REQUESTED',
  BtbTestOutSuccess = 'BTB_TEST_OUT_SUCCESS',
  CompletedByImpersonation = 'COMPLETED_BY_IMPERSONATION',
  CsvEnrolled = 'CSV_ENROLLED',
  IndividualEnrolled = 'INDIVIDUAL_ENROLLED',
  OptionalEnrolled = 'OPTIONAL_ENROLLED',
}

/** An enrollment item */
export type EnrollmentItem = KnowledgeRefresher | Policy | PurchasedCourse

/** Enrollment metadata for an event */
export type EnrollmentMetadata = EventMetadataInterface & {
  __typename?: 'EnrollmentMetadata'
  /** Indicates if the enrollment is certifiable */
  certifiable?: Maybe<Scalars['Boolean']>
  /** The completion date of the enrollment */
  completedAt?: Maybe<Scalars['String']>
  /** The duration of the enrollment */
  duration?: Maybe<Scalars['String']>
  /** The ID of the enrollment */
  enrollmentId?: Maybe<Scalars['Int']>
  /** The label of enrollment metadata */
  label?: Maybe<Scalars['String']>
  /** Indicates if the enrollment is past due */
  pastDue?: Maybe<Scalars['Boolean']>
  /** Indicates if the enrollment has been replaced */
  replaced?: Maybe<Scalars['Boolean']>
  /** The reason why the enrollment was replaced */
  replacedReason?: Maybe<Scalars['String']>
  /** The score of the enrollment */
  score?: Maybe<Scalars['Float']>
  /** The start date of the enrollment */
  startedAt?: Maybe<Scalars['String']>
  templateId?: Maybe<Scalars['Int']>
  templateName?: Maybe<Scalars['String']>
  templateType?: Maybe<Scalars['String']>
  templateUuid?: Maybe<Scalars['String']>
  /** The title of enrollment metadata */
  title?: Maybe<Scalars['String']>
  /** Details of the training campaign associated with the enrollment */
  trainingCampaign?: Maybe<TrainingCampaignDetails>
  /** The type of enrollment metadata */
  type?: Maybe<Scalars['String']>
}

export enum EnrollmentPlatformTypes {
  All = 'ALL',
  LxWeb = 'LX_WEB',
  LxWebMobile = 'LX_WEB_MOBILE',
  MobileApp = 'MOBILE_APP',
}

/** Number of enrollment completions per platform */
export type EnrollmentPlatforms = {
  __typename?: 'EnrollmentPlatforms'
  /** Quantitative measure of the Learner Experience on the application platform */
  learnerApp?: Maybe<Scalars['Int']>
  /** Quantitative measure of the Learner Experience on web platform */
  learnerExperience?: Maybe<Scalars['Int']>
  /** Quantitative measure of the Learner Experience on mobile platform */
  learnerExperienceMobile?: Maybe<Scalars['Int']>
}

/** Platform where enrollment was started and completed */
export type EnrollmentPlatformsActual = {
  __typename?: 'EnrollmentPlatformsActual'
  /** The platform where the enrollment was completed */
  completedPlatform?: Maybe<EnrollmentPlatformTypes>
  /** The platform where the enrollment was started */
  startedPlatform?: Maybe<EnrollmentPlatformTypes>
}

export enum EnrollmentScopes {
  Complete = 'complete',
  InProgress = 'in_progress',
  Incomplete = 'incomplete',
  NotStarted = 'not_started',
  PastDue = 'past_due',
}

/** Autogenerated return type of EnrollmentStart. */
export type EnrollmentStartPayload = {
  __typename?: 'EnrollmentStartPayload'
  errors?: Maybe<Array<Error>>
  mobileAuthentication?: Maybe<EnrollmentClientAuth>
  node?: Maybe<Enrollment>
}

export type EnrollmentStats = {
  __typename?: 'EnrollmentStats'
  /** Absolute number of completed enrollments */
  completed: Scalars['Int']
  /** Absolute number of in progress enrollments */
  inProgress: Scalars['Int']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** Absolute number of total enrollments */
  total: Scalars['Int']
}

export enum EnrollmentStatuses {
  Completed = 'COMPLETED',
  Incomplete = 'INCOMPLETE',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED',
}

/** Obsolete global training aggregate table type, this now uses zenka */
export type EnrollmentTotals = Identifier &
  Timestamps & {
    __typename?: 'EnrollmentTotals'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    user?: Maybe<User>
  }

export enum EnrollmentTypes {
  Assessment = 'ASSESSMENT',
  Course = 'COURSE',
  KnowledgeRefresher = 'KNOWLEDGE_REFRESHER',
  Policy = 'POLICY',
}

export enum EnrollmentUpdateActions {
  ForcePass = 'FORCE_PASS',
  Reset = 'RESET',
  SoftDelete = 'SOFT_DELETE',
}

/** Autogenerated return type of EnrollmentUpdate. */
export type EnrollmentUpdatePayload = {
  __typename?: 'EnrollmentUpdatePayload'
  errors?: Maybe<Array<Error>>
  nodes?: Maybe<Array<Enrollment>>
}

/** An environment questionnaire */
export type EnvironmentQuestionnaire = Identifier &
  Timestamps & {
    __typename?: 'EnvironmentQuestionnaire'
    /** Antivirus solutions used in the environment */
    antivirus?: Maybe<Array<EqAntivirusOptions>>
    /** Custom antivirus solution if not listed in the antivirus options */
    antivirusCustom?: Maybe<Scalars['String']>
    /** How audit logs are displayed in the environment */
    auditLogDisplay?: Maybe<Scalars['String']>
    /** Options for collaboration tools used in the environment */
    collaboration?: Maybe<Array<EqCollaborationOptions>>
    /** Custom collaboration tool if not listed in the collaboration options */
    collaborationCustom?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Email security solutions used in the environment */
    emailSecurity?: Maybe<Array<EqEmailSecurityOptions>>
    /** Custom email security solution if not listed in the email security options */
    emailSecurityCustom?: Maybe<Scalars['String']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Learning management systems used in the environment */
    lms?: Maybe<Array<EqLmsOptions>>
    /** Custom LMS if not listed in the LMS options */
    lmsCustom?: Maybe<Scalars['String']>
    /** Network security measures in place in the environment */
    networkSecurity?: Maybe<Array<EqNetworkSecurityOptions>>
    /** Custom network security solution if not listed in the network security options */
    networkSecurityCustom?: Maybe<Scalars['String']>
    /** Other security products used in the environment not covered by previous fields */
    otherSecurityProducts: Scalars['String']
    /** SIEM solutions used */
    siem?: Maybe<Array<EqSiemOptions>>
    /** Custom SIEM solution if not listed in the SIEM options */
    siemCustom?: Maybe<Scalars['String']>
    /** SSO solutions used in the environment */
    sso?: Maybe<Array<EqSsoOptions>>
    /** Custom SSO solution if not listed in the SSO options */
    ssoCustom?: Maybe<Scalars['String']>
    /** Current status of the environment questionnaire */
    status: EqStatus
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** User management solutions used in the environment */
    userManagement?: Maybe<Array<EqUserManagementOptions>>
    /** Custom user management solution if not listed in the user management options */
    userManagementCustom?: Maybe<Scalars['String']>
    /** Web security measures in place in the environment */
    webSecurity?: Maybe<Array<EqWebSecurityOptions>>
    /** Custom web security solution if not listed in the web security options */
    webSecurityCustom?: Maybe<Scalars['String']>
  }

export type EnvironmentQuestionnaireAttributes = {
  /** The antivirus options */
  antivirus?: Maybe<Array<EqAntivirusOptions>>
  /** The custom antivirus option */
  antivirusCustom?: Maybe<Scalars['String']>
  /** The collaboration options */
  collaboration?: Maybe<Array<EqCollaborationOptions>>
  /** The custom collaboration option */
  collaborationCustom?: Maybe<Scalars['String']>
  /** The email security options */
  emailSecurity?: Maybe<Array<EqEmailSecurityOptions>>
  /** The custom email security option */
  emailSecurityCustom?: Maybe<Scalars['String']>
  /** The LMS options */
  lms?: Maybe<Array<EqLmsOptions>>
  /** The custom LMS option */
  lmsCustom?: Maybe<Scalars['String']>
  /** The network security options */
  networkSecurity?: Maybe<Array<EqNetworkSecurityOptions>>
  /** The custom network security option */
  networkSecurityCustom?: Maybe<Scalars['String']>
  /** The other security products */
  otherSecurityProducts?: Maybe<Scalars['String']>
  /** The SIEM options */
  siem?: Maybe<Array<EqSiemOptions>>
  /** The custom SIEM option */
  siemCustom?: Maybe<Scalars['String']>
  /** The SSO options */
  sso?: Maybe<Array<EqSsoOptions>>
  /** The custom SSO option */
  ssoCustom?: Maybe<Scalars['String']>
  /** The user management options */
  userManagement?: Maybe<Array<EqUserManagementOptions>>
  /** The custom user management option */
  userManagementCustom?: Maybe<Scalars['String']>
  /** The web security options */
  webSecurity?: Maybe<Array<EqWebSecurityOptions>>
  /** The custom web security option */
  webSecurityCustom?: Maybe<Scalars['String']>
}

/** Autogenerated return type of EnvironmentQuestionnaireClear. */
export type EnvironmentQuestionnaireClearPayload = {
  __typename?: 'EnvironmentQuestionnaireClearPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The cleared environment questionnaire */
  node?: Maybe<EnvironmentQuestionnaire>
}

/** Autogenerated return type of EnvironmentQuestionnaireSave. */
export type EnvironmentQuestionnaireSavePayload = {
  __typename?: 'EnvironmentQuestionnaireSavePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The saved environment questionnaire */
  node?: Maybe<EnvironmentQuestionnaire>
}

export enum EqAntivirusOptions {
  Bitdefender = 'bitdefender',
  Cisco = 'cisco',
  CrowdstrikeFalcon = 'crowdstrike_falcon',
  EsetProtect = 'eset_protect',
  Forticlient = 'forticlient',
  Kaspersky = 'kaspersky',
  MicrosoftDefender = 'microsoft_defender',
  Na = 'na',
  Other = 'other',
  SentinelOne = 'sentinel_one',
  Sophos = 'sophos',
  ThreatDown = 'threat_down',
  TrendMicro = 'trend_micro',
  Webroot = 'webroot',
}

export enum EqCollaborationOptions {
  Chatter = 'chatter',
  GoogleChat = 'google_chat',
  MondayCom = 'monday_com',
  Na = 'na',
  Other = 'other',
  Slack = 'slack',
  Teams = 'teams',
  Webex = 'webex',
  Workplace = 'workplace',
  Workvivo = 'workvivo',
  Yammer = 'yammer',
  Zoom = 'zoom',
}

export enum EqEmailSecurityOptions {
  Abnormal = 'abnormal',
  Barracuda = 'barracuda',
  Cisco = 'cisco',
  Cloudflare = 'cloudflare',
  Forcepoint = 'forcepoint',
  Fortimail = 'fortimail',
  Google = 'google',
  Microsoft = 'microsoft',
  Mimecast = 'mimecast',
  Na = 'na',
  Other = 'other',
  Proofpoint = 'proofpoint',
  Symantec = 'symantec',
  Virtru = 'virtru',
}

export enum EqLmsOptions {
  Blackboard = 'blackboard',
  Canvas = 'canvas',
  Cornerstone = 'cornerstone',
  GoogleClassroom = 'google_classroom',
  Knowbe4 = 'knowbe4',
  MicrosoftTeamsForEducation = 'microsoft_teams_for_education',
  Moodle = 'moodle',
  Na = 'na',
  Other = 'other',
  SapLitmos = 'sap_litmos',
  SapSuccessfactors = 'sap_successfactors',
  Workday = 'workday',
}

export enum EqNetworkSecurityOptions {
  Bitdefender = 'bitdefender',
  Cisco = 'cisco',
  CrowdstrikeFalcon = 'crowdstrike_falcon',
  Cylance = 'cylance',
  Darktrace = 'darktrace',
  Eset = 'eset',
  Fortinet = 'fortinet',
  MicrosoftDefender = 'microsoft_defender',
  Na = 'na',
  Other = 'other',
  SentinalOne = 'sentinal_one',
  Sophos = 'sophos',
  Symantec = 'symantec',
  Trellix = 'trellix',
}

export enum EqSiemOptions {
  ChronicleSoar = 'chronicle_soar',
  MicrosoftSentinel = 'microsoft_sentinel',
  Na = 'na',
  Other = 'other',
  PaloAlto = 'palo_alto',
  Phisher = 'phisher',
  Proofpoint = 'proofpoint',
  Solarwinds = 'solarwinds',
  Splunk = 'splunk',
  SumoLogic = 'sumo_logic',
  Swimlane = 'swimlane',
  Tines = 'tines',
}

export enum EqSsoOptions {
  Aws = 'aws',
  Citrix = 'citrix',
  Duo = 'duo',
  Google = 'google',
  Ibm = 'ibm',
  Jumpcloud = 'jumpcloud',
  Microsoft = 'microsoft',
  Na = 'na',
  Okta = 'okta',
  Other = 'other',
  Rippling = 'rippling',
  Salesforce = 'salesforce',
}

export enum EqStatus {
  Completed = 'completed',
  Incomplete = 'incomplete',
}

export enum EqUserManagementOptions {
  BambooHr = 'bamboo_hr',
  Cyberark = 'cyberark',
  GoogleWorkspace = 'google_workspace',
  Ibm = 'ibm',
  Jumpcloud = 'jumpcloud',
  MicrosoftActiveDirectory = 'microsoft_active_directory',
  MicrosoftEntraId = 'microsoft_entra_id',
  Na = 'na',
  Okta = 'okta',
  Onelogin = 'onelogin',
  Oracle = 'oracle',
  Other = 'other',
  Rippling = 'rippling',
  Sailpoint = 'sailpoint',
}

export enum EqWebSecurityOptions {
  Barracuda = 'barracuda',
  Cisco = 'cisco',
  Cloudflare = 'cloudflare',
  DnsFilter = 'dns_filter',
  Forcepoint = 'forcepoint',
  Fortinet = 'fortinet',
  Kaspersky = 'kaspersky',
  Na = 'na',
  Netskope = 'netskope',
  Other = 'other',
  PaloAlto = 'palo_alto',
  Sophos = 'sophos',
  Symantec = 'symantec',
}

export type Error = {
  __typename?: 'Error'
  /** The field associated with the error, if applicable */
  field: Scalars['String']
  /** A JSON object containing dynamic content for error messages */
  placeholders?: Maybe<Scalars['JSON']>
  /** A textual explanation of the error */
  reason: Scalars['String']
  /** The ID of the record related to this error, if applicable */
  recordId?: Maybe<Scalars['Int']>
}

/** Event type */
export type Event = {
  __typename?: 'Event'
  description?: Maybe<Scalars['String']>
  /** The unique identifier of the event */
  id: Scalars['BigInt']
  /** The name of the event */
  name?: Maybe<Scalars['String']>
}

export enum EventComparisons {
  Equal = 'EQUAL',
  GreaterThan = 'GREATER_THAN',
  LessThan = 'LESS_THAN',
}

/** fields common to various event metadata */
export type EventMetadataInterface = {
  templateId?: Maybe<Scalars['Int']>
  templateName?: Maybe<Scalars['String']>
  templateType?: Maybe<Scalars['String']>
  templateUuid?: Maybe<Scalars['String']>
}

/** Event pagination metadata */
export type EventPagination = {
  __typename?: 'EventPagination'
  /** The current page number being viewed */
  page: Scalars['Int']
  /** The total number of pages available for pagination */
  pages: Scalars['Int']
  /** The number of events displayed per page */
  per: Scalars['Int']
  /** The total number of events available */
  totalCount: Scalars['Int']
}

export type EventQueryArguments = {
  endDate?: Maybe<Scalars['ISO8601Date']>
  eventType?: Maybe<Array<Scalars['String']>>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  rangeType?: Maybe<Scalars['String']>
  source?: Maybe<Array<Scalars['String']>>
  startDate?: Maybe<Scalars['ISO8601Date']>
  userId: Scalars['Int']
}

/** Event source */
export type EventSource = {
  __typename?: 'EventSource'
  /** The unique identifier of the event source */
  id: Scalars['Int']
  /** The name of the event source */
  name?: Maybe<Scalars['String']>
}

export enum EventStatuses {
  Created = 'CREATED',
  Resolved = 'RESOLVED',
  Reviewed = 'REVIEWED',
}

/** Fields common between Ksat and external event types */
export type EventTypeInterface = {
  description?: Maybe<Scalars['String']>
  id: Scalars['BigInt']
}

/** Event type union */
export type EventTypeUnion =
  | ExternalEvent
  | KmsatEvent
  | PiqEchelonEvent
  | SecurityCoachEvent

export enum EventTypes {
  InitialScan = 'INITIAL_SCAN',
  StatusChange = 'STATUS_CHANGE',
}

/** Event user */
export type EventUser = {
  __typename?: 'EventUser'
  /** Indicates if the event user is archived */
  archived: Scalars['Boolean']
  /** The email of the event user */
  email: Scalars['String']
  /** The unique identifier of the event user */
  id: Scalars['Int']
}

export type Ever = {
  __typename?: 'Ever'
  /** Indicates if the criterion applies for any time frame */
  ever: Scalars['Boolean']
}

export type ExecReportsConfigurationsPhishingActivities = {
  __typename?: 'ExecReportsConfigurationsPhishingActivities'
  /** The IDs of the accounts to include in the phishing activities report */
  accountIds?: Maybe<Array<Scalars['Int']>>
  /** The aggregation method of the phishing activities data */
  aggregation: Scalars['String']
  /** The date interval for the phishing activities report */
  dateInterval?: Maybe<Scalars['String']>
  description: Scalars['String']
  /** Flag to disable the "None" placeholder in the category field */
  disableNonePlaceholderCategory?: Maybe<Scalars['Boolean']>
  /** Flag to disable the "None" placeholder in the group by field */
  disableNonePlaceholderGroupBy?: Maybe<Scalars['Boolean']>
  /** The end date for the phishing activities report */
  endDate?: Maybe<Scalars['ISO8601DateTime']>
  /** The type of graph to display the phishing activities data */
  graphType: Scalars['String']
  /** The attribute to group the phishing activities by */
  groupBy: Scalars['String']
  /** Flag to enable filtering based on group selections */
  groupFiltersEnabled?: Maybe<Scalars['Boolean']>
  /** The IDs of the groups to include in the phishing activities report */
  groupIds?: Maybe<Array<Scalars['Int']>>
  /** Flag to include or exclude archived users in the report */
  includeArchivedUsers: Scalars['Boolean']
  /** The position of the legend in the phishing activities graph */
  legendPosition: Scalars['String']
  /** Flag to override the default report date settings */
  overrideReportDateSettings?: Maybe<Scalars['Boolean']>
  /** Flag to override the default report group settings */
  overrideReportGroupSettings?: Maybe<Scalars['Boolean']>
  /** Flag to show margins for the category in the phishing activities report */
  showMarginsCategory?: Maybe<Scalars['Boolean']>
  /** Flag to show margins for the group by in the phishing activities report */
  showMarginsGroupBy?: Maybe<Scalars['Boolean']>
  /** The start date for the phishing activities report */
  startDate?: Maybe<Scalars['ISO8601DateTime']>
  /** The title of the Phishing Metrics widget */
  title: Scalars['String']
  /** The top N entities to include in the phishing activities report */
  topN?: Maybe<Scalars['Int']>
  /** The type of executive report widget */
  type: ExecReportsWidget
  /** The data field used for the x-axis of the phishing activities graph */
  xAxis: Scalars['String']
}

export type ExecReportsConfigurationsRiskScore = {
  __typename?: 'ExecReportsConfigurationsRiskScore'
  /** The IDs of the accounts to include in the report */
  accountIds?: Maybe<Array<Scalars['Int']>>
  /** The aggregation method for the Risk Score report */
  aggregation: Scalars['String']
  /** The date interval for data aggregation */
  dateInterval?: Maybe<Scalars['String']>
  description: Scalars['String']
  /** Whether to disable the "None" placeholder in category options */
  disableNonePlaceholderCategory?: Maybe<Scalars['Boolean']>
  /** Whether to disable the "None" placeholder in group by options */
  disableNonePlaceholderGroupBy?: Maybe<Scalars['Boolean']>
  /** The end date for the report data */
  endDate?: Maybe<Scalars['ISO8601DateTime']>
  /** The type of graph to use for displaying the Risk Score */
  graphType: Scalars['String']
  /** The parameter to group data by in the report */
  groupBy: Scalars['String']
  /** Whether group filters are enabled */
  groupFiltersEnabled?: Maybe<Scalars['Boolean']>
  /** The IDs of the groups to include in the report */
  groupIds?: Maybe<Array<Scalars['Int']>>
  /** Whether to include archived users in the report */
  includeArchivedUsers: Scalars['Boolean']
  /** The position of the graph legend */
  legendPosition: Scalars['String']
  /** Whether to override the report date settings */
  overrideReportDateSettings?: Maybe<Scalars['Boolean']>
  /** Whether to override the report group settings */
  overrideReportGroupSettings?: Maybe<Scalars['Boolean']>
  /** Whether to show margins for categories */
  showMarginsCategory?: Maybe<Scalars['Boolean']>
  /** Whether to show margins for group by options */
  showMarginsGroupBy?: Maybe<Scalars['Boolean']>
  /** The start date for the report data */
  startDate?: Maybe<Scalars['ISO8601DateTime']>
  /** The title of the Risk Score Trend widget */
  title: Scalars['String']
  /** The number of top items to display in the report */
  topN?: Maybe<Scalars['Int']>
  /** The type of executive report widget */
  type: ExecReportsWidget
  /** The label for the x-axis of the graph */
  xAxis: Scalars['String']
}

export type ExecReportsConfigurationsText = {
  __typename?: 'ExecReportsConfigurationsText'
  /** The content of the Text Block widget in the executive report */
  content: Scalars['String']
  description: Scalars['String']
  /** The title of the Text Block widget in the executive report */
  title: Scalars['String']
  /** The type of widget in the executive report */
  type: ExecReportsWidget
}

export type ExecReportsConfigurationsTitle = {
  __typename?: 'ExecReportsConfigurationsTitle'
  /** The content of the Text Block widget in the executive report */
  content: Scalars['String']
  description: Scalars['String']
  /** Indicates whether the logo is shown in the title configuration */
  showLogo: Scalars['Boolean']
  /** The title of the Text Block widget in the executive report */
  title: Scalars['String']
  /** The type of widget in the executive report */
  type: ExecReportsWidget
}

export type ExecReportsConfigurationsTrainingActivities = {
  __typename?: 'ExecReportsConfigurationsTrainingActivities'
  /** The specific account IDs to include in the data */
  accountIds?: Maybe<Array<Scalars['Int']>>
  /** The aggregation method of the data */
  aggregation: Scalars['String']
  /** The interval between dates for the data aggregation */
  dateInterval?: Maybe<Scalars['String']>
  description: Scalars['String']
  /** Whether to disable placeholder for uncategorized data */
  disableNonePlaceholderCategory?: Maybe<Scalars['Boolean']>
  /** Whether to disable placeholder for ungrouped data */
  disableNonePlaceholderGroupBy?: Maybe<Scalars['Boolean']>
  /** The end date for the data selection */
  endDate?: Maybe<Scalars['ISO8601DateTime']>
  /** The type of graph to be displayed */
  graphType: Scalars['String']
  /** The category by which the data is grouped */
  groupBy: Scalars['String']
  /** Whether group filters are enabled for the report */
  groupFiltersEnabled?: Maybe<Scalars['Boolean']>
  /** The specific group IDs to include in the data */
  groupIds?: Maybe<Array<Scalars['Int']>>
  /** Whether to include archived users in the data */
  includeArchivedUsers: Scalars['Boolean']
  /** The position of the legend in the graph */
  legendPosition: Scalars['String']
  /** Whether to override the default report date settings */
  overrideReportDateSettings?: Maybe<Scalars['Boolean']>
  /** Whether to override the default report group settings */
  overrideReportGroupSettings?: Maybe<Scalars['Boolean']>
  /** Whether to show margins for categories */
  showMarginsCategory?: Maybe<Scalars['Boolean']>
  /** Whether to show margins for groupings */
  showMarginsGroupBy?: Maybe<Scalars['Boolean']>
  /** The start date for the data selection */
  startDate?: Maybe<Scalars['ISO8601DateTime']>
  /** The title of the Training Metrics widget */
  title: Scalars['String']
  /** The number of top records to display */
  topN?: Maybe<Scalars['Int']>
  /** The criteria for selecting training campaigns */
  trainingCampaignCriteria?: Maybe<Scalars['String']>
  /** The specific training campaign IDs to include in the report */
  trainingCampaignIds?: Maybe<Array<Maybe<Scalars['Int']>>>
  /** The type of executive report widget */
  type: ExecReportsWidget
  /** The variable displayed on the x-axis of the graph */
  xAxis: Scalars['String']
}

/** Autogenerated return type of ExecReportsDashboardCreate. */
export type ExecReportsDashboardCreatePayload = {
  __typename?: 'ExecReportsDashboardCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ExecReportsExecReportsDashboard>
}

/** Autogenerated return type of ExecReportsDashboardDelete. */
export type ExecReportsDashboardDeletePayload = {
  __typename?: 'ExecReportsDashboardDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ExecReportsExecReportsDashboard>
}

/** Autogenerated return type of ExecReportsDashboardUpdate. */
export type ExecReportsDashboardUpdatePayload = {
  __typename?: 'ExecReportsDashboardUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ExecReportsExecReportsDashboard>
}

export type ExecReportsDashboardWidget = {
  accountIds?: Maybe<Array<Scalars['Int']>>
  aggregation?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  dateInterval?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  disableNonePlaceholderCategory?: Maybe<Scalars['Boolean']>
  disableNonePlaceholderGroupBy?: Maybe<Scalars['Boolean']>
  endColumn: Scalars['Int']
  endDate?: Maybe<Scalars['ISO8601DateTime']>
  endRow: Scalars['Int']
  graphType?: Maybe<Scalars['String']>
  groupBy?: Maybe<Scalars['String']>
  groupFiltersEnabled?: Maybe<Scalars['Boolean']>
  groupIds?: Maybe<Array<Scalars['Int']>>
  includeArchivedUsers?: Maybe<Scalars['Boolean']>
  legendPosition?: Maybe<Scalars['String']>
  overrideReportDateSettings?: Maybe<Scalars['Boolean']>
  overrideReportGroupSettings?: Maybe<Scalars['Boolean']>
  scaleFactor?: Maybe<Scalars['Int']>
  showLogo?: Maybe<Scalars['Boolean']>
  showMarginsCategory?: Maybe<Scalars['Boolean']>
  showMarginsGroupBy?: Maybe<Scalars['Boolean']>
  startColumn: Scalars['Int']
  startDate?: Maybe<Scalars['ISO8601DateTime']>
  startRow: Scalars['Int']
  title?: Maybe<Scalars['String']>
  topN?: Maybe<Scalars['Int']>
  trainingCampaignCriteria?: Maybe<Scalars['String']>
  trainingCampaignIds?: Maybe<Array<Maybe<Scalars['Int']>>>
  type: ExecReportsWidget
  xAxis?: Maybe<Scalars['String']>
}

export type ExecReportsDownloadMetadata =
  | ExecReportsPdfConfiguration
  | PasswordIqCsvConfiguration
  | PasswordIqPdfConfiguration

export type ExecReportsExecReport = {
  __typename?: 'ExecReportsExecReport'
  /** The date and time when the report was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The unique identifier of the creator of the report */
  creatorId: Scalars['Int']
  /** Type of the creator of the report */
  creatorType: Scalars['String']
  /** The unique identifier of the dashboard associated with this report */
  dashboardId: Scalars['String']
  /** The name of the dashboard associated with this report */
  dashboardName?: Maybe<Scalars['String']>
  /** The unique identifier of the executive report */
  id?: Maybe<Scalars['ID']>
  /** The name of the executive report */
  name?: Maybe<Scalars['String']>
  /** Type of the owner of the report */
  ownerType: Scalars['String']
  /** The date and time when the report was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>
}

export type ExecReportsExecReportsDashboard = {
  __typename?: 'ExecReportsExecReportsDashboard'
  /** IDs of accounts associated with the executive report dashboard */
  accountIds?: Maybe<Array<Scalars['Int']>>
  /** Display options for the audit log within the executive report dashboard */
  auditLogDisplay?: Maybe<Scalars['String']>
  /** The date and time when the executive report dashboard was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The creator of the executive report dashboard */
  creator: Actor
  /** Font color used in the executive report dashboard */
  fontColor?: Maybe<Scalars['String']>
  /** Font size used in the executive report dashboard */
  fontSize?: Maybe<Scalars['String']>
  /** Font style used in the executive report dashboard */
  fontStyle?: Maybe<Scalars['String']>
  /** IDs of groups associated with the executive report dashboard */
  groupIds?: Maybe<Array<Scalars['Int']>>
  /** Unique identifier of the executive report dashboard */
  id: Scalars['Int']
  /** URL of the logo displayed on the executive report dashboard */
  logoUrl?: Maybe<Scalars['String']>
  /** Name of the executive report dashboard */
  name: Scalars['String']
  /** Type of PDF generated for the executive report dashboard */
  pdfType?: Maybe<Scalars['String']>
  /** Timeframe for the data presented in the executive report dashboard */
  timeframe?: Maybe<ReportTimeframe>
  /** The date and time when the executive report dashboard was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Widgets included in the executive report dashboard */
  widgets: Array<ExecReportsExecReportsDashboardWidget>
}

export type ExecReportsExecReportsDashboardWidget = {
  __typename?: 'ExecReportsExecReportsDashboardWidget'
  /** The date and time when the widget was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The configuration of the executive report widget */
  execReportsWidgetConfiguration?: Maybe<ExecReportsWidgetConfigurations>
  /** The height of the widget in grid units */
  height: Scalars['Int']
  /** Unique identifier of the dashboard widget */
  id?: Maybe<Scalars['ID']>
  /** The scale factor of the widget for zooming in and out */
  scaleFactor?: Maybe<Scalars['Int']>
  /** The starting column position of the widget on the dashboard */
  startColumn: Scalars['Int']
  /** The starting row position of the widget on the dashboard */
  startRow: Scalars['Int']
  /** The date and time when the widget was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The width of the widget in grid units */
  width: Scalars['Int']
}

export type ExecReportsExecReportsDashboards = {
  __typename?: 'ExecReportsExecReportsDashboards'
  /** A collection of executive report dashboards */
  dashboards: Array<ExecReportsExecReportsDashboard>
  /** A token used for Zenka integration */
  zenkaToken?: Maybe<Scalars['String']>
}

export type ExecReportsExecReportsWidgetsConfiguration = {
  __typename?: 'ExecReportsExecReportsWidgetsConfiguration'
  /** The content of the executive report widget */
  content: Scalars['String']
  description: Scalars['String']
  /** The title of the executive report widget */
  title: Scalars['String']
  /** Criteria used to select training campaigns for the report */
  trainingCampaignCriteria?: Maybe<Scalars['String']>
  /** IDs of the training campaigns included in the report */
  trainingCampaignIds?: Maybe<Array<Maybe<Scalars['Int']>>>
  /** The type of the executive report widget */
  type: ExecReportsWidget
}

/** Autogenerated return type of ExecReportsPDF. */
export type ExecReportsPdfPayload = {
  __typename?: 'ExecReportsPDFPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Download>
}

export type ExecReportsPdf = {
  dashboardId?: Maybe<Scalars['String']>
}

export type ExecReportsPdfConfiguration = {
  __typename?: 'ExecReportsPdfConfiguration'
  /** The name of the dashboard associated with the PDF configuration */
  dashboardName?: Maybe<Scalars['String']>
  /** A collection of widgets configurations for the report */
  widgets: Array<ExecReportsExecReportsWidgetsConfiguration>
}

/** Executive reports Phishing Metrics widget Data */
export type ExecReportsPhishingActivitiesData = {
  __typename?: 'ExecReportsPhishingActivitiesData'
  /** The data aggregation method used in the report */
  aggregation: Scalars['String']
  /** The date interval for the report data */
  dateInterval?: Maybe<Scalars['String']>
  description: Scalars['String']
  /** Disables the use of "none" as a placeholder in the category settings */
  disableNonePlaceholderCategory?: Maybe<Scalars['Boolean']>
  /** Disables the use of "none" as a placeholder in the group by settings */
  disableNonePlaceholderGroupBy?: Maybe<Scalars['Boolean']>
  /** The end date for the report data */
  endDate?: Maybe<Scalars['ISO8601DateTime']>
  /** The type of graph to display the report data */
  graphType: Scalars['String']
  /** The attribute used to group data in the report */
  groupBy: Scalars['String']
  /** Indicates if group filters are enabled for the report */
  groupFiltersEnabled?: Maybe<Scalars['Boolean']>
  /** The IDs of the groups to include in the report */
  groupIds?: Maybe<Array<Scalars['Int']>>
  /** Indicates if archived users should be included in the report */
  includeArchivedUsers: Scalars['Boolean']
  /** The position of the legend in the report graph */
  legendPosition: Scalars['String']
  /** Indicates if the default report date settings are overridden */
  overrideReportDateSettings?: Maybe<Scalars['Boolean']>
  /** Indicates if the default report group settings are overridden */
  overrideReportGroupSettings?: Maybe<Scalars['Boolean']>
  /** Indicates if margins are shown for categories in the report */
  showMarginsCategory?: Maybe<Scalars['Boolean']>
  /** Indicates if margins are shown for group by attributes in the report */
  showMarginsGroupBy?: Maybe<Scalars['Boolean']>
  /** The start date for the report data */
  startDate?: Maybe<Scalars['ISO8601DateTime']>
  /** The title of the phishing activity report */
  title: Scalars['String']
  /** Limits the report data to the top N results */
  topN?: Maybe<Scalars['Int']>
  /** The attribute used for the x-axis in the report graph */
  xAxis: Scalars['String']
}

/** Report download */
export type ExecReportsReport = {
  __typename?: 'ExecReportsReport'
  /** The category of the downloadable report */
  category: DownloadablesCategories
  /** The configuration details of the report */
  configuration: ExecReportsDownloadMetadata
  /** The creation timestamp of the report */
  createdAt: Scalars['ISO8601DateTime']
  /** The unique identifier of the report */
  id: Scalars['Int']
  /** The user who is impersonating, if applicable */
  impersonatingUser?: Maybe<User>
  /** The user that shared the report */
  sharedBy?: Maybe<Scalars['String']>
  /** The type of the downloadable report */
  type: DownloadablesTypes
  /** The last update timestamp of the report */
  updatedAt: Scalars['ISO8601DateTime']
}

/** Executive reports Risk Score widget data */
export type ExecReportsRiskScoreData = {
  __typename?: 'ExecReportsRiskScoreData'
  /** Aggregation method used in the report */
  aggregation: Scalars['String']
  /** The date interval for the report data */
  dateInterval?: Maybe<Scalars['String']>
  description: Scalars['String']
  /** Disable the "none" placeholder in category selections */
  disableNonePlaceholderCategory?: Maybe<Scalars['Boolean']>
  /** Disable the "none" placeholder in group by selections */
  disableNonePlaceholderGroupBy?: Maybe<Scalars['Boolean']>
  /** End date for the report data */
  endDate?: Maybe<Scalars['ISO8601DateTime']>
  /** The type of graph used in the report */
  graphType: Scalars['String']
  /** The criteria used to group data in the report */
  groupBy: Scalars['String']
  /** Whether group filters are enabled in the report */
  groupFiltersEnabled?: Maybe<Scalars['Boolean']>
  /** Filter the report results by group IDs */
  groupIds?: Maybe<Array<Scalars['Int']>>
  /** Whether to include archived users in the report */
  includeArchivedUsers: Scalars['Boolean']
  /** Position of the legend in the report graph */
  legendPosition: Scalars['String']
  /** Whether to override default report date settings */
  overrideReportDateSettings?: Maybe<Scalars['Boolean']>
  /** Whether to override default report group settings */
  overrideReportGroupSettings?: Maybe<Scalars['Boolean']>
  /** Indicates if margins are shown for categories in the report */
  showMarginsCategory?: Maybe<Scalars['Boolean']>
  /** Indicates if margins are shown for group by attributes in the report */
  showMarginsGroupBy?: Maybe<Scalars['Boolean']>
  /** Start date for the report data */
  startDate?: Maybe<Scalars['ISO8601DateTime']>
  /** The title of the Risk Score report */
  title: Scalars['String']
  /** Limit the report to top N results */
  topN?: Maybe<Scalars['Int']>
  /** The label for the x-axis in the report graph */
  xAxis: Scalars['String']
}

/** Executive reports Text Block widget data */
export type ExecReportsTextData = {
  __typename?: 'ExecReportsTextData'
  /** The content of the text data */
  content: Scalars['String']
  description: Scalars['String']
  /** The title of the text data */
  title: Scalars['String']
}

/** Executive reports Title and Logo widget data */
export type ExecReportsTitleData = {
  __typename?: 'ExecReportsTitleData'
  /** The content of the report */
  content: Scalars['String']
  description: Scalars['String']
  /** The URL of the logo to display if show logo is true */
  logoUrl?: Maybe<Scalars['String']>
  /** Indicates if the logo should be shown */
  showLogo?: Maybe<Scalars['Boolean']>
  /** The title of the report */
  title: Scalars['String']
}

/** Executive reports Training Metrics widget data */
export type ExecReportsTrainingActivitiesData = {
  __typename?: 'ExecReportsTrainingActivitiesData'
  /** The aggregation method used in the training activities report */
  aggregation: Scalars['String']
  /** The date interval for the training activities report */
  dateInterval?: Maybe<Scalars['String']>
  description: Scalars['String']
  /** Flag to disable the none placeholder for category in the training activities report */
  disableNonePlaceholderCategory?: Maybe<Scalars['Boolean']>
  /** Flag to disable the none placeholder for group by in the training activities report */
  disableNonePlaceholderGroupBy?: Maybe<Scalars['Boolean']>
  /** The end date for the training activities report period */
  endDate?: Maybe<Scalars['ISO8601DateTime']>
  /** The type of graph to be displayed for the training activities report */
  graphType: Scalars['String']
  /** The parameter on which the training activities data is grouped by */
  groupBy: Scalars['String']
  /** Flag indicating if group filters are enabled in the training activities report */
  groupFiltersEnabled?: Maybe<Scalars['Boolean']>
  /** The IDs of the groups included in the training activities report */
  groupIds?: Maybe<Array<Scalars['Int']>>
  /** Flag to either include or exclude archived users in the training activities report */
  includeArchivedUsers: Scalars['Boolean']
  /** The position of the legend on the training activities report graph */
  legendPosition: Scalars['String']
  /** Flag to override default date settings for the training activities report */
  overrideReportDateSettings?: Maybe<Scalars['Boolean']>
  /** Flag to override default group settings for the training activities report */
  overrideReportGroupSettings?: Maybe<Scalars['Boolean']>
  /** Flag to show or hide margins for category in the training activities report */
  showMarginsCategory?: Maybe<Scalars['Boolean']>
  /** Flag to show or hide margins for group by in the training activities report */
  showMarginsGroupBy?: Maybe<Scalars['Boolean']>
  /** The start date for the training activities report period */
  startDate?: Maybe<Scalars['ISO8601DateTime']>
  /** The title of the training activities report */
  title: Scalars['String']
  /** The number of top performers to display in the training activities report */
  topN?: Maybe<Scalars['Int']>
  /** The criteria for the training campaigns included in the training activities report */
  trainingCampaignCriteria?: Maybe<Scalars['String']>
  /** The IDs of the training campaigns included in the training activities report */
  trainingCampaignIds?: Maybe<Array<Maybe<Scalars['Int']>>>
  /** The x-axis parameter for the training activities report graph */
  xAxis: Scalars['String']
}

export enum ExecReportsWidget {
  ExecReportsPhishingActivitiesWidget = 'ExecReportsPhishingActivitiesWidget',
  ExecReportsRiskScoreWidget = 'ExecReportsRiskScoreWidget',
  ExecReportsTextWidget = 'ExecReportsTextWidget',
  ExecReportsTitleWidget = 'ExecReportsTitleWidget',
  ExecReportsTrainingActivitiesWidget = 'ExecReportsTrainingActivitiesWidget',
}

/** A widget configuration type */
export type ExecReportsWidgetConfigurations =
  | ExecReportsConfigurationsPhishingActivities
  | ExecReportsConfigurationsRiskScore
  | ExecReportsConfigurationsText
  | ExecReportsConfigurationsTitle
  | ExecReportsConfigurationsTrainingActivities

export type ExportUnmappedEventsMessage = {
  __typename?: 'ExportUnmappedEventsMessage'
  /** Indicates if the export unmapped events message is queued */
  queued?: Maybe<Scalars['Boolean']>
}

export enum Extensions {
  /** Word Document With Macro (DOC) */
  Docm = 'DOCM',
  /** Word Document With Macro (DOCM) */
  Docm2 = 'DOCM2',
  /** Word Document */
  Docx = 'DOCX',
  /** HTML */
  Html = 'HTML',
  /** HTML with link */
  Htmla = 'HTMLA',
  /** PDF */
  Pdf = 'PDF',
  /** Powerpoint Document With Macro */
  Ppsm = 'PPSM',
  /** Powerpoint Document */
  Pptx = 'PPTX',
  /** Excel Document With Macro (XLS) */
  Xlsm = 'XLSM',
  /** Excel Document With Macro (XLSM) */
  Xlsm2 = 'XLSM2',
  /** Excel Document */
  Xlsx = 'XLSX',
  /** Zipped Word Document With Macro */
  Zipdocm = 'ZIPDOCM',
  /** Zipped Word Document */
  Zipdocx = 'ZIPDOCX',
  /** Zipped PDF */
  Zippdf = 'ZIPPDF',
  /** Zipped Powerpoint Document With Macro */
  Zipppsm = 'ZIPPPSM',
  /** Zipped Powerpoint Document */
  Zippptx = 'ZIPPPTX',
  /** Zipped Excel Document With Macro */
  Zipxlsm = 'ZIPXLSM',
  /** Zipped Excel Document */
  Zipxlsx = 'ZIPXLSX',
}

/** External user event type */
export type ExternalEvent = EventTypeInterface & {
  __typename?: 'ExternalEvent'
  description?: Maybe<Scalars['String']>
  /** The name of the external event */
  externalName: Scalars['String']
  id: Scalars['BigInt']
}

export type FailureTypeByUserCount = {
  __typename?: 'FailureTypeByUserCount'
  /** CSV format of the report */
  csv: Scalars['String']
  /** Data of failure types and user counts */
  data: Array<FailureTypeUserCountsData>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type FailureTypeUserCountsData = {
  __typename?: 'FailureTypeUserCountsData'
  /** Failures count categorized by failure types for the group */
  failures: FailureTypesCount
  /** Name of the group for which failure counts are reported */
  groupName: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type FailureTypesBarChart = {
  __typename?: 'FailureTypesBarChart'
  /** CSV data representation for the chart */
  csv: Scalars['String']
  /** The current PPP value used in the failure types bar chart */
  currentPpp: Scalars['Float']
  /** A series of data points for the failure types bar chart */
  series: Array<FailureTypesBarChartSeries>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type FailureTypesBarChartSeries = {
  __typename?: 'FailureTypesBarChartSeries'
  /** The data for the failure types bar chart series */
  data: Array<FailureTypesBarChartSeriesData>
  /** The date associated with the failure types data */
  date?: Maybe<Scalars['ISO8601Date']>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type FailureTypesBarChartSeriesData = {
  __typename?: 'FailureTypesBarChartSeriesData'
  /** The number of times attachments were opened */
  attachmentOpen?: Maybe<Scalars['Int']>
  /** The number of times a callback phishingnumber was called */
  callbackCalled?: Maybe<Scalars['Int']>
  /** The number of callback phishing campaigns that transmitted data */
  callbackData?: Maybe<Scalars['Int']>
  /** The number of clicks recorded */
  clicks?: Maybe<Scalars['Int']>
  /** The number of times data was entered */
  dataEntered?: Maybe<Scalars['Int']>
  /** The number of deliveries */
  delivered?: Maybe<Scalars['Int']>
  /** The number of times macros were enabled in documents */
  macroEnabled?: Maybe<Scalars['Int']>
  /** The name of the failure type */
  name: Scalars['String']
  /** The Phish-prone Percentage */
  ppp: Scalars['String']
  /** The number of times QR codes were scanned */
  qrCodeScanned?: Maybe<Scalars['Int']>
  /** The number of times a reply was made */
  replied?: Maybe<Scalars['Int']>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type FailureTypesCount = {
  __typename?: 'FailureTypesCount'
  /** The number of times attachments were opened */
  attachmentOpen?: Maybe<Scalars['Int']>
  /** The number of times a callback phishing number was called */
  callbackCalled?: Maybe<Scalars['Int']>
  /** The number of times callback phishing data was received */
  callbackData?: Maybe<Scalars['Int']>
  /** The number of clicks recorded */
  clicks?: Maybe<Scalars['Int']>
  /** The number of times data was entered */
  dataEntered?: Maybe<Scalars['Int']>
  /** The number of times macros were enabled */
  macroEnabled?: Maybe<Scalars['Int']>
  /** The number of times QR codes were scanned */
  qrCodeScanned?: Maybe<Scalars['Int']>
  /** The number of times replies were made */
  replied?: Maybe<Scalars['Int']>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type FailuresByPhishingTemplate = {
  __typename?: 'FailuresByPhishingTemplate'
  /** CSV format of the failure data by phishing template */
  csv: Scalars['String']
  /** Collection of failure data by phishing template */
  data: Array<FailuresByPhishingTemplateData>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type FailuresByPhishingTemplateData = {
  __typename?: 'FailuresByPhishingTemplateData'
  /** The campaign in which the template was used */
  campaign?: Maybe<Scalars['String']>
  /** The number of occurrences */
  count: Scalars['Int']
  /** The name associated with the count */
  countName: Scalars['String']
  /** The phishing template used */
  template?: Maybe<Scalars['String']>
}

export type Feedback = {
  __typename?: 'Feedback'
  success?: Maybe<Scalars['String']>
}

export type FieldMappingAttributes = {
  googleField?: Maybe<Scalars['String']>
  kmsatField: Scalars['String']
}

export enum ForceMfaOptions {
  Admins = 'ADMINS',
  Everyone = 'EVERYONE',
  Nobody = 'NOBODY',
}

export type FreeTestEmailsCursor = {
  __typename?: 'FreeTestEmailsCursor'
  /** The paginated nodes */
  nodes: Array<TestEmail>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Data for the free PST wizard */
export type FreeToolData = {
  __typename?: 'FreeToolData'
  /** Domains allowed to access */
  allowedDomains: Array<Scalars['String']>
  /** Options for the landing page */
  landingPageOpts: Array<FreeToolLandingPageOpt>
  /** Maximum number of recipients allowed */
  maxRecipients: Scalars['Int']
  /** URL to the privacy policy */
  privacyPolicyUrl?: Maybe<Scalars['String']>
  /** Recipients from PRT */
  recipientsFromPrt: Array<Scalars['String']>
  /** Recipients from PST */
  recipientsFromPst: Array<Scalars['String']>
  /** Recipients from QRT */
  recipientsFromQrt: Array<Scalars['String']>
  /** Recipients from SPT */
  recipientsFromSpt: Array<Scalars['String']>
  /** URL for support resources */
  supportUrl?: Maybe<Scalars['String']>
  /** Options for translated templates */
  translatedTemplateOpts: Array<FreeToolTranslatedTemplateOpt>
}

/** Landing page option for free tools */
export type FreeToolLandingPageOpt = {
  __typename?: 'FreeToolLandingPageOpt'
  /** The image associated with the free tool landing page option */
  image: Scalars['String']
  /** The name of the free tool landing page option */
  name?: Maybe<Scalars['String']>
  /** The UUID for the free tool landing page option */
  uuid: Scalars['String']
}

/** Free tool report data */
export type FreeToolReportData = {
  __typename?: 'FreeToolReportData'
  /** The Izd for the campaign */
  campaignId: Scalars['Int']
  /** The ID for the campaign run */
  campaignRunId: Scalars['Int']
  /** A list of locales for the campaign */
  locales: Array<Scalars['String']>
  /** The PPP of the campaign */
  ppp: Scalars['String']
  /** The industry PPP of the campaign */
  pppIndustry: Scalars['String']
  /** The start time of the campaign run */
  startedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The current status of the phishing campaign run */
  status: PhishingCampaignRunStatuses
  totalClicked: Scalars['Int']
  totalDataEntered: Scalars['Int']
  totalDelivered: Scalars['Int']
  totalQrCodeScanned: Scalars['Int']
  totalReplied: Scalars['Int']
}

/** Free tool result data */
export type FreeToolResult = Identifier & {
  __typename?: 'FreeToolResult'
  /** Details about the phishing campaign run */
  campaignRun?: Maybe<PhishingCampaignRun>
  /** The UUID of the email template used */
  emailTemplateUuid?: Maybe<Scalars['String']>
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The UUID of the landing page used */
  landingPageUuid?: Maybe<Scalars['String']>
  /** The name associated with the free tool result */
  name: Scalars['String']
}

/** Template data for free tools */
export type FreeToolTranslatedTemplate = Identifier & {
  __typename?: 'FreeToolTranslatedTemplate'
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The position of the translated template within a collection */
  position: Scalars['Int']
  /** The UUID of the template translation */
  templateTranslationUuid: Scalars['String']
}

/** Template option with language and templates for free tools */
export type FreeToolTranslatedTemplateOpt = {
  __typename?: 'FreeToolTranslatedTemplateOpt'
  /** The language of the free tool translated template */
  language: Languages
  /** The collection of free tool translated templates */
  templates: Array<FreeToolTranslatedTemplate>
}

export enum FreeToolTypes {
  Prt = 'PRT',
  Pst = 'PST',
  Qrt = 'QRT',
  Spt = 'SPT',
}

/** Autogenerated return type of FreeToolsFreeToolLaunch. */
export type FreeToolsFreeToolLaunchPayload = {
  __typename?: 'FreeToolsFreeToolLaunchPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PhishingCampaign>
}

/** Autogenerated return type of FreeToolsPrtCreate. */
export type FreeToolsPrtCreatePayload = {
  __typename?: 'FreeToolsPrtCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PhishingCampaign>
}

/** Autogenerated return type of FreeToolsPstCreate. */
export type FreeToolsPstCreatePayload = {
  __typename?: 'FreeToolsPstCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PhishingCampaign>
}

/** Autogenerated return type of FreeToolsQrtCreate. */
export type FreeToolsQrtCreatePayload = {
  __typename?: 'FreeToolsQrtCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PhishingCampaign>
}

/** Autogenerated return type of FreeToolsSptCreate. */
export type FreeToolsSptCreatePayload = {
  __typename?: 'FreeToolsSptCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PhishingCampaign>
}

export enum Frequencies {
  Biweek = 'BIWEEK',
  Month = 'MONTH',
  Onetime = 'ONETIME',
  Quarter = 'QUARTER',
  Week = 'WEEK',
}

/** The gamification of an account */
export type Gamification = GamificationInterface &
  Identifier &
  Timestamps & {
    __typename?: 'Gamification'
    /** Indicates if badges are enabled */
    badgesEnabled?: Maybe<Scalars['Boolean']>
    /** List of badges that are enabled */
    badgesEnabledList: Array<Badges>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Indicates if gamification is enabled */
    enabled?: Maybe<Scalars['Boolean']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** @deprecated To be deprecated once multiple leaderboards are supported */
    leaderboard?: Maybe<Leaderboard>
    /** The groups that are included in the leaderboard */
    leaderboardGroups: Array<Group>
    /** The time window for the leaderboard */
    leaderboardTimeWindow: LeaderboardPeriods
    /** @deprecated Please use the leaderboard field */
    leaderboardType?: Maybe<LeaderboardTypes>
    /** List of leaderboard types that are enabled */
    leaderboardTypes?: Maybe<Array<LeaderboardTypes>>
    /** List of leaderboards that are enabled */
    leaderboards?: Maybe<Array<LeaderboardNew>>
    /** Indicates if leaderboards are enabled */
    leaderboardsEnabled?: Maybe<Scalars['Boolean']>
    /** Indicates if leaderboards ignore optional training */
    leaderboardsIgnoreOptionalTraining?: Maybe<Scalars['Boolean']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type GamificationAttributes = {
  badgesEnabled?: Maybe<Scalars['Boolean']>
  badgesEnabledList?: Maybe<Array<Badges>>
  id?: Maybe<Scalars['Int']>
  leaderboardGroupIds?: Maybe<Array<Scalars['Int']>>
  leaderboardTimeWindow?: Maybe<LeaderboardPeriods>
  leaderboardType?: Maybe<LeaderboardTypes>
  leaderboardTypes?: Maybe<Array<LeaderboardTypes>>
  leaderboardsEnabled?: Maybe<Scalars['Boolean']>
  leaderboardsIgnoreOptionalTraining?: Maybe<Scalars['Boolean']>
}

/** Common fields between user gamification and account gamification */
export type GamificationInterface = {
  /** Indicates if badges are enabled */
  badgesEnabled?: Maybe<Scalars['Boolean']>
  /** List of badges that are enabled */
  badgesEnabledList: Array<Badges>
  /** @deprecated To be deprecated once multiple leaderboards are supported */
  leaderboard?: Maybe<Leaderboard>
  /** The groups that are included in the leaderboard */
  leaderboardGroups: Array<Group>
  /** The time window for the leaderboard */
  leaderboardTimeWindow: LeaderboardPeriods
  /** @deprecated Please use the leaderboard field */
  leaderboardType?: Maybe<LeaderboardTypes>
  /** List of leaderboard types that are enabled */
  leaderboardTypes?: Maybe<Array<LeaderboardTypes>>
  /** List of leaderboards that are enabled */
  leaderboards?: Maybe<Array<LeaderboardNew>>
  /** Indicates if leaderboards are enabled */
  leaderboardsEnabled?: Maybe<Scalars['Boolean']>
}

export type GchatUser = {
  __typename?: 'GchatUser'
  /** The date and time when the GChat user was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The domain associated with the GChat user */
  domain?: Maybe<Scalars['String']>
  /** The email address of the GChat user */
  email?: Maybe<Scalars['String']>
  /** The name of the space the GChat user is part of */
  spaceName?: Maybe<Scalars['String']>
}

export type GlobalPhishingAggregateColumns = {
  __typename?: 'GlobalPhishingAggregateColumns'
  /** A list of columns related to phishing totals */
  phishingTotalsColumns?: Maybe<
    Array<ReportsGlobalPhishingAggregatePhishingTotalsColumns>
  >
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** A list of columns related to user data */
  userColumns?: Maybe<Array<ReportsUserColumns>>
}

export type GlobalPhishingAggregateTableCursor = {
  __typename?: 'GlobalPhishingAggregateTableCursor'
  columns?: Maybe<Columns>
  nodes: Array<AggregateTable>
  pagination: Pagination
  timeframe?: Maybe<ReportTimeframe>
}

export type GlobalPhishingRawColumns = {
  __typename?: 'GlobalPhishingRawColumns'
  /** Columns related to email templates used in phishing campaigns in reports */
  emailTemplateColumns?: Maybe<
    Array<ReportsGlobalPhishingRawEmailTemplateColumns>
  >
  /** Columns related to phishing campaign information in reports */
  phishingCampaignColumns?: Maybe<
    Array<ReportsGlobalPhishingRawPhishingCampaignColumns>
  >
  /** Columns related to the recipients of phishing campaigns in reports */
  phishingCampaignRecipientColumns?: Maybe<
    Array<ReportsGlobalPhishingRawPhishingCampaignRecipientColumns>
  >
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** Columns related to user information in reports */
  userColumns?: Maybe<Array<ReportsUserColumns>>
}

export type GlobalPhishingRawTableCursor = {
  __typename?: 'GlobalPhishingRawTableCursor'
  columns?: Maybe<Columns>
  nodes: Array<PhishingCampaignRecipient>
  pagination: Pagination
  timeframe?: Maybe<ReportTimeframe>
}

export type GlobalTrainingAggregateChart = {
  __typename?: 'GlobalTrainingAggregateChart'
  /** Settings related to the partner account */
  partnerAccountSettings?: Maybe<Array<PartnerAccountSettings>>
  /** Data series for the global training aggregate chart */
  series?: Maybe<GlobalTrainingAggregateChartSeries>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** Token for accessing Zenka data */
  zenkaToken: Scalars['String']
  /** URL to access Zenka data */
  zenkaUrl: Scalars['String']
}

export type GlobalTrainingAggregateChartSeries = {
  __typename?: 'GlobalTrainingAggregateChartSeries'
  /** The number of users who have completed the training */
  completed?: Maybe<Scalars['Int']>
  /** The number of users who have completed the training past the due date */
  completedPastDue?: Maybe<Scalars['Int']>
  /** The number of users enrolled in the training */
  enrolled?: Maybe<Scalars['Int']>
  /** The number of users currently in progress of completing the training */
  inProgress?: Maybe<Scalars['Int']>
  /** The number of users whose training progress is past due */
  inProgressPastDue?: Maybe<Scalars['Int']>
  /** The number of users who have not started the training */
  notStarted?: Maybe<Scalars['Int']>
  /** The number of users who have not started the training and are past the due date */
  notStartedPastDue?: Maybe<Scalars['Int']>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type GlobalTrainingAggregateColumns = {
  __typename?: 'GlobalTrainingAggregateColumns'
  /** Columns related to totals of enrollments in training reports */
  enrollmentTotalsColumns?: Maybe<
    Array<ReportsGlobalTrainingAggregateEnrollmentTotalsColumns>
  >
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** Columns related to user data in reports */
  userColumns?: Maybe<Array<ReportsUserColumns>>
}

export type GlobalTrainingAggregateCsv = {
  __typename?: 'GlobalTrainingAggregateCsv'
  /** Comma-separated values data representing the global training aggregate report */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type GlobalTrainingAggregateTableCursor = {
  __typename?: 'GlobalTrainingAggregateTableCursor'
  columns?: Maybe<Columns>
  nodes: Array<EnrollmentTotals>
  pagination: Pagination
  timeframe?: Maybe<ReportTimeframe>
}

export type GlobalTrainingRawChart = {
  __typename?: 'GlobalTrainingRawChart'
  /** Settings related to the partner account */
  partnerAccountSettings?: Maybe<Array<PartnerAccountSettings>>
  /** A series representation of the global training raw chart */
  series?: Maybe<Array<GlobalTrainingRawChartSeries>>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** The token used for authentication with the Zenka service */
  zenkaToken: Scalars['String']
  /** The URL for the Zenka service */
  zenkaUrl: Scalars['String']
}

export type GlobalTrainingRawChartSeries = {
  __typename?: 'GlobalTrainingRawChartSeries'
  /** The data points for the global training raw chart series */
  data?: Maybe<Array<GlobalTrainingRawChartSeriesData>>
  /** The date associated with the global training raw chart series */
  date?: Maybe<Scalars['ISO8601Date']>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type GlobalTrainingRawChartSeriesData = {
  __typename?: 'GlobalTrainingRawChartSeriesData'
  /** The total number of trainings completed */
  completed?: Maybe<Scalars['Int']>
  /** The total number of trainings created */
  created?: Maybe<Scalars['Int']>
  /** The total number of trainings started */
  started?: Maybe<Scalars['Int']>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type GlobalTrainingRawColumns = {
  __typename?: 'GlobalTrainingRawColumns'
  /** Columns related to enrollments in a training campaign */
  enrollmentColumns?: Maybe<Array<ReportsGlobalTrainingRawEnrollmentColumns>>
  /** Columns related to the enrollment items in a training campaign */
  enrollmentItemColumns?: Maybe<
    Array<ReportsGlobalTrainingRawEnrollmentItemColumns>
  >
  /** Columns related to the store items in a training campaign */
  storeItemColumns?: Maybe<Array<ReportsGlobalTrainingRawStoreItemColumns>>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** Columns related to training campaigns */
  trainingCampaignColumns?: Maybe<
    Array<ReportsGlobalTrainingRawTrainingCampaignColumns>
  >
  /** Columns related to user information */
  userColumns?: Maybe<Array<ReportsUserColumns>>
}

export type GlobalTrainingRawCsv = {
  __typename?: 'GlobalTrainingRawCsv'
  /** The CSV content for global training report */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type GlobalTrainingRawTableCursor = {
  __typename?: 'GlobalTrainingRawTableCursor'
  columns?: Maybe<Columns>
  nodes: Array<Enrollment>
  pagination: Pagination
  timeframe?: Maybe<ReportTimeframe>
}

export type GoogleGroupAttributes = {
  id: Scalars['String']
  name?: Maybe<Scalars['String']>
}

/** A configuration for Google User Provisioning */
export type GoogleUserProvisioningConfig = {
  __typename?: 'GoogleUserProvisioningConfig'
  domains: Array<GoogleUserProvisioningDomain>
  fieldMappings: Array<GoogleUserProvisioningFieldMapping>
}

/** A domain for Google User Provisioning */
export type GoogleUserProvisioningDomain = {
  __typename?: 'GoogleUserProvisioningDomain'
  excludedGroups: Array<GoogleUserProvisioningGroup>
  excludedUsers: Array<Scalars['String']>
  includedGroups: Array<GoogleUserProvisioningGroup>
  includedUsers: Array<Scalars['String']>
  name: Scalars['String']
  syncAllUsers: Scalars['Boolean']
}

export type GoogleUserProvisioningDomainsCursor = {
  __typename?: 'GoogleUserProvisioningDomainsCursor'
  /** The paginated nodes */
  nodes: Array<GoogleUserProvisioningDomain>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** A mapping for a field for Google User Provisioning */
export type GoogleUserProvisioningFieldMapping = {
  __typename?: 'GoogleUserProvisioningFieldMapping'
  googleField?: Maybe<Scalars['String']>
  kmsatField: Scalars['String']
}

/** A group for Google User Provisioning */
export type GoogleUserProvisioningGroup = {
  __typename?: 'GoogleUserProvisioningGroup'
  id: Scalars['String']
  name?: Maybe<Scalars['String']>
}

export type GoogleUserProvisioningGroupsCursor = {
  __typename?: 'GoogleUserProvisioningGroupsCursor'
  /** The paginated nodes */
  nodes: Array<GoogleUserProvisioningGroup>
  /** Information about the current paginated set */
  pagination: Pagination
}

export enum GoogleUserProvisioningStatuses {
  Done = 'DONE',
  Error = 'ERROR',
  NotFound = 'NOT_FOUND',
  Paused = 'PAUSED',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
}

/** A user for Google User Provisioning */
export type GoogleUserProvisioningUser = {
  __typename?: 'GoogleUserProvisioningUser'
  email: Scalars['String']
}

export type GoogleUserProvisioningUsersCursor = {
  __typename?: 'GoogleUserProvisioningUsersCursor'
  /** The paginated nodes */
  nodes: Array<GoogleUserProvisioningUser>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of GpabCsvEmail. */
export type GpabCsvEmailPayload = {
  __typename?: 'GpabCsvEmailPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
}

/** Autogenerated return type of GpabQuickEmail. */
export type GpabQuickEmailPayload = {
  __typename?: 'GpabQuickEmailPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The users that the emails were sent to */
  nodes?: Maybe<Array<User>>
}

/** A collection of groups */
export type Group = Identifier &
  Timestamps & {
    __typename?: 'Group'
    /** The account ID associated with the group */
    accountId?: Maybe<Scalars['Int']>
    /** The account name associated with the group */
    accountName: Scalars['String']
    /** The account name with domain of the group */
    accountNameWithDomain: Scalars['String']
    auditLogDisplay?: Maybe<Scalars['String']>
    auditLogName?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The criteria used to define the group */
    criterions: Array<GroupCriterion>
    /** The display name of the group */
    displayName: Scalars['String']
    /** The historical Risk Scores of the group */
    groupRiskScoreHistories: Array<GroupRiskScoreHistory>
    /** The type of the group */
    groupType: GroupTypes
    /** Indicates if the group has any campaign runs */
    hasCampaignRuns: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** LDAP object GUID of the group */
    ldapObjectGuid?: Maybe<Scalars['String']>
    /** The number of members in the group */
    memberCount: Scalars['Int']
    /** The primary name of the group */
    name: Scalars['String']
    percentDone?: Maybe<Scalars['Float']>
    processing: Scalars['Boolean']
    /** The risk booster level of the group */
    riskBooster?: Maybe<RiskBoosterLevels>
    /** The Risk Score of the group */
    riskScore?: Maybe<Scalars['Float']>
    /** The Risk Score history over time for the group */
    riskScoreHistories: Array<TimeData>
    /** The Security Roles assigned to the group */
    securityRoles: Array<SecurityRole>
    /** The status of the group */
    status: GroupStatuses
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The locales for phishing tests of the group */
    userPhishingLocales?: Maybe<Array<Scalars['String']>>
  }

/** A collection of groups */
export type GroupGroupRiskScoreHistoriesArgs = {
  fullHistory?: Maybe<Scalars['Boolean']>
}

/** Attributes for creating or updating a group */
export type GroupAttributes = {
  /** The type of the group */
  groupType?: Maybe<GroupTypes>
  /** The name of the group */
  name?: Maybe<Scalars['String']>
  /** The risk booster level of the group */
  riskBooster?: Maybe<RiskBoosterLevels>
  /** The IDs of the Security Roles to assign to the group */
  securityRoles?: Maybe<Array<Scalars['Int']>>
}

/** A group criterion */
export type GroupCriterion = Identifier & {
  __typename?: 'GroupCriterion'
  /** The argument of the group criterion */
  argument: Argument
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The type of the group criterion */
  type: Types
}

export type GroupCriterionAttributes = {
  assessments?: Maybe<AssessmentsArgument>
  breachEvent?: Maybe<BreachEventArgument>
  customEvent?: Maybe<CustomEventArgument>
  id: Scalars['BigInt']
  passwordIqEvent?: Maybe<PasswordIqEventArgument>
  passwordIqState?: Maybe<PasswordIqStateArgument>
  phishEvent?: Maybe<PhishEventArgument>
  phishFlipEvent?: Maybe<PhishFlipEventArgument>
  postTrainingEvent?: Maybe<PostTrainingArgument>
  securityCoach?: Maybe<SecurityCoachArgument>
  securityCoachRealTimeCoaching?: Maybe<SecurityCoachRealTimeCoachingArgument>
  training?: Maybe<TrainingArgument>
  type: Types
  userDate?: Maybe<UserDateArgument>
  userField?: Maybe<UserFieldArgument>
}

/** A group leaderboard participant */
export type GroupLeader = LeaderInterface & {
  __typename?: 'GroupLeader'
  id: Scalars['Int']
  includesCurrentUser?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  score?: Maybe<Scalars['Int']>
}

export enum GroupPermissionScopes {
  AdvancedReporting = 'ADVANCED_REPORTING',
  ExecutiveReports = 'EXECUTIVE_REPORTS',
  Phishing = 'PHISHING',
  Training = 'TRAINING',
  TrainingReports = 'TRAINING_REPORTS',
  Users = 'USERS',
}

/** Risk Score for a group */
export type GroupRiskScoreHistory = Identifier & {
  __typename?: 'GroupRiskScoreHistory'
  /** The timestamp when the Risk Score was calculated */
  createdAt: Scalars['ISO8601DateTime']
  /** The group associated with this Risk Score history */
  group: Group
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The calculated Risk Score of the group */
  riskScore?: Maybe<Scalars['Float']>
}

export enum GroupSortFields {
  CreatedAt = 'CREATED_AT',
  MemberCount = 'MEMBER_COUNT',
  Name = 'NAME',
  RiskBooster = 'RISK_BOOSTER',
}

export enum GroupStatuses {
  Active = 'ACTIVE',
  All = 'ALL',
  Archived = 'ARCHIVED',
}

export enum GroupTypes {
  AdiManaged = 'ADI_MANAGED',
  Classic = 'CLASSIC',
  Console = 'CONSOLE',
  Smart = 'SMART',
}

/** Autogenerated return type of GroupsArchive. */
export type GroupsArchivePayload = {
  __typename?: 'GroupsArchivePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The groups that were archived */
  nodes: Array<Group>
}

/** Autogenerated return type of GroupsArchiveUsers. */
export type GroupsArchiveUsersPayload = {
  __typename?: 'GroupsArchiveUsersPayload'
  /** The group with the archived users */
  group?: Maybe<Group>
}

/** Autogenerated return type of GroupsBulkDelete. */
export type GroupsBulkDeletePayload = {
  __typename?: 'GroupsBulkDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The result of the bulk delete */
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of GroupsClone. */
export type GroupsClonePayload = {
  __typename?: 'GroupsClonePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The cloned group */
  node?: Maybe<Group>
}

/** Autogenerated return type of GroupsCreate. */
export type GroupsCreatePayload = {
  __typename?: 'GroupsCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created group */
  node?: Maybe<Group>
}

export type GroupsCursor = {
  __typename?: 'GroupsCursor'
  /** The paginated nodes */
  nodes: Array<Group>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of GroupsDelete. */
export type GroupsDeletePayload = {
  __typename?: 'GroupsDeletePayload'
  /** The groups that were deleted */
  nodes: Array<Group>
}

/** Autogenerated return type of GroupsRemoveUsers. */
export type GroupsRemoveUsersPayload = {
  __typename?: 'GroupsRemoveUsersPayload'
  /** The group with the removed users */
  group?: Maybe<Group>
}

/** Autogenerated return type of GroupsSaveCriterions. */
export type GroupsSaveCriterionsPayload = {
  __typename?: 'GroupsSaveCriterionsPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Group>
}

/** Autogenerated return type of GroupsUpdate. */
export type GroupsUpdatePayload = {
  __typename?: 'GroupsUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated group */
  node?: Maybe<Group>
}

export type HiddenPage = {
  __typename?: 'HiddenPage'
  /** The unique ID of the hidden page */
  id: Scalars['ID']
  /** The UUID of the hidden page */
  uuid: Scalars['String']
}

export type HiddenPageAttributes = {
  uuid: Scalars['String']
}

export type HostNameEmailsData = {
  __typename?: 'HostNameEmailsData'
  /** The host name associated with the email */
  hostName?: Maybe<Scalars['String']>
  /** The user name associated with the email */
  userName?: Maybe<Scalars['String']>
}

/** Unique remote identifier to fetch the node */
export type Identifier = {
  /** Unique identifier for the object */
  id: Scalars['Int']
}

export enum IdpCertificationFormats {
  Sha1 = 'SHA1',
  Sha2 = 'SHA2',
}

export type IdpData = Identifier &
  Timestamps & {
    __typename?: 'IdpData'
    /** The name of the company associated with the identity provider data */
    companyName?: Maybe<Scalars['String']>
    /** The country associated with the identity provider data */
    country?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The type of employment of the associated user */
    employeeType?: Maybe<Scalars['String']>
    /** The external identifier associated with the user in the idp */
    externalId?: Maybe<Scalars['String']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The last date and time the user changed their password */
    lastPasswordChangeDateTime?: Maybe<Scalars['ISO8601DateTime']>
    /** The mail nickname associated with the user */
    mailNickname?: Maybe<Scalars['String']>
    /** The SAM account name for on-premises directory synchronization */
    onPremisesSamAccountName?: Maybe<Scalars['String']>
    /** The security identifier for on-premises directory synchronization */
    onPremisesSecurityIdentifier?: Maybe<Scalars['String']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The principal name of the user in the identity provider system */
    userPrincipalName?: Maybe<Scalars['String']>
  }

/** An individual leaderboard participant */
export type IndividualLeader = LeaderInterface & {
  __typename?: 'IndividualLeader'
  id: Scalars['Int']
  includesCurrentUser?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  /** The rank position of the individual in the leaderboard */
  rank?: Maybe<Scalars['Int']>
  score?: Maybe<Scalars['Int']>
}

export enum Industries {
  AllIndustries = 'ALL_INDUSTRIES',
  Banking = 'BANKING',
  BusinessServices = 'BUSINESS_SERVICES',
  Construction = 'CONSTRUCTION',
  Consulting = 'CONSULTING',
  ConsumerServices = 'CONSUMER_SERVICES',
  Education = 'EDUCATION',
  EnergyAndUtilities = 'ENERGY_AND_UTILITIES',
  FinancialServices = 'FINANCIAL_SERVICES',
  Government = 'GOVERNMENT',
  HealthcareAndPharmaceuticals = 'HEALTHCARE_AND_PHARMACEUTICALS',
  Hospitality = 'HOSPITALITY',
  Insurance = 'INSURANCE',
  Legal = 'LEGAL',
  Manufacturing = 'MANUFACTURING',
  Nonprofit = 'NONPROFIT',
  NotForProfit = 'NOT_FOR_PROFIT',
  Other = 'OTHER',
  RetailAndWholesale = 'RETAIL_AND_WHOLESALE',
  Technology = 'TECHNOLOGY',
  Transportation = 'TRANSPORTATION',
}

export type IndustriesCursor = {
  __typename?: 'IndustriesCursor'
  /** The paginated nodes */
  nodes: Array<Industry>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** A collection of industries */
export type Industry = Identifier &
  Timestamps & {
    __typename?: 'Industry'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The enum representation of the industry name */
    enumName: Scalars['String']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The name of the industry */
    name: Scalars['String']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

/** Benchmarking data for an industry */
export type IndustryBenchmark = Identifier & {
  __typename?: 'IndustryBenchmark'
  /** The baseline size of the industry benchmark */
  baseline: IndustryBenchmarkSizes
  /** The 90-day size measurement of the industry benchmark */
  days90: IndustryBenchmarkSizes
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The industry to which the benchmark pertains */
  industry: Industries
  /** The yearly size measurement of the industry benchmark */
  year: IndustryBenchmarkSizes
}

/** Benchmarking data sizes */
export type IndustryBenchmarkSizes = {
  __typename?: 'IndustryBenchmarkSizes'
  /** Representative size value for large-sized entities within the industry */
  large: Scalars['Float']
  /** Representative size value for medium-sized entities within the industry */
  medium: Scalars['Float']
  /** Representative size value for small-sized entities within the industry */
  small: Scalars['Float']
}

export type IndustryBenchmarksCursor = {
  __typename?: 'IndustryBenchmarksCursor'
  /** The paginated nodes */
  nodes: Array<IndustryBenchmark>
  /** Information about the current paginated set */
  pagination: Pagination
}

export type IntegrationDetectionTrend = {
  __typename?: 'IntegrationDetectionTrend'
  /** The distribution data across different categories or groups */
  distribution?: Maybe<Scalars['JSON']>
  /** The trend data over time for an event or metric */
  trend?: Maybe<Scalars['JSON']>
}

export type IntegrationSummary = {
  __typename?: 'IntegrationSummary'
  /** The number of detections that have been mapped to a security category */
  mappedDetections?: Maybe<Scalars['Int']>
  /** The total number of detections */
  totalDetections?: Maybe<Scalars['Int']>
  /** The count of unique users affected by the detections */
  uniqueUsers?: Maybe<Scalars['Int']>
  /** The number of detections that have not been mapped to any security category */
  unmappedDetections?: Maybe<Scalars['Int']>
}

/** An IP range */
export type IpRange = Identifier &
  Timestamps & {
    __typename?: 'IpRange'
    accountIdsCount: Scalars['Int']
    auditLogDisplay?: Maybe<Scalars['String']>
    campaignRecipientsIdsCount: Scalars['Int']
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The email of the user who created this IP range */
    createdByEmail?: Maybe<Scalars['String']>
    designation: IpRangeDesignations
    /** The ending IP address of the range */
    friendlyIpEnd: Scalars['String']
    /** The starting IP address of the range */
    friendlyIpStart: Scalars['String']
    geoData: Scalars['String']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** A label identifying the IP range */
    label: Scalars['String']
    /** The number of clicks received in the current month */
    monthClicks: Scalars['Int']
    notes?: Maybe<Scalars['String']>
    /** The number of clicks received in the current quarter */
    quarterClicks: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type IpRangeAttributes = {
  designation?: Maybe<IpRangeDesignations>
  /** The end of the IP range */
  friendlyIpEnd: Scalars['String']
  /** The start of the IP range */
  friendlyIpStart: Scalars['String']
  /** The label of the IP range */
  label: Scalars['String']
  notes?: Maybe<Scalars['String']>
}

export enum IpRangeDesignations {
  Account = 'ACCOUNT',
  All = 'ALL',
  Bot = 'BOT',
  Human = 'HUMAN',
  Unknown = 'UNKNOWN',
}

export enum IpRangePurgeTimePeriods {
  Days_30 = 'DAYS_30',
  Days_90 = 'DAYS_90',
}

/** Autogenerated return type of IpRangesAdminPurge. */
export type IpRangesAdminPurgePayload = {
  __typename?: 'IpRangesAdminPurgePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<IpRange>
}

/** Autogenerated return type of IpRangesCreate. */
export type IpRangesCreatePayload = {
  __typename?: 'IpRangesCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created IP range */
  node?: Maybe<IpRange>
}

export type IpRangesCursor = {
  __typename?: 'IpRangesCursor'
  /** The paginated nodes */
  nodes: Array<IpRange>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of IpRangesDelete. */
export type IpRangesDeletePayload = {
  __typename?: 'IpRangesDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The deleted IP range */
  node?: Maybe<IpRange>
}

/** Autogenerated return type of IpRangesPurge. */
export type IpRangesPurgePayload = {
  __typename?: 'IpRangesPurgePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The IP range that was purged */
  node?: Maybe<IpRange>
}

export enum IpRangesSortFields {
  Accountidscount = 'ACCOUNTIDSCOUNT',
  Campaignrecipientsidscount = 'CAMPAIGNRECIPIENTSIDSCOUNT',
  Createdat = 'CREATEDAT',
  Createdbyemail = 'CREATEDBYEMAIL',
  Designation = 'DESIGNATION',
  Label = 'LABEL',
  Notes = 'NOTES',
  Start = 'START',
}

/** Autogenerated return type of IpRangesUpdate. */
export type IpRangesUpdatePayload = {
  __typename?: 'IpRangesUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated IP range */
  node?: Maybe<IpRange>
}

/** The KCM account settings */
export type Kcm = Identifier &
  Timestamps & {
    __typename?: 'Kcm'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Indicates whether the KCM feature is enabled */
    kcmEnabled: Scalars['Boolean']
    /** The end date of the KCM subscription */
    kcmSubscriptionEndDate?: Maybe<Scalars['ISO8601Date']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type KcmAttributes = {
  id?: Maybe<Scalars['Int']>
  kcmEnabled?: Maybe<Scalars['Boolean']>
  kcmSubscriptionEndDate?: Maybe<Scalars['ISO8601DateTime']>
}

/** User Event API key */
export type Key = {
  __typename?: 'Key'
  /** The unique identifier of the API key */
  id: Scalars['Int']
  /** The JWT token associated with the API key */
  jwt: Scalars['String']
  /** The name of the API key */
  name: Scalars['String']
}

/** A Webhook definition */
export type KeyValue = Identifier &
  Timestamps & {
    __typename?: 'KeyValue'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The key identifier for a webhook setting */
    key: Scalars['String']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The value associated with the webhook setting key */
    value: Scalars['String']
  }

/** KSAT User Event type */
export type KmsatEvent = EventTypeInterface & {
  __typename?: 'KmsatEvent'
  description?: Maybe<Scalars['String']>
  id: Scalars['BigInt']
  /** The type of KSAT event */
  name: TimelineEventTypes
}

export enum KnowledgeAreas {
  EmailSecurity = 'EMAIL_SECURITY',
  HumanFirewall = 'HUMAN_FIREWALL',
  IncidentReporting = 'INCIDENT_REPORTING',
  InternetUse = 'INTERNET_USE',
  MobileDevices = 'MOBILE_DEVICES',
  PasswordsAuthentication = 'PASSWORDS_AUTHENTICATION',
  SocialMedia = 'SOCIAL_MEDIA',
}

export type KnowledgePoint = {
  __typename?: 'KnowledgePoint'
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** The knowledge area associated with the knowledge point */
  x: KnowledgeAreas
  /** The y-axis value representing the knowledge point score */
  y: Scalars['Float']
}

/** A knowledge refresher for a ModStore item */
export type KnowledgeRefresher = Identifier &
  StorePurchaseInterface &
  Timestamps & {
    __typename?: 'KnowledgeRefresher'
    /** The alternatives for the purchased course */
    alternatives: Array<CourseAlternative>
    /** Indicates if the purchased course is archived */
    archived?: Maybe<Scalars['Boolean']>
    /** Indicates if the purchased course is an assessment */
    assessment?: Maybe<Scalars['Boolean']>
    /** The type of assessment associated with the purchased course */
    assessmentType?: Maybe<AssessmentTypes>
    /** The type of asset associated with the purchased course */
    assetType: Scalars['String']
    /** The completion percentage of the Knowledge Refresher */
    completionPercentage?: Maybe<Scalars['Int']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The description of the purchased course */
    description?: Maybe<Scalars['String']>
    /** The duration of the purchased course */
    duration?: Maybe<Scalars['Int']>
    id: Scalars['Int']
    /** A polymorphic identifier for associated content */
    polymorphicId: Scalars['String']
    /** The published date of the purchased course */
    publishedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** Indicates if the purchased course is retired */
    retired?: Maybe<Scalars['Boolean']>
    /** Indicates if the purchased course is retiring soon */
    retiringSoon?: Maybe<Scalars['Boolean']>
    storeItem?: Maybe<StoreItemUnion>
    storeItemType?: Maybe<Scalars['String']>
    storeItemUuid: Scalars['String']
    title: Scalars['String']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** Indicates if the purchased course is uploaded */
    uploaded?: Maybe<Scalars['Boolean']>
  }

/** A knowledge refresher for a ModStore item */
export type KnowledgeRefresherCompletionPercentageArgs = {
  trainingCampaignId: Scalars['Int']
}

export type KnowledgeRefresherQuestion = {
  __typename?: 'KnowledgeRefresherQuestion'
  correctFeedback: Scalars['String']
  correctOption?: Maybe<KnowledgeRefresherQuestionOption>
  id: Scalars['Int']
  incorrectFeedback: Scalars['String']
  label: Scalars['String']
  options?: Maybe<Array<KnowledgeRefresherQuestionOption>>
  status: Scalars['String']
}

export type KnowledgeRefresherQuestionOption = {
  __typename?: 'KnowledgeRefresherQuestionOption'
  id: Scalars['Int']
  label: Scalars['String']
  right: Scalars['String']
}

export type KnowledgeRefresherQuiz = {
  __typename?: 'KnowledgeRefresherQuiz'
  question?: Maybe<KnowledgeRefresherQuestion>
  questions: Array<KnowledgeRefresherQuestion>
}

export type KnowledgeRefresherQuizQuestionArgs = {
  id: Scalars['ID']
}

export type KnowledgeSeries = {
  __typename?: 'KnowledgeSeries'
  /** Array of knowledge points related to the series */
  data: Array<KnowledgePoint>
  /** The name of the knowledge series */
  name: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type KnowledgeSeriesCollection = {
  __typename?: 'KnowledgeSeriesCollection'
  /** CSV format of the knowledge series data */
  csv: Scalars['String']
  /** A collection of knowledge series data */
  dataseries: Array<KnowledgeSeries>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type KnowledgeTimeSeries = {
  __typename?: 'KnowledgeTimeSeries'
  /** Time series data points for the report */
  data: Array<TimePoint>
  /** The specified area of knowledge */
  name: KnowledgeAreas
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type KnowledgeTimeSeriesCollection = {
  __typename?: 'KnowledgeTimeSeriesCollection'
  /** A CSV representation of the knowledge time series data */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** A collection of knowledge-related metrics over time */
  timeseries: Array<KnowledgeTimeSeries>
}

/** Landing domain */
export type LandingDomain = Identifier &
  Timestamps & {
    __typename?: 'LandingDomain'
    /** Indicates if the landing domain is currently active */
    active?: Maybe<Scalars['Boolean']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Indicates if this is the default landing domain */
    isDefault: Scalars['Boolean']
    /** The name of the landing domain */
    name: Scalars['String']
    /** Indicates if this is the default domain for previews */
    previewDefault: Scalars['Boolean']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type LandingDomainAttributes = {
  isDefault?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  previewDefault?: Maybe<Scalars['Boolean']>
}

export enum LandingDomainSortFields {
  IsDefault = 'IS_DEFAULT',
  Name = 'NAME',
  PreviewDefault = 'PREVIEW_DEFAULT',
}

/** Autogenerated return type of LandingDomainsCreate. */
export type LandingDomainsCreatePayload = {
  __typename?: 'LandingDomainsCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<LandingDomain>
}

export type LandingDomainsCursor = {
  __typename?: 'LandingDomainsCursor'
  /** The paginated nodes */
  nodes: Array<LandingDomain>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of LandingDomainsDelete. */
export type LandingDomainsDeletePayload = {
  __typename?: 'LandingDomainsDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<LandingDomain>
}

/** Autogenerated return type of LandingDomainsToggleArchived. */
export type LandingDomainsToggleArchivedPayload = {
  __typename?: 'LandingDomainsToggleArchivedPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<LandingDomain>
}

/** Autogenerated return type of LandingDomainsUpdate. */
export type LandingDomainsUpdatePayload = {
  __typename?: 'LandingDomainsUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<LandingDomain>
}

/** Email templates for phishing */
export type LandingPage = Identifier &
  Timestamps & {
    __typename?: 'LandingPage'
    auditLogDisplay?: Maybe<Scalars['String']>
    /** The category of the landing page */
    category?: Maybe<LandingPageCategory>
    /** The HTML content of the landing page */
    contentHtml: Scalars['String']
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The timestamp when the landing page was marked as updated */
    markedAsUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The URL for the preview of the landing page */
    previewUrl: Scalars['String']
    shortLivedPublicPreviewUrl?: Maybe<Scalars['String']>
    /** The URL path of the landing page */
    slug: Scalars['String']
    /** The title of the landing page */
    title: Scalars['String']
    /** The type of the landing page */
    type: LandingPageTypes
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The full URL of the landing page */
    url: Scalars['String']
  }

export type LandingPageAttributes = {
  /** The content of the landing page */
  contentHtml: Scalars['String']
  /** The title of the landing page */
  title: Scalars['String']
}

/** An unpaginated list of landing page categories */
export type LandingPageCategories = {
  __typename?: 'LandingPageCategories'
  /** A list of landing page categories */
  nodes: Array<LandingPageCategory>
}

/** Category object to filter landing page nodes */
export type LandingPageCategory = Identifier &
  Timestamps & {
    __typename?: 'LandingPageCategory'
    auditLogDisplay?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The count of landing pages under this category */
    landingPageCount: Scalars['Int']
    /** A list of landing pages under this category */
    landingPages: Array<LandingPage>
    /** The name of the landing page category */
    name: Scalars['String']
    translationKey?: Maybe<Scalars['String']>
    /** The type of landing page category */
    type: LandingPageCategoryTypes
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type LandingPageCategoryAttributes = {
  /** The name of the category */
  name: Scalars['String']
}

export enum LandingPageCategoryTypes {
  All = 'ALL',
  Managed = 'MANAGED',
  System = 'SYSTEM',
  User = 'USER',
}

/** Landing page drafts */
export type LandingPageCounts = {
  __typename?: 'LandingPageCounts'
  /** The total count of items on the landing page */
  allCount: Scalars['Int']
  /** The count of drafts on the landing page */
  draftsCount: Scalars['Int']
}

export enum LandingPageSortFields {
  CategoryName = 'CATEGORY_NAME',
  Title = 'TITLE',
  Updated = 'UPDATED',
}

export enum LandingPageTypes {
  All = 'ALL',
  Managed = 'MANAGED',
  System = 'SYSTEM',
  User = 'USER',
}

/** Autogenerated return type of LandingPagesCategoryCreate. */
export type LandingPagesCategoryCreatePayload = {
  __typename?: 'LandingPagesCategoryCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created landing page category */
  node?: Maybe<LandingPageCategory>
}

/** Autogenerated return type of LandingPagesCategoryDelete. */
export type LandingPagesCategoryDeletePayload = {
  __typename?: 'LandingPagesCategoryDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The deleted landing page category */
  node?: Maybe<LandingPageCategory>
}

/** Autogenerated return type of LandingPagesCategoryUpdate. */
export type LandingPagesCategoryUpdatePayload = {
  __typename?: 'LandingPagesCategoryUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated landing page category */
  node?: Maybe<LandingPageCategory>
}

/** Autogenerated return type of LandingPagesCreate. */
export type LandingPagesCreatePayload = {
  __typename?: 'LandingPagesCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created landing page */
  node?: Maybe<LandingPage>
}

export type LandingPagesCursor = {
  __typename?: 'LandingPagesCursor'
  /** The paginated nodes */
  nodes: Array<LandingPage>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of LandingPagesImport. */
export type LandingPagesImportPayload = {
  __typename?: 'LandingPagesImportPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The imported templates */
  nodes?: Maybe<Array<LandingPage>>
}

/** Autogenerated return type of LandingPagesUpdate. */
export type LandingPagesUpdatePayload = {
  __typename?: 'LandingPagesUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated landing page */
  node?: Maybe<LandingPage>
}

/** ModStore language */
export type Language = Identifier & {
  __typename?: 'Language'
  /** The ISO code of the language */
  code: Scalars['String']
  /** The icon representing the language */
  icon: Scalars['String']
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The name of the language */
  name: Scalars['String']
  /** The translation key used for localization of the language name */
  translationKey: Scalars['String']
}

export enum Languages {
  AfZa = 'AF_ZA',
  AmEt = 'AM_ET',
  ArAe = 'AR_AE',
  ArBh = 'AR_BH',
  ArDz = 'AR_DZ',
  ArEg = 'AR_EG',
  ArIq = 'AR_IQ',
  ArJo = 'AR_JO',
  ArKw = 'AR_KW',
  ArLb = 'AR_LB',
  ArLy = 'AR_LY',
  ArMa = 'AR_MA',
  ArMod = 'AR_MOD',
  ArOm = 'AR_OM',
  ArQa = 'AR_QA',
  ArSa = 'AR_SA',
  ArSy = 'AR_SY',
  ArTn = 'AR_TN',
  ArYe = 'AR_YE',
  AsIn = 'AS_IN',
  AzAz = 'AZ_AZ',
  BeBy = 'BE_BY',
  BgBg = 'BG_BG',
  BnIn = 'BN_IN',
  BoNp = 'BO_NP',
  BsBa = 'BS_BA',
  CaEs = 'CA_ES',
  CsCz = 'CS_CZ',
  DaDk = 'DA_DK',
  DeAt = 'DE_AT',
  DeCh = 'DE_CH',
  DeDe = 'DE_DE',
  DeLi = 'DE_LI',
  DeLu = 'DE_LU',
  ElGr = 'EL_GR',
  EnAu = 'EN_AU',
  EnBz = 'EN_BZ',
  EnCa = 'EN_CA',
  EnGb = 'EN_GB',
  EnIe = 'EN_IE',
  EnIn = 'EN_IN',
  EnJm = 'EN_JM',
  EnKy = 'EN_KY',
  EnNz = 'EN_NZ',
  EnPh = 'EN_PH',
  EnSg = 'EN_SG',
  EnTt = 'EN_TT',
  EnUs = 'EN_US',
  EnZa = 'EN_ZA',
  Es_419 = 'ES_419',
  EsAr = 'ES_AR',
  EsBo = 'ES_BO',
  EsCl = 'ES_CL',
  EsCo = 'ES_CO',
  EsCr = 'ES_CR',
  EsDo = 'ES_DO',
  EsEc = 'ES_EC',
  EsEs = 'ES_ES',
  EsGt = 'ES_GT',
  EsHn = 'ES_HN',
  EsMx = 'ES_MX',
  EsNi = 'ES_NI',
  EsPa = 'ES_PA',
  EsPe = 'ES_PE',
  EsPr = 'ES_PR',
  EsPy = 'ES_PY',
  EsSv = 'ES_SV',
  EsUy = 'ES_UY',
  EsVe = 'ES_VE',
  EtEe = 'ET_EE',
  EuEs = 'EU_ES',
  FaIr = 'FA_IR',
  FiFi = 'FI_FI',
  FrBe = 'FR_BE',
  FrCa = 'FR_CA',
  FrCh = 'FR_CH',
  FrFr = 'FR_FR',
  FrLu = 'FR_LU',
  GdIe = 'GD_IE',
  GnPy = 'GN_PY',
  GuIn = 'GU_IN',
  HeIl = 'HE_IL',
  HiIn = 'HI_IN',
  HrHr = 'HR_HR',
  HuHu = 'HU_HU',
  HyAm = 'HY_AM',
  IdId = 'ID_ID',
  IsIs = 'IS_IS',
  ItCh = 'IT_CH',
  ItIt = 'IT_IT',
  JaJp = 'JA_JP',
  KkKz = 'KK_KZ',
  KmKh = 'KM_KH',
  KnIn = 'KN_IN',
  KoKr = 'KO_KR',
  LoLa = 'LO_LA',
  LtLt = 'LT_LT',
  LvLv = 'LV_LV',
  MiNz = 'MI_NZ',
  MlIn = 'ML_IN',
  MnMn = 'MN_MN',
  MrIn = 'MR_IN',
  MsBn = 'MS_BN',
  MsMy = 'MS_MY',
  MtMt = 'MT_MT',
  MyMm = 'MY_MM',
  NbNo = 'NB_NO',
  NeNp = 'NE_NP',
  NlBe = 'NL_BE',
  NlNl = 'NL_NL',
  NoNo = 'NO_NO',
  OrIn = 'OR_IN',
  PaPk = 'PA_PK',
  PlPl = 'PL_PL',
  PtBr = 'PT_BR',
  PtPt = 'PT_PT',
  RoMo = 'RO_MO',
  RoRo = 'RO_RO',
  RuMo = 'RU_MO',
  RuRu = 'RU_RU',
  SaIn = 'SA_IN',
  SdPk = 'SD_PK',
  SiLk = 'SI_LK',
  SkSk = 'SK_SK',
  SlSl = 'SL_SL',
  SoSo = 'SO_SO',
  SqAl = 'SQ_AL',
  SrCyrlRs = 'SR_CYRL_RS',
  SrLatnRs = 'SR_LATN_RS',
  SrRs = 'SR_RS',
  SvFi = 'SV_FI',
  SvSe = 'SV_SE',
  SwKe = 'SW_KE',
  TaSg = 'TA_SG',
  TeIn = 'TE_IN',
  TgTj = 'TG_TJ',
  ThTh = 'TH_TH',
  TkTm = 'TK_TM',
  TrTr = 'TR_TR',
  UkUa = 'UK_UA',
  UrPk = 'UR_PK',
  UzUz = 'UZ_UZ',
  ViVn = 'VI_VN',
  XhZa = 'XH_ZA',
  YiIl = 'YI_IL',
  ZhCn = 'ZH_CN',
  ZhHk = 'ZH_HK',
  ZhMo = 'ZH_MO',
  ZhSg = 'ZH_SG',
  ZhTw = 'ZH_TW',
  ZuZa = 'ZU_ZA',
}

export type Latest = {
  __typename?: 'Latest'
  /** Indicates if the timeframe is the latest */
  latest: Scalars['Boolean']
}

/** Autogenerated return type of LaunchDarklyDisablePhishingTemplateExclusions. */
export type LaunchDarklyDisablePhishingTemplateExclusionsPayload = {
  __typename?: 'LaunchDarklyDisablePhishingTemplateExclusionsPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['JSON']>
}

/** Autogenerated return type of LaunchDarklyEnableAidaPhishingTemplateGlobal. */
export type LaunchDarklyEnableAidaPhishingTemplateGlobalPayload = {
  __typename?: 'LaunchDarklyEnableAidaPhishingTemplateGlobalPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['JSON']>
}

/** Autogenerated return type of LaunchDarklyEnablePhishingTemplateExclusions. */
export type LaunchDarklyEnablePhishingTemplateExclusionsPayload = {
  __typename?: 'LaunchDarklyEnablePhishingTemplateExclusionsPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['JSON']>
}

/** LDAP Sync Reports */
export type LdapSyncReport = Identifier &
  Timestamps & {
    __typename?: 'LdapSyncReport'
    /** The URL to the file containing client-specific information */
    clientFileUrl?: Maybe<Scalars['String']>
    /** The timestamp when the sync was completed */
    completedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Details of credential updates performed during sync */
    credentialUpdates?: Maybe<LdapSyncReportUpdates>
    /** Details of domain updates performed during sync */
    domainUpdates?: Maybe<LdapSyncReportUpdates>
    /** Details of group updates performed during sync */
    groupUpdates: LdapSyncReportUpdates
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The identity provider used for sync */
    identityProvider?: Maybe<Scalars['String']>
    /** Details of membership updates performed during sync */
    membershipUpdates: LdapSyncReportUpdates
    /** The source from which users are provisioned */
    source: UserProvisioningSources
    /** The current status of the LDAP Sync Report */
    status: LdapSyncReportStatuses
    /** The URL to the file containing sync details */
    syncFileUrl?: Maybe<Scalars['String']>
    /** Indicates if the sync was performed in test mode */
    testMode: Scalars['Boolean']
    /** The timestamp when the report was truncated */
    truncatedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** Details of user updates performed during sync */
    userUpdates: LdapSyncReportUpdates
  }

/** LDAP Sync Report item */
export type LdapSyncReportItem = Identifier &
  Timestamps & {
    __typename?: 'LdapSyncReportItem'
    /** The action taken for the LDAP Sync Report item */
    action: LdapSyncReportItemActions
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The email address associated with the LDAP object */
    email?: Maybe<Scalars['String']>
    /** The error message, if any, for the LDAP Sync Report item */
    error?: Maybe<Scalars['String']>
    /** The group associated with the LDAP object */
    group?: Maybe<Scalars['String']>
    /** The global unique identifier of the LDAP object */
    guid?: Maybe<Scalars['String']>
    /** Indicates whether the LDAP Sync Report item is hidden */
    hidden: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The GUID of the LDAP object */
    ldapObjectGuid?: Maybe<Scalars['String']>
    /** The manager of the LDAP object */
    manager?: Maybe<Scalars['String']>
    /** Any message associated with the LDAP Sync Report item */
    message?: Maybe<Scalars['String']>
    /** The name of the LDAP object */
    name?: Maybe<Scalars['String']>
    /** The type of the LDAP Sync Report item */
    type: LdapSyncReportItemTypes
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export enum LdapSyncReportItemActions {
  Archived = 'ARCHIVED',
  Changed = 'CHANGED',
  Claimed = 'CLAIMED',
  Created = 'CREATED',
  Deleted = 'DELETED',
  Unarchived = 'UNARCHIVED',
}

export enum LdapSyncReportItemStatuses {
  All = 'ALL',
  Error = 'ERROR',
  Success = 'SUCCESS',
}

export enum LdapSyncReportItemTypes {
  Credential = 'CREDENTIAL',
  Domain = 'DOMAIN',
  Group = 'GROUP',
  Membership = 'MEMBERSHIP',
  User = 'USER',
}

export type LdapSyncReportItemsCursor = {
  __typename?: 'LdapSyncReportItemsCursor'
  /** The paginated nodes */
  nodes: Array<LdapSyncReportItem>
  /** Information about the current paginated set */
  pagination: Pagination
}

export enum LdapSyncReportSourceFilters {
  Adi = 'ADI',
  All = 'ALL',
  Google = 'GOOGLE',
  Scim = 'SCIM',
  ScimV2 = 'SCIM_V2',
}

export enum LdapSyncReportStatuses {
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Skipped = 'SKIPPED',
}

/** LDAP Sync Reports pdates counts */
export type LdapSyncReportUpdates = {
  __typename?: 'LdapSyncReportUpdates'
  /** The number of archived sync operations */
  archived?: Maybe<Scalars['Int']>
  /** The number of changed sync operations */
  changed?: Maybe<Scalars['Int']>
  /** The number of claimed sync operations */
  claimed?: Maybe<Scalars['Int']>
  /** The number of created sync operations */
  created?: Maybe<Scalars['Int']>
  /** The number of deleted sync operations */
  deleted?: Maybe<Scalars['Int']>
  /** The number of errors encountered during sync operations */
  errors: Scalars['Int']
  /** The number of successful sync operations */
  success: Scalars['Int']
  /** The number of unarchived sync operations */
  unarchived?: Maybe<Scalars['Int']>
}

export type LdapSyncReportsCursor = {
  __typename?: 'LdapSyncReportsCursor'
  /** The paginated nodes */
  nodes: Array<LdapSyncReport>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** A participant or group on a leaderboard */
export type LeaderInterface = {
  id: Scalars['Int']
  includesCurrentUser?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  score?: Maybe<Scalars['Int']>
}

/** A leaderboard for gamification */
export type Leaderboard = {
  __typename?: 'Leaderboard'
  /** Specifies the type of leaderboard */
  leaderboardType?: Maybe<LeaderboardTypes>
  /** A collection of groups in the leaderboard */
  leaders?: Maybe<Array<LeaderboardGroup>>
  /** A collection of individual accounts in the leaderboard, listed by account */
  leadersIndividualByAccount?: Maybe<Array<LeaderboardIndividualAccount>>
}

/** A leaderboard group for gamification */
export type LeaderboardGroup = {
  __typename?: 'LeaderboardGroup'
  /** The ID of the leaderboard group */
  id: Scalars['Int']
  /** Indicates if the current user is included in the leaderboard group */
  includesCurrentUser?: Maybe<Scalars['Boolean']>
  /** The name of the leaderboard group */
  name: Scalars['String']
  /** The score of the leaderboard group */
  score?: Maybe<Scalars['Int']>
}

/** An individual leaderboard for the account */
export type LeaderboardIndividualAccount = {
  __typename?: 'LeaderboardIndividualAccount'
  /** The unique identifier of the leaderboard entry */
  id: Scalars['Int']
  /** Indicates if the current user is included in the leaderboard entry */
  includesCurrentUser?: Maybe<Scalars['Boolean']>
  /** The name of the individual account on the leaderboard */
  name: Scalars['String']
  /** The rank of the individual account on the leaderboard */
  rank?: Maybe<Scalars['Int']>
  /** The score of the individual account on the leaderboard */
  score?: Maybe<Scalars['Int']>
}

/** A leaderboard for gamification */
export type LeaderboardNew = {
  __typename?: 'LeaderboardNew'
  /** The size of the leaderboard */
  leaderboardSize?: Maybe<Scalars['Int']>
  /** The type of leaderboard */
  leaderboardType?: Maybe<LeaderboardTypes>
  /** A list of leaders on the leaderboard */
  leaders?: Maybe<Array<LeadersUnion>>
}

export enum LeaderboardPeriods {
  AllTime = 'ALL_TIME',
  CurrentMonth = 'CURRENT_MONTH',
  CurrentQuarter = 'CURRENT_QUARTER',
  CurrentYear = 'CURRENT_YEAR',
  Past_30Days = 'PAST_30_DAYS',
  Past_90Days = 'PAST_90_DAYS',
}

export enum LeaderboardTypes {
  GroupLeaderboard = 'GROUP_LEADERBOARD',
  IndividualLeaderboard = 'INDIVIDUAL_LEADERBOARD',
  NoLeaderboard = 'NO_LEADERBOARD',
}

/** A leaderboard item */
export type LeadersUnion = GroupLeader | IndividualLeader

/** A rendered message intended for a specific recipient */
export type LearnerMessage = Identifier &
  Timestamps & {
    __typename?: 'LearnerMessage'
    /** The textual content of the message */
    content: Scalars['String']
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The current status of the message */
    status: MessagesCampaignRecipientStatuses
    /** The subject of the message */
    subject: Scalars['String']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type LearnerMessageAttributes = {
  status?: Maybe<MessagesCampaignRecipientStatuses>
}

export type LearnerPlatformRawChart = {
  __typename?: 'LearnerPlatformRawChart'
  /** The series of data points for the learner platform chart */
  series?: Maybe<Array<LearnerPlatformRawChartSeries>>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type LearnerPlatformRawChartSeries = {
  __typename?: 'LearnerPlatformRawChartSeries'
  /** The data points for the chart series */
  data?: Maybe<Array<LearnerPlatformRawChartSeriesData>>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type LearnerPlatformRawChartSeriesData = {
  __typename?: 'LearnerPlatformRawChartSeriesData'
  /** The completed count */
  completed?: Maybe<Scalars['Int']>
  /** The count from LX web */
  lxWeb?: Maybe<Scalars['Int']>
  /** The count from LX web mobile */
  lxWebMobile?: Maybe<Scalars['Int']>
  /** The count from mobile app */
  mobileApp?: Maybe<Scalars['Int']>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type LearnerPlatformRawColumns = {
  __typename?: 'LearnerPlatformRawColumns'
  /** Columns pertaining to enrollment details */
  enrollmentColumns?: Maybe<Array<ReportsLearnerPlatformRawEnrollmentColumns>>
  /** Columns specific to enrollment items */
  enrollmentItemColumns?: Maybe<
    Array<ReportsLearnerPlatformRawEnrollmentItemColumns>
  >
  /** Columns related to the enrollment platform */
  enrollmentPlatformColumns?: Maybe<
    Array<ReportsLearnerPlatformRawEnrollmentPlatformColumns>
  >
  /** Columns related to items available in the store */
  storeItemColumns?: Maybe<Array<ReportsLearnerPlatformRawStoreItemColumns>>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** Columns associated with training campaigns */
  trainingCampaignColumns?: Maybe<
    Array<ReportsLearnerPlatformRawTrainingCampaignColumns>
  >
  /** Columns related to user data */
  userColumns?: Maybe<Array<ReportsUserColumns>>
}

export type LearnerPlatformRawCsv = {
  __typename?: 'LearnerPlatformRawCsv'
  /** The CSV data of the report */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type LearnerPlatformRawTableCursor = {
  __typename?: 'LearnerPlatformRawTableCursor'
  columns?: Maybe<Columns>
  nodes: Array<Enrollment>
  pagination: Pagination
  timeframe?: Maybe<ReportTimeframe>
}

export type ListCursor = {
  __typename?: 'ListCursor'
  /** The paginated nodes */
  nodes: Array<SavedReport>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of LmsCommit. */
export type LmsCommitPayload = {
  __typename?: 'LmsCommitPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<LmsEnrollment>
}

/** The training session information for an enrollment */
export type LmsEnrollment = {
  __typename?: 'LmsEnrollment'
  /** Represents the completion status of the enrollment */
  completionStatus?: Maybe<Scalars['String']>
  /** Indicates how the learner exited the course */
  exit?: Maybe<Scalars['String']>
  /** The ID of the learner */
  learnerId?: Maybe<Scalars['Int']>
  /** Name of the learner */
  learnerName?: Maybe<Scalars['String']>
  /** The location within the course where the learner left off */
  location?: Maybe<Scalars['String']>
  /** Score required to pass the course */
  passingScore?: Maybe<Scalars['Float']>
  /** The maximum score achievable in the course */
  scoreMax?: Maybe<Scalars['Float']>
  /** The minimum score achievable in the course */
  scoreMin?: Maybe<Scalars['Float']>
  /** The raw score achieved by the learner */
  scoreRaw?: Maybe<Scalars['Float']>
  /** The scaled score of the learner */
  scoreScaled?: Maybe<Scalars['Float']>
  /** Unique identifier of the session */
  sessionId?: Maybe<Scalars['String']>
  /** Duration of the session in the course */
  sessionTime?: Maybe<Scalars['String']>
  /** Indicates the success status of the learner in the course */
  successStatus?: Maybe<Scalars['String']>
  /** Data saved when the course was suspended */
  suspendData?: Maybe<Scalars['String']>
  /** Represents the theme of the enrollment */
  theme?: Maybe<Scalars['String']>
  /** Total time spent by the learner in the course */
  totalTime?: Maybe<Scalars['String']>
}

/** Autogenerated return type of LmsInit. */
export type LmsInitPayload = {
  __typename?: 'LmsInitPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<LmsEnrollment>
}

/** Autogenerated return type of LmsTerminate. */
export type LmsTerminatePayload = {
  __typename?: 'LmsTerminatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<LmsEnrollment>
}

/** User locale lists for UI, phishing, and training */
export type Locales = {
  __typename?: 'Locales'
  /** Available locales for admin interfaces */
  adminLocales: Array<Scalars['String']>
  /** Available locales for PSTs */
  phishingLocales: Array<Scalars['String']>
  /** Available locales for training modules */
  trainingLocales: Array<Scalars['String']>
}

export type Location = {
  __typename?: 'Location'
  /** The unique identifier of the location */
  id: Scalars['String']
  /** The name of the location */
  name: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type LogProperties = {
  __typename?: 'LogProperties'
  /** The total number of events */
  eventsCount?: Maybe<Scalars['Int']>
  /** The count of unique users */
  uniqueUsers?: Maybe<Scalars['Int']>
}

export type LogTypeTrend = {
  __typename?: 'LogTypeTrend'
  /** The date of the log trend */
  date?: Maybe<Scalars['String']>
  /** The properties associated with the log trend */
  properties?: Maybe<LogProperties>
}

/** Managed training notification */
export type ManagedNotification = Identifier &
  NotificationInterface &
  Timestamps & {
    __typename?: 'ManagedNotification'
    /** The admin email template for the notification */
    adminEmailTemplate?: Maybe<NotificationTemplate>
    /** The unique identifier of the admin email template */
    adminEmailTemplateId?: Maybe<Scalars['Int']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The delivery method for the notification */
    deliveryMethod?: Maybe<TrainingNotificationDeliveryMethods>
    /** The unique identifier of the user email template */
    emailTemplateId?: Maybe<Scalars['Int']>
    /** Indicates if the notification is enabled */
    enabled?: Maybe<Scalars['Boolean']>
    /** The enrollment filter for the notification */
    enrollmentFilter?: Maybe<Array<NotificationEnrollmentFilter>>
    /** Unique identifier for the object */
    id: Scalars['Int']
    lastEnrollmentId?: Maybe<Scalars['Int']>
    /** The manager email template for the notification */
    managerEmailTemplate?: Maybe<NotificationTemplate>
    /** The unique identifier of the manager email template */
    managerEmailTemplateId?: Maybe<Scalars['Int']>
    /** The message type for the notification */
    messageType?: Maybe<TrainingCampaignNotification>
    /** The offset from type for the notification */
    offsetFrom: Scalars['String']
    /** The number of days for the offset */
    offsetNumDays?: Maybe<Scalars['Int']>
    /** The date and time the notification was processed */
    processedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The user IDs for the recipients of the notification */
    recipientUserIds?: Maybe<Array<Scalars['Int']>>
    /** The reschedule offset for the notification */
    rescheduleOffset?: Maybe<Scalars['Int']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The user email template for the notification */
    userEmailTemplate?: Maybe<NotificationTemplate>
  }

export type ManagedNotificationAttributes = {
  adminEmailTemplateId?: Maybe<Scalars['Int']>
  deliveryMethod?: Maybe<TrainingNotificationDeliveryMethods>
  destroy?: Maybe<Scalars['Boolean']>
  emailTemplateId?: Maybe<Scalars['Int']>
  enabled?: Maybe<Scalars['Boolean']>
  enrollmentFilter?: Maybe<NotificationEnrollmentFilter>
  fallbackEmail?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['ID']>
  managerEmailTemplateId?: Maybe<Scalars['Int']>
  messageType: TrainingCampaignNotification
  offsetNumDays?: Maybe<Scalars['Int']>
  recipientUserIds?: Maybe<Array<Scalars['ID']>>
  rescheduleOffset?: Maybe<Scalars['Int']>
  timestamp?: Maybe<Scalars['BigInt']>
}

/** A Managed phishing campaign */
export type ManagedPhishingCampaign = Identifier &
  PhishingCampaignInterface &
  Timestamps & {
    __typename?: 'ManagedPhishingCampaign'
    /** IDs of accounts associated with the phishing campaign */
    accountIds: Array<Scalars['Int']>
    /** Accounts associated with the phishing campaign */
    accounts: Array<Account>
    /** Indicates if the phishing campaign is active */
    active: Scalars['Boolean']
    /** Indicates if the AIDA template is selected */
    aidaSelectedTemplate?: Maybe<Scalars['Boolean']>
    /** Phishing campaigns associated with the managed phishing campaign */
    campaigns: Array<PhishingCampaign>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The creator of the managed phishing campaign */
    creator?: Maybe<PartnerAdmin>
    /** The email template used in the phishing campaign */
    emailTemplate?: Maybe<PhishingTemplate>
    /** The frequency period for the phishing campaign */
    frequencyPeriod: Frequencies
    /** Indicates if email templates are fully randomized */
    fullRandom?: Maybe<Scalars['Boolean']>
    /** IDs of the groups included in the phishing campaign */
    groupIds: Array<Scalars['Int']>
    /** Determines if the phishing campaign should be hidden from reports */
    hideFromReports: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The landing page used in the phishing campaign */
    landingPage?: Maybe<LandingPage>
    /** The last time the phishing campaign was run */
    lastRun?: Maybe<Scalars['ISO8601DateTime']>
    localizedCampaign?: Maybe<Scalars['Boolean']>
    /** The name of the phishing campaign */
    name: Scalars['String']
    /** The date and time for the next run of the phishing campaign */
    nextRun?: Maybe<Scalars['ISO8601DateTime']>
    /** The phishing domain associated with the phishing campaign */
    phishDomain?: Maybe<PhishDomain>
    /** Indicates if account edits are prevented for this phishing campaign */
    preventAccountEdits?: Maybe<Scalars['Boolean']>
    /** The difficulty levels available for the phishing campaign */
    rating: Array<EmailTemplateDifficultyRatings>
    /** The domain prefix for replies in the phishing campaign */
    replyDomainPrefix?: Maybe<Scalars['String']>
    /** The phishing domain used for replies */
    replyPhishDomain?: Maybe<PhishDomain>
    /** Determines if an email is sent after a phishing campaign run */
    sendEmailAfterRun: Scalars['Boolean']
    /** The duration for sending emails in the phishing campaign */
    sendingDuration?: Maybe<Scalars['Int']>
    /** The units for the sending duration */
    sendingDurationUnits?: Maybe<Durations>
    /** Determines if emails are spread over the sending duration */
    spreadEmails?: Maybe<Scalars['Boolean']>
    /** Determines if the content for replies should be stored */
    storeReplyContent: Scalars['Boolean']
    /** The categories of email templates available for the phishing campaign */
    templateCategories: Array<PhishingTemplateCategory>
    /** The time zone used for scheduling the phishing campaign */
    timeZone: TimeZones
    /** Determines if out-of-office replies are tracked */
    trackOutOfOfficeReplies: Scalars['Boolean']
    /** Determines if replies are tracked */
    trackReplies: Scalars['Boolean']
    /** The duration for tracking emails in the phishing campaign */
    trackingDuration: Scalars['Int']
    /** The units for the tracking duration */
    trackingDurationUnits: Durations
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type ManagedPhishingCampaignAttributes = {
  accountIds?: Maybe<Array<Scalars['Int']>>
  emailTemplateId?: Maybe<Scalars['Int']>
  frequencyPeriod?: Maybe<Frequencies>
  groupIds?: Maybe<Array<Scalars['Int']>>
  hideFromReports?: Maybe<Scalars['Boolean']>
  landingPageId?: Maybe<Scalars['Int']>
  lastRun?: Maybe<Scalars['ISO8601DateTime']>
  localizedCampaign?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  nextRunDate?: Maybe<Scalars['ISO8601Date']>
  nextRunTime?: Maybe<Scalars['Time']>
  phishDomainId?: Maybe<Scalars['Int']>
  preventAccountEdits?: Maybe<Scalars['Boolean']>
  rating?: Maybe<Array<EmailTemplateDifficultyRatings>>
  replyDomainPrefix?: Maybe<Scalars['String']>
  replyPhishDomainId?: Maybe<Scalars['Int']>
  selectedTemplateCategories?: Maybe<Array<Scalars['Int']>>
  sendEmailAfterRun?: Maybe<Scalars['Boolean']>
  sendingDuration?: Maybe<Scalars['Int']>
  sendingDurationUnits?: Maybe<Durations>
  spreadEmails?: Maybe<Scalars['Boolean']>
  storeReplyContent?: Maybe<Scalars['Boolean']>
  timeZone?: Maybe<TimeZones>
  trackOutOfOfficeReplies?: Maybe<Scalars['Boolean']>
  trackReplies?: Maybe<Scalars['Boolean']>
  trackingDuration?: Maybe<Scalars['Int']>
  trackingDurationUnits?: Maybe<Durations>
}

/** Autogenerated return type of ManagedPhishingCampaignsCreate. */
export type ManagedPhishingCampaignsCreatePayload = {
  __typename?: 'ManagedPhishingCampaignsCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ManagedPhishingCampaign>
}

export type ManagedPhishingCampaignsCursor = {
  __typename?: 'ManagedPhishingCampaignsCursor'
  /** The paginated nodes */
  nodes: Array<ManagedPhishingCampaign>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of ManagedPhishingCampaignsDeactivate. */
export type ManagedPhishingCampaignsDeactivatePayload = {
  __typename?: 'ManagedPhishingCampaignsDeactivatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ManagedPhishingCampaign>
}

/** Autogenerated return type of ManagedPhishingCampaignsDelete. */
export type ManagedPhishingCampaignsDeletePayload = {
  __typename?: 'ManagedPhishingCampaignsDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ManagedPhishingCampaign>
}

/** Autogenerated return type of ManagedPhishingCampaignsHideFromReports. */
export type ManagedPhishingCampaignsHideFromReportsPayload = {
  __typename?: 'ManagedPhishingCampaignsHideFromReportsPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ManagedPhishingCampaign>
}

/** Autogenerated return type of ManagedPhishingCampaignsUpdate. */
export type ManagedPhishingCampaignsUpdatePayload = {
  __typename?: 'ManagedPhishingCampaignsUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ManagedPhishingCampaign>
}

/** A managed store purchase */
export type ManagedStorePurchase = Identifier &
  StorePurchaseInterface &
  Timestamps & {
    __typename?: 'ManagedStorePurchase'
    /** The alternatives for the purchased course */
    alternatives: Array<CourseAlternative>
    /** Indicates if the purchased course is archived */
    archived?: Maybe<Scalars['Boolean']>
    /** Indicates if the purchased course is an assessment */
    assessment?: Maybe<Scalars['Boolean']>
    /** The type of assessment associated with the purchased course */
    assessmentType?: Maybe<AssessmentTypes>
    /** The type of asset associated with the purchased course */
    assetType: Scalars['String']
    /** Indicates if the purchase is part of a beta program */
    beta?: Maybe<Scalars['Boolean']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The description of the purchased course */
    description?: Maybe<Scalars['String']>
    /** The duration of the purchased course */
    duration?: Maybe<Scalars['Int']>
    /** Indicates if the purchase is hidden */
    hidden?: Maybe<Scalars['Boolean']>
    /** The ID of the managed store purchase */
    id: Scalars['Int']
    /** A polymorphic identifier for the purchase */
    polymorphicId: Scalars['String']
    /** The published date of the purchased course */
    publishedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The date and time when the purchase was made */
    purchasedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** Indicates if the purchased course is retired */
    retired?: Maybe<Scalars['Boolean']>
    /** Indicates if the purchased course is retiring soon */
    retiringSoon?: Maybe<Scalars['Boolean']>
    /** Statistics related to the purchased course */
    stats: PurchasedCourseStats
    /** The store item associated with this purchase */
    storeItem?: Maybe<StoreItemUnion>
    /** The source from which the store item was acquired */
    storeItemSource?: Maybe<Scalars['String']>
    /** The type of the store item */
    storeItemType?: Maybe<Scalars['String']>
    /** The ID of the store item */
    storeItemUuid?: Maybe<Scalars['String']>
    /** The title of the purchased course */
    title: Scalars['String']
    /** The status of the training campaign */
    trainingCampaignBadge?: Maybe<Scalars['String']>
    /** Training campaigns associated with the purchase */
    trainingCampaigns: Array<ManagedTrainingCampaign>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** Indicates if the purchased course is uploaded */
    uploaded?: Maybe<Scalars['Boolean']>
  }

export type ManagedStorePurchaseAttributes = {
  hidden?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of ManagedStorePurchasesAddToChildrensLibraries. */
export type ManagedStorePurchasesAddToChildrensLibrariesPayload = {
  __typename?: 'ManagedStorePurchasesAddToChildrensLibrariesPayload'
  errors?: Maybe<Array<Error>>
  node: Scalars['Int']
}

/** Autogenerated return type of ManagedStorePurchasesCreate. */
export type ManagedStorePurchasesCreatePayload = {
  __typename?: 'ManagedStorePurchasesCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ManagedStorePurchase>
}

export type ManagedStorePurchasesCursor = {
  __typename?: 'ManagedStorePurchasesCursor'
  /** The paginated nodes */
  nodes: Array<ManagedStorePurchase>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of ManagedStorePurchasesRemove. */
export type ManagedStorePurchasesRemovePayload = {
  __typename?: 'ManagedStorePurchasesRemovePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ManagedStorePurchase>
}

/** Autogenerated return type of ManagedStorePurchasesUpdate. */
export type ManagedStorePurchasesUpdatePayload = {
  __typename?: 'ManagedStorePurchasesUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ManagedStorePurchase>
}

/** A managed training campaign */
export type ManagedTrainingCampaign = Identifier &
  Timestamps &
  TrainingCampaignInterface & {
    __typename?: 'ManagedTrainingCampaign'
    /** IDs of the accounts associated with the managed training campaign */
    accountIds: Array<Scalars['Int']>
    /** Accounts associated with the managed training campaign */
    accounts: Array<Account>
    /** Indicates if the training campaign is active */
    active: Scalars['Boolean']
    /** Indicates if past due completions are allowed */
    allowPastDueCompletions: Scalars['Boolean']
    /** Indicates if survey comments are allowed */
    allowSurveyComments: Scalars['Boolean']
    /** Indicates if surveys are allowed */
    allowSurveys: Scalars['Boolean']
    /** Indicates if assessment scores are anonymized */
    anonymizeAssessmentScores?: Maybe<Scalars['Boolean']>
    /** Assets associated with the training campaign */
    assets: Array<TrainingCampaignAsset>
    /** Indicates if users are auto-enrolled */
    autoEnroll?: Maybe<Scalars['Boolean']>
    /** Indicates if users are auto-unenrolled */
    autoUnenroll?: Maybe<Scalars['Boolean']>
    /** Training campaigns associated with the managed training campaign */
    campaigns: Array<TrainingCampaign>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The creator of the managed training campaign */
    creator?: Maybe<PartnerAdmin>
    /** Indicates if the training campaign is soft deleted */
    deleted: Scalars['Boolean']
    endCampaignAtDate?: Maybe<Scalars['ISO8601Date']>
    endCampaignAtTime?: Maybe<Scalars['Time']>
    /** The end campaign option */
    endCampaignOption?: Maybe<TrainingCampaignEnd>
    /** The end date of the training campaign */
    endsAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The duration of the training campaign */
    enrollmentDuration?: Maybe<Scalars['Int']>
    /** The type of duration for the training campaign */
    enrollmentDurationType?: Maybe<TrainingCampaignDurations>
    /** IDs of the groups associated with the managed training campaign */
    groupIds: Array<Scalars['Int']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The delay in days for knowledge refreshers */
    knowledgeRefreshersDelayInDays?: Maybe<Scalars['Int']>
    /** Indicates if knowledge refreshers are enabled */
    knowledgeRefreshersEnabled?: Maybe<Scalars['Boolean']>
    /** The name of the training campaign */
    name: Scalars['String']
    /** Notifications associated with the managed training campaign */
    notifications: Array<ManagedNotification>
    /** The percentage of the training campaign completed */
    percentComplete: Scalars['Int']
    /** Flag indicating whether account edits are prevented */
    preventAccountEdits?: Maybe<Scalars['Boolean']>
    /** Indicates if auto-enrollments are reset */
    resetAutoEnrollments?: Maybe<Scalars['Boolean']>
    /** The selected content for the training campaign */
    selectedContent?: Maybe<Array<Scalars['String']>>
    startCampaignAtDate?: Maybe<Scalars['ISO8601Date']>
    startCampaignAtTime?: Maybe<Scalars['Time']>
    /** The start date of the training campaign */
    startsAt: Scalars['ISO8601DateTime']
    /** The status of the training campaign */
    status: TrainingCampaignStatuses
    /** The time zone of the training campaign */
    timeZone?: Maybe<TimeZones>
    /** Indicates if scores are tracked */
    trackScores?: Maybe<Scalars['Boolean']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type ManagedTrainingCampaignAttributes = {
  accountIds?: Maybe<Array<Scalars['Int']>>
  allowPastDueCompletions?: Maybe<Scalars['Boolean']>
  allowSurveyComments?: Maybe<Scalars['Boolean']>
  allowSurveys?: Maybe<Scalars['Boolean']>
  anonymizeAssessmentScores?: Maybe<Scalars['Boolean']>
  autoEnroll?: Maybe<Scalars['Boolean']>
  autoUnenroll?: Maybe<Scalars['Boolean']>
  deleted?: Maybe<Scalars['Boolean']>
  endCampaignAtDate?: Maybe<Scalars['ISO8601Date']>
  endCampaignAtTime?: Maybe<Scalars['Time']>
  enrollmentDuration?: Maybe<Scalars['Int']>
  enrollmentDurationType?: Maybe<TrainingCampaignDurations>
  groupIds?: Maybe<Array<Scalars['Int']>>
  knowledgeRefreshersDelayInDays?: Maybe<Scalars['Int']>
  knowledgeRefreshersEnabled?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  notifications: Array<ManagedNotificationAttributes>
  preventAccountEdits?: Maybe<Scalars['Boolean']>
  resetAutoEnrollments?: Maybe<Scalars['Boolean']>
  selectedContent?: Maybe<Array<Scalars['String']>>
  startCampaignAtDate?: Maybe<Scalars['ISO8601Date']>
  startCampaignAtTime?: Maybe<Scalars['Time']>
  status?: Maybe<TrainingCampaignStatuses>
  timeZone?: Maybe<TimeZones>
  trackScores?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of ManagedTrainingCampaignsClose. */
export type ManagedTrainingCampaignsClosePayload = {
  __typename?: 'ManagedTrainingCampaignsClosePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ManagedTrainingCampaign>
}

/** Autogenerated return type of ManagedTrainingCampaignsCreate. */
export type ManagedTrainingCampaignsCreatePayload = {
  __typename?: 'ManagedTrainingCampaignsCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ManagedTrainingCampaign>
}

export type ManagedTrainingCampaignsCursor = {
  __typename?: 'ManagedTrainingCampaignsCursor'
  /** The paginated nodes */
  nodes: Array<ManagedTrainingCampaign>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of ManagedTrainingCampaignsDelete. */
export type ManagedTrainingCampaignsDeletePayload = {
  __typename?: 'ManagedTrainingCampaignsDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ManagedTrainingCampaign>
}

/** Autogenerated return type of ManagedTrainingCampaignsUpdate. */
export type ManagedTrainingCampaignsUpdatePayload = {
  __typename?: 'ManagedTrainingCampaignsUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ManagedTrainingCampaign>
}

/** The result of a course check */
export type ManagedTrainingCourseCheckResult = {
  __typename?: 'ManagedTrainingCourseCheckResult'
  /** Accounts that can see some content */
  partialAccounts: Array<Account>
  /** A list of store items related to the managed training course */
  storeItems: Array<StoreItem>
  /** Accounts that can't see any content */
  totalAccounts: Array<Account>
}

/** Manager stats */
export type ManagerReport = Identifier &
  Timestamps & {
    __typename?: 'ManagerReport'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Listing of direct reports */
    directReports?: Maybe<Array<DirectReport>>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Count of mandatory enrollments completed by users */
    mandatoryEnrollmentsCompleteCount: Scalars['Int']
    /** Total count of mandatory enrollments */
    mandatoryEnrollmentsCount: Scalars['Int']
    /** Count of phishing test failures */
    phishingFailures?: Maybe<Scalars['Int']>
    /** Count of phishing incidents not reported */
    phishingNotReported?: Maybe<Scalars['Int']>
    /** Count of phishing incidents reported */
    phishingReported?: Maybe<Scalars['Int']>
    /** Total incidents of phishing */
    phishingTotal?: Maybe<Scalars['Int']>
    /** Phish-prone Percentage */
    ppp?: Maybe<Scalars['Float']>
    /** Overall Risk Score */
    riskScore?: Maybe<Scalars['Float']>
    /** Total number of members in the report */
    totalMembersCount: Scalars['Int']
    /** Percentage of completed training by members */
    trainingPercentComplete: Scalars['Float']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** List of users with complete enrollments */
    usersWithCompleteEnrollments: Array<DirectReport>
    /** Count of users with complete enrollments */
    usersWithCompleteEnrollmentsCount: Scalars['Int']
    /** List of users with complete optional enrollments */
    usersWithCompleteOptionalEnrollments: Array<DirectReport>
    /** Count of users with complete optional enrollments */
    usersWithCompleteOptionalEnrollmentsCount: Scalars['Int']
    /** List of users with enrollments due soon */
    usersWithDueSoonEnrollments: Array<DirectReport>
    /** Count of users with enrollments due soon */
    usersWithDueSoonEnrollmentsCount: Scalars['Int']
    /** List of users with overdue enrollments */
    usersWithOverDueEnrollments: Array<DirectReport>
    /** Count of users with overdue enrollments */
    usersWithOverDueEnrollmentsCount: Scalars['Int']
    /** List of users with phishing test failures */
    usersWithPhishingFailures: Array<DirectReport>
    /** Count of users with phishing test failures */
    usersWithPhishingFailuresCount?: Maybe<Scalars['Int']>
  }

/** Manager stats */
export type ManagerReportDirectReportsArgs = {
  id?: Maybe<Scalars['Int']>
}

export type MappedUserData = {
  __typename?: 'MappedUserData'
  /** List of aliases for the user */
  aliases?: Maybe<Array<AliasData>>
  /** Score representing the user's ability to identify phishing attempts */
  antiPhishingScore?: Maybe<Scalars['Int']>
  /** Score representing the user's cybersecurity awareness */
  awarenessScore?: Maybe<Scalars['Int']>
  /** The date when the user was created */
  createdDate?: Maybe<Scalars['ISO8601DateTime']>
  /** Department within the organization to which the user belongs */
  department?: Maybe<Scalars['String']>
  /** Email address of the user */
  email?: Maybe<Scalars['String']>
  /** Title of the employee within the organization */
  employeeTitle?: Maybe<Scalars['String']>
  /** First name of the user */
  firstName?: Maybe<Scalars['String']>
  /** List of emails associated with the host name */
  hostNameEmails?: Maybe<Array<HostNameEmailsData>>
  /** The unique identifier of the user */
  id?: Maybe<Scalars['Int']>
  /** Indicates whether the user is currently active within the organization */
  isActive?: Maybe<Scalars['Boolean']>
  /** Last name of the user */
  lastName?: Maybe<Scalars['String']>
  /** Location of the user within the organization */
  location?: Maybe<Scalars['String']>
  /** Email address of the user's manager */
  managerEmail?: Maybe<Scalars['String']>
  /** Unique identifier of the user's manager */
  managerId?: Maybe<Scalars['Int']>
  /** The date when the user's information was last modified */
  modifiedDate?: Maybe<Scalars['ISO8601DateTime']>
  /** Unique identifier of the organization to which the user belongs */
  organizationId?: Maybe<Scalars['Int']>
  /** Score representing the user's efficiency in reporting phishing attempts */
  phishingReportScore?: Maybe<Scalars['Int']>
  /** Source from which the user data was obtained */
  source?: Maybe<Scalars['String']>
  /** Score representing the user's progress in cybersecurity training programs */
  trainingScore?: Maybe<Scalars['Int']>
}

export type MappedUsersList = {
  __typename?: 'MappedUsersList'
  /** The data containing mapped user information */
  data: Array<MappedUserData>
  /** The draw counter that this object is a response to */
  draw: Scalars['Int']
  /** The total number of user records after filtering */
  recordsFiltered: Scalars['Int']
  /** The total number of user records available */
  recordsTotal: Scalars['Int']
}

export type MappingRecommendationFilterData = {
  __typename?: 'MappingRecommendationFilterData'
  /** The unique identifier of the mapping recommendation filter */
  id?: Maybe<Scalars['String']>
  /** Textual description or name related to the recommendation filter */
  text?: Maybe<Scalars['String']>
}

export type MappingRule = {
  __typename?: 'MappingRule'
  /** A list of delimiters used in the mapping rule */
  delimiterList: Array<Delimiters>
  /** A list of fields associated with the mapping rule */
  fieldList: Array<Scalars['String']>
  /** The unique identifier of the mapping rule */
  id: Scalars['ID']
  /** Indicates whether the mapping rule is active */
  isActive: Scalars['Boolean']
  /** The key associated with the mapping rule */
  key: Scalars['String']
  /** The name of the vendor associated with the mapping rule */
  vendorName: Scalars['String']
  /** The verb associated with the mapping rule */
  verb: RuleVerb
}

export enum MappingRuleTypes {
  Custom = 'custom',
  System = 'system',
}

export type MappingRules = {
  __typename?: 'MappingRules'
  /** Custom rules defined for mapping */
  customRules: Array<MappingRule>
  /** System-generated rules for mapping */
  systemRules: Array<MappingRule>
}

/** A user membership */
export type Membership = Identifier &
  Timestamps & {
    __typename?: 'Membership'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The group associated with the membership */
    group?: Maybe<Group>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The user associated with the membership */
    user: User
  }

/** Message content */
export type MessageContent = Identifier &
  Timestamps & {
    __typename?: 'MessageContent'
    /** The body of the message */
    body: Scalars['String']
    /** The content of the message */
    content: Scalars['String']
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The unique identifier for the email template used */
    emailTemplateId?: Maybe<Scalars['Int']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The language in which the message is written */
    language: Scalars['String']
    /** The subject of the message */
    subject: Scalars['String']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type MessageContentAttributes = {
  body: Scalars['String']
  emailTemplateId?: Maybe<Scalars['Int']>
  language: Scalars['String']
  subject: Scalars['String']
}

export type MessageSentData = {
  __typename?: 'MessageSentData'
  /** Error message if the slack message delivery fails */
  error?: Maybe<Scalars['String']>
  /** The job ID associated with the slack message delivery */
  jobId?: Maybe<Scalars['Int']>
  /** The text content of the slack message */
  text?: Maybe<Scalars['String']>
}

/** Message topic for campaigns and runs */
export type MessageTopic = {
  __typename?: 'MessageTopic'
  /** The ID of the message topic */
  id: Scalars['Int']
  /** The ID of the messages campaign associated with this message topic */
  messagesCampaignId: Scalars['Int']
  /** The ID of the topic associated with this message topic */
  topicId: Scalars['Int']
}

export type MessagesAttributes = {
  allUsers: Scalars['Boolean']
  campaignType: MessagesCampaignTypes
  customBody?: Maybe<Scalars['String']>
  customLanguage?: Maybe<Scalars['String']>
  customSubject?: Maybe<Scalars['String']>
  frequencyPeriod?: Maybe<MessagesCampaignFrequencyTypes>
  fromEmail?: Maybe<Scalars['String']>
  fromName?: Maybe<Scalars['String']>
  messageContentAttributes: MessageContentAttributes
  name: Scalars['String']
  nextRun?: Maybe<Scalars['ISO8601DateTime']>
  recurringDayOfWeek?: Maybe<Scalars['Int']>
  selectedGroups: Array<Scalars['Int']>
  selectedTopics?: Maybe<Array<Scalars['Int']>>
  sendEmail?: Maybe<Scalars['Boolean']>
  useCompanyLogo?: Maybe<Scalars['Boolean']>
}

export enum MessagesCampaignFrequencyTypes {
  Biweek = 'BIWEEK',
  Month = 'MONTH',
  Week = 'WEEK',
}

export enum MessagesCampaignRecipientStatuses {
  Created = 'CREATED',
  Delivered = 'DELIVERED',
  Fetched = 'FETCHED',
  Read = 'READ',
}

export enum MessagesCampaignStatuses {
  Delivered = 'DELIVERED',
  Failed = 'FAILED',
  Generating = 'GENERATING',
  Pending = 'PENDING',
  Pregenerating = 'PREGENERATING',
  Scheduled = 'SCHEDULED',
}

export enum MessagesCampaignTypes {
  Custom = 'CUSTOM',
  CybersecurityAwarenessMonth = 'CYBERSECURITY_AWARENESS_MONTH',
  HintsAndTips = 'HINTS_AND_TIPS',
  ScamOfTheWeek = 'SCAM_OF_THE_WEEK',
}

/** Autogenerated return type of MessagesCreateCampaign. */
export type MessagesCreateCampaignPayload = {
  __typename?: 'MessagesCreateCampaignPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Campaign>
}

/** Autogenerated return type of MessagesDeleteCampaign. */
export type MessagesDeleteCampaignPayload = {
  __typename?: 'MessagesDeleteCampaignPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Campaign>
}

/** Autogenerated return type of MessagesSetCampaignActiveState. */
export type MessagesSetCampaignActiveStatePayload = {
  __typename?: 'MessagesSetCampaignActiveStatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Campaign>
}

/** Autogenerated return type of MessagesSetRunArchiveState. */
export type MessagesSetRunArchiveStatePayload = {
  __typename?: 'MessagesSetRunArchiveStatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Run>
}

/** Autogenerated return type of MessagesUpdateCampaign. */
export type MessagesUpdateCampaignPayload = {
  __typename?: 'MessagesUpdateCampaignPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Campaign>
}

/** Autogenerated return type of MessagesUpdateMessageRecipient. */
export type MessagesUpdateMessageRecipientPayload = {
  __typename?: 'MessagesUpdateMessageRecipientPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<LearnerMessage>
}

/** Event metadata */
export type Metadata =
  | AuditMetadata
  | BounceMetadata
  | BreachMetadata
  | BrowserMetadata
  | CommonMetadata
  | EnrollmentMetadata
  | PiqMetadata
  | SchMetadata

export type MissedOpportunities = {
  __typename?: 'MissedOpportunities'
  /** The number of days for which data is being represented */
  days: Scalars['Int']
  /** A collection of error messages, if any */
  errors?: Maybe<Array<Error>>
  /** A collection of missed security opportunities */
  nodes?: Maybe<Array<MissedOpportunity>>
}

export type MissedOpportunity = {
  __typename?: 'MissedOpportunity'
  /** The count of missed opportunities */
  missedOpportunityCount: Scalars['Int']
  /** The unique identifier of the workflow */
  workflowId: Scalars['String']
}

/** A ModStore recommendation */
export type ModstoreRecommendation = {
  __typename?: 'ModstoreRecommendation'
  /** The enrollment associated with this recommendation, if any */
  enrollment?: Maybe<Enrollment>
  /** The rating for the recommendation */
  rating: Scalars['Float']
  /** The store item associated with this recommendation */
  storeItem: StoreItem
  /** The ID of the recommendation */
  uuid: Scalars['String']
}

/** All ModStore recommendations for an account */
export type ModstoreRecommendations = {
  __typename?: 'ModstoreRecommendations'
  /** A list of phishing module recommendations */
  phishingRecommendations: Array<ModstoreRecommendation>
  /** A list of training module recommendations */
  trainingRecommendations: Array<ModstoreRecommendation>
}

export type ModstoreTopic = {
  name: Scalars['String']
  translationKey: Scalars['String']
}

export type MostClickedEmailTemplate = {
  __typename?: 'MostClickedEmailTemplate'
  /** The number of clicks the email template has received */
  clicks: Scalars['Int']
  /** The name of the most clicked email template */
  name: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

/** An account */
export type MultiInstanceResult = {
  __typename?: 'MultiInstanceResult'
  /** The code associated with the multi-instance result */
  code?: Maybe<Scalars['String']>
  /** Indicates whether the current instance is active */
  currentInstance: Scalars['Boolean']
  /** A list of multi-instance results for accounts */
  results?: Maybe<Array<AccountMultiInstance>>
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Deletes the account if the deletion request is still valid */
  accountConfirmDeletion?: Maybe<AccountsConfirmDeletionPayload>
  accountCreate?: Maybe<AccountsCreatePayload>
  /** Creates a new PAB instance for the account */
  accountCreatePabInstance?: Maybe<AccountsCreatePabInstancePayload>
  /** Deletes the completion certificate for the account */
  accountDeleteCert?: Maybe<AccountsDeleteCertPayload>
  /** Deletes an account that can be deleted by only one person */
  accountDeleteFree?: Maybe<AccountsDeleteFreePayload>
  /** Deletes the logo for the account */
  accountDeleteLogo?: Maybe<AccountsDeleteLogoPayload>
  /** Deletes the PAB icon for a Phish Alert setting for the account */
  accountDeletePabIcon?: Maybe<AccountsDeletePabIconPayload>
  /** Deletes a PAB instance for an account */
  accountDeletePabInstance?: Maybe<AccountsDeletePabInstancePayload>
  accountDeletePhisher?: Maybe<AccountsDeletePhisherPayload>
  accountInvokeReseed?: Maybe<AccountsInvokeReseedPayload>
  /** Updates the partner access expiration for the account */
  accountPrivacyUpdateAccess?: Maybe<AccountSettingsPrivacyUpdateAccessPayload>
  accountRefreshAssessmentScores?: Maybe<AccountsRefreshAssessmentScoresPayload>
  accountRefreshData?: Maybe<AccountsRefreshDataPayload>
  /** Regenerates a token for an account */
  accountRegenerateToken?: Maybe<AccountsRegenerateTokenPayload>
  /** Marks the account as requested to be deleted */
  accountRequestDeletion?: Maybe<AccountsRequestDeletionPayload>
  accountResetFreeCampaign?: Maybe<AccountsResetFreeCampaignPayload>
  /** Deletes a token for the account */
  accountRevokeToken?: Maybe<AccountsRevokeTokenPayload>
  accountSalesforceDisconnect?: Maybe<AccountSettingsSalesforceDisconnectPayload>
  /** Get Salesforce data for account */
  accountSalesforceFetch?: Maybe<AccountSettingsSalesforceFetchPayload>
  /** Schedule Salesforce data fetch for account */
  accountSalesforceScheduleFetch?: Maybe<AccountSettingsSalesforceScheduleFetchPayload>
  /** Starts a breach scan for the account */
  accountStartEec?: Maybe<AccountsStartEecPayload>
  accountToggleArchived?: Maybe<AccountsToggleArchivedPayload>
  accountUpdate?: Maybe<AccountsUpdatePayload>
  /** Creates a CSV of the AIDA campaign summary table */
  aidaCampaignSummaryTableCsv?: Maybe<DownloadCenterCustomReportsAidaCampaignSummaryTablePayload>
  /** Disable the current AIDA campaign */
  aidaCampaignsDisable?: Maybe<AidaCampaignsDisablePayload>
  /** Setup AIDA Remediation training */
  aidaCampaignsEnable?: Maybe<AidaCampaignsEnablePayload>
  /** Updates an AIDA Policy Quiz question option */
  aidaPolicyQuizQuestionOptionUpdate?: Maybe<AidaPolicyQuizQuestionOptionsUpdatePayload>
  /** Updates an AIDA Policy Quiz question */
  aidaPolicyQuizQuestionUpdate?: Maybe<AidaPolicyQuizQuestionsUpdatePayload>
  /** Generate new questions for an AIDA Policy Quiz */
  aidaPolicyQuizRegenerate?: Maybe<AidaPolicyQuizzesRegeneratePayload>
  /** Updates an AIDA Policy Quiz */
  aidaPolicyQuizUpdate?: Maybe<AidaPolicyQuizzesUpdatePayload>
  /** Creates a new allowed domain */
  allowedDomainCreate?: Maybe<AllowedDomainsCreatePayload>
  /** Deletes an allowed domain */
  allowedDomainDelete?: Maybe<AllowedDomainsDeletePayload>
  /** Imports a list of allowed domains */
  allowedDomainImport?: Maybe<AllowedDomainsImportPayload>
  /** Tries to verify an allowed domain's DNS records */
  allowedDomainVerify?: Maybe<AllowedDomainsVerifyPayload>
  /** Create an API token */
  apiTokensCreate?: Maybe<ApiTokensCreatePayload>
  /** Delete an API Token */
  apiTokensDelete?: Maybe<ApiTokensDeletePayload>
  /** Reassign API token(s) to current admin */
  apiTokensReassign?: Maybe<ApiTokensReassignPayload>
  /** Update an API token */
  apiTokensUpdate?: Maybe<ApiTokensUpdatePayload>
  /** Create an ASAP program for the current account */
  asapCreateProgram?: Maybe<AsapCreateProgramPayload>
  /** Create an ASAP program task for the current account's program */
  asapCreateProgramTask?: Maybe<AsapCreateProgramTaskPayload>
  /** Delete an ASAP program for the current account */
  asapDeleteProgram?: Maybe<AsapDeleteProgramPayload>
  /** Create an ASAP program for the current account */
  asapUpdateProgram?: Maybe<AsapUpdateProgramPayload>
  /** Update an ASAP program task */
  asapUpdateProgramTask?: Maybe<AsapUpdateProgramTaskPayload>
  /** See if enrollment unlocks a badge */
  badgesEarn?: Maybe<BadgesEarnPayload>
  /** Marks badges as acknowledged */
  badgesMarkAsAcknowledged?: Maybe<BadgesAcknowledgePayload>
  /** Marks a badge as viewed */
  badgesMarkAsViewed?: Maybe<BadgesViewPayload>
  /** Deletes the audio file for a callback phishing audio template message */
  callbackPhishingAudioTemplateMessagesDeleteAudioFile?: Maybe<CallbackPhishingAudioTemplateMessagesDeleteAudioFilePayload>
  /** Creates a user callback phishing greeting template */
  callbackPhishingGreetingTemplateCreate?: Maybe<CallbackPhishingGreetingTemplatesCreatePayload>
  /** Updates a callback phishing greeting template */
  callbackPhishingGreetingTemplateUpdate?: Maybe<CallbackPhishingGreetingTemplatesUpdatePayload>
  /** Adds topics to callback phishing greeting templates */
  callbackPhishingGreetingTemplatesAddTopics?: Maybe<CallbackPhishingGreetingTemplatesAddTopicsPayload>
  /** Copies a set of callback phishing greeting templates into the current account */
  callbackPhishingGreetingTemplatesCopy?: Maybe<CallbackPhishingGreetingTemplatesCopyPayload>
  /** Deletes callback phishing greeting templates */
  callbackPhishingGreetingTemplatesDelete?: Maybe<CallbackPhishingGreetingTemplatesDeletePayload>
  /** Removes topics from callback phishing greeting templates */
  callbackPhishingGreetingTemplatesRemoveTopics?: Maybe<CallbackPhishingGreetingTemplatesRemoveTopicsPayload>
  /** Links a phone number in AWS to the DynamoDB table and this database */
  callbackPhishingPhoneNumberLinkOrphan?: Maybe<CallbackPhishingPhoneNumbersLinkOrphanPayload>
  /** Releases a phone number back to AWS and archives it in our database */
  callbackPhishingPhoneNumberRelease?: Maybe<CallbackPhishingPhoneNumbersReleasePayload>
  /** Toggles a phone number on and off for potential deletion */
  callbackPhishingPhoneNumberToggleDeletion?: Maybe<CallbackPhishingPhoneNumbersToggleDeletionPayload>
  /** Creates a user callback phishing response template */
  callbackPhishingResponseTemplateCreate?: Maybe<CallbackPhishingResponseTemplatesCreatePayload>
  /** Updates a callback phishing response template */
  callbackPhishingResponseTemplateUpdate?: Maybe<CallbackPhishingResponseTemplatesUpdatePayload>
  /** Adds topics to callback phishing response templates */
  callbackPhishingResponseTemplatesAddTopics?: Maybe<CallbackPhishingResponseTemplatesAddTopicsPayload>
  /** Copies a set of callback phishing response templates into the current account */
  callbackPhishingResponseTemplatesCopy?: Maybe<CallbackPhishingResponseTemplatesCopyPayload>
  /** Deletes callback phishing response templates */
  callbackPhishingResponseTemplatesDelete?: Maybe<CallbackPhishingResponseTemplatesDeletePayload>
  /** Removes topics from callback phishing response templates */
  callbackPhishingResponseTemplatesRemoveTopics?: Maybe<CallbackPhishingResponseTemplatesRemoveTopicsPayload>
  /** Creates a user callback phishing template */
  callbackPhishingTemplateCreate?: Maybe<CallbackPhishingTemplatesCreatePayload>
  /** Updates a callback phishing template */
  callbackPhishingTemplateUpdate?: Maybe<CallbackPhishingTemplatesUpdatePayload>
  /** Copies a set of callback phishing templates into the current account */
  callbackPhishingTemplatesCopy?: Maybe<CallbackPhishingTemplatesCopyPayload>
  /** Creates a test email for a callback phishing campaign */
  callbackPhishingTestEmailCreate?: Maybe<CallbackPhishingTestEmailsCreatePayload>
  /** Create a category */
  cdrmsCreateCategory?: Maybe<SecurityCoachCdrmsCreateCategoryPayload>
  /** Create a rule */
  cdrmsCreateRule?: Maybe<SecurityCoachCdrmsCreateRulePayload>
  /** Create a topic */
  cdrmsCreateTopic?: Maybe<SecurityCoachCdrmsCreateTopicPayload>
  /** Create a vendor */
  cdrmsCreateVendor?: Maybe<SecurityCoachCdrmsCreateVendorPayload>
  /** Delete a category */
  cdrmsDeleteCategory?: Maybe<SecurityCoachCdrmsDeleteCategoryPayload>
  /** Delete a rule */
  cdrmsDeleteRule?: Maybe<SecurityCoachCdrmsDeleteRulePayload>
  /** Delete a topic */
  cdrmsDeleteTopic?: Maybe<SecurityCoachCdrmsDeleteTopicPayload>
  /** Delete a vendor */
  cdrmsDeleteVendor?: Maybe<SecurityCoachCdrmsDeleteVendorPayload>
  /** Release a rule */
  cdrmsReleaseRule?: Maybe<SecurityCoachCdrmsReleaseRulePayload>
  /** Release a vendor to production */
  cdrmsReleaseVendorToProduction?: Maybe<SecurityCoachCdrmsReleaseVendorToProductionPayload>
  /** Update a category */
  cdrmsUpdateCategory?: Maybe<SecurityCoachCdrmsUpdateCategoryPayload>
  /** Update a detection rule dispute */
  cdrmsUpdateDetectionRuleDispute?: Maybe<SecurityCoachCdrmsUpdateDetectionRuleDisputePayload>
  /** Partially update a category */
  cdrmsUpdatePartialCategory?: Maybe<SecurityCoachCdrmsUpdatePartialCategoryPayload>
  /** Partially update a rule */
  cdrmsUpdatePartialRule?: Maybe<SecurityCoachCdrmsUpdatePartialRulePayload>
  /** Partially update a topic */
  cdrmsUpdatePartialTopic?: Maybe<SecurityCoachCdrmsUpdatePartialTopicPayload>
  /** Partially update a vendor */
  cdrmsUpdatePartialVendor?: Maybe<SecurityCoachCdrmsUpdatePartialVendorPayload>
  /** Update a rule */
  cdrmsUpdateRule?: Maybe<SecurityCoachCdrmsUpdateRulePayload>
  /** Update a topic */
  cdrmsUpdateTopic?: Maybe<SecurityCoachCdrmsUpdateTopicPayload>
  /** Update a vendor */
  cdrmsUpdateVendor?: Maybe<SecurityCoachCdrmsUpdateVendorPayload>
  /** Exempts clickwraps from the TOS requirement */
  clickwrapsExempt?: Maybe<ClickwrapsExemptPayload>
  /** Forces clickwraps from the TOS requirement */
  clickwrapsForce?: Maybe<ClickwrapsForcePayload>
  /** Assign a content theme to a selected campaign for the current account */
  contentThemeAssignToTrainingCampaign?: Maybe<ContentThemeAssignToTrainingCampaignPayload>
  /** Clone a content theme for the current account */
  contentThemeClone?: Maybe<ContentThemeClonePayload>
  /** Create a content theme for the current account */
  contentThemeCreate?: Maybe<ContentThemeCreatePayload>
  /** Delete a content theme for the current account */
  contentThemeDelete?: Maybe<ContentThemeDeletePayload>
  /** Remove the content theme from a selected campaign for the current account */
  contentThemeRemoveDefault?: Maybe<ContentThemeRemoveDefaultPayload>
  /** Remove the content theme from a selected campaign for the current account */
  contentThemeRemoveFromTrainingCampaign?: Maybe<ContentThemeRemoveFromTrainingCampaignPayload>
  /** Set the default content theme for the current account */
  contentThemeSetDefault?: Maybe<ContentThemeSetDefaultPayload>
  /** Update a content theme for the current account */
  contentThemeUpdate?: Maybe<ContentThemeUpdatePayload>
  /** Sign out current user, and/or current partner admin */
  currentActorsSignOut?: Maybe<CurrentActorsSignOutPayload>
  /** Create a linked device */
  deviceDetailsCreate?: Maybe<DeviceDetailsCreatePayload>
  /** Delete a linked device */
  deviceDetailsDelete?: Maybe<DeviceDetailsDeletePayload>
  /** Updates a linked device FCM token */
  deviceDetailsUpdate?: Maybe<DeviceDetailsUpdatePayload>
  /** Remove the current account from the phishing template exclusions flag */
  disablePhishingTemplateExclusions?: Maybe<LaunchDarklyDisablePhishingTemplateExclusionsPayload>
  /** Create a DKIM configuration */
  dkimConfigCreate?: Maybe<DkimConfigsCreatePayload>
  /** Delete a DKIM configuration */
  dkimConfigDelete?: Maybe<DkimConfigsDeletePayload>
  /** Update a DKIM configuration */
  dkimConfigUpdate?: Maybe<DkimConfigsUpdatePayload>
  /** Validate the DNS of a DKIM configuration */
  dkimConfigValidate?: Maybe<DkimConfigsValidatePayload>
  /** Create a DMI configuration */
  dmiConfigCreate?: Maybe<DmiConfigsCreatePayload>
  /** Delete a DMI configuration */
  dmiConfigDelete?: Maybe<DmiConfigsDeletePayload>
  /** Reconnect a DMI configuration */
  dmiConfigReconnect?: Maybe<DmiConfigsReconnectPayload>
  /** Update a DMI configuration */
  dmiConfigUpdate?: Maybe<DmiConfigsUpdatePayload>
  /** Creates a report download */
  downloadCreateAccountReportCard?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateActiveRulesReport?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateAidaCampaignSummaryTable?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateAssessmentScoreByKnowledgeArea?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateAssessmentScoreByKnowledgeAreaOverTime?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateAuditLogs?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateEmailsSentByGroup?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateEmailsSentByTemplate?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateFailureTypeUserCounts?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateFailureTypesBarChart?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateFailuresByPhishingTemplate?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateGlobalPhishingAggregate?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateGlobalPhishingRaw?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateGlobalTrainingAggregate?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateGlobalTrainingRaw?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateGroupReportCard?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateIncompleteTraining?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateInitialPpp?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateLearnerPlatformRaw?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateNoTrainingAssigned?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateOverallPppByAccount?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateOverallPppByCampaign?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateOverallPppByGroup?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateOverallPppByTrainingTime?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreatePabComparison?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreatePabUserActivity?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreatePhishFailurePercentageByCampaigns?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreatePhishFailurePercentageOverall?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreatePhishingAllClickers?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreatePhishingSecurityTests?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreatePppDistribution?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateRiskScoreByAssessment?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateRiskScoreByGroup?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateRiskScoreByLocation?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateRiskScoreBySurvey?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateRiskScoreByUser?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateRiskScoreV2ByGroup?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateRiskScoreV2ByManager?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateRiskScoreV2ByUser?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateSecurityCoachActivityRtcAggregate?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateSecurityCoachActivityRtcRaw?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateSecurityCoachDetectionRulesActivityAggregate?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateSecurityCoachDetectionRulesActivityRaw?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateSurveyScoreByDimension?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateSurveyScoreByDimensionOverTime?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateTrainingCampaignEnrollments?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateTrainingCampaignSurveys?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateTrainingComparisonByPpp?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateTrainingComparisonByRiskScore?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateTrainingHours?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateTrainingInteractionsByGroup?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateTrainingInteractionsByPurchasedCourse?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateTrainingNotStarted?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateTrainingStatusByCampaign?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateTrainingStatusByCourse?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateTrainingStatusByGroup?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateTrainingStatusesByCampaign?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateTrainingSurveysByCampaign?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateTrainingSurveysByCourse?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateUnphishedUsers?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateUserCountByAssessment?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateUserCountBySurvey?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateUserCounts?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateUserPhishingCampaigns?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateUserReportCard?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateUserSignIns?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateUserTrainingCampaigns?: Maybe<ReportsCreatePayload>
  /** Creates a report download */
  downloadCreateUsersList?: Maybe<ReportsCreatePayload>
  /** Permanently delete downloads */
  downloadDelete?: Maybe<DownloadCenterDownloadDeletePayload>
  /** Retries a report download */
  downloadReportRetry?: Maybe<DownloadCenterDownloadReportRetryPayload>
  /** Download a scheduled report upon request */
  downloadSavedReport?: Maybe<SavedReportsDownloadPayload>
  /** Marks downloads as viewed */
  downloadViewed?: Maybe<DownloadCenterDownloadViewedPayload>
  eaiGenerateTemplate?: Maybe<EaiPhishingTemplateGenerateTemplatePayload>
  eaiSaveTemplate?: Maybe<EaiPhishingTemplateSaveTemplatePayload>
  eaiSubmitFeedback?: Maybe<EaiPhishingTemplateSubmitFeedbackPayload>
  eaiUpdateTemplate?: Maybe<EaiPhishingTemplateUpdateTemplatePayload>
  /** Creates an email alias for the user */
  emailAliasCreate?: Maybe<EmailAliasesCreatePayload>
  /** Deletes an email alias */
  emailAliasDelete?: Maybe<EmailAliasesDeletePayload>
  /** Deletes a template from an automation account */
  emailTemplateDelete?: Maybe<EmailTemplateDeletePayload>
  /** Add the current account to the aida-global-features flag */
  enableAidaPhishingTemplateGlobal?: Maybe<LaunchDarklyEnableAidaPhishingTemplateGlobalPayload>
  /** Add the current account to the phishing template exclusions flag */
  enablePhishingTemplateExclusions?: Maybe<LaunchDarklyEnablePhishingTemplateExclusionsPayload>
  /** Accept an enrollment URL policy */
  enrollmentAcceptUrlPolicy?: Maybe<EnrollmentAcceptUrlPolicyPayload>
  /** Creates an optional enrollment record for a given ModStore or CCM item UUID */
  enrollmentCreate?: Maybe<EnrollmentCreatePayload>
  /** Starts an enrollment */
  enrollmentStart?: Maybe<EnrollmentStartPayload>
  /** Update an enrollment */
  enrollmentUpdate?: Maybe<EnrollmentUpdatePayload>
  /** Clear the environment questionnaire */
  environmentQuestionnaireClear?: Maybe<EnvironmentQuestionnaireClearPayload>
  /** Save the environment questionnaire */
  environmentQuestionnaireSave?: Maybe<EnvironmentQuestionnaireSavePayload>
  /** Create an executive reports dashboard */
  execReportsDashboardCreate?: Maybe<ExecReportsDashboardCreatePayload>
  /** Create an executive reports dashboard */
  execReportsDashboardDelete?: Maybe<ExecReportsDashboardDeletePayload>
  /** Update an executive reports dashboard */
  execReportsDashboardUpdate?: Maybe<ExecReportsDashboardUpdatePayload>
  /** Creates a PDF download of the executive reports dashboard */
  execReportsPdf?: Maybe<ExecReportsPdfPayload>
  /** Force a new SCIM sync */
  forceSyncScim?: Maybe<AccountSettingsUserProvisioningForceSyncScimPayload>
  /** Creates or updates a free PRT campaign  */
  freePrtCreate?: Maybe<FreeToolsPrtCreatePayload>
  /** Creates or updates a free PST campaign  */
  freePstCreate?: Maybe<FreeToolsPstCreatePayload>
  /** Creates or updates a free QRT campaign  */
  freeQrtCreate?: Maybe<FreeToolsQrtCreatePayload>
  /** Creates or updates a free SPT campaign  */
  freeSptCreate?: Maybe<FreeToolsSptCreatePayload>
  /** Launches a free tool campaign */
  freeToolLaunch?: Maybe<FreeToolsFreeToolLaunchPayload>
  /** Creates a download for active rules report */
  generateActiveRulesReport?: Maybe<DownloadCenterCustomReportsActiveRulesReportPayload>
  /** Creates a download for the list of audit logs */
  generateAuditLogs?: Maybe<DownloadCenterCustomReportsAuditLogsPayload>
  /** Creates a download for PAB report comparison */
  generatePabComparison?: Maybe<DownloadCenterCustomReportsPabComparisonPayload>
  /** Creates a download for the list of users PAB activity */
  generatePabUserActivity?: Maybe<DownloadCenterCustomReportsPabUserActivityPayload>
  /** Creates a download for the list of phishing clickers */
  generatePhishingAllClickers?: Maybe<DownloadCenterCustomReportsPhishingAllClickersPayload>
  /** Creates a download for the phishing security tests report */
  generatePhishingSecurityTests?: Maybe<DownloadCenterCustomReportsPhishingSecurityTestsPayload>
  /** Creates a download for a training campaign's enrollments */
  generateTrainingCampaignEnrollments?: Maybe<DownloadCenterCustomReportsTrainingCampaignEnrollmentsPayload>
  /** Creates a download for a training campaign's surveys */
  generateTrainingCampaignSurveys?: Maybe<DownloadCenterCustomReportsTrainingCampaignSurveysPayload>
  /** Creates a download for the phishing campaigns details from users */
  generateUserPhishingCampaigns?: Maybe<DownloadCenterCustomReportsUserPhishingCampaignsPayload>
  /** Creates a download for the training campaigns details from users */
  generateUserTrainingCampaigns?: Maybe<DownloadCenterCustomReportsUserTrainingCampaignsPayload>
  /** Creates a download for the list of users */
  generateUsersList?: Maybe<DownloadCenterCustomReportsUsersListPayload>
  /** Sends Google PAB emails to multiple users from a CSV file */
  gpabCsvEmail?: Maybe<GpabCsvEmailPayload>
  /** Sends Google PAB emails to multiple users from an email address */
  gpabQuickEmail?: Maybe<GpabQuickEmailPayload>
  /** Archive groups */
  groupArchive?: Maybe<GroupsArchivePayload>
  /** Archive users in a group */
  groupArchiveUsers?: Maybe<GroupsArchiveUsersPayload>
  /** Bulk delete groups from a CSV file */
  groupBulkDelete?: Maybe<GroupsBulkDeletePayload>
  /** Clone a group */
  groupClone?: Maybe<GroupsClonePayload>
  /** Create a group */
  groupCreate?: Maybe<GroupsCreatePayload>
  /** Delete groups */
  groupDelete?: Maybe<GroupsDeletePayload>
  /** Remove users from a group */
  groupRemoveUsers?: Maybe<GroupsRemoveUsersPayload>
  /** Save group criterions */
  groupSaveCriterions?: Maybe<GroupsSaveCriterionsPayload>
  /** Update a group */
  groupUpdate?: Maybe<GroupsUpdatePayload>
  /** Purges an IP range of clicks from the Site Admin area */
  ipRangeAdminPurge?: Maybe<IpRangesAdminPurgePayload>
  /** Creates an IP range for the account */
  ipRangeCreate?: Maybe<IpRangesCreatePayload>
  /** Deletes a IP range for the account */
  ipRangeDelete?: Maybe<IpRangesDeletePayload>
  /** Purges an IP range of failures */
  ipRangePurge?: Maybe<IpRangesPurgePayload>
  /** Updates a IP range for the account */
  ipRangeUpdate?: Maybe<IpRangesUpdatePayload>
  /** Creates a landing domain */
  landingDomainCreate?: Maybe<LandingDomainsCreatePayload>
  /** Deletes a landing domain for the account */
  landingDomainDelete?: Maybe<LandingDomainsDeletePayload>
  /** Archives or unarchives a landing domain */
  landingDomainToggleArchived?: Maybe<LandingDomainsToggleArchivedPayload>
  /** Updates a landing domain */
  landingDomainUpdate?: Maybe<LandingDomainsUpdatePayload>
  /** Creates a landing page category in the current account */
  landingPageCategoryCreate?: Maybe<LandingPagesCategoryCreatePayload>
  /** Deletes a landing page category */
  landingPageCategoryDelete?: Maybe<LandingPagesCategoryDeletePayload>
  /** Updates a landing page category in the current account */
  landingPageCategoryUpdate?: Maybe<LandingPagesCategoryUpdatePayload>
  /** Creates a landing page in the current context */
  landingPageCreate?: Maybe<LandingPagesCreatePayload>
  /** Imports multiple templates based on a JSON format */
  landingPageImport?: Maybe<LandingPagesImportPayload>
  /** Creates a landing page in the current context */
  landingPageUpdate?: Maybe<LandingPagesUpdatePayload>
  /** Commit LMS data to a learner's enrollment */
  lmsCommit?: Maybe<LmsCommitPayload>
  /** Initialize a learner's enrollment */
  lmsInit?: Maybe<LmsInitPayload>
  /** Commit LMS data to a learner's enrollment and terminate session */
  lmsTerminate?: Maybe<LmsTerminatePayload>
  /** Creates a managed phishing campaign for the current partner admin */
  managedPhishingCampaignCreate?: Maybe<ManagedPhishingCampaignsCreatePayload>
  /** Deactivate or activate a managed phishing campaign */
  managedPhishingCampaignDeactivate?: Maybe<ManagedPhishingCampaignsDeactivatePayload>
  /** Delete a managed phishing campaign */
  managedPhishingCampaignDelete?: Maybe<ManagedPhishingCampaignsDeletePayload>
  /** Hide or show a managed phishing campaign from reports */
  managedPhishingCampaignHideFromReports?: Maybe<ManagedPhishingCampaignsHideFromReportsPayload>
  /** Update a managed phishing campaign for the current partner admin */
  managedPhishingCampaignUpdate?: Maybe<ManagedPhishingCampaignsUpdatePayload>
  /** Add a single piece of content to multiple accounts underneath partner */
  managedStorePurchaseAddToChildrensLibraries?: Maybe<ManagedStorePurchasesAddToChildrensLibrariesPayload>
  /** Creates a managed store purchases for the partner */
  managedStorePurchaseCreate?: Maybe<ManagedStorePurchasesCreatePayload>
  /** Remove managed store purchase from the current partner */
  managedStorePurchaseRemove?: Maybe<ManagedStorePurchasesRemovePayload>
  /** Update managed store purchase from the current partner */
  managedStorePurchaseUpdate?: Maybe<ManagedStorePurchasesUpdatePayload>
  /** Close a managed training campaign */
  managedTrainingCampaignClose?: Maybe<ManagedTrainingCampaignsClosePayload>
  /** Creates a nanaged training campaign for the current partner admin */
  managedTrainingCampaignCreate?: Maybe<ManagedTrainingCampaignsCreatePayload>
  /** Delete a managed training campaign */
  managedTrainingCampaignDelete?: Maybe<ManagedTrainingCampaignsDeletePayload>
  /** Update a managed training campaign for the current partner */
  managedTrainingCampaignUpdate?: Maybe<ManagedTrainingCampaignsUpdatePayload>
  /** Creates a learner messaging campaign */
  messageCampaignCreate?: Maybe<MessagesCreateCampaignPayload>
  /** Deletes a learner messaging campaign */
  messageCampaignDelete?: Maybe<MessagesDeleteCampaignPayload>
  /** Updates a learner messaging campaign */
  messageCampaignUpdate?: Maybe<MessagesUpdateCampaignPayload>
  /** Creates a notification */
  notificationCreate?: Maybe<NotificationCreatePayload>
  /** Creates a notification template category in the current account */
  notificationTemplateCategoryCreate?: Maybe<NotificationTemplatesCategoryCreatePayload>
  /** Delete a notification template category */
  notificationTemplateCategoryDelete?: Maybe<NotificationTemplatesCategoryDeletePayload>
  /** Hides or shows a notification template category from an account */
  notificationTemplateCategoryHide?: Maybe<NotificationTemplatesCategoryHidePayload>
  /** Updates a notification template category in the current account */
  notificationTemplateCategoryUpdate?: Maybe<NotificationTemplatesCategoryUpdatePayload>
  /** Creates a notification template in the current account */
  notificationTemplateCreate?: Maybe<NotificationTemplatesCreatePayload>
  /** Hides or shows a notification template from an account */
  notificationTemplateHide?: Maybe<NotificationTemplatesHidePayload>
  /** Imports multiple notification templates based on a JSON format */
  notificationTemplateImport?: Maybe<NotificationTemplatesImportPayload>
  /** Updates a notification template */
  notificationTemplateUpdate?: Maybe<NotificationTemplatesUpdatePayload>
  /** Creates a partner admin */
  partnerAdminCreate?: Maybe<PartnerAdminsCreatePayload>
  /** Deactivate or activate MFA for a partner admin */
  partnerAdminDeactivateMfa?: Maybe<PartnerAdminsDeactivateMfaPayload>
  /** Delete a partner admin */
  partnerAdminDelete?: Maybe<PartnerAdminsDeletePayload>
  /** Disables MFA for the partner (if valid) */
  partnerAdminDisableMfa?: Maybe<PartnerAdminsDisableMfaPayload>
  /** Enables MFA for the partner (if valid) */
  partnerAdminEnableMfa?: Maybe<PartnerAdminsEnableMfaPayload>
  /** Update a partner admin for the current partner */
  partnerAdminUpdate?: Maybe<PartnerAdminsUpdatePayload>
  /** Update the current partner admin */
  partnerAdminUpdateSelf?: Maybe<PartnerAdminsUpdateSelfPayload>
  /** Create an API token */
  partnerApiTokensCreate?: Maybe<PartnersApiTokensCreatePayload>
  /** Deletes an API token */
  partnerApiTokensDelete?: Maybe<PartnersApiTokensDeletePayload>
  /** Reassign API token(s) to current partner */
  partnerApiTokensReassign?: Maybe<PartnersApiTokensReassignPayload>
  /** Update an API token */
  partnerApiTokensUpdate?: Maybe<PartnersApiTokensUpdatePayload>
  /** Creates a main partner */
  partnerCreate?: Maybe<PartnersCreatePayload>
  /** Delete a partner */
  partnerDelete?: Maybe<PartnersDeletePayload>
  /** Delete a partner's logo */
  partnerDeleteLogo?: Maybe<PartnersDeleteLogoPayload>
  partnerSalesforceDisconnect?: Maybe<PartnersSalesforceDisconnectPayload>
  /** Get Salesforce data for partner */
  partnerSalesforceFetch?: Maybe<PartnersSalesforceFetchPayload>
  /** Schedule Salesforce data fetch for partners child accounts */
  partnerSalesforceScheduleAccountsFetch?: Maybe<PartnersSalesforceScheduleAccountsFetchPayload>
  /** Update a partner */
  partnerUpdate?: Maybe<PartnersUpdatePayload>
  /** Update the partner’s account */
  partnerUpdateAccount?: Maybe<PartnersUpdateAccountPayload>
  /** Enable SAML latest certificate for partner */
  partnerUseSamlLatestCertificate?: Maybe<PartnersSamlUseLatestCertificatePayload>
  /** Creates a CSV download of the PIQ vulnerabilities by users */
  passwordIqCsv?: Maybe<PasswordIqCsvPayload>
  /** Create a PasswordIQ dashboard */
  passwordIqDashboardCreate?: Maybe<PasswordIqDashboardCreatePayload>
  /** Create a PasswordIQ dashboard */
  passwordIqDashboardDelete?: Maybe<PasswordIqDashboardDeletePayload>
  /** Update a PIQ Dashboard */
  passwordIqDashboardUpdate?: Maybe<PasswordIqDashboardUpdatePayload>
  /** Creates a pdf download of the PIQ dashboard */
  passwordIqPdf?: Maybe<PasswordIqPdfPayload>
  /** Updates a Password IQ Event. */
  passwordIqScanNow?: Maybe<PasswordIqScanNowPayload>
  /** Updates a Password IQ Schedule. */
  passwordIqScheduleUpdate?: Maybe<PasswordIqScheduleUpdatePayload>
  /** Updates a Password IQ Event. */
  passwordIqUpdateEvent?: Maybe<PasswordIqUpdateEventPayload>
  /** Pause Google sync */
  pauseGoogleSync?: Maybe<AccountSettingsUserProvisioningGooglePauseSyncPayload>
  /** Creates a phish domain */
  phishDomainCreate?: Maybe<PhishDomainsCreatePayload>
  /** Creates a phish domain for the account */
  phishDomainCreateCustom?: Maybe<PhishDomainsCreateCustomPayload>
  /** Deletes a phish domain for the account */
  phishDomainDelete?: Maybe<PhishDomainsDeletePayload>
  /** Archives or unarchives a phish domain */
  phishDomainToggleArchived?: Maybe<PhishDomainsToggleArchivedPayload>
  /** Add or remove a system phish domain to or from the override list for the current account */
  phishDomainToggleOverride?: Maybe<PhishDomainsToggleOverridePayload>
  /** Updates a phish domain */
  phishDomainUpdate?: Maybe<PhishDomainsUpdatePayload>
  /** Creates a phishing campaign in the current account */
  phishingCampaignCreate?: Maybe<PhishingCampaignsCreatePayload>
  /** Deactivate or activate a phishing campaign */
  phishingCampaignDeactivate?: Maybe<PhishingCampaignsDeactivatePayload>
  /** Delete a phishing campaign */
  phishingCampaignDelete?: Maybe<PhishingCampaignsDeletePayload>
  /** Edit a phishing campaign in the current account */
  phishingCampaignEdit?: Maybe<PhishingCampaignsEditPayload>
  /** Hide or show a phishing campaign from reports */
  phishingCampaignHideFromReports?: Maybe<PhishingCampaignsHideFromReportsPayload>
  /** Deletes the content associated with a reply event */
  phishingCampaignRecipientRemoveReply?: Maybe<PhishingCampaignsRecipientRemoveReplyPayload>
  /** Update a phishing campaign recipient */
  phishingCampaignRecipientUpdate?: Maybe<PhishingCampaignsRecipientUpdatePayload>
  /** Bulk change a collection of campaign recipients */
  phishingCampaignRunBulkChange?: Maybe<PhishingCampaignRunsBulkChangePayload>
  /** Deletes a phishing campaign run */
  phishingCampaignRunDelete?: Maybe<PhishingCampaignRunsDeletePayload>
  /** Stop a campaign run */
  phishingCampaignRunStop?: Maybe<PhishingCampaignRunsStopPayload>
  /** Creates a phishing template category in the current account */
  phishingTemplateCategoryCreate?: Maybe<PhishingTemplatesCategoryCreatePayload>
  /** Delete a phishing template category */
  phishingTemplateCategoryDelete?: Maybe<PhishingTemplatesCategoryDeletePayload>
  /** Hides a phishing template category from an account */
  phishingTemplateCategoryHide?: Maybe<PhishingTemplatesCategoryHidePayload>
  /** Updates a phishing template category */
  phishingTemplateCategoryUpdate?: Maybe<PhishingTemplatesCategoryUpdatePayload>
  /** Creates a phishing template in the current account */
  phishingTemplateCreate?: Maybe<PhishingTemplatesCreatePayload>
  /** Hides a phishing template from an account */
  phishingTemplateHide?: Maybe<PhishingTemplatesHidePayload>
  /** Imports multiple phishing templates based on a JSON format */
  phishingTemplateImport?: Maybe<PhishingTemplatesImportPayload>
  /** Creates a phishing template in the current account */
  phishingTemplateUpdate?: Maybe<PhishingTemplatesUpdatePayload>
  /** Copies a set of phishing templates into the current account */
  phishingTemplatesCopy?: Maybe<PhishingTemplatesCopyPayload>
  /** Adds topics to phishing templates */
  phishingTemplatesTopicsAdd?: Maybe<PhishingTemplatesTopicsAddPayload>
  /** Removes topics from phishing templates */
  phishingTemplatesTopicsRemove?: Maybe<PhishingTemplatesTopicsRemovePayload>
  /** Creates a physical QR code campaign */
  physicalQrCampaignCreate?: Maybe<PhysicalQrCampaignsCreatePayload>
  /** Deletes a physical QR code campaign */
  physicalQrCampaignDelete?: Maybe<PhysicalQrCampaignsDeletePayload>
  /** Finishes a physical QR code campaign */
  physicalQrCampaignEndCampaign?: Maybe<PhysicalQrCampaignsEndCampaignPayload>
  /** Creates a physical QR code campaign location */
  physicalQrCampaignLocationCreate?: Maybe<PhysicalQrCampaignLocationsCreatePayload>
  /** Deletes a physical QR code campaign location */
  physicalQrCampaignLocationDelete?: Maybe<PhysicalQrCampaignLocationsDeletePayload>
  /** Updates a physical QR code campaign location */
  physicalQrCampaignLocationUpdate?: Maybe<PhysicalQrCampaignLocationsUpdatePayload>
  /** Restarts a physical QR code campaign */
  physicalQrCampaignStartCampaign?: Maybe<PhysicalQrCampaignsStartCampaignPayload>
  /** Updates a physical QR code campaign */
  physicalQrCampaignUpdate?: Maybe<PhysicalQrCampaignsUpdatePayload>
  /** Creates a user physical QR code template */
  physicalQrTemplateCreate?: Maybe<PhysicalQrTemplatesCreatePayload>
  /** Updates a physical QR code template */
  physicalQrTemplateUpdate?: Maybe<PhysicalQrTemplatesUpdatePayload>
  /** Copies a set of phishing templates into the current account */
  physicalQrTemplatesCopy?: Maybe<PhysicalQrTemplatesCopyPayload>
  /** Accepts the policy for the current user */
  policyAccept?: Maybe<PoliciesAcceptPayload>
  /** Creates a policy asset */
  policyAssetCreate?: Maybe<PolicyAssetsCreatePayload>
  /** Delete a policy asset */
  policyAssetDelete?: Maybe<PolicyAssetsDeletePayload>
  /** Updates a policy asset */
  policyAssetUpdate?: Maybe<PolicyAssetsUpdatePayload>
  /** Creates a policy */
  policyCreate?: Maybe<PoliciesCreatePayload>
  /** Soft deletes a policy */
  policyDelete?: Maybe<PoliciesDeletePayload>
  /** Updates a policy */
  policyUpdate?: Maybe<PoliciesUpdatePayload>
  /** Updates the acknowledged pages for a policy enrollment for the current user */
  policyUpdateAcceptedPages?: Maybe<PoliciesUpdateAcceptedPagesPayload>
  /** Updates the policy asset ID for a policy enrollment for the current user */
  policyUpdateAssetId?: Maybe<PoliciesUpdateAssetIdPayload>
  /** Updates the pages viewed for a policy enrollment for the current user */
  policyUpdatePagesViewed?: Maybe<PoliciesUpdatePagesViewedPayload>
  /** Updates the total time for an enrollment for a policy for the current user */
  policyUpdateTotalTime?: Maybe<PoliciesUpdateTotalTimePayload>
  /** Purge the SCIM for an account */
  purgeScimV2?: Maybe<AccountSettingsUserProvisioningPurgeScimV2Payload>
  /** Generate a new SCIM token */
  regenerateScimToken?: Maybe<AccountSettingsUserProvisioningRegenerateScimTokenPayload>
  /** Remove Google configuration */
  removeGoogleConfig?: Maybe<AccountSettingsUserProvisioningGoogleRemoveConfigPayload>
  /** Creates a saved report category */
  reportCategoryCreate?: Maybe<SavedReportsCategoriesCreatePayload>
  /** Deletes a saved report category */
  reportCategoryDelete?: Maybe<SavedReportsCategoriesDeletePayload>
  /** Reorders saved report categories for the current account */
  reportCategoryReorder?: Maybe<SavedReportsCategoriesReorderPayload>
  /** Updates a saved report category */
  reportCategoryUpdate?: Maybe<SavedReportsCategoriesUpdatePayload>
  /** Clones a saved report */
  reportClone?: Maybe<SavedReportsClonePayload>
  /** Deletes saved reports */
  reportDelete?: Maybe<SavedReportsDeletePayload>
  /** Moves saved reports to a different category */
  reportMove?: Maybe<SavedReportsMovePayload>
  /** Creates or updates a saved report */
  reportSaveAccountReportCard?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveActiveRulesReport?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveAidaCampaignSummaryTable?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveAssessmentScoreByKnowledgeArea?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveAssessmentScoreByKnowledgeAreaOverTime?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveAuditLogs?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveEmailsSentByGroup?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveEmailsSentByTemplate?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveFailureTypeUserCounts?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveFailureTypesBarChart?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveFailuresByPhishingTemplate?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveGlobalPhishingAggregate?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveGlobalPhishingRaw?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveGlobalTrainingAggregate?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveGlobalTrainingRaw?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveGroupReportCard?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveIncompleteTraining?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveInitialPpp?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveLearnerPlatformRaw?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveNoTrainingAssigned?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveOverallPppByAccount?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveOverallPppByCampaign?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveOverallPppByGroup?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveOverallPppByTrainingTime?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSavePabComparison?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSavePabUserActivity?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSavePhishFailurePercentageByCampaigns?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSavePhishFailurePercentageOverall?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSavePhishingAllClickers?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSavePhishingSecurityTests?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSavePppDistribution?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveRiskScoreByAssessment?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveRiskScoreByGroup?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveRiskScoreByLocation?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveRiskScoreBySurvey?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveRiskScoreByUser?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveRiskScoreV2ByGroup?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveRiskScoreV2ByManager?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveRiskScoreV2ByUser?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveSecurityCoachActivityRtcAggregate?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveSecurityCoachActivityRtcRaw?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveSecurityCoachDetectionRulesActivityAggregate?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveSecurityCoachDetectionRulesActivityRaw?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveSurveyScoreByDimension?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveSurveyScoreByDimensionOverTime?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveTrainingCampaignEnrollments?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveTrainingCampaignSurveys?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveTrainingComparisonByPpp?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveTrainingComparisonByRiskScore?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveTrainingHours?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveTrainingInteractionsByGroup?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveTrainingInteractionsByPurchasedCourse?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveTrainingNotStarted?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveTrainingStatusByCampaign?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveTrainingStatusByCourse?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveTrainingStatusByGroup?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveTrainingStatusesByCampaign?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveTrainingSurveysByCampaign?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveTrainingSurveysByCourse?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveUnphishedUsers?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveUserCountByAssessment?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveUserCountBySurvey?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveUserCounts?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveUserPhishingCampaigns?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveUserReportCard?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveUserSignIns?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveUserTrainingCampaigns?: Maybe<SavedReportsSavePayload>
  /** Creates or updates a saved report */
  reportSaveUsersList?: Maybe<SavedReportsSavePayload>
  /** Runs one or more demo seeds */
  rerunDemoSeed?: Maybe<RerunDemoSeedPayload>
  /** Creates a restricted domain */
  restrictedDomainCreate?: Maybe<RestrictedDomainsCreatePayload>
  /** Deletes a restricted domain */
  restrictedDomainDelete?: Maybe<RestrictedDomainsDeletePayload>
  /** Updates a restricted domain */
  restrictedDomainUpdate?: Maybe<RestrictedDomainsUpdatePayload>
  /** Revoke the SCIM token */
  revokeScimToken?: Maybe<AccountSettingsUserProvisioningRevokeScimTokenPayload>
  /** Save Google configuration */
  saveGoogleConfig?: Maybe<AccountSettingsUserProvisioningGoogleSaveConfigPayload>
  /** Save Google credentials */
  saveGoogleCredentials?: Maybe<AccountSettingsUserProvisioningGoogleSaveCredentialsPayload>
  /** Deactivates scheduled reports */
  scheduledReportDeactivate?: Maybe<ScheduledReportsDeactivatePayload>
  /** Deletes scheduled reports */
  scheduledReportDelete?: Maybe<ScheduledReportsDeletePayload>
  /** Creates or updates a scheduled report */
  scheduledReportSave?: Maybe<ScheduledReportsSavePayload>
  /** Send a scheduled report upon request */
  scheduledReportSend?: Maybe<ScheduledReportsSendPayload>
  /** Adds domains to the No Prompt Domain list */
  secondChanceAddToOrionList?: Maybe<SecondChanceAddToOrionListPayload>
  /** Deletes domains from Second Chance */
  secondChanceBulkDeleteDomains?: Maybe<SecondChanceBulkDeleteDomainsPayload>
  /** Imports domains to Second Chance */
  secondChanceImportDomains?: Maybe<SecondChanceImportDomainsPayload>
  /** Updates the settings for Second Chance */
  secondChanceUpdateSettings?: Maybe<SecondChanceUpdateSettingsPayload>
  /** Approve an alias recommendation */
  securityCoachApproveUserMappingRecommendation?: Maybe<SecurityCoachApproveUserMappingRecommendationPayload>
  /** Create a RTC campaign */
  securityCoachCampaignsCreate?: Maybe<SecurityCoachCampaignsCreatePayload>
  /** Set the status of a RTC campaign */
  securityCoachCampaignsDelete?: Maybe<SecurityCoachCampaignsDeletePayload>
  /** Update a RTC Campaign */
  securityCoachCampaignsEdit?: Maybe<SecurityCoachCampaignsUpdatePayload>
  /** Set the status of a RTC campaign */
  securityCoachCampaignsSetStatus?: Maybe<SecurityCoachCampaignsSetStatusPayload>
  /** Updates a teachable moment workflow rule */
  securityCoachCloneSystemTmr?: Maybe<SecurityCoachOrchestrationCloneTmrPayload>
  /** Creates a Mapping Job for Unmapped Events */
  securityCoachCreateRemapJobForUnmappedEvents?: Maybe<SecurityCoachAnalyticsCreateRemapJobForUnmappedEventsPayload>
  /** creates a teachable moment workflow rule */
  securityCoachCreateTeachableMomentRule?: Maybe<SecurityCoachOrchestrationCreateTeachableMomentRulePayload>
  /** Archives SecurityCoach custom content translations */
  securityCoachCustomContentArchive?: Maybe<SecurityCoachCustomContentArchivePayload>
  /** Creates SecurityCoach custom content */
  securityCoachCustomContentCreate?: Maybe<SecurityCoachCustomContentCreatePayload>
  /** Deletes SecurityCoach custom content */
  securityCoachCustomContentDelete?: Maybe<SecurityCoachCustomContentDeletePayload>
  /** Creates SecurityCoach custom content translation */
  securityCoachCustomContentTranslationsCreate?: Maybe<SecurityCoachCustomContentTranslationsCreatePayload>
  /** Deletes SecurityCoach custom content translation */
  securityCoachCustomContentTranslationsDelete?: Maybe<SecurityCoachCustomContentTranslationsDeletePayload>
  /** Publishes SecurityCoach custom content translation */
  securityCoachCustomContentTranslationsPublish?: Maybe<SecurityCoachCustomContentTranslationsPublishPayload>
  /** Updates SecurityCoach custom content translation */
  securityCoachCustomContentTranslationsUpdate?: Maybe<SecurityCoachCustomContentTranslationsUpdatePayload>
  /** Updates SecurityCoach custom content */
  securityCoachCustomContentUpdate?: Maybe<SecurityCoachCustomContentUpdatePayload>
  /** Clones a SecurityCoach custom notification and its custom translations in the current account */
  securityCoachCustomNotificationsClone?: Maybe<SecurityCoachCustomNotificationsClonePayload>
  /** Creates a SecurityCoach custom notification in the current account */
  securityCoachCustomNotificationsCreate?: Maybe<SecurityCoachCustomNotificationsCreatePayload>
  /** Deletes a SecurityCoach custom notification in the current account */
  securityCoachCustomNotificationsDelete?: Maybe<SecurityCoachCustomNotificationsDeletePayload>
  /** Updates a SecurityCoach custom notification in the current account */
  securityCoachCustomNotificationsUpdate?: Maybe<SecurityCoachCustomNotificationsUpdatePayload>
  /** Creates a SecurityCoach custom translation in the current account for the given custom notification ID */
  securityCoachCustomTranslationsCreate?: Maybe<SecurityCoachCustomTranslationsCreatePayload>
  /** Deletes a SecurityCoach custom translation in the current account */
  securityCoachCustomTranslationsDelete?: Maybe<SecurityCoachCustomTranslationsDeletePayload>
  /** Updates a SecurityCoach custom translation in the current account */
  securityCoachCustomTranslationsUpdate?: Maybe<SecurityCoachCustomTranslationsUpdatePayload>
  /** Deletes a teachable moment workflow rule */
  securityCoachDeleteTeachableMomentRule?: Maybe<SecurityCoachOrchestrationDeleteTeachableMomentRulePayload>
  /** Send a Google Chat Message */
  securityCoachDeliverGoogleChatMessage?: Maybe<SecurityCoachDeliveryGchatSendSampleMessagePayload>
  /** Send a Slack Message */
  securityCoachDeliverSlackMessage?: Maybe<SecurityCoachDeliverySlackSendSampleMessagePayload>
  /** Send a Teams Message */
  securityCoachDeliverTeamsMessage?: Maybe<SecurityCoachDeliveryTeamsSendSampleMessagePayload>
  /** Deletes a Google Chat Domain */
  securityCoachDeliveryGoogleChatDeleteDomain?: Maybe<SecurityCoachDeliveryGchatDeleteDomainPayload>
  /** Delivery Preview for Real Time Coaching Campaigns */
  securityCoachDeliveryPreview?: Maybe<SecurityCoachDeliveryPreviewPayload>
  /** Deletes a slack workspace */
  securityCoachDeliverySlackWorkspacesDelete?: Maybe<SecurityCoachDeliverySlackWorkspacesDeletePayload>
  /** Delete Teams Integration */
  securityCoachDeliveryTeamsDeleteIntegrationCallback?: Maybe<SecurityCoachDeliveryTeamsDeleteIntegrationCallbackPayload>
  /** Delivery registration with Teams */
  securityCoachDeliveryTeamsReviewPermissionsCallback?: Maybe<SecurityCoachDeliveryTeamsReviewPermissionsCallbackPayload>
  /** Upload Bot to Teams */
  securityCoachDeliveryTeamsUploadBotCallback?: Maybe<SecurityCoachDeliveryTeamsUploadBotCallbackPayload>
  /** edits a teachable moment workflow rule */
  securityCoachEditTeachableMomentRule?: Maybe<SecurityCoachOrchestrationEditTeachableMomentRulePayload>
  /** Validate user mapping data */
  securityCoachEditUserMapping?: Maybe<SecurityCoachEditUserMappingPayload>
  /** Updates the status a teachable moment workflow rule */
  securityCoachEnableTeachableMomentRule?: Maybe<SecurityCoachOrchestrationEnableTeachableMomentRulePayload>
  /** Create a custom security coach rule */
  securityCoachMappingRuleCreate?: Maybe<SecurityCoachRulesCreatePayload>
  /** Deletes a security coach rule */
  securityCoachMappingRuleDelete?: Maybe<SecurityCoachRulesDeletePayload>
  /** Toggles a security coach rule to active/inactive */
  securityCoachMappingRuleToggle?: Maybe<SecurityCoachRulesTogglePayload>
  /** Flags a rule as generator of false positive detections */
  securityCoachMarkFalsePositiveRule?: Maybe<SecurityCoachOrchestrationMarkFalsePositiveRulePayload>
  /** Turn on SecurityCoach limited preview for an account */
  securityCoachPreview?: Maybe<SecurityCoachPreviewPayload>
  /** Request a quote for securityCoach */
  securityCoachQuote?: Maybe<SecurityCoachQuotePayload>
  /** Register bot to Teams */
  securityCoachRegisterTeamsBot?: Maybe<SecurityCoachDeliveryTeamsRegisterPayload>
  /** Reject alias recommendations */
  securityCoachRejectUserMappingRecommendation?: Maybe<SecurityCoachRejectUserMappingRecommendationPayload>
  /** Uninstall bot from an organization */
  securityCoachRemoveBotFromOrg?: Maybe<SecurityCoachDeliveryTeamsDeleteTeamsFromOrgPayload>
  /** Uninstall bot from a team */
  securityCoachRemoveBotFromTeam?: Maybe<SecurityCoachDeliveryTeamsDeleteFromTeamPayload>
  /** Reset a Security Coach preview */
  securityCoachResetPreview?: Maybe<SecurityCoachResetPreviewPayload>
  /** Updates a SecurityCoach setup status action */
  securityCoachSetupStatusUpdate?: Maybe<SecurityCoachSetupStatusUpdatePayload>
  /** Toggle Engine Status */
  securityCoachToggleEngineStatus?: Maybe<SecurityCoachToggleEngineStatusPayload>
  /** Upload User Mapping CSV for Security Coach */
  securityCoachUserMappingCsvUpload?: Maybe<SecurityCoachUserMappingCsvUploadPayload>
  /** Setup Bitdefender integration */
  securityCoachVendorsBitdefenderConnect?: Maybe<SecurityCoachVendorsBitdefenderConnectPayload>
  /** Update Bitdefender integration */
  securityCoachVendorsBitdefenderUpdate?: Maybe<SecurityCoachVendorsBitdefenderUpdatePayload>
  /** Setup Carbon Black integration */
  securityCoachVendorsCarbonBlackConnect?: Maybe<SecurityCoachVendorsCarbonBlackConnectPayload>
  /** Update Carbon Black integration */
  securityCoachVendorsCarbonBlackUpdate?: Maybe<SecurityCoachVendorsCarbonBlackUpdatePayload>
  /** Setup Cisco integration */
  securityCoachVendorsCiscoConnect?: Maybe<SecurityCoachVendorsCiscoConnectPayload>
  /** Setup Cisco Umbrella integration */
  securityCoachVendorsCiscoUmbrellaConnect?: Maybe<SecurityCoachVendorsCiscoUmbrellaConnectPayload>
  /** Update Cisco Umbrella integration */
  securityCoachVendorsCiscoUmbrellaUpdate?: Maybe<SecurityCoachVendorsCiscoUmbrellaUpdatePayload>
  /** Update Cisco integration */
  securityCoachVendorsCiscoUpdate?: Maybe<SecurityCoachVendorsCiscoUpdatePayload>
  /** Setup Cloudflare Zero Trust integration */
  securityCoachVendorsCloudflareZeroTrustConnect?: Maybe<SecurityCoachVendorsCloudflareZeroTrustConnectPayload>
  /** Update Cloudflare Zero Trust integration */
  securityCoachVendorsCloudflareZeroTrustUpdate?: Maybe<SecurityCoachVendorsCloudflareZeroTrustUpdatePayload>
  /** Setup Cloudflare Area 1 integration */
  securityCoachVendorsCloudflarearea1Connect?: Maybe<SecurityCoachVendorsCloudflareArea1ConnectPayload>
  /** Update Cloudflare Area 1 integration */
  securityCoachVendorsCloudflarearea1Update?: Maybe<SecurityCoachVendorsCloudflareArea1UpdatePayload>
  /** Setup Code42 integration */
  securityCoachVendorsCodeFourTwoConnect?: Maybe<SecurityCoachVendorsCodeFourTwoConnectPayload>
  /** Update Code42 integration */
  securityCoachVendorsCodeFourTwoUpdate?: Maybe<SecurityCoachVendorsCodeFourTwoUpdatePayload>
  /** Setup Crowdstrike integration */
  securityCoachVendorsCrowdstrikeConnect?: Maybe<SecurityCoachVendorsCrowdstrikeConnectPayload>
  /** Update Crowdstrike integration */
  securityCoachVendorsCrowdstrikeUpdate?: Maybe<SecurityCoachVendorsCrowdstrikeUpdatePayload>
  /** Setup Cylance integration */
  securityCoachVendorsCylanceConnect?: Maybe<SecurityCoachVendorsCylanceConnectPayload>
  /** Delete a vendor integration */
  securityCoachVendorsDelete?: Maybe<SecurityCoachVendorsDeletePayload>
  /** Dismiss vendor alert */
  securityCoachVendorsDismissAlert?: Maybe<SecurityCoachVendorsDismissAlertPayload>
  /** Setup Fortigate Cloud integration */
  securityCoachVendorsFortigateCloudConnect?: Maybe<SecurityCoachVendorsFortigateCloudConnectPayload>
  /** Update Fortigate Cloud integration */
  securityCoachVendorsFortigateCloudUpdate?: Maybe<SecurityCoachVendorsFortigateCloudUpdatePayload>
  /** Setup Google integration */
  securityCoachVendorsGoogleConnect?: Maybe<SecurityCoachVendorsGoogleConnectPayload>
  /** Update Google integration */
  securityCoachVendorsGoogleUpdate?: Maybe<SecurityCoachVendorsGoogleUpdatePayload>
  /** Setup Kmsat integration */
  securityCoachVendorsKmsatConnect?: Maybe<SecurityCoachVendorsKmsatConnectPayload>
  /** Setup Malwarebytes integration */
  securityCoachVendorsMalwarebytesConnect?: Maybe<SecurityCoachVendorsMalwarebytesConnectPayload>
  /** Update Malwarebytes integration */
  securityCoachVendorsMalwarebytesUpdate?: Maybe<SecurityCoachVendorsMalwarebytesUpdatePayload>
  /** Setup Microsoft integration */
  securityCoachVendorsMicrosoftConnect?: Maybe<SecurityCoachVendorsMicrosoftConnectPayload>
  /** Update Microsoft integration */
  securityCoachVendorsMicrosoftUpdate?: Maybe<SecurityCoachVendorsMicrosoftUpdatePayload>
  /** Setup Mimecast integration */
  securityCoachVendorsMimecastConnect?: Maybe<SecurityCoachVendorsMimecastConnectPayload>
  /** Update Mimecast integration */
  securityCoachVendorsMimecastUpdate?: Maybe<SecurityCoachVendorsMimecastUpdatePayload>
  /** Setup Netskope integration */
  securityCoachVendorsNetskopeConnect?: Maybe<SecurityCoachVendorsNetskopeConnectPayload>
  /** Update Netscope integration */
  securityCoachVendorsNetskopeUpdate?: Maybe<SecurityCoachVendorsNetskopeUpdatePayload>
  /** Setup Okta integration */
  securityCoachVendorsOktaConnect?: Maybe<SecurityCoachVendorsOktaConnectPayload>
  /** Update Okta integration */
  securityCoachVendorsOktaUpdate?: Maybe<SecurityCoachVendorsOktaUpdatePayload>
  /** Setup Palo Alto NGFW integration */
  securityCoachVendorsPaloAltoNgfwConnect?: Maybe<SecurityCoachVendorsPaloAltoNgfwConnectPayload>
  /** Update Palo Alto NGFW integration */
  securityCoachVendorsPaloAltoNgfwUpdate?: Maybe<SecurityCoachVendorsPaloAltoNgfwUpdatePayload>
  /** Setup Palo Alto integration */
  securityCoachVendorsPaloaltoConnect?: Maybe<SecurityCoachVendorsPaloaltoConnectPayload>
  /** Setup Proofpoint integration */
  securityCoachVendorsProofpointConnect?: Maybe<SecurityCoachVendorsProofpointConnectPayload>
  /** Update Proofpoint integration */
  securityCoachVendorsProofpointUpdate?: Maybe<SecurityCoachVendorsProofpointUpdatePayload>
  /** Setup SentinelOne integration */
  securityCoachVendorsSentineloneConnect?: Maybe<SecurityCoachVendorsSentinelOneConnectPayload>
  /** Update SentinelOne integration */
  securityCoachVendorsSentineloneUpdate?: Maybe<SecurityCoachVendorsSentinelOneUpdatePayload>
  /** Setup Sonicwall integration */
  securityCoachVendorsSonicwallConnect?: Maybe<SecurityCoachVendorsSonicwallConnectPayload>
  /** Update Sonicwall integration */
  securityCoachVendorsSonicwallUpdate?: Maybe<SecurityCoachVendorsSonicwallUpdatePayload>
  /** Setup Sophos integration */
  securityCoachVendorsSophosConnect?: Maybe<SecurityCoachVendorsSophosConnectPayload>
  /** Update Sophos integration */
  securityCoachVendorsSophosUpdate?: Maybe<SecurityCoachVendorsSophosUpdatePayload>
  /** Setup Zscaler integration */
  securityCoachVendorsZscalerConnect?: Maybe<SecurityCoachVendorsZscalerConnectPayload>
  /** Create Security Role */
  securityRoleCreate?: Maybe<SecurityRolesCreatePayload>
  /** Deletes a Security Role */
  securityRoleDelete?: Maybe<SecurityRolesDeletePayload>
  /** Update Security Role */
  securityRoleUpdate?: Maybe<SecurityRolesUpdatePayload>
  /** Updates a message campaigns active status */
  setCampaignActiveState?: Maybe<MessagesSetCampaignActiveStatePayload>
  /** Updates a message run archived status */
  setRunArchiveState?: Maybe<MessagesSetRunArchiveStatePayload>
  /** Sign in as the main admin of an account belonging to the current partner admin */
  signInAsAccount?: Maybe<SignInAsAccountPayload>
  /** Sign in as an admin of an account belonging to the current partner admins partner */
  signInAsAdmin?: Maybe<SignInAsAccountAdminPayload>
  /** Sign in as a partner admin of a partner underneath the current partner admin's partner */
  signInAsChildPartner?: Maybe<SignInAsChildPartnerPayload>
  /** Sign in as a partner admin */
  signInAsPartner?: Maybe<SignInAsPartnerAdminPayload>
  /** Sign in as a non full admin in the same account */
  signInAsUser?: Maybe<SignInAsAccountUserPayload>
  /** Creates a Site Admin */
  siteAdminCreate?: Maybe<SiteAdminsCreatePayload>
  /** Deactivate or activate MFA on Site Admin */
  siteAdminDeactivateMfa?: Maybe<SiteAdminsDeactivateMfaPayload>
  /** Delete a Site Admin */
  siteAdminDelete?: Maybe<SiteAdminsDeletePayload>
  /** Disable or enable a Site Admin */
  siteAdminDisable?: Maybe<SiteAdminsDisablePayload>
  /** Disables MFA on the Site Admin (if valid) */
  siteAdminDisableMfa?: Maybe<SiteAdminsDisableMfaPayload>
  /** Enables MFA on the site admin (if valid) */
  siteAdminEnableMfa?: Maybe<SiteAdminsEnableMfaPayload>
  /** Deactivate or activate full admin status on Site Admin */
  siteAdminToggleFullAdmin?: Maybe<SiteAdminsToggleFullAdminPayload>
  /** Update a Site Admin */
  siteAdminUpdate?: Maybe<SiteAdminsUpdatePayload>
  /** Update the current admin */
  siteAdminUpdateSelf?: Maybe<SiteAdminsUpdateSelfPayload>
  /** Update site settings */
  siteSettingsUpdate?: Maybe<SiteSettingsUpdatePayload>
  /** Deletes a smart host domain from PSM */
  smartHostDomainDelete?: Maybe<SmartHostDomainsDeletePayload>
  /** Sends a test email through PSM */
  smartHostDomainSendTest?: Maybe<SmartHostDomainsSendTestPayload>
  /** Update a smart host domain in PSM */
  smartHostDomainUpdate?: Maybe<SmartHostDomainsUpdatePayload>
  /** Start Google sync */
  startGoogleSync?: Maybe<AccountSettingsUserProvisioningGoogleStartSyncPayload>
  /** Create store purchase for the account */
  storePurchaseCreate?: Maybe<StorePurchasesCreatePayload>
  /** Logs the download of a store purchase for the account */
  storePurchaseDownload?: Maybe<StorePurchasesDownloadPayload>
  /** Remove store purchase from the current account */
  storePurchaseRemove?: Maybe<StorePurchasesRemovePayload>
  /** Update store purchase from the current account */
  storePurchaseUpdate?: Maybe<StorePurchasesUpdatePayload>
  /** Create a subscription */
  subscriptionCreate?: Maybe<SubscriptionsCreatePayload>
  /** Delete a subscription */
  subscriptionDelete?: Maybe<SubscriptionsDeletePayload>
  /** Update a subscription */
  subscriptionUpdate?: Maybe<SubscriptionsUpdatePayload>
  /** Creates a survey for an enrollment */
  surveyCreate?: Maybe<SurveysCreatePayload>
  /** Creates a phishing template topic in the current account */
  templateTopicCreate?: Maybe<TemplateTopicsCreatePayload>
  /** Delete a phishing template topic */
  templateTopicDelete?: Maybe<TemplateTopicsDeletePayload>
  /** Updates a phishing template topic in the current account */
  templateTopicUpdate?: Maybe<TemplateTopicsUpdatePayload>
  /** Creates a test email and sends it */
  testEmailCreate?: Maybe<TestEmailsCreatePayload>
  /** Update a trademark */
  trademarkCreate?: Maybe<TrademarksCreatePayload>
  /** Initiate deletion of a trademark */
  trademarkDelete?: Maybe<TrademarksDeletePayload>
  /** Change trademarks to the correct status */
  trademarkMatchesResolve?: Maybe<TrademarkMatchesResolvePayload>
  /** Update a trademark */
  trademarkUpdate?: Maybe<TrademarksUpdatePayload>
  /** Adds a user to a training campaign */
  trainingCampaignAddUser?: Maybe<TrainingCampaignsAddUserPayload>
  /** Bulk change a collection of enrollments */
  trainingCampaignBulkChange?: Maybe<TrainingCampaignsBulkChangePayload>
  /** Marks a training campaign as closed */
  trainingCampaignClose?: Maybe<TrainingCampaignsClosePayload>
  /** Creates a training campaign in the current account */
  trainingCampaignCreate?: Maybe<TrainingCampaignsCreatePayload>
  /** Enrolls multiple learners through a CSV file */
  trainingCampaignCsvEnroll?: Maybe<TrainingCampaignsCsvEnrollPayload>
  /** Deletes training campaigns */
  trainingCampaignDelete?: Maybe<TrainingCampaignsDeletePayload>
  /** Edit a training campaign in the current account */
  trainingCampaignEdit?: Maybe<TrainingCampaignsEditPayload>
  /** Extends users enrollment due dates */
  trainingCampaignExtendDueDate?: Maybe<TrainingCampaignsExtendDueDatePayload>
  /** Removes a user from a training campaign */
  trainingCampaignRemoveUser?: Maybe<TrainingCampaignsRemoveUserPayload>
  /** Opens previously closed training campaigns */
  trainingCampaignReopen?: Maybe<TrainingCampaignsReopenPayload>
  /** Updates a message recipient status */
  updateMessageRecipient?: Maybe<MessagesUpdateMessageRecipientPayload>
  /** Creates a USB drive test */
  usbCampaignCreate?: Maybe<UsbCampaignsCreatePayload>
  /** Destroys a USB drive test */
  usbCampaignDestroy?: Maybe<UsbCampaignsDestroyPayload>
  /** Ends a USB drive test */
  usbCampaignEnd?: Maybe<UsbCampaignsEndPayload>
  /** Restarts a USB drive test */
  usbCampaignRestart?: Maybe<UsbCampaignsRestartPayload>
  /** Starts a USB drive test */
  usbCampaignStart?: Maybe<UsbCampaignsStartPayload>
  /** Updates a USB drive test */
  usbCampaignUpdate?: Maybe<UsbCampaignsUpdatePayload>
  /** Enable SAML latest certificate for account */
  useSamlLatestCertificate?: Maybe<AccountSettingsSamlUseLatestCertificatePayload>
  /** Accepts Clickwrap for an account admin user */
  userAcceptClickwrap?: Maybe<UserAcceptClickwrapPayload>
  /** Adds a collections of users to a collection of classic groups */
  userAddToGroups?: Maybe<UsersAddToGroupsPayload>
  /** Archive a collection of users */
  userArchive?: Maybe<UsersArchivePayload>
  /** Bulk archive a collection of users */
  userBulkArchive?: Maybe<UsersBulkArchivePayload>
  /** Bulk change domains of a collection of users via ids */
  userBulkChangeDomain?: Maybe<UsersBulkChangeDomainPayload>
  /** Bulk change domains of a collection of users via emails */
  userBulkChangeDomainEmails?: Maybe<UsersBulkChangeDomainEmailsPayload>
  /** Bulk delete a collection of users */
  userBulkDelete?: Maybe<UsersBulkDeletePayload>
  /** Create a user */
  userCreate?: Maybe<UsersCreatePayload>
  /** Create a user via the partner portal */
  userCreateUpsideDown?: Maybe<UsersCreateUpsideDownPayload>
  /** Imports multiple users by CSV file */
  userCsvImport?: Maybe<UsersCsvImportPayload>
  /** Permanently delete a collection of non-admin users */
  userDelete?: Maybe<UsersDeletePayload>
  /** Delete all users in the account except for the owner */
  userDeleteAll?: Maybe<UserDeleteAllPayload>
  /** Delete one or more users by email */
  userDeleteByEmail?: Maybe<UserDeleteByEmailPayload>
  /** Removes a collections of users from a specific group */
  userDeleteMemberships?: Maybe<UsersDeleteMembershipsPayload>
  /** Edit a user */
  userEdit?: Maybe<UsersEditPayload>
  /** Edit a user via partner portal */
  userEditUpsideDown?: Maybe<UsersEditUpsideDownPayload>
  /** Create a User Event API key */
  userEventApiCreateKey?: Maybe<EchelonApiCreateKeyPayload>
  /** Delete a User Event API key */
  userEventApiDeleteKey?: Maybe<EchelonApiDeleteKeyPayload>
  /** Resync user events with the User Event API */
  userEventResync?: Maybe<UsersEventResyncPayload>
  /** Grants admin priviledges to a collection of users */
  userGrantAdmin?: Maybe<UsersGrantAdminPayload>
  /** Edit the current user's profile */
  userProfileEdit?: Maybe<UsersEditProfilePayload>
  /** Imports multiple users by email address */
  userQuickImport?: Maybe<UsersQuickImportPayload>
  /** Resend devise confirmation email */
  userResendConfirmationEmail?: Maybe<UsersResendConfirmationEmailPayload>
  /** Revoke admin priviledges from a collection of admins */
  userRevokeAdmin?: Maybe<UsersRevokeAdminPayload>
  /** Send mobile sign-in link email */
  userSendMobileSignInLinkEmail?: Maybe<UsersSendMobileSignInLinkEmailPayload>
  /** Sets your default executive reports dashboard */
  userSetDefaultExecReportsDashboard?: Maybe<UserSetDefaultExecReportsDashboardPayload>
  /** Sets your default PasswordIQ dashboard */
  userSetDefaultPiqDashboard?: Maybe<UserSetDefaultPiqDashboardPayload>
  /** Remove an external event */
  userTimelineExternalEvent?: Maybe<TimelineExternalEventDeletePayload>
  /** Unarchive a collection of users */
  userUnarchive?: Maybe<UsersUnarchivePayload>
  /** Processes a user merge action */
  usersMergeProcess?: Maybe<UsersMergeProcessPayload>
  /** Creates a UST */
  ustCreate?: Maybe<UstCreatePayload>
  /** Starts a UST */
  ustStart?: Maybe<UstStartPayload>
  /** Lists a paginated set of webhooks */
  webhookCreate?: Maybe<WebhooksCreatePayload>
  /** Delete a webhook */
  webhookDelete?: Maybe<WebhooksDeletePayload>
  /** Update a webhook definition */
  webhookUpdate?: Maybe<WebhooksUpdatePayload>
}

export type MutationAccountConfirmDeletionArgs = {
  id: Scalars['Int']
}

export type MutationAccountCreateArgs = {
  attributes: AccountAttributes
}

export type MutationAccountCreatePabInstanceArgs = {
  id?: Maybe<Scalars['Int']>
}

export type MutationAccountDeleteCertArgs = {
  accountId: Scalars['Int']
}

export type MutationAccountDeleteFreeArgs = {
  id: Scalars['Int']
}

export type MutationAccountDeleteLogoArgs = {
  accountId: Scalars['Int']
}

export type MutationAccountDeletePabIconArgs = {
  accountId: Scalars['Int']
  id: Scalars['Int']
}

export type MutationAccountDeletePabInstanceArgs = {
  accountId: Scalars['Int']
  id: Scalars['Int']
}

export type MutationAccountDeletePhisherArgs = {
  id: Scalars['Int']
}

export type MutationAccountInvokeReseedArgs = {
  id: Scalars['Int']
}

export type MutationAccountPrivacyUpdateAccessArgs = {
  newAccessExpiration?: Maybe<AccountPartnerAccessPeriods>
}

export type MutationAccountRefreshAssessmentScoresArgs = {
  accountId: Scalars['Int']
  assessmentType: AssessmentTypes
}

export type MutationAccountRefreshDataArgs = {
  id: Scalars['Int']
}

export type MutationAccountRegenerateTokenArgs = {
  accountId: Scalars['Int']
  token: AccountTokens
}

export type MutationAccountRequestDeletionArgs = {
  id: Scalars['Int']
}

export type MutationAccountResetFreeCampaignArgs = {
  campaignType: Scalars['String']
  id: Scalars['Int']
}

export type MutationAccountRevokeTokenArgs = {
  accountId: Scalars['Int']
  token: AccountTokens
}

export type MutationAccountSalesforceDisconnectArgs = {
  sfid: Scalars['String']
}

export type MutationAccountSalesforceFetchArgs = {
  fetchFromBothSfid?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['Int']>
  partnerSfid?: Maybe<Scalars['String']>
  sfid?: Maybe<Scalars['String']>
}

export type MutationAccountSalesforceScheduleFetchArgs = {
  fetchFromBothSfid?: Maybe<Scalars['Boolean']>
  id: Scalars['Int']
  partnerId?: Maybe<Scalars['String']>
  sfid?: Maybe<Scalars['String']>
  timestamp: Scalars['ISO8601DateTime']
}

export type MutationAccountToggleArchivedArgs = {
  id: Scalars['Int']
}

export type MutationAccountUpdateArgs = {
  attributes: AccountAttributes
  id?: Maybe<Scalars['Int']>
}

export type MutationAidaCampaignSummaryTableCsvArgs = {
  columns?: Maybe<Array<AidaSummaryColumns>>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<AidaSummarySortFields>
}

export type MutationAidaCampaignsEnableArgs = {
  attributes: AidaCampaignAttributes
}

export type MutationAidaPolicyQuizQuestionOptionUpdateArgs = {
  attributes: PolicyQuizQuestionOptionAttributes
  id: Scalars['Int']
}

export type MutationAidaPolicyQuizQuestionUpdateArgs = {
  attributes: PolicyQuizQuestionAttributes
  id: Scalars['Int']
}

export type MutationAidaPolicyQuizRegenerateArgs = {
  id: Scalars['Int']
}

export type MutationAidaPolicyQuizUpdateArgs = {
  id: Scalars['Int']
  policyQuizAttributes?: Maybe<PolicyQuizAttributes>
  policyQuizQuestionAttributes: Array<PolicyQuizQuestionAttributes>
  policyQuizQuestionOptionAttributes: Array<PolicyQuizQuestionOptionAttributes>
}

export type MutationAllowedDomainCreateArgs = {
  accountId?: Maybe<Scalars['Int']>
  domainType: AllowedDomainTypes
  name: Scalars['String']
  verificationStatus: AllowedDomainVerificationStatuses
}

export type MutationAllowedDomainDeleteArgs = {
  id: Scalars['Int']
}

export type MutationAllowedDomainImportArgs = {
  accountId: Scalars['Int']
  domains: Array<Scalars['String']>
}

export type MutationAllowedDomainVerifyArgs = {
  id: Scalars['Int']
}

export type MutationApiTokensCreateArgs = {
  payload: ApiTokenAttributes
}

export type MutationApiTokensDeleteArgs = {
  id: Scalars['Int']
}

export type MutationApiTokensReassignArgs = {
  apiOwnerId: Scalars['Int']
  primaryAccountOwnerId?: Maybe<Scalars['Int']>
}

export type MutationApiTokensUpdateArgs = {
  id: Scalars['Int']
  payload: ApiTokenAttributes
}

export type MutationAsapCreateProgramArgs = {
  attributes?: Maybe<AsapProgramAttributes>
}

export type MutationAsapCreateProgramTaskArgs = {
  attributes: AsapProgramTaskAttributes
}

export type MutationAsapDeleteProgramArgs = {
  id: Scalars['Int']
}

export type MutationAsapUpdateProgramArgs = {
  attributes?: Maybe<AsapProgramAttributes>
  id: Scalars['Int']
}

export type MutationAsapUpdateProgramTaskArgs = {
  attributes: AsapProgramTaskAttributes
  id: Scalars['Int']
}

export type MutationBadgesEarnArgs = {
  enrollmentId?: Maybe<Scalars['Int']>
}

export type MutationBadgesMarkAsAcknowledgedArgs = {
  badgeIds: Array<Scalars['Int']>
}

export type MutationCallbackPhishingAudioTemplateMessagesDeleteAudioFileArgs = {
  messageId: Scalars['Int']
}

export type MutationCallbackPhishingGreetingTemplateCreateArgs = {
  attributes: CallbackPhishingGreetingTemplateAttributes
}

export type MutationCallbackPhishingGreetingTemplateUpdateArgs = {
  attributes: CallbackPhishingGreetingTemplateAttributes
  id: Scalars['Int']
}

export type MutationCallbackPhishingGreetingTemplatesAddTopicsArgs = {
  templateIds: Array<Scalars['Int']>
  topicIds: Array<Scalars['Int']>
}

export type MutationCallbackPhishingGreetingTemplatesCopyArgs = {
  uuids: Array<Scalars['String']>
}

export type MutationCallbackPhishingGreetingTemplatesDeleteArgs = {
  templateIds: Array<Scalars['Int']>
}

export type MutationCallbackPhishingGreetingTemplatesRemoveTopicsArgs = {
  templateIds: Array<Scalars['Int']>
  topicIds: Array<Scalars['Int']>
}

export type MutationCallbackPhishingPhoneNumberLinkOrphanArgs = {
  number: Scalars['String']
}

export type MutationCallbackPhishingPhoneNumberReleaseArgs = {
  id: Scalars['Int']
}

export type MutationCallbackPhishingPhoneNumberToggleDeletionArgs = {
  id: Scalars['Int']
}

export type MutationCallbackPhishingResponseTemplateCreateArgs = {
  attributes: CallbackPhishingResponseTemplateAttributes
}

export type MutationCallbackPhishingResponseTemplateUpdateArgs = {
  attributes: CallbackPhishingResponseTemplateAttributes
  id: Scalars['Int']
}

export type MutationCallbackPhishingResponseTemplatesAddTopicsArgs = {
  templateIds: Array<Scalars['Int']>
  topicIds: Array<Scalars['Int']>
}

export type MutationCallbackPhishingResponseTemplatesCopyArgs = {
  uuids: Array<Scalars['String']>
}

export type MutationCallbackPhishingResponseTemplatesDeleteArgs = {
  templateIds: Array<Scalars['Int']>
}

export type MutationCallbackPhishingResponseTemplatesRemoveTopicsArgs = {
  templateIds: Array<Scalars['Int']>
  topicIds: Array<Scalars['Int']>
}

export type MutationCallbackPhishingTemplateCreateArgs = {
  attributes: CallbackPhishingTemplateAttributes
}

export type MutationCallbackPhishingTemplateUpdateArgs = {
  attributes: CallbackPhishingTemplateAttributes
  id: Scalars['Int']
}

export type MutationCallbackPhishingTemplatesCopyArgs = {
  uuids: Array<Scalars['String']>
}

export type MutationCallbackPhishingTestEmailCreateArgs = {
  emailTemplateId: Scalars['Int']
  greetingTemplateUuid: Scalars['String']
  phoneNumberRegion: Scalars['String']
}

export type MutationCdrmsCreateCategoryArgs = {
  name: Scalars['String']
}

export type MutationCdrmsCreateRuleArgs = {
  aggregate: Scalars['Boolean']
  bucketId: Scalars['String']
  category: Scalars['String']
  code: Scalars['String']
  count: Scalars['Int']
  criteria: TeachableMomentCriteriaPayload
  description: Scalars['String']
  lookbackDays: Scalars['Int']
  name: Scalars['String']
  riskLevel: Scalars['String']
  rtcCoachingMessage: Scalars['String']
  severity: Scalars['String']
  stage: Scalars['String']
  superBucketId: Scalars['String']
  techContentReviewedName: Scalars['String']
  topics: Array<Scalars['String']>
  vendorCodeName: Scalars['String']
}

export type MutationCdrmsCreateTopicArgs = {
  name: Scalars['String']
}

export type MutationCdrmsCreateVendorArgs = {
  logType: Scalars['String']
  vendorCode: Scalars['String']
  vendorName: Scalars['String']
  vendorType: Scalars['String']
}

export type MutationCdrmsDeleteCategoryArgs = {
  id: Scalars['String']
}

export type MutationCdrmsDeleteRuleArgs = {
  id: Scalars['String']
}

export type MutationCdrmsDeleteTopicArgs = {
  id: Scalars['String']
}

export type MutationCdrmsDeleteVendorArgs = {
  id: Scalars['String']
}

export type MutationCdrmsReleaseRuleArgs = {
  email: Scalars['String']
  environment: Scalars['String']
  id: Scalars['String']
}

export type MutationCdrmsReleaseVendorToProductionArgs = {
  id: Scalars['String']
  logType: Scalars['String']
  vendorCode: Scalars['String']
  vendorName: Scalars['String']
  vendorType: Scalars['String']
}

export type MutationCdrmsUpdateCategoryArgs = {
  id: Scalars['String']
  name: Scalars['String']
}

export type MutationCdrmsUpdateDetectionRuleDisputeArgs = {
  comment?: Maybe<Scalars['String']>
  filters?: Maybe<UpdateRuleDisputeFilter>
  items?: Maybe<Array<UpdateRuleDisputeItem>>
  status?: Maybe<RuleDisputeStatus>
}

export type MutationCdrmsUpdatePartialCategoryArgs = {
  id: Scalars['String']
  name: Scalars['String']
}

export type MutationCdrmsUpdatePartialRuleArgs = {
  aggregate: Scalars['Boolean']
  bucketId: Scalars['String']
  category: Scalars['String']
  code: Scalars['String']
  count: Scalars['Int']
  criteria: TeachableMomentCriteriaPayload
  description: Scalars['String']
  id: Scalars['String']
  lookbackDays: Scalars['Int']
  name: Scalars['String']
  riskLevel: Scalars['String']
  rtcCoachingMessage: Scalars['String']
  severity: Scalars['String']
  stage: Scalars['String']
  superBucketId: Scalars['String']
  techContentReviewedName: Scalars['String']
  topics: Array<Scalars['String']>
  vendorCodeName: Scalars['String']
}

export type MutationCdrmsUpdatePartialTopicArgs = {
  id: Scalars['String']
  name: Scalars['String']
}

export type MutationCdrmsUpdatePartialVendorArgs = {
  id: Scalars['String']
  logType: Scalars['String']
  vendorCode: Scalars['String']
  vendorName: Scalars['String']
  vendorType: Scalars['String']
}

export type MutationCdrmsUpdateRuleArgs = {
  aggregate: Scalars['Boolean']
  bucketId: Scalars['String']
  category: Scalars['String']
  code: Scalars['String']
  count: Scalars['Int']
  criteria: TeachableMomentCriteriaPayload
  description: Scalars['String']
  id: Scalars['String']
  lookbackDays: Scalars['Int']
  name: Scalars['String']
  riskLevel: Scalars['String']
  rtcCoachingMessage: Scalars['String']
  severity: Scalars['String']
  stage: Scalars['String']
  superBucketId: Scalars['String']
  techContentReviewedName: Scalars['String']
  topics: Array<Scalars['String']>
  vendorCodeName: Scalars['String']
}

export type MutationCdrmsUpdateTopicArgs = {
  id: Scalars['String']
  name: Scalars['String']
}

export type MutationCdrmsUpdateVendorArgs = {
  id: Scalars['String']
  logType: Scalars['String']
  vendorCode: Scalars['String']
  vendorName: Scalars['String']
  vendorType: Scalars['String']
}

export type MutationClickwrapsExemptArgs = {
  ids: Array<Scalars['Int']>
}

export type MutationClickwrapsForceArgs = {
  ids: Array<Scalars['Int']>
}

export type MutationContentThemeAssignToTrainingCampaignArgs = {
  contentThemeId: Scalars['Int']
  trainingCampaignId: Scalars['Int']
}

export type MutationContentThemeCloneArgs = {
  id: Scalars['Int']
}

export type MutationContentThemeCreateArgs = {
  attributes: ContentThemeAttributes
  language?: Maybe<Scalars['String']>
}

export type MutationContentThemeDeleteArgs = {
  id: Scalars['Int']
}

export type MutationContentThemeRemoveFromTrainingCampaignArgs = {
  trainingCampaignId: Scalars['Int']
}

export type MutationContentThemeSetDefaultArgs = {
  id: Scalars['Int']
}

export type MutationContentThemeUpdateArgs = {
  attributes: ContentThemeAttributes
  id: Scalars['Int']
  language?: Maybe<Scalars['String']>
}

export type MutationCurrentActorsSignOutArgs = {
  actors: Array<Scalars['String']>
}

export type MutationDeviceDetailsCreateArgs = {
  payload: DeviceDetailsAttributes
}

export type MutationDeviceDetailsDeleteArgs = {
  deviceId: Scalars['String']
}

export type MutationDeviceDetailsUpdateArgs = {
  deviceId: Scalars['String']
  fcmToken?: Maybe<Scalars['String']>
}

export type MutationDkimConfigCreateArgs = {
  attributes: DkimConfigAttributes
}

export type MutationDkimConfigDeleteArgs = {
  id: Scalars['Int']
}

export type MutationDkimConfigUpdateArgs = {
  attributes: DkimConfigAttributes
  id: Scalars['Int']
}

export type MutationDkimConfigValidateArgs = {
  id: Scalars['Int']
}

export type MutationDmiConfigCreateArgs = {
  attributes: DmiConfigAttributes
}

export type MutationDmiConfigDeleteArgs = {
  id: Scalars['Int']
}

export type MutationDmiConfigReconnectArgs = {
  id: Scalars['Int']
}

export type MutationDmiConfigUpdateArgs = {
  attributes: DmiConfigAttributes
  id: Scalars['Int']
}

export type MutationDownloadCreateAccountReportCardArgs = {
  configuration: ReportsTypesAccountReportCard
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateActiveRulesReportArgs = {
  configuration: ReportsTypesActiveRulesReport
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateAidaCampaignSummaryTableArgs = {
  configuration: ReportsTypesAidaCampaignSummaryTable
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateAssessmentScoreByKnowledgeAreaArgs = {
  configuration: ReportsTypesAssessmentScoreByKnowledgeArea
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateAssessmentScoreByKnowledgeAreaOverTimeArgs = {
  configuration: ReportsTypesAssessmentScoreByKnowledgeAreaOverTime
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateAuditLogsArgs = {
  configuration: ReportsTypesAuditLogs
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateEmailsSentByGroupArgs = {
  configuration: ReportsTypesEmailsSentByGroup
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateEmailsSentByTemplateArgs = {
  configuration: ReportsTypesEmailsSentByTemplate
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateFailureTypeUserCountsArgs = {
  configuration: ReportsTypesFailureTypeUserCounts
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateFailureTypesBarChartArgs = {
  configuration: ReportsTypesFailureTypesBarChart
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateFailuresByPhishingTemplateArgs = {
  configuration: ReportsTypesFailuresByPhishingTemplate
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateGlobalPhishingAggregateArgs = {
  configuration: ReportsTypesGlobalPhishingAggregate
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateGlobalPhishingRawArgs = {
  configuration: ReportsTypesGlobalPhishingRaw
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateGlobalTrainingAggregateArgs = {
  configuration: ReportsTypesGlobalTrainingAggregate
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateGlobalTrainingRawArgs = {
  configuration: ReportsTypesGlobalTrainingRaw
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateGroupReportCardArgs = {
  configuration: ReportsTypesGroupReportCard
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateIncompleteTrainingArgs = {
  configuration: ReportsTypesIncompleteTraining
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateInitialPppArgs = {
  configuration: ReportsTypesInitialPpp
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateLearnerPlatformRawArgs = {
  configuration: ReportsTypesLearnerPlatformRaw
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateNoTrainingAssignedArgs = {
  configuration: ReportsTypesNoTrainingAssigned
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateOverallPppByAccountArgs = {
  configuration: ReportsTypesOverallPppByAccount
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateOverallPppByCampaignArgs = {
  configuration: ReportsTypesOverallPppByCampaign
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateOverallPppByGroupArgs = {
  configuration: ReportsTypesOverallPppByGroup
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateOverallPppByTrainingTimeArgs = {
  configuration: ReportsTypesOverallPppByTrainingTime
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreatePabComparisonArgs = {
  configuration: ReportsTypesPabComparison
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreatePabUserActivityArgs = {
  configuration: ReportsTypesPabUserActivity
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreatePhishFailurePercentageByCampaignsArgs = {
  configuration: ReportsTypesPhishFailurePercentageByCampaigns
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreatePhishFailurePercentageOverallArgs = {
  configuration: ReportsTypesPhishFailurePercentageOverall
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreatePhishingAllClickersArgs = {
  configuration: ReportsTypesPhishingAllClickers
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreatePhishingSecurityTestsArgs = {
  configuration: ReportsTypesPhishingSecurityTests
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreatePppDistributionArgs = {
  configuration: ReportsTypesPppDistribution
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateRiskScoreByAssessmentArgs = {
  configuration: ReportsTypesRiskScoreByAssessment
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateRiskScoreByGroupArgs = {
  configuration: ReportsTypesRiskScoreByGroup
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateRiskScoreByLocationArgs = {
  configuration: ReportsTypesRiskScoreByLocation
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateRiskScoreBySurveyArgs = {
  configuration: ReportsTypesRiskScoreBySurvey
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateRiskScoreByUserArgs = {
  configuration: ReportsTypesRiskScoreByUser
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateRiskScoreV2ByGroupArgs = {
  configuration: ReportsTypesRiskScoreV2ByGroup
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateRiskScoreV2ByManagerArgs = {
  configuration: ReportsTypesRiskScoreV2ByManager
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateRiskScoreV2ByUserArgs = {
  configuration: ReportsTypesRiskScoreV2ByUser
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateSecurityCoachActivityRtcAggregateArgs = {
  configuration: ReportsTypesSecurityCoachActivityRtcAggregate
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateSecurityCoachActivityRtcRawArgs = {
  configuration: ReportsTypesSecurityCoachActivityRtcRaw
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateSecurityCoachDetectionRulesActivityAggregateArgs =
  {
    configuration: ReportsTypesSecurityCoachDetectionRulesActivityAggregate
    fileType: DownloadFileTypes
    locale: Scalars['String']
  }

export type MutationDownloadCreateSecurityCoachDetectionRulesActivityRawArgs = {
  configuration: ReportsTypesSecurityCoachDetectionRulesActivityRaw
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateSurveyScoreByDimensionArgs = {
  configuration: ReportsTypesSurveyScoreByDimension
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateSurveyScoreByDimensionOverTimeArgs = {
  configuration: ReportsTypesSurveyScoreByDimensionOverTime
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateTrainingCampaignEnrollmentsArgs = {
  configuration: ReportsTypesTrainingCampaignEnrollments
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateTrainingCampaignSurveysArgs = {
  configuration: ReportsTypesTrainingCampaignSurveys
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateTrainingComparisonByPppArgs = {
  configuration: ReportsTypesTrainingComparisonByPpp
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateTrainingComparisonByRiskScoreArgs = {
  configuration: ReportsTypesTrainingComparisonByRiskScore
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateTrainingHoursArgs = {
  configuration: ReportsTypesTrainingHours
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateTrainingInteractionsByGroupArgs = {
  configuration: ReportsTypesTrainingInteractionsByGroup
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateTrainingInteractionsByPurchasedCourseArgs = {
  configuration: ReportsTypesTrainingInteractionsByPurchasedCourse
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateTrainingNotStartedArgs = {
  configuration: ReportsTypesTrainingNotStarted
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateTrainingStatusByCampaignArgs = {
  configuration: ReportsTypesTrainingStatusByCampaign
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateTrainingStatusByCourseArgs = {
  configuration: ReportsTypesTrainingStatusByCourse
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateTrainingStatusByGroupArgs = {
  configuration: ReportsTypesTrainingStatusByGroup
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateTrainingStatusesByCampaignArgs = {
  configuration: ReportsTypesTrainingStatusesByCampaign
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateTrainingSurveysByCampaignArgs = {
  configuration: ReportsTypesTrainingSurveysByCampaign
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateTrainingSurveysByCourseArgs = {
  configuration: ReportsTypesTrainingSurveysByCourse
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateUnphishedUsersArgs = {
  configuration: ReportsTypesUnphishedUsers
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateUserCountByAssessmentArgs = {
  configuration: ReportsTypesUserCountByAssessment
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateUserCountBySurveyArgs = {
  configuration: ReportsTypesUserCountBySurvey
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateUserCountsArgs = {
  configuration: ReportsTypesUserCounts
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateUserPhishingCampaignsArgs = {
  configuration: ReportsTypesUserPhishingCampaigns
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateUserReportCardArgs = {
  configuration: ReportsTypesUserReportCard
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateUserSignInsArgs = {
  configuration: ReportsTypesUserSignIns
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateUserTrainingCampaignsArgs = {
  configuration: ReportsTypesUserTrainingCampaigns
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadCreateUsersListArgs = {
  configuration: ReportsTypesUsersList
  fileType: DownloadFileTypes
  locale: Scalars['String']
}

export type MutationDownloadDeleteArgs = {
  downloadIds: Array<Scalars['Int']>
}

export type MutationDownloadReportRetryArgs = {
  id: Scalars['Int']
  locale: Scalars['String']
}

export type MutationDownloadSavedReportArgs = {
  fileType: DownloadFileTypes
  id: Scalars['Int']
}

export type MutationDownloadViewedArgs = {
  all?: Maybe<Scalars['Boolean']>
  ids?: Maybe<Array<Scalars['Int']>>
  isDownloaded?: Maybe<Scalars['Boolean']>
}

export type MutationEaiGenerateTemplateArgs = {
  attributes: EaiPhishingTemplateAttributes
}

export type MutationEaiSaveTemplateArgs = {
  attributes: EaiPhishingTemplateSaveAttributes
}

export type MutationEaiSubmitFeedbackArgs = {
  attributes: EaiPhishingTemplateFeedbackAttributes
}

export type MutationEaiUpdateTemplateArgs = {
  attributes: EaiPhishingTemplateUpdateAttributes
}

export type MutationEmailAliasCreateArgs = {
  emailAlias: Scalars['String']
  userId: Scalars['Int']
}

export type MutationEmailAliasDeleteArgs = {
  id: Scalars['Int']
}

export type MutationEmailTemplateDeleteArgs = {
  id: Scalars['Int']
}

export type MutationEnrollmentAcceptUrlPolicyArgs = {
  id: Scalars['Int']
}

export type MutationEnrollmentCreateArgs = {
  id: Scalars['String']
  language?: Maybe<Scalars['String']>
  storeItemSource?: Maybe<StoreItemSource>
}

export type MutationEnrollmentStartArgs = {
  id: Scalars['Int']
  language?: Maybe<Scalars['String']>
  storeItemSource?: Maybe<StoreItemSource>
}

export type MutationEnrollmentUpdateArgs = {
  action: EnrollmentUpdateActions
  enrollmentIds: Array<Scalars['Int']>
  trainingCampaignId: Scalars['Int']
}

export type MutationEnvironmentQuestionnaireSaveArgs = {
  attributes: EnvironmentQuestionnaireAttributes
}

export type MutationExecReportsDashboardCreateArgs = {
  accountIds?: Maybe<Array<Scalars['Int']>>
  fontColor?: Maybe<Scalars['String']>
  fontSize?: Maybe<Scalars['String']>
  fontStyle?: Maybe<Scalars['String']>
  groupIds?: Maybe<Array<Scalars['Int']>>
  name: Scalars['String']
  pdfType?: Maybe<Scalars['String']>
  timeframe?: Maybe<ReportsTimeframe>
  widgets: Array<ExecReportsDashboardWidget>
}

export type MutationExecReportsDashboardDeleteArgs = {
  id: Scalars['Int']
}

export type MutationExecReportsDashboardUpdateArgs = {
  accountIds?: Maybe<Array<Scalars['Int']>>
  fontColor?: Maybe<Scalars['String']>
  fontSize?: Maybe<Scalars['String']>
  fontStyle?: Maybe<Scalars['String']>
  groupIds?: Maybe<Array<Scalars['Int']>>
  id: Scalars['Int']
  name: Scalars['String']
  pdfType?: Maybe<Scalars['String']>
  timeframe?: Maybe<ReportsTimeframe>
  widgets: Array<ExecReportsDashboardWidget>
}

export type MutationExecReportsPdfArgs = {
  config: ExecReportsPdf
}

export type MutationForceSyncScimArgs = {
  id?: Maybe<Scalars['Int']>
}

export type MutationFreePrtCreateArgs = {
  emailTemplateUuid?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  recipients: Array<Scalars['String']>
  senderEmail: Scalars['String']
  senderName: Scalars['String']
}

export type MutationFreePstCreateArgs = {
  emailTemplateUuid?: Maybe<Scalars['String']>
  landingPageUuid?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  phishDomainId?: Maybe<Scalars['Int']>
  recipients: Array<Scalars['String']>
}

export type MutationFreeQrtCreateArgs = {
  emailTemplateUuid?: Maybe<Scalars['String']>
  landingPageUuid?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  recipients: Array<Scalars['String']>
}

export type MutationFreeSptCreateArgs = {
  emailTemplateUuid?: Maybe<Scalars['String']>
  landingPageUuid?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  recipients: Array<Scalars['String']>
}

export type MutationFreeToolLaunchArgs = {
  toolType: FreeToolTypes
}

export type MutationGenerateActiveRulesReportArgs = {
  from?: Maybe<Scalars['ISO8601Date']>
  rule?: Maybe<Scalars['String']>
  search?: Maybe<Scalars['String']>
  vendor?: Maybe<Scalars['String']>
}

export type MutationGenerateAuditLogsArgs = {
  activities?: Maybe<Array<AuditLogActivities>>
  changes?: Maybe<Array<AuditLogChangeFields>>
  endTime?: Maybe<Scalars['ISO8601DateTime']>
  impersonatingUserIds?: Maybe<Array<Scalars['Int']>>
  objectCategories?: Maybe<Array<AuditLogObjectCategories>>
  objectIds?: Maybe<Array<Scalars['Int']>>
  objectTypes?: Maybe<Array<AuditLogObjectTypes>>
  onlyApiEvents?: Maybe<Scalars['Boolean']>
  parentPartnerAdminIds?: Maybe<Array<Scalars['Int']>>
  partnerAdminIds?: Maybe<Array<Scalars['Int']>>
  searchPeriod?: Maybe<AuditLogSearchPeriods>
  siteAdminIds?: Maybe<Array<Scalars['Int']>>
  startTime?: Maybe<Scalars['ISO8601DateTime']>
  userIds?: Maybe<Array<Scalars['Int']>>
}

export type MutationGeneratePabComparisonArgs = {
  timeframe: ReportsTimeframe
}

export type MutationGeneratePabUserActivityArgs = {
  activityStatus?: Maybe<UserHasOutlookAddinActivityTypes>
  activityType?: Maybe<OutlookAddinActivityActivityTypes>
  search?: Maybe<Scalars['String']>
  userStatus?: Maybe<UserStatusFilters>
}

export type MutationGeneratePhishingAllClickersArgs = {
  callback?: Maybe<Scalars['Boolean']>
  phishingCampaignId?: Maybe<Scalars['Int']>
  phishingCampaignRunId?: Maybe<Scalars['Int']>
}

export type MutationGeneratePhishingSecurityTestsArgs = {
  comparison?: Maybe<Scalars['String']>
  grouping?: Maybe<Scalars['String']>
  groups?: Maybe<Array<Scalars['Int']>>
  includeNonFailures?: Maybe<Scalars['Boolean']>
  includePhishFlipCampaigns?: Maybe<Scalars['String']>
  psts?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
}

export type MutationGenerateTrainingCampaignEnrollmentsArgs = {
  assetId?: Maybe<Scalars['Int']>
  assetType?: Maybe<Scalars['String']>
  includeArchived?: Maybe<Scalars['Boolean']>
  scope?: Maybe<EnrollmentScopes>
  search?: Maybe<Scalars['String']>
  trainingCampaignId: Scalars['Int']
}

export type MutationGenerateTrainingCampaignSurveysArgs = {
  trainingCampaignId: Scalars['Int']
}

export type MutationGenerateUserPhishingCampaignsArgs = {
  userId: Scalars['Int']
}

export type MutationGenerateUserTrainingCampaignsArgs = {
  userId: Scalars['Int']
}

export type MutationGenerateUsersListArgs = {
  filterNoGroups: Scalars['Boolean']
  groupStatus?: Maybe<GroupStatuses>
  groups?: Maybe<Array<Scalars['Int']>>
  search?: Maybe<Scalars['String']>
  singleGroup?: Maybe<Scalars['Boolean']>
  sortBy: UserSortFields
  sortDir: SortDirections
  userStatus: UserStatusFilters
  userType: UserTypeFilters
}

export type MutationGpabCsvEmailArgs = {
  payload?: Scalars['File']
  phishalertSettingId: Scalars['Int']
}

export type MutationGpabQuickEmailArgs = {
  emails: Array<Scalars['String']>
  phishalertSettingId: Scalars['Int']
}

export type MutationGroupArchiveArgs = {
  archive: Scalars['Boolean']
  ids: Array<Scalars['Int']>
}

export type MutationGroupArchiveUsersArgs = {
  id: Scalars['Int']
}

export type MutationGroupBulkDeleteArgs = {
  adminsToNotify?: Maybe<Array<Scalars['Int']>>
  file: Scalars['File']
  notifyRequester?: Maybe<Scalars['Boolean']>
}

export type MutationGroupCloneArgs = {
  id: Scalars['Int']
  name: Scalars['String']
  static?: Maybe<Scalars['Boolean']>
}

export type MutationGroupCreateArgs = {
  attributes: GroupAttributes
}

export type MutationGroupDeleteArgs = {
  ids: Array<Scalars['Int']>
}

export type MutationGroupRemoveUsersArgs = {
  id: Scalars['Int']
}

export type MutationGroupSaveCriterionsArgs = {
  criterions: Array<GroupCriterionAttributes>
  groupId: Scalars['Int']
}

export type MutationGroupUpdateArgs = {
  attributes: GroupAttributes
  id: Scalars['Int']
}

export type MutationIpRangeAdminPurgeArgs = {
  all: Scalars['Boolean']
  ipRangeId: Scalars['Int']
}

export type MutationIpRangeCreateArgs = {
  attributes: IpRangeAttributes
}

export type MutationIpRangeDeleteArgs = {
  ipRangeId: Scalars['Int']
}

export type MutationIpRangePurgeArgs = {
  ipRangeId: Scalars['Int']
  timePeriod: IpRangePurgeTimePeriods
}

export type MutationIpRangeUpdateArgs = {
  attributes: IpRangeAttributes
  ipRangeId: Scalars['Int']
}

export type MutationLandingDomainCreateArgs = {
  attributes: LandingDomainAttributes
}

export type MutationLandingDomainDeleteArgs = {
  id: Scalars['Int']
}

export type MutationLandingDomainToggleArchivedArgs = {
  id: Scalars['Int']
}

export type MutationLandingDomainUpdateArgs = {
  attributes: LandingDomainAttributes
  id: Scalars['Int']
}

export type MutationLandingPageCategoryCreateArgs = {
  attributes: LandingPageCategoryAttributes
}

export type MutationLandingPageCategoryDeleteArgs = {
  id: Scalars['Int']
}

export type MutationLandingPageCategoryUpdateArgs = {
  attributes: LandingPageCategoryAttributes
  id: Scalars['Int']
}

export type MutationLandingPageCreateArgs = {
  attributes: LandingPageAttributes
}

export type MutationLandingPageImportArgs = {
  payload: Scalars['String']
}

export type MutationLandingPageUpdateArgs = {
  attributes: LandingPageAttributes
  id: Scalars['Int']
}

export type MutationLmsCommitArgs = {
  enrollmentId: Scalars['Int']
  payload: Scalars['String']
  processedAt: Scalars['ISO8601DateTime']
  sessionId: Scalars['String']
}

export type MutationLmsInitArgs = {
  enrollmentId: Scalars['Int']
}

export type MutationLmsTerminateArgs = {
  enrollmentId: Scalars['Int']
  payload: Scalars['String']
  processedAt: Scalars['ISO8601DateTime']
  sessionId: Scalars['String']
}

export type MutationManagedPhishingCampaignCreateArgs = {
  attributes: ManagedPhishingCampaignAttributes
}

export type MutationManagedPhishingCampaignDeactivateArgs = {
  deactivate: Scalars['Boolean']
  id: Scalars['Int']
}

export type MutationManagedPhishingCampaignDeleteArgs = {
  id: Scalars['Int']
}

export type MutationManagedPhishingCampaignHideFromReportsArgs = {
  hide: Scalars['Boolean']
  id: Scalars['Int']
}

export type MutationManagedPhishingCampaignUpdateArgs = {
  attributes: ManagedPhishingCampaignAttributes
  id: Scalars['Int']
}

export type MutationManagedStorePurchaseAddToChildrensLibrariesArgs = {
  accountIds: Array<Scalars['Int']>
  managedStorePurchaseId: Scalars['Int']
}

export type MutationManagedStorePurchaseCreateArgs = {
  uuid: Scalars['String']
}

export type MutationManagedStorePurchaseRemoveArgs = {
  id: Scalars['Int']
}

export type MutationManagedStorePurchaseUpdateArgs = {
  attributes?: Maybe<ManagedStorePurchaseAttributes>
  id: Scalars['Int']
}

export type MutationManagedTrainingCampaignCloseArgs = {
  id: Scalars['Int']
}

export type MutationManagedTrainingCampaignCreateArgs = {
  attributes: ManagedTrainingCampaignAttributes
  endCampaignOption: TrainingCampaignEnd
}

export type MutationManagedTrainingCampaignDeleteArgs = {
  id: Scalars['Int']
}

export type MutationManagedTrainingCampaignUpdateArgs = {
  attributes: ManagedTrainingCampaignAttributes
  endCampaignOption: TrainingCampaignEnd
  id: Scalars['Int']
}

export type MutationMessageCampaignCreateArgs = {
  attributes: MessagesAttributes
}

export type MutationMessageCampaignDeleteArgs = {
  id: Scalars['Int']
}

export type MutationMessageCampaignUpdateArgs = {
  attributes: MessagesAttributes
  id: Scalars['Int']
}

export type MutationNotificationCreateArgs = {
  attributes: NotificationAttributes
  campaignId: Scalars['Int']
}

export type MutationNotificationTemplateCategoryCreateArgs = {
  attributes: NotificationTemplateCategoryAttributes
}

export type MutationNotificationTemplateCategoryDeleteArgs = {
  id: Scalars['Int']
}

export type MutationNotificationTemplateCategoryHideArgs = {
  hide: Scalars['Boolean']
  id: Scalars['Int']
}

export type MutationNotificationTemplateCategoryUpdateArgs = {
  attributes: NotificationTemplateCategoryAttributes
  id: Scalars['Int']
}

export type MutationNotificationTemplateCreateArgs = {
  attributes: NotificationTemplateAttributes
}

export type MutationNotificationTemplateHideArgs = {
  hide: Scalars['Boolean']
  id: Scalars['Int']
}

export type MutationNotificationTemplateImportArgs = {
  payload: Scalars['String']
  type: NotificationTemplateTypes
}

export type MutationNotificationTemplateUpdateArgs = {
  attributes: NotificationTemplateAttributes
  id: Scalars['Int']
}

export type MutationPartnerAdminCreateArgs = {
  attributes: PartnerAdminAttributes
  partnerId?: Maybe<Scalars['Int']>
}

export type MutationPartnerAdminDeactivateMfaArgs = {
  deactivate: Scalars['Boolean']
  id: Scalars['Int']
}

export type MutationPartnerAdminDeleteArgs = {
  id: Scalars['Int']
}

export type MutationPartnerAdminDisableMfaArgs = {
  partnerId?: Maybe<Scalars['Int']>
  password?: Maybe<Scalars['String']>
}

export type MutationPartnerAdminEnableMfaArgs = {
  otp: Scalars['String']
  partnerId?: Maybe<Scalars['Int']>
}

export type MutationPartnerAdminUpdateArgs = {
  attributes: PartnerAdminAttributes
  id: Scalars['Int']
}

export type MutationPartnerAdminUpdateSelfArgs = {
  attributes: PartnerAdminAttributes
}

export type MutationPartnerApiTokensCreateArgs = {
  payload: PartnersApiTokenAttributes
}

export type MutationPartnerApiTokensDeleteArgs = {
  id: Scalars['Int']
}

export type MutationPartnerApiTokensReassignArgs = {
  partnerAdminId: Scalars['Int']
}

export type MutationPartnerApiTokensUpdateArgs = {
  id: Scalars['Int']
  payload: PartnersApiTokenAttributes
}

export type MutationPartnerCreateArgs = {
  attributes: PartnerAttributes
}

export type MutationPartnerDeleteArgs = {
  id: Scalars['Int']
}

export type MutationPartnerDeleteLogoArgs = {
  partnerId: Scalars['Int']
}

export type MutationPartnerSalesforceDisconnectArgs = {
  sfid: Scalars['String']
}

export type MutationPartnerSalesforceFetchArgs = {
  id?: Maybe<Scalars['Int']>
  sfid?: Maybe<Scalars['String']>
  useParentsSfid?: Maybe<Scalars['Boolean']>
}

export type MutationPartnerSalesforceScheduleAccountsFetchArgs = {
  id: Scalars['Int']
  sfid?: Maybe<Scalars['String']>
  timestamp: Scalars['ISO8601DateTime']
  useParentsSfid?: Maybe<Scalars['Boolean']>
}

export type MutationPartnerUpdateArgs = {
  attributes: PartnerAttributes
  id: Scalars['Int']
}

export type MutationPartnerUpdateAccountArgs = {
  attributes: PartnerAttributes
}

export type MutationPasswordIqCsvArgs = {
  detection: Array<DetectionTypes>
  search?: Maybe<Scalars['String']>
  sort?: Maybe<UserStatesSort>
  userType?: Maybe<PiqUserTypes>
}

export type MutationPasswordIqDashboardCreateArgs = {
  name: Scalars['String']
  widgets: Array<PasswordIqDashboardWidget>
}

export type MutationPasswordIqDashboardDeleteArgs = {
  id: Scalars['Int']
}

export type MutationPasswordIqDashboardUpdateArgs = {
  id: Scalars['Int']
  name: Scalars['String']
  widgets: Array<PasswordIqDashboardWidget>
}

export type MutationPasswordIqPdfArgs = {
  config: PiqPdf
}

export type MutationPasswordIqScheduleUpdateArgs = {
  attributes: PiqScheduleAttributes
}

export type MutationPasswordIqUpdateEventArgs = {
  id: Scalars['Int']
  note: Scalars['String']
  status: EventStatuses
}

export type MutationPauseGoogleSyncArgs = {
  id?: Maybe<Scalars['Int']>
}

export type MutationPhishDomainCreateArgs = {
  attributes: PhishDomainAttributes
}

export type MutationPhishDomainCreateCustomArgs = {
  phishDomainId: Scalars['Int']
  subdomain: Scalars['String']
}

export type MutationPhishDomainDeleteArgs = {
  phishDomainId: Scalars['Int']
}

export type MutationPhishDomainToggleArchivedArgs = {
  id: Scalars['Int']
}

export type MutationPhishDomainToggleOverrideArgs = {
  phishDomainId: Scalars['Int']
}

export type MutationPhishDomainUpdateArgs = {
  attributes: PhishDomainAttributes
  id: Scalars['Int']
}

export type MutationPhishingCampaignCreateArgs = {
  attributes: PhishingCampaignAttributes
  campaignType?: Maybe<PhishingCampaignTypes>
}

export type MutationPhishingCampaignDeactivateArgs = {
  deactivate: Scalars['Boolean']
  id: Scalars['Int']
}

export type MutationPhishingCampaignDeleteArgs = {
  id: Scalars['Int']
}

export type MutationPhishingCampaignEditArgs = {
  attributes: PhishingCampaignAttributes
  id: Scalars['Int']
}

export type MutationPhishingCampaignHideFromReportsArgs = {
  hide: Scalars['Boolean']
  id: Scalars['Int']
}

export type MutationPhishingCampaignRecipientRemoveReplyArgs = {
  id: Scalars['ID']
}

export type MutationPhishingCampaignRecipientUpdateArgs = {
  attributes: PhishingCampaignRecipientAttributes
  id: Scalars['ID']
}

export type MutationPhishingCampaignRunBulkChangeArgs = {
  adminsToNotify?: Maybe<Array<Scalars['Int']>>
  file: Scalars['File']
  notifyRequester?: Maybe<Scalars['Boolean']>
  phishingCampaignRunId: Scalars['Int']
}

export type MutationPhishingCampaignRunDeleteArgs = {
  campaignId: Scalars['Int']
  runId: Scalars['Int']
}

export type MutationPhishingCampaignRunStopArgs = {
  campaignId: Scalars['Int']
  runId: Scalars['Int']
}

export type MutationPhishingTemplateCategoryCreateArgs = {
  attributes: PhishingTemplateCategoryAttributes
}

export type MutationPhishingTemplateCategoryDeleteArgs = {
  id: Scalars['Int']
}

export type MutationPhishingTemplateCategoryHideArgs = {
  hide: Scalars['Boolean']
  id: Scalars['Int']
}

export type MutationPhishingTemplateCategoryUpdateArgs = {
  attributes: PhishingTemplateCategoryAttributes
  id: Scalars['Int']
}

export type MutationPhishingTemplateCreateArgs = {
  attributes: PhishingTemplateAttributes
  type: PhishingTemplateTypes
}

export type MutationPhishingTemplateHideArgs = {
  hide: Scalars['Boolean']
  id: Scalars['Int']
}

export type MutationPhishingTemplateImportArgs = {
  payload: Scalars['String']
  type: PhishingTemplateTypes
}

export type MutationPhishingTemplateUpdateArgs = {
  attributes: PhishingTemplateAttributes
  id: Scalars['Int']
}

export type MutationPhishingTemplatesCopyArgs = {
  topicIds?: Maybe<Array<Scalars['Int']>>
  uuids: Array<Scalars['String']>
}

export type MutationPhishingTemplatesTopicsAddArgs = {
  templateIds: Array<Scalars['Int']>
  topicIds: Array<Scalars['Int']>
}

export type MutationPhishingTemplatesTopicsRemoveArgs = {
  templateIds: Array<Scalars['Int']>
  topicIds: Array<Scalars['Int']>
}

export type MutationPhysicalQrCampaignCreateArgs = {
  attributes: PhysicalQrCampaignAttributes
}

export type MutationPhysicalQrCampaignDeleteArgs = {
  id: Scalars['Int']
}

export type MutationPhysicalQrCampaignEndCampaignArgs = {
  id: Scalars['Int']
}

export type MutationPhysicalQrCampaignLocationCreateArgs = {
  attributes: PhysicalQrCampaignLocationAttributes
  physicalQrCampaignId: Scalars['Int']
}

export type MutationPhysicalQrCampaignLocationDeleteArgs = {
  id: Scalars['Int']
  physicalQrCampaignId: Scalars['Int']
}

export type MutationPhysicalQrCampaignLocationUpdateArgs = {
  attributes: PhysicalQrCampaignLocationAttributes
  id: Scalars['Int']
  physicalQrCampaignId: Scalars['Int']
}

export type MutationPhysicalQrCampaignStartCampaignArgs = {
  id: Scalars['Int']
}

export type MutationPhysicalQrCampaignUpdateArgs = {
  attributes: PhysicalQrCampaignAttributes
  id: Scalars['Int']
}

export type MutationPhysicalQrTemplateCreateArgs = {
  attributes: PhysicalQrTemplateAttributes
}

export type MutationPhysicalQrTemplateUpdateArgs = {
  attributes: PhysicalQrTemplateAttributes
  id: Scalars['Int']
}

export type MutationPhysicalQrTemplatesCopyArgs = {
  uuids: Array<Scalars['String']>
}

export type MutationPolicyAcceptArgs = {
  enrollmentId: Scalars['Int']
}

export type MutationPolicyAssetCreateArgs = {
  attributes: PolicyAssetAttributes
  policyId: Scalars['Int']
}

export type MutationPolicyAssetDeleteArgs = {
  id: Scalars['Int']
}

export type MutationPolicyAssetUpdateArgs = {
  attributes: PolicyAssetAttributes
  id: Scalars['Int']
}

export type MutationPolicyCreateArgs = {
  attributes: PolicyAttributes
}

export type MutationPolicyDeleteArgs = {
  id: Scalars['Int']
}

export type MutationPolicyUpdateArgs = {
  attributes: PolicyAttributes
  id: Scalars['Int']
}

export type MutationPolicyUpdateAcceptedPagesArgs = {
  enrollmentId: Scalars['Int']
  pages: Array<Scalars['Int']>
}

export type MutationPolicyUpdateAssetIdArgs = {
  assetId: Scalars['Int']
  enrollmentId: Scalars['Int']
}

export type MutationPolicyUpdatePagesViewedArgs = {
  enrollmentId: Scalars['Int']
  pages: Array<Scalars['Int']>
}

export type MutationPolicyUpdateTotalTimeArgs = {
  enrollmentId: Scalars['Int']
  time: Scalars['Int']
}

export type MutationPurgeScimV2Args = {
  accountId?: Maybe<Scalars['Int']>
  type: ScimPurgeTypes
}

export type MutationRegenerateScimTokenArgs = {
  id?: Maybe<Scalars['Int']>
}

export type MutationRemoveGoogleConfigArgs = {
  id?: Maybe<Scalars['Int']>
}

export type MutationReportCategoryCreateArgs = {
  name: Scalars['String']
}

export type MutationReportCategoryDeleteArgs = {
  id: Scalars['Int']
}

export type MutationReportCategoryReorderArgs = {
  ids: Array<Scalars['Int']>
}

export type MutationReportCategoryUpdateArgs = {
  id: Scalars['Int']
  name: Scalars['String']
}

export type MutationReportCloneArgs = {
  categoryId?: Maybe<Scalars['Int']>
  id: Scalars['Int']
  name: Scalars['String']
}

export type MutationReportDeleteArgs = {
  ids: Array<Scalars['Int']>
}

export type MutationReportMoveArgs = {
  categoryId?: Maybe<Scalars['Int']>
  ids: Array<Scalars['Int']>
}

export type MutationReportSaveAccountReportCardArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesAccountReportCard>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveActiveRulesReportArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesActiveRulesReport>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveAidaCampaignSummaryTableArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesAidaCampaignSummaryTable>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveAssessmentScoreByKnowledgeAreaArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesAssessmentScoreByKnowledgeArea>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveAssessmentScoreByKnowledgeAreaOverTimeArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesAssessmentScoreByKnowledgeAreaOverTime>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveAuditLogsArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesAuditLogs>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveEmailsSentByGroupArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesEmailsSentByGroup>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveEmailsSentByTemplateArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesEmailsSentByTemplate>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveFailureTypeUserCountsArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesFailureTypeUserCounts>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveFailureTypesBarChartArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesFailureTypesBarChart>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveFailuresByPhishingTemplateArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesFailuresByPhishingTemplate>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveGlobalPhishingAggregateArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesGlobalPhishingAggregate>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveGlobalPhishingRawArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesGlobalPhishingRaw>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveGlobalTrainingAggregateArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesGlobalTrainingAggregate>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveGlobalTrainingRawArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesGlobalTrainingRaw>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveGroupReportCardArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesGroupReportCard>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveIncompleteTrainingArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesIncompleteTraining>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveInitialPppArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesInitialPpp>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveLearnerPlatformRawArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesLearnerPlatformRaw>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveNoTrainingAssignedArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesNoTrainingAssigned>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveOverallPppByAccountArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesOverallPppByAccount>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveOverallPppByCampaignArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesOverallPppByCampaign>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveOverallPppByGroupArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesOverallPppByGroup>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveOverallPppByTrainingTimeArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesOverallPppByTrainingTime>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSavePabComparisonArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesPabComparison>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSavePabUserActivityArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesPabUserActivity>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSavePhishFailurePercentageByCampaignsArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesPhishFailurePercentageByCampaigns>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSavePhishFailurePercentageOverallArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesPhishFailurePercentageOverall>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSavePhishingAllClickersArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesPhishingAllClickers>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSavePhishingSecurityTestsArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesPhishingSecurityTests>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSavePppDistributionArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesPppDistribution>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveRiskScoreByAssessmentArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesRiskScoreByAssessment>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveRiskScoreByGroupArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesRiskScoreByGroup>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveRiskScoreByLocationArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesRiskScoreByLocation>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveRiskScoreBySurveyArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesRiskScoreBySurvey>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveRiskScoreByUserArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesRiskScoreByUser>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveRiskScoreV2ByGroupArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesRiskScoreV2ByGroup>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveRiskScoreV2ByManagerArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesRiskScoreV2ByManager>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveRiskScoreV2ByUserArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesRiskScoreV2ByUser>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveSecurityCoachActivityRtcAggregateArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesSecurityCoachActivityRtcAggregate>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveSecurityCoachActivityRtcRawArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesSecurityCoachActivityRtcRaw>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveSecurityCoachDetectionRulesActivityAggregateArgs =
  {
    categoryId?: Maybe<Scalars['Int']>
    configuration?: Maybe<ReportsTypesSecurityCoachDetectionRulesActivityAggregate>
    id?: Maybe<Scalars['Int']>
    name?: Maybe<Scalars['String']>
  }

export type MutationReportSaveSecurityCoachDetectionRulesActivityRawArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesSecurityCoachDetectionRulesActivityRaw>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveSurveyScoreByDimensionArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesSurveyScoreByDimension>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveSurveyScoreByDimensionOverTimeArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesSurveyScoreByDimensionOverTime>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveTrainingCampaignEnrollmentsArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesTrainingCampaignEnrollments>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveTrainingCampaignSurveysArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesTrainingCampaignSurveys>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveTrainingComparisonByPppArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesTrainingComparisonByPpp>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveTrainingComparisonByRiskScoreArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesTrainingComparisonByRiskScore>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveTrainingHoursArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesTrainingHours>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveTrainingInteractionsByGroupArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesTrainingInteractionsByGroup>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveTrainingInteractionsByPurchasedCourseArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesTrainingInteractionsByPurchasedCourse>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveTrainingNotStartedArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesTrainingNotStarted>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveTrainingStatusByCampaignArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesTrainingStatusByCampaign>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveTrainingStatusByCourseArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesTrainingStatusByCourse>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveTrainingStatusByGroupArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesTrainingStatusByGroup>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveTrainingStatusesByCampaignArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesTrainingStatusesByCampaign>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveTrainingSurveysByCampaignArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesTrainingSurveysByCampaign>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveTrainingSurveysByCourseArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesTrainingSurveysByCourse>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveUnphishedUsersArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesUnphishedUsers>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveUserCountByAssessmentArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesUserCountByAssessment>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveUserCountBySurveyArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesUserCountBySurvey>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveUserCountsArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesUserCounts>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveUserPhishingCampaignsArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesUserPhishingCampaigns>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveUserReportCardArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesUserReportCard>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveUserSignInsArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesUserSignIns>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveUserTrainingCampaignsArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesUserTrainingCampaigns>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationReportSaveUsersListArgs = {
  categoryId?: Maybe<Scalars['Int']>
  configuration?: Maybe<ReportsTypesUsersList>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type MutationRerunDemoSeedArgs = {
  seed: Scalars['String']
}

export type MutationRestrictedDomainCreateArgs = {
  attributes: RestrictedDomainAttributes
  tld?: Maybe<Scalars['Boolean']>
}

export type MutationRestrictedDomainDeleteArgs = {
  id: Scalars['Int']
}

export type MutationRestrictedDomainUpdateArgs = {
  attributes: RestrictedDomainAttributes
  id: Scalars['Int']
}

export type MutationRevokeScimTokenArgs = {
  id?: Maybe<Scalars['Int']>
}

export type MutationSaveGoogleConfigArgs = {
  attributes: ConfigAttributes
}

export type MutationSaveGoogleCredentialsArgs = {
  googleCustomerId: Scalars['String']
  googleSubjectEmail: Scalars['String']
  id?: Maybe<Scalars['Int']>
}

export type MutationScheduledReportDeactivateArgs = {
  id: Scalars['Int']
}

export type MutationScheduledReportDeleteArgs = {
  id: Scalars['Int']
}

export type MutationScheduledReportSaveArgs = {
  attributes: ScheduledReportAttributes
  id?: Maybe<Scalars['Int']>
  schedulableName?: Maybe<Scalars['String']>
  schedulableType: Schedulable
}

export type MutationScheduledReportSendArgs = {
  id: Scalars['Int']
}

export type MutationSecondChanceAddToOrionListArgs = {
  domainNames: Array<Scalars['String']>
  listType: Scalars['String']
}

export type MutationSecondChanceBulkDeleteDomainsArgs = {
  domainIds: Array<Scalars['Int']>
}

export type MutationSecondChanceImportDomainsArgs = {
  file?: Maybe<Scalars['File']>
  name?: Maybe<Scalars['String']>
  overwrite?: Maybe<Scalars['Boolean']>
  singleDomain: Scalars['Boolean']
}

export type MutationSecondChanceUpdateSettingsArgs = {
  attributes: SecondChanceSettingsAttributes
}

export type MutationSecurityCoachApproveUserMappingRecommendationArgs = {
  selectedTransits: Array<Scalars['Int']>
}

export type MutationSecurityCoachCampaignsCreateArgs = {
  attributes: RtcCampaignAttributes
}

export type MutationSecurityCoachCampaignsDeleteArgs = {
  id: Scalars['Int']
}

export type MutationSecurityCoachCampaignsEditArgs = {
  attributes: RtcCampaignAttributes
  id: Scalars['Int']
}

export type MutationSecurityCoachCampaignsSetStatusArgs = {
  id: Scalars['Int']
  status: CampaignStatuses
}

export type MutationSecurityCoachCloneSystemTmrArgs = {
  customTms?: Maybe<Array<Scalars['String']>>
  systemTms: Array<Scalars['String']>
}

export type MutationSecurityCoachCreateRemapJobForUnmappedEventsArgs = {
  timeframe: ReportsTimeframe
  vendor: Array<Scalars['String']>
}

export type MutationSecurityCoachCreateTeachableMomentRuleArgs = {
  aggregate: Scalars['Boolean']
  categories: Array<Scalars['String']>
  count?: Maybe<Scalars['Int']>
  criteria: TeachableMomentCriteriaPayload
  cronScheduleUtc?: Maybe<Scalars['String']>
  description: Scalars['String']
  lookbackDays?: Maybe<Scalars['Int']>
  name: Scalars['String']
  riskLevel: TmrRiskLevels
  runType: Scalars['String']
  status: Scalars['String']
  systemWorkflowId?: Maybe<Scalars['String']>
  vendorCodeName: Vendors
}

export type MutationSecurityCoachCustomContentArchiveArgs = {
  id: Scalars['Int']
}

export type MutationSecurityCoachCustomContentCreateArgs = {
  attributes: CustomContentFormAttributes
}

export type MutationSecurityCoachCustomContentDeleteArgs = {
  id: Scalars['Int']
}

export type MutationSecurityCoachCustomContentTranslationsCreateArgs = {
  attributes: CustomContentTranslationFormAttributes
  contentId: Scalars['Int']
}

export type MutationSecurityCoachCustomContentTranslationsDeleteArgs = {
  id: Scalars['Int']
}

export type MutationSecurityCoachCustomContentTranslationsPublishArgs = {
  id: Scalars['Int']
}

export type MutationSecurityCoachCustomContentTranslationsUpdateArgs = {
  attributes: CustomContentTranslationFormAttributes
  id: Scalars['Int']
}

export type MutationSecurityCoachCustomContentUpdateArgs = {
  attributes: CustomContentFormAttributes
  id: Scalars['Int']
}

export type MutationSecurityCoachCustomNotificationsCloneArgs = {
  id: Scalars['Int']
}

export type MutationSecurityCoachCustomNotificationsCreateArgs = {
  attributes: CustomNotificationFormAttributes
}

export type MutationSecurityCoachCustomNotificationsDeleteArgs = {
  id: Scalars['Int']
}

export type MutationSecurityCoachCustomNotificationsUpdateArgs = {
  attributes: CustomNotificationFormAttributes
  id: Scalars['Int']
}

export type MutationSecurityCoachCustomTranslationsCreateArgs = {
  attributes: CustomTranslationFormAttributes
  customNotificationId: Scalars['Int']
}

export type MutationSecurityCoachCustomTranslationsDeleteArgs = {
  id: Scalars['Int']
}

export type MutationSecurityCoachCustomTranslationsUpdateArgs = {
  attributes: CustomTranslationFormAttributes
  id: Scalars['Int']
}

export type MutationSecurityCoachDeleteTeachableMomentRuleArgs = {
  ruleId: Scalars['String']
}

export type MutationSecurityCoachDeliverGoogleChatMessageArgs = {
  email: Scalars['String']
}

export type MutationSecurityCoachDeliverSlackMessageArgs = {
  email: Scalars['String']
  workspace: Scalars['String']
}

export type MutationSecurityCoachDeliverTeamsMessageArgs = {
  email: Scalars['String']
}

export type MutationSecurityCoachDeliveryPreviewArgs = {
  contentId: Scalars['String']
  contentType: ContentTypes
  deliveryMethod?: Maybe<DeliveryMethods>
  email: Scalars['String']
  fallbackEmail: Scalars['Boolean']
  locale?: Maybe<Scalars['String']>
  notificationId: Scalars['String']
  notificationType: NotificationTypes
  useGif: Scalars['Boolean']
}

export type MutationSecurityCoachDeliverySlackWorkspacesDeleteArgs = {
  workspace: Scalars['String']
}

export type MutationSecurityCoachDeliveryTeamsDeleteIntegrationCallbackArgs = {
  accessToken: Scalars['String']
}

export type MutationSecurityCoachDeliveryTeamsUploadBotCallbackArgs = {
  accessToken: Scalars['String']
  botName: Scalars['String']
}

export type MutationSecurityCoachEditTeachableMomentRuleArgs = {
  aggregate: Scalars['Boolean']
  categories: Array<Scalars['String']>
  count?: Maybe<Scalars['Int']>
  criteria: TeachableMomentCriteriaPayload
  cronScheduleUtc?: Maybe<Scalars['String']>
  description: Scalars['String']
  lookbackDays?: Maybe<Scalars['Int']>
  name: Scalars['String']
  riskLevel: TmrRiskLevels
  ruleId: Scalars['String']
  runType: Scalars['String']
  status: Scalars['String']
  vendorCodeName: Vendors
}

export type MutationSecurityCoachEditUserMappingArgs = {
  aliases?: Maybe<Array<Scalars['JSON']>>
  hostnames: Array<Scalars['String']>
  userId: Scalars['Int']
  usernames: Array<Scalars['String']>
}

export type MutationSecurityCoachEnableTeachableMomentRuleArgs = {
  enabled?: Maybe<Scalars['Boolean']>
  workflowId: Scalars['String']
}

export type MutationSecurityCoachMappingRuleCreateArgs = {
  selectedDelimiters: Array<Delimiters>
  selectedVerbs: Array<Scalars['String']>
  vendorKey: Scalars['ID']
  verb: RuleVerb
}

export type MutationSecurityCoachMappingRuleDeleteArgs = {
  id: Scalars['ID']
}

export type MutationSecurityCoachMappingRuleToggleArgs = {
  id: Scalars['ID']
  ruleType: MappingRuleTypes
}

export type MutationSecurityCoachMarkFalsePositiveRuleArgs = {
  reason: Scalars['String']
  workflowId: Scalars['String']
}

export type MutationSecurityCoachRegisterTeamsBotArgs = {
  installTo: Scalars['String']
  teams?: Maybe<Array<Scalars['String']>>
}

export type MutationSecurityCoachRejectUserMappingRecommendationArgs = {
  selectedTransits: Array<Scalars['Int']>
}

export type MutationSecurityCoachRemoveBotFromTeamArgs = {
  teamId: Scalars['String']
}

export type MutationSecurityCoachResetPreviewArgs = {
  accountId: Scalars['Int']
}

export type MutationSecurityCoachSetupStatusUpdateArgs = {
  actionName: SetupStatusActions
  value: Scalars['Boolean']
}

export type MutationSecurityCoachToggleEngineStatusArgs = {
  ruleEngine: Engines
}

export type MutationSecurityCoachUserMappingCsvUploadArgs = {
  file: Scalars['File']
}

export type MutationSecurityCoachVendorsBitdefenderConnectArgs = {
  accessUrl: Scalars['String']
  apiKey: Scalars['String']
}

export type MutationSecurityCoachVendorsBitdefenderUpdateArgs = {
  accessUrl: Scalars['String']
  apiKey: Scalars['String']
  id: Scalars['Int']
}

export type MutationSecurityCoachVendorsCarbonBlackConnectArgs = {
  apiDomain: Scalars['String']
  apiId: Scalars['String']
  apiKey: Scalars['String']
  orgKey: Scalars['String']
}

export type MutationSecurityCoachVendorsCarbonBlackUpdateArgs = {
  apiDomain: Scalars['String']
  apiId: Scalars['String']
  apiKey: Scalars['String']
  id: Scalars['Int']
  orgKey: Scalars['String']
}

export type MutationSecurityCoachVendorsCiscoConnectArgs = {
  domainName: Scalars['String']
  password: Scalars['String']
  username: Scalars['String']
}

export type MutationSecurityCoachVendorsCiscoUmbrellaConnectArgs = {
  clientId: Scalars['String']
  clientSecret: Scalars['String']
}

export type MutationSecurityCoachVendorsCiscoUmbrellaUpdateArgs = {
  clientId: Scalars['String']
  clientSecret: Scalars['String']
  id: Scalars['Int']
}

export type MutationSecurityCoachVendorsCiscoUpdateArgs = {
  domainName: Scalars['String']
  id: Scalars['Int']
  password: Scalars['String']
  username: Scalars['String']
}

export type MutationSecurityCoachVendorsCloudflareZeroTrustConnectArgs = {
  apiToken: Scalars['String']
  clientId: Scalars['String']
}

export type MutationSecurityCoachVendorsCloudflareZeroTrustUpdateArgs = {
  apiToken: Scalars['String']
  clientId: Scalars['String']
  id: Scalars['Int']
}

export type MutationSecurityCoachVendorsCloudflarearea1ConnectArgs = {
  privKey?: Maybe<Scalars['String']>
  pubKey?: Maybe<Scalars['String']>
  streaming: Scalars['Boolean']
}

export type MutationSecurityCoachVendorsCloudflarearea1UpdateArgs = {
  id: Scalars['Int']
  privKey?: Maybe<Scalars['String']>
  pubKey?: Maybe<Scalars['String']>
  streaming: Scalars['Boolean']
}

export type MutationSecurityCoachVendorsCodeFourTwoConnectArgs = {
  apiDomain: Scalars['String']
  clientId: Scalars['String']
  clientSecret: Scalars['String']
}

export type MutationSecurityCoachVendorsCodeFourTwoUpdateArgs = {
  apiDomain: Scalars['String']
  clientId: Scalars['String']
  clientSecret: Scalars['String']
  id: Scalars['Int']
}

export type MutationSecurityCoachVendorsCrowdstrikeConnectArgs = {
  clientId: Scalars['String']
  clientSecret: Scalars['String']
  endpoint: Scalars['String']
}

export type MutationSecurityCoachVendorsCrowdstrikeUpdateArgs = {
  clientId: Scalars['String']
  clientSecret: Scalars['String']
  endpoint: Scalars['String']
  id: Scalars['Int']
}

export type MutationSecurityCoachVendorsDeleteArgs = {
  registrationId: Scalars['Int']
}

export type MutationSecurityCoachVendorsDismissAlertArgs = {
  id: Scalars['Int']
}

export type MutationSecurityCoachVendorsFortigateCloudConnectArgs = {
  apiId: Scalars['String']
  domain: Scalars['String']
  password: Scalars['String']
}

export type MutationSecurityCoachVendorsFortigateCloudUpdateArgs = {
  apiId: Scalars['String']
  domain: Scalars['String']
  id: Scalars['Int']
  password: Scalars['String']
}

export type MutationSecurityCoachVendorsGoogleConnectArgs = {
  adminEmail: Scalars['String']
  endpoint: Scalars['String']
  jsonFile: Scalars['String']
  vendor: Scalars['String']
}

export type MutationSecurityCoachVendorsGoogleUpdateArgs = {
  adminEmail: Scalars['String']
  endpoint: Scalars['String']
  id: Scalars['Int']
  jsonFile: Scalars['String']
  vendor: Scalars['String']
}

export type MutationSecurityCoachVendorsMalwarebytesConnectArgs = {
  cid: Scalars['String']
  clientId: Scalars['String']
  clientSecret: Scalars['String']
}

export type MutationSecurityCoachVendorsMalwarebytesUpdateArgs = {
  cid: Scalars['String']
  clientId: Scalars['String']
  clientSecret: Scalars['String']
  id: Scalars['Int']
}

export type MutationSecurityCoachVendorsMicrosoftConnectArgs = {
  clientId: Scalars['String']
  clientSecret: Scalars['String']
  tenant: Scalars['String']
  tokenExpiry: Scalars['String']
  vendor: Scalars['String']
}

export type MutationSecurityCoachVendorsMicrosoftUpdateArgs = {
  clientId: Scalars['String']
  clientSecret: Scalars['String']
  id: Scalars['Int']
  tenant: Scalars['String']
  tokenExpiry: Scalars['String']
  vendor: Scalars['String']
}

export type MutationSecurityCoachVendorsMimecastConnectArgs = {
  clientId: Scalars['String']
  clientSecret: Scalars['String']
}

export type MutationSecurityCoachVendorsMimecastUpdateArgs = {
  clientId: Scalars['String']
  clientSecret: Scalars['String']
  id: Scalars['Int']
}

export type MutationSecurityCoachVendorsNetskopeConnectArgs = {
  clientId: Scalars['String']
  clientSecret: Scalars['String']
}

export type MutationSecurityCoachVendorsNetskopeUpdateArgs = {
  clientId: Scalars['String']
  clientSecret: Scalars['String']
  id: Scalars['Int']
}

export type MutationSecurityCoachVendorsOktaConnectArgs = {
  apiDomain: Scalars['String']
  clientSecret: Scalars['String']
  endpoint: Scalars['String']
}

export type MutationSecurityCoachVendorsOktaUpdateArgs = {
  apiDomain: Scalars['String']
  clientSecret: Scalars['String']
  endpoint: Scalars['String']
  id: Scalars['Int']
}

export type MutationSecurityCoachVendorsPaloAltoNgfwConnectArgs = {
  apiDomain: Scalars['String']
  clientId: Scalars['String']
  clientSecret: Scalars['String']
}

export type MutationSecurityCoachVendorsPaloAltoNgfwUpdateArgs = {
  apiDomain: Scalars['String']
  clientId: Scalars['String']
  clientSecret: Scalars['String']
  id: Scalars['Int']
}

export type MutationSecurityCoachVendorsProofpointConnectArgs = {
  clientId: Scalars['String']
  clientSecret: Scalars['String']
}

export type MutationSecurityCoachVendorsProofpointUpdateArgs = {
  clientId: Scalars['String']
  clientSecret: Scalars['String']
  id: Scalars['Int']
}

export type MutationSecurityCoachVendorsSentineloneConnectArgs = {
  apiDomain: Scalars['String']
  clientSecret: Scalars['String']
}

export type MutationSecurityCoachVendorsSentineloneUpdateArgs = {
  apiDomain: Scalars['String']
  clientSecret: Scalars['String']
  id: Scalars['Int']
}

export type MutationSecurityCoachVendorsSonicwallConnectArgs = {
  apiKey: Scalars['String']
  password: Scalars['String']
  url: Scalars['String']
  username: Scalars['String']
}

export type MutationSecurityCoachVendorsSonicwallUpdateArgs = {
  apiKey: Scalars['String']
  id: Scalars['Int']
  password: Scalars['String']
  url: Scalars['String']
  username: Scalars['String']
}

export type MutationSecurityCoachVendorsSophosConnectArgs = {
  clientId: Scalars['String']
  clientSecret: Scalars['String']
}

export type MutationSecurityCoachVendorsSophosUpdateArgs = {
  clientId: Scalars['String']
  clientSecret: Scalars['String']
  id: Scalars['Int']
}

export type MutationSecurityCoachVendorsZscalerConnectArgs = {
  streamingType?: Maybe<Scalars['String']>
}

export type MutationSecurityRoleCreateArgs = {
  attributes: SecurityRoleAttributes
}

export type MutationSecurityRoleDeleteArgs = {
  id: Scalars['Int']
}

export type MutationSecurityRoleUpdateArgs = {
  attributes: SecurityRoleAttributes
  id: Scalars['Int']
}

export type MutationSetCampaignActiveStateArgs = {
  active: Scalars['Boolean']
  id: Scalars['Int']
}

export type MutationSetRunArchiveStateArgs = {
  archived: Scalars['Boolean']
  id: Scalars['Int']
}

export type MutationSignInAsAccountArgs = {
  id: Scalars['Int']
}

export type MutationSignInAsAdminArgs = {
  id: Scalars['Int']
}

export type MutationSignInAsChildPartnerArgs = {
  id?: Maybe<Scalars['Int']>
}

export type MutationSignInAsPartnerArgs = {
  id?: Maybe<Scalars['Int']>
  ols?: Maybe<Scalars['Boolean']>
}

export type MutationSignInAsUserArgs = {
  id?: Maybe<Scalars['Int']>
}

export type MutationSiteAdminCreateArgs = {
  attributes: SiteAdminAttributes
}

export type MutationSiteAdminDeactivateMfaArgs = {
  deactivate: Scalars['Boolean']
  id: Scalars['Int']
}

export type MutationSiteAdminDeleteArgs = {
  id: Scalars['Int']
}

export type MutationSiteAdminDisableArgs = {
  disable: Scalars['Boolean']
  id: Scalars['Int']
}

export type MutationSiteAdminDisableMfaArgs = {
  password?: Maybe<Scalars['String']>
  siteAdminId?: Maybe<Scalars['Int']>
}

export type MutationSiteAdminEnableMfaArgs = {
  otp: Scalars['String']
  siteAdminId?: Maybe<Scalars['Int']>
}

export type MutationSiteAdminToggleFullAdminArgs = {
  deactivate: Scalars['Boolean']
  id: Scalars['Int']
}

export type MutationSiteAdminUpdateArgs = {
  attributes: SiteAdminAttributes
  id: Scalars['Int']
}

export type MutationSiteAdminUpdateSelfArgs = {
  attributes: SiteAdminAttributes
}

export type MutationSiteSettingsUpdateArgs = {
  attributes: SiteSettingsAttributes
}

export type MutationSmartHostDomainDeleteArgs = {
  accountId?: Maybe<Scalars['Int']>
  domain: Scalars['String']
  numHosts: Scalars['Int']
}

export type MutationSmartHostDomainSendTestArgs = {
  accountId?: Maybe<Scalars['Int']>
  domain: Scalars['String']
  name: Scalars['String']
}

export type MutationSmartHostDomainUpdateArgs = {
  accountId?: Maybe<Scalars['Int']>
  attributes: SmartHostDomainAttributes
}

export type MutationStartGoogleSyncArgs = {
  id?: Maybe<Scalars['Int']>
}

export type MutationStorePurchaseCreateArgs = {
  accountId?: Maybe<Scalars['Int']>
  uuid: Scalars['String']
}

export type MutationStorePurchaseDownloadArgs = {
  accountId?: Maybe<Scalars['Int']>
  locale: Scalars['String']
  uuid: Scalars['String']
}

export type MutationStorePurchaseRemoveArgs = {
  id: Scalars['Int']
}

export type MutationStorePurchaseUpdateArgs = {
  attributes?: Maybe<StorePurchaseAttributes>
  id: Scalars['Int']
}

export type MutationSubscriptionCreateArgs = {
  attributes: SubscriptionAttributes
}

export type MutationSubscriptionDeleteArgs = {
  id: Scalars['Int']
}

export type MutationSubscriptionUpdateArgs = {
  attributes: SubscriptionAttributes
  id: Scalars['Int']
}

export type MutationSurveyCreateArgs = {
  attributes: SurveyAttributes
  enrollmentId: Scalars['Int']
}

export type MutationTemplateTopicCreateArgs = {
  attributes: TemplateTopicAttributes
  type?: Maybe<TemplateTopicTypes>
}

export type MutationTemplateTopicDeleteArgs = {
  id: Scalars['Int']
}

export type MutationTemplateTopicUpdateArgs = {
  attributes: TemplateTopicAttributes
  id: Scalars['Int']
}

export type MutationTestEmailCreateArgs = {
  freeToolType?: Maybe<FreeToolTypes>
  from?: Maybe<Scalars['String']>
  fromDisplayName?: Maybe<Scalars['String']>
  templateId?: Maybe<Scalars['Int']>
  templateUuid?: Maybe<Scalars['String']>
  to?: Maybe<Scalars['String']>
}

export type MutationTrademarkCreateArgs = {
  attributes: TrademarkAttributes
}

export type MutationTrademarkDeleteArgs = {
  id: Scalars['Int']
}

export type MutationTrademarkMatchesResolveArgs = {
  status: TrademarkMatchStatuses
  trademarkMatchIds: Array<Scalars['Int']>
}

export type MutationTrademarkUpdateArgs = {
  attributes: TrademarkAttributes
  id: Scalars['Int']
}

export type MutationTrainingCampaignAddUserArgs = {
  trainingCampaignId: Scalars['Int']
  userId: Scalars['Int']
}

export type MutationTrainingCampaignBulkChangeArgs = {
  adminsToNotify?: Maybe<Array<Scalars['Int']>>
  assetId?: Maybe<Scalars['Int']>
  file: Scalars['File']
  notifyRequester?: Maybe<Scalars['Boolean']>
  trainingCampaignId: Scalars['Int']
}

export type MutationTrainingCampaignCloseArgs = {
  id: Scalars['Int']
}

export type MutationTrainingCampaignCreateArgs = {
  attributes: TrainingCampaignAttributes
  endCampaignOption: TrainingCampaignEnd
}

export type MutationTrainingCampaignCsvEnrollArgs = {
  payload?: Scalars['File']
  trainingCampaignId: Scalars['Int']
}

export type MutationTrainingCampaignDeleteArgs = {
  ids: Array<Scalars['Int']>
}

export type MutationTrainingCampaignEditArgs = {
  attributes: TrainingCampaignAttributes
  endCampaignOption: TrainingCampaignEnd
  id: Scalars['Int']
}

export type MutationTrainingCampaignExtendDueDateArgs = {
  dueDate: Scalars['ISO8601Date']
  enrollmentIds: Array<Scalars['Int']>
  trainingCampaignId: Scalars['Int']
}

export type MutationTrainingCampaignRemoveUserArgs = {
  trainingCampaignId: Scalars['Int']
  userId: Scalars['Int']
}

export type MutationTrainingCampaignReopenArgs = {
  ids: Array<Scalars['Int']>
}

export type MutationUpdateMessageRecipientArgs = {
  attributes: LearnerMessageAttributes
  id: Scalars['Int']
}

export type MutationUsbCampaignCreateArgs = {
  attributes: UsbCampaignAttributes
}

export type MutationUsbCampaignDestroyArgs = {
  id: Scalars['Int']
}

export type MutationUsbCampaignEndArgs = {
  id: Scalars['Int']
}

export type MutationUsbCampaignRestartArgs = {
  id: Scalars['Int']
}

export type MutationUsbCampaignStartArgs = {
  id: Scalars['Int']
}

export type MutationUsbCampaignUpdateArgs = {
  attributes: UsbCampaignAttributes
  id: Scalars['Int']
}

export type MutationUseSamlLatestCertificateArgs = {
  id?: Maybe<Scalars['Int']>
}

export type MutationUserAddToGroupsArgs = {
  groupIds: Array<Scalars['Int']>
  userIds: Array<Scalars['Int']>
}

export type MutationUserArchiveArgs = {
  userIds: Array<Scalars['Int']>
}

export type MutationUserBulkArchiveArgs = {
  adminsToNotify?: Maybe<Array<Scalars['Int']>>
  file: Scalars['File']
  notifyRequester?: Maybe<Scalars['Boolean']>
}

export type MutationUserBulkChangeDomainArgs = {
  accountId: Scalars['Int']
  domainId: Scalars['Int']
  userIds: Array<Scalars['Int']>
}

export type MutationUserBulkChangeDomainEmailsArgs = {
  accountId: Scalars['Int']
  domainId: Scalars['Int']
  userEmails: Array<Scalars['String']>
}

export type MutationUserBulkDeleteArgs = {
  adminsToNotify?: Maybe<Array<Scalars['Int']>>
  file: Scalars['File']
  notifyRequester?: Maybe<Scalars['Boolean']>
}

export type MutationUserCreateArgs = {
  email: Scalars['String']
  firstName?: Maybe<Scalars['String']>
  groupIds?: Maybe<Array<Scalars['Int']>>
  jobTitle?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
}

export type MutationUserCreateUpsideDownArgs = {
  accountId: Scalars['Int']
  attributes: UserAttributes
}

export type MutationUserCsvImportArgs = {
  adminsToNotify?: Maybe<Array<Scalars['Int']>>
  notifyAllAdmins?: Maybe<Scalars['Boolean']>
  notifyRequester?: Maybe<Scalars['Boolean']>
  payload?: Scalars['File']
}

export type MutationUserDeleteArgs = {
  accountIds?: Maybe<Array<Scalars['Int']>>
  userIds: Array<Scalars['Int']>
}

export type MutationUserDeleteByEmailArgs = {
  emails: Array<Scalars['String']>
}

export type MutationUserDeleteMembershipsArgs = {
  groupId: Scalars['Int']
  userIds: Array<Scalars['Int']>
}

export type MutationUserEditArgs = {
  attributes: UserAttributes
  userId: Scalars['Int']
}

export type MutationUserEditUpsideDownArgs = {
  attributes: UserAttributes
  userId: Scalars['Int']
}

export type MutationUserEventApiCreateKeyArgs = {
  name: Scalars['String']
}

export type MutationUserEventApiDeleteKeyArgs = {
  id: Scalars['Int']
}

export type MutationUserEventResyncArgs = {
  cutoffTime?: Maybe<Scalars['ISO8601DateTime']>
  startTime?: Maybe<Scalars['ISO8601DateTime']>
  userId: Scalars['Int']
}

export type MutationUserGrantAdminArgs = {
  accountIds?: Maybe<Array<Scalars['Int']>>
  userIds: Array<Scalars['Int']>
}

export type MutationUserProfileEditArgs = {
  attributes: UserAttributes
  userId?: Maybe<Scalars['Int']>
}

export type MutationUserQuickImportArgs = {
  emails: Array<Scalars['String']>
  groupIds?: Maybe<Array<Scalars['Int']>>
  password?: Maybe<Scalars['String']>
}

export type MutationUserResendConfirmationEmailArgs = {
  userId: Scalars['Int']
}

export type MutationUserRevokeAdminArgs = {
  accountIds?: Maybe<Array<Scalars['Int']>>
  userIds: Array<Scalars['Int']>
}

export type MutationUserSendMobileSignInLinkEmailArgs = {
  allUsers: Scalars['Boolean']
  groupIds: Array<Scalars['Int']>
}

export type MutationUserSetDefaultExecReportsDashboardArgs = {
  dashboardId?: Maybe<Scalars['Int']>
}

export type MutationUserSetDefaultPiqDashboardArgs = {
  dashboardId?: Maybe<Scalars['Int']>
}

export type MutationUserTimelineExternalEventArgs = {
  id: Scalars['String']
}

export type MutationUserUnarchiveArgs = {
  userIds: Array<Scalars['Int']>
}

export type MutationUsersMergeProcessArgs = {
  mergeUserId: Scalars['Int']
  targetUserId: Scalars['Int']
}

export type MutationUstCreateArgs = {
  name: Scalars['String']
}

export type MutationWebhookCreateArgs = {
  attributes: WebhooksCreateAttributes
}

export type MutationWebhookDeleteArgs = {
  id: Scalars['Int']
}

export type MutationWebhookUpdateArgs = {
  attributes: WebhooksCreateAttributes
  id: Scalars['Int']
}

export enum NameFormatTypes {
  First = 'FIRST',
  FirstLast = 'FIRST_LAST',
  Last = 'LAST',
  LastFirst = 'LAST_FIRST',
}

/** New API token */
export type NewApiToken = Timestamps & {
  __typename?: 'NewApiToken'
  /** Indicates whether the API token is active */
  active: Scalars['Boolean']
  /** The display information for audit logs */
  auditLogDisplay?: Maybe<Scalars['String']>
  /** The timestamp when the record was created */
  createdAt: Scalars['ISO8601DateTime']
  /** The user who created the API token */
  createdBy?: Maybe<User>
  /** The expiration date of the API token */
  expiresOn?: Maybe<Scalars['ISO8601DateTime']>
  /** The unique identifier of the API token */
  id: Scalars['Int']
  /** The JWT associated with the API token */
  jwt: Scalars['String']
  /** The name of the API token */
  name: Scalars['String']
  /** The scopes associated with the API token */
  scopes: Array<ApiScopes>
  /** The share profile information of the API token */
  shareProfile?: Maybe<Scalars['JSON']>
  /** The shared secret of the API token */
  sharedSecret?: Maybe<Scalars['String']>
  /** The timestamp when the record was last updated */
  updatedAt: Scalars['ISO8601DateTime']
  /** The user associated with this API token */
  user?: Maybe<User>
  /** The UUID of the API token */
  uuid: Scalars['String']
}

/** New User Event API key */
export type NewKey = {
  __typename?: 'NewKey'
  /** The unique identifier of the API key */
  id: Scalars['Int']
  /** The JWT token associated with the API key */
  jwt: Scalars['String']
  /** The name of the API key */
  name: Scalars['String']
}

/** New API token */
export type NewPartnerApiToken = Timestamps & {
  __typename?: 'NewPartnerApiToken'
  active: Scalars['Boolean']
  auditLogDisplay?: Maybe<Scalars['String']>
  /** The timestamp when the record was created */
  createdAt: Scalars['ISO8601DateTime']
  expiresOn?: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['Int']
  jwt: Scalars['String']
  name: Scalars['String']
  partnerAdmin?: Maybe<PartnerAdmin>
  scopes: Array<PartnerApiScopes>
  sharedSecret?: Maybe<Scalars['String']>
  /** The timestamp when the record was last updated */
  updatedAt: Scalars['ISO8601DateTime']
  uuid: Scalars['String']
}

/** Notes about the account settings */
export type Notes = Identifier &
  Timestamps & {
    __typename?: 'Notes'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** General notes or comments related to the account settings */
    general?: Maybe<Scalars['String']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type NotesAttributes = {
  general?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
}

/** Training notification */
export type Notification = Identifier &
  NotificationInterface &
  Timestamps & {
    __typename?: 'Notification'
    /** The admin email template for the notification */
    adminEmailTemplate?: Maybe<NotificationTemplate>
    /** The unique identifier of the admin email template */
    adminEmailTemplateId?: Maybe<Scalars['Int']>
    /** Display information for audit logs */
    auditLogDisplay?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The delivery method for the notification */
    deliveryMethod?: Maybe<TrainingNotificationDeliveryMethods>
    /** The unique identifier of the user email template */
    emailTemplateId?: Maybe<Scalars['Int']>
    /** Indicates if the notification is enabled */
    enabled?: Maybe<Scalars['Boolean']>
    /** The enrollment filter for the notification */
    enrollmentFilter?: Maybe<Array<NotificationEnrollmentFilter>>
    /** Indicates if fallback to email is enabled for notifications */
    fallbackEmail: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    lastEnrollmentId?: Maybe<Scalars['Int']>
    /** The manager email template for the notification */
    managerEmailTemplate?: Maybe<NotificationTemplate>
    /** The unique identifier of the manager email template */
    managerEmailTemplateId?: Maybe<Scalars['Int']>
    /** The message type for the notification */
    messageType?: Maybe<TrainingCampaignNotification>
    /** The offset from type for the notification */
    offsetFrom: Scalars['String']
    /** The number of days for the offset */
    offsetNumDays?: Maybe<Scalars['Int']>
    /** The date and time the notification was processed */
    processedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The user IDs for the recipients of the notification */
    recipientUserIds?: Maybe<Array<Scalars['Int']>>
    /** The reschedule offset for the notification */
    rescheduleOffset?: Maybe<Scalars['Int']>
    /** The ID of the training campaign */
    trainingCampaignId?: Maybe<Scalars['Int']>
    /** The name of the training campaign */
    trainingCampaignName?: Maybe<Scalars['String']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The user email template for the notification */
    userEmailTemplate?: Maybe<NotificationTemplate>
  }

export type NotificationAttributes = {
  /** The ID of the admin email template */
  adminEmailTemplateId?: Maybe<Scalars['Int']>
  /** The delivery method */
  deliveryMethod?: Maybe<TrainingNotificationDeliveryMethods>
  /** Whether to destroy the notification */
  destroy?: Maybe<Scalars['Boolean']>
  /** The ID of the email template */
  emailTemplateId?: Maybe<Scalars['Int']>
  /** Whether the notification is enabled */
  enabled: Scalars['Boolean']
  /** The enrollment filter */
  enrollmentFilter?: Maybe<NotificationEnrollmentFilter>
  /** Whether to use the fallback email */
  fallbackEmail?: Maybe<Scalars['Boolean']>
  /** The ID of the notification */
  id?: Maybe<Scalars['Int']>
  /** The ID of the manager email template */
  managerEmailTemplateId?: Maybe<Scalars['Int']>
  /** The type of message */
  messageType: TrainingCampaignNotification
  /** The number of days to offset the notification */
  offsetNumDays?: Maybe<Scalars['Int']>
  /** The IDs of the recipient users */
  recipientUserIds?: Maybe<Array<Scalars['Int']>>
  /** The reschedule offset */
  rescheduleOffset?: Maybe<Scalars['Int']>
  /** The timestamp of the notification */
  timestamp?: Maybe<Scalars['BigInt']>
}

/** Autogenerated return type of NotificationCreate. */
export type NotificationCreatePayload = {
  __typename?: 'NotificationCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Notification>
}

export enum NotificationEnrollmentFilter {
  Completed = 'completed',
  CustomUsers = 'custom_users',
  EnrolledUsers = 'enrolled_users',
  Expired = 'expired',
  NotCompleted = 'not_completed',
  NotLoggedIn = 'not_logged_in',
  NotStarted = 'not_started',
  PolicyNotAck = 'policy_not_ack',
}

/** Common fields between notifications and managed_notifications */
export type NotificationInterface = {
  /** The admin email template for the notification */
  adminEmailTemplate?: Maybe<NotificationTemplate>
  /** The unique identifier of the admin email template */
  adminEmailTemplateId?: Maybe<Scalars['Int']>
  /** The delivery method for the notification */
  deliveryMethod?: Maybe<TrainingNotificationDeliveryMethods>
  /** The unique identifier of the user email template */
  emailTemplateId?: Maybe<Scalars['Int']>
  /** Indicates if the notification is enabled */
  enabled?: Maybe<Scalars['Boolean']>
  /** The enrollment filter for the notification */
  enrollmentFilter?: Maybe<Array<NotificationEnrollmentFilter>>
  lastEnrollmentId?: Maybe<Scalars['Int']>
  /** The manager email template for the notification */
  managerEmailTemplate?: Maybe<NotificationTemplate>
  /** The unique identifier of the manager email template */
  managerEmailTemplateId?: Maybe<Scalars['Int']>
  /** The message type for the notification */
  messageType?: Maybe<TrainingCampaignNotification>
  /** The offset from type for the notification */
  offsetFrom: Scalars['String']
  /** The number of days for the offset */
  offsetNumDays?: Maybe<Scalars['Int']>
  /** The date and time the notification was processed */
  processedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The user IDs for the recipients of the notification */
  recipientUserIds?: Maybe<Array<Scalars['Int']>>
  /** The reschedule offset for the notification */
  rescheduleOffset?: Maybe<Scalars['Int']>
  /** The user email template for the notification */
  userEmailTemplate?: Maybe<NotificationTemplate>
}

/** Status of training notification */
export type NotificationReceipt = Timestamps & {
  __typename?: 'NotificationReceipt'
  /** The timestamp when the record was created */
  createdAt: Scalars['ISO8601DateTime']
  /** The delivery method of the notification */
  deliveryMethod?: Maybe<Scalars['String']>
  /** The ID of the email template associated with the notification */
  emailTemplateId: Scalars['Int']
  /** The error code associated with the notification */
  errorCode?: Maybe<Scalars['String']>
  /** The type of notification */
  notificationType: Scalars['String']
  /** The type of recipient for the notification */
  recipientType?: Maybe<Scalars['String']>
  /** The status of the notification receipt */
  status: Scalars['String']
  /** The template of the notification */
  template?: Maybe<NotificationTemplate>
  /** The timestamp when the record was last updated */
  updatedAt: Scalars['ISO8601DateTime']
}

/** Training notification templates */
export type NotificationTemplate = EmailTemplateInterface &
  Identifier &
  Timestamps & {
    __typename?: 'NotificationTemplate'
    /** Indicates if the email template is archived */
    archived: Scalars['Boolean']
    auditLogDisplay?: Maybe<Scalars['String']>
    auditLogName?: Maybe<Scalars['String']>
    /** The category of the phishing template */
    category?: Maybe<PhishingTemplateCategory>
    /** The HTML content of the email template */
    contentHtml?: Maybe<Scalars['String']>
    contentHtmlSanitized?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The from address of the email template */
    from: Scalars['String']
    /** The display name of the from address of the email template */
    fromDisplayName?: Maybe<Scalars['String']>
    fromDisplayNameSanitized?: Maybe<Scalars['String']>
    /** The from address of the email template with the domain */
    fromWithDomain: Scalars['String']
    /** Indicates if the email template is hidden */
    hidden: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The language code of the email template */
    languageCode?: Maybe<Scalars['String']>
    markedAsUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The name of the email template */
    name?: Maybe<Scalars['String']>
    /** The reply to address of the email template */
    replyTo?: Maybe<Scalars['String']>
    /** The display name of the reply to address of the email template */
    replyToDisplayName?: Maybe<Scalars['String']>
    /** The subject of the email template */
    subject: Scalars['String']
    subjectSanitized: Scalars['String']
    /** The UUID for the email template translation */
    templateTranslationUuid: Scalars['String']
    /** The type of notification template */
    type: NotificationTemplateTypes
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type NotificationTemplateAttributes = {
  /** The HTML content of the notification */
  contentHtml: Scalars['String']
  /** The email address from which the notification will be sent */
  from: Scalars['String']
  /** The display name of the sender */
  fromDisplayName?: Maybe<Scalars['String']>
  /** The name of the notification template */
  name?: Maybe<Scalars['String']>
  /** The subject of the notification */
  subject: Scalars['String']
  /** The sanitized subject of the notification */
  subjectSanitized?: Maybe<Scalars['String']>
}

/** An unpaginated list of phishing template categories */
export type NotificationTemplateCategories = {
  __typename?: 'NotificationTemplateCategories'
  /** A list of notification template categories */
  nodes: Array<NotificationTemplateCategory>
}

/** Category object to filter email template nodes */
export type NotificationTemplateCategory = Identifier &
  Timestamps & {
    __typename?: 'NotificationTemplateCategory'
    auditLogDisplay?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Indicates if the notification template category is hidden */
    hidden?: Maybe<Scalars['Boolean']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The name of the notification template category */
    name: Scalars['String']
    /** The ordering of the notification template category */
    ordering?: Maybe<Scalars['Int']>
    /** The count of templates in the notification template category */
    templateCount?: Maybe<Scalars['Int']>
    /** The translation key for the notification template category */
    translationKey?: Maybe<Scalars['String']>
    /** The type of the notification template category */
    type: NotificationTemplateCategoryTypes
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

/** Category object to filter email template nodes */
export type NotificationTemplateCategoryTemplateCountArgs = {
  showHidden?: Maybe<Scalars['Boolean']>
}

export type NotificationTemplateCategoryAttributes = {
  /** The name of the category */
  name: Scalars['String']
}

export enum NotificationTemplateCategoryTypes {
  Managed = 'MANAGED',
  System = 'SYSTEM',
  User = 'USER',
}

/** Notification template defaults for user roles */
export type NotificationTemplateDefaults = {
  __typename?: 'NotificationTemplateDefaults'
  /** The admin level default value */
  admin?: Maybe<Scalars['Int']>
  /** The manager level default value */
  manager?: Maybe<Scalars['Int']>
  /** The name of the notification template */
  name: Scalars['String']
  /** The user level default value */
  user?: Maybe<Scalars['Int']>
}

/** Count of notification templates in draft */
export type NotificationTemplateDrafts = {
  __typename?: 'NotificationTemplateDrafts'
  /** The total number of notification template drafts */
  count: Scalars['Int']
}

export enum NotificationTemplateTypes {
  Managed = 'MANAGED',
  System = 'SYSTEM',
  User = 'USER',
}

/** Autogenerated return type of NotificationTemplatesCategoryCreate. */
export type NotificationTemplatesCategoryCreatePayload = {
  __typename?: 'NotificationTemplatesCategoryCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created notification template category */
  node?: Maybe<NotificationTemplateCategory>
}

/** Autogenerated return type of NotificationTemplatesCategoryDelete. */
export type NotificationTemplatesCategoryDeletePayload = {
  __typename?: 'NotificationTemplatesCategoryDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The deleted notification template category */
  node?: Maybe<NotificationTemplateCategory>
}

/** Autogenerated return type of NotificationTemplatesCategoryHide. */
export type NotificationTemplatesCategoryHidePayload = {
  __typename?: 'NotificationTemplatesCategoryHidePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated notification template category */
  node?: Maybe<NotificationTemplateCategory>
}

/** Autogenerated return type of NotificationTemplatesCategoryUpdate. */
export type NotificationTemplatesCategoryUpdatePayload = {
  __typename?: 'NotificationTemplatesCategoryUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated notification template category */
  node?: Maybe<NotificationTemplateCategory>
}

/** Autogenerated return type of NotificationTemplatesCreate. */
export type NotificationTemplatesCreatePayload = {
  __typename?: 'NotificationTemplatesCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created notification template */
  node?: Maybe<NotificationTemplate>
}

export type NotificationTemplatesCursor = {
  __typename?: 'NotificationTemplatesCursor'
  /** The paginated nodes */
  nodes: Array<NotificationTemplate>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of NotificationTemplatesHide. */
export type NotificationTemplatesHidePayload = {
  __typename?: 'NotificationTemplatesHidePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated notification template */
  node?: Maybe<NotificationTemplate>
}

/** Autogenerated return type of NotificationTemplatesImport. */
export type NotificationTemplatesImportPayload = {
  __typename?: 'NotificationTemplatesImportPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The imported notification templates */
  nodes?: Maybe<Array<NotificationTemplate>>
}

/** Autogenerated return type of NotificationTemplatesUpdate. */
export type NotificationTemplatesUpdatePayload = {
  __typename?: 'NotificationTemplatesUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated notification template */
  node?: Maybe<NotificationTemplate>
}

export enum NotificationTranslationTypes {
  Custom = 'custom',
  System = 'system',
}

export enum NotificationTypes {
  Custom = 'custom',
  System = 'system',
}

export type Ordering = {
  field: Scalars['String']
  format: Scalars['String']
}

export type Organization = {
  __typename?: 'Organization'
  /** The unique identifier of the organization */
  id?: Maybe<Scalars['String']>
  /** The name of the organization */
  name?: Maybe<Scalars['String']>
}

export type OrganizationKey = {
  __typename?: 'OrganizationKey'
  /** The unique key identifier for an organization */
  key?: Maybe<Scalars['String']>
}

export type OsDistribution = {
  __typename?: 'OsDistribution'
  /** The number of occurrences of this OS version */
  count?: Maybe<Scalars['Int']>
  /** The name of the operating system */
  os?: Maybe<Scalars['String']>
  /** The version of the operating system */
  version?: Maybe<Scalars['String']>
}

/** An OutlookAddinActivity */
export type OutlookAddinActivity = Identifier &
  Timestamps & {
    __typename?: 'OutlookAddinActivity'
    /** The version of the Outlook add-in */
    addinVersion?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The unique identifier for the machine where the add-in is installed */
    machineGuid?: Maybe<Scalars['String']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export enum OutlookAddinActivityActivityTypes {
  All = 'ALL',
  AllReported = 'ALL_REPORTED',
  NonReports = 'NON_REPORTS',
}

export type OutlookAddinActivityUsersCursor = {
  __typename?: 'OutlookAddinActivityUsersCursor'
  /** The paginated nodes */
  nodes: Array<User>
  /** Information about the current paginated set */
  pagination: Pagination
}

export enum OutlookAddinActivityUsersSortFields {
  Email = 'EMAIL',
}

export type OverallPppByTrainingTimeSeries = {
  __typename?: 'OverallPppByTrainingTimeSeries'
  /** A CSV representation of the data for download */
  csv: Scalars['String']
  /** A series of data related to overall PPP by training */
  series: Array<OverallPppByTrainingTimeSeriesData>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type OverallPppByTrainingTimeSeriesData = {
  __typename?: 'OverallPppByTrainingTimeSeriesData'
  /** The count of members associated with the report */
  memberCount: Scalars['Int']
  /** The name of the entity related to the report */
  name: Scalars['String']
  /** Phish-prone Percentage */
  ppp: Scalars['Float']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** The total time spent in training */
  trainingTime: Scalars['Float']
}

export type PabComparison = {
  __typename?: 'PabComparison'
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type PabUserActivity = {
  __typename?: 'PabUserActivity'
  /** A CSV representation of the user activity report */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

/** ModStore package */
export type Package = {
  __typename?: 'Package'
  /** The ID of the package */
  id: Scalars['Int']
  /** The path to launch the package */
  launchPath?: Maybe<Scalars['String']>
}

/** Package file */
export type PackageFile = {
  __typename?: 'PackageFile'
  /** The name of the package file */
  name: Scalars['String']
  /** The file size in bytes */
  size: Scalars['BigInt']
}

/** ModStore package file list */
export type PackageFileList = {
  __typename?: 'PackageFileList'
  /** The base path where the package files are located */
  basePath?: Maybe<Scalars['String']>
  /** Cookies used for CloudFront distribution authentication */
  cloudfrontCookies: Scalars['JSON']
  /** A list of package files */
  files?: Maybe<Array<PackageFile>>
  /** The initial path to access the package content */
  launchPath?: Maybe<Scalars['String']>
  /** Indicates whether the package is suitable for downloading on mobile devices */
  suitableForMobileDownload: Scalars['Boolean']
}

/** Information about the current paginated set */
export type Pagination = {
  __typename?: 'Pagination'
  /** Current page number */
  page: Scalars['Int']
  /** Total number of pages */
  pages: Scalars['Int']
  /** Number of items in the page */
  per: Scalars['Int']
  /** Total number of items */
  totalCount: Scalars['Int']
}

/** A partner */
export type Partner = Identifier &
  Timestamps & {
    __typename?: 'Partner'
    /** Indicates whether administrators with MFA can bypass login */
    allowAdminWithMfaLoginBypass?: Maybe<Scalars['Boolean']>
    /** Indicates whether the partner can edit account information */
    canEditAccountInfo?: Maybe<Scalars['Boolean']>
    /** Indicates whether the partner can enable user moves between accounts */
    canEnableUserMoves?: Maybe<Scalars['Boolean']>
    /** Indicates whether the partner can manage phishing campaigns */
    canManagePhishingCampaigns: Scalars['Boolean']
    /** Indicates whether the partner can manage subaccounts */
    canManageSubaccounts: Scalars['Boolean']
    /** Indicates whether the partner can manage training campaigns */
    canManageTrainingCampaigns: Scalars['Boolean']
    /** The city where the partner company is located */
    city?: Maybe<Scalars['String']>
    /** The name of the partner company */
    companyName?: Maybe<Scalars['String']>
    /** The end date of the partner's Compliance Plus subscription */
    compliancePlusSubscriptionEndDate?: Maybe<Scalars['ISO8601Date']>
    /** The country where the partner company is located */
    country?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The default locale for the partner's adminis */
    defaultAdminLocale?: Maybe<Scalars['String']>
    /** The default landing page for the partner */
    defaultLandingPage: LandingPage
    /** Indicates whether non-SAML logins are disabled */
    disableNonSamlLogins?: Maybe<Scalars['Boolean']>
    /** The domain associated with the partner */
    domain?: Maybe<Scalars['String']>
    /** Ensures a session originates from the same IP address */
    ensureSameSessionIp?: Maybe<Scalars['Boolean']>
    /** The Entity ID for SAML authentication */
    entityId?: Maybe<Scalars['String']>
    /** The phone number extension for the partner */
    extension?: Maybe<Scalars['String']>
    /** Indicates whether the partner has executive reports */
    hasExecReports: Scalars['Boolean']
    /** Indicates whether the partner has the managed ModStore */
    hasManagedModstore: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The fingerprint of the identity provider certificate for SAML */
    idpCertFingerprint?: Maybe<Scalars['String']>
    /** The format of the identity provider certificate for SAML */
    idpCertFormat?: Maybe<IdpCertificationFormats>
    /** The identity provider SSO target URL for SAML */
    idpSsoTargetUrl?: Maybe<Scalars['String']>
    /** A list of IP addresses allowed to access the partner's resources */
    ipAllowList?: Maybe<Scalars['String']>
    /** The locale of the partner */
    locale?: Maybe<Scalars['String']>
    /** The logo of the partner */
    logo?: Maybe<Scalars['String']>
    /** The download URL for the partner's logo */
    logoDownloadUrl?: Maybe<Scalars['String']>
    /** The file name of the partner's logo */
    logoFileName?: Maybe<Scalars['String']>
    /** The number of accounts associated with the partner */
    numAccounts: Scalars['Int']
    /** The number of bulk accounts associated with the partner */
    numBulkAccounts: Scalars['Int']
    /** The total number of bulk users associated with the partner */
    numBulkUsers?: Maybe<Scalars['Int']>
    /** The count of bulk users associated with the partner */
    numBulkUsersCount: Scalars['Int']
    /** The number of free accounts associated with the partner */
    numFreeAccounts: Scalars['Int']
    /** The number of free users associated with the partner */
    numFreeUsers: Scalars['Int']
    /** The number of paid accounts associated with the partner */
    numPaidAccounts: Scalars['Int']
    /** The number of paid users associated with the partner */
    numPaidUsers: Scalars['Int']
    /** The number of trial accounts associated with the partner */
    numTrialAccounts: Scalars['Int']
    /** The number of trial users associated with the partner */
    numTrialUsers: Scalars['Int']
    /** The total number of users associated with the partner */
    numUsers: Scalars['Int']
    /** Indicates whether the partner has an online shop */
    onlineShop: Scalars['Boolean']
    /** Indicates whether the partner's parent has a Salesforce ID */
    parentHasSfid: Scalars['Boolean']
    /** Detailed information about the partner */
    partner?: Maybe<Partner>
    /** The owner administrator of the partner */
    partnerAdminOwner?: Maybe<PartnerAdmin>
    /** The administrative permissions of the partner */
    partnerAdminPermission?: Maybe<PartnerAdminPermission>
    /** A list of partner administrators */
    partnerAdmins: Array<PartnerAdmin>
    /** The unique identifier of the partner */
    partnerId?: Maybe<Scalars['Int']>
    /** The type of partner */
    partnerType: PartnerTypes
    /** The end date of the partner's PhishER prevention subscription */
    phisherSubscriptionEndDate?: Maybe<Scalars['ISO8601Date']>
    /** The contact phone number for the partner */
    phoneNumber?: Maybe<Scalars['String']>
    /** The list of purchased SKUs by the partner */
    purchasedSkus: Array<PartnerPurchasedSku>
    /** Indicates whether console sessions are restricted */
    restrictConsoleSession?: Maybe<Scalars['Boolean']>
    /** Indicates whether SAML authentication is enabled for the partner */
    samlEnabled: Scalars['Boolean']
    /** Indicates whether SAML user provisioning is enabled */
    samlUserProvisioningEnabled?: Maybe<Scalars['Boolean']>
    /** The session timeout in minutes for the partner */
    sessionTimeout: Scalars['Int']
    /** The Salesforce ID of the partner */
    sfid?: Maybe<Scalars['String']>
    /** Indicates whether SAML requests should be signed */
    signSamlRequests?: Maybe<Scalars['Boolean']>
    /** The SSO bypass URL for the partner */
    ssoBypass: Scalars['String']
    /** The SSO callback URL for the partner */
    ssoCallback?: Maybe<Scalars['String']>
    /** The SSO inbound URL for the partner */
    ssoIn?: Maybe<Scalars['String']>
    /** The SSO metadata for the partner */
    ssoMetadata?: Maybe<Scalars['String']>
    /** The original status of SSO for the partner */
    ssoOriginalStatus: Scalars['Boolean']
    /** The SSO outbound URL for the partner */
    ssoOut?: Maybe<Scalars['String']>
    /** The SSO slug for the partner */
    ssoSlug?: Maybe<Scalars['String']>
    /** The state where the partner company is located */
    state?: Maybe<Scalars['String']>
    /** The first street address of the partner company */
    streetAddress1?: Maybe<Scalars['String']>
    /** The second street address of the partner company */
    streetAddress2?: Maybe<Scalars['String']>
    /** The end date of the partner's Student Edition subscription */
    studentEditionSubscriptionEndDate?: Maybe<Scalars['ISO8601Date']>
    /** The subscription details of the partner */
    subscription?: Maybe<Subscription>
    /** The end date of the partner's subscription */
    subscriptionEndDate?: Maybe<Scalars['ISO8601Date']>
    /** The subscription ID associated with the partner */
    subscriptionId?: Maybe<Scalars['Int']>
    /** The suite number of the partner company */
    suiteNumber?: Maybe<Scalars['String']>
    /** The timezone of the partner */
    timeZone?: Maybe<TimeZones>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The usable Salesforce ID for the partner */
    usableSfid?: Maybe<Scalars['String']>
    /** Indicates whether the latest certificate should be used for SAML */
    useLatestCertificate?: Maybe<Scalars['Boolean']>
    /** Indicates whether the partner uses their parent's Salesforce ID */
    useParentsSfid: Scalars['Boolean']
    /** The website URL of the partner */
    website?: Maybe<Scalars['String']>
    /** The ZIP code of the partner company */
    zipCode?: Maybe<Scalars['String']>
  }

/** A partner */
export type PartnerPartnerAdminsArgs = {
  onlyFullAdmins?: Maybe<Scalars['Boolean']>
}

export type PartnerAccountSettings = {
  __typename?: 'PartnerAccountSettings'
  /** The company name associated with the partner account */
  companyName?: Maybe<Scalars['String']>
  /** The unique identifier of the partner account settings */
  id: Scalars['Int']
  /** Indicates if optional training is enabled for the partner account */
  optionalTrainingEnabled?: Maybe<Scalars['Boolean']>
  /** The timezone set for the partner account */
  timeZone: TimeZones
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

/** A partner admin */
export type PartnerAdmin = Identifier &
  Timestamps & {
    __typename?: 'PartnerAdmin'
    /** The admin locale settings of the partner admin */
    adminLocale?: Maybe<Scalars['String']>
    /** The API tokens associated with the partner admin */
    apiTokens: Array<PartnerApiToken>
    /** Time when the partner admin's account was confirmed */
    confirmedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Timestamp of the partner admin's current sign-in */
    currentSignInAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The display name of the partner admin */
    displayName: Scalars['String']
    /** The email address of the partner admin */
    email: Scalars['String']
    /** The phone extension for the partner admin's phone number */
    extension?: Maybe<Scalars['String']>
    /** The first name of the partner admin */
    firstName?: Maybe<Scalars['String']>
    /** Indicates whether the partner admin has full administrative privileges */
    fullAdmin: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The last name of the partner admin */
    lastName?: Maybe<Scalars['String']>
    /** The locale settings of the partner admin */
    locale?: Maybe<Scalars['String']>
    /** The URL to download the partner's logo */
    logoDownloadUrl?: Maybe<Scalars['String']>
    /** Indicates whether multi-factor authentication is enabled */
    mfaEnabled?: Maybe<Scalars['Boolean']>
    /** The mobile phone number of the partner admin */
    mobilePhoneNumber?: Maybe<Scalars['String']>
    /** Indicates whether the online shop feature is enabled */
    onlineShop: Scalars['Boolean']
    /** The one-time password code for authentication */
    otpCode: Scalars['String']
    /** The secret key for generating one-time passwords */
    otpSecretKey: Scalars['String']
    /** The permissions assigned to the partner admin */
    partnerAdminPermission?: Maybe<PartnerAdminPermission>
    /** The ID of the associated partner */
    partnerId?: Maybe<Scalars['Int']>
    /** The partner admin's phone number */
    phoneNumber?: Maybe<Scalars['String']>
    /** QR code image data for setting up a two-factor authenticator app */
    qrCodeImageData: Scalars['String']
    /** The timezone of the partner admin */
    timeZone?: Maybe<TimeZones>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type PartnerAdminAttributes = {
  adminLocale?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  extension?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  fullAdmin?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  locale?: Maybe<Scalars['String']>
  mfaEnabled?: Maybe<Scalars['Boolean']>
  mobilePhoneNumber?: Maybe<Scalars['String']>
  partnerAdminPermission?: Maybe<PartnerAdminPermissionAttributes>
  password?: Maybe<Scalars['String']>
  passwordConfirmation?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  timeZone?: Maybe<TimeZones>
}

/** A set of permissions for a partner admin */
export type PartnerAdminPermission = Identifier &
  Timestamps & {
    __typename?: 'PartnerAdminPermission'
    /** Determines if the partner admin can manage phishing campaigns */
    canManagePhishingCampaigns: Scalars['Boolean']
    /** Determines if the partner admin can manage training campaigns */
    canManageTrainingCampaigns: Scalars['Boolean']
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Defines the permissions for accessing executive reports */
    executiveReports: PartnerAdminReportingPermissions
    /** Indicates whether the partner admin has access to executive reports */
    hasExecReports: Scalars['Boolean']
    /** Indicates whether the partner admin has the Managed ModStore */
    hasManagedModstore: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Defines the reporting permissions for the partner admin */
    reporting: PartnerAdminReportingPermissions
    /** Specifies the permissions for sending reports */
    sendReports: PartnerAdminSendReportsPermissions
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type PartnerAdminPermissionAttributes = {
  canManagePhishingCampaigns?: Maybe<Scalars['Boolean']>
  canManageTrainingCampaigns?: Maybe<Scalars['Boolean']>
  executiveReports?: Maybe<PartnerAdminReportingPermissions>
  hasExecReports?: Maybe<Scalars['Boolean']>
  hasManagedModstore?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['Int']>
  reporting?: Maybe<PartnerAdminReportingPermissions>
  sendReports?: Maybe<PartnerAdminSendReportsPermissions>
}

export enum PartnerAdminReportingPermissions {
  NoAccess = 'NO_ACCESS',
  Read = 'READ',
  Write = 'WRITE',
}

export enum PartnerAdminSendReportsPermissions {
  NoAccess = 'NO_ACCESS',
  Write = 'WRITE',
}

/** Autogenerated return type of PartnerAdminsCreate. */
export type PartnerAdminsCreatePayload = {
  __typename?: 'PartnerAdminsCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PartnerAdmin>
}

export type PartnerAdminsCursor = {
  __typename?: 'PartnerAdminsCursor'
  /** The paginated nodes */
  nodes: Array<PartnerAdmin>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of PartnerAdminsDeactivateMfa. */
export type PartnerAdminsDeactivateMfaPayload = {
  __typename?: 'PartnerAdminsDeactivateMfaPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PartnerAdmin>
}

/** Autogenerated return type of PartnerAdminsDelete. */
export type PartnerAdminsDeletePayload = {
  __typename?: 'PartnerAdminsDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PartnerAdmin>
}

/** Autogenerated return type of PartnerAdminsDisableMfa. */
export type PartnerAdminsDisableMfaPayload = {
  __typename?: 'PartnerAdminsDisableMfaPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PartnerAdmin>
}

/** Autogenerated return type of PartnerAdminsEnableMfa. */
export type PartnerAdminsEnableMfaPayload = {
  __typename?: 'PartnerAdminsEnableMfaPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PartnerAdmin>
}

export enum PartnerAdminsSortFields {
  ConfirmedAt = 'CONFIRMED_AT',
  CurrentSignInAt = 'CURRENT_SIGN_IN_AT',
  Email = 'EMAIL',
  PartnerName = 'PARTNER_NAME',
}

/** Autogenerated return type of PartnerAdminsUpdate. */
export type PartnerAdminsUpdatePayload = {
  __typename?: 'PartnerAdminsUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PartnerAdmin>
}

/** Autogenerated return type of PartnerAdminsUpdateSelf. */
export type PartnerAdminsUpdateSelfPayload = {
  __typename?: 'PartnerAdminsUpdateSelfPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PartnerAdmin>
}

export enum PartnerApiScopes {
  PartnerPublicKmsat = 'partner_public_kmsat',
}

/** An API token */
export type PartnerApiToken = Timestamps & {
  __typename?: 'PartnerApiToken'
  active: Scalars['Boolean']
  auditLogDisplay?: Maybe<Scalars['String']>
  /** The timestamp when the record was created */
  createdAt: Scalars['ISO8601DateTime']
  expiresOn?: Maybe<Scalars['ISO8601DateTime']>
  id: Scalars['Int']
  jwt: Scalars['String']
  name: Scalars['String']
  partnerAdmin?: Maybe<PartnerAdmin>
  scopes: Array<PartnerApiScopes>
  sharedSecret?: Maybe<Scalars['String']>
  /** The timestamp when the record was last updated */
  updatedAt: Scalars['ISO8601DateTime']
  uuid: Scalars['String']
}

export type PartnerApiTokensCursor = {
  __typename?: 'PartnerApiTokensCursor'
  /** The paginated nodes */
  nodes: Array<PartnerApiToken>
  /** Information about the current paginated set */
  pagination: Pagination
}

export type PartnerAttributes = {
  allowAdminWithMfaLoginBypass?: Maybe<Scalars['Boolean']>
  canEditAccountInfo?: Maybe<Scalars['Boolean']>
  canEnableUserMoves?: Maybe<Scalars['Boolean']>
  canManagePhishingCampaigns?: Maybe<Scalars['Boolean']>
  canManageSubaccounts?: Maybe<Scalars['Boolean']>
  canManageTrainingCampaigns?: Maybe<Scalars['Boolean']>
  city?: Maybe<Scalars['String']>
  companyName?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  defaultAdminLocale?: Maybe<Scalars['String']>
  disableNonSamlLogins?: Maybe<Scalars['Boolean']>
  domain?: Maybe<Scalars['String']>
  ensureSameSessionIp?: Maybe<Scalars['Boolean']>
  extension?: Maybe<Scalars['String']>
  hasExecReports?: Maybe<Scalars['Boolean']>
  hasManagedModstore?: Maybe<Scalars['Boolean']>
  idpCertFingerprint?: Maybe<Scalars['String']>
  idpCertFormat?: Maybe<IdpCertificationFormats>
  idpSsoTargetUrl?: Maybe<Scalars['String']>
  ipAllowList?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['String']>
  logoFileName?: Maybe<Scalars['String']>
  numBulkUsers?: Maybe<Scalars['Int']>
  partnerAdmin?: Maybe<PartnerAdminAttributes>
  partnerId?: Maybe<Scalars['Int']>
  partnerType?: Maybe<PartnerTypes>
  phisherSubscriptionEndDate?: Maybe<Scalars['ISO8601Date']>
  phoneNumber?: Maybe<Scalars['String']>
  purchasedSkus?: Maybe<Array<PartnerPurchasedSkusAttributes>>
  restrictConsoleSession?: Maybe<Scalars['Boolean']>
  samlEnabled?: Maybe<Scalars['Boolean']>
  samlUserProvisioningEnabled?: Maybe<Scalars['Boolean']>
  sessionTimeout?: Maybe<Scalars['Int']>
  sfid?: Maybe<Scalars['String']>
  signSamlRequests?: Maybe<Scalars['Boolean']>
  state?: Maybe<Scalars['String']>
  streetAddress1?: Maybe<Scalars['String']>
  streetAddress2?: Maybe<Scalars['String']>
  subscriptionEndDate?: Maybe<Scalars['ISO8601Date']>
  subscriptionId?: Maybe<Scalars['Int']>
  suiteNumber?: Maybe<Scalars['String']>
  timeZone?: Maybe<TimeZones>
  useParentsSfid?: Maybe<Scalars['Boolean']>
  website?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['String']>
}

/** Purchased SKU added to a main partner */
export type PartnerPurchasedSku = {
  __typename?: 'PartnerPurchasedSku'
  /** The expiration date of the SKU purchase, if applicable */
  expiresAt?: Maybe<Scalars['ISO8601Date']>
  /** The unique code representing the SKU */
  skuCode: Scalars['String']
  /** The current status of the SKU purchase */
  status?: Maybe<AccountPurchaseStatuses>
  /** The title of the purchased SKU */
  title: Scalars['String']
}

export type PartnerPurchasedSkusAttributes = {
  expiresAt?: Maybe<Scalars['ISO8601DateTime']>
  skuCode: Scalars['String']
  title: Scalars['String']
}

/** Partner preferences */
export type PartnerSettingsPreferences = {
  __typename?: 'PartnerSettingsPreferences'
  defaultExecReportsDashboard?: Maybe<ExecReportsExecReportsDashboard>
  defaultPiqDashboard?: Maybe<PasswordIqPiqDashboard>
}

export enum PartnerTypes {
  Consultant = 'CONSULTANT',
  Customer = 'CUSTOMER',
  Msp = 'MSP',
  Reseller = 'RESELLER',
}

export type PartnersApiTokenAttributes = {
  active: Scalars['Boolean']
  expiresOn?: Maybe<Scalars['ISO8601Date']>
  name: Scalars['String']
  partnerAdminId?: Maybe<Scalars['Int']>
  scopes: Array<PartnerApiScopes>
}

/** Autogenerated return type of PartnersApiTokensCreate. */
export type PartnersApiTokensCreatePayload = {
  __typename?: 'PartnersApiTokensCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<NewPartnerApiToken>
}

/** Autogenerated return type of PartnersApiTokensDelete. */
export type PartnersApiTokensDeletePayload = {
  __typename?: 'PartnersApiTokensDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PartnerApiToken>
}

/** Autogenerated return type of PartnersApiTokensReassign. */
export type PartnersApiTokensReassignPayload = {
  __typename?: 'PartnersApiTokensReassignPayload'
  errors?: Maybe<Array<Error>>
  reassigned?: Maybe<Scalars['Int']>
}

/** Autogenerated return type of PartnersApiTokensUpdate. */
export type PartnersApiTokensUpdatePayload = {
  __typename?: 'PartnersApiTokensUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PartnerApiToken>
}

/** Autogenerated return type of PartnersCreate. */
export type PartnersCreatePayload = {
  __typename?: 'PartnersCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Partner>
}

export type PartnersCursor = {
  __typename?: 'PartnersCursor'
  /** The paginated nodes */
  nodes: Array<Partner>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of PartnersDeleteLogo. */
export type PartnersDeleteLogoPayload = {
  __typename?: 'PartnersDeleteLogoPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Partner>
}

/** Autogenerated return type of PartnersDelete. */
export type PartnersDeletePayload = {
  __typename?: 'PartnersDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Partner>
}

/** Autogenerated return type of PartnersSalesforceDisconnect. */
export type PartnersSalesforceDisconnectPayload = {
  __typename?: 'PartnersSalesforceDisconnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Partner>
}

/** Autogenerated return type of PartnersSalesforceFetch. */
export type PartnersSalesforceFetchPayload = {
  __typename?: 'PartnersSalesforceFetchPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Partner>
}

/** Autogenerated return type of PartnersSalesforceScheduleAccountsFetch. */
export type PartnersSalesforceScheduleAccountsFetchPayload = {
  __typename?: 'PartnersSalesforceScheduleAccountsFetchPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Array<Scalars['String']>>
}

/** Autogenerated return type of PartnersSamlUseLatestCertificate. */
export type PartnersSamlUseLatestCertificatePayload = {
  __typename?: 'PartnersSamlUseLatestCertificatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Partner>
}

export enum PartnersSortFields {
  CompanyName = 'COMPANY_NAME',
  CreatedAt = 'CREATED_AT',
  Domain = 'DOMAIN',
  NumBulkUsers = 'NUM_BULK_USERS',
  PartnerType = 'PARTNER_TYPE',
  PhisherSubscriptionEndDate = 'PHISHER_SUBSCRIPTION_END_DATE',
  SubscriptionEndDate = 'SUBSCRIPTION_END_DATE',
}

/** Autogenerated return type of PartnersUpdateAccount. */
export type PartnersUpdateAccountPayload = {
  __typename?: 'PartnersUpdateAccountPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Partner>
}

/** Autogenerated return type of PartnersUpdate. */
export type PartnersUpdatePayload = {
  __typename?: 'PartnersUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Partner>
}

export type PasswordIqAuditLog = {
  __typename?: 'PasswordIqAuditLog'
  /** The data of the action that was logged in the audit */
  data?: Maybe<Scalars['String']>
  /** The date and time when the audit log was created */
  datetime?: Maybe<Scalars['String']>
  /** Metadata associated with the audit log */
  metadata?: Maybe<Scalars['String']>
  /** The status of the action logged in the audit */
  status?: Maybe<Scalars['String']>
}

export type PasswordIqAuditLogData = {
  __typename?: 'PasswordIqAuditLogData'
  /** The count of audit logs available */
  count?: Maybe<Scalars['Int']>
  /** Indicates if there were errors generating the audit logs */
  errors?: Maybe<Scalars['Boolean']>
  /** A collection of audit logs */
  logs?: Maybe<Array<PasswordIqAuditLog>>
}

export type PasswordIqConfigurationsCount = {
  __typename?: 'PasswordIqConfigurationsCount'
  /** Configuration groups for the PIQ widget. */
  configurationGroups: Array<PasswordIqPiqWidgetConfigurationGroup>
  /** The detection types for password configuration. */
  detection: DetectionTypes
  /** The groups associated with the password IQ configuration. */
  groups: Array<Group>
  /** The title of the password IQ configurations. */
  title?: Maybe<Scalars['String']>
  /** The type of the PIQ widget. */
  type: PiqWidget
  /** The user type for the PIQ configurations. */
  userType?: Maybe<PiqUserTypes>
}

export type PasswordIqConfigurationsDetectionComparison = {
  __typename?: 'PasswordIqConfigurationsDetectionComparison'
  /** A list of configuration groups for the widget */
  configurationGroups: Array<PasswordIqPiqWidgetConfigurationGroup>
  /** Specifies the type of detection */
  detection: DetectionTypes
  /** A list of groups associated with the configuration */
  groups: Array<Group>
  /** The title of the detection comparison configuration */
  title?: Maybe<Scalars['String']>
  /** Specifies the type of the widget */
  type: PiqWidget
  /** Specifies the user type */
  userType?: Maybe<PiqUserTypes>
}

export type PasswordIqConfigurationsOvertime = {
  __typename?: 'PasswordIqConfigurationsOvertime'
  /** Configuration groups for the Password IQ widget */
  configurationGroups: Array<PasswordIqPiqWidgetConfigurationGroup>
  /** The detection types for the Password IQ configuration */
  detection: DetectionTypes
  /** Groups associated with the overtime configuration */
  groups: Array<Group>
  /** The timeframe for the overtime data */
  timeframe?: Maybe<ReportTimeframe>
  /** The title of the overtime widget */
  title?: Maybe<Scalars['String']>
  /** The type of the Password IQ widget */
  type: PiqWidget
  /** The user types targeted by the Password IQ widget */
  userType?: Maybe<PiqUserTypes>
}

export type PasswordIqConfigurationsRedFlag = {
  __typename?: 'PasswordIqConfigurationsRedFlag'
  /** Configuration groups specific to the red flag widget */
  configurationGroups: Array<PasswordIqPiqWidgetConfigurationGroup>
  /** The type of detection for the red flag */
  detection: DetectionTypes
  /** Groups associated with the red flag */
  groups: Array<Group>
  /** The overtime time frame indicating extended period analysis */
  overtimeTimeframe?: Maybe<ReportTimeframe>
  /** The time frame for the red flag report */
  timeframe?: Maybe<ReportTimeframe>
  /** The title of the red flag */
  title?: Maybe<Scalars['String']>
  /** The type of widget for the Password IQ red flag configuration */
  type: PiqWidget
  /** The type of user associated with the red flag */
  userType?: Maybe<PiqUserTypes>
  /** The variance time frame in the report, indicating fluctuations */
  varianceTimeframe?: Maybe<ReportTimeframe>
}

export type PasswordIqConfigurationsStates = {
  __typename?: 'PasswordIqConfigurationsStates'
  /** IDs of expanded items */
  expandedIds: Array<Scalars['Int']>
  /** Current page number */
  page?: Maybe<Scalars['Int']>
  /** Number of items per page */
  per?: Maybe<Scalars['Int']>
  /** Search query */
  search?: Maybe<Scalars['String']>
  /** Types of selected detections */
  selectedDetections: Array<DetectionTypes>
  /** Field to sort by */
  sort?: Maybe<SortFields>
  /** Direction of sort */
  sortDirection?: Maybe<SortDirections>
  /** Title of the widget */
  title?: Maybe<Scalars['String']>
  /** Type of the widget */
  type: PiqWidget
  /** Type of user */
  userType?: Maybe<PiqUserTypes>
}

export type PasswordIqConfigurationsVariance = {
  __typename?: 'PasswordIqConfigurationsVariance'
  /** Configuration groups for the Password IQ widget */
  configurationGroups: Array<PasswordIqPiqWidgetConfigurationGroup>
  /** The detection types for the Password IQ */
  detection: DetectionTypes
  /** Groups associated with this configuration */
  groups: Array<Group>
  /** The timeframe for the configuration */
  timeframe?: Maybe<ReportTimeframe>
  /** The title of the variance configuration */
  title?: Maybe<Scalars['String']>
  /** The type of the PIQ widget */
  type: PiqWidget
  /** The user type for the variance configuration */
  userType?: Maybe<PiqUserTypes>
}

export type PasswordIqCsvConfiguration = {
  __typename?: 'PasswordIqCsvConfiguration'
  /** The types of detections to filter the search */
  detectionTypes: Array<DetectionTypes>
  /** The number of rows returned in the search result */
  rowsReturned: Scalars['Int']
  /** A query string for searching configurations */
  search?: Maybe<Scalars['String']>
  /** The type of user for whom the configuration applies */
  userType?: Maybe<PiqUserTypes>
}

/** Autogenerated return type of PasswordIqCsv. */
export type PasswordIqCsvPayload = {
  __typename?: 'PasswordIqCsvPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Download>
}

/** Autogenerated return type of PasswordIqDashboardCreate. */
export type PasswordIqDashboardCreatePayload = {
  __typename?: 'PasswordIqDashboardCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PasswordIqPiqDashboard>
}

/** Autogenerated return type of PasswordIqDashboardDelete. */
export type PasswordIqDashboardDeletePayload = {
  __typename?: 'PasswordIqDashboardDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PasswordIqPiqDashboard>
}

/** Autogenerated return type of PasswordIqDashboardUpdate. */
export type PasswordIqDashboardUpdatePayload = {
  __typename?: 'PasswordIqDashboardUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PasswordIqPiqDashboard>
}

export type PasswordIqDashboardWidget = {
  detection?: Maybe<WidgetDetectionTypes>
  endColumn: Scalars['Int']
  endRow: Scalars['Int']
  groups?: Maybe<Array<Scalars['Int']>>
  overtimeTimeframe?: Maybe<ReportsTimeframe>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  selectedDetections?: Maybe<Array<WidgetDetectionTypes>>
  sort?: Maybe<SortFields>
  sortDirection?: Maybe<SortDirections>
  startColumn: Scalars['Int']
  startRow: Scalars['Int']
  timeframe?: Maybe<ReportsTimeframe>
  title?: Maybe<Scalars['String']>
  type: PiqWidget
  userType?: Maybe<PiqUserTypes>
  varianceTimeframe?: Maybe<ReportsTimeframe>
}

/** Password IQ Detection Type */
export type PasswordIqDetection = {
  __typename?: 'PasswordIqDetection'
  /** The name of the detection */
  name: Scalars['String']
}

/** Password IQ Detection Count Data */
export type PasswordIqDetectionCountData = {
  __typename?: 'PasswordIqDetectionCountData'
  /** Detection counts for the given criteria */
  counts: PasswordIqDetectionCounts
  /** The timestamp of the last scan */
  lastScan?: Maybe<Scalars['ISO8601DateTime']>
}

/** Password IQ Detection Counts */
export type PasswordIqDetectionCounts = {
  __typename?: 'PasswordIqDetectionCounts'
  /** Counts of passwords stored in clear text */
  AD_PW_CLEAR_TEXT?: Maybe<Scalars['Int']>
  /** Counts of detected empty passwords */
  AD_PW_EMPTY?: Maybe<Scalars['Int']>
  /** Counts of passwords found in data breaches */
  AD_PW_FOUND_IN_BREACH?: Maybe<Scalars['Int']>
  /** Counts of passwords set to never expire */
  AD_PW_NEVER_EXPIRES?: Maybe<Scalars['Int']>
  /** Counts of accounts with no password required */
  AD_PW_NOT_REQD?: Maybe<Scalars['Int']>
  /** Counts of detected shared passwords */
  AD_PW_SHARED?: Maybe<Scalars['Int']>
  /** Counts of detected weak passwords */
  AD_PW_WEAK?: Maybe<Scalars['Int']>
  /** Counts of users without AES encryption set */
  AD_USER_AES_ENCRYPTION_NOT_SET?: Maybe<Scalars['Int']>
  /** Counts of users with only DES encryption */
  AD_USER_DES_ONLY_ENCRYPTION?: Maybe<Scalars['Int']>
  /** Counts of users that have preauthentication enabled */
  AD_USER_HAS_PREAUTHENTICATION?: Maybe<Scalars['Int']>
  /** Counts of users that use LM hash for storing passwords */
  AD_USER_USES_LM_HASH?: Maybe<Scalars['Int']>
  /** Total counts of all detection categories */
  ALL?: Maybe<Scalars['Int']>
}

export type PasswordIqEvent = Identifier & {
  __typename?: 'PasswordIqEvent'
  /** Logical operator for criterion */
  boolean: Booleans
  /** Comparison operator for event */
  comparison: EventComparisons
  /** Count threshold for event */
  count: Scalars['Int']
  /** Indicator if event was detected */
  detected: Scalars['Boolean']
  /** Types of detections for Password IQ event */
  detection?: Maybe<Array<DetectionTypes>>
  /** Type of Password IQ event */
  event?: Maybe<EventTypes>
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** Timeframe for event occurrence */
  timeframe: Timeframe
}

export type PasswordIqEventArgument = {
  bool: Booleans
  comparison?: Maybe<EventComparisons>
  count: Scalars['Int']
  countType: EventComparisons
  detected: Scalars['Boolean']
  detection?: Maybe<Array<DetectionTypes>>
  event?: Maybe<EventTypes>
  timeframe: TimeframeArgument
}

/** Autogenerated return type of PasswordIqPDF. */
export type PasswordIqPdfPayload = {
  __typename?: 'PasswordIqPDFPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Download>
}

export type PasswordIqPdfConfiguration = {
  __typename?: 'PasswordIqPdfConfiguration'
  /** A collection of widget configurations for the PDF */
  widgets: Array<PasswordIqPiqWidgetsConfiguration>
}

/** Password IQ Pie Chart Data */
export type PasswordIqPieData = {
  __typename?: 'PasswordIqPieData'
  /** Number of detected instances */
  detected: Scalars['Int']
  /** Number of instances not detected */
  not_detected: Scalars['Int']
}

export type PasswordIqPiqDashboard = {
  __typename?: 'PasswordIqPiqDashboard'
  /** The date and time when the dashboard was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The user who created the dashboard */
  creator: User
  /** Unique identifier of the dashboard */
  id: Scalars['Int']
  /** Name of the dashboard */
  name: Scalars['String']
  /** The date and time when the dashboard was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Collection of widgets associated with the dashboard */
  widgets: Array<PasswordIqPiqDashboardWidget>
}

export type PasswordIqPiqDashboardWidget = {
  __typename?: 'PasswordIqPiqDashboardWidget'
  /** Timestamp of when the widget was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The height of the widget */
  height: Scalars['Int']
  /** The unique ID of the dashboard widget */
  id?: Maybe<Scalars['ID']>
  /** Configuration details for the Password IQ widget */
  piqWidgetConfiguration?: Maybe<PasswordIqWidgetConfigurations>
  /** The starting column position of the widget on the dashboard */
  startColumn: Scalars['Int']
  /** The starting row position of the widget on the dashboard */
  startRow: Scalars['Int']
  /** Timestamp of the last update to the widget */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The width of the widget */
  width: Scalars['Int']
}

/** Password IQ Pie Chart Data */
export type PasswordIqPiqDataPoint = {
  __typename?: 'PasswordIqPiqDataPoint'
  /** The X-axis value representing date */
  x: Scalars['ISO8601Date']
  /** The Y-axis value representing a numeric measure */
  y: Scalars['Int']
}

/** Password IQ User email */
export type PasswordIqPiqEmail = {
  __typename?: 'PasswordIqPiqEmail'
  /** The email address */
  address: Scalars['String']
}

/** Password IQ Event */
export type PasswordIqPiqEvent = {
  __typename?: 'PasswordIqPiqEvent'
  /** Indicates if the PIQ event was detected automatically */
  detected: Scalars['Boolean']
  /** The type of detection that triggered the PIQ event */
  detectionType: PasswordIqDetection
  /** Unique identifier of the PIQ event */
  id: Scalars['Int']
  /** Optional notes associated with the PIQ event */
  note?: Maybe<PasswordIqPiqNote>
  /** The date and time when the PIQ event occurred */
  occurredAt: Scalars['ISO8601Date']
  /** Indicates whether the PIQ event has been resolved */
  resolved: Scalars['Boolean']
  /** The current status of the PIQ event */
  status: EventStatuses
}

/** Password IQ Event Note */
export type PasswordIqPiqNote = {
  __typename?: 'PasswordIqPiqNote'
  /** A note related to the Password IQ */
  note: Scalars['String']
}

/** Pagination Metadata for Password IQ Detection States */
export type PasswordIqPiqPagination = {
  __typename?: 'PasswordIqPiqPagination'
  /** The current page number in the pagination sequence */
  page: Scalars['Int']
  /** The total number of pages available */
  pages: Scalars['Int']
  /** The number of items per page */
  per: Scalars['Int']
  /** The total number of items available */
  totalCount: Scalars['Int']
}

/** Password IQ Pie Chart Data */
export type PasswordIqPiqSerie = {
  __typename?: 'PasswordIqPiqSerie'
  /** Data points within the Password IQ series */
  data: Array<PasswordIqPiqDataPoint>
  /** The name of the Password IQ series */
  name: Scalars['String']
}

/** Password IQ User */
export type PasswordIqPiqUser = {
  __typename?: 'PasswordIqPiqUser'
  /** A list of emails associated with the user */
  emails: Array<PasswordIqPiqEmail>
  /** A list of events related to the user */
  events: Array<PasswordIqPiqEvent>
  /** The unique identifier of the user */
  id: Scalars['Int']
  /** The KMSAT identifier for the user */
  kmsatId?: Maybe<Scalars['Int']>
}

/** A PiqWidgetConfigurationGroup */
export type PasswordIqPiqWidgetConfigurationGroup = {
  __typename?: 'PasswordIqPiqWidgetConfigurationGroup'
  /** The group identifier associated with this PIQ widget configuration group */
  groupId: Scalars['Int']
  /** The unique identifier of the PIQ widget configuration group */
  id: Scalars['Int']
}

export type PasswordIqPiqWidgetsConfiguration = {
  __typename?: 'PasswordIqPiqWidgetsConfiguration'
  /** The detection type used in the widget */
  detection?: Maybe<DetectionTypes>
  /** Groups associated with the widget */
  groups: Array<Group>
  /** The current page number for pagination */
  page?: Maybe<Scalars['Int']>
  /** The number of items per page for pagination */
  per?: Maybe<Scalars['Int']>
  /** Search query to filter results */
  search?: Maybe<Scalars['String']>
  /** List of selected detection types */
  selectedDetections?: Maybe<Array<DetectionTypes>>
  /** Field to sort the results by */
  sort?: Maybe<SortFields>
  /** Direction of sorting results, either ascending or descending */
  sortDirection?: Maybe<SortDirections>
  /** Timeframe for filtering the results */
  timeframe?: Maybe<ReportTimeframe>
  /** The title of the widget */
  title?: Maybe<Scalars['String']>
  /** The type of the Password IQ widget */
  type: PiqWidget
  /** Type of user for the widget configuration */
  userType?: Maybe<PiqUserTypes>
}

/** Report download */
export type PasswordIqReport = {
  __typename?: 'PasswordIqReport'
  /** The category of the PasswordIQ report */
  category: DownloadablesCategories
  /** Configuration details for the PasswordIQ report */
  configuration: PiqDownloadMetadata
  /** The creation timestamp of the PasswordIQ report */
  createdAt: Scalars['ISO8601DateTime']
  /** The unique identifier of the PasswordIQ report */
  id: Scalars['Int']
  /** The user who downloaded the report, if applicable */
  impersonatingUser?: Maybe<User>
  /** The type of the downloadable report */
  type: DownloadablesTypes
  /** The last update timestamp of the PasswordIQ report */
  updatedAt: Scalars['ISO8601DateTime']
}

/** Password IQ requested scan */
export type PasswordIqRequestedScan = {
  __typename?: 'PasswordIqRequestedScan'
  /** The associated account ID of the requested scan */
  accountId?: Maybe<Scalars['Int']>
  /** The unique identifier of the requested scan */
  id?: Maybe<Scalars['Int']>
  /** The timestamp when the scan was requested */
  requestedAt?: Maybe<Scalars['ISO8601DateTime']>
}

/** Autogenerated return type of PasswordIqScanNow. */
export type PasswordIqScanNowPayload = {
  __typename?: 'PasswordIqScanNowPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PasswordIqRequestedScan>
}

/** Password IQ Schedule Data */
export type PasswordIqSchedule = {
  __typename?: 'PasswordIqSchedule'
  /** The days of the week the schedule is active */
  days?: Maybe<Array<Weekdays>>
  /** Indicates if this is the default schedule */
  isDefault?: Maybe<Scalars['Boolean']>
  /** The starting time for the schedule */
  startTime?: Maybe<Scalars['String']>
}

/** Autogenerated return type of PasswordIqScheduleUpdate. */
export type PasswordIqScheduleUpdatePayload = {
  __typename?: 'PasswordIqScheduleUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PasswordIqSchedule>
}

export type PasswordIqState = Identifier & {
  __typename?: 'PasswordIqState'
  /** Indicates whether a detection was made */
  detected: Scalars['Boolean']
  /** The detection state indicated by PasswordIq */
  detection?: Maybe<DetectionTypes>
  /** Unique identifier for the object */
  id: Scalars['Int']
}

export type PasswordIqStateArgument = {
  detected: Scalars['Boolean']
  detection?: Maybe<DetectionTypes>
}

/** Autogenerated return type of PasswordIqUpdateEvent. */
export type PasswordIqUpdateEventPayload = {
  __typename?: 'PasswordIqUpdateEventPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PasswordIqPiqEvent>
}

/** Password IQ User Detection States */
export type PasswordIqUserStates = {
  __typename?: 'PasswordIqUserStates'
  /** Pagination details for the user list */
  pagination: PasswordIqPiqPagination
  /** A list of users */
  users: Array<PasswordIqPiqUser>
}

/** A widget configuration type */
export type PasswordIqWidgetConfigurations =
  | PasswordIqConfigurationsCount
  | PasswordIqConfigurationsDetectionComparison
  | PasswordIqConfigurationsOvertime
  | PasswordIqConfigurationsRedFlag
  | PasswordIqConfigurationsStates
  | PasswordIqConfigurationsVariance

/** A file */
export type PersistedFile = Timestamps & {
  __typename?: 'PersistedFile'
  /** The timestamp when the record was created */
  createdAt: Scalars['ISO8601DateTime']
  /** The name of the persisted file */
  name: Scalars['String']
  /** The timestamp when the record was last updated */
  updatedAt: Scalars['ISO8601DateTime']
}

/** The phishing account settings */
export type PhishAlertStats = {
  __typename?: 'PhishAlertStats'
  /** The number of installations */
  installed: Scalars['Int']
  /** The number of reported non-simulated phishing alerts */
  reportedNonSimulatedPhishing: Scalars['Int']
  /** Graphical data for reported non-simulated phishing over time */
  reportedNonSimulatedPhishingGraph: Array<TimeData>
  /** The number of reported simulated phishing alerts */
  reportedSimulatedPhishing: Scalars['Int']
  /** Graphical data for reported simulated phishing over time */
  reportedSimulatedPhishingGraph: Array<TimeData>
  /** The total number of alerts */
  total: Scalars['Int']
  /** The number of uninstallations */
  uninstalled: Scalars['Int']
}

/** A domain used for phishing emails */
export type PhishDomain = Identifier &
  Timestamps & {
    __typename?: 'PhishDomain'
    /** Indicates if the phishing domain is active */
    active?: Maybe<Scalars['Boolean']>
    auditLogDisplay?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    domainType: PhishDomainTypes
    dotname: Scalars['String']
    /** Indicates if the phishing domain is hidden */
    hidden: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The name of the phishing domain */
    name: Scalars['String']
    replyToEnabled: Scalars['Boolean']
    rootDomain: Scalars['String']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type PhishDomainAttributes = {
  domainType?: Maybe<PhishDomainTypes>
  hidden?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  replyToEnabled?: Maybe<Scalars['Boolean']>
}

export enum PhishDomainSortFields {
  Createdat = 'CREATEDAT',
  Domaintype = 'DOMAINTYPE',
  Hidden = 'HIDDEN',
  Name = 'NAME',
  Replytoenabled = 'REPLYTOENABLED',
}

export enum PhishDomainTypes {
  Accountusable = 'ACCOUNTUSABLE',
  Accountusablewithreplyto = 'ACCOUNTUSABLEWITHREPLYTO',
  Customizable = 'CUSTOMIZABLE',
  Freetoolusable = 'FREETOOLUSABLE',
  Legacy = 'LEGACY',
  ReplyTo = 'REPLY_TO',
  System = 'SYSTEM',
  User = 'USER',
}

/** Autogenerated return type of PhishDomainsCreateCustom. */
export type PhishDomainsCreateCustomPayload = {
  __typename?: 'PhishDomainsCreateCustomPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created phish domain */
  node?: Maybe<PhishDomain>
}

/** Autogenerated return type of PhishDomainsCreate. */
export type PhishDomainsCreatePayload = {
  __typename?: 'PhishDomainsCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PhishDomain>
}

export type PhishDomainsCursor = {
  __typename?: 'PhishDomainsCursor'
  /** The paginated nodes */
  nodes: Array<PhishDomain>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of PhishDomainsDelete. */
export type PhishDomainsDeletePayload = {
  __typename?: 'PhishDomainsDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The deleted phish domain */
  node?: Maybe<PhishDomain>
}

/** Autogenerated return type of PhishDomainsToggleArchived. */
export type PhishDomainsToggleArchivedPayload = {
  __typename?: 'PhishDomainsToggleArchivedPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PhishDomain>
}

/** Autogenerated return type of PhishDomainsToggleOverride. */
export type PhishDomainsToggleOverridePayload = {
  __typename?: 'PhishDomainsToggleOverridePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated phish domain */
  node?: Maybe<PhishDomain>
}

/** Autogenerated return type of PhishDomainsUpdate. */
export type PhishDomainsUpdatePayload = {
  __typename?: 'PhishDomainsUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PhishDomain>
}

export type PhishEvent = Identifier & {
  __typename?: 'PhishEvent'
  /** The boolean value defining the criterion logic */
  boolean: Booleans
  /** The type of comparison to be used for evaluation */
  comparison: EventComparisons
  /** The count of events for the criterion */
  count: Scalars['Int']
  /** The specific phishing event to be considered */
  event: PhishEvents
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The timeframe for the criterion */
  timeframe: Timeframe
}

export type PhishEventArgument = {
  bool: Booleans
  count: Scalars['Int']
  countType: EventComparisons
  event: PhishEvents
  timeframe: TimeframeArgument
}

export enum PhishEvents {
  AllFailures = 'ALL_FAILURES',
  AllFailuresButClicks = 'ALL_FAILURES_BUT_CLICKS',
  AttachmentOpen = 'ATTACHMENT_OPEN',
  Bounced = 'BOUNCED',
  CallbackDataEntered = 'CALLBACK_DATA_ENTERED',
  Called = 'CALLED',
  Clicked = 'CLICKED',
  DataEntered = 'DATA_ENTERED',
  Delivered = 'DELIVERED',
  MacroEnabled = 'MACRO_ENABLED',
  Opened = 'OPENED',
  Passed = 'PASSED',
  QrCodeScanned = 'QR_CODE_SCANNED',
  Replied = 'REPLIED',
  Reported = 'REPORTED',
  RunFailures = 'RUN_FAILURES',
}

export type PhishFlipEvent = Identifier & {
  __typename?: 'PhishFlipEvent'
  /** The boolean value defining the criterion logic */
  boolean: Booleans
  /** The type of comparison to be used for evaluation */
  comparison: EventComparisons
  /** The count of events for the criterion */
  count: Scalars['Int']
  /** The specific event type for the PhishFlip criterion */
  event: PhishFlipEvents
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The timeframe for the criterion */
  timeframe: Timeframe
}

export type PhishFlipEventArgument = {
  bool: Booleans
  count: Scalars['Int']
  countType: EventComparisons
  event: PhishFlipEvents
  timeframe: TimeframeArgument
}

export enum PhishFlipEvents {
  AllFailures = 'ALL_FAILURES',
  AllFailuresButClicks = 'ALL_FAILURES_BUT_CLICKS',
  AttachmentOpen = 'ATTACHMENT_OPEN',
  Bounced = 'BOUNCED',
  Clicked = 'CLICKED',
  DataEntered = 'DATA_ENTERED',
  Delivered = 'DELIVERED',
  MacroEnabled = 'MACRO_ENABLED',
  Opened = 'OPENED',
  Passed = 'PASSED',
  QrCodeScanned = 'QR_CODE_SCANNED',
  Replied = 'REPLIED',
  Reported = 'REPORTED',
  RunFailures = 'RUN_FAILURES',
}

export enum PhishalertDispositions {
  Phishing = 'PHISHING',
  Spam = 'SPAM',
  Unknown = 'UNKNOWN',
}

/** The settings for a language of a Phish Alert instance */
export type PhishalertLanguage = Identifier &
  Timestamps & {
    __typename?: 'PhishalertLanguage'
    _destroy: Scalars['Boolean']
    auditLogDisplay?: Maybe<Scalars['String']>
    /** Confirmation message displayed in this language setting */
    confirmationMessage?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Flag to display the phishing description TODO: DOCS CHANGE THIS!! */
    displayPhishingDescription: Scalars['Boolean']
    /** Flag to display the spam description TODO: DOCS CHANGE THIS!! */
    displaySpamDescription: Scalars['Boolean']
    /** Flag to display the unknown description TODO: DOCS CHANGE THIS!! */
    displayUnknownDescription: Scalars['Boolean']
    /** Subject for forwarded emails in this language setting */
    emailForwardSubject?: Maybe<Scalars['String']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Indicates if this is the default language setting */
    isDefault?: Maybe<Scalars['Boolean']>
    /** The language setting */
    language: PublishedLanguages
    /** Message report content for non-PST users */
    messageReportNonPst?: Maybe<Scalars['String']>
    /** Message report content for PST users */
    messageReportPst?: Maybe<Scalars['String']>
    phishalertSetting?: Maybe<PhishalertSetting>
    /** Description for phishing emails TODO: DOCS CHANGE THIS!! */
    phishingDescription?: Maybe<Scalars['String']>
    /** Label for phishing emails TODO: DOCS CHANGE THIS!! */
    phishingLabel?: Maybe<Scalars['String']>
    /** Text to be displayed on the report button */
    reportButtonText: Scalars['String']
    /** Text to be displayed for the report group */
    reportGroupText: Scalars['String']
    /** Flag to show message report for non-PST users */
    showMessageReportNonPst: Scalars['Boolean']
    /** Flag to show message report for PST users */
    showMessageReportPst: Scalars['Boolean']
    /** Description for spam emails TODO: DOCS CHANGE THIS!! */
    spamDescription?: Maybe<Scalars['String']>
    /** Label for spam emails TODO: DOCS CHANGE THIS!! */
    spamLabel?: Maybe<Scalars['String']>
    /** Timeout for PST report in seconds */
    timeoutReportPst: Scalars['Int']
    /** Description for unknown emails TODO: DOCS CHANGE THIS!! */
    unknownDescription?: Maybe<Scalars['String']>
    /** Label for unknown emails TODO: DOCS CHANGE THIS!! */
    unknownLabel?: Maybe<Scalars['String']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type PhishalertLanguageAttributes = {
  _destroy?: Maybe<Scalars['Boolean']>
  confirmationMessage?: Maybe<Scalars['String']>
  displayPhishingDescription?: Maybe<Scalars['Boolean']>
  displaySpamDescription?: Maybe<Scalars['Boolean']>
  displayUnknownDescription?: Maybe<Scalars['Boolean']>
  emailForwardSubject?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  isDefault?: Maybe<Scalars['Boolean']>
  language?: Maybe<PublishedLanguages>
  messageReportNonPst?: Maybe<Scalars['String']>
  messageReportPst?: Maybe<Scalars['String']>
  phishingDescription?: Maybe<Scalars['String']>
  phishingLabel?: Maybe<Scalars['String']>
  reportButtonText?: Maybe<Scalars['String']>
  reportGroupText?: Maybe<Scalars['String']>
  showMessageReportNonPst?: Maybe<Scalars['Boolean']>
  showMessageReportPst?: Maybe<Scalars['Boolean']>
  spamDescription?: Maybe<Scalars['String']>
  spamLabel?: Maybe<Scalars['String']>
  timeoutReportPst?: Maybe<Scalars['CoercedInt']>
  unknownDescription?: Maybe<Scalars['String']>
  unknownLabel?: Maybe<Scalars['String']>
}

/** The settings for a Phish Alert instance */
export type PhishalertSetting = Identifier &
  Timestamps & {
    __typename?: 'PhishalertSetting'
    /** Add headers as an attachment TODO: DOCS CHANGE THIS!! */
    addHeadersAsAttachment: Scalars['Boolean']
    /** Allow users to add comments to their phishing reports */
    allowUsersToComment: Scalars['Boolean']
    /** Whether to also CC the forwarded email to another address */
    alsoCc?: Maybe<Scalars['Boolean']>
    auditLogDisplay?: Maybe<Scalars['String']>
    auditLogName?: Maybe<Scalars['String']>
    /** Chrome extension details */
    chromeExtension: Scalars['String']
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Default disposition for unknown emails TODO: DOCS CHANGE THIS!! */
    defaultDisposition: PhishalertDispositions
    /** URL of the default icon used */
    defaultIconUrl: Scalars['String']
    /** Disable CRID validation */
    disableCridValidation: Scalars['Boolean']
    /** Disable the unknown disposition feature */
    disableUnknownDisposition: Scalars['Boolean']
    /** Format of emails when they are forwarded */
    emailFormat: EmailForwardFormats
    /** Email address to which phishing emails are forwarded */
    emailForward: Scalars['String']
    /** Email address for forwarding via Microsoft add-in */
    emailForwardMicrosoftAddin?: Maybe<Scalars['String']>
    /** Email address for forwarding phishing detected emails */
    emailForwardPhishing: Scalars['String']
    /** Email address for forwarding spam detected emails */
    emailForwardSpam: Scalars['String']
    /** Email address for forwarding unknown emails */
    emailForwardUnknown: Scalars['String']
    /** Flag to enable or disable forwarding of emails */
    enableForwarding: Scalars['Boolean']
    /** Exclude the email body from reports sent to Microsoft */
    excludeEmailBodyInReport: Scalars['Boolean']
    /** Flag to set this as the global default Phish Alert setting */
    globalDefault: Scalars['Boolean']
    /** Manifest for the Graph PAB application */
    graphManifest: Scalars['String']
    /** Manifest for the Hybrid PAB application */
    hybridManifest: Scalars['String']
    /** URL of the icon used for the setting */
    iconUrl?: Maybe<Scalars['String']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Include email headers in the body of forwarded emails */
    includeHeadersInEmailBody: Scalars['Boolean']
    /** Flag to indicate if the default icon is used */
    isDefaultIcon: Scalars['Boolean']
    /** License key for the Phish Alert setting */
    license: Scalars['String']
    /** The name of the Phish Alert setting */
    name: Scalars['String']
    /** URL for Outlook integration */
    outlookUrl?: Maybe<Scalars['String']>
    /** Icon for the PAB */
    pabIcon?: Maybe<Scalars['String']>
    /** Supported languages for the Phish Alert */
    phishalertLanguages: Array<PhishalertLanguage>
    /** Flag to pull locale information for phishing reports */
    pullLocaleForPhishing: Scalars['Boolean']
    /** Save forwarded emails to sent items */
    saveToSentItems: Scalars['Boolean']
    /** Send reports directly to Microsoft */
    sendToMicrosoft: Scalars['Boolean']
    /** Flag to send to Microsoft via add-in */
    sendToMicrosoftAddin: Scalars['Boolean']
    /** Manifest for the spam application */
    spamManifest: Scalars['String']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type PhishalertSettingAttributes = {
  addHeadersAsAttachment?: Maybe<Scalars['Boolean']>
  allowUsersToComment?: Maybe<Scalars['Boolean']>
  alsoCc?: Maybe<Scalars['Boolean']>
  defaultDisposition?: Maybe<PhishalertDispositions>
  disableCridValidation?: Maybe<Scalars['Boolean']>
  disableUnknownDisposition?: Maybe<Scalars['Boolean']>
  emailFormat?: Maybe<EmailForwardFormats>
  emailForward?: Maybe<Scalars['String']>
  emailForwardMicrosoftAddin?: Maybe<Scalars['String']>
  emailForwardPhishing?: Maybe<Scalars['String']>
  emailForwardSpam?: Maybe<Scalars['String']>
  emailForwardUnknown?: Maybe<Scalars['String']>
  enableForwarding?: Maybe<Scalars['Boolean']>
  excludeEmailBodyInReport?: Maybe<Scalars['Boolean']>
  globalDefault?: Maybe<Scalars['Boolean']>
  id: Scalars['Int']
  includeHeadersInEmailBody?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  pabIcon?: Maybe<Scalars['String']>
  phishalertLanguages?: Maybe<Array<PhishalertLanguageAttributes>>
  pullLocaleForPhishing?: Maybe<Scalars['Boolean']>
  saveToSentItems?: Maybe<Scalars['Boolean']>
  sendToMicrosoft?: Maybe<Scalars['Boolean']>
  sendToMicrosoftAddin?: Maybe<Scalars['Boolean']>
}

export type PhishingAllClickers = {
  __typename?: 'PhishingAllClickers'
  /** The CSV format of the phishing report data */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export enum PhishingAttackVectors {
  Excelattachments = 'EXCELATTACHMENTS',
  Htmlattachments = 'HTMLATTACHMENTS',
  Macroattachments = 'MACROATTACHMENTS',
  Pdfattachments = 'PDFATTACHMENTS',
  Powerpointattachments = 'POWERPOINTATTACHMENTS',
  Wordattachments = 'WORDATTACHMENTS',
  Zippedattachments = 'ZIPPEDATTACHMENTS',
}

/** A phishing campaign */
export type PhishingCampaign = Identifier &
  PhishingCampaignInterface &
  Timestamps & {
    __typename?: 'PhishingCampaign'
    /** Indicates if the phishing campaign is active */
    active: Scalars['Boolean']
    /** Indicates if the AIDA template is selected */
    aidaSelectedTemplate?: Maybe<Scalars['Boolean']>
    /** Indicates if the phishing campaign targets all users */
    allUsers: Scalars['Boolean']
    auditLogDisplay?: Maybe<Scalars['String']>
    /** The business days considered for the campaign */
    businessDays?: Maybe<Array<Scalars['Int']>>
    /** End hour for campaign's business hours */
    businessHoursEndHour?: Maybe<Scalars['Time']>
    /** Start hour for campaign's business hours */
    businessHoursStartHour?: Maybe<Scalars['Time']>
    /** UUID for the callback phishing greeting template translation */
    callbackPhishingGreetingTemplateTranslationUuid?: Maybe<Scalars['String']>
    /** The phone number region for callback phishing */
    callbackPhishingPhoneNumberRegion?: Maybe<Scalars['String']>
    callbackPhishingPhoneNumberRegions?: Maybe<CallbackPhishingPhoneNumberRegions>
    /** The type of the phishing campaign */
    campaignType: Scalars['String']
    /** The group that clicked in the phishing campaign */
    clickedGroup?: Maybe<Group>
    /** Users who clicked in the phishing campaign */
    clickers: Array<PhishingCampaignClicker>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    difficulties: Array<Scalars['Int']>
    displayTemplateSelection?: Maybe<Scalars['String']>
    /** The email template used in the phishing campaign */
    emailTemplate?: Maybe<PhishingTemplate>
    /** The first run of the phishing campaign */
    firstRun?: Maybe<PhishingCampaignRun>
    /** The frequency period for the phishing campaign */
    frequencyPeriod: Frequencies
    /** Indicates if email templates are fully randomized */
    fullRandom?: Maybe<Scalars['Boolean']>
    /** Groups involved in the phishing campaign */
    groups: Array<Group>
    /** Determines if the campaign should be hidden from reports */
    hideFromReports: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Indicates if PhishFlip is enabled for the campaign */
    isPhishflip: Scalars['Boolean']
    /** Determines if the phishing campaign recurs */
    isRecurring: Scalars['Boolean']
    /** The landing page used in the phishing campaign */
    landingPage?: Maybe<LandingPage>
    landingPageId?: Maybe<Scalars['Int']>
    /** The most recent run of the phishing campaign */
    lastRun?: Maybe<PhishingCampaignRun>
    /** The date of the last run of the phishing campaign */
    lastRunDate?: Maybe<Scalars['ISO8601DateTime']>
    localizedCampaign?: Maybe<Scalars['Boolean']>
    /** Indicates if the campaign is managed */
    managedPhishingCampaign?: Maybe<ManagedPhishingCampaign>
    /** The name of the phishing campaign */
    name: Scalars['String']
    /** The date and time for the next run of the campaign */
    nextRun?: Maybe<Scalars['ISO8601DateTime']>
    /** The phishing domain associated with the campaign */
    phishDomain?: Maybe<PhishDomain>
    /** The difficulty levels available for the phishing campaign */
    rating: Array<EmailTemplateDifficultyRatings>
    /** The domain prefix for replies in the phishing campaign */
    replyDomainPrefix?: Maybe<Scalars['String']>
    /** The phishing domain used for replies */
    replyPhishDomain?: Maybe<PhishDomain>
    /** The runs of the phishing campaign */
    runs: Array<PhishingCampaignRun>
    /** The groups selected for the phishing campaign */
    selectedGroups: Array<Scalars['Int']>
    /** The template topics selected for the campaign */
    selectedTemplateTopics?: Maybe<Scalars['JSON']>
    /** Determines if an email is sent after a campaign run */
    sendEmailAfterRun: Scalars['Boolean']
    /** The duration for sending emails in the phishing campaign */
    sendingDuration?: Maybe<Scalars['Int']>
    /** The units for the sending duration */
    sendingDurationUnits?: Maybe<Durations>
    /** Determines if emails are spread over the sending duration */
    spreadEmails?: Maybe<Scalars['Boolean']>
    startedWithin24Hours: Scalars['Boolean']
    /** Determines if the full callback phone number is stored */
    storeFullCallbackPhoneNumber: Scalars['Boolean']
    /** Determines if replies' content should be stored */
    storeReplyContent: Scalars['Boolean']
    /** The categories of email templates available for the phishing campaign */
    templateCategories: Array<PhishingTemplateCategory>
    /** Template topics available for the campaign */
    templateTopics?: Maybe<Array<TemplateTopic>>
    /** The timezone used for scheduling the phishing campaign */
    timeZone: TimeZones
    /** Determines if out-of-office replies are tracked */
    trackOutOfOfficeReplies: Scalars['Boolean']
    /** Determines if replies are tracked */
    trackReplies: Scalars['Boolean']
    /** The duration for tracking emails in the phishing campaign */
    trackingDuration: Scalars['Int']
    /** The units for the tracking duration */
    trackingDurationUnits: Durations
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type PhishingCampaignAttributes = {
  /** The business days of the phishing campaign */
  businessDays?: Maybe<Array<Scalars['Int']>>
  /** The business hours end time of the campaign */
  businessHoursEndHour?: Maybe<Scalars['Time']>
  /** The business hours start time of the phishing campaign */
  businessHoursStartHour?: Maybe<Scalars['Time']>
  /** The callback phishing greeting template translation UUID */
  callbackPhishingGreetingTemplateTranslationUuid?: Maybe<Scalars['String']>
  /** The callback phishing phone number region */
  callbackPhishingPhoneNumberRegion?: Maybe<Scalars['String']>
  /** The clicked group ID */
  clickedGroupId?: Maybe<Scalars['Int']>
  /** The email template ID */
  emailTemplateId?: Maybe<Scalars['Int']>
  /** The frequency period of the phishing campaign */
  frequencyPeriod?: Maybe<Frequencies>
  /** Whether to hide the phishing campaign from reports */
  hideFromReports?: Maybe<Scalars['Boolean']>
  /** The landing page ID */
  landingPageId?: Maybe<Scalars['Int']>
  /** Whether the phishing campaign is localized */
  localizedCampaign?: Maybe<Scalars['Boolean']>
  /** The name of the phishing campaign */
  name?: Maybe<Scalars['String']>
  /** The next run date of the phishing campaign */
  nextRunDate?: Maybe<Scalars['ISO8601Date']>
  /** The next run time of the phishing campaign */
  nextRunTime?: Maybe<Scalars['Time']>
  /** The phish domain ID */
  phishDomainId?: Maybe<Scalars['Int']>
  /** The sophistication of the phishing campaign */
  rating?: Maybe<Array<EmailTemplateDifficultyRatings>>
  /** The reply domain prefix */
  replyDomainPrefix?: Maybe<Scalars['String']>
  /** The reply phish domain ID */
  replyPhishDomainId?: Maybe<Scalars['Int']>
  /** The groups to send the phishing campaign to */
  selectedGroups?: Maybe<Array<Scalars['Int']>>
  /** The selected phishing template categories */
  selectedTemplateCategories?: Maybe<Array<Scalars['Int']>>
  /** The selected phishing template topics */
  selectedTemplateTopics?: Maybe<Scalars['JSON']>
  /** Whether to send an email after the run */
  sendEmailAfterRun?: Maybe<Scalars['Boolean']>
  /** The sending duration of the phishing campaign */
  sendingDuration?: Maybe<Scalars['Int']>
  /** The sending duration units of the phishing campaign */
  sendingDurationUnits?: Maybe<Durations>
  /** Whether to spread the emails */
  spreadEmails?: Maybe<Scalars['Boolean']>
  /** Whether to store the full callback phone number */
  storeFullCallbackPhoneNumber?: Maybe<Scalars['Boolean']>
  /** Whether to store reply content */
  storeReplyContent?: Maybe<Scalars['Boolean']>
  /** The time zone of the phishing campaign */
  timeZone?: Maybe<TimeZones>
  /** Whether to track out-of-office replies */
  trackOutOfOfficeReplies?: Maybe<Scalars['Boolean']>
  /** Whether to track replies */
  trackReplies?: Maybe<Scalars['Boolean']>
  /** The tracking duration of the phishing campaign */
  trackingDuration?: Maybe<Scalars['Int']>
  /** The tracking duration units of the phishing campaign */
  trackingDurationUnits?: Maybe<Durations>
}

/** A phishing campaign clicker */
export type PhishingCampaignClicker = {
  __typename?: 'PhishingCampaignClicker'
  /** The total number of clicks made by the user on phishing campaign links */
  clicks: Scalars['Int']
  /** The user who clicked on the phishing campaign link */
  user: User
}

/** A phishing campaign recipient failure Detail */
export type PhishingCampaignFailureDetails = {
  __typename?: 'PhishingCampaignFailureDetails'
  /** The date when the failure occurred in the phishing campaign */
  date: Scalars['ISO8601DateTime']
  /** The type of failure encountered in the phishing campaign */
  type: Scalars['String']
}

export enum PhishingCampaignFilters {
  Active = 'ACTIVE',
  All = 'ALL',
  Hidden = 'HIDDEN',
  Inactive = 'INACTIVE',
  Phishflip = 'PHISHFLIP',
}

/** Common fields between phishing campaigns and managed phishing campaigns */
export type PhishingCampaignInterface = {
  /** Indicates if the phishing campaign is active */
  active: Scalars['Boolean']
  /** Indicates if the AIDA template is selected */
  aidaSelectedTemplate?: Maybe<Scalars['Boolean']>
  /** The email template used in the phishing campaign */
  emailTemplate?: Maybe<PhishingTemplate>
  /** The frequency period for the phishing campaign */
  frequencyPeriod: Frequencies
  /** Indicates if email templates are fully randomized */
  fullRandom?: Maybe<Scalars['Boolean']>
  /** Determines if the phishing campaign should be hidden from reports */
  hideFromReports: Scalars['Boolean']
  /** The landing page used in the phishing campaign */
  landingPage?: Maybe<LandingPage>
  localizedCampaign?: Maybe<Scalars['Boolean']>
  /** The name of the phishing campaign */
  name: Scalars['String']
  /** The date and time for the next run of the phishing campaign */
  nextRun?: Maybe<Scalars['ISO8601DateTime']>
  /** The phishing domain associated with the phishing campaign */
  phishDomain?: Maybe<PhishDomain>
  /** The difficulty levels available for the phishing campaign */
  rating: Array<EmailTemplateDifficultyRatings>
  /** The domain prefix for replies in the phishing campaign */
  replyDomainPrefix?: Maybe<Scalars['String']>
  /** The phishing domain used for replies */
  replyPhishDomain?: Maybe<PhishDomain>
  /** Determines if an email is sent after a phishing campaign run */
  sendEmailAfterRun: Scalars['Boolean']
  /** The duration for sending emails in the phishing campaign */
  sendingDuration?: Maybe<Scalars['Int']>
  /** The units for the sending duration */
  sendingDurationUnits?: Maybe<Durations>
  /** Determines if emails are spread over the sending duration */
  spreadEmails?: Maybe<Scalars['Boolean']>
  /** Determines if the content for replies should be stored */
  storeReplyContent: Scalars['Boolean']
  /** The categories of email templates available for the phishing campaign */
  templateCategories: Array<PhishingTemplateCategory>
  /** The time zone used for scheduling the phishing campaign */
  timeZone: TimeZones
  /** Determines if out-of-office replies are tracked */
  trackOutOfOfficeReplies: Scalars['Boolean']
  /** Determines if replies are tracked */
  trackReplies: Scalars['Boolean']
  /** The duration for tracking emails in the phishing campaign */
  trackingDuration: Scalars['Int']
  /** The units for the tracking duration */
  trackingDurationUnits: Durations
}

/** A phishing campaign non-recipient */
export type PhishingCampaignNonRecipient = Identifier &
  Timestamps & {
    __typename?: 'PhishingCampaignNonRecipient'
    /** The specific campaign run associated with this non-recipient entry */
    campaignRun: PhishingCampaignRun
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The reason the user was not a recipient of the phishing campaign */
    reason?: Maybe<Scalars['String']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The user who was not a recipient of the phishing campaign */
    user: User
  }

export type PhishingCampaignNonRecipientsCursor = {
  __typename?: 'PhishingCampaignNonRecipientsCursor'
  /** The paginated nodes */
  nodes: Array<PhishingCampaignNonRecipient>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** A phishing campaign recipient */
export type PhishingCampaignRecipient = Timestamps & {
  __typename?: 'PhishingCampaignRecipient'
  /** The date and time when the recipient opened an attachment in the PST */
  attachmentOpen?: Maybe<Scalars['ISO8601DateTime']>
  /** Type of attachment, if any, included in the PST */
  attachmentType?: Maybe<Extensions>
  /** Reason why the PST was bounced or not delivered to this recipient */
  bounceReason?: Maybe<Scalars['String']>
  /** The date and time when the PST was bounced or not delivered to the recipient */
  bounced?: Maybe<Scalars['ISO8601DateTime']>
  /** The browser used by the recipient to interact with the PST */
  browser?: Maybe<Scalars['String']>
  /** Version of the browser used by the recipient */
  browserVersion?: Maybe<Scalars['String']>
  /** The date and time when the recipient entered data during a callback phishing campaign */
  callbackDataEntered?: Maybe<Scalars['ISO8601DateTime']>
  /** The date and time when the recipient made a call based on instructions from the PST */
  called?: Maybe<Scalars['ISO8601DateTime']>
  /** DMI for the campaign recipient */
  campaignRecipientDmi?: Maybe<PhishingCampaignRecipientDmi>
  /** The specific run of a PST that includes this recipient */
  campaignRun: PhishingCampaignRun
  /** The date and time when the recipient clicked on a link in the PST */
  clicked?: Maybe<Scalars['ISO8601DateTime']>
  /** The number of times the recipient clicked on links in the PST */
  clickedCount?: Maybe<Scalars['Int']>
  /** The timestamp when the record was created */
  createdAt: Scalars['ISO8601DateTime']
  /** The date and time when the recipient entered data into a form or webpage from the PST */
  dataEntered?: Maybe<Scalars['ISO8601DateTime']>
  /** The date and time when the PST was delivered to the recipient */
  delivered?: Maybe<Scalars['ISO8601DateTime']>
  deliveredAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Display formatted IP address of the recipient */
  displayIp?: Maybe<Scalars['String']>
  /** The date and time when the PST was dropped or not delivered to the recipient */
  dropped?: Maybe<Scalars['ISO8601DateTime']>
  /** Email address of the phishing campaign recipient */
  email?: Maybe<Scalars['String']>
  /** The template used for the PST sent to this recipient */
  emailTemplate: PhishingTemplate
  /** ID of the email template used for the PST */
  emailTemplateId: Scalars['Int']
  /** Details of any delivery failures for the PST recipient */
  failureDetails?: Maybe<Array<PhishingCampaignFailureDetails>>
  /** Unique identifier for the object */
  id: Scalars['CoercedBigInt']
  /** IP address of the recipient when they interacted with the PST */
  ipAddress?: Maybe<Scalars['String']>
  /** Geographical location of the recipient’s IP address */
  ipLocation?: Maybe<Scalars['String']>
  /** The date and time when the recipient enabled macros in a document from the PST */
  macroEnabled?: Maybe<Scalars['ISO8601DateTime']>
  /** The date and time when the recipient opened the PST */
  opened?: Maybe<Scalars['ISO8601DateTime']>
  /** Operating system of the recipient’s device */
  os?: Maybe<Scalars['String']>
  /** The date and time when the recipient processed the PST */
  processed?: Maybe<Scalars['ISO8601DateTime']>
  /** The date and time when the recipient scanned a QR code from the PST */
  qrCodeScanned?: Maybe<Scalars['ISO8601DateTime']>
  /** The date and time when the recipient replied to the PST */
  replied?: Maybe<Scalars['ISO8601DateTime']>
  /** Content of any reply from the recipient to the PST */
  replyContent?: Maybe<ReplyContent>
  /** The date and time when the recipient reported the PST */
  reported?: Maybe<Scalars['ISO8601DateTime']>
  /** The scheduled time for the PST delivery */
  scheduledAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The timestamp when the record was last updated */
  updatedAt: Scalars['ISO8601DateTime']
  /** User associated with this campaign recipient */
  user: User
}

export type PhishingCampaignRecipientAttributes = {
  /** The Attachment open status */
  attachmentOpen?: Maybe<Scalars['ISO8601DateTime']>
  /** The Callback data entered status */
  callbackDataEntered?: Maybe<Scalars['ISO8601DateTime']>
  /** The Called status */
  called?: Maybe<Scalars['ISO8601DateTime']>
  /** The Clicked status */
  clicked?: Maybe<Scalars['ISO8601DateTime']>
  /** The Data entered status */
  dataEntered?: Maybe<Scalars['ISO8601DateTime']>
  /** The Macro enabled status */
  macroEnabled?: Maybe<Scalars['ISO8601DateTime']>
  /** The Qr code scanned status */
  qrCodeScanned?: Maybe<Scalars['ISO8601DateTime']>
  /** The Replied status */
  replied?: Maybe<Scalars['ISO8601DateTime']>
}

/** A phishing campaign recipient DMI */
export type PhishingCampaignRecipientDmi = Identifier &
  Timestamps & {
    __typename?: 'PhishingCampaignRecipientDmi'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The datetime when the recipient received the campaign */
    receivedAt: Scalars['ISO8601DateTime']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type PhishingCampaignRecipientsCursor = {
  __typename?: 'PhishingCampaignRecipientsCursor'
  /** The paginated nodes */
  nodes: Array<PhishingCampaignRecipient>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** A phishing campaign */
export type PhishingCampaignRun = Identifier &
  Timestamps & {
    __typename?: 'PhishingCampaignRun'
    /** Indicator if an AIDA template is used */
    aidaSelectedTemplate?: Maybe<Scalars['Boolean']>
    /** The greeting template for callback phishing used in the campaign */
    callbackPhishingGreetingTemplate?: Maybe<CallbackPhishingGreetingTemplate>
    /** The callback phishing phone number used in the phishing campaign */
    callbackPhishingPhoneNumber?: Maybe<CallbackPhishingPhoneNumber>
    /** The response template for callback phishing used in the campaign */
    callbackPhishingResponseTemplate?: Maybe<CallbackPhishingResponseTemplate>
    /** The campaign associated with this phishing campaign */
    campaign: PhishingCampaign
    /** The delivery methods used for the phishing campaign */
    campaignDeliveryMethods?: Maybe<Array<CampaignDeliveryMethod>>
    /** The list of non-recipients for the phishing campaign */
    campaignNonRecipients?: Maybe<Array<PhishingCampaignNonRecipient>>
    /** The count of non-recipients for the phishing campaign */
    campaignNonRecipientsCount?: Maybe<Scalars['Int']>
    /** The list of recipients involved in the phishing campaign */
    campaignRecipients: Array<PhishingCampaignRecipient>
    /** The completion time of the phishing campaign */
    completedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The custom reply-to address used in this phishing campaign */
    customReplyTo?: Maybe<Scalars['String']>
    /** The duration of the phishing campaign */
    duration: Scalars['Int']
    /** The ID of the email template used in this phishing campaign */
    emailTemplateId?: Maybe<Scalars['Int']>
    /** Indicator if full random template selection is applied */
    fullRandomTemplate?: Maybe<Scalars['Boolean']>
    /** The name of the groups targeted in the phishing campaign */
    groupNames: Scalars['String']
    /** The list of groups associated with the phishing campaign */
    groups: Array<Group>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The landing page used for the phishing campaign */
    landingPage?: Maybe<LandingPage>
    /** A preview of the landing page used in the phishing campaign */
    landingPagePreview: Scalars['String']
    /** The output of the phishing campaign */
    output?: Maybe<Scalars['String']>
    /** The phishing domain used in this campaign */
    phishDomain?: Maybe<Scalars['String']>
    /** The percentage of users who fell for the PST */
    phishPronePercentage: Scalars['Float']
    /** The template used for phishing in this campaign */
    phishingTemplate?: Maybe<PhishingTemplate>
    /** The number of recipients targeted in the phishing campaign */
    recipientCount: Scalars['Int']
    /** The prefix of the reply domain used in this phishing campaign */
    replyDomainPrefix?: Maybe<Scalars['String']>
    /** The ID of the reply phishing domain used in this campaign */
    replyPhishDomainId?: Maybe<Scalars['Int']>
    /** The number of scheduled emails for the phishing campaign */
    scheduledCount?: Maybe<Scalars['Int']>
    /** The duration taken to send all the emails for the phishing campaign */
    sendingDuration: Scalars['Int']
    /** The start time of the phishing campaign */
    startedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The current status of the phishing campaign */
    status: PhishingCampaignRunStatuses
    /** Indicator whether the reply content is stored */
    storeReplyContent?: Maybe<Scalars['Boolean']>
    /** The total number of attachment opens in the phishing campaign */
    totalAttachmentOpen: Scalars['Int']
    /** The total number of bounces in the phishing campaign */
    totalBounced: Scalars['Int']
    /** The total number of callback data entries in the phishing campaign */
    totalCallbackDataEntered: Scalars['Int']
    /** The total number of calls in the phishing campaign */
    totalCalled: Scalars['Int']
    /** The total number of clicks in the phishing campaign */
    totalClicked: Scalars['Int']
    /** The total number of data entries in the phishing campaign */
    totalDataEntered: Scalars['Int']
    /** The total number of deliveries in the phishing campaign */
    totalDelivered: Scalars['Int']
    /** The total number of drops in the phishing campaign */
    totalDropped: Scalars['Int']
    /** The total number of failures in the phishing campaign */
    totalFailed: Scalars['Int']
    /** The total number of macro enabled in the phishing campaign */
    totalMacroEnabled: Scalars['Int']
    /** The total number of opens in the phishing campaign */
    totalOpened: Scalars['Int']
    /** The total number of QR code scans in the phishing campaign */
    totalQrCodeScanned: Scalars['Int']
    /** The total number of replies in the phishing campaign */
    totalReplied: Scalars['Int']
    /** The total number of reports in the phishing campaign */
    totalReported: Scalars['Int']
    /** The total number of scheduled emails in the phishing campaign */
    totalScheduled: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export enum PhishingCampaignRunStatuses {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Created = 'CREATED',
  Error = 'ERROR',
  Scheduling = 'SCHEDULING',
}

/** Autogenerated return type of PhishingCampaignRunsBulkChange. */
export type PhishingCampaignRunsBulkChangePayload = {
  __typename?: 'PhishingCampaignRunsBulkChangePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated phishing campaign run */
  node?: Maybe<Scalars['Boolean']>
}

export type PhishingCampaignRunsCursor = {
  __typename?: 'PhishingCampaignRunsCursor'
  /** The paginated nodes */
  nodes: Array<PhishingCampaignRun>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of PhishingCampaignRunsDelete. */
export type PhishingCampaignRunsDeletePayload = {
  __typename?: 'PhishingCampaignRunsDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The deleted phishing campaign run */
  node?: Maybe<PhishingCampaignRun>
}

/** Autogenerated return type of PhishingCampaignRunsStop. */
export type PhishingCampaignRunsStopPayload = {
  __typename?: 'PhishingCampaignRunsStopPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The stopped phishing campaign run */
  node?: Maybe<PhishingCampaignRun>
}

export enum PhishingCampaignSortFields {
  Id = 'ID',
}

export enum PhishingCampaignTypes {
  All = 'ALL',
  Callback = 'CALLBACK',
  PhishFlip = 'PHISH_FLIP',
  Standard = 'STANDARD',
}

/** Autogenerated return type of PhishingCampaignsCreate. */
export type PhishingCampaignsCreatePayload = {
  __typename?: 'PhishingCampaignsCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created phishing campaign */
  node?: Maybe<PhishingCampaign>
}

export type PhishingCampaignsCursor = {
  __typename?: 'PhishingCampaignsCursor'
  /** The paginated nodes */
  nodes: Array<PhishingCampaign>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of PhishingCampaignsDeactivate. */
export type PhishingCampaignsDeactivatePayload = {
  __typename?: 'PhishingCampaignsDeactivatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated phishing campaign */
  node?: Maybe<PhishingCampaign>
}

/** Autogenerated return type of PhishingCampaignsDelete. */
export type PhishingCampaignsDeletePayload = {
  __typename?: 'PhishingCampaignsDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The deleted phishing campaign */
  node?: Maybe<PhishingCampaign>
}

/** Autogenerated return type of PhishingCampaignsEdit. */
export type PhishingCampaignsEditPayload = {
  __typename?: 'PhishingCampaignsEditPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated phishing campaign */
  node?: Maybe<PhishingCampaign>
}

/** Autogenerated return type of PhishingCampaignsHideFromReports. */
export type PhishingCampaignsHideFromReportsPayload = {
  __typename?: 'PhishingCampaignsHideFromReportsPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated phishing campaign */
  node?: Maybe<PhishingCampaign>
}

/** Autogenerated return type of PhishingCampaignsRecipientRemoveReply. */
export type PhishingCampaignsRecipientRemoveReplyPayload = {
  __typename?: 'PhishingCampaignsRecipientRemoveReplyPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated phishing campaign recipient */
  node?: Maybe<PhishingCampaignRecipient>
}

/** Autogenerated return type of PhishingCampaignsRecipientUpdate. */
export type PhishingCampaignsRecipientUpdatePayload = {
  __typename?: 'PhishingCampaignsRecipientUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated phishing campaign recipient */
  node?: Maybe<PhishingCampaignRecipient>
}

export type PhishingSecurityTests = {
  __typename?: 'PhishingSecurityTests'
  /** CSV format of the Phishing Security Test report */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

/** Email templates for phishing */
export type PhishingTemplate = EmailTemplateInterface &
  Identifier &
  Timestamps & {
    __typename?: 'PhishingTemplate'
    /** Settings for the AIDA template */
    aidaPhishingTemplateSettings?: Maybe<AidaPhishingTemplateSettings>
    /** Indicates if the email template is archived */
    archived: Scalars['Boolean']
    /** The filename of any attachment included with the phishing template */
    attachmentFilename?: Maybe<Scalars['String']>
    /** The type of attachment included with the phishing template */
    attachmentType?: Maybe<Extensions>
    auditLogDisplay?: Maybe<Scalars['String']>
    auditLogName?: Maybe<Scalars['String']>
    /** The category of the phishing template */
    category?: Maybe<PhishingTemplateCategory>
    /** The HTML content of the email template */
    contentHtml?: Maybe<Scalars['String']>
    contentHtmlSanitized?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Indication of whether the template is excluded from use */
    excluded: Scalars['Boolean']
    /** The from address of the email template */
    from: Scalars['String']
    /** The display name of the from address of the email template */
    fromDisplayName?: Maybe<Scalars['String']>
    fromDisplayNameSanitized?: Maybe<Scalars['String']>
    fromSanitized: Scalars['String']
    /** The from address of the email template with the domain */
    fromWithDomain: Scalars['String']
    /** Indicates if the email template is hidden */
    hidden: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Indication of whether the template is an AIDA template */
    isAida?: Maybe<Scalars['Boolean']>
    /** The ID of the landing domain associated with the phishing template */
    landingDomainId?: Maybe<Scalars['Int']>
    /** The ID of the landing page associated with the phishing template */
    landingPageId?: Maybe<Scalars['Int']>
    /** The language code of the phishing template */
    languageCode?: Maybe<Scalars['String']>
    markedAsUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The name of the email template */
    name?: Maybe<Scalars['String']>
    /** The difficulty rating of the phishing template */
    rating?: Maybe<EmailTemplateDifficultyRatings>
    ratingRaw?: Maybe<Scalars['Int']>
    /** The email address that recipients are instructed to reply to */
    replyTo?: Maybe<Scalars['String']>
    /** The display name for the reply-to email address */
    replyToDisplayName?: Maybe<Scalars['String']>
    replyToDisplayNameSanitized?: Maybe<Scalars['String']>
    replyToSanitized?: Maybe<Scalars['String']>
    /** The subject of the email template */
    subject: Scalars['String']
    subjectSanitized: Scalars['String']
    /** The UUID for the email template translation */
    templateTranslationUuid: Scalars['String']
    /** A list of topics associated with the phishing template */
    topics?: Maybe<Array<TemplateTopic>>
    /** Translations for the phishing template */
    translations?: Maybe<Array<PhishingTemplateTranslation>>
    /** The type of phishing template */
    type: PhishingTemplateTypes
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type PhishingTemplateAttributes = {
  /** The settings for the AIDA phishing template */
  aidaPhishingTemplateSettingsAttributes?: Maybe<AidaPhishingTemplateSettingsAttributes>
  /** The filename of the attachment */
  attachmentFilename?: Maybe<Scalars['String']>
  /** The type of attachment */
  attachmentType?: Maybe<Extensions>
  /** The HTML content of the email */
  contentHtml: Scalars['String']
  /** The email address to send the email from */
  from: Scalars['String']
  /** The display name of the sender */
  fromDisplayName?: Maybe<Scalars['String']>
  /** Whether the phishing template is an AIDA phishing template */
  isAida?: Maybe<Scalars['Boolean']>
  /** The ID of the landing domain to use */
  landingDomainId?: Maybe<Scalars['Int']>
  /** The ID of the landing page to use */
  landingPageId?: Maybe<Scalars['Int']>
  /** The language code of the email */
  languageCode?: Maybe<Scalars['String']>
  /** The name of the template */
  name?: Maybe<Scalars['String']>
  /** The difficulty rating of the email */
  rating?: Maybe<EmailTemplateDifficultyRatings>
  /** The email address to send replies to */
  replyTo?: Maybe<Scalars['String']>
  /** The display name of the reply-to */
  replyToDisplayName?: Maybe<Scalars['String']>
  /** The subject of the email */
  subject: Scalars['String']
}

/** An unpaginated list of phishing template categories */
export type PhishingTemplateCategories = {
  __typename?: 'PhishingTemplateCategories'
  /** A list of phishing template categories */
  nodes: Array<PhishingTemplateCategory>
}

/** Category object to filter email template nodes */
export type PhishingTemplateCategory = Identifier &
  Timestamps & {
    __typename?: 'PhishingTemplateCategory'
    auditLogDisplay?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Indicates if the phishing template category is hidden */
    hidden?: Maybe<Scalars['Boolean']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The name of the phishing template category */
    name: Scalars['String']
    /** Ordering preference of the phishing template category */
    ordering?: Maybe<Scalars['Int']>
    /** The count of templates within this phishing template category */
    templateCount?: Maybe<Scalars['Int']>
    translationKey?: Maybe<Scalars['String']>
    /** The type of phishing template category based on predefined types */
    type: PhishingTemplateCategoryTypes
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

/** Category object to filter email template nodes */
export type PhishingTemplateCategoryTemplateCountArgs = {
  showExcluded?: Maybe<Scalars['Boolean']>
  showHidden?: Maybe<Scalars['Boolean']>
}

export type PhishingTemplateCategoryAttributes = {
  /** The name of the category */
  name: Scalars['String']
}

/** Phishing template category recommendation */
export type PhishingTemplateCategoryRecommendation = {
  __typename?: 'PhishingTemplateCategoryRecommendation'
  /** The phishing template category */
  phishingTemplateCategory: PhishingTemplateCategory
  /** The count of phishing template matches */
  phishingTemplateMatchCount: Scalars['Int']
}

export enum PhishingTemplateCategoryTypes {
  Community = 'COMMUNITY',
  Managed = 'MANAGED',
  System = 'SYSTEM',
  User = 'USER',
}

/** Email templates for phishing */
export type PhishingTemplateDrafts = {
  __typename?: 'PhishingTemplateDrafts'
  /** The count of phishing template drafts */
  count: Scalars['Int']
  /** The total count of phishing template drafts available */
  totalCount: Scalars['Int']
}

/** Phishing template translation */
export type PhishingTemplateTranslation = {
  __typename?: 'PhishingTemplateTranslation'
  /** The ID of the phishing template translation */
  id: Scalars['Int']
  /** Language code of the phishing template translation */
  languageCode: Scalars['String']
  /** UUID for the phishing template translation */
  uuid: Scalars['String']
}

export enum PhishingTemplateTypes {
  CallbackPhishing = 'CALLBACK_PHISHING',
  CallbackPhishingSystem = 'CALLBACK_PHISHING_SYSTEM',
  Community = 'COMMUNITY',
  Managed = 'MANAGED',
  System = 'SYSTEM',
  User = 'USER',
}

export type PhishingTemplatesAsapCursor = {
  __typename?: 'PhishingTemplatesAsapCursor'
  /** The paginated nodes */
  nodes: Array<PhishingTemplate>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of PhishingTemplatesCategoryCreate. */
export type PhishingTemplatesCategoryCreatePayload = {
  __typename?: 'PhishingTemplatesCategoryCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created phishing template category */
  node?: Maybe<PhishingTemplateCategory>
}

/** Autogenerated return type of PhishingTemplatesCategoryDelete. */
export type PhishingTemplatesCategoryDeletePayload = {
  __typename?: 'PhishingTemplatesCategoryDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The deleted phishing template category */
  node?: Maybe<PhishingTemplateCategory>
}

/** Autogenerated return type of PhishingTemplatesCategoryHide. */
export type PhishingTemplatesCategoryHidePayload = {
  __typename?: 'PhishingTemplatesCategoryHidePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The phishing template category that was hidden */
  node?: Maybe<PhishingTemplateCategory>
}

/** Autogenerated return type of PhishingTemplatesCategoryUpdate. */
export type PhishingTemplatesCategoryUpdatePayload = {
  __typename?: 'PhishingTemplatesCategoryUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated phishing template category */
  node?: Maybe<PhishingTemplateCategory>
}

/** Autogenerated return type of PhishingTemplatesCopy. */
export type PhishingTemplatesCopyPayload = {
  __typename?: 'PhishingTemplatesCopyPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The copied phishing templates */
  nodes?: Maybe<Array<PhishingTemplate>>
}

/** Autogenerated return type of PhishingTemplatesCreate. */
export type PhishingTemplatesCreatePayload = {
  __typename?: 'PhishingTemplatesCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created phishing template */
  node?: Maybe<PhishingTemplate>
}

export type PhishingTemplatesCursor = {
  __typename?: 'PhishingTemplatesCursor'
  /** The paginated nodes */
  nodes: Array<PhishingTemplate>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of PhishingTemplatesHide. */
export type PhishingTemplatesHidePayload = {
  __typename?: 'PhishingTemplatesHidePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The phishing template that was hidden */
  node?: Maybe<PhishingTemplate>
}

/** Autogenerated return type of PhishingTemplatesImport. */
export type PhishingTemplatesImportPayload = {
  __typename?: 'PhishingTemplatesImportPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The imported phishing templates */
  nodes?: Maybe<Array<PhishingTemplate>>
}

/** Autogenerated return type of PhishingTemplatesTopicsAdd. */
export type PhishingTemplatesTopicsAddPayload = {
  __typename?: 'PhishingTemplatesTopicsAddPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The result of the mutation */
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of PhishingTemplatesTopicsRemove. */
export type PhishingTemplatesTopicsRemovePayload = {
  __typename?: 'PhishingTemplatesTopicsRemovePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The result of the mutation */
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of PhishingTemplatesUpdate. */
export type PhishingTemplatesUpdatePayload = {
  __typename?: 'PhishingTemplatesUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated phishing template */
  node?: Maybe<PhishingTemplate>
}

/** Physical QR code campaign */
export type PhysicalQrCampaign = Identifier &
  Timestamps & {
    __typename?: 'PhysicalQrCampaign'
    auditLogDisplay?: Maybe<Scalars['String']>
    /** The completion time of the QR code campaign */
    completedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The geolocation information for the QR campaign. Uses USB campaign schema for compatibility. */
    geoLocation?: Maybe<UsbCampaignGeoLocation>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The ID of the landing page associated with the QR code campaign */
    landingPageId?: Maybe<Scalars['Int']>
    /** The name of the QR code campaign */
    name: Scalars['String']
    /** Notes associated with the QR code campaign */
    notes?: Maybe<Scalars['String']>
    /** The ID of the phishing domain associated with the QR code campaign */
    phishDomainId?: Maybe<Scalars['Int']>
    /** A single physical QR code campaign location */
    physicalQrCampaignLocation?: Maybe<PhysicalQrCampaignLocation>
    /** The count of physical QR code campaign locations */
    physicalQrCampaignLocationCount: Scalars['Int']
    /** A list of physical QR code campaign locations */
    physicalQrCampaignLocations: Array<PhysicalQrCampaignLocation>
    /** The start time of the QR code campaign */
    startedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The status of the QR code campaign */
    status: PhysicalQrCampaignStatuses
    /** UUID of the template translation used in the QR code campaign */
    templateTranslationUuid: Scalars['String']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

/** Physical QR code campaign */
export type PhysicalQrCampaignPhysicalQrCampaignLocationArgs = {
  locationId?: Maybe<Scalars['Int']>
}

export type PhysicalQrCampaignAttributes = {
  /** The ID of the landing page */
  landingPageId?: Maybe<Scalars['Int']>
  /** The name of the physical QR code campaign */
  name?: Maybe<Scalars['String']>
  /** The notes of the physical QR code campaign */
  notes?: Maybe<Scalars['String']>
  /** The ID of the phish domain */
  phishDomainId?: Maybe<Scalars['Int']>
  /** The UUID of the template translation */
  templateTranslationUuid?: Maybe<Scalars['String']>
}

/** Physical QR code campaign location */
export type PhysicalQrCampaignLocation = Identifier &
  Timestamps & {
    __typename?: 'PhysicalQrCampaignLocation'
    auditLogDisplay?: Maybe<Scalars['String']>
    /** HTML content associated with the physical QR code campaign location */
    contentHtml: Scalars['String']
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Location associated with the physical QR code campaign */
    location: Scalars['String']
    /** Optional notes regarding the physical QR code campaign location */
    notes?: Maybe<Scalars['String']>
    /** The associated physical QR code campaign */
    physicalQrCampaign: PhysicalQrCampaign
    physicalQrCampaignId?: Maybe<Scalars['Int']>
    /** Results gathered from the physical QR code campaign */
    physicalQrCampaignResults: Array<PhysicalQrCampaignResult>
    /** Total amount of data entered at this location */
    totalDataEntered: Scalars['Int']
    /** Total number of QR codes scanned at this location */
    totalQrCodeScanned: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type PhysicalQrCampaignLocationAttributes = {
  /** The location of the physical QR code campaign */
  location?: Maybe<Scalars['String']>
  /** Notes about the physical QR code campaign location */
  notes?: Maybe<Scalars['String']>
}

/** Autogenerated return type of PhysicalQrCampaignLocationsCreate. */
export type PhysicalQrCampaignLocationsCreatePayload = {
  __typename?: 'PhysicalQrCampaignLocationsCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created physical QR code campaign location */
  node?: Maybe<PhysicalQrCampaignLocation>
}

/** Autogenerated return type of PhysicalQrCampaignLocationsDelete. */
export type PhysicalQrCampaignLocationsDeletePayload = {
  __typename?: 'PhysicalQrCampaignLocationsDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The deleted physical QR code campaign location */
  node?: Maybe<PhysicalQrCampaign>
}

/** Autogenerated return type of PhysicalQrCampaignLocationsUpdate. */
export type PhysicalQrCampaignLocationsUpdatePayload = {
  __typename?: 'PhysicalQrCampaignLocationsUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated physical QR code campaign location */
  node?: Maybe<PhysicalQrCampaign>
}

/** Physical QR code campaign result */
export type PhysicalQrCampaignResult = Identifier &
  Timestamps & {
    __typename?: 'PhysicalQrCampaignResult'
    /** The browser used to scan the QR code */
    browser?: Maybe<Scalars['String']>
    /** The version of the browser used to scan the QR code */
    browserVersion?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Timestamp of when data was entered after scanning the QR code */
    dataEntered?: Maybe<Scalars['ISO8601DateTime']>
    /** The hostname of the device used to scan the QR code */
    hostname?: Maybe<Scalars['String']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The IP address from which the QR code was scanned */
    ipAddress?: Maybe<Scalars['String']>
    /** The geographical location associated with the IP address */
    ipLocation?: Maybe<Scalars['String']>
    /** Indicates whether the scan was made from a mobile device */
    mobile: Scalars['Boolean']
    /** The operating system of the device used to scan the QR code */
    os?: Maybe<Scalars['String']>
    /** The physical location related to the QR code campaign where the scan occurred */
    physicalQrCampaignLocation: PhysicalQrCampaignLocation
    /** The platform of the device used to scan the QR code */
    platform?: Maybe<Scalars['String']>
    /** Timestamp of when the QR code was scanned */
    qrCodeScanned?: Maybe<Scalars['ISO8601DateTime']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type PhysicalQrCampaignResultsCursor = {
  __typename?: 'PhysicalQrCampaignResultsCursor'
  /** The paginated nodes */
  nodes: Array<PhysicalQrCampaignResult>
  /** Information about the current paginated set */
  pagination: Pagination
}

export enum PhysicalQrCampaignResultsSortFields {
  Browser = 'BROWSER',
  BrowserVersion = 'BROWSER_VERSION',
  DataEntered = 'DATA_ENTERED',
  Hostname = 'HOSTNAME',
  IpAddress = 'IP_ADDRESS',
  IpLocation = 'IP_LOCATION',
  Mobile = 'MOBILE',
  Os = 'OS',
  Platform = 'PLATFORM',
  QrScanned = 'QR_SCANNED',
}

export enum PhysicalQrCampaignStatuses {
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Started = 'STARTED',
}

/** Autogenerated return type of PhysicalQrCampaignsCreate. */
export type PhysicalQrCampaignsCreatePayload = {
  __typename?: 'PhysicalQrCampaignsCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created physical QR code campaign */
  node?: Maybe<PhysicalQrCampaign>
}

export type PhysicalQrCampaignsCursor = {
  __typename?: 'PhysicalQrCampaignsCursor'
  /** The paginated nodes */
  nodes: Array<PhysicalQrCampaign>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of PhysicalQrCampaignsDelete. */
export type PhysicalQrCampaignsDeletePayload = {
  __typename?: 'PhysicalQrCampaignsDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The deleted physical QR code campaign */
  node?: Maybe<PhysicalQrCampaign>
}

/** Autogenerated return type of PhysicalQrCampaignsEndCampaign. */
export type PhysicalQrCampaignsEndCampaignPayload = {
  __typename?: 'PhysicalQrCampaignsEndCampaignPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated physical QR code campaign */
  node?: Maybe<PhysicalQrCampaign>
}

export enum PhysicalQrCampaignsSortFields {
  CompletedAt = 'COMPLETED_AT',
  Name = 'NAME',
  StartedAt = 'STARTED_AT',
}

/** Autogenerated return type of PhysicalQrCampaignsStartCampaign. */
export type PhysicalQrCampaignsStartCampaignPayload = {
  __typename?: 'PhysicalQrCampaignsStartCampaignPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated physical QR code campaign */
  node?: Maybe<PhysicalQrCampaign>
}

/** Autogenerated return type of PhysicalQrCampaignsUpdate. */
export type PhysicalQrCampaignsUpdatePayload = {
  __typename?: 'PhysicalQrCampaignsUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated physical QR code campaign */
  node?: Maybe<PhysicalQrCampaign>
}

/** Email templates for physical QR code */
export type PhysicalQrTemplate = Identifier &
  Timestamps & {
    __typename?: 'PhysicalQrTemplate'
    /** Indicates if the physical QR template is archived */
    archived: Scalars['Boolean']
    auditLogDisplay?: Maybe<Scalars['String']>
    /** The HTML content of the physical QR code template */
    contentHtml?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The ID of the landing domain associated with the physical QR code template */
    landingDomainId?: Maybe<Scalars['Int']>
    /** The ID of the landing page associated with the physical QR code template */
    landingPageId?: Maybe<Scalars['Int']>
    /** The language code of the physical QR code template */
    languageCode?: Maybe<Scalars['String']>
    markedAsUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The name of the physical QR code template */
    name?: Maybe<Scalars['String']>
    /** The difficulty rating of the email template */
    rating?: Maybe<EmailTemplateDifficultyRatings>
    /** The UUID of the template translation associated with the physical QR code template */
    templateTranslationUuid: Scalars['String']
    /** The topics associated with the physical QR code template */
    topics?: Maybe<Array<TemplateTopic>>
    /** The type of the physical QR code template */
    type: PhysicalQrTemplateTypes
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type PhysicalQrTemplateAttributes = {
  /** The HTML content of the physical QR code template */
  contentHtml: Scalars['String']
  /** The ID of the landing domain */
  landingDomainId?: Maybe<Scalars['Int']>
  /** The ID of the landing page */
  landingPageId?: Maybe<Scalars['Int']>
  /** The language code of the physical QR code template */
  languageCode?: Maybe<Scalars['String']>
  /** The name of the physical QR code template */
  name?: Maybe<Scalars['String']>
  /** The difficulty rating of the physical QR code template */
  rating?: Maybe<EmailTemplateDifficultyRatings>
}

export enum PhysicalQrTemplateSortFields {
  Difficulty = 'DIFFICULTY',
  Name = 'NAME',
  TopicName = 'TOPIC_NAME',
  Updated = 'UPDATED',
}

export enum PhysicalQrTemplateTypes {
  PhysicalQr = 'PHYSICAL_QR',
  PhysicalQrSystem = 'PHYSICAL_QR_SYSTEM',
}

/** Autogenerated return type of PhysicalQrTemplatesCopy. */
export type PhysicalQrTemplatesCopyPayload = {
  __typename?: 'PhysicalQrTemplatesCopyPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The copied phishing templates */
  nodes?: Maybe<Array<PhysicalQrTemplate>>
}

/** Autogenerated return type of PhysicalQrTemplatesCreate. */
export type PhysicalQrTemplatesCreatePayload = {
  __typename?: 'PhysicalQrTemplatesCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created physical QR code template */
  node?: Maybe<PhysicalQrTemplate>
}

export type PhysicalQrTemplatesCursor = {
  __typename?: 'PhysicalQrTemplatesCursor'
  /** The paginated nodes */
  nodes: Array<PhysicalQrTemplate>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of PhysicalQrTemplatesUpdate. */
export type PhysicalQrTemplatesUpdatePayload = {
  __typename?: 'PhysicalQrTemplatesUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated physical QR code template */
  node?: Maybe<PhysicalQrTemplate>
}

export type PiqDownloadMetadata =
  | PasswordIqCsvConfiguration
  | PasswordIqPdfConfiguration

/** PasswordIQ User Event type */
export type PiqEchelonEvent = EventTypeInterface & {
  __typename?: 'PiqEchelonEvent'
  description?: Maybe<Scalars['String']>
  /** The name associated with the detection event type */
  detectionName: PiqTimelineEventTypes
  id: Scalars['BigInt']
}

/** PasswordIQ metadata for an event */
export type PiqMetadata = {
  __typename?: 'PiqMetadata'
  /** The number of days since detection */
  days?: Maybe<Scalars['Int']>
  /** Indicates if the entity was detected */
  detected: Scalars['Boolean']
  /** Details about the detection */
  detection: Scalars['String']
  /** Indicates if this was detected during the initial scan */
  initialScan?: Maybe<Scalars['Boolean']>
}

export type PiqPaginationAttributes = {
  page: Scalars['Int']
  per: Scalars['Int']
}

export type PiqPdf = {
  dashboardId?: Maybe<Scalars['Int']>
}

export type PiqScheduleAttributes = {
  reset?: Maybe<Scalars['Boolean']>
  startTime: Scalars['String']
  weekdays: Array<Weekdays>
}

export enum PiqTimelineEventTypes {
  PiqAdPwClearText = 'piq_ad_pw_clear_text',
  PiqAdPwEmpty = 'piq_ad_pw_empty',
  PiqAdPwFoundInBreach = 'piq_ad_pw_found_in_breach',
  PiqAdPwNeverExpires = 'piq_ad_pw_never_expires',
  PiqAdPwNotReqd = 'piq_ad_pw_not_reqd',
  PiqAdPwShared = 'piq_ad_pw_shared',
  PiqAdPwWeak = 'piq_ad_pw_weak',
  PiqAdUserAesKeyMissing = 'piq_ad_user_aes_key_missing',
  PiqAdUserDesOnlyEncryption = 'piq_ad_user_des_only_encryption',
  PiqAdUserHasPreauthentication = 'piq_ad_user_has_preauthentication',
  PiqAdUserUsesLmHash = 'piq_ad_user_uses_lm_hash',
}

export enum PiqUserTypes {
  All = 'ALL',
  Kmsat = 'KMSAT',
  NonKmsat = 'NON_KMSAT',
}

export enum PiqWidget {
  PiqCountWidget = 'PiqCountWidget',
  PiqDetectionComparisonWidget = 'PiqDetectionComparisonWidget',
  PiqOvertimeWidget = 'PiqOvertimeWidget',
  PiqRedFlag = 'PiqRedFlag',
  PiqUserListWidget = 'PiqUserListWidget',
  PiqVarianceWidget = 'PiqVarianceWidget',
}

/** The placeholder account settings */
export type Placeholder = Identifier &
  Timestamps & {
    __typename?: 'Placeholder'
    /** Setting to auto-prepend email banner in phishing simulations */
    autoPrependEmailBannerPhishing: AutoPrependEmailBannerTypes
    /** Setting to auto-prepend subject prefix in phishing simulations */
    autoPrependSubjectPrefixPhishing: AutoPrependSubjectPrefixTypes
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The display name setting for emails */
    displayName?: Maybe<Scalars['String']>
    /** The custom email banner setting */
    emailBanner?: Maybe<Scalars['String']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The name format setting for email sender identities */
    nameFormat: NameFormatTypes
    /** The Phishing Reply Test sender email setting */
    prtSenderEmail?: Maybe<Scalars['String']>
    /** The Phishing Reply Test sender name setting */
    prtSenderName?: Maybe<Scalars['String']>
    /** The custom email signature block setting */
    signatureBlock?: Maybe<Scalars['String']>
    /** The custom subject prefix setting */
    subjectPrefix?: Maybe<Scalars['String']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type PlaceholderSettingsAttributes = {
  autoPrependEmailBannerPhishing?: Maybe<AutoPrependEmailBannerTypes>
  autoPrependSubjectPrefixPhishing?: Maybe<AutoPrependSubjectPrefixTypes>
  displayName?: Maybe<Scalars['String']>
  emailBanner?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  nameFormat?: Maybe<NameFormatTypes>
  prtSenderEmail?: Maybe<Scalars['String']>
  prtSenderName?: Maybe<Scalars['String']>
  signatureBlock?: Maybe<Scalars['String']>
  subjectPrefix?: Maybe<Scalars['String']>
}

/** Autogenerated return type of PoliciesAccept. */
export type PoliciesAcceptPayload = {
  __typename?: 'PoliciesAcceptPayload'
  node?: Maybe<EnrollmentBasic>
}

/** Autogenerated return type of PoliciesCreate. */
export type PoliciesCreatePayload = {
  __typename?: 'PoliciesCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created policy */
  node?: Maybe<Policy>
}

export type PoliciesCursor = {
  __typename?: 'PoliciesCursor'
  /** The paginated nodes */
  nodes: Array<Policy>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of PoliciesDelete. */
export type PoliciesDeletePayload = {
  __typename?: 'PoliciesDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The deleted policy */
  node?: Maybe<Policy>
}

export enum PoliciesSortFields {
  Id = 'ID',
  Status = 'STATUS',
  Title = 'TITLE',
  UpdatedAt = 'UPDATED_AT',
}

/** Autogenerated return type of PoliciesUpdateAcceptedPages. */
export type PoliciesUpdateAcceptedPagesPayload = {
  __typename?: 'PoliciesUpdateAcceptedPagesPayload'
  node?: Maybe<EnrollmentBasic>
}

/** Autogenerated return type of PoliciesUpdateAssetId. */
export type PoliciesUpdateAssetIdPayload = {
  __typename?: 'PoliciesUpdateAssetIdPayload'
  node?: Maybe<EnrollmentBasic>
}

/** Autogenerated return type of PoliciesUpdatePagesViewed. */
export type PoliciesUpdatePagesViewedPayload = {
  __typename?: 'PoliciesUpdatePagesViewedPayload'
  node?: Maybe<EnrollmentBasic>
}

/** Autogenerated return type of PoliciesUpdate. */
export type PoliciesUpdatePayload = {
  __typename?: 'PoliciesUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated policy */
  node?: Maybe<Policy>
}

/** Autogenerated return type of PoliciesUpdateTotalTime. */
export type PoliciesUpdateTotalTimePayload = {
  __typename?: 'PoliciesUpdateTotalTimePayload'
  node?: Maybe<EnrollmentBasic>
}

/** Policy */
export type Policy = Identifier &
  Timestamps & {
    __typename?: 'Policy'
    /** Indicates if the policy is archived */
    archived?: Maybe<Scalars['Boolean']>
    /** The type of assessment associated with the policy */
    assessmentType?: Maybe<AssessmentTypes>
    /** A single asset associated with the policy */
    asset?: Maybe<PolicyAsset>
    /** The type of asset associated with the policy */
    assetType: Scalars['String']
    /** A list of assets associated with the policy */
    assets: Array<PolicyAsset>
    auditLogDisplay?: Maybe<Scalars['String']>
    /** The completion percentage of the policy */
    completionPercentage?: Maybe<Scalars['Int']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The default language of the policy */
    defaultLanguage: Languages
    /** Indicates if the policy is deleted */
    deleted: Scalars['Boolean']
    /** The description of the policy */
    description?: Maybe<Scalars['String']>
    /** Indicates if the policy is downloadable */
    downloadable: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Indicates if the policy is deletable */
    isDeletable: Scalars['Boolean']
    /** The count of languages available for the policy */
    languagesCount: Scalars['Int']
    /** The minimum time required to complete the policy */
    minimumTime?: Maybe<Scalars['Int']>
    /** The number of pages in the policy document */
    pageCount?: Maybe<Scalars['Int']>
    /** The polymorphic ID of the policy */
    polymorphicId: Scalars['String']
    /** The publication date of the policy */
    publishedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** AIDA Policy Assets languages with published quiz */
    publishedLanguages: Array<Languages>
    /** Indicates if the policy is retired */
    retired?: Maybe<Scalars['Boolean']>
    /** Indicates if the policy will retire soon */
    retiringSoon?: Maybe<Scalars['Boolean']>
    /** The current status of the policy */
    status: PolicyStatuses
    /** The title of the policy */
    title: Scalars['String']
    /** A list of translations of the policy */
    translations: Array<Translation>
    /** The type of policy */
    type: PolicyTypes
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

/** Policy */
export type PolicyAssetArgs = {
  id?: Maybe<Scalars['Int']>
}

/** Policy */
export type PolicyCompletionPercentageArgs = {
  trainingCampaignId: Scalars['Int']
}

/** Policy language asset */
export type PolicyAsset = Identifier &
  Timestamps & {
    __typename?: 'PolicyAsset'
    /** The acceptance requirements for the policy asset */
    acceptanceRequirements: PolicyAssetAcceptances
    /** The text detailing the acceptance requirements */
    acceptanceText: Scalars['String']
    auditLogDisplay?: Maybe<Scalars['String']>
    auditLogTitle?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The description of the policy asset */
    description?: Maybe<Scalars['String']>
    /** The prompt for the document provided in the policy asset */
    documentPrompt?: Maybe<Scalars['String']>
    /** The file associated with the policy asset */
    file?: Maybe<PersistedFile>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The language in which the policy asset is provided */
    language: Languages
    /** The total number of pages in the policy asset */
    pageCount?: Maybe<Scalars['Int']>
    /** The prompt displayed on the policy asset page */
    pagePrompt?: Maybe<Scalars['String']>
    /** The unique ID of the policy */
    policyId?: Maybe<Scalars['Int']>
    /** The quiz associated with the policy asset */
    policyQuiz?: Maybe<PolicyQuiz>
    /** The title of the policy asset */
    title: Scalars['String']
    /** The type of policy asset */
    type: PolicyTypes
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The URL where the policy asset can be accessed */
    url?: Maybe<Scalars['String']>
  }

export enum PolicyAssetAcceptances {
  AllPages = 'ALL_PAGES',
  LastPage = 'LAST_PAGE',
}

export type PolicyAssetAttributes = {
  /** The acceptance requirements of the policy asset */
  acceptanceRequirements: PolicyAssetAcceptances
  /** The acceptance text of the policy asset */
  acceptanceText: Scalars['String']
  /** The language of the AIDA policy asset */
  aidaLanguage?: Maybe<AidaLanguages>
  /** The description of the policy asset */
  description?: Maybe<Scalars['String']>
  /** The document prompt of the policy asset */
  documentPrompt?: Maybe<Scalars['String']>
  /** The file of the policy asset */
  file?: Maybe<Scalars['File']>
  /** The ID of the policy asset */
  id?: Maybe<Scalars['BigInt']>
  /** The language of the policy asset */
  language: Languages
  /** The page prompt of the policy asset */
  pagePrompt?: Maybe<Scalars['String']>
  /** The title of the policy asset */
  title: Scalars['String']
  /** The type of the policy asset */
  type: PolicyTypes
  /** The updated at timestamp of the policy asset */
  updatedAt?: Maybe<Scalars['String']>
  /** The URL of the policy asset */
  url?: Maybe<Scalars['String']>
}

/** A basic policy asset for a trainee */
export type PolicyAssetBasic = Identifier & {
  __typename?: 'PolicyAssetBasic'
  /** The requirements for policy asset acceptance */
  acceptanceRequirements: PolicyAssetAcceptances
  /** The text describing the acceptance requirements */
  acceptanceText: Scalars['String']
  /** A prompt related to the policy document */
  documentPrompt?: Maybe<Scalars['String']>
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The number of pages in the policy document */
  pageCount?: Maybe<Scalars['Int']>
  /** A prompt related to a specific page in the policy document */
  pagePrompt?: Maybe<Scalars['String']>
  /** The title of the policy asset */
  title: Scalars['String']
}

/** Autogenerated return type of PolicyAssetsCreate. */
export type PolicyAssetsCreatePayload = {
  __typename?: 'PolicyAssetsCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PolicyAsset>
}

/** Autogenerated return type of PolicyAssetsDelete. */
export type PolicyAssetsDeletePayload = {
  __typename?: 'PolicyAssetsDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PolicyAsset>
}

/** Autogenerated return type of PolicyAssetsUpdate. */
export type PolicyAssetsUpdatePayload = {
  __typename?: 'PolicyAssetsUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PolicyAsset>
}

export type PolicyAttributes = {
  /** The assets of the policy */
  assets: Array<PolicyAssetAttributes>
  /** The default language of the policy */
  defaultLanguage: Languages
  /** The description of the policy */
  description?: Maybe<Scalars['String']>
  /** Whether the policy is downloadable */
  downloadable: Scalars['Boolean']
  /** The minimum time to read the policy */
  minimumTime?: Maybe<Scalars['Int']>
  /** The status of the policy */
  status: PolicyStatuses
  /** The title of the policy */
  title: Scalars['String']
  /** The type of the policy */
  type: PolicyTypes
}

/** A basic policy for a trainee */
export type PolicyBasic = Identifier & {
  __typename?: 'PolicyBasic'
  /** The associated asset of the policy */
  asset?: Maybe<PolicyAssetBasic>
  /** Indicates if the policy allows for downloading */
  downloadable: Scalars['Boolean']
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The minimum time requirement for the policy */
  minimumTime?: Maybe<Scalars['Int']>
  /** The title of the policy */
  title: Scalars['String']
}

/** A basic policy for a trainee */
export type PolicyBasicAssetArgs = {
  id?: Maybe<Scalars['Int']>
}

/** Policy enrollment */
export type PolicyEnrollment = Identifier &
  Timestamps & {
    __typename?: 'PolicyEnrollment'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Indicates whether the document has been acknowledged */
    documentAcknowledged?: Maybe<Scalars['Boolean']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** List of pages that have been acknowledged */
    pagesAcknowledged: Array<Scalars['Int']>
    /** List of pages that have been viewed */
    pagesViewed: Array<Scalars['Int']>
    /** The ID of the policy asset */
    policyAssetId?: Maybe<Scalars['Int']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

/** A basic policy enrollment for a trainee */
export type PolicyEnrollmentBasic = Identifier & {
  __typename?: 'PolicyEnrollmentBasic'
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** A list of pages that have been acknowledged by the user */
  pagesAcknowledged: Array<Scalars['Int']>
  /** A list of pages that have been viewed by the user */
  pagesViewed: Array<Scalars['Int']>
  /** The ID for the policy asset */
  policyAssetId?: Maybe<Scalars['Int']>
}

/** The policy quiz */
export type PolicyQuiz = {
  __typename?: 'PolicyQuiz'
  canRegenerate?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['Int']>
  policy?: Maybe<Policy>
  policyAsset?: Maybe<PolicyAsset>
  questions?: Maybe<Array<PolicyQuizQuestion>>
  status?: Maybe<Scalars['String']>
}

export type PolicyQuizAttributes = {
  previousStatus: PolicyQuizStatuses
  status: PolicyQuizStatuses
}

/** Policy quiz question bank options */
export type PolicyQuizOption = {
  __typename?: 'PolicyQuizOption'
  id?: Maybe<Scalars['Int']>
  isCorrect?: Maybe<Scalars['Boolean']>
  label?: Maybe<Scalars['String']>
}

/** Policy quiz question */
export type PolicyQuizQuestion = {
  __typename?: 'PolicyQuizQuestion'
  correctFeedback?: Maybe<Scalars['String']>
  id: Scalars['ID']
  incorrectFeedback?: Maybe<Scalars['String']>
  label?: Maybe<Scalars['String']>
  options?: Maybe<Array<PolicyQuizOption>>
  status?: Maybe<PolicyQuizQuestionStatus>
}

export type PolicyQuizQuestionAttributes = {
  correctFeedback?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  incorrectFeedback?: Maybe<Scalars['String']>
  label?: Maybe<Scalars['String']>
  status?: Maybe<PolicyQuizQuestionStatus>
}

/** The AIDA Policy Quiz question option */
export type PolicyQuizQuestionOption = {
  __typename?: 'PolicyQuizQuestionOption'
  id?: Maybe<Scalars['Int']>
  isCorrect?: Maybe<Scalars['Boolean']>
  label?: Maybe<Scalars['String']>
}

export type PolicyQuizQuestionOptionAttributes = {
  id?: Maybe<Scalars['ID']>
  isCorrect?: Maybe<Scalars['Boolean']>
  label?: Maybe<Scalars['String']>
}

export enum PolicyQuizQuestionStatus {
  Saved = 'saved',
  Unsaved = 'unsaved',
}

export enum PolicyQuizStatuses {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Generating = 'GENERATING',
  Published = 'PUBLISHED',
}

export enum PolicyStatuses {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export enum PolicyTypes {
  Aida = 'AIDA',
  Pdf = 'PDF',
  Url = 'URL',
}

export type PostTrainingArgument = {
  bool: Booleans
  courses: Array<TrainingCourseArgument>
  event: PhishEvents
  trainingEvent: TrainingEvents
}

export type PostTrainingEvent = Identifier & {
  __typename?: 'PostTrainingEvent'
  /** Boolean condition for the group criterion */
  boolean: Booleans
  /** A list of courses associated with the group criterion */
  courses: Array<Course>
  /** Specifies the phishing event for the group criterion */
  event: PhishEvents
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** Specifies the training event for the group criterion */
  trainingEvent: TrainingEvents
}

export type PppDistributionData = {
  __typename?: 'PppDistributionData'
  /** Comma-separated values representing the data for download */
  csv: Scalars['String']
  /** The series of data points for the report */
  dataseries: Array<DataSeries>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export enum PublishedLanguages {
  Ar = 'AR',
  CsCz = 'CS_CZ',
  DaDk = 'DA_DK',
  DeDe = 'DE_DE',
  ElGr = 'EL_GR',
  EnAu = 'EN_AU',
  EnCa = 'EN_CA',
  EnGb = 'EN_GB',
  EnUs = 'EN_US',
  Es_419 = 'ES_419',
  EsEs = 'ES_ES',
  FiFi = 'FI_FI',
  FrCa = 'FR_CA',
  FrFr = 'FR_FR',
  HeIl = 'HE_IL',
  HiIn = 'HI_IN',
  HuHu = 'HU_HU',
  IdId = 'ID_ID',
  ItIt = 'IT_IT',
  JaJp = 'JA_JP',
  KoKr = 'KO_KR',
  MsMy = 'MS_MY',
  MyMm = 'MY_MM',
  NlNl = 'NL_NL',
  NoNo = 'NO_NO',
  PlPl = 'PL_PL',
  PtBr = 'PT_BR',
  PtPt = 'PT_PT',
  RoRo = 'RO_RO',
  RuRu = 'RU_RU',
  SvSe = 'SV_SE',
  SwKe = 'SW_KE',
  ThTh = 'TH_TH',
  TrTr = 'TR_TR',
  UkUa = 'UK_UA',
  ViVn = 'VI_VN',
  ZhCn = 'ZH_CN',
  ZhTw = 'ZH_TW',
}

/** A publisher */
export type Publisher = {
  __typename?: 'Publisher'
  /** The name of the publisher */
  name: Scalars['String']
}

/** Store purchase content options configuration */
export type PurchasedContentOptions = {
  __typename?: 'PurchasedContentOptions'
  /** The theme of the content */
  contentTheme?: Maybe<ContentTheme>
  /** Custom terms associated with the content */
  customTerms?: Maybe<Array<CustomTerm>>
  /** Indicates if captions are on by default */
  defaultCaptions?: Maybe<Scalars['Boolean']>
  /** A list of pages that are hidden in the content */
  hiddenPages?: Maybe<Array<HiddenPage>>
  /** Indicates if the content is non-skippable */
  nonSkip?: Maybe<Scalars['Boolean']>
  /** The required score to pass */
  passingScore?: Maybe<Scalars['Float']>
  /** The display order for reference material */
  referenceMaterialOrder?: Maybe<Scalars['Int']>
  /** URL for reference material related to the content */
  referenceMaterialUrl?: Maybe<Scalars['String']>
  /** Indicates if testing out of the content is an option */
  testOut?: Maybe<Scalars['Boolean']>
  /** The passing score for testing out of the content */
  testoutPassingScore?: Maybe<Scalars['Float']>
  /** The last time the content options were updated */
  updatedAt?: Maybe<Scalars['String']>
}

export type PurchasedContentOptionsAttributes = {
  /** The content theme ID of the purchased content options */
  contentThemeId?: Maybe<Scalars['Int']>
  /** The custom terms of the purchased content options */
  customTermsAttributes?: Maybe<Array<CustomTermAttributes>>
  /** The default captions status of the purchased content options */
  defaultCaptions?: Maybe<Scalars['Boolean']>
  /** The hidden pages of the purchased content options */
  hiddenPagesAttributes?: Maybe<Array<HiddenPageAttributes>>
  /** The non-skip status of the purchased content options */
  nonSkip?: Maybe<Scalars['Boolean']>
  /** The passing score of the purchased content options */
  passingScore?: Maybe<Scalars['Float']>
  /** The reference material order of the purchased content options */
  referenceMaterialOrder?: Maybe<Scalars['Int']>
  /** The reference material URL of the purchased content options */
  referenceMaterialUrl?: Maybe<Scalars['String']>
  /** The test out status of the purchased content options */
  testOut?: Maybe<Scalars['Boolean']>
  /** The test out passing score of the purchased content options */
  testoutPassingScore?: Maybe<Scalars['Float']>
  /** The updated at of the purchased content options */
  updatedAt?: Maybe<Scalars['String']>
}

/** Purchased course */
export type PurchasedCourse = Identifier &
  StorePurchaseInterface &
  Timestamps & {
    __typename?: 'PurchasedCourse'
    /** The alternatives for the purchased course */
    alternatives: Array<CourseAlternative>
    /** Indicates if the purchased course is archived */
    archived?: Maybe<Scalars['Boolean']>
    /** Indicates if the purchased course is an assessment */
    assessment?: Maybe<Scalars['Boolean']>
    /** The type of assessment associated with the purchased course */
    assessmentType?: Maybe<AssessmentTypes>
    /** The type of asset associated with the purchased course */
    assetType: Scalars['String']
    /** Indicates if the course is in beta */
    beta?: Maybe<Scalars['Boolean']>
    /** The percentage of the course that the user has completed */
    completionPercentage?: Maybe<Scalars['Int']>
    /** The source from which the course content is derived */
    contentSource?: Maybe<Scalars['String']>
    /** The course ID to which this purchase relates */
    courseId: Scalars['Int']
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The description of the purchased course */
    description?: Maybe<Scalars['String']>
    /** The duration of the purchased course */
    duration?: Maybe<Scalars['Int']>
    /** Indicates if the course is hidden from the user */
    hidden?: Maybe<Scalars['Boolean']>
    /** The ID of the purchased course */
    id: Scalars['Int']
    /** URL or location of the policy the user needs to acknowledge */
    policyLocation?: Maybe<Scalars['String']>
    /** A polymorphic identifier for associated content */
    polymorphicId: Scalars['String']
    /** Indicates if the course is published */
    published?: Maybe<Scalars['Boolean']>
    /** The published date of the purchased course */
    publishedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** Options available for purchased content */
    purchasedContentOptions?: Maybe<PurchasedContentOptions>
    /** Indicates if the purchased course is retired */
    retired?: Maybe<Scalars['Boolean']>
    /** Indicates if the purchased course is retiring soon */
    retiringSoon?: Maybe<Scalars['Boolean']>
    /** Statistical data related to the purchased course */
    stats: PurchasedCourseStats
    /** Contains information about the store item */
    storeItem?: Maybe<StoreItemUnion>
    /** The type of store item */
    storeItemType?: Maybe<Scalars['String']>
    /** The UUID for the store item associated with this course */
    storeItemUuid: Scalars['String']
    /** The title of the purchased course */
    title: Scalars['String']
    /** The badge associated with completing the training campaign */
    trainingCampaignBadge?: Maybe<Scalars['String']>
    /** List of training campaigns associated with this purchase */
    trainingCampaigns?: Maybe<Array<TrainingCampaign>>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** Indicates if the purchased course is uploaded */
    uploaded?: Maybe<Scalars['Boolean']>
    /** Indicates if the user needs to acknowledge a policy for this course */
    userHasToAcknowledgePolicy?: Maybe<Scalars['Boolean']>
  }

/** Purchased course */
export type PurchasedCourseCompletionPercentageArgs = {
  trainingCampaignId: Scalars['Int']
}

/** Usage stats on a purchased course or managed store purchase */
export type PurchasedCourseStats = {
  __typename?: 'PurchasedCourseStats'
  /** The total number of users who have completed the course */
  completeEnrollments?: Maybe<Scalars['Int']>
  /** The ID of the purchased course */
  id: Scalars['Int']
  /** The total number of users who have not completed the course */
  incompleteEnrollments?: Maybe<Scalars['Int']>
  /** The average rating of the course length by users */
  lengthRating?: Maybe<Scalars['Float']>
  /** The average overall rating of the course by users */
  overallRating?: Maybe<Scalars['Float']>
  /** The average rating of the course teaching style by users */
  styleRating?: Maybe<Scalars['Float']>
  /** The total number of enrollments in the course */
  totalEnrollments?: Maybe<Scalars['Int']>
  /** The average rating of the course value for money by users */
  valueRating?: Maybe<Scalars['Float']>
}

/** An unpaginated list of purchased courses */
export type PurchasedCourses = {
  __typename?: 'PurchasedCourses'
  /** A list of purchased courses */
  nodes: Array<PurchasedCourse>
}

export type PurchasedCoursesCursor = {
  __typename?: 'PurchasedCoursesCursor'
  /** The paginated nodes */
  nodes: Array<PurchasedCourse>
  /** Information about the current paginated set */
  pagination: Pagination
}

export type Query = {
  __typename?: 'Query'
  /** Find the current account */
  account?: Maybe<Account>
  /** Get assessment results for an account */
  accountAssessmentResults?: Maybe<AssessmentResults>
  /** Find an account for account settings */
  accountSettings?: Maybe<Account>
  /** Find all accounts for a partner */
  accounts: AccountsCursor
  /** Retrieve the current aida campaign settings */
  aidaCampaign?: Maybe<AidaCampaign>
  /** Returns AIDA determinations for an AIDA campaign */
  aidaDeterminations: AidaDeterminationsCursor
  /** Retrieve the AIDA Policy Quiz for given policy asset */
  aidaPolicyQuiz?: Maybe<PolicyQuiz>
  /** Find all allowed domains for an account */
  allowedDomains: AllowedDomainsCursor
  /** Get the DNS TXT record to verify allowed domains for an account */
  allowedDomainsDnsRecord: Scalars['String']
  /** An API token */
  apiToken?: Maybe<ApiToken>
  /** Validates an API token */
  apiTokenValidator?: Maybe<ApiTokenValidation>
  /** Paginated API tokens */
  apiTokens: ApiTokensCursor
  /** Retrieve ASAP program */
  asapProgram: AsapProgram
  /** Get assessment domains */
  assessmentDomains: AssessmentDomainsCursor
  /** List of assessment benchmarking data for industries */
  assessmentIndustryBenchmarks: AssessmentIndustryBenchmarksCursor
  /** Get results for an assessment */
  assessmentResults?: Maybe<AssessmentResults>
  /** Get all possible attachment types */
  attachmentTypes?: Maybe<Array<Attachment>>
  /** Find an audit log by ID */
  auditLogExtended?: Maybe<AuditLogExtended>
  /** Return search */
  auditLogObjectSearch: AuditLogObjectSearchCursor
  /** Return paginated audit logs for the account */
  auditLogs: AuditLogsCursor
  /** List all available SKUs that can be added to a main partner by a Site Admin */
  availablePartnerSkus?: Maybe<Array<PartnerPurchasedSku>>
  /** List all available SKUs that can be added to an account by a Site Admin */
  availableSkus?: Maybe<Array<AccountPurchasedSku>>
  /** Returns a callback phishing greeting or response template ready to be previewed */
  callbackPhishingAudioTemplatePreview?: Maybe<CallbackPhishingAudioTemplate>
  /** Find a callback phishing greeting template by ID */
  callbackPhishingGreetingTemplate?: Maybe<CallbackPhishingGreetingTemplate>
  /** Find all callback phishing greeting template languages */
  callbackPhishingGreetingTemplateLanguages: CallbackPhishingGreetingTemplateLanguagesCursor
  /** Find all callback phishing greeting templates */
  callbackPhishingGreetingTemplates: CallbackPhishingGreetingTemplatesCursor
  /** Find all callback phishing phone numbers */
  callbackPhishingPhoneNumbers: CallbackPhishingPhoneNumbersCursor
  /** Find a callback phishing response template by ID */
  callbackPhishingResponseTemplate?: Maybe<CallbackPhishingResponseTemplate>
  /** Find all callback phishing response template languages */
  callbackPhishingResponseTemplateLanguages: CallbackPhishingResponseTemplateLanguagesCursor
  /** Find all callback phishing response templates */
  callbackPhishingResponseTemplates: CallbackPhishingResponseTemplatesCursor
  /** Find a callback phishing email template by ID */
  callbackPhishingTemplate?: Maybe<CallbackPhishingTemplate>
  /** Returns a callback phishing template ready to be previewed */
  callbackPhishingTemplatePreview?: Maybe<CallbackPhishingTemplate>
  /** Find all callback phishing templates */
  callbackPhishingTemplates: CallbackPhishingTemplatesCursor
  /** List notification templates available in a campaign creation */
  campaignNotificationTemplates: Array<NotificationTemplate>
  /** Find all uploaded content for the current account to be displayed in the LX library */
  ccmItems?: Maybe<Array<CcmItem>>
  /** Get all buckets for a given super bucket name */
  cdrmsGetBuckets?: Maybe<Array<CdrmsBucket>>
  /** Get all categories */
  cdrmsGetCategories?: Maybe<CdrmsCategories>
  /** Get a specific category */
  cdrmsGetCategory?: Maybe<CdrmsCategory>
  /** Get a specific rule */
  cdrmsGetRule?: Maybe<CdrmsRule>
  /** Get all disputed rules */
  cdrmsGetRuleDisputes?: Maybe<CdrmsRuleDisputes>
  /** Get required data for dropdowns */
  cdrmsGetRuleDisputesUi?: Maybe<CdrmsRuleDisputesUi>
  /** Get OD version of a rule */
  cdrmsGetRuleOd?: Maybe<CdrmsRule>
  /** Get Production version of a rule */
  cdrmsGetRuleProduction?: Maybe<CdrmsRule>
  /** Get all rules */
  cdrmsGetRules?: Maybe<CdrmsRules>
  /** Get all super buckets */
  cdrmsGetSuperBuckets?: Maybe<Array<CdrmsBucket>>
  /** Get a specific topic */
  cdrmsGetTopic?: Maybe<CdrmsTopic>
  /** Get all topics */
  cdrmsGetTopics?: Maybe<CdrmsTopics>
  /** Get a specific vendor */
  cdrmsGetVendor?: Maybe<CdrmsVendor>
  /** Get OD version of a vendor */
  cdrmsGetVendorOd?: Maybe<CdrmsVendor>
  /** Get Production version of a vendor */
  cdrmsGetVendorProduction?: Maybe<CdrmsVendor>
  /** Get all vendors */
  cdrmsGetVendors?: Maybe<CdrmsVendors>
  /** Return all the accounts underneath the partner that can see the piece of content that don’t already have the content */
  childrensLibraryAccounts: ChildrensLibraryAccountsCursor
  /** Find all Clickwraps */
  clickwraps: ClickwrapsCursor
  /** Find a content theme or return a new one for the account */
  contentTheme?: Maybe<ContentTheme>
  /** Return paginated content themes for the account */
  contentThemes: ContentThemesCursor
  /** Give a live user count from a set of criterions */
  criterionCount?: Maybe<Scalars['Int']>
  /** Gets the current admin */
  currentAdmin?: Maybe<SiteAdmin>
  /** Gets the current impersonating user */
  currentImpersonatingUser?: Maybe<User>
  /** Gets the current partner admin */
  currentPartnerAdmin?: Maybe<PartnerAdmin>
  /** Find current account’s subscription or partner’s subscription */
  currentSubscription?: Maybe<Subscription>
  /** Gets the current user */
  currentUser?: Maybe<User>
  /** List custom event types and sources */
  customEventTypesAndSources: CustomEventTypesAndSources
  /** Find all the active Sidekiq jobs running or enqueuing demo seeds */
  demoSeedStatuses: DemoSeedStatusesCursor
  /** Find all detections for an account */
  detections: DetectionsCursor
  /** Paginated direct reports */
  directReports: DirectReportsCursor
  /** Find the specified email type DKIM configuration for the current account */
  dkimConfig?: Maybe<DkimConfig>
  /** Find a DMI configuration for the current account */
  dmiConfig?: Maybe<DmiConfig>
  /** Find all DMI configurations for the current account */
  dmiConfigs: DmiConfigsCursor
  /** Find a single download */
  download?: Maybe<Download>
  /** Return the count for unviewed downloads */
  downloadUnviewedCount: Scalars['Int']
  /** Find current report configuration filters for an actor */
  downloadsReportConfigFilters: DownloadsReportConfigFiltersCursor
  /** Find an enrollment by ID */
  enrollment?: Maybe<Enrollment>
  /** Get a basic enrollment for a policy for a trainee */
  enrollmentForPolicyBasic?: Maybe<EnrollmentBasic>
  /** Returns the number of enrollment completions per platform */
  enrollmentPlatforms?: Maybe<EnrollmentPlatforms>
  /** Find an environment questionnaire by its parent */
  environmentQuestionnaire?: Maybe<EnvironmentQuestionnaire>
  /** Get all event types from the User Event API */
  eventTypes?: Maybe<Array<Event>>
  /** Get an executive reports dashboard definition */
  execReportsDashboard: ExecReportsExecReportsDashboard
  /** Get your executive reports dashboard definition */
  execReportsDashboards: ExecReportsExecReportsDashboards
  /** Phishing Metrics widget data for executive reports */
  execReportsPhishingActivities: ExecReportsPhishingActivitiesData
  /** Risk Score Trend widget data for executive reports */
  execReportsRiskScore: ExecReportsRiskScoreData
  /** Text Block widget data for executive reports */
  execReportsText: ExecReportsTextData
  /** Title and Logo widget data for executive reports */
  execReportsTitle: ExecReportsTitleData
  /** Training Metrics widget data for executive reports */
  execReportsTrainingActivities: ExecReportsTrainingActivitiesData
  /** Fetch an account’s free test emails */
  freeTestEmails: FreeTestEmailsCursor
  /** Get data for a free tool campaign */
  freeToolCampaign?: Maybe<FreeToolResult>
  /** Get data for the free tools */
  freeToolData: FreeToolData
  /** Get data for a free tool report */
  freeToolReport?: Maybe<FreeToolReportData>
  /** Get configuration for Google User Provisioning */
  googleUserProvisioningConfig?: Maybe<GoogleUserProvisioningConfig>
  /** Get domains from Google User Provisioning */
  googleUserProvisioningDomains: GoogleUserProvisioningDomainsCursor
  /** Get groups from Google User Provisioning */
  googleUserProvisioningGroups: GoogleUserProvisioningGroupsCursor
  /** Get status for Google User Provisioning */
  googleUserProvisioningStatus: GoogleUserProvisioningStatuses
  /** Get users from Google User Provisioning */
  googleUserProvisioningUsers: GoogleUserProvisioningUsersCursor
  /** Fetch the Gmail token for a PAB */
  gpabMagicMailToken: Scalars['String']
  /** Return Graph PAB data for download */
  graphSuccess?: Maybe<PhishalertSetting>
  /** Find a group for an account */
  group?: Maybe<Group>
  /** Find groups for an account */
  groups: GroupsCursor
  /** Find all industries */
  industries: IndustriesCursor
  /** List of benchmarking data for industries */
  industryBenchmarks: IndustryBenchmarksCursor
  /** Find an IP range */
  ipRange?: Maybe<IpRange>
  /** Find all IP ranges */
  ipRanges: IpRangesCursor
  /** Retrieve a knowledge refresher */
  knowledgeRefresherQuiz: KnowledgeRefresherQuiz
  /** Find a landing domain */
  landingDomain: LandingDomain
  /** Find all landing domains */
  landingDomains: LandingDomainsCursor
  /** Find a landing page by ID or UUID */
  landingPage?: Maybe<LandingPage>
  /** Find all landing page categories */
  landingPageCategories: LandingPageCategories
  /** Find all landing pages in draft state */
  landingPageCounts: LandingPageCounts
  /** Find all landing pages */
  landingPages: LandingPagesCursor
  /** List available languages */
  languages?: Maybe<Array<Scalars['String']>>
  /** Fetch a LDAP sync report */
  ldapSyncReport?: Maybe<LdapSyncReport>
  /** Find all LDAP sync reports items for a sync report */
  ldapSyncReportItems: LdapSyncReportItemsCursor
  /** Find all LDAP sync reports for an account */
  ldapSyncReports: LdapSyncReportsCursor
  /** List available LX languages */
  locales?: Maybe<Locales>
  /** Find a managed phishing campaign by ID */
  managedPhishingCampaign?: Maybe<ManagedPhishingCampaign>
  /** Find all managed phishing campaigns */
  managedPhishingCampaigns: ManagedPhishingCampaignsCursor
  /** Find a specific managed store purchase */
  managedStorePurchase: ManagedStorePurchase
  /** Find all managed store purchases for a partner */
  managedStorePurchases: ManagedStorePurchasesCursor
  /** Find a managed training campaign by ID */
  managedTrainingCampaign?: Maybe<ManagedTrainingCampaign>
  /** Find all managed training campaigns */
  managedTrainingCampaigns: ManagedTrainingCampaignsCursor
  /** Find which accounts cannot see which pieces of content */
  managedTrainingCourseCheck: ManagedTrainingCourseCheckResult
  /** Manager report for user */
  managerReport: ManagerReport
  /** Gets user messaging campaign by campaign ID */
  messageCampaign?: Maybe<Campaign>
  /** Gets message campaigns for the account */
  messageCampaigns: CampaignsCursor
  /** Get message run by ID */
  messageRun?: Maybe<Run>
  /** Gets user messages by run ID */
  messageRunRecipients: RunRecipientsCursor
  /** All message runs for an account */
  messageRuns: RunsCursor
  /** Return ModStore recommendations for the account */
  modstoreRecommendations: ModstoreRecommendations
  /** Find all accounts for a partner */
  multiInstanceAccounts: Array<MultiInstanceResult>
  /** List default notification template IDs */
  notificationDefaultTemplates: Array<NotificationTemplateDefaults>
  /** Find a notification template by ID */
  notificationTemplate?: Maybe<NotificationTemplate>
  /** Find all notification template categories */
  notificationTemplateCategories: NotificationTemplateCategories
  /** Total count of visible templates */
  notificationTemplateCount: Scalars['Int']
  /** Find all notification templates in draft state */
  notificationTemplateDrafts: NotificationTemplateDrafts
  /** Returns a phishing template ready to be previewed */
  notificationTemplatePreview?: Maybe<NotificationTemplate>
  /** List notification templates */
  notificationTemplates: NotificationTemplatesCursor
  /** List of users who have at least one Outlook add-in activity */
  outlookAddinActivityUsers: OutlookAddinActivityUsersCursor
  /** Retrieve a list of files for a package */
  packageFileList: PackageFileList
  /** Find a partner by ID */
  partner?: Maybe<Partner>
  /** Find a partner admin by ID */
  partnerAdmin?: Maybe<PartnerAdmin>
  /** Find the current partner for partner settings */
  partnerAdminSettings?: Maybe<PartnerAdmin>
  /** Find partner admins */
  partnerAdmins: PartnerAdminsCursor
  /** Partner API token */
  partnerApiToken?: Maybe<PartnerApiToken>
  /** Paginated API tokens */
  partnerApiTokens: PartnerApiTokensCursor
  /** Get data for partners dashboard graphs */
  partnerDashboardGraphs?: Maybe<Scalars['JSON']>
  /** Return the current partner preference */
  partnerPreference?: Maybe<PartnerSettingsPreferences>
  /** Find the current partner account for partner settings */
  partnerSettings?: Maybe<Partner>
  /** Find partners */
  partners: PartnersCursor
  /** Get Password IQ data stored in S3 for an audit log */
  passwordIqAuditLogS3Data: AuditLogS3Data
  /** Get Password IQ audit logs */
  passwordIqAuditLogs: PasswordIqAuditLogData
  /** PIQ client installer link */
  passwordIqClientPath: Scalars['String']
  /** Get a PIQ dashboard definition */
  passwordIqDashboard: PasswordIqPiqDashboard
  /** Get your PIQ dashboard definition */
  passwordIqDashboards: Array<PasswordIqPiqDashboard>
  /** Detection Counts for Password IQ */
  passwordIqDetectionCounts: PasswordIqDetectionCountData
  /** Pie Chart Data for Password IQ */
  passwordIqGraph: Array<PasswordIqPiqSerie>
  /** Pie Chart Data for Password IQ */
  passwordIqPie: PasswordIqPieData
  /** Schedule Data for Password IQ */
  passwordIqSchedule: PasswordIqSchedule
  /** User Detection States for Password IQ */
  passwordIqUserStates: PasswordIqUserStates
  /** Pie Chart Data for Password IQ */
  passwordIqVariance: Array<PasswordIqPiqSerie>
  /** Fetch your Phish Alert stats */
  phishAlertStats: PhishAlertStats
  /** Find a phish domain */
  phishDomain: PhishDomain
  /** Find all phish domains */
  phishDomains: PhishDomainsCursor
  /** Returns all email addresses from PER for account */
  phisherEmailAddresses: Scalars['String']
  /** Find a campaign by ID */
  phishingCampaign?: Maybe<PhishingCampaign>
  /** Find phishing campaign nonrecipients by campaign run ID */
  phishingCampaignNonRecipients: PhishingCampaignNonRecipientsCursor
  /** Find a campaign recipient by ID */
  phishingCampaignRecipient?: Maybe<PhishingCampaignRecipient>
  /** Find all the campaign recipients in a campaign run */
  phishingCampaignRecipients: PhishingCampaignRecipientsCursor
  /** Find a campaign run by ID */
  phishingCampaignRun?: Maybe<PhishingCampaignRun>
  /** Find all campaign runs in account */
  phishingCampaignRuns: PhishingCampaignRunsCursor
  /** Find all phishing campaigns */
  phishingCampaigns: PhishingCampaignsCursor
  /** List user phishing locales for the given groups */
  phishingLocalesForGroups?: Maybe<Scalars['JSON']>
  /** Find a phishing campaign by ID */
  phishingTemplate?: Maybe<PhishingTemplate>
  /** Find all phishing template categories */
  phishingTemplateCategories: PhishingTemplateCategories
  /** Find a phishing template category by ID */
  phishingTemplateCategory?: Maybe<PhishingTemplateCategory>
  /** Find template categories which contain translations for the requested language set */
  phishingTemplateCategoryRecommendations: Array<PhishingTemplateCategoryRecommendation>
  /** Total count of visible templates */
  phishingTemplateCount: Scalars['Int']
  /** Find all email templates in draft state */
  phishingTemplateDrafts: PhishingTemplateDrafts
  /** Returns a phishing template ready to be previewed */
  phishingTemplatePreview?: Maybe<PhishingTemplate>
  /** List email templates */
  phishingTemplates: PhishingTemplatesCursor
  /** List email templates specifically for ASAP */
  phishingTemplatesAsap: PhishingTemplatesAsapCursor
  /** Find a physical QR code campaign by ID */
  physicalQrCampaign?: Maybe<PhysicalQrCampaign>
  /** Find a physical QR code campaign by ID */
  physicalQrCampaignLocation?: Maybe<PhysicalQrCampaignLocation>
  /** Find all physical QR code campaign locations for a campaign */
  physicalQrCampaignResults: PhysicalQrCampaignResultsCursor
  /** Find all physical QR code campaigns */
  physicalQrCampaigns: PhysicalQrCampaignsCursor
  /** Find a physical QR code template by ID */
  physicalQrTemplate?: Maybe<PhysicalQrTemplate>
  /** Returns a physical QR code template ready to be previewed */
  physicalQrTemplatePreview?: Maybe<PhysicalQrTemplate>
  /** Find all physical QR code templates */
  physicalQrTemplates: PhysicalQrTemplatesCursor
  /** Find all your policies */
  policies: PoliciesCursor
  /** Find a policy */
  policy?: Maybe<Policy>
  /** Find a basic policy for a trainee */
  policyBasic?: Maybe<PolicyBasic>
  /** Retrieve the AIDA Policy Quiz for given policy asset */
  policyQuiz?: Maybe<PolicyQuiz>
  /** Find a specific purchased course */
  purchasedCourse: PurchasedCourse
  /** Find all your purchased courses */
  purchasedCourses: PurchasedCoursesCursor
  /** Returns the options for the courses selector in the training quiz report */
  purchasedCoursesWithInteractionsSelectQuery: PurchasedCourses
  /** Find all report categories for an account */
  reportCategories: ReportCategories
  /** Find downloads */
  reportDownloads: ReportDownloadsCursor
  /** Find account groups with access to a given report */
  reportPermissionGroups: ReportPermissionGroupsCursor
  /** Return active rules report */
  reportsActiveRulesReport: ActiveRulesReport
  /** Return AIDA Campaign summary table data */
  reportsAidaCampaignSummaryTableCsv: AidaCampaignSummaryTableCsv
  /** Return a distribution of assessment scores by knowledge area */
  reportsAssessmentScoreByKnowledgeArea: KnowledgeSeriesCollection
  /** Return a distribution of assessment scores by knowledge area */
  reportsAssessmentScoreByKnowledgeAreaOverTime: KnowledgeTimeSeriesCollection
  /** Return a list of audit logs */
  reportsAuditLogs: AuditLogs
  /** Emails templates sent */
  reportsEmailTemplatesSent: Array<PhishingTemplate>
  /** Emails sent by group counts in the given timeframe */
  reportsEmailsSentByGroup: EmailsSentByGroupCursor
  /** Emails sent by group counts in the given timeframe for CSV */
  reportsEmailsSentByGroupCsv: EmailsSentCountsCsv
  /** Emails sent by template counts in the given timeframe */
  reportsEmailsSentByTemplate: EmailsSentByTemplateCursor
  /** Emails sent by template counts in the given timeframe for CSV */
  reportsEmailsSentByTemplateCsv: EmailsSentCountsByTemplateCsv
  /** Dataset for failure types by user count */
  reportsFailureTypeUserCounts: FailureTypeByUserCount
  /** Return failure types by campaigns */
  reportsFailureTypesBarChart: FailureTypesBarChart
  /** Return failures by phishing templates count in the given timeframe */
  reportsFailuresByPhishingTemplate: FailuresByPhishingTemplate
  /** Return global phishing aggregate chart data */
  reportsGlobalPhishingAggregate: AggregateChart
  /** Return global phishing aggregate table data */
  reportsGlobalPhishingAggregateCsv: AggregateCsv
  /** Obsolete global phishing aggregate table data */
  reportsGlobalPhishingAggregateTable: GlobalPhishingAggregateTableCursor
  /** Return global phishing raw bar chart data */
  reportsGlobalPhishingRaw: RawBarChart
  /** Return global phishing raw table data */
  reportsGlobalPhishingRawCsv: RawCsv
  /** Obsolete global phishing raw table data */
  reportsGlobalPhishingRawTable: GlobalPhishingRawTableCursor
  /** Global training aggregate chart resolver, this now uses zenka */
  reportsGlobalTrainingAggregate: GlobalTrainingAggregateChart
  /** Global training aggregate chart resolver, this now uses zenka */
  reportsGlobalTrainingAggregateChart: GlobalTrainingAggregateChart
  /** Global training aggregate CSV resolver */
  reportsGlobalTrainingAggregateCsv: GlobalTrainingAggregateCsv
  /** Obsolete global training aggregate table resolver, this now uses zenka */
  reportsGlobalTrainingAggregateTable: GlobalTrainingAggregateTableCursor
  /** Global training raw chart resolver, this now uses zenka */
  reportsGlobalTrainingRaw: GlobalTrainingRawChart
  /** Global training raw chart resolver, this now uses zenka */
  reportsGlobalTrainingRawChart: GlobalTrainingRawChart
  /** Global training raw CSV resolver */
  reportsGlobalTrainingRawCsv: GlobalTrainingRawCsv
  /** Obsolete global training report GlobalTrainingRawTable Resolver, this now uses zenka */
  reportsGlobalTrainingRawTable: GlobalTrainingRawTableCursor
  /** Return the incomplete training users in the given timeframe */
  reportsIncompleteTraining: UnphishedAndUntrainedUsers
  /** Returns the average PPP of users who received their first phishing test during the specified range */
  reportsInitialPpp: TimePointCollection
  /** Learner platform activity raw chart resolver */
  reportsLearnerPlatformRaw: LearnerPlatformRawChart
  /** Learner platform activity raw chart resolver */
  reportsLearnerPlatformRawChart: LearnerPlatformRawChart
  /** Learner platform activity raw CSV resolver */
  reportsLearnerPlatformRawCsv: LearnerPlatformRawCsv
  /** Learner platform activity report LearnerPlatformRawTable resolver */
  reportsLearnerPlatformRawTable: LearnerPlatformRawTableCursor
  /** Returns user location options for reports */
  reportsLocationOptions: Array<Scalars['String']>
  /** Returns the top five email templates that were clicked by this user */
  reportsMostClickedEmailTemplates: Array<MostClickedEmailTemplate>
  /** Return the unphished users in the given timeframe */
  reportsNoTrainingAssigned: UnphishedAndUntrainedUsers
  /** Returns the average PPP of selected accounts and users during the specified range */
  reportsOverallPppByAccount: TimeSeriesCollectionOverallPppWithCount
  /** Returns the average PPP of users in selected campaigns during the specified range */
  reportsOverallPppByCampaign: TimeSeriesCollectionOverallPppWithCount
  /** Returns the average PPP of selected users during the specified range */
  reportsOverallPppByGroup: TimeSeriesCollectionOverallPppWithCount
  reportsOverallPppByGroupCard: TimeSeriesCollectionOverallPppWithCount
  /** Returns the average PPP and training time of selected groups during the specified range */
  reportsOverallPppByTrainingTime: OverallPppByTrainingTimeSeries
  /** Returns the average PPP of a selected user during the specified range */
  reportsOverallPppByUser: TimeSeriesCollectionOverallPppWithCount
  /** Return List of PAB comparison per day */
  reportsPabComparison: PabComparison
  /** Return list of users latest PAB activity */
  reportsPabUserActivity: PabUserActivity
  /** Return overall Phish-failure Percentage */
  reportsPhishFailurePercentageByCampaigns: TimeSeriesCollection
  /** Return overall Phish-failure Percentage */
  reportsPhishFailurePercentageOverall: TimeSeriesCollection
  /** Return list of clickers in one or more phishing campaigns or runs */
  reportsPhishingAllClickers: PhishingAllClickers
  /** Return PST report */
  reportsPhishingSecurityTests: PhishingSecurityTests
  /** Return a distribution of user counts in all PPPs ranges */
  reportsPppDistribution: PppDistributionData
  /** Return a distribution of user counts in all PPPs ranges */
  reportsRiskScoreByAssessment: DistributionCollection
  /** Return Risk Scores by selected groups */
  reportsRiskScoreByGroup: TimeSeriesCollection
  reportsRiskScoreByGroupCard: TimeSeriesCollection
  /** Return Risk Scores by selected locations */
  reportsRiskScoreByLocation: TimeSeriesCollectionWithOptionalDate
  /** Return a distribution of user percentages in culture score ranges */
  reportsRiskScoreBySurvey: DistributionCollection
  /** Return Risk Scores by riskiest users for selected groups */
  reportsRiskScoreByUser: RiskScoreByUser
  /** Return Risk Score history for the selected user */
  reportsRiskScoreHistoryByUser: TimeSeriesCollection
  reportsRiskScoreV2ByGroup: RiskScoreV2ByGroup
  reportsRiskScoreV2ByManager: RiskScoreV2ByManager
  reportsRiskScoreV2ByUser: RiskScoreV2ByUser
  /** SecurityCoach activity aggregate Real-Time Coaching aggregate main resolver */
  reportsSecurityCoachActivityRtcAggregate: SecurityCoachActivityRtcAggregateCursor
  /** SecurityCoach activity Real-Time Coaching aggregate CSV resolver */
  reportsSecurityCoachActivityRtcAggregateCsv: SecurityCoachActivityRtcAggregateCsv
  /** SecurityCoach activity Real-Time Coaching raw main resolver */
  reportsSecurityCoachActivityRtcRaw: SecurityCoachActivityRtcRawCursor
  /** SecurityCoach activity Real-Time Coaching raw CSV resolver */
  reportsSecurityCoachActivityRtcRawCsv: SecurityCoachActivityRtcRawCsv
  /** SecurityCoach detection rules activity aggregate main resolver */
  reportsSecurityCoachDetectionRulesActivityAggregate: AggregateResponse
  /** SecurityCoach activity Real-Time Coaching aggregate CSV resolver */
  reportsSecurityCoachDetectionRulesActivityAggregateCsv: SecurityCoachDetectionRulesActivityAggregateCsv
  /** SecurityCoach detection rules activity raw main resolver */
  reportsSecurityCoachDetectionRulesActivityRaw: RawResponse
  /** SecurityCoach activity Real-Time Coaching raw CSV resolver */
  reportsSecurityCoachDetectionRulesActivityRawCsv: SecurityCoachDetectionRulesActivityRawCsv
  /** Return a distribution of survey scores by dimension */
  reportsSurveyScoreByDimension: DimensionSeriesCollection
  /** Return a distribution of survey scores by dimension */
  reportsSurveyScoreByDimensionOverTime: DimensionTimeSeriesCollection
  /** Return training campaign's surveys */
  reportsTrainingCampaignEnrollments: TrainingCampaignEnrollments
  /** Return training campaign's surveys */
  reportsTrainingCampaignSurveys: TrainingCampaignSurveys
  /** Dataset for training comparison by Phish-prone Percentage */
  reportsTrainingComparisonByPpp: TrainingComparisonSeries
  /** Dataset for training comparison by Risk Score */
  reportsTrainingComparisonByRiskScore: TrainingComparisonSeries
  /** Return total amount of trained hours in the given timeframe */
  reportsTrainingHours: TimeSeriesCollection
  /** Return training interactions for a given purchased course and group */
  reportsTrainingInteractionsByGroup?: Maybe<Array<TrainingInteractionsByGroup>>
  reportsTrainingInteractionsByGroupCsv: TrainingInteractionsByGroupCsv
  /** Return training interactions for a given purchased course */
  reportsTrainingInteractionsByPurchasedCourse?: Maybe<
    Array<TrainingInteractionsByPurchasedCourse>
  >
  reportsTrainingInteractionsByPurchasedCourseCsv: TrainingInteractionsCsv
  /** Return the unphished users in the given timeframe */
  reportsTrainingNotStarted: UnphishedAndUntrainedUsers
  /** Return the total number of users under each training status within each campaign during the specified date range */
  reportsTrainingStatusByCampaign: TrainingStatuses
  reportsTrainingStatusByCampaignCard: TrainingStatuses
  /** Return the total number of users under each training status within each course during the specified date range */
  reportsTrainingStatusByCourse: TrainingStatuses
  /** Return the total number of users under each training status within each group during the specified date range */
  reportsTrainingStatusByGroup: TrainingStatuses
  /** Return the total number of users under each training status within each group during the specified date range */
  reportsTrainingStatusesByCampaign: TrainingStatusesByCampaign
  /** Return the average survey totals for each training campaign and content combo */
  reportsTrainingSurveysByCampaign: ByCampaign
  /** Return the average survey totals for each content */
  reportsTrainingSurveysByCourse: ByCourse
  /** Return the unphished users in the given timeframe */
  reportsUnphishedUsers: UnphishedAndUntrainedUsers
  /** Return a distribution of user counts by assessment score ranges */
  reportsUserCountByAssessment: DistributionCollection
  /** Return a distribution of user counts by Security Culture Survey score ranges */
  reportsUserCountBySurvey: DistributionCollection
  /** Return user counts for the given timeframe */
  reportsUserCounts: TimeSeriesCollection
  reportsUserCountsCard: TimeSeriesCollection
  /** Return phishing campaigns details from users */
  reportsUserPhishingCampaignsCsv: UserPhishingCampaignsCsv
  /** Return changes in user counts for the given timeframe */
  reportsUserSignIns: UserSignIns
  /** Return training campaigns details from users */
  reportsUserTrainingCampaignsCsv: UserTrainingCampaignsCsv
  /** Return list of users */
  reportsUsersList: UsersList
  /** Find a restricted domain */
  restrictedDomain: RestrictedDomain
  /** Find all restricted domains */
  restrictedDomains: RestrictedDomainsCursor
  /** The account Risk Score stats */
  riskScoreStats: RiskScoreStats
  /** Get SAML certificates avaliable */
  samlCertificates: Array<SamlCertificate>
  /** Returns a saved report */
  savedReport?: Maybe<SavedReport>
  /** Returns a collection of saved reports */
  savedReports: ListCursor
  /** Find current report configuration filters */
  savedReportsFilters: SavedReportsFiltersCursor
  /** Returns a scheduled report configuration */
  scheduledReport?: Maybe<ScheduledReport>
  /** Returns a collection of scheduled reports */
  scheduledReports: ScheduledReportsListCursor
  /** Find current report configuration filters */
  scheduledReportsFilters?: Maybe<ReportConfigFilters>
  /** Find a Second Chance device for an account */
  secondChanceDevice: SecondChanceDevice
  /** Find all Second Chance devices for an account */
  secondChanceDevices: SecondChanceDevicesCursor
  /** Find all Second Chance domain prompts */
  secondChanceDomainPrompts: SecondChanceDomainPromptsCursor
  /** Find all Second Chance No Prompt Domains for an account */
  secondChanceDomains: SecondChanceDomainsCursor
  /** The stats and info for the Second Change Overview tab */
  secondChanceOverview: SecondChanceOverview
  /** Find all Second Chance post actions for an account */
  secondChancePostActions: SecondChancePostActionsCursor
  /** The data for the Second Chance Settings tab */
  secondChanceSettings: SecondChanceSettings
  /** Find a Second Chance URL for an account */
  secondChanceUrl: SecondChanceUrl
  /** Find all Second Chance URLs for an account */
  secondChanceUrls: SecondChanceUrlsCursor
  /** Find a Second Chance user for an account */
  secondChanceUser: SecondChanceUser
  /** Find all Second Chance users for an account */
  secondChanceUsers: SecondChanceUsersCursor
  /** Security Coach RTC Data Aggregate */
  securityCoachAggregateCampaignData: AggregateCampaignDataCursor
  /** Retrieve api endpoints for a vendor */
  securityCoachApiEndpoints?: Maybe<ApiEndpointData>
  /** Retrieve status and errors for all vendors */
  securityCoachBulkVendorStatus?: Maybe<BulkVendorStatusData>
  /** Find a RTC Campaign */
  securityCoachCampaign?: Maybe<SecurityCoachCampaign>
  /** Security Coach RTC Data Raw */
  securityCoachCampaignData: CampaignDataCursor
  /** Coaching Campaign Overview */
  securityCoachCampaignOverview?: Maybe<CampaignOverview>
  /** List RTC Campaigns */
  securityCoachCampaigns: SecurityCoachCampaignsCursor
  /** Get total number of coaching campaigns */
  securityCoachCampaignsCount: Scalars['Int']
  /** Get All Coaching Content */
  securityCoachCatalogContent: Array<StoreItem>
  /** Get Relevant Coaching Content based on rule ids */
  securityCoachContent: Array<SecurityCoachContent>
  /** Get Relevant Coaching Content */
  securityCoachContentItem: Array<SecurityCoachTranslatedSecurityTip>
  /** Get the status details of an uploaded user mapping CSV for Security Coach */
  securityCoachCsvStatusDetails?: Maybe<CsvStatusDetails>
  /** A SecurityCoach custom content */
  securityCoachCustomContent?: Maybe<CustomContent>
  /** List SecurityCoach custom content translations for a custom content ID */
  securityCoachCustomContentTranslations: Array<CustomContentTranslation>
  /** List SecurityCoach custom content items */
  securityCoachCustomContents: Array<CustomContent>
  /** Get Relevant Coaching Custom Content based on rule ids */
  securityCoachCustomContentsByRules: Array<CustomContent>
  /** A SecurityCoach custom notification */
  securityCoachCustomNotification: CustomNotification
  /** List SecurityCoach custom notifications */
  securityCoachCustomNotifications: CustomNotificationsCursor
  /** A SecurityCoach custom translation */
  securityCoachCustomTranslation: CustomTranslation
  /** Get Google Chat Configuration */
  securityCoachDeliveryGoogleChatConfiguration: Configuration
  /** Get Google Chat Users by Domain */
  securityCoachDeliveryGoogleChatUsersByDomain: UsersByDomain
  /** Get the slack setup data */
  securityCoachDeliverySlackSetup: SecurityCoachDeliverySlackSetup
  /** Get all registered slack workspaces */
  securityCoachDeliverySlackWorkspaces: Array<Workspace>
  /** Get Teams Configuration */
  securityCoachDeliveryTeamsConfiguration: Array<Channel>
  /** Delete the teams integration */
  securityCoachDeliveryTeamsDeleteIntegrationUrl: SecurityCoachDeliveryTeamsSetup
  /** Get the teams setup data */
  securityCoachDeliveryTeamsReviewPermissionsUrl: SecurityCoachDeliveryTeamsSetup
  /** Get all registered Teams */
  securityCoachDeliveryTeamsTeams: Teams
  /** Get the teams setup data for the permissions necessary for uploading the bot */
  securityCoachDeliveryTeamsUploadBotUrl: SecurityCoachDeliveryTeamsSetup
  /** List engine status */
  securityCoachEngineStatus?: Maybe<EngineStatus>
  /** Export Unmapped Events to a CSV file */
  securityCoachExportUnmappedEventsToCsv: ExportUnmappedEventsMessage
  /** Get List of Remap Jobs */
  securityCoachGetListOfRemapJobs: Array<RemapJobs>
  /** Get Organization Key for a vendor */
  securityCoachGetOrganizationKey?: Maybe<OrganizationKey>
  /** Get Remap Jobs Status */
  securityCoachGetRemapJobsStatus: RemapJobsStatus
  /** Get Unmapped Data */
  securityCoachGetUnmappedData: UnmappedData
  /** Get User Data for a Workspace */
  securityCoachGetUserData: UserData
  /** Get details and vendor types for all vendors. */
  securityCoachGetVendorDetails?: Maybe<Array<VendorDetails>>
  /** List mapped users */
  securityCoachListMappedUsers?: Maybe<MappedUsersList>
  /** List filter conditionals for a given filter field. */
  securityCoachListMappingRecommendationFilterConditions?: Maybe<
    Array<MappingRecommendationFilterData>
  >
  /** List available filter values for a given filter field */
  securityCoachListMappingRecommendationFilterValues?: Maybe<
    Array<MappingRecommendationFilterData>
  >
  /** List teachable moment categories */
  securityCoachListTeachableMomentCategories?: Maybe<TeachableMomentCategoriesPayload>
  /** List teachable moment rules and their status (active, inactive) */
  securityCoachListTeachableMomentRules?: Maybe<TeachableMomentRulesPayload>
  /** List teachable moment system rules */
  securityCoachListTeachableMomentSystemRules?: Maybe<TeachableMomentRulesPayload>
  /** List recommended mappings for users */
  securityCoachListUserMappingRecommendation?: Maybe<UserMappingRecommendationList>
  /** List mapping recommendation filter fields */
  securityCoachListUserMappingRecommendationFilterFields?: Maybe<
    Array<MappingRecommendationFilterData>
  >
  /** Retrieve list of uploaded CSVs for Security Coach */
  securityCoachMappingCsvs: UploadedCsvs
  /** A SecurityCoach custom notification */
  securityCoachNotificationPreview: CustomTranslation
  /** Get all real time notification templates */
  securityCoachNotifications: Array<SecurityCoachNotification>
  /** Real-Time Coaching Overview */
  securityCoachRealTimeCoachingOverview?: Maybe<RealTimeCoachingOverview>
  /** Real-Time Coaching summary */
  securityCoachRealTimeCoachingSummary?: Maybe<RealTimeCoachingSummary>
  /** List teachable moment rules that are not part of an active campaign to recommend */
  securityCoachRecommendedTeachableMomentRules?: Maybe<
    Array<RecommendedTeachableMomentRules>
  >
  /** Active rules statistics */
  securityCoachReportingActiveRulesStatistics?: Maybe<ActiveRulesStatistics>
  /** Top Risky Users */
  securityCoachReportingActiveRulesTopRisky?: Maybe<Array<ActiveRulesTopRisky>>
  /** All Detection Statistic */
  securityCoachReportingAllDetectionStatistics?: Maybe<AllDetectionStatistics>
  /** All Detections */
  securityCoachReportingAllDetections?: Maybe<AllDetections>
  /** All Detections Summary */
  securityCoachReportingAllDetectionsSummary?: Maybe<
    Array<AllDetectionsSummary>
  >
  /** Employees Receiving Detections */
  securityCoachReportingEmployeesReceivingDetections?: Maybe<EmployeesReceivingDetections>
  /** Endpoint OS Contribution */
  securityCoachReportingEndpointOsContribution?: Maybe<Array<OsDistribution>>
  /** Endpoint Threat Severity */
  securityCoachReportingEndpointThreatSeverity?: Maybe<Array<ThreatSeverity>>
  /** Integration Detection Trend */
  securityCoachReportingIntegrationDetectionTrend?: Maybe<IntegrationDetectionTrend>
  /** Integration summary */
  securityCoachReportingIntegrationSummary?: Maybe<IntegrationSummary>
  /** Log Type Trend */
  securityCoachReportingLogTypeTrend?: Maybe<Array<LogTypeTrend>>
  /** Risk Statistic */
  securityCoachReportingRiskStatistics?: Maybe<RiskStatistics>
  /** Rules summary */
  securityCoachReportingRulesSummary?: Maybe<RulesSummary>
  /** Threat Category */
  securityCoachReportingThreatCategory?: Maybe<Array<ThreatCategory>>
  /** Threat Distribution */
  securityCoachReportingThreatDistribution?: Maybe<ThreatDistribution>
  /** A rule detection event */
  securityCoachRuleDetectionEvent?: Maybe<DetectionEvent>
  /** Get a list of custom mapping rules for Security Coach */
  securityCoachRules: MappingRules
  /** Get total number of RTC campaigns per rule ID */
  securityCoachRulesCampaignsCounts: Array<RuleCampaignsCount>
  /** Get rule options for custom mapping rules for Security Coach */
  securityCoachRulesOptions: RuleOptionData
  /** Get a list of vendor keys available for custom mapping rules for Security Coach */
  securityCoachRulesVendors: Array<SelectOption>
  /** Get the SecurityCoach setup status for the account */
  securityCoachSetupStatus: SetupStatus
  /** List teachable moment system rule details */
  securityCoachSystemWorkflowDetail?: Maybe<TeachableMomentRules>
  /** List tmrs for IDs provided */
  securityCoachTeachableMomentRulesById?: Maybe<TeachableMomentRulesPayload>
  /** List threat rule dropdown conditions (IS, IS NOT, CONTAINS) */
  securityCoachThreatRuleDropdownConditions?: Maybe<Array<ThreatRuleDropdown>>
  /** List threat rule dropdown left hand side values */
  securityCoachThreatRuleDropdownLhs?: Maybe<Array<ThreatRuleDropdown>>
  /** List threat rule dropdown right hand side values */
  securityCoachThreatRuleDropdownRhs?: Maybe<Array<ThreatRuleDropdown>>
  /** Top detection rules */
  securityCoachTopDetectionRules?: Maybe<Array<TopDetectionRules>>
  /** List unlisted users */
  securityCoachUnlistedUsers?: Maybe<UnlistedUserPayload>
  /** Trend / count data for user discovery system */
  securityCoachUserDiscoveryTrend?: Maybe<UserDiscoveryTrendData>
  /** Validate user mapping data */
  securityCoachValidateUserMapping?: Maybe<ValidateUserMappingData>
  /** Retrieve activity status for all vendors */
  securityCoachVendorActivity?: Maybe<VendorActivityData>
  /** Retrieve registration details and status for a vendor */
  securityCoachVendorStatus?: Maybe<VendorStatusData>
  /** Retrieve vendors alerts */
  securityCoachVendorsAlerts?: Maybe<Array<VendorAlert>>
  /** Find a specific Security Role */
  securityRole: SecurityRole
  /** Find all Security Roles for an account */
  securityRoles: SecurityRolesCursor
  /** Find a Site Admin by ID */
  siteAdmin?: Maybe<SiteAdmin>
  /** Get data for Site Admin dashboard graphs */
  siteAdminDashboardGraphs?: Maybe<Scalars['JSON']>
  /** Find all Site Admins */
  siteAdmins: SiteAdminsCursor
  /** Fetch site settings */
  siteSettings: SiteSettings
  /** Find all smart host domains from PSM */
  smartHostDomains: SmartHostDomainsCursor
  /** Find all accounts that have the specified domain */
  smartHostMultiInstanceAccounts: SmartHostMultiInstanceAccountsCursor
  /** Find a store purchase */
  storePurchase?: Maybe<StorePurchase>
  /** Find all your store purchases */
  storePurchases: StorePurchasesCursor
  /** Find a subscription */
  subscription?: Maybe<Subscription>
  /** Find all subscriptions */
  subscriptions: SubscriptionsCursor
  /** Find a phishing template category by ID */
  templateCategory?: Maybe<PhishingTemplateCategory>
  /** Find all template languages */
  templateLanguages: TemplateLanguagesCursor
  /** Find all template topics */
  templateTopics: TemplateTopicsCursor
  /** Timeframe */
  timeframe: ReportTimeframe
  /** Find a trademark */
  trademark?: Maybe<Trademark>
  /** Find a trademark match */
  trademarkMatch?: Maybe<TrademarkMatch>
  /** Find all the trademarks matches for a trademark */
  trademarkMatches: TrademarkMatchesCursor
  /** Find all the trademarks */
  trademarks: TrademarksCursor
  /** Find a training campaign by ID */
  trainingCampaign?: Maybe<TrainingCampaign>
  /** Plot users completion data for a campaign */
  trainingCampaignCompletionGraph?: Maybe<Array<TimeData>>
  /** Find all training campaign course surveys */
  trainingCampaignSurveys: TrainingCampaignSurveysCursor
  /** Find all users withing a training campaign */
  trainingCampaignUsers: TrainingCampaignUsersCursor
  /** Find all training campaign */
  trainingCampaigns: TrainingCampaignsCursor
  /** Return responses for a given question and purchased course */
  trainingInteractionsResponses?: Maybe<TrainingInteractionsResponses>
  /** Return responses for a given question, groups, and purchased course */
  trainingInteractionsResponsesByGroups?: Maybe<TrainingInteractionsResponsesByGroups>
  /** Find users who match the desired training report */
  trainingReport: TrainingReportCursor
  /** Find a USB drive test by ID */
  usbCampaign?: Maybe<UsbCampaign>
  /** Find all USB drive test campaigns */
  usbCampaigns: UsbCampaignsCursor
  /** Find a single user */
  user?: Maybe<User>
  /** Find a single user by email */
  userByEmail?: Maybe<User>
  /** Fetch user event API keys */
  userEventApiKeys: Array<Key>
  /** Fetch User Event API usage statistics */
  userEventApiStats: Stats
  /** Fetch User Event API statuses */
  userEventApiStatuses: Statuses
  /** Return the current user preference */
  userPreference?: Maybe<UserSettingsPreferences>
  /** Find users who match the desired user report */
  userReport: UserReportCursor
  /** User timeline */
  userTimeline: TimelineEvents
  /** Find training campaigns for a given user */
  userTrainingCampaigns: UserTrainingCampaignsCursor
  /** Find all users for an account */
  users: UsersCursor
  /** Preview data for a potential merge users action */
  usersMergePreview: UsersMergePreview
  /** Lists a paginated set of webhooks */
  webhooks?: Maybe<Array<Webhook>>
}

export type QueryAccountArgs = {
  id?: Maybe<Scalars['Int']>
}

export type QueryAccountAssessmentResultsArgs = {
  assessmentType?: Maybe<AssessmentTypes>
}

export type QueryAccountSettingsArgs = {
  accountId?: Maybe<Scalars['Int']>
}

export type QueryAccountsArgs = {
  all?: Maybe<Scalars['Boolean']>
  archivedUsers?: Maybe<Scalars['Boolean']>
  billingType?: Maybe<AccountBillingTypes>
  excludeAnonymized?: Maybe<Scalars['Boolean']>
  otherPartnersAccounts?: Maybe<AccountPartnerInclusions>
  page?: Maybe<Scalars['Int']>
  partnerId?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  phishingEnabled?: Maybe<Scalars['Boolean']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<AccountSortFields>
  status?: Maybe<AccountStatuses>
  trainingEnabled?: Maybe<Scalars['Boolean']>
}

export type QueryAidaCampaignArgs = {
  timeframe?: Maybe<ReportsTimeframe>
}

export type QueryAidaDeterminationsArgs = {
  all?: Maybe<Scalars['Boolean']>
  includeFailed?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<AidaSummarySortFields>
}

export type QueryAidaPolicyQuizArgs = {
  policyAssetId: Scalars['Int']
}

export type QueryAllowedDomainsArgs = {
  accountId?: Maybe<Scalars['Int']>
  all?: Maybe<Scalars['Boolean']>
  includePending?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryApiTokenArgs = {
  id: Scalars['Int']
}

export type QueryApiTokenValidatorArgs = {
  key: Scalars['String']
  source: ApiScopes
}

export type QueryApiTokensArgs = {
  all?: Maybe<Scalars['Boolean']>
  excludeMobileTokens?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  type: ApiTokenTypes
}

export type QueryAssessmentDomainsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryAssessmentIndustryBenchmarksArgs = {
  all?: Maybe<Scalars['Boolean']>
  assessmentType?: Maybe<AssessmentTypes>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryAssessmentResultsArgs = {
  assessmentId: Scalars['Int']
  campaignId: Scalars['Int']
}

export type QueryAuditLogExtendedArgs = {
  id: Scalars['String']
}

export type QueryAuditLogObjectSearchArgs = {
  all?: Maybe<Scalars['Boolean']>
  objectIds?: Maybe<Array<Scalars['Int']>>
  objectSearch?: Maybe<Scalars['String']>
  objectType: AuditLogObjectTypes
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryAuditLogsArgs = {
  activities?: Maybe<Array<AuditLogActivities>>
  after?: Maybe<CursorPaginationCursorAttributes>
  before?: Maybe<CursorPaginationCursorAttributes>
  changes?: Maybe<Array<AuditLogChangeFields>>
  endTime?: Maybe<Scalars['ISO8601DateTime']>
  first?: Maybe<Scalars['Int']>
  impersonatingUserIds?: Maybe<Array<Scalars['Int']>>
  last?: Maybe<Scalars['Int']>
  objectCategories?: Maybe<Array<AuditLogObjectCategories>>
  objectIds?: Maybe<Array<Scalars['Int']>>
  objectTypes?: Maybe<Array<AuditLogObjectTypes>>
  onlyApiEvents?: Maybe<Scalars['Boolean']>
  parentPartnerAdminIds?: Maybe<Array<Scalars['Int']>>
  partnerAdminIds?: Maybe<Array<Scalars['Int']>>
  siteAdminIds?: Maybe<Array<Scalars['Int']>>
  startTime?: Maybe<Scalars['ISO8601DateTime']>
  timeframe?: Maybe<AuditLogSearchPeriods>
  userIds?: Maybe<Array<Scalars['Int']>>
}

export type QueryCallbackPhishingAudioTemplatePreviewArgs = {
  uuid?: Maybe<Scalars['String']>
}

export type QueryCallbackPhishingGreetingTemplateArgs = {
  id: Scalars['Int']
}

export type QueryCallbackPhishingGreetingTemplateLanguagesArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  types?: Maybe<Array<CallbackPhishingAudioTemplateTypes>>
}

export type QueryCallbackPhishingGreetingTemplatesArgs = {
  all?: Maybe<Scalars['Boolean']>
  languages?: Maybe<Array<Scalars['String']>>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<CallbackPhishingAudioTemplateSortFields>
  topicId?: Maybe<Scalars['Int']>
  topicIds?: Maybe<Array<Scalars['Int']>>
  translationUuids?: Maybe<Array<Scalars['String']>>
  type?: Maybe<CallbackPhishingAudioTemplateTypes>
}

export type QueryCallbackPhishingPhoneNumbersArgs = {
  all?: Maybe<Scalars['Boolean']>
  countryCodes?: Maybe<Array<CallbackPhishingPhoneNumberCountryCodes>>
  dedicated?: Maybe<CallbackPhishingDedicatedOptions>
  doNotDelete?: Maybe<CallbackPhishingDeleteOptions>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<CallbackPhishingPhoneNumberSortFields>
  statuses?: Maybe<Array<CallbackPhishingPhoneNumberStatuses>>
  tollFree?: Maybe<CallbackPhishingTollFreeOptions>
}

export type QueryCallbackPhishingResponseTemplateArgs = {
  id: Scalars['Int']
}

export type QueryCallbackPhishingResponseTemplateLanguagesArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  types?: Maybe<Array<CallbackPhishingAudioTemplateTypes>>
}

export type QueryCallbackPhishingResponseTemplatesArgs = {
  all?: Maybe<Scalars['Boolean']>
  languages?: Maybe<Array<Scalars['String']>>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<CallbackPhishingAudioTemplateSortFields>
  topicId?: Maybe<Scalars['Int']>
  topicIds?: Maybe<Array<Scalars['Int']>>
  translationUuids?: Maybe<Array<Scalars['String']>>
  type?: Maybe<CallbackPhishingAudioTemplateTypes>
}

export type QueryCallbackPhishingTemplateArgs = {
  id: Scalars['Int']
}

export type QueryCallbackPhishingTemplatePreviewArgs = {
  landingPageUuid?: Maybe<Scalars['String']>
  uuid?: Maybe<Scalars['String']>
}

export type QueryCallbackPhishingTemplatesArgs = {
  all?: Maybe<Scalars['Boolean']>
  difficulty?: Maybe<Array<EmailTemplateDifficultyRatings>>
  languages?: Maybe<Array<Scalars['String']>>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<EmailTemplateSortFields>
  topicId?: Maybe<Scalars['Int']>
  topicIds?: Maybe<Array<Scalars['Int']>>
  translationUuids?: Maybe<Array<Scalars['String']>>
  type?: Maybe<CallbackPhishingTemplateTypes>
}

export type QueryCcmItemsArgs = {
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryCdrmsGetBucketsArgs = {
  name: Scalars['String']
}

export type QueryCdrmsGetCategoriesArgs = {
  page?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
}

export type QueryCdrmsGetCategoryArgs = {
  id: Scalars['String']
}

export type QueryCdrmsGetRuleArgs = {
  id: Scalars['String']
}

export type QueryCdrmsGetRuleDisputesArgs = {
  orgIds?: Maybe<Array<Scalars['String']>>
  page?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
  ruleIds?: Maybe<Array<Scalars['String']>>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<Scalars['String']>
  status?: Maybe<RuleDisputeStatus>
  vendorCodeNames?: Maybe<Array<Scalars['String']>>
}

export type QueryCdrmsGetRuleOdArgs = {
  id: Scalars['String']
}

export type QueryCdrmsGetRuleProductionArgs = {
  id: Scalars['String']
}

export type QueryCdrmsGetRulesArgs = {
  category?: Maybe<Array<Scalars['String']>>
  page?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDesc?: Maybe<Scalars['Boolean']>
  sortField?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  topic?: Maybe<Array<Scalars['String']>>
  vendorCodeName?: Maybe<Array<Scalars['String']>>
}

export type QueryCdrmsGetTopicArgs = {
  id: Scalars['String']
}

export type QueryCdrmsGetTopicsArgs = {
  page?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
}

export type QueryCdrmsGetVendorArgs = {
  id: Scalars['String']
}

export type QueryCdrmsGetVendorOdArgs = {
  id: Scalars['String']
}

export type QueryCdrmsGetVendorProductionArgs = {
  id: Scalars['String']
}

export type QueryCdrmsGetVendorsArgs = {
  page?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
}

export type QueryChildrensLibraryAccountsArgs = {
  all?: Maybe<Scalars['Boolean']>
  managedStorePurchaseId: Scalars['Int']
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryClickwrapsArgs = {
  all?: Maybe<Scalars['Boolean']>
  formerAdmins?: Maybe<ClickwrapFormerAdminStatuses>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<ClickwrapSortFields>
  status?: Maybe<ClickwrapStatuses>
}

export type QueryContentThemeArgs = {
  id?: Maybe<Scalars['Int']>
}

export type QueryContentThemesArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryCriterionCountArgs = {
  criterions: Array<GroupCriterionAttributes>
  groupId: Scalars['Int']
}

export type QueryDemoSeedStatusesArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryDetectionsArgs = {
  all?: Maybe<Scalars['Boolean']>
  from?: Maybe<Scalars['ISO8601Date']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  rule?: Maybe<Scalars['String']>
  search?: Maybe<Scalars['String']>
  vendor?: Maybe<Scalars['String']>
}

export type QueryDirectReportsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  userId?: Maybe<Scalars['Int']>
}

export type QueryDkimConfigArgs = {
  all?: Maybe<Scalars['Boolean']>
  id: Scalars['Int']
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryDmiConfigArgs = {
  id: Scalars['Int']
}

export type QueryDmiConfigsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryDownloadArgs = {
  id: Scalars['Int']
}

export type QueryDownloadsReportConfigFiltersArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryEnrollmentArgs = {
  id: Scalars['Int']
}

export type QueryEnrollmentForPolicyBasicArgs = {
  id?: Maybe<Scalars['Int']>
}

export type QueryExecReportsDashboardArgs = {
  id: Scalars['Int']
}

export type QueryExecReportsPhishingActivitiesArgs = {
  aggregation?: Maybe<Scalars['String']>
  dateInterval?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  disableNonePlaceholderCategory?: Maybe<Scalars['Boolean']>
  disableNonePlaceholderGroupBy?: Maybe<Scalars['Boolean']>
  endDate?: Maybe<Scalars['ISO8601DateTime']>
  graphType?: Maybe<Scalars['String']>
  groupBy?: Maybe<Scalars['String']>
  groupFiltersEnabled?: Maybe<Scalars['Boolean']>
  groupIds?: Maybe<Array<Scalars['Int']>>
  includeArchivedUsers?: Maybe<Scalars['Boolean']>
  legendPosition?: Maybe<Scalars['String']>
  overrideReportDateSettings?: Maybe<Scalars['Boolean']>
  overrideReportGroupSettings?: Maybe<Scalars['Boolean']>
  showMarginsCategory?: Maybe<Scalars['Boolean']>
  showMarginsGroupBy?: Maybe<Scalars['Boolean']>
  startDate?: Maybe<Scalars['ISO8601DateTime']>
  title?: Maybe<Scalars['String']>
  topN?: Maybe<Scalars['Int']>
  xAxis?: Maybe<Scalars['String']>
}

export type QueryExecReportsRiskScoreArgs = {
  aggregation?: Maybe<Scalars['String']>
  dateInterval?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  disableNonePlaceholderCategory?: Maybe<Scalars['Boolean']>
  disableNonePlaceholderGroupBy?: Maybe<Scalars['Boolean']>
  endDate?: Maybe<Scalars['ISO8601DateTime']>
  graphType?: Maybe<Scalars['String']>
  groupBy?: Maybe<Scalars['String']>
  groupFiltersEnabled?: Maybe<Scalars['Boolean']>
  groupIds?: Maybe<Array<Scalars['Int']>>
  includeArchivedUsers?: Maybe<Scalars['Boolean']>
  legendPosition?: Maybe<Scalars['String']>
  overrideReportDateSettings?: Maybe<Scalars['Boolean']>
  overrideReportGroupSettings?: Maybe<Scalars['Boolean']>
  showMarginsCategory?: Maybe<Scalars['Boolean']>
  showMarginsGroupBy?: Maybe<Scalars['Boolean']>
  startDate?: Maybe<Scalars['ISO8601DateTime']>
  title?: Maybe<Scalars['String']>
  topN?: Maybe<Scalars['Int']>
  xAxis?: Maybe<Scalars['String']>
}

export type QueryExecReportsTextArgs = {
  content?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type QueryExecReportsTitleArgs = {
  content?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  showLogo?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
}

export type QueryExecReportsTrainingActivitiesArgs = {
  aggregation?: Maybe<Scalars['String']>
  dateInterval?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  disableNonePlaceholderCategory?: Maybe<Scalars['Boolean']>
  disableNonePlaceholderGroupBy?: Maybe<Scalars['Boolean']>
  endDate?: Maybe<Scalars['ISO8601DateTime']>
  graphType?: Maybe<Scalars['String']>
  groupBy?: Maybe<Scalars['String']>
  groupFiltersEnabled?: Maybe<Scalars['Boolean']>
  groupIds?: Maybe<Array<Scalars['Int']>>
  includeArchivedUsers?: Maybe<Scalars['Boolean']>
  legendPosition?: Maybe<Scalars['String']>
  overrideReportDateSettings?: Maybe<Scalars['Boolean']>
  overrideReportGroupSettings?: Maybe<Scalars['Boolean']>
  showMarginsCategory?: Maybe<Scalars['Boolean']>
  showMarginsGroupBy?: Maybe<Scalars['Boolean']>
  startDate?: Maybe<Scalars['ISO8601DateTime']>
  title?: Maybe<Scalars['String']>
  topN?: Maybe<Scalars['Int']>
  trainingCampaignCriteria?: Maybe<Scalars['String']>
  trainingCampaignIds?: Maybe<Array<Maybe<Scalars['Int']>>>
  xAxis?: Maybe<Scalars['String']>
}

export type QueryFreeTestEmailsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  type: FreeToolTypes
}

export type QueryFreeToolCampaignArgs = {
  inactive?: Maybe<Scalars['Boolean']>
  toolType: FreeToolTypes
}

export type QueryFreeToolDataArgs = {
  toolType: FreeToolTypes
}

export type QueryFreeToolReportArgs = {
  freeToolType: FreeToolTypes
  payload: Scalars['String']
}

export type QueryGoogleUserProvisioningDomainsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryGoogleUserProvisioningGroupsArgs = {
  all?: Maybe<Scalars['Boolean']>
  domain: Scalars['String']
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
}

export type QueryGoogleUserProvisioningUsersArgs = {
  all?: Maybe<Scalars['Boolean']>
  domain: Scalars['String']
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
}

export type QueryGpabMagicMailTokenArgs = {
  phishalertSettingId: Scalars['Int']
}

export type QueryGraphSuccessArgs = {
  license: Scalars['String']
}

export type QueryGroupArgs = {
  id: Scalars['Int']
}

export type QueryGroupsArgs = {
  accountIds?: Maybe<Array<Scalars['Int']>>
  all?: Maybe<Scalars['Boolean']>
  ids?: Maybe<Array<Scalars['Int']>>
  includeAnonymizePhishing?: Maybe<Scalars['Boolean']>
  order?: Maybe<SortDirections>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  permissionScope?: Maybe<GroupPermissionScopes>
  search?: Maybe<Scalars['String']>
  sortBy?: Maybe<GroupSortFields>
  status?: Maybe<GroupStatuses>
  type?: Maybe<GroupTypes>
}

export type QueryIndustriesArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryIndustryBenchmarksArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryIpRangeArgs = {
  id: Scalars['Int']
}

export type QueryIpRangesArgs = {
  all?: Maybe<Scalars['Boolean']>
  designation?: Maybe<IpRangeDesignations>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortBy?: Maybe<IpRangesSortFields>
  sortDirection?: Maybe<SortDirections>
}

export type QueryKnowledgeRefresherQuizArgs = {
  enrollmentId: Scalars['ID']
}

export type QueryLandingDomainArgs = {
  id: Scalars['Int']
}

export type QueryLandingDomainsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<LandingDomainSortFields>
  status?: Maybe<ActiveStatuses>
}

export type QueryLandingPageArgs = {
  id?: Maybe<Scalars['Int']>
  uuid?: Maybe<Scalars['String']>
}

export type QueryLandingPageCategoriesArgs = {
  all?: Maybe<Scalars['Boolean']>
  type?: Maybe<LandingPageTypes>
}

export type QueryLandingPageCountsArgs = {
  type?: Maybe<LandingPageTypes>
}

export type QueryLandingPagesArgs = {
  all?: Maybe<Scalars['Boolean']>
  categoryId?: Maybe<Scalars['Int']>
  drafts?: Maybe<Scalars['Boolean']>
  ids?: Maybe<Array<Scalars['Int']>>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<LandingPageSortFields>
  type?: Maybe<LandingPageTypes>
}

export type QueryLdapSyncReportArgs = {
  id: Scalars['Int']
}

export type QueryLdapSyncReportItemsArgs = {
  action: LdapSyncReportItemActions
  all?: Maybe<Scalars['Boolean']>
  ldapSyncReportId: Scalars['Int']
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  status?: Maybe<LdapSyncReportItemStatuses>
  type: LdapSyncReportItemTypes
}

export type QueryLdapSyncReportsArgs = {
  all?: Maybe<Scalars['Boolean']>
  filterSource?: Maybe<LdapSyncReportSourceFilters>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryManagedPhishingCampaignArgs = {
  id: Scalars['Int']
}

export type QueryManagedPhishingCampaignsArgs = {
  all?: Maybe<Scalars['Boolean']>
  filter?: Maybe<PhishingCampaignFilters>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryManagedStorePurchaseArgs = {
  id: Scalars['Int']
}

export type QueryManagedStorePurchasesArgs = {
  all?: Maybe<Scalars['Boolean']>
  onlyTraining?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  searchString?: Maybe<Scalars['String']>
  showHidden?: Maybe<Scalars['Boolean']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<StorePurchaseSortFields>
  storeItemTypes?: Maybe<Array<StoreItemTypes>>
  uuids?: Maybe<Array<Scalars['String']>>
  withCcm?: Maybe<Scalars['Boolean']>
}

export type QueryManagedTrainingCampaignArgs = {
  id: Scalars['Int']
}

export type QueryManagedTrainingCampaignsArgs = {
  all?: Maybe<Scalars['Boolean']>
  filter?: Maybe<TrainingCampaignFilters>
  managedTrainingCampaignIds?: Maybe<Array<Scalars['Int']>>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<TrainingCampaignSortFields>
}

export type QueryManagedTrainingCourseCheckArgs = {
  accountIds: Array<Scalars['Int']>
  managedStorePurchaseIds: Array<Scalars['Int']>
}

export type QueryManagerReportArgs = {
  userId?: Maybe<Scalars['Int']>
}

export type QueryMessageCampaignArgs = {
  id: Scalars['Int']
}

export type QueryMessageCampaignsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  pendingCustom?: Maybe<Scalars['Boolean']>
  per?: Maybe<Scalars['Int']>
  recurring?: Maybe<Scalars['Boolean']>
}

export type QueryMessageRunArgs = {
  id: Scalars['Int']
}

export type QueryMessageRunRecipientsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  runId: Scalars['Int']
  search?: Maybe<Scalars['String']>
  status?: Maybe<MessagesCampaignRecipientStatuses>
}

export type QueryMessageRunsArgs = {
  all?: Maybe<Scalars['Boolean']>
  campaignId?: Maybe<Scalars['Int']>
  campaignType?: Maybe<MessagesCampaignTypes>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  query?: Maybe<Scalars['String']>
}

export type QueryMultiInstanceAccountsArgs = {
  billingType?: Maybe<AccountBillingTypes>
  excludeAnonymized?: Maybe<Scalars['Boolean']>
  otherPartnersAccounts?: Maybe<AccountPartnerInclusions>
  phishingEnabled?: Maybe<Scalars['Boolean']>
  search?: Maybe<Scalars['String']>
  status?: Maybe<AccountStatuses>
}

export type QueryNotificationTemplateArgs = {
  id: Scalars['Int']
}

export type QueryNotificationTemplateCategoriesArgs = {
  showHidden?: Maybe<Scalars['Boolean']>
  type?: Maybe<NotificationTemplateCategoryTypes>
}

export type QueryNotificationTemplateCountArgs = {
  type?: Maybe<NotificationTemplateTypes>
}

export type QueryNotificationTemplateDraftsArgs = {
  type: NotificationTemplateCategoryTypes
}

export type QueryNotificationTemplatePreviewArgs = {
  emailTemplateUuid?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
}

export type QueryNotificationTemplatesArgs = {
  all?: Maybe<Scalars['Boolean']>
  categoryIds?: Maybe<Array<Scalars['Int']>>
  drafts?: Maybe<Scalars['Boolean']>
  includeArchived?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  showHidden?: Maybe<Scalars['Boolean']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<EmailTemplateSortFields>
  type?: Maybe<NotificationTemplateTypes>
}

export type QueryOutlookAddinActivityUsersArgs = {
  accountId?: Maybe<Scalars['Int']>
  activityStatus?: Maybe<UserHasOutlookAddinActivityTypes>
  activityType?: Maybe<OutlookAddinActivityActivityTypes>
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<OutlookAddinActivityUsersSortFields>
  status?: Maybe<UserStatusFilters>
}

export type QueryPackageFileListArgs = {
  enrollmentId: Scalars['Int']
  languageCode: Scalars['String']
}

export type QueryPartnerArgs = {
  id?: Maybe<Scalars['Int']>
}

export type QueryPartnerAdminArgs = {
  id: Scalars['Int']
}

export type QueryPartnerAdminsArgs = {
  all?: Maybe<Scalars['Boolean']>
  fullAdminsOnly?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  partnerId?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<PartnerAdminsSortFields>
}

export type QueryPartnerApiTokenArgs = {
  id?: Maybe<Scalars['Int']>
}

export type QueryPartnerApiTokensArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryPartnersArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  partnerId?: Maybe<Scalars['Int']>
  partnerType?: Maybe<PartnerTypes>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<PartnersSortFields>
}

export type QueryPasswordIqAuditLogS3DataArgs = {
  key: Scalars['String']
}

export type QueryPasswordIqAuditLogsArgs = {
  action?: Maybe<Array<Scalars['String']>>
  application?: Maybe<Array<Scalars['String']>>
  dateRange?: Maybe<AuditLogsDateRange>
  pagination?: Maybe<AuditLogsPaginationAttributes>
  product?: Maybe<Array<Scalars['String']>>
  sort?: Maybe<Scalars['String']>
  status?: Maybe<Array<Scalars['String']>>
}

export type QueryPasswordIqDashboardArgs = {
  id: Scalars['Int']
}

export type QueryPasswordIqDetectionCountsArgs = {
  groups?: Maybe<Array<Scalars['Int']>>
  userType?: Maybe<PiqUserTypes>
}

export type QueryPasswordIqGraphArgs = {
  detection?: Maybe<DetectionTypes>
  groups?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
  userType?: Maybe<PiqUserTypes>
}

export type QueryPasswordIqPieArgs = {
  detection?: Maybe<DetectionTypes>
  groups?: Maybe<Array<Scalars['Int']>>
  userType?: Maybe<PiqUserTypes>
}

export type QueryPasswordIqUserStatesArgs = {
  detection?: Maybe<Array<DetectionTypes>>
  pagination: PiqPaginationAttributes
  search?: Maybe<Scalars['String']>
  sort?: Maybe<UserStatesSort>
  userType?: Maybe<PiqUserTypes>
}

export type QueryPasswordIqVarianceArgs = {
  detection?: Maybe<DetectionTypes>
  groups?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
  userType?: Maybe<PiqUserTypes>
}

export type QueryPhishDomainArgs = {
  id: Scalars['Int']
}

export type QueryPhishDomainsArgs = {
  all?: Maybe<Scalars['Boolean']>
  domainType?: Maybe<PhishDomainTypes>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  replyToEnabled?: Maybe<Scalars['Boolean']>
  search?: Maybe<Scalars['String']>
  showHidden?: Maybe<Scalars['Boolean']>
  sortBy?: Maybe<PhishDomainSortFields>
  sortDirection?: Maybe<SortDirections>
  status?: Maybe<ActiveStatuses>
}

export type QueryPhishingCampaignArgs = {
  id: Scalars['Int']
}

export type QueryPhishingCampaignNonRecipientsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  runId: Scalars['Int']
}

export type QueryPhishingCampaignRecipientArgs = {
  campaignRunId?: Maybe<Scalars['Int']>
  id: Scalars['ID']
}

export type QueryPhishingCampaignRecipientsArgs = {
  all?: Maybe<Scalars['Boolean']>
  campaignRunId: Scalars['Int']
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryPhishingCampaignRunArgs = {
  id: Scalars['Int']
}

export type QueryPhishingCampaignRunsArgs = {
  all?: Maybe<Scalars['Boolean']>
  campaignId?: Maybe<Scalars['Int']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryPhishingCampaignsArgs = {
  all?: Maybe<Scalars['Boolean']>
  filter?: Maybe<PhishingCampaignFilters>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<PhishingCampaignSortFields>
  type?: Maybe<PhishingCampaignTypes>
}

export type QueryPhishingLocalesForGroupsArgs = {
  groupIds?: Maybe<Array<Scalars['Int']>>
}

export type QueryPhishingTemplateArgs = {
  id: Scalars['Int']
}

export type QueryPhishingTemplateCategoriesArgs = {
  all?: Maybe<Scalars['Boolean']>
  showHidden?: Maybe<Scalars['Boolean']>
  showNonPst?: Maybe<Scalars['Boolean']>
  type?: Maybe<PhishingTemplateCategoryTypes>
}

export type QueryPhishingTemplateCategoryArgs = {
  id: Scalars['Int']
}

export type QueryPhishingTemplateCategoryRecommendationsArgs = {
  languageCodes: Array<Scalars['String']>
}

export type QueryPhishingTemplateCountArgs = {
  showNonPst?: Maybe<Scalars['Boolean']>
  type?: Maybe<PhishingTemplateTypes>
}

export type QueryPhishingTemplateDraftsArgs = {
  type: PhishingTemplateCategoryTypes
}

export type QueryPhishingTemplatePreviewArgs = {
  freeToolType?: Maybe<FreeToolTypes>
  id?: Maybe<Scalars['Int']>
  landingPageId?: Maybe<Scalars['Int']>
  landingPageUuid?: Maybe<Scalars['String']>
  prtSenderEmail?: Maybe<Scalars['String']>
  prtSenderName?: Maybe<Scalars['String']>
  uuid?: Maybe<Scalars['String']>
}

export type QueryPhishingTemplatesArgs = {
  all?: Maybe<Scalars['Boolean']>
  categoryId?: Maybe<Scalars['Int']>
  categoryIds?: Maybe<Array<Scalars['Int']>>
  difficulty?: Maybe<Array<EmailTemplateDifficultyRatings>>
  drafts?: Maybe<Scalars['Boolean']>
  languages?: Maybe<Array<Scalars['String']>>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  showExcluded?: Maybe<Scalars['Boolean']>
  showHidden?: Maybe<Scalars['Boolean']>
  showNonPst?: Maybe<Scalars['Boolean']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<EmailTemplateSortFields>
  topicId?: Maybe<Scalars['Int']>
  topicIds?: Maybe<Array<Scalars['Int']>>
  translationUuids?: Maybe<Array<Scalars['String']>>
  type?: Maybe<PhishingTemplateTypes>
}

export type QueryPhishingTemplatesAsapArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryPhysicalQrCampaignArgs = {
  id: Scalars['Int']
}

export type QueryPhysicalQrCampaignLocationArgs = {
  id: Scalars['Int']
  physicalQrCampaignId: Scalars['Int']
}

export type QueryPhysicalQrCampaignResultsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  physicalQrCampaignId: Scalars['Int']
  physicalQrCampaignLocationId?: Maybe<Scalars['Int']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<PhysicalQrCampaignResultsSortFields>
}

export type QueryPhysicalQrCampaignsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<PhysicalQrCampaignsSortFields>
}

export type QueryPhysicalQrTemplateArgs = {
  id: Scalars['Int']
}

export type QueryPhysicalQrTemplatePreviewArgs = {
  landingPageId?: Maybe<Scalars['Int']>
  uuid?: Maybe<Scalars['String']>
}

export type QueryPhysicalQrTemplatesArgs = {
  all?: Maybe<Scalars['Boolean']>
  difficulty?: Maybe<Array<EmailTemplateDifficultyRatings>>
  languages?: Maybe<Array<Scalars['String']>>
  noDrafts?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<PhysicalQrTemplateSortFields>
  topicId?: Maybe<Scalars['Int']>
  topicIds?: Maybe<Array<Scalars['Int']>>
  type?: Maybe<PhysicalQrTemplateTypes>
}

export type QueryPoliciesArgs = {
  all?: Maybe<Scalars['Boolean']>
  ids?: Maybe<Array<Scalars['Int']>>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<PoliciesSortFields>
  statuses?: Maybe<Array<PolicyStatuses>>
}

export type QueryPolicyArgs = {
  id: Scalars['Int']
}

export type QueryPolicyBasicArgs = {
  id: Scalars['Int']
}

export type QueryPolicyQuizArgs = {
  policyAssetId: Scalars['Int']
}

export type QueryPurchasedCourseArgs = {
  id: Scalars['Int']
}

export type QueryPurchasedCoursesArgs = {
  all?: Maybe<Scalars['Boolean']>
  archived?: Maybe<Scalars['Boolean']>
  contentOptionsEdited?: Maybe<Scalars['Boolean']>
  excludeHidden?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  purchasedCourseIds?: Maybe<Array<Scalars['String']>>
  storeItemTypes?: Maybe<Array<StoreItemTypes>>
  uuids?: Maybe<Array<Scalars['String']>>
}

export type QueryReportCategoriesArgs = {
  includeEmpty?: Maybe<Scalars['Boolean']>
  includeOthers?: Maybe<Scalars['Boolean']>
}

export type QueryReportDownloadsArgs = {
  all?: Maybe<Scalars['Boolean']>
  asPartner?: Maybe<Scalars['Boolean']>
  generated?: Maybe<Scalars['Boolean']>
  groupIds?: Maybe<Array<Maybe<Scalars['Int']>>>
  includeNaGroups?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  reportTypes?: Maybe<Array<DownloadablesTypes>>
  searchReportTypes?: Maybe<Array<DownloadablesTypes>>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<DownloadsSortFields>
}

export type QueryReportPermissionGroupsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  reportPermissionName: ReportPermissionNames
}

export type QueryReportsActiveRulesReportArgs = {
  payload: ReportsTypesActiveRulesReport
}

export type QueryReportsAidaCampaignSummaryTableCsvArgs = {
  payload: ReportsTypesAidaCampaignSummaryTable
}

export type QueryReportsAssessmentScoreByKnowledgeAreaArgs = {
  payload: ReportsTypesAssessmentScoreByKnowledgeArea
}

export type QueryReportsAssessmentScoreByKnowledgeAreaOverTimeArgs = {
  payload: ReportsTypesAssessmentScoreByKnowledgeAreaOverTime
}

export type QueryReportsAuditLogsArgs = {
  payload: ReportsTypesAuditLogs
}

export type QueryReportsEmailsSentByGroupArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  payload: ReportsTypesEmailsSentByGroup
  per?: Maybe<Scalars['Int']>
}

export type QueryReportsEmailsSentByGroupCsvArgs = {
  payload: ReportsTypesEmailsSentByGroup
}

export type QueryReportsEmailsSentByTemplateArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  payload: ReportsTypesEmailsSentByTemplate
  per?: Maybe<Scalars['Int']>
}

export type QueryReportsEmailsSentByTemplateCsvArgs = {
  payload: ReportsTypesEmailsSentByTemplate
}

export type QueryReportsFailureTypeUserCountsArgs = {
  payload: ReportsTypesFailureTypeUserCounts
}

export type QueryReportsFailureTypesBarChartArgs = {
  payload: ReportsTypesFailureTypesBarChart
}

export type QueryReportsFailuresByPhishingTemplateArgs = {
  payload: ReportsTypesFailuresByPhishingTemplate
}

export type QueryReportsGlobalPhishingAggregateArgs = {
  payload: ReportsTypesGlobalPhishingAggregate
}

export type QueryReportsGlobalPhishingAggregateCsvArgs = {
  payload: ReportsTypesGlobalPhishingAggregate
}

export type QueryReportsGlobalPhishingAggregateTableArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  payload: ReportsTypesGlobalPhishingAggregate
  per?: Maybe<Scalars['Int']>
}

export type QueryReportsGlobalPhishingRawArgs = {
  payload: ReportsTypesGlobalPhishingRaw
}

export type QueryReportsGlobalPhishingRawCsvArgs = {
  payload: ReportsTypesGlobalPhishingRaw
}

export type QueryReportsGlobalPhishingRawTableArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  payload: ReportsTypesGlobalPhishingRaw
  per?: Maybe<Scalars['Int']>
}

export type QueryReportsGlobalTrainingAggregateArgs = {
  payload: ReportsTypesGlobalTrainingAggregate
}

export type QueryReportsGlobalTrainingAggregateChartArgs = {
  payload: ReportsTypesGlobalTrainingAggregate
}

export type QueryReportsGlobalTrainingAggregateCsvArgs = {
  payload: ReportsTypesGlobalTrainingAggregate
}

export type QueryReportsGlobalTrainingAggregateTableArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  payload: ReportsTypesGlobalTrainingAggregate
  per?: Maybe<Scalars['Int']>
}

export type QueryReportsGlobalTrainingRawArgs = {
  payload: ReportsTypesGlobalTrainingRaw
}

export type QueryReportsGlobalTrainingRawChartArgs = {
  payload: ReportsTypesGlobalTrainingRaw
}

export type QueryReportsGlobalTrainingRawCsvArgs = {
  payload: ReportsTypesGlobalTrainingRaw
}

export type QueryReportsGlobalTrainingRawTableArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  payload: ReportsTypesGlobalTrainingRaw
  per?: Maybe<Scalars['Int']>
}

export type QueryReportsIncompleteTrainingArgs = {
  payload: ReportsTypesIncompleteTraining
}

export type QueryReportsInitialPppArgs = {
  payload: ReportsTypesInitialPpp
}

export type QueryReportsLearnerPlatformRawArgs = {
  payload: ReportsTypesLearnerPlatformRaw
}

export type QueryReportsLearnerPlatformRawChartArgs = {
  payload: ReportsTypesLearnerPlatformRaw
}

export type QueryReportsLearnerPlatformRawCsvArgs = {
  payload: ReportsTypesLearnerPlatformRaw
}

export type QueryReportsLearnerPlatformRawTableArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  payload: ReportsTypesLearnerPlatformRaw
  per?: Maybe<Scalars['Int']>
}

export type QueryReportsMostClickedEmailTemplatesArgs = {
  payload: ReportsTypesMostClickedEmailTemplates
}

export type QueryReportsNoTrainingAssignedArgs = {
  payload: ReportsTypesNoTrainingAssigned
}

export type QueryReportsOverallPppByAccountArgs = {
  payload: ReportsTypesOverallPppByAccount
}

export type QueryReportsOverallPppByCampaignArgs = {
  payload: ReportsTypesOverallPppByCampaign
}

export type QueryReportsOverallPppByGroupArgs = {
  payload: ReportsTypesOverallPppByGroup
}

export type QueryReportsOverallPppByGroupCardArgs = {
  payload: ReportsTypesOverallPppByGroup
}

export type QueryReportsOverallPppByTrainingTimeArgs = {
  payload: ReportsTypesOverallPppByTrainingTime
}

export type QueryReportsOverallPppByUserArgs = {
  payload: ReportsTypesOverallPppByUser
}

export type QueryReportsPabComparisonArgs = {
  payload: ReportsTypesPabComparison
}

export type QueryReportsPabUserActivityArgs = {
  payload: ReportsTypesPabUserActivity
}

export type QueryReportsPhishFailurePercentageByCampaignsArgs = {
  payload: ReportsTypesPhishFailurePercentageByCampaigns
}

export type QueryReportsPhishFailurePercentageOverallArgs = {
  payload: ReportsTypesPhishFailurePercentageOverall
}

export type QueryReportsPhishingAllClickersArgs = {
  payload: ReportsTypesPhishingAllClickers
}

export type QueryReportsPhishingSecurityTestsArgs = {
  payload: ReportsTypesPhishingSecurityTests
}

export type QueryReportsPppDistributionArgs = {
  payload: ReportsTypesPppDistribution
}

export type QueryReportsRiskScoreByAssessmentArgs = {
  payload: ReportsTypesRiskScoreByAssessment
}

export type QueryReportsRiskScoreByGroupArgs = {
  payload: ReportsTypesRiskScoreByGroup
}

export type QueryReportsRiskScoreByGroupCardArgs = {
  payload: ReportsTypesRiskScoreByGroup
}

export type QueryReportsRiskScoreByLocationArgs = {
  payload: ReportsTypesRiskScoreByLocation
}

export type QueryReportsRiskScoreBySurveyArgs = {
  payload: ReportsTypesRiskScoreBySurvey
}

export type QueryReportsRiskScoreByUserArgs = {
  payload: ReportsTypesRiskScoreByUser
}

export type QueryReportsRiskScoreHistoryByUserArgs = {
  payload: ReportsTypesRiskScoreHistoryByUser
}

export type QueryReportsRiskScoreV2ByGroupArgs = {
  payload: ReportsTypesRiskScoreV2ByGroup
}

export type QueryReportsRiskScoreV2ByManagerArgs = {
  payload: ReportsTypesRiskScoreV2ByManager
}

export type QueryReportsRiskScoreV2ByUserArgs = {
  payload: ReportsTypesRiskScoreV2ByUser
}

export type QueryReportsSecurityCoachActivityRtcAggregateArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  payload: ReportsTypesSecurityCoachActivityRtcAggregate
  per?: Maybe<Scalars['Int']>
}

export type QueryReportsSecurityCoachActivityRtcAggregateCsvArgs = {
  payload: ReportsTypesSecurityCoachActivityRtcAggregate
}

export type QueryReportsSecurityCoachActivityRtcRawArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  payload: ReportsTypesSecurityCoachActivityRtcRaw
  per?: Maybe<Scalars['Int']>
}

export type QueryReportsSecurityCoachActivityRtcRawCsvArgs = {
  payload: ReportsTypesSecurityCoachActivityRtcRaw
}

export type QueryReportsSecurityCoachDetectionRulesActivityAggregateArgs = {
  page: Scalars['Int']
  payload: ReportsTypesSecurityCoachDetectionRulesActivityAggregate
  per: Scalars['Int']
}

export type QueryReportsSecurityCoachDetectionRulesActivityAggregateCsvArgs = {
  payload: ReportsTypesSecurityCoachDetectionRulesActivityAggregate
}

export type QueryReportsSecurityCoachDetectionRulesActivityRawArgs = {
  page: Scalars['Int']
  payload: ReportsTypesSecurityCoachDetectionRulesActivityRaw
  per: Scalars['Int']
}

export type QueryReportsSecurityCoachDetectionRulesActivityRawCsvArgs = {
  payload: ReportsTypesSecurityCoachDetectionRulesActivityRaw
}

export type QueryReportsSurveyScoreByDimensionArgs = {
  payload: ReportsTypesSurveyScoreByDimension
}

export type QueryReportsSurveyScoreByDimensionOverTimeArgs = {
  payload: ReportsTypesSurveyScoreByDimensionOverTime
}

export type QueryReportsTrainingCampaignEnrollmentsArgs = {
  payload: ReportsTypesTrainingCampaignEnrollments
}

export type QueryReportsTrainingCampaignSurveysArgs = {
  payload: ReportsTypesTrainingCampaignSurveys
}

export type QueryReportsTrainingComparisonByPppArgs = {
  payload: ReportsTypesTrainingComparisonByPpp
}

export type QueryReportsTrainingComparisonByRiskScoreArgs = {
  payload: ReportsTypesTrainingComparisonByRiskScore
}

export type QueryReportsTrainingHoursArgs = {
  payload: ReportsTypesTrainingHours
}

export type QueryReportsTrainingInteractionsByGroupArgs = {
  payload: ReportsTypesTrainingInteractionsByGroup
}

export type QueryReportsTrainingInteractionsByGroupCsvArgs = {
  payload: ReportsTypesTrainingInteractionsByGroup
}

export type QueryReportsTrainingInteractionsByPurchasedCourseArgs = {
  payload: ReportsTypesTrainingInteractionsByPurchasedCourse
}

export type QueryReportsTrainingInteractionsByPurchasedCourseCsvArgs = {
  payload: ReportsTypesTrainingInteractionsByPurchasedCourse
}

export type QueryReportsTrainingNotStartedArgs = {
  payload: ReportsTypesTrainingNotStarted
}

export type QueryReportsTrainingStatusByCampaignArgs = {
  payload: ReportsTypesTrainingStatusByCampaign
}

export type QueryReportsTrainingStatusByCampaignCardArgs = {
  payload: ReportsTypesTrainingStatusByCampaign
}

export type QueryReportsTrainingStatusByCourseArgs = {
  payload: ReportsTypesTrainingStatusByCourse
}

export type QueryReportsTrainingStatusByGroupArgs = {
  payload: ReportsTypesTrainingStatusByGroup
}

export type QueryReportsTrainingStatusesByCampaignArgs = {
  payload: ReportsTypesTrainingStatusesByCampaign
}

export type QueryReportsTrainingSurveysByCampaignArgs = {
  payload: ReportsTypesTrainingSurveysByCampaign
}

export type QueryReportsTrainingSurveysByCourseArgs = {
  payload: ReportsTypesTrainingSurveysByCourse
}

export type QueryReportsUnphishedUsersArgs = {
  payload: ReportsTypesUnphishedUsers
}

export type QueryReportsUserCountByAssessmentArgs = {
  payload: ReportsTypesUserCountByAssessment
}

export type QueryReportsUserCountBySurveyArgs = {
  payload: ReportsTypesUserCountBySurvey
}

export type QueryReportsUserCountsArgs = {
  payload: ReportsTypesUserCounts
}

export type QueryReportsUserCountsCardArgs = {
  payload: ReportsTypesUserCounts
}

export type QueryReportsUserPhishingCampaignsCsvArgs = {
  payload: ReportsTypesUserPhishingCampaigns
}

export type QueryReportsUserSignInsArgs = {
  payload: ReportsTypesUserSignIns
}

export type QueryReportsUserTrainingCampaignsCsvArgs = {
  payload: ReportsTypesUserTrainingCampaigns
}

export type QueryReportsUsersListArgs = {
  payload: ReportsTypesUsersList
}

export type QueryRestrictedDomainArgs = {
  id: Scalars['Int']
}

export type QueryRestrictedDomainsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<RestrictedDomainSortFields>
  tld?: Maybe<Scalars['Boolean']>
}

export type QuerySavedReportArgs = {
  id: Scalars['Int']
}

export type QuerySavedReportsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  payload?: Maybe<SavedReportsList>
  per?: Maybe<Scalars['Int']>
}

export type QuerySavedReportsFiltersArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryScheduledReportArgs = {
  id: Scalars['Int']
}

export type QueryScheduledReportsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  payload?: Maybe<ScheduledReportsList>
  per?: Maybe<Scalars['Int']>
}

export type QuerySecondChanceDeviceArgs = {
  id: Scalars['Int']
}

export type QuerySecondChanceDevicesArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<SecondChanceDeviceSortFields>
}

export type QuerySecondChanceDomainPromptsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  startDays?: Maybe<Scalars['Int']>
}

export type QuerySecondChanceDomainsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<SecondChanceDomainSortFields>
}

export type QuerySecondChancePostActionsArgs = {
  all?: Maybe<Scalars['Boolean']>
  deviceId?: Maybe<Scalars['Int']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<SecondChancePostActionSortFields>
  url?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['Int']>
}

export type QuerySecondChanceUrlArgs = {
  id: Scalars['String']
}

export type QuerySecondChanceUrlsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<SecondChanceUrlSortFields>
  startDays?: Maybe<Scalars['Int']>
}

export type QuerySecondChanceUserArgs = {
  id: Scalars['Int']
}

export type QuerySecondChanceUsersArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<SecondChanceUserSortFields>
}

export type QuerySecurityCoachAggregateCampaignDataArgs = {
  all?: Maybe<Scalars['Boolean']>
  campaignId: Scalars['Int']
  groups?: Maybe<Array<Scalars['Int']>>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  ruleDetectionCount?: Maybe<DetectionCountFilters>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<Scalars['String']>
  timeframe: ReportsTimeframe
  userIds?: Maybe<Array<Scalars['Int']>>
}

export type QuerySecurityCoachApiEndpointsArgs = {
  vendor: Vendors
}

export type QuerySecurityCoachCampaignArgs = {
  id: Scalars['Int']
}

export type QuerySecurityCoachCampaignDataArgs = {
  all?: Maybe<Scalars['Boolean']>
  campaignId: Scalars['Int']
  deliveryMethods?: Maybe<Array<DetectionDeliveryMethods>>
  deliveryStatus?: Maybe<Array<Scalars['String']>>
  detectionRules?: Maybe<Array<Scalars['String']>>
  groups?: Maybe<Array<Scalars['Int']>>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<Scalars['String']>
  timeframe: ReportsTimeframe
  userIds?: Maybe<Array<Scalars['Int']>>
}

export type QuerySecurityCoachCampaignOverviewArgs = {
  id: Scalars['Int']
  status?: Maybe<Array<DeliveryStatus>>
  timeframe: ReportsTimeframe
}

export type QuerySecurityCoachCampaignsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  status?: Maybe<CampaignStatuses>
}

export type QuerySecurityCoachCampaignsCountArgs = {
  status?: Maybe<CampaignStatuses>
}

export type QuerySecurityCoachCatalogContentArgs = {
  languages?: Maybe<Array<Scalars['String']>>
  locale?: Maybe<Scalars['String']>
  search?: Maybe<Scalars['String']>
  topics?: Maybe<Array<Scalars['String']>>
  withAllSelectedLanguages?: Maybe<Scalars['Boolean']>
}

export type QuerySecurityCoachContentArgs = {
  ruleIds?: Maybe<Array<Scalars['String']>>
}

export type QuerySecurityCoachContentItemArgs = {
  uuid: Scalars['String']
}

export type QuerySecurityCoachCsvStatusDetailsArgs = {
  id: Scalars['ID']
}

export type QuerySecurityCoachCustomContentArgs = {
  id: Scalars['Int']
}

export type QuerySecurityCoachCustomContentTranslationsArgs = {
  id: Scalars['Int']
  publishedOnly?: Maybe<Scalars['Boolean']>
}

export type QuerySecurityCoachCustomContentsArgs = {
  payload: CustomContentsListAttributes
}

export type QuerySecurityCoachCustomContentsByRulesArgs = {
  ruleIds?: Maybe<Array<Scalars['String']>>
  topicNames?: Maybe<Array<Scalars['String']>>
}

export type QuerySecurityCoachCustomNotificationArgs = {
  id: Scalars['Int']
}

export type QuerySecurityCoachCustomNotificationsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  payload: CustomNotificationsListAttributes
  per?: Maybe<Scalars['Int']>
}

export type QuerySecurityCoachCustomTranslationArgs = {
  id: Scalars['Int']
  preview?: Maybe<Scalars['Boolean']>
}

export type QuerySecurityCoachDeliveryGoogleChatUsersByDomainArgs = {
  limit: Scalars['Int']
  nextPageToken: Scalars['String']
}

export type QuerySecurityCoachDeliveryTeamsTeamsArgs = {
  startsWith: Scalars['String']
}

export type QuerySecurityCoachExportUnmappedEventsToCsvArgs = {
  timeframe: ReportsTimeframe
  vendorCodeNames: Array<Scalars['String']>
}

export type QuerySecurityCoachGetOrganizationKeyArgs = {
  vendor: Vendors
}

export type QuerySecurityCoachGetUnmappedDataArgs = {
  pageOffset: Scalars['Int']
  pageSize: Scalars['Int']
  timeframe: ReportsTimeframe
  vendor: Array<Scalars['String']>
}

export type QuerySecurityCoachGetUserDataArgs = {
  workspace: Scalars['String']
}

export type QuerySecurityCoachListMappedUsersArgs = {
  draw: Scalars['Int']
  length: Scalars['Int']
  search: Scalars['String']
  start: Scalars['Int']
}

export type QuerySecurityCoachListMappingRecommendationFilterConditionsArgs = {
  field: Scalars['String']
}

export type QuerySecurityCoachListMappingRecommendationFilterValuesArgs = {
  field: Scalars['String']
}

export type QuerySecurityCoachListTeachableMomentCategoriesArgs = {
  vendorCodeName?: Maybe<Array<Scalars['String']>>
}

export type QuerySecurityCoachListTeachableMomentRulesArgs = {
  categories?: Maybe<Array<Scalars['String']>>
  page?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
  pageType?: Maybe<Scalars['String']>
  ruleType?: Maybe<Tmr>
  search?: Maybe<Scalars['String']>
  sortBy?: Maybe<TmrSortFields>
  sortDirection?: Maybe<SortDirections>
  status?: Maybe<RuleStatus>
  vendorCodeName?: Maybe<Array<Vendors>>
}

export type QuerySecurityCoachListTeachableMomentSystemRulesArgs = {
  vendorCodeName?: Maybe<Array<Scalars['String']>>
}

export type QuerySecurityCoachListUserMappingRecommendationArgs = {
  filterData: Scalars['String']
  length: Scalars['Int']
  search: Scalars['String']
  showActive: Scalars['Boolean']
  showInactive: Scalars['Boolean']
  start: Scalars['Int']
}

export type QuerySecurityCoachMappingCsvsArgs = {
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QuerySecurityCoachNotificationPreviewArgs = {
  id: Scalars['String']
  translationType: NotificationTranslationTypes
}

export type QuerySecurityCoachRealTimeCoachingOverviewArgs = {
  timeframe: ReportsTimeframe
}

export type QuerySecurityCoachRealTimeCoachingSummaryArgs = {
  timeframe: ReportsTimeframe
}

export type QuerySecurityCoachRecommendedTeachableMomentRulesArgs = {
  vendorCodeName?: Maybe<Vendors>
}

export type QuerySecurityCoachReportingActiveRulesStatisticsArgs = {
  timeframe: ReportsTimeframe
}

export type QuerySecurityCoachReportingActiveRulesTopRiskyArgs = {
  timeframe: ReportsTimeframe
}

export type QuerySecurityCoachReportingAllDetectionStatisticsArgs = {
  timeframe: ReportsTimeframe
}

export type QuerySecurityCoachReportingAllDetectionsArgs = {
  ordering?: Maybe<Ordering>
  pageNumber: Scalars['Int']
  pageSize: Scalars['Int']
  showMapped: Scalars['Boolean']
  showUnMapped: Scalars['Boolean']
  threatCategories?: Maybe<Array<Scalars['String']>>
  threatSeverities?: Maybe<Array<Scalars['String']>>
  timeframe: ReportsTimeframe
  vendorCodeNames?: Maybe<Array<Scalars['String']>>
}

export type QuerySecurityCoachReportingAllDetectionsSummaryArgs = {
  eventField?: Maybe<Scalars['String']>
  showMapped: Scalars['Boolean']
  showUnMapped: Scalars['Boolean']
  threatCategories?: Maybe<Array<Scalars['String']>>
  threatSeverities?: Maybe<Array<Scalars['String']>>
  timeframe: ReportsTimeframe
  vendorCodeNames?: Maybe<Array<Scalars['String']>>
}

export type QuerySecurityCoachReportingEmployeesReceivingDetectionsArgs = {
  timeframe: ReportsTimeframe
  type: Scalars['String']
}

export type QuerySecurityCoachReportingEndpointOsContributionArgs = {
  timeframe: ReportsTimeframe
}

export type QuerySecurityCoachReportingEndpointThreatSeverityArgs = {
  timeframe: ReportsTimeframe
}

export type QuerySecurityCoachReportingIntegrationDetectionTrendArgs = {
  timeframe: ReportsTimeframe
}

export type QuerySecurityCoachReportingIntegrationSummaryArgs = {
  timeframe: ReportsTimeframe
  vendorCodeName?: Maybe<Vendors>
}

export type QuerySecurityCoachReportingLogTypeTrendArgs = {
  timeframe: ReportsTimeframe
  type: Scalars['String']
}

export type QuerySecurityCoachReportingRiskStatisticsArgs = {
  timeframe: ReportsTimeframe
  type: Scalars['String']
}

export type QuerySecurityCoachReportingRulesSummaryArgs = {
  timeframe: ReportsTimeframe
  vendorCodeName?: Maybe<Vendors>
}

export type QuerySecurityCoachReportingThreatCategoryArgs = {
  timeframe: ReportsTimeframe
  type: Scalars['String']
}

export type QuerySecurityCoachReportingThreatDistributionArgs = {
  percentage: Scalars['Int']
  timeframe: ReportsTimeframe
  type: Scalars['String']
}

export type QuerySecurityCoachRuleDetectionEventArgs = {
  ruleId: Scalars['String']
}

export type QuerySecurityCoachRulesArgs = {
  vendorCodeName?: Maybe<Scalars['String']>
}

export type QuerySecurityCoachRulesCampaignsCountsArgs = {
  campaignStatus?: Maybe<CampaignStatuses>
  ruleIds?: Maybe<Array<Scalars['String']>>
}

export type QuerySecurityCoachRulesOptionsArgs = {
  id: Scalars['ID']
}

export type QuerySecurityCoachSystemWorkflowDetailArgs = {
  systemWorkflowId: Scalars['String']
}

export type QuerySecurityCoachTeachableMomentRulesByIdArgs = {
  vendorCodeName?: Maybe<Array<Scalars['String']>>
  workflowIds: Array<Scalars['String']>
}

export type QuerySecurityCoachThreatRuleDropdownConditionsArgs = {
  vendorCodeName: Scalars['String']
}

export type QuerySecurityCoachThreatRuleDropdownLhsArgs = {
  isVendorRequired?: Maybe<Scalars['Boolean']>
  vendorCodeName: Scalars['String']
}

export type QuerySecurityCoachThreatRuleDropdownRhsArgs = {
  lhsField: Scalars['String']
  search: Scalars['String']
  vendorCodeName: Array<Scalars['String']>
}

export type QuerySecurityCoachTopDetectionRulesArgs = {
  timeframe: ReportsTimeframe
}

export type QuerySecurityCoachUnlistedUsersArgs = {
  filterData: Array<UnlistedUsersFilterData>
  length: Scalars['Int']
  start: Scalars['Int']
  timeframe: ReportsTimeframe
}

export type QuerySecurityCoachUserDiscoveryTrendArgs = {
  timeframe: ReportsTimeframe
}

export type QuerySecurityCoachValidateUserMappingArgs = {
  checkDuplicateValue: Scalars['String']
  editIdentifier: Scalars['String']
  editedUser?: Maybe<Scalars['Int']>
  submitType: Scalars['String']
}

export type QuerySecurityCoachVendorActivityArgs = {
  hours?: Maybe<Scalars['Int']>
}

export type QuerySecurityCoachVendorStatusArgs = {
  endpoint?: Maybe<Scalars['String']>
  vendor: Vendors
}

export type QuerySecurityRoleArgs = {
  id: Scalars['Int']
}

export type QuerySecurityRolesArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDescending?: Maybe<Scalars['Boolean']>
}

export type QuerySiteAdminArgs = {
  id?: Maybe<Scalars['Int']>
}

export type QuerySiteAdminsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<SiteAdminsSortFields>
}

export type QuerySmartHostDomainsArgs = {
  accountId?: Maybe<Scalars['Int']>
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QuerySmartHostMultiInstanceAccountsArgs = {
  accountId?: Maybe<Scalars['Int']>
  all?: Maybe<Scalars['Boolean']>
  domain: Scalars['String']
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryStorePurchaseArgs = {
  all?: Maybe<Scalars['Boolean']>
  id: Scalars['Int']
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryStorePurchasesArgs = {
  accountId?: Maybe<Scalars['Int']>
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  searchString?: Maybe<Scalars['String']>
  showHidden?: Maybe<Scalars['Boolean']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<StorePurchaseSortFields>
  storeItemTypes?: Maybe<Array<StoreItemTypes>>
  uuids?: Maybe<Array<Scalars['String']>>
  withCcm?: Maybe<Scalars['Boolean']>
}

export type QuerySubscriptionArgs = {
  all?: Maybe<Scalars['Boolean']>
  id: Scalars['Int']
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QuerySubscriptionsArgs = {
  all?: Maybe<Scalars['Boolean']>
  onlyActive?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryTemplateCategoryArgs = {
  id: Scalars['Int']
}

export type QueryTemplateLanguagesArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  types?: Maybe<Array<EmailTemplateTypes>>
}

export type QueryTemplateTopicsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  types?: Maybe<Array<TemplateTopicTypes>>
}

export type QueryTimeframeArgs = {
  timeframe: ReportsTimeframe
}

export type QueryTrademarkArgs = {
  all?: Maybe<Scalars['Boolean']>
  id: Scalars['Int']
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryTrademarkMatchArgs = {
  all?: Maybe<Scalars['Boolean']>
  id: Scalars['Int']
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryTrademarkMatchesArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<TrademarkMatchSortFields>
  status?: Maybe<TrademarkMatchStatuses>
  trademarkId: Scalars['Int']
}

export type QueryTrademarksArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryTrainingCampaignArgs = {
  id: Scalars['Int']
}

export type QueryTrainingCampaignCompletionGraphArgs = {
  archived?: Maybe<Scalars['Boolean']>
  completed?: Maybe<Scalars['Boolean']>
  id: Scalars['Int']
}

export type QueryTrainingCampaignSurveysArgs = {
  all?: Maybe<Scalars['Boolean']>
  id: Scalars['Int']
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryTrainingCampaignUsersArgs = {
  all?: Maybe<Scalars['Boolean']>
  asset?: Maybe<TrainingAsset>
  includeArchived?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  scope?: Maybe<EnrollmentScopes>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<TrainingCampaignUsersSortFields>
  trainingCampaignId: Scalars['Int']
  userId?: Maybe<Scalars['Int']>
}

export type QueryTrainingCampaignsArgs = {
  accountId?: Maybe<Scalars['Int']>
  all?: Maybe<Scalars['Boolean']>
  filter?: Maybe<TrainingCampaignFilters>
  includeDeleted?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<TrainingCampaignSortFields>
  statuses?: Maybe<Array<TrainingCampaignStatuses>>
  trainingCampaignIds?: Maybe<Array<Scalars['Int']>>
}

export type QueryTrainingInteractionsResponsesArgs = {
  payload: ReportsTypesTrainingInteractionsResponses
}

export type QueryTrainingInteractionsResponsesByGroupsArgs = {
  payload: ReportsTypesTrainingInteractionsResponsesByGroups
}

export type QueryTrainingReportArgs = {
  all?: Maybe<Scalars['Boolean']>
  courses: Array<Scalars['String']>
  endDate: Scalars['ISO8601Date']
  groups?: Maybe<Array<Scalars['Int']>>
  includeArchived?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  report: TrainingReportTypes
  startDate: Scalars['ISO8601Date']
}

export type QueryUsbCampaignArgs = {
  id: Scalars['Int']
}

export type QueryUsbCampaignsArgs = {
  all?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  ust?: Maybe<Scalars['Boolean']>
}

export type QueryUserArgs = {
  id: Scalars['Int']
}

export type QueryUserByEmailArgs = {
  email: Scalars['String']
}

export type QueryUserEventApiStatusesArgs = {
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

export type QueryUserReportArgs = {
  all?: Maybe<Scalars['Boolean']>
  includeArchived?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  report: UserReportTypes
}

export type QueryUserTimelineArgs = {
  eventQuery: EventQueryArguments
}

export type QueryUserTrainingCampaignsArgs = {
  all?: Maybe<Scalars['Boolean']>
  excludeOptional?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  userId: Scalars['Int']
}

export type QueryUsersArgs = {
  accountIds?: Maybe<Array<Scalars['Int']>>
  all?: Maybe<Scalars['Boolean']>
  filterGroups?: Maybe<Array<Scalars['Int']>>
  filterNoGroups?: Maybe<Scalars['Boolean']>
  group?: Maybe<Scalars['Int']>
  managersOnly?: Maybe<Scalars['Boolean']>
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  permissionScope?: Maybe<GroupPermissionScopes>
  search?: Maybe<Scalars['String']>
  searchTypes?: Maybe<Array<UserSearchTypes>>
  sortBy?: Maybe<UserSortFields>
  sortDirection?: Maybe<SortDirections>
  status?: Maybe<UserStatusFilters>
  type?: Maybe<UserTypeFilters>
  userIds?: Maybe<Array<Scalars['Int']>>
}

export type QueryUsersMergePreviewArgs = {
  mergeUserId: Scalars['Int']
  targetUserId: Scalars['Int']
}

export type Range = {
  __typename?: 'Range'
  /** The end date of the timeframe range */
  endDate?: Maybe<Scalars['ISO8601Date']>
  /** The start date of the timeframe range */
  startDate?: Maybe<Scalars['ISO8601Date']>
}

export enum RangeTypes {
  After = 'AFTER',
  Before = 'BEFORE',
}

export type RawBarChart = {
  __typename?: 'RawBarChart'
  /** Settings related to partner accounts */
  partnerAccountSettings?: Maybe<Array<PartnerAccountSettings>>
  /** A series of data points for the raw bar chart */
  series?: Maybe<Array<RawBarChartSeries>>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** A token for Zenka integration */
  zenkaToken: Scalars['String']
  /** The URL for Zenka integration */
  zenkaUrl: Scalars['String']
}

export type RawBarChartSeries = {
  __typename?: 'RawBarChartSeries'
  /** The data points for the raw bar chart series */
  data?: Maybe<Array<RawBarChartSeriesData>>
  /** The date associated with this set of data points */
  date?: Maybe<Scalars['ISO8601Date']>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type RawBarChartSeriesData = {
  __typename?: 'RawBarChartSeriesData'
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** The total number of successfully delivered items */
  totalDelivered?: Maybe<Scalars['Int']>
  /** The total number of items that failed to deliver */
  totalFailed?: Maybe<Scalars['Int']>
  /** The total number of items that were reported */
  totalReported?: Maybe<Scalars['Int']>
}

export type RawColumns = {
  __typename?: 'RawColumns'
  /** Columns related to detection information */
  detectionColumns?: Maybe<
    Array<ReportsSecurityCoachActivityRtcRawDetectionColumns>
  >
  /** Columns related to RTC (Real Time Coaching) campaign information */
  rtcCampaignColumns?: Maybe<
    Array<ReportsSecurityCoachActivityRtcRawRtcCampaignColumns>
  >
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** Columns related to user information */
  userColumns?: Maybe<Array<ReportsUserColumns>>
  /** Columns related to user identity provider data */
  userIdpDataColumns?: Maybe<Array<ReportsUserIdpDataColumns>>
}

export type RawCsv = {
  __typename?: 'RawCsv'
  /** The CSV format data of the global phishing report */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type RawData = {
  __typename?: 'RawData'
  /** A list of categories associated with the raw data */
  categories?: Maybe<Array<Scalars['String']>>
  /** Indicates if the raw data has been deleted */
  deletedStatus?: Maybe<Scalars['Boolean']>
  /** Indicates if the raw data has been flagged as a false positive */
  flaggedFalsePositive?: Maybe<Scalars['Boolean']>
  /** The unique identifier of the raw data */
  id?: Maybe<Scalars['String']>
  /** The date and time when the data was last modified */
  modified?: Maybe<Scalars['ISO8601DateTime']>
  /** The risk level assigned to the raw data */
  riskLevel?: Maybe<TmrRiskLevels>
  /** The type of rule associated with the raw data */
  ruleType?: Maybe<Tmr>
  /** The user associated with the Security Account Broker (SAB) */
  sabUser?: Maybe<Scalars['Int']>
  /** The stage of the raw data */
  stage?: Maybe<Scalars['String']>
  /** The user associated with the raw data */
  user?: Maybe<User>
  /** The name of the vendor code associated with the raw data */
  vendorCodeName?: Maybe<Scalars['String']>
  workflowDescription?: Maybe<Scalars['String']>
  /** The name of the workflow associated with the raw data */
  workflowName?: Maybe<Scalars['String']>
}

export type RawResponse = {
  __typename?: 'RawResponse'
  /** The columns selected and returned for the raw data */
  columns?: Maybe<SecurityCoachDetectionRulesActivityRawColumns>
  /** The total number of items available */
  count: Scalars['Int']
  /** The page number for the next page of results */
  next?: Maybe<Scalars['String']>
  /** The number of items per page */
  pageSize: Scalars['Int']
  /** Pagination details */
  pagination: Pagination
  /** The page number for the previous page of results */
  previous?: Maybe<Scalars['String']>
  /** A collection of data results for the report */
  results: Array<RawData>
  /** The timeframe for the report */
  timeframe: ReportTimeframe
  /** The total number of pages available */
  totalPages: Scalars['Int']
}

export type RealTimeCoachingOverview = {
  __typename?: 'RealTimeCoachingOverview'
  /** Counts of coaching campaigns organized and their outcomes */
  campaignCounts?: Maybe<Array<CampaignCount>>
  /** The total number of coaching sessions delivered */
  coachingDelivered?: Maybe<Scalars['Int']>
  /** The data related to detections that triggered the coaching */
  detectionData?: Maybe<Array<DetectionData>>
  /** The number of users who received coaching for the first time */
  newUsersCoached?: Maybe<Scalars['Int']>
  /** The count of users who were repeat offenders */
  repeatedOffenders?: Maybe<Scalars['Int']>
  /** A list of groups categorized as risky based on their members' actions or behaviors */
  riskiestGroups?: Maybe<Array<TopRiskyGroups>>
  /** A list of the users deemed most risky based on their actions or behaviors */
  riskiestUsers?: Maybe<Array<TopRiskyUsers>>
  /** The total number of users who received coaching */
  totalUsersCoached?: Maybe<Scalars['Int']>
}

export type RealTimeCoachingSummary = {
  __typename?: 'RealTimeCoachingSummary'
  /** The total number of coaching sessions delivered */
  coachingDelivered?: Maybe<Scalars['Int']>
  /** A list of coaching trends over time */
  coachingTrends?: Maybe<Array<RealTimeCoachingTrend>>
  /** The current number of opportunities for coaching */
  currentOpportunities?: Maybe<Scalars['Int']>
  /** The percentage of users considered risky */
  riskyUsersPercentage?: Maybe<Scalars['Float']>
}

export type RealTimeCoachingTrend = {
  __typename?: 'RealTimeCoachingTrend'
  /** The count of incidents for the real-time coaching trend */
  count?: Maybe<Scalars['Int']>
  /** The date of the real-time coaching trend */
  date?: Maybe<Scalars['ISO8601Date']>
}

/** A message recipient */
export type Recipient = Identifier &
  Timestamps & {
    __typename?: 'Recipient'
    /** The campaign associated with the message */
    campaign: Campaign
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The timestamp when the message was read */
    readAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The delivery status of the message to the recipient */
    status: MessagesCampaignRecipientStatuses
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The user receiving the message */
    user: User
  }

export type RecommendedTeachableMomentRules = {
  __typename?: 'RecommendedTeachableMomentRules'
  /** Details about missed opportunities to improve security posture */
  missedOpportunities?: Maybe<MissedOpportunities>
  /** The risk level score associated with the teachable moment */
  riskLevelScore?: Maybe<Scalars['Int']>
  /** A collection of rules related to the teachable moment */
  rules?: Maybe<Array<TeachableMomentRules>>
  /** A unique identifier for the teachable moment rule */
  slug?: Maybe<Scalars['String']>
}

export type Registration = {
  __typename?: 'Registration'
  /** The unique identifier of the registration */
  id: Scalars['Int']
}

export type Relative = {
  __typename?: 'Relative'
  /** Specifies the type of range for the timeframe */
  rangeType: RangeTypes
  /** The unit of time for the timeframe */
  unit: DateUnits
  /** The value indicating the magnitude of the timeframe */
  value: Scalars['Int']
}

export type RemapJobCreatedMessage = {
  __typename?: 'RemapJobCreatedMessage'
  /** The unique identifier for the remap job */
  jobId?: Maybe<Scalars['String']>
  /** The message associated with the remap job created */
  message?: Maybe<Scalars['String']>
}

export type RemapJobs = {
  __typename?: 'RemapJobs'
  /** The creation date of the job */
  createdDate?: Maybe<Scalars['String']>
  /** The end date of the job */
  endDate?: Maybe<Scalars['String']>
  /** Unique identifier for the job */
  jobId?: Maybe<Scalars['String']>
  /** The organization ID associated with the job */
  orgId?: Maybe<Scalars['Int']>
  /** The start date of the job */
  startDate?: Maybe<Scalars['String']>
  /** The current status of the job */
  status?: Maybe<Scalars['String']>
  /** List of vendor code names associated with the job */
  vendorCodeNames?: Maybe<Array<Scalars['String']>>
}

export type RemapJobsStatus = {
  __typename?: 'RemapJobsStatus'
  remapJobRunning: Scalars['Boolean']
}

/** A reply email */
export type ReplyContent = {
  __typename?: 'ReplyContent'
  /** The list of attachments in the reply */
  attachments?: Maybe<Array<Scalars['String']>>
  /** The HTML content of the reply */
  contentHtml: Scalars['String']
  /** The URL to download the reply */
  downloadUrl?: Maybe<Scalars['String']>
  /** The sender of the reply */
  from?: Maybe<Scalars['String']>
  /** The recipients of the reply */
  replyTo?: Maybe<Array<Scalars['String']>>
  /** The subject of the reply */
  subject?: Maybe<Scalars['String']>
}

/** Report download */
export type Report = ReportConfigurable & {
  __typename?: 'Report'
  configuration: Configurations
  /** The timestamp when the record was created */
  createdAt: Scalars['ISO8601DateTime']
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The user being impersonated, if any */
  impersonatingUser?: Maybe<User>
  /** The user who shared the report */
  sharedBy?: Maybe<Scalars['String']>
  /** The type of report */
  type: DownloadablesTypes
  /** The timestamp when the record was last updated */
  updatedAt: Scalars['ISO8601DateTime']
}

/** An unpaginated list of report categories */
export type ReportCategories = {
  __typename?: 'ReportCategories'
  /** The count of draft templates in categories */
  draftsTemplateCount: Scalars['Int']
  /** A list of report categories */
  nodes: Array<ReportCategory>
}

/** Saved report categories */
export type ReportCategory = Identifier &
  Timestamps & {
    __typename?: 'ReportCategory'
    auditLogDisplay?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The name of the report category */
    name: Scalars['String']
    /** The ordering index of the report category */
    ordering: Scalars['Int']
    /** The number of reports associated with this category */
    reportCount: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export enum ReportConfigCategories {
  AccountPhishFailurePercentage = 'ACCOUNT_PHISH_FAILURE_PERCENTAGE',
  AccountPhishPronePercentage = 'ACCOUNT_PHISH_PRONE_PERCENTAGE',
  AccountReportCard = 'ACCOUNT_REPORT_CARD',
  CultureSurveyReports = 'CULTURE_SURVEY_REPORTS',
  EmailsSent = 'EMAILS_SENT',
  ExecReports = 'EXEC_REPORTS',
  FailuresByPhishingTemplate = 'FAILURES_BY_PHISHING_TEMPLATE',
  FailureTypes = 'FAILURE_TYPES',
  GlobalPhishing = 'GLOBAL_PHISHING',
  GlobalTraining = 'GLOBAL_TRAINING',
  KnowledgeAssessmentReports = 'KNOWLEDGE_ASSESSMENT_REPORTS',
  LearnerPlatform = 'LEARNER_PLATFORM',
  OverallUserCounts = 'OVERALL_USER_COUNTS',
  PhishFailurePercentage = 'PHISH_FAILURE_PERCENTAGE',
  PhishPronePercentage = 'PHISH_PRONE_PERCENTAGE',
  PhishPronePercentageByUserCount = 'PHISH_PRONE_PERCENTAGE_BY_USER_COUNT',
  ReportCards = 'REPORT_CARDS',
  RiskScore = 'RISK_SCORE',
  RiskScoreV2 = 'RISK_SCORE_V2',
  SecurityCoachActivityRtc = 'SECURITY_COACH_ACTIVITY_RTC',
  SecurityCoachDetectionRulesActivity = 'SECURITY_COACH_DETECTION_RULES_ACTIVITY',
  TemplateFailures = 'TEMPLATE_FAILURES',
  TrainingComparison = 'TRAINING_COMPARISON',
  TrainingCoverage = 'TRAINING_COVERAGE',
  TrainingHours = 'TRAINING_HOURS',
  TrainingInteractions = 'TRAINING_INTERACTIONS',
  TrainingStatus = 'TRAINING_STATUS',
  TrainingStatusesByCampaign = 'TRAINING_STATUSES_BY_CAMPAIGN',
  TrainingSurveys = 'TRAINING_SURVEYS',
  UserCountByPhishPronePercentage = 'USER_COUNT_BY_PHISH_PRONE_PERCENTAGE',
  UserListings = 'USER_LISTINGS',
}

/** Report configuration filters */
export type ReportConfigFilters = {
  __typename?: 'ReportConfigFilters'
  /** The accounts associated with the report configurations */
  accounts: Array<Account>
  /** The categories of the report configurations */
  categories: Array<ReportConfigCategories>
  /** The report configurations available */
  configs: Array<Report>
  /** The groups associated with the report configurations */
  groups: Array<Group>
}

/** Fields for records that have report configurations */
export type ReportConfigurable = {
  configuration: Configurations
  /** The timestamp when the record was created */
  createdAt: Scalars['ISO8601DateTime']
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The type of report */
  type: DownloadablesTypes
  /** The timestamp when the record was last updated */
  updatedAt: Scalars['ISO8601DateTime']
}

export type ReportDownloadsCursor = {
  __typename?: 'ReportDownloadsCursor'
  /** The paginated nodes */
  nodes: Array<Download>
  /** Information about the current paginated set */
  pagination: Pagination
}

export enum ReportFrequencies {
  Annual = 'ANNUAL',
  Month = 'MONTH',
  Onetime = 'ONETIME',
  Quarter = 'QUARTER',
  Week = 'WEEK',
}

export type ReportPermissionGroupsCursor = {
  __typename?: 'ReportPermissionGroupsCursor'
  /** The paginated nodes */
  nodes: Array<Group>
  /** Information about the current paginated set */
  pagination: Pagination
}

export enum ReportPermissionNames {
  ReportsDetectionRulesActivity = 'reports_detection_rules_activity',
  ReportsEmailTemplatesSent = 'reports_email_templates_sent',
  ReportsFailureTypes = 'reports_failure_types',
  ReportsFailuresByPhishingTemplate = 'reports_failures_by_phishing_template',
  ReportsGroupAndUserCards = 'reports_group_and_user_cards',
  ReportsLearnerPlatform = 'reports_learner_platform',
  ReportsPhishFailurePercentage = 'reports_phish_failure_percentage',
  ReportsPhishPronePercentage = 'reports_phish_prone_percentage',
  ReportsPhishingActivity = 'reports_phishing_activity',
  ReportsPhishingResultsByUser = 'reports_phishing_results_by_user',
  ReportsQuizInteractions = 'reports_quiz_interactions',
  ReportsRealTimeCoachingActivity = 'reports_real_time_coaching_activity',
  ReportsRiskScore = 'reports_risk_score',
  ReportsSecurityAwarenessProficiencyAssessment = 'reports_security_awareness_proficiency_assessment',
  ReportsSecurityCultureSurvey = 'reports_security_culture_survey',
  ReportsTrainingActivity = 'reports_training_activity',
  ReportsTrainingComparison = 'reports_training_comparison',
  ReportsTrainingContentSurvey = 'reports_training_content_survey',
  ReportsTrainingHours = 'reports_training_hours',
  ReportsTrainingStatusByCampaign = 'reports_training_status_by_campaign',
  ReportsTrainingStatusOverview = 'reports_training_status_overview',
  ReportsUnphishedAndUntrainedUser = 'reports_unphished_and_untrained_user',
  ReportsUserCountAndLogin = 'reports_user_count_and_login',
}

export type ReportTimeframe = {
  __typename?: 'ReportTimeframe'
  /** The calculated end date based on the timeframe criteria */
  calculatedEnd?: Maybe<Scalars['ISO8601Date']>
  /** The calculated start date based on the timeframe criteria */
  calculatedStart?: Maybe<Scalars['ISO8601Date']>
  /** The criteria used to calculate the timeframe for enrollments */
  criteria?: Maybe<ReportsEnrollmentsTimeframeCriteria>
  /** The custom end date for the report */
  customEnd?: Maybe<Scalars['ISO8601Date']>
  /** The custom start date for the report */
  customStart?: Maybe<Scalars['ISO8601Date']>
  /** The unit of time used for relative timeframe calculations */
  relativeUnit?: Maybe<ReportsTimeframeRelativeUnits>
  /** The value used in conjunction with the relative unit for timeframe calculations */
  relativeValue?: Maybe<Scalars['Int']>
  /** The type of the timeframe for the report */
  type: ReportsTimeframeTypes
}

export type ReportsConfigurationsAccountReportCard = {
  __typename?: 'ReportsConfigurationsAccountReportCard'
  /** Reference to the account snapshot for the report */
  account: SnapshotReference
  /** The timeframe for the account report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsActiveRulesReport = {
  __typename?: 'ReportsConfigurationsActiveRulesReport'
  /** The starting date to filter the active rules report */
  from?: Maybe<Scalars['ISO8601Date']>
  /** Rule name to filter the active rules report */
  rule?: Maybe<Scalars['String']>
  /** Search term to filter the active rules report */
  search?: Maybe<Scalars['String']>
  /** Vendor name to filter the active rules report */
  vendor?: Maybe<Scalars['String']>
}

export type ReportsConfigurationsAidaCampaignSummaryTable = {
  __typename?: 'ReportsConfigurationsAidaCampaignSummaryTable'
  account: Scalars['Int']
  columns?: Maybe<Array<AidaSummaryColumns>>
  includeFailed: Scalars['Boolean']
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<AidaSummarySortFields>
}

export type ReportsConfigurationsAssessmentScoreByKnowledgeArea = {
  __typename?: 'ReportsConfigurationsAssessmentScoreByKnowledgeArea'
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** List of knowledge areas covered in the assessment */
  knowledgeAreas: Array<KnowledgeAreas>
  /** Timeframe of the assessment */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsAssessmentScoreByKnowledgeAreaOverTime = {
  __typename?: 'ReportsConfigurationsAssessmentScoreByKnowledgeAreaOverTime'
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** List of knowledge areas covered in the report */
  knowledgeAreas: Array<KnowledgeAreas>
  /** The timeframe over which the assessment score is evaluated */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsAuditLogs = {
  __typename?: 'ReportsConfigurationsAuditLogs'
  /** Activities recorded in the audit logs */
  activities: Array<AuditLogActivities>
  /** Fields within the audit logs that have been changed */
  changes: Array<AuditLogChangeFields>
  /** The end time for the search period */
  endTime?: Maybe<Scalars['ISO8601DateTime']>
  /** Names of admins who are impersonating other users */
  impersonatingUserNames: Array<Scalars['String']>
  /** Categories of objects in the audit logs */
  objectCategories: Array<AuditLogObjectCategories>
  /** Names of objects in the audit logs */
  objectNames: Array<Scalars['String']>
  /** Types of objects in the audit logs */
  objectTypes: Array<AuditLogObjectTypes>
  /** Names of parent partner adminis */
  parentPartnerAdminNames: Array<Scalars['String']>
  /** Names of partner administrators */
  partnerAdminNames: Array<Scalars['String']>
  /** The search period for the audit logs */
  searchPeriod?: Maybe<AuditLogSearchPeriods>
  /** Names of site administrators */
  siteAdminNames: Array<Scalars['String']>
  /** The start time for the search period */
  startTime?: Maybe<Scalars['ISO8601DateTime']>
  /** Names of users */
  userNames: Array<Scalars['String']>
}

export type ReportsConfigurationsEmailsSentByGroup = {
  __typename?: 'ReportsConfigurationsEmailsSentByGroup'
  /** A boolean value indicating if all users are included */
  allUsers?: Maybe<Scalars['Boolean']>
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The direction of sorting */
  sortDirection?: Maybe<SortDirections>
  /** The field by which the emails are sorted */
  sortField?: Maybe<ReportsEmailsSentByTemplateSortFields>
  /** The timeframe for which the report is generated */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsEmailsSentByTemplate = {
  __typename?: 'ReportsConfigurationsEmailsSentByTemplate'
  /** References to the email templates */
  emailTemplates?: Maybe<Array<SnapshotReference>>
  /** Direction of sorting the results */
  sortDirection?: Maybe<SortDirections>
  /** Field by which to sort the results */
  sortField?: Maybe<ReportsEmailsSentByTemplateSortFields>
  /** Timeframe for the email reporting */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsFailureTypeUserCounts = {
  __typename?: 'ReportsConfigurationsFailureTypeUserCounts'
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The timeframe of the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsFailureTypesBarChart = {
  __typename?: 'ReportsConfigurationsFailureTypesBarChart'
  /** The type of phishing campaign */
  campaignType?: Maybe<PhishingCampaignTypes>
  /** A collection of campaigns within the report */
  campaigns: Array<SnapshotReference>
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The industry Phish-prone Percentage */
  industryPpp?: Maybe<Scalars['Float']>
  /** The timeframe for which the data is reported */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsFailuresByPhishingTemplate = {
  __typename?: 'ReportsConfigurationsFailuresByPhishingTemplate'
  /** The type of phishing campaign, if applicable */
  campaignType?: Maybe<PhishingCampaignTypes>
  /** A list of campaigns included in the report */
  campaigns: Array<SnapshotReference>
  /** The timeframe for which the report data is relevant */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsGlobalPhishingAggregate = {
  __typename?: 'ReportsConfigurationsGlobalPhishingAggregate'
  /** References to the accounts included in the report */
  accounts: Array<SnapshotReference>
  /** Types of phishing attack vectors */
  attackVectors?: Maybe<Array<ReportsPhishingAttackVectors>>
  /** Type of phishing campaign */
  campaignType?: Maybe<PhishingCampaignTypes>
  /** Phishing campaigns included in the report */
  campaigns: Array<SnapshotReference>
  /** Columns included in the report */
  columns?: Maybe<GlobalPhishingAggregateColumns>
  /** Groups to be excluded from the report */
  excludeGroups: Array<SnapshotReference>
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** Sort direction for report data */
  sortDirection: SortDirections
  /** Field by which the report data is sorted */
  sortField?: Maybe<Scalars['String']>
  /** The timeframe for the report */
  timeframe: ReportTimeframe
  /** Filter for the status of users */
  userStatus: UserStatusFilters
}

export type ReportsConfigurationsGlobalPhishingRaw = {
  __typename?: 'ReportsConfigurationsGlobalPhishingRaw'
  /** References to the accounts included in the report */
  accounts: Array<SnapshotReference>
  /** The attack vectors used in phishing attempts */
  attackVectors?: Maybe<Array<ReportsPhishingAttackVectors>>
  /** The type of phishing campaigns */
  campaignType?: Maybe<PhishingCampaignTypes>
  /** Campaigns included in the global phishing report */
  campaigns: Array<SnapshotReference>
  /** The columns included in the raw global phishing report */
  columns?: Maybe<GlobalPhishingRawColumns>
  /** Groups excluded from the global phishing report */
  excludeGroups: Array<SnapshotReference>
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The type of outcomes for phishing attempts */
  outcomeType?: Maybe<ReportsPhishingOutcomeTypes>
  /** The direction of sorting for the report data */
  sortDirection: SortDirections
  /** The field by which the report data is sorted */
  sortField?: Maybe<Scalars['String']>
  /** The timeframe for the global phishing report */
  timeframe: ReportTimeframe
  /** The status of users included in the report */
  userStatus: UserStatusFilters
}

export type ReportsConfigurationsGlobalTrainingAggregate = {
  __typename?: 'ReportsConfigurationsGlobalTrainingAggregate'
  /** References to the accounts included in the report */
  accounts: Array<SnapshotReference>
  /** The columns included in the global training aggregate report */
  columns?: Maybe<GlobalTrainingAggregateColumns>
  /** The types of content included in the report */
  contentTypes?: Maybe<Array<ReportsStoreItemTypes>>
  /** The enrollment statuses included in the report */
  enrollmentStatuses?: Maybe<Array<EnrollmentStatuses>>
  /** Criteria used for selecting enrollments by status */
  enrollmentStatusesCriteria: ReportsEnrollmentStatusesCriteria
  /** References to the groups excluded from the report */
  excludeGroups: Array<SnapshotReference>
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The sort direction of the report data */
  sortDirection: SortDirections
  /** The field on which the report data is sorted */
  sortField?: Maybe<Scalars['String']>
  /** The timeframe for the report */
  timeframe: ReportTimeframe
  /** References to the training campaigns included in the report */
  trainingCampaigns: Array<SnapshotReference>
  /** Criteria used for selecting training campaigns */
  trainingCampaignsCriteria: ReportsTrainingCampaignsCriteria
  /** The user status filter applied to the report */
  userStatus: UserStatusFilters
}

export type ReportsConfigurationsGlobalTrainingRaw = {
  __typename?: 'ReportsConfigurationsGlobalTrainingRaw'
  /** References to the accounts included in the report */
  accounts: Array<SnapshotReference>
  /** Defines the columns included in the report */
  columns?: Maybe<GlobalTrainingRawColumns>
  /** Types of content included in the report */
  contentTypes?: Maybe<Array<ReportsStoreItemTypes>>
  /** The enrollment statuses included in the report */
  enrollmentStatuses?: Maybe<Array<EnrollmentStatuses>>
  /** The criteria for enrollment statuses included in the report */
  enrollmentStatusesCriteria: ReportsEnrollmentStatusesCriteria
  /** Groups excluded from the report */
  excludeGroups: Array<SnapshotReference>
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The direction of the sorting applied to the report */
  sortDirection: SortDirections
  /** The field the report is sorted by */
  sortField?: Maybe<Scalars['String']>
  /** The specific timeframe for the report */
  timeframe: ReportTimeframe
  /** References to the training campaigns included in the report */
  trainingCampaigns: Array<SnapshotReference>
  /** The criteria used to select training campaigns for the report */
  trainingCampaignsCriteria: ReportsTrainingCampaignsCriteria
  /** Filter for user statuses included in the report */
  userStatus: UserStatusFilters
}

export type ReportsConfigurationsGroupReportCard = {
  __typename?: 'ReportsConfigurationsGroupReportCard'
  /** The reference to the group in the report */
  group: SnapshotReference
  /** The timeframe for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsIncompleteTraining = {
  __typename?: 'ReportsConfigurationsIncompleteTraining'
  /** The timeframe for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsInitialPpp = {
  __typename?: 'ReportsConfigurationsInitialPpp'
  /** The timeframe for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsLearnerPlatformRaw = {
  __typename?: 'ReportsConfigurationsLearnerPlatformRaw'
  /** References to the accounts included in the report */
  accounts: Array<SnapshotReference>
  /** Columns selected for the report */
  columns: LearnerPlatformRawColumns
  /** The types of content included in the report */
  contentTypes?: Maybe<Array<ReportsStoreItemTypes>>
  /** Platform of enrollment for users in the report */
  enrollmentPlatform?: Maybe<EnrollmentPlatformTypes>
  /** Groups excluded from the report */
  excludeGroups: Array<SnapshotReference>
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The specified timeframe for the report */
  timeframe: ReportTimeframe
  /** References to the training campaigns included in the report */
  trainingCampaigns: Array<SnapshotReference>
  /** Criteria to filter training campaigns in the report */
  trainingCampaignsCriteria: ReportsTrainingCampaignsCriteria
  /** The status of users included in the report */
  userStatus: UserStatusFilters
}

export type ReportsConfigurationsNoTrainingAssigned = {
  __typename?: 'ReportsConfigurationsNoTrainingAssigned'
  /** The timeframe for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsOverallPppByAccount = {
  __typename?: 'ReportsConfigurationsOverallPppByAccount'
  /** References to the accounts included in the report */
  accounts: Array<SnapshotReference>
  /** Indicates if data is combined across all accounts */
  combined: Scalars['Boolean']
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The timeframe for which the report data is relevant */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsOverallPppByCampaign = {
  __typename?: 'ReportsConfigurationsOverallPppByCampaign'
  /** Type of phishing campaign */
  campaignType?: Maybe<PhishingCampaignTypes>
  /** Campaign snapshots related to the report */
  campaigns: Array<SnapshotReference>
  /** Indicates if data is combined from multiple sources */
  combined: Scalars['Boolean']
  /** Group snapshots related to the report */
  groups: Array<SnapshotReference>
  /** Industry Phish-prone Percentage */
  industryPpp?: Maybe<Scalars['Float']>
  /** Timeframe for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsOverallPppByGroup = {
  __typename?: 'ReportsConfigurationsOverallPppByGroup'
  /** A list of snapshot references grouped by certain criteria */
  groups: Array<SnapshotReference>
  /** The industry Phish-prone Percentage */
  industryPpp?: Maybe<Scalars['Float']>
  /** The specific timeframe for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsOverallPppByTrainingTime = {
  __typename?: 'ReportsConfigurationsOverallPppByTrainingTime'
  /** References to the accounts included in the report */
  accounts: Array<SnapshotReference>
  /** Indicates if the report combines data from multiple sources */
  combined: Scalars['Boolean']
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** Defines the timeframe for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsPabComparison = {
  __typename?: 'ReportsConfigurationsPabComparison'
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsPabUserActivity = {
  __typename?: 'ReportsConfigurationsPabUserActivity'
  /** Filter by the status of the user activity */
  activityStatus?: Maybe<UserHasOutlookAddinActivityTypes>
  /** Type of activity performed by the user */
  activityType?: Maybe<OutlookAddinActivityActivityTypes>
  /** Search query used to filter user activity */
  search?: Maybe<Scalars['String']>
  /** Filter by the status of the user */
  userStatus: UserStatusFilters
}

export type ReportsConfigurationsPhishFailurePercentageByCampaigns = {
  __typename?: 'ReportsConfigurationsPhishFailurePercentageByCampaigns'
  /** The type of phishing campaigns included in the report */
  campaignType?: Maybe<PhishingCampaignTypes>
  /** Campaign snapshots referenced in the report */
  campaigns: Array<SnapshotReference>
  /** Indicates whether data from multiple campaigns is combined */
  combined: Scalars['Boolean']
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The specific timeframe for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsPhishFailurePercentageOverall = {
  __typename?: 'ReportsConfigurationsPhishFailurePercentageOverall'
  /** References to the accounts included in the report */
  accounts: Array<SnapshotReference>
  /** Indicates if the data is combined for the report */
  combined: Scalars['Boolean']
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The time frame for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsPhishingAllClickers = {
  __typename?: 'ReportsConfigurationsPhishingAllClickers'
  /** Indicates whether this was a callback phishing campaign */
  callback?: Maybe<Scalars['Boolean']>
  /** Reference to the phishing campaign associated with the clicker */
  phishingCampaign?: Maybe<SnapshotReference>
  /** Reference to the specific run of the phishing campaign associated with the clicker */
  phishingCampaignRun?: Maybe<SnapshotReference>
}

export type ReportsConfigurationsPhishingSecurityTests = {
  __typename?: 'ReportsConfigurationsPhishingSecurityTests'
  /** Collection of phishing campaign runs included in the report */
  campaignRuns: Array<PhishingCampaignRun>
  /** The type of comparison for the Phishing Security Tests */
  comparison?: Maybe<Scalars['String']>
  /** The grouping criteria of the Phishing Security Tests */
  grouping?: Maybe<Scalars['String']>
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** Flag to include tests that did not result in failures */
  includeNonFailures?: Maybe<Scalars['Boolean']>
  /** Flag to include PhishFlip campaigns in the report */
  includePhishFlipCampaigns?: Maybe<Scalars['String']>
  /** The timeframe for the phishing security tests report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsPppDistribution = {
  __typename?: 'ReportsConfigurationsPppDistribution'
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
}

export type ReportsConfigurationsRiskScoreByAssessment = {
  __typename?: 'ReportsConfigurationsRiskScoreByAssessment'
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The timeframe for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsRiskScoreByGroup = {
  __typename?: 'ReportsConfigurationsRiskScoreByGroup'
  /** References to the accounts included in the report */
  accounts: Array<SnapshotReference>
  /** Indicates if the Risk Scores were combined */
  combined: Scalars['Boolean']
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The specific timeframe for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsRiskScoreByLocation = {
  __typename?: 'ReportsConfigurationsRiskScoreByLocation'
  /** List of locations included in the report */
  locations: Array<Location>
  /** The timeframe for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsRiskScoreBySurvey = {
  __typename?: 'ReportsConfigurationsRiskScoreBySurvey'
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** Timeframe for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsRiskScoreByUser = {
  __typename?: 'ReportsConfigurationsRiskScoreByUser'
  /** References to the accounts included in the report */
  accounts: Array<SnapshotReference>
  /** Indicates if the Risk Scores are combined */
  combined: Scalars['Boolean']
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The timeframe for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsRiskScoreV2ByGroup = {
  __typename?: 'ReportsConfigurationsRiskScoreV2ByGroup'
  groups: Array<SnapshotReference>
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsRiskScoreV2ByManager = {
  __typename?: 'ReportsConfigurationsRiskScoreV2ByManager'
  managerEmails?: Maybe<Array<Scalars['String']>>
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsRiskScoreV2ByUser = {
  __typename?: 'ReportsConfigurationsRiskScoreV2ByUser'
  timeframe: ReportTimeframe
  userIds?: Maybe<Array<Scalars['Int']>>
}

export type ReportsConfigurationsSecurityCoachActivityRtcAggregate = {
  __typename?: 'ReportsConfigurationsSecurityCoachActivityRtcAggregate'
  /** The columns to be included in the security coach activity report */
  columns?: Maybe<AggregateColumns>
  /** The delivery methods used for the detection of security threats in the report */
  deliveryMethods: Array<DetectionDeliveryMethods>
  /** Counts of detections filtered as specified for the report */
  detectionCount: DetectionCountFilters
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** Indicates if there were any new users coached during the specified timeframe */
  newUsersCoached: Scalars['Boolean']
  /** A collection of RTC campaigns related to security coaching activities */
  rtcCampaigns: Array<SnapshotReference>
  /** The timeframe for the reported security coach activities */
  timeframe: ReportTimeframe
  /** Filter for user status within the reported security coach activities */
  userStatus: UserStatusFilters
}

export type ReportsConfigurationsSecurityCoachActivityRtcRaw = {
  __typename?: 'ReportsConfigurationsSecurityCoachActivityRtcRaw'
  /** Columns available in the Security Coach Activity Raw report */
  columns?: Maybe<RawColumns>
  /** Delivery methods used for detections in Security Coach campaigns */
  deliveryMethods: Array<DetectionDeliveryMethods>
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** List of Real-Time Coaching campaigns */
  rtcCampaigns: Array<SnapshotReference>
  /** Time range for the security coach activity report */
  timeframe: ReportTimeframe
  /** Filter for the user status within the report */
  userStatus: UserStatusFilters
}

export type ReportsConfigurationsSecurityCoachDetectionRulesActivityAggregate =
  {
    __typename?: 'ReportsConfigurationsSecurityCoachDetectionRulesActivityAggregate'
    /** Columns to be included in the report */
    columns?: Maybe<SecurityCoachDetectionRulesActivityAggregateColumns>
    /** List of detection rules */
    detectionRules: Array<Scalars['String']>
    /** Categories of detection rules */
    detectionRulesCategory: Array<Scalars['String']>
    /** Filter by rule detection count */
    ruleDetectionCount: RuleDetectionCountFilters
    /** Time frame for the report data */
    timeframe: ReportTimeframe
    /** List of vendors */
    vendors: Array<Vendors>
  }

export type ReportsConfigurationsSecurityCoachDetectionRulesActivityRaw = {
  __typename?: 'ReportsConfigurationsSecurityCoachDetectionRulesActivityRaw'
  /** Columns specification for the detection rules activity report */
  columns?: Maybe<SecurityCoachDetectionRulesActivityRawColumns>
  /** A list of detection rules */
  detectionRules: Array<Scalars['String']>
  /** Categories of the detection rules */
  detectionRulesCategory: Array<Scalars['String']>
  /** Risk levels associated with the detection rules */
  riskLevels: Array<TmrRiskLevels>
  /** The timeframe for the detection rules activity */
  timeframe: ReportTimeframe
  /** A list of vendors associated with the detection rules */
  vendors: Array<Vendors>
}

export type ReportsConfigurationsSurveyScoreByDimension = {
  __typename?: 'ReportsConfigurationsSurveyScoreByDimension'
  /** The dimensions across which survey scores are measured */
  dimensions: Array<SurveyDimensions>
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The timeframe for the survey score reporting */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsSurveyScoreByDimensionOverTime = {
  __typename?: 'ReportsConfigurationsSurveyScoreByDimensionOverTime'
  /** Dimensions of the survey for evaluation */
  dimensions: Array<SurveyDimensions>
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The timeframe for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsTrainingCampaignEnrollments = {
  __typename?: 'ReportsConfigurationsTrainingCampaignEnrollments'
  /** The specific asset for the enrollment */
  asset?: Maybe<EnrollmentItem>
  /** Indicates whether archived enrollments should be included */
  includeArchived: Scalars['Boolean']
  /** The scope of enrollment */
  scope?: Maybe<EnrollmentScopes>
  /** Search term used for filtering enrollments */
  search?: Maybe<Scalars['String']>
  /** The training campaign associated with the enrollments */
  trainingCampaign: TrainingCampaign
}

export type ReportsConfigurationsTrainingCampaignSurveys = {
  __typename?: 'ReportsConfigurationsTrainingCampaignSurveys'
  /** Used to get the enum to generate on the FE */
  notUsed?: Maybe<DownloadablesCategories>
  /** The training campaign related to the survey */
  trainingCampaign: TrainingCampaign
}

export type ReportsConfigurationsTrainingComparisonByPpp = {
  __typename?: 'ReportsConfigurationsTrainingComparisonByPpp'
  /** References to the accounts included in the report */
  accounts: Array<SnapshotReference>
  /** Indicates if the data is combined from multiple sources */
  combined: Scalars['Boolean']
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
}

export type ReportsConfigurationsTrainingComparisonByRiskScore = {
  __typename?: 'ReportsConfigurationsTrainingComparisonByRiskScore'
  /** References to the accounts included in the report */
  accounts: Array<SnapshotReference>
  /** Indicates if the data should be combined */
  combined: Scalars['Boolean']
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
}

export type ReportsConfigurationsTrainingHours = {
  __typename?: 'ReportsConfigurationsTrainingHours'
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The time frame for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsTrainingInteractionsByGroup = {
  __typename?: 'ReportsConfigurationsTrainingInteractionsByGroup'
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** A list of purchased course identifiers */
  purchasedCourses: Array<Scalars['Int']>
  /** The direction to sort the report data */
  sortDirection: SortDirections
  /** The timeframe for the report data */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsTrainingInteractionsByPurchasedCourse = {
  __typename?: 'ReportsConfigurationsTrainingInteractionsByPurchasedCourse'
  /** An array of IDs for purchased courses */
  purchasedCourses: Array<Scalars['Int']>
  /** Direction to sort the results, either ascending or descending */
  sortDirection: SortDirections
  /** A specific timeframe for reporting data */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsTrainingNotStarted = {
  __typename?: 'ReportsConfigurationsTrainingNotStarted'
  /** The timeframe for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsTrainingStatusByCampaign = {
  __typename?: 'ReportsConfigurationsTrainingStatusByCampaign'
  /** References to the accounts included in the report */
  accounts: Array<SnapshotReference>
  /** The timeframe for the report */
  timeframe: ReportTimeframe
  /** References to the training campaigns included in the report */
  trainingCampaigns: Array<SnapshotReference>
}

export type ReportsConfigurationsTrainingStatusByCourse = {
  __typename?: 'ReportsConfigurationsTrainingStatusByCourse'
  /** References to the accounts included in the report */
  accounts: Array<SnapshotReference>
  /** The specific timeframe for the report */
  timeframe: ReportTimeframe
  /** References to the training campaigns included in the report */
  trainingCampaigns: Array<SnapshotReference>
}

export type ReportsConfigurationsTrainingStatusByGroup = {
  __typename?: 'ReportsConfigurationsTrainingStatusByGroup'
  /** References to the accounts included in the report */
  accounts: Array<SnapshotReference>
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The timeframe for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsTrainingStatusesByCampaign = {
  __typename?: 'ReportsConfigurationsTrainingStatusesByCampaign'
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The specified timeframe for the report */
  timeframe: ReportTimeframe
  /** References to the training campaigns included in the report */
  trainingCampaigns: Array<SnapshotReference>
}

export type ReportsConfigurationsTrainingSurveysByCampaign = {
  __typename?: 'ReportsConfigurationsTrainingSurveysByCampaign'
  /** The specific timeframe for the report */
  timeframe: ReportTimeframe
  /** References to the training campaigns included in the report */
  trainingCampaigns: Array<SnapshotReference>
}

export type ReportsConfigurationsTrainingSurveysByCourse = {
  __typename?: 'ReportsConfigurationsTrainingSurveysByCourse'
  /** References to the content included in the report */
  content: Array<SnapshotReference>
  /** The timeframe for which the data is reported */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsUnphishedUsers = {
  __typename?: 'ReportsConfigurationsUnphishedUsers'
  /** The timeframe for the report data */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsUserCountByAssessment = {
  __typename?: 'ReportsConfigurationsUserCountByAssessment'
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The time frame for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsUserCountBySurvey = {
  __typename?: 'ReportsConfigurationsUserCountBySurvey'
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The time frame for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsUserCounts = {
  __typename?: 'ReportsConfigurationsUserCounts'
  /** References to the accounts included in the report */
  accounts: Array<SnapshotReference>
  /** Indicates if the data is combined from multiple sources */
  combined: Scalars['Boolean']
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The timeframe for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsUserPhishingCampaigns = {
  __typename?: 'ReportsConfigurationsUserPhishingCampaigns'
  /** Reference to the snapshot of a user’s phishing campaign */
  user: SnapshotReference
  /** The unique identifier of the user */
  userId: Scalars['Int']
}

export type ReportsConfigurationsUserReportCard = {
  __typename?: 'ReportsConfigurationsUserReportCard'
  /** The timeframe for the report */
  timeframe: ReportTimeframe
  /** Reference to the user in the report snapshot */
  user: SnapshotReference
}

export type ReportsConfigurationsUserSignIns = {
  __typename?: 'ReportsConfigurationsUserSignIns'
  /** References to the accounts included in the report */
  accounts: Array<SnapshotReference>
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** The timeframe for the report */
  timeframe: ReportTimeframe
}

export type ReportsConfigurationsUserTrainingCampaigns = {
  __typename?: 'ReportsConfigurationsUserTrainingCampaigns'
  /** A reference to the user associated with the training campaign */
  user: SnapshotReference
  /** The unique identifier for the user associated with the training campaign */
  userId: Scalars['Int']
}

export type ReportsConfigurationsUsersList = {
  __typename?: 'ReportsConfigurationsUsersList'
  /** The current chunk number of data being queried */
  chunkNum?: Maybe<Scalars['Int']>
  /** Flag to filter users not assigned to any group */
  filterNoGroups: Scalars['Boolean']
  /** References to the groups included in the report */
  groups: Array<SnapshotReference>
  /** Search term for filtering users by name, email, or other attributes */
  search?: Maybe<Scalars['String']>
  /** Direction for sorting: ascending or descending */
  sortDirection: SortDirections
  /** Field to sort the users list by */
  sortField: UserSortFields
  /** The total number of chunks available for the query */
  totalChunks?: Maybe<Scalars['Int']>
  /** Filter for user activation status */
  userStatus: UserStatusFilters
  /** Filter for selecting specific types of users */
  userTypeFilter: UserTypeFilters
}

/** Autogenerated return type of ReportsCreate. */
export type ReportsCreatePayload = {
  __typename?: 'ReportsCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Report>
}

export enum ReportsEmailsSentByTemplateSortFields {
  ClickedCount = 'CLICKED_COUNT',
  ClickedRate = 'CLICKED_RATE',
  DeliveredCount = 'DELIVERED_COUNT',
  FailureCount = 'FAILURE_COUNT',
  PhishPronePercentage = 'PHISH_PRONE_PERCENTAGE',
  ReportedCount = 'REPORTED_COUNT',
  SentCount = 'SENT_COUNT',
  UserCount = 'USER_COUNT',
}

export enum ReportsEmailsSentSortFields {
  ClickedCount = 'CLICKED_COUNT',
  ClickedRate = 'CLICKED_RATE',
  DeliveredCount = 'DELIVERED_COUNT',
  FailureCount = 'FAILURE_COUNT',
  Name = 'NAME',
  ReportedCount = 'REPORTED_COUNT',
  SentCount = 'SENT_COUNT',
  UserCount = 'USER_COUNT',
}

export enum ReportsEnrollmentStatusesCriteria {
  All = 'ALL',
  PastDue = 'PAST_DUE',
}

export enum ReportsEnrollmentsTimeframeCriteria {
  CompletedAt = 'COMPLETED_AT',
  CreatedAt = 'CREATED_AT',
  ScheduledAt = 'SCHEDULED_AT',
  StartedAt = 'STARTED_AT',
}

export type ReportsGlobalPhishingAggregateColumns = {
  phishingTotalsColumns?: Maybe<
    Array<ReportsGlobalPhishingAggregatePhishingTotalsColumns>
  >
  userColumns?: Maybe<Array<ReportsUserColumns>>
}

export enum ReportsGlobalPhishingAggregatePhishingTotalsColumns {
  AttachmentOpenCount = 'ATTACHMENT_OPEN_COUNT',
  AvgDiffDelivered = 'AVG_DIFF_DELIVERED',
  AvgDiffFailed = 'AVG_DIFF_FAILED',
  BouncedCount = 'BOUNCED_COUNT',
  CallbackDataEnteredCount = 'CALLBACK_DATA_ENTERED_COUNT',
  CalledCount = 'CALLED_COUNT',
  ClickedCount = 'CLICKED_COUNT',
  DataEnteredCount = 'DATA_ENTERED_COUNT',
  DeliveredCount = 'DELIVERED_COUNT',
  FailedCount = 'FAILED_COUNT',
  FailuresCount = 'FAILURES_COUNT',
  MacroEnabledCount = 'MACRO_ENABLED_COUNT',
  OpenedCount = 'OPENED_COUNT',
  Ppp = 'PPP',
  QrCodeScannedCount = 'QR_CODE_SCANNED_COUNT',
  RepliedCount = 'REPLIED_COUNT',
  ReportedCount = 'REPORTED_COUNT',
  ScheduledCount = 'SCHEDULED_COUNT',
}

export type ReportsGlobalPhishingRawColumns = {
  emailTemplateColumns?: Maybe<
    Array<ReportsGlobalPhishingRawEmailTemplateColumns>
  >
  phishingCampaignColumns?: Maybe<
    Array<ReportsGlobalPhishingRawPhishingCampaignColumns>
  >
  phishingCampaignRecipientColumns?: Maybe<
    Array<ReportsGlobalPhishingRawPhishingCampaignRecipientColumns>
  >
  userColumns?: Maybe<Array<ReportsUserColumns>>
}

export enum ReportsGlobalPhishingRawEmailTemplateColumns {
  AttachmentType = 'ATTACHMENT_TYPE',
  CategoryName = 'CATEGORY_NAME',
  CategoryType = 'CATEGORY_TYPE',
  From = 'FROM',
  LanguageCode = 'LANGUAGE_CODE',
  Name = 'NAME',
  Sophistication = 'SOPHISTICATION',
}

export enum ReportsGlobalPhishingRawPhishingCampaignColumns {
  FrequencyPeriod = 'FREQUENCY_PERIOD',
  IsPhishflip = 'IS_PHISHFLIP',
  LandingPage = 'LANDING_PAGE',
  LastRun = 'LAST_RUN',
  LocalizedCampaign = 'LOCALIZED_CAMPAIGN',
  Name = 'NAME',
  PhishDomain = 'PHISH_DOMAIN',
  Status = 'STATUS',
  StoreReplyContent = 'STORE_REPLY_CONTENT',
  TemplateSelection = 'TEMPLATE_SELECTION',
}

export enum ReportsGlobalPhishingRawPhishingCampaignRecipientColumns {
  AttachmentOpen = 'ATTACHMENT_OPEN',
  Bounced = 'BOUNCED',
  Browser = 'BROWSER',
  BrowserVersion = 'BROWSER_VERSION',
  CallbackDataEntered = 'CALLBACK_DATA_ENTERED',
  Called = 'CALLED',
  Clicked = 'CLICKED',
  DataEntered = 'DATA_ENTERED',
  Delivered = 'DELIVERED',
  IpAddress = 'IP_ADDRESS',
  MacroEnabled = 'MACRO_ENABLED',
  Opened = 'OPENED',
  Os = 'OS',
  QrCodeScanned = 'QR_CODE_SCANNED',
  Replied = 'REPLIED',
  Reported = 'REPORTED',
  ScheduledAt = 'SCHEDULED_AT',
}

export type ReportsGlobalTrainingAggregateColumns = {
  enrollmentTotalsColumns?: Maybe<
    Array<ReportsGlobalTrainingAggregateEnrollmentTotalsColumns>
  >
  userColumns?: Maybe<Array<ReportsUserColumns>>
}

export enum ReportsGlobalTrainingAggregateEnrollmentTotalsColumns {
  Completed = 'COMPLETED',
  CompletedPastDue = 'COMPLETED_PAST_DUE',
  Enrolled = 'ENROLLED',
  InProgress = 'IN_PROGRESS',
  InProgressPastDue = 'IN_PROGRESS_PAST_DUE',
  NotStarted = 'NOT_STARTED',
  NotStartedPastDue = 'NOT_STARTED_PAST_DUE',
  ScoreAverage = 'SCORE_AVERAGE',
  TimeSpent = 'TIME_SPENT',
  TimeSpentOfCompleted = 'TIME_SPENT_OF_COMPLETED',
}

export type ReportsGlobalTrainingRawColumns = {
  enrollmentColumns?: Maybe<Array<ReportsGlobalTrainingRawEnrollmentColumns>>
  enrollmentItemColumns?: Maybe<
    Array<ReportsGlobalTrainingRawEnrollmentItemColumns>
  >
  storeItemColumns?: Maybe<Array<ReportsGlobalTrainingRawStoreItemColumns>>
  trainingCampaignColumns?: Maybe<
    Array<ReportsGlobalTrainingRawTrainingCampaignColumns>
  >
  userColumns?: Maybe<Array<ReportsUserColumns>>
}

export enum ReportsGlobalTrainingRawEnrollmentColumns {
  CompletedAt = 'COMPLETED_AT',
  CreatedAt = 'CREATED_AT',
  ExpiresAt = 'EXPIRES_AT',
  ExtendedExpiresAt = 'EXTENDED_EXPIRES_AT',
  LastStartedAt = 'LAST_STARTED_AT',
  PastDue = 'PAST_DUE',
  Score = 'SCORE',
  StartedAt = 'STARTED_AT',
  Status = 'STATUS',
  TimeRemaining = 'TIME_REMAINING',
  TotalTime = 'TOTAL_TIME',
}

export enum ReportsGlobalTrainingRawEnrollmentItemColumns {
  AssetType = 'ASSET_TYPE',
  Duration = 'DURATION',
  PublishedAt = 'PUBLISHED_AT',
  Title = 'TITLE',
}

export enum ReportsGlobalTrainingRawStoreItemColumns {
  ExpiresAt = 'EXPIRES_AT',
  IsCmp = 'IS_CMP',
  Publisher = 'PUBLISHER',
  SubscriptionLevel = 'SUBSCRIPTION_LEVEL',
}

export enum ReportsGlobalTrainingRawTrainingCampaignColumns {
  EndCampaignAt = 'END_CAMPAIGN_AT',
  Name = 'NAME',
  StartCampaignAt = 'START_CAMPAIGN_AT',
  Status = 'STATUS',
}

export type ReportsLearnerPlatformRawColumns = {
  enrollmentColumns?: Maybe<Array<ReportsLearnerPlatformRawEnrollmentColumns>>
  enrollmentItemColumns?: Maybe<
    Array<ReportsLearnerPlatformRawEnrollmentItemColumns>
  >
  enrollmentPlatformColumns?: Maybe<
    Array<ReportsLearnerPlatformRawEnrollmentPlatformColumns>
  >
  storeItemColumns?: Maybe<Array<ReportsLearnerPlatformRawStoreItemColumns>>
  trainingCampaignColumns?: Maybe<
    Array<ReportsLearnerPlatformRawTrainingCampaignColumns>
  >
  userColumns?: Maybe<Array<ReportsUserColumns>>
}

export enum ReportsLearnerPlatformRawEnrollmentColumns {
  CompletedAt = 'COMPLETED_AT',
  CreatedAt = 'CREATED_AT',
  EnrollmentPlatforms = 'ENROLLMENT_PLATFORMS',
  ExpiresAt = 'EXPIRES_AT',
  ExtendedExpiresAt = 'EXTENDED_EXPIRES_AT',
  LastStartedAt = 'LAST_STARTED_AT',
  PastDue = 'PAST_DUE',
  StartedAt = 'STARTED_AT',
  Status = 'STATUS',
  TimeRemaining = 'TIME_REMAINING',
  TotalTime = 'TOTAL_TIME',
}

export enum ReportsLearnerPlatformRawEnrollmentItemColumns {
  AssetType = 'ASSET_TYPE',
  Duration = 'DURATION',
  PublishedAt = 'PUBLISHED_AT',
  Title = 'TITLE',
}

export enum ReportsLearnerPlatformRawEnrollmentPlatformColumns {
  CompletedPlatform = 'COMPLETED_PLATFORM',
  StartedPlatform = 'STARTED_PLATFORM',
}

export enum ReportsLearnerPlatformRawStoreItemColumns {
  ExpiresAt = 'EXPIRES_AT',
  IsCmp = 'IS_CMP',
  Publisher = 'PUBLISHER',
  SubscriptionLevel = 'SUBSCRIPTION_LEVEL',
}

export enum ReportsLearnerPlatformRawTrainingCampaignColumns {
  EndCampaignAt = 'END_CAMPAIGN_AT',
  Name = 'NAME',
  StartCampaignAt = 'START_CAMPAIGN_AT',
  Status = 'STATUS',
}

export enum ReportsPhishingAttackVectors {
  Excelattachments = 'EXCELATTACHMENTS',
  Htmlattachments = 'HTMLATTACHMENTS',
  Macroattachments = 'MACROATTACHMENTS',
  Pdfattachments = 'PDFATTACHMENTS',
  Powerpointattachments = 'POWERPOINTATTACHMENTS',
  Wordattachments = 'WORDATTACHMENTS',
  Zippedattachments = 'ZIPPEDATTACHMENTS',
}

export enum ReportsPhishingOutcomeTypes {
  All = 'ALL',
  Bounced = 'BOUNCED',
  Failed = 'FAILED',
  Reported = 'REPORTED',
}

export enum ReportsSecurityCoachActivityRtcAggregateAggregateDetectionColumns {
  DetectionCount = 'DETECTION_COUNT',
  EmailNotificationCount = 'EMAIL_NOTIFICATION_COUNT',
  GchatNotificationCount = 'GCHAT_NOTIFICATION_COUNT',
  ScheduledDetectionCount = 'SCHEDULED_DETECTION_COUNT',
  SlackNotificationCount = 'SLACK_NOTIFICATION_COUNT',
  TeamsNotificationCount = 'TEAMS_NOTIFICATION_COUNT',
  UniqueCampaignDetectionCount = 'UNIQUE_CAMPAIGN_DETECTION_COUNT',
}

export type ReportsSecurityCoachActivityRtcAggregateColumns = {
  aggregateDetectionColumns?: Maybe<
    Array<ReportsSecurityCoachActivityRtcAggregateAggregateDetectionColumns>
  >
  userColumns?: Maybe<Array<ReportsUserColumns>>
  userIdpDataColumns?: Maybe<Array<ReportsUserIdpDataColumns>>
}

export type ReportsSecurityCoachActivityRtcRawColumns = {
  detectionColumns?: Maybe<
    Array<ReportsSecurityCoachActivityRtcRawDetectionColumns>
  >
  rtcCampaignColumns?: Maybe<
    Array<ReportsSecurityCoachActivityRtcRawRtcCampaignColumns>
  >
  userColumns?: Maybe<Array<ReportsUserColumns>>
  userIdpDataColumns?: Maybe<Array<ReportsUserIdpDataColumns>>
}

export enum ReportsSecurityCoachActivityRtcRawDetectionColumns {
  CreatedAt = 'CREATED_AT',
  DeliveryMethod = 'DELIVERY_METHOD',
  Status = 'STATUS',
}

export enum ReportsSecurityCoachActivityRtcRawRtcCampaignColumns {
  Name = 'NAME',
  StoreItemTitle = 'STORE_ITEM_TITLE',
  TemplateCategoryName = 'TEMPLATE_CATEGORY_NAME',
}

export type ReportsSecurityCoachDetectionRulesActivityAggregateColumns = {
  aggregateDetectionRuleColumns?: Maybe<
    Array<ReportsSecurityCoachDetectionRulesActivityAggregateDetectionRuleColumns>
  >
  userColumns?: Maybe<Array<ReportsUserColumns>>
  userIdpDataColumns?: Maybe<Array<ReportsUserIdpDataColumns>>
}

export enum ReportsSecurityCoachDetectionRulesActivityAggregateDetectionRuleColumns {
  MaxRiskLevel = 'MAX_RISK_LEVEL',
  UniqueWorkflowCount = 'UNIQUE_WORKFLOW_COUNT',
  WorkflowuserlogCount = 'WORKFLOWUSERLOG_COUNT',
}

export enum ReportsSecurityCoachDetectionRulesActivityDetectionRuleColumns {
  Categories = 'CATEGORIES',
  Modified = 'MODIFIED',
  RiskLevel = 'RISK_LEVEL',
  VendorCodeName = 'VENDOR_CODE_NAME',
  WorkflowDescription = 'WORKFLOW_DESCRIPTION',
  WorkflowName = 'WORKFLOW_NAME',
}

export type ReportsSecurityCoachDetectionRulesActivityRawColumns = {
  detectionRuleColumns?: Maybe<
    Array<ReportsSecurityCoachDetectionRulesActivityDetectionRuleColumns>
  >
  userColumns?: Maybe<Array<ReportsUserColumns>>
  userIdpDataColumns?: Maybe<Array<ReportsUserIdpDataColumns>>
}

export enum ReportsStoreItemTypes {
  Assessment = 'ASSESSMENT',
  Course = 'COURSE',
  Game = 'GAME',
  KnowledgeRefresher = 'KNOWLEDGE_REFRESHER',
  MobileFirst = 'MOBILE_FIRST',
  Policy = 'POLICY',
  ScormVideo = 'SCORM_VIDEO',
}

export type ReportsTimeframe = {
  criteria?: Maybe<ReportsEnrollmentsTimeframeCriteria>
  customEnd?: Maybe<Scalars['ISO8601Date']>
  customStart?: Maybe<Scalars['ISO8601Date']>
  relativeUnit?: Maybe<ReportsTimeframeRelativeUnits>
  relativeValue?: Maybe<Scalars['Int']>
  type: ReportsTimeframeTypes
}

export enum ReportsTimeframeRelativeUnits {
  Month = 'MONTH',
  Week = 'WEEK',
}

export enum ReportsTimeframeTypes {
  Custom = 'CUSTOM',
  EntireHistory = 'ENTIRE_HISTORY',
  Last_6Months = 'LAST_6_MONTHS',
  Last_7Days = 'LAST_7_DAYS',
  Last_24Hours = 'LAST_24_HOURS',
  Last_30Days = 'LAST_30_DAYS',
  Last_90Days = 'LAST_90_DAYS',
  LastMonth = 'LAST_MONTH',
  LastQuarter = 'LAST_QUARTER',
  LastYear = 'LAST_YEAR',
  MonthToDate = 'MONTH_TO_DATE',
  WeekToDate = 'WEEK_TO_DATE',
  YearToDate = 'YEAR_TO_DATE',
}

export enum ReportsTrainingCampaignsCriteria {
  AidaOptionalTraining = 'AIDA_OPTIONAL_TRAINING',
  CampaignTraining = 'CAMPAIGN_TRAINING',
  OptionalTraining = 'OPTIONAL_TRAINING',
}

export enum ReportsTypes {
  AccountReportCard = 'ACCOUNT_REPORT_CARD',
  ActiveRulesReport = 'ACTIVE_RULES_REPORT',
  AidaCampaignSummaryTable = 'AIDA_CAMPAIGN_SUMMARY_TABLE',
  AssessmentScoreByKnowledgeArea = 'ASSESSMENT_SCORE_BY_KNOWLEDGE_AREA',
  AssessmentScoreByKnowledgeAreaOverTime = 'ASSESSMENT_SCORE_BY_KNOWLEDGE_AREA_OVER_TIME',
  AuditLogs = 'AUDIT_LOGS',
  EmailsSentByGroup = 'EMAILS_SENT_BY_GROUP',
  EmailsSentByTemplate = 'EMAILS_SENT_BY_TEMPLATE',
  ExecReportsPdf = 'EXEC_REPORTS_PDF',
  FailuresByPhishingTemplate = 'FAILURES_BY_PHISHING_TEMPLATE',
  FailureTypesBarChart = 'FAILURE_TYPES_BAR_CHART',
  FailureTypeUserCounts = 'FAILURE_TYPE_USER_COUNTS',
  GlobalPhishingAggregate = 'GLOBAL_PHISHING_AGGREGATE',
  GlobalPhishingRaw = 'GLOBAL_PHISHING_RAW',
  GlobalTrainingAggregate = 'GLOBAL_TRAINING_AGGREGATE',
  GlobalTrainingRaw = 'GLOBAL_TRAINING_RAW',
  GroupReportCard = 'GROUP_REPORT_CARD',
  IncompleteTraining = 'INCOMPLETE_TRAINING',
  InitialPpp = 'INITIAL_PPP',
  LearnerPlatformRaw = 'LEARNER_PLATFORM_RAW',
  NoTrainingAssigned = 'NO_TRAINING_ASSIGNED',
  OverallPppByAccount = 'OVERALL_PPP_BY_ACCOUNT',
  OverallPppByCampaign = 'OVERALL_PPP_BY_CAMPAIGN',
  OverallPppByGroup = 'OVERALL_PPP_BY_GROUP',
  OverallPppByTrainingTime = 'OVERALL_PPP_BY_TRAINING_TIME',
  PabComparison = 'PAB_COMPARISON',
  PabUserActivity = 'PAB_USER_ACTIVITY',
  PhishingAllClickers = 'PHISHING_ALL_CLICKERS',
  PhishingSecurityTests = 'PHISHING_SECURITY_TESTS',
  PhishFailurePercentageByCampaigns = 'PHISH_FAILURE_PERCENTAGE_BY_CAMPAIGNS',
  PhishFailurePercentageOverall = 'PHISH_FAILURE_PERCENTAGE_OVERALL',
  PppDistribution = 'PPP_DISTRIBUTION',
  RiskScoreByAssessment = 'RISK_SCORE_BY_ASSESSMENT',
  RiskScoreByGroup = 'RISK_SCORE_BY_GROUP',
  RiskScoreByLocation = 'RISK_SCORE_BY_LOCATION',
  RiskScoreBySurvey = 'RISK_SCORE_BY_SURVEY',
  RiskScoreByUser = 'RISK_SCORE_BY_USER',
  RiskScoreV2ByGroup = 'RISK_SCORE_V2_BY_GROUP',
  RiskScoreV2ByManager = 'RISK_SCORE_V2_BY_MANAGER',
  RiskScoreV2ByUser = 'RISK_SCORE_V2_BY_USER',
  SecurityCoachActivityRtcAggregate = 'SECURITY_COACH_ACTIVITY_RTC_AGGREGATE',
  SecurityCoachActivityRtcRaw = 'SECURITY_COACH_ACTIVITY_RTC_RAW',
  SecurityCoachDetectionRulesActivityAggregate = 'SECURITY_COACH_DETECTION_RULES_ACTIVITY_AGGREGATE',
  SecurityCoachDetectionRulesActivityRaw = 'SECURITY_COACH_DETECTION_RULES_ACTIVITY_RAW',
  SurveyScoreByDimension = 'SURVEY_SCORE_BY_DIMENSION',
  SurveyScoreByDimensionOverTime = 'SURVEY_SCORE_BY_DIMENSION_OVER_TIME',
  TrainingCampaignEnrollments = 'TRAINING_CAMPAIGN_ENROLLMENTS',
  TrainingCampaignSurveys = 'TRAINING_CAMPAIGN_SURVEYS',
  TrainingComparisonByPpp = 'TRAINING_COMPARISON_BY_PPP',
  TrainingComparisonByRiskScore = 'TRAINING_COMPARISON_BY_RISK_SCORE',
  TrainingHours = 'TRAINING_HOURS',
  TrainingInteractionsByGroup = 'TRAINING_INTERACTIONS_BY_GROUP',
  TrainingInteractionsByPurchasedCourse = 'TRAINING_INTERACTIONS_BY_PURCHASED_COURSE',
  TrainingNotStarted = 'TRAINING_NOT_STARTED',
  TrainingStatusesByCampaign = 'TRAINING_STATUSES_BY_CAMPAIGN',
  TrainingStatusByCampaign = 'TRAINING_STATUS_BY_CAMPAIGN',
  TrainingStatusByCourse = 'TRAINING_STATUS_BY_COURSE',
  TrainingStatusByGroup = 'TRAINING_STATUS_BY_GROUP',
  TrainingSurveysByCampaign = 'TRAINING_SURVEYS_BY_CAMPAIGN',
  TrainingSurveysByCourse = 'TRAINING_SURVEYS_BY_COURSE',
  UnphishedUsers = 'UNPHISHED_USERS',
  UsersList = 'USERS_LIST',
  UserCounts = 'USER_COUNTS',
  UserCountByAssessment = 'USER_COUNT_BY_ASSESSMENT',
  UserCountBySurvey = 'USER_COUNT_BY_SURVEY',
  UserPhishingCampaigns = 'USER_PHISHING_CAMPAIGNS',
  UserReportCard = 'USER_REPORT_CARD',
  UserSignIns = 'USER_SIGN_INS',
  UserTrainingCampaigns = 'USER_TRAINING_CAMPAIGNS',
}

export type ReportsTypesAccountReportCard = {
  accountId: Scalars['Int']
  timeframe: ReportsTimeframe
}

export type ReportsTypesActiveRulesReport = {
  from?: Maybe<Scalars['ISO8601Date']>
  rule?: Maybe<Scalars['String']>
  search?: Maybe<Scalars['String']>
  vendor?: Maybe<Scalars['String']>
}

export type ReportsTypesAidaCampaignSummaryTable = {
  account: Scalars['Int']
  columns?: Maybe<Array<AidaSummaryColumns>>
  includeFailed?: Maybe<Scalars['Boolean']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<AidaSummarySortFields>
}

export type ReportsTypesAssessmentScoreByKnowledgeArea = {
  groups?: Maybe<Array<Scalars['Int']>>
  knowledgeAreas?: Maybe<Array<KnowledgeAreas>>
  timeframe: ReportsTimeframe
}

export type ReportsTypesAssessmentScoreByKnowledgeAreaOverTime = {
  groups?: Maybe<Array<Scalars['Int']>>
  knowledgeAreas?: Maybe<Array<KnowledgeAreas>>
  timeframe: ReportsTimeframe
}

export type ReportsTypesAuditLogs = {
  activities?: Maybe<Array<AuditLogActivities>>
  changes?: Maybe<Array<AuditLogChangeFields>>
  endTime?: Maybe<Scalars['ISO8601DateTime']>
  impersonatingUserIds?: Maybe<Array<Scalars['Int']>>
  objectCategories?: Maybe<Array<AuditLogObjectCategories>>
  objectIds?: Maybe<Array<Scalars['Int']>>
  objectTypes?: Maybe<Array<AuditLogObjectTypes>>
  onlyApiEvents?: Maybe<Scalars['Boolean']>
  parentPartnerAdminIds?: Maybe<Array<Scalars['Int']>>
  partnerAdminIds?: Maybe<Array<Scalars['Int']>>
  searchPeriod?: Maybe<AuditLogSearchPeriods>
  siteAdminIds?: Maybe<Array<Scalars['Int']>>
  startTime?: Maybe<Scalars['ISO8601DateTime']>
  userIds?: Maybe<Array<Scalars['Int']>>
}

export type ReportsTypesEmailsSentByGroup = {
  groups?: Maybe<Array<Scalars['Int']>>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<ReportsEmailsSentSortFields>
  timeframe: ReportsTimeframe
}

export type ReportsTypesEmailsSentByTemplate = {
  emailTemplates?: Maybe<Array<Scalars['Int']>>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<ReportsEmailsSentByTemplateSortFields>
  timeframe: ReportsTimeframe
}

export type ReportsTypesFailureTypeUserCounts = {
  groups?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
}

export type ReportsTypesFailureTypesBarChart = {
  campaignType?: Maybe<PhishingCampaignTypes>
  campaigns?: Maybe<Array<Scalars['Int']>>
  groups?: Maybe<Array<Scalars['Int']>>
  industryPpp?: Maybe<Scalars['Float']>
  timeframe: ReportsTimeframe
}

export type ReportsTypesFailuresByPhishingTemplate = {
  campaignType?: Maybe<PhishingCampaignTypes>
  campaigns?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
}

export type ReportsTypesGlobalPhishingAggregate = {
  accounts?: Maybe<Array<Scalars['Int']>>
  attackVectors?: Maybe<Array<ReportsPhishingAttackVectors>>
  campaignType?: Maybe<PhishingCampaignTypes>
  campaigns?: Maybe<Array<Scalars['Int']>>
  columns?: Maybe<ReportsGlobalPhishingAggregateColumns>
  excludeGroups?: Maybe<Array<Scalars['Int']>>
  groups?: Maybe<Array<Scalars['Int']>>
  searchString?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<Scalars['String']>
  timeframe: ReportsTimeframe
  userStatus: UserStatusFilters
}

export type ReportsTypesGlobalPhishingRaw = {
  accounts?: Maybe<Array<Scalars['Int']>>
  attackVectors?: Maybe<Array<ReportsPhishingAttackVectors>>
  campaignType?: Maybe<PhishingCampaignTypes>
  campaigns?: Maybe<Array<Scalars['Int']>>
  columns?: Maybe<ReportsGlobalPhishingRawColumns>
  excludeGroups?: Maybe<Array<Scalars['Int']>>
  groups?: Maybe<Array<Scalars['Int']>>
  outcomeType: ReportsPhishingOutcomeTypes
  searchString?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<Scalars['String']>
  timeframe: ReportsTimeframe
  userStatus: UserStatusFilters
}

export type ReportsTypesGlobalTrainingAggregate = {
  accounts?: Maybe<Array<Scalars['Int']>>
  columns?: Maybe<ReportsGlobalTrainingAggregateColumns>
  contentTypes?: Maybe<Array<ReportsStoreItemTypes>>
  enrollmentStatuses?: Maybe<Array<EnrollmentStatuses>>
  enrollmentStatusesCriteria?: Maybe<ReportsEnrollmentStatusesCriteria>
  excludeGroups?: Maybe<Array<Scalars['Int']>>
  groups?: Maybe<Array<Scalars['Int']>>
  searchString?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<Scalars['String']>
  timeframe: ReportsTimeframe
  trainingCampaigns?: Maybe<Array<Scalars['Int']>>
  trainingCampaignsCriteria?: Maybe<ReportsTrainingCampaignsCriteria>
  userStatus?: Maybe<UserStatusFilters>
}

export type ReportsTypesGlobalTrainingRaw = {
  accounts?: Maybe<Array<Scalars['Int']>>
  columns?: Maybe<ReportsGlobalTrainingRawColumns>
  contentTypes?: Maybe<Array<ReportsStoreItemTypes>>
  enrollmentStatuses?: Maybe<Array<EnrollmentStatuses>>
  enrollmentStatusesCriteria?: Maybe<ReportsEnrollmentStatusesCriteria>
  excludeGroups?: Maybe<Array<Scalars['Int']>>
  groups?: Maybe<Array<Scalars['Int']>>
  searchString?: Maybe<Scalars['String']>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<Scalars['String']>
  timeframe: ReportsTimeframe
  trainingCampaigns?: Maybe<Array<Scalars['Int']>>
  trainingCampaignsCriteria?: Maybe<ReportsTrainingCampaignsCriteria>
  userStatus?: Maybe<UserStatusFilters>
}

export type ReportsTypesGroupReportCard = {
  groupId: Scalars['Int']
  timeframe: ReportsTimeframe
}

export type ReportsTypesIncompleteTraining = {
  timeframe: ReportsTimeframe
}

export type ReportsTypesInitialPpp = {
  timeframe: ReportsTimeframe
}

export type ReportsTypesLearnerPlatformRaw = {
  accounts?: Maybe<Array<Scalars['Int']>>
  columns?: Maybe<ReportsLearnerPlatformRawColumns>
  contentTypes?: Maybe<Array<ReportsStoreItemTypes>>
  enrollmentPlatform?: Maybe<EnrollmentPlatformTypes>
  excludeGroups?: Maybe<Array<Scalars['Int']>>
  groups?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
  trainingCampaigns?: Maybe<Array<Scalars['Int']>>
  trainingCampaignsCriteria?: Maybe<ReportsTrainingCampaignsCriteria>
  userStatus?: Maybe<UserStatusFilters>
}

export type ReportsTypesMostClickedEmailTemplates = {
  accountId?: Maybe<Scalars['Int']>
  groupId?: Maybe<Scalars['Int']>
  timeframe: ReportsTimeframe
  userId?: Maybe<Scalars['Int']>
}

export type ReportsTypesNoTrainingAssigned = {
  timeframe: ReportsTimeframe
}

export type ReportsTypesOverallPppByAccount = {
  accounts?: Maybe<Array<Scalars['Int']>>
  combined?: Maybe<Scalars['Boolean']>
  groups?: Maybe<Array<Scalars['Int']>>
  industryPpp?: Maybe<Scalars['Float']>
  timeframe: ReportsTimeframe
}

export type ReportsTypesOverallPppByCampaign = {
  campaignType?: Maybe<PhishingCampaignTypes>
  campaigns?: Maybe<Array<Scalars['Int']>>
  combined?: Maybe<Scalars['Boolean']>
  groups?: Maybe<Array<Scalars['Int']>>
  industryPpp?: Maybe<Scalars['Float']>
  timeframe: ReportsTimeframe
}

export type ReportsTypesOverallPppByGroup = {
  groups?: Maybe<Array<Scalars['Int']>>
  industryPpp?: Maybe<Scalars['Float']>
  timeframe: ReportsTimeframe
}

export type ReportsTypesOverallPppByTrainingTime = {
  accounts?: Maybe<Array<Scalars['Int']>>
  combined?: Maybe<Scalars['Boolean']>
  groups?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
}

export type ReportsTypesOverallPppByUser = {
  timeframe: ReportsTimeframe
  userId: Scalars['Int']
}

export type ReportsTypesPabComparison = {
  timeframe: ReportsTimeframe
}

export type ReportsTypesPabUserActivity = {
  activityStatus?: Maybe<UserHasOutlookAddinActivityTypes>
  activityType?: Maybe<OutlookAddinActivityActivityTypes>
  search?: Maybe<Scalars['String']>
  userStatus?: Maybe<UserStatusFilters>
}

export type ReportsTypesPhishFailurePercentageByCampaigns = {
  campaignType?: Maybe<PhishingCampaignTypes>
  campaigns?: Maybe<Array<Scalars['Int']>>
  combined?: Maybe<Scalars['Boolean']>
  groups?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
}

export type ReportsTypesPhishFailurePercentageOverall = {
  accounts?: Maybe<Array<Scalars['Int']>>
  combined?: Maybe<Scalars['Boolean']>
  groups?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
}

export type ReportsTypesPhishingAllClickers = {
  callback?: Maybe<Scalars['Boolean']>
  phishingCampaignId?: Maybe<Scalars['Int']>
  phishingCampaignRunId?: Maybe<Scalars['Int']>
}

export type ReportsTypesPhishingSecurityTests = {
  campaignRuns?: Maybe<Array<Scalars['Int']>>
  comparison?: Maybe<Scalars['String']>
  grouping?: Maybe<Scalars['String']>
  groups?: Maybe<Array<Scalars['Int']>>
  includeNonFailures?: Maybe<Scalars['Boolean']>
  includePhishFlipCampaigns?: Maybe<Scalars['String']>
  timeframe: ReportsTimeframe
}

export type ReportsTypesPppDistribution = {
  groups?: Maybe<Array<Scalars['Int']>>
}

export type ReportsTypesRiskScoreByAssessment = {
  groups?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
}

export type ReportsTypesRiskScoreByGroup = {
  accounts?: Maybe<Array<Scalars['Int']>>
  combined?: Maybe<Scalars['Boolean']>
  groups?: Maybe<Array<Scalars['Int']>>
  isAccountReportCard?: Maybe<Scalars['Boolean']>
  timeframe: ReportsTimeframe
}

export type ReportsTypesRiskScoreByLocation = {
  locations?: Maybe<Array<Scalars['String']>>
  timeframe: ReportsTimeframe
}

export type ReportsTypesRiskScoreBySurvey = {
  groups?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
}

export type ReportsTypesRiskScoreByUser = {
  accounts?: Maybe<Array<Scalars['Int']>>
  groups?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
}

export type ReportsTypesRiskScoreHistoryByUser = {
  timeframe: ReportsTimeframe
  userId: Scalars['Int']
}

export type ReportsTypesRiskScoreV2ByGroup = {
  groups?: Maybe<Array<Scalars['Int']>>
  managerEmails?: Maybe<Array<Scalars['String']>>
  timeframe: ReportsTimeframe
  userIds?: Maybe<Array<Scalars['Int']>>
}

export type ReportsTypesRiskScoreV2ByManager = {
  groups?: Maybe<Array<Scalars['Int']>>
  managerEmails?: Maybe<Array<Scalars['String']>>
  timeframe: ReportsTimeframe
  userIds?: Maybe<Array<Scalars['Int']>>
}

export type ReportsTypesRiskScoreV2ByUser = {
  groups?: Maybe<Array<Scalars['Int']>>
  managerEmails?: Maybe<Array<Scalars['String']>>
  timeframe: ReportsTimeframe
  userIds?: Maybe<Array<Scalars['Int']>>
}

export type ReportsTypesSecurityCoachActivityRtcAggregate = {
  columns?: Maybe<ReportsSecurityCoachActivityRtcAggregateColumns>
  deliveryMethods?: Maybe<Array<DetectionDeliveryMethods>>
  detectionCount?: Maybe<DetectionCountFilters>
  groups?: Maybe<Array<Scalars['Int']>>
  newUsersCoached?: Maybe<Scalars['Boolean']>
  rtcCampaigns?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
  userStatus?: Maybe<UserStatusFilters>
}

export type ReportsTypesSecurityCoachActivityRtcRaw = {
  columns?: Maybe<ReportsSecurityCoachActivityRtcRawColumns>
  deliveryMethods?: Maybe<Array<DetectionDeliveryMethods>>
  groups?: Maybe<Array<Scalars['Int']>>
  rtcCampaigns?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
  userStatus?: Maybe<UserStatusFilters>
}

export type ReportsTypesSecurityCoachDetectionRulesActivityAggregate = {
  columns?: Maybe<ReportsSecurityCoachDetectionRulesActivityAggregateColumns>
  detectionRules?: Maybe<Array<Scalars['String']>>
  detectionRulesCategory?: Maybe<Array<Scalars['String']>>
  ruleDetectionCount?: Maybe<RuleDetectionCountFilters>
  timeframe: ReportsTimeframe
  userIds?: Maybe<Array<Scalars['Int']>>
  vendors?: Maybe<Array<Vendors>>
}

export type ReportsTypesSecurityCoachDetectionRulesActivityRaw = {
  columns?: Maybe<ReportsSecurityCoachDetectionRulesActivityRawColumns>
  detectionRules?: Maybe<Array<Scalars['String']>>
  detectionRulesCategory?: Maybe<Array<Scalars['String']>>
  riskLevels?: Maybe<Array<TmrRiskLevels>>
  timeframe: ReportsTimeframe
  userIds?: Maybe<Array<Scalars['Int']>>
  vendors?: Maybe<Array<Vendors>>
}

export type ReportsTypesSurveyScoreByDimension = {
  dimensions?: Maybe<Array<SurveyDimensions>>
  groups?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
}

export type ReportsTypesSurveyScoreByDimensionOverTime = {
  dimensions?: Maybe<Array<SurveyDimensions>>
  groups?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
}

export type ReportsTypesTrainingCampaignEnrollments = {
  assetId?: Maybe<Scalars['Int']>
  assetType?: Maybe<Scalars['String']>
  includeArchived?: Maybe<Scalars['Boolean']>
  scope?: Maybe<EnrollmentScopes>
  search?: Maybe<Scalars['String']>
  trainingCampaignId: Scalars['Int']
}

export type ReportsTypesTrainingCampaignSurveys = {
  trainingCampaignId: Scalars['Int']
}

export type ReportsTypesTrainingComparisonByPpp = {
  accounts?: Maybe<Array<Scalars['Int']>>
  combined?: Maybe<Scalars['Boolean']>
  groups?: Maybe<Array<Scalars['Int']>>
}

export type ReportsTypesTrainingComparisonByRiskScore = {
  accounts?: Maybe<Array<Scalars['Int']>>
  combined?: Maybe<Scalars['Boolean']>
  groups?: Maybe<Array<Scalars['Int']>>
}

export type ReportsTypesTrainingHours = {
  groups?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
}

export type ReportsTypesTrainingInteractionsByGroup = {
  enrollmentLanguage?: Maybe<Scalars['String']>
  groups?: Array<Scalars['Int']>
  purchasedCourses: Array<Scalars['Int']>
  sortDirection?: Maybe<SortDirections>
  timeframe: ReportsTimeframe
  userStatus?: Maybe<UserStatusFilters>
}

export type ReportsTypesTrainingInteractionsByPurchasedCourse = {
  enrollmentLanguage?: Maybe<Scalars['String']>
  purchasedCourses: Array<Scalars['Int']>
  sortDirection?: Maybe<SortDirections>
  timeframe: ReportsTimeframe
  userStatus?: Maybe<UserStatusFilters>
}

export type ReportsTypesTrainingInteractionsResponses = {
  enrollmentLanguage?: Maybe<Scalars['String']>
  purchasedCourses: Array<Scalars['Int']>
  question: Scalars['String']
  sortDirection?: Maybe<SortDirections>
  timeframe: ReportsTimeframe
  userStatus?: Maybe<UserStatusFilters>
}

export type ReportsTypesTrainingInteractionsResponsesByGroups = {
  enrollmentLanguage?: Maybe<Scalars['String']>
  groupId?: Maybe<Array<Scalars['Int']>>
  purchasedCourses: Array<Scalars['Int']>
  question: Scalars['String']
  sortDirection?: Maybe<SortDirections>
  timeframe: ReportsTimeframe
  userStatus?: Maybe<UserStatusFilters>
}

export type ReportsTypesTrainingNotStarted = {
  timeframe: ReportsTimeframe
}

export type ReportsTypesTrainingStatusByCampaign = {
  accounts?: Maybe<Array<Scalars['Int']>>
  groupId?: Maybe<Scalars['Int']>
  timeframe: ReportsTimeframe
  trainingCampaigns?: Maybe<Array<Scalars['Int']>>
  userId?: Maybe<Scalars['Int']>
}

export type ReportsTypesTrainingStatusByCourse = {
  accounts?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
  trainingCampaigns?: Maybe<Array<Scalars['Int']>>
}

export type ReportsTypesTrainingStatusByGroup = {
  accounts?: Maybe<Array<Scalars['Int']>>
  groups?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
}

export type ReportsTypesTrainingStatusesByCampaign = {
  groups?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
  trainingCampaigns?: Maybe<Array<Scalars['Int']>>
}

export type ReportsTypesTrainingSurveysByCampaign = {
  timeframe: ReportsTimeframe
  trainingCampaigns?: Maybe<Array<Scalars['Int']>>
}

export type ReportsTypesTrainingSurveysByCourse = {
  content?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
}

export type ReportsTypesUnphishedUsers = {
  timeframe: ReportsTimeframe
}

export type ReportsTypesUserCountByAssessment = {
  groups?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
}

export type ReportsTypesUserCountBySurvey = {
  groups?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
}

export type ReportsTypesUserCounts = {
  accounts?: Maybe<Array<Scalars['Int']>>
  combined?: Maybe<Scalars['Boolean']>
  groups?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
}

export type ReportsTypesUserPhishingCampaigns = {
  userId: Scalars['Int']
}

export type ReportsTypesUserReportCard = {
  timeframe: ReportsTimeframe
  userId: Scalars['Int']
}

export type ReportsTypesUserSignIns = {
  accounts?: Maybe<Array<Scalars['Int']>>
  groups?: Maybe<Array<Scalars['Int']>>
  timeframe: ReportsTimeframe
}

export type ReportsTypesUserTrainingCampaigns = {
  userId: Scalars['Int']
}

export type ReportsTypesUsersList = {
  chunkNum?: Maybe<Scalars['Int']>
  filterNoGroups: Scalars['Boolean']
  groupStatus?: Maybe<GroupStatuses>
  groups?: Maybe<Array<Scalars['Int']>>
  search?: Maybe<Scalars['String']>
  singleGroup?: Maybe<Scalars['Boolean']>
  sortDirection: Scalars['String']
  sortField: Scalars['String']
  totalChunks?: Maybe<Scalars['Int']>
  userStatus: UserStatusFilters
  userType: UserTypeFilters
}

export enum ReportsUserColumns {
  Account = 'ACCOUNT',
  ArchivedAt = 'ARCHIVED_AT',
  CreatedAt = 'CREATED_AT',
  CurrentPpp = 'CURRENT_PPP',
  CustomDate_1 = 'CUSTOM_DATE_1',
  CustomDate_2 = 'CUSTOM_DATE_2',
  CustomField_1 = 'CUSTOM_FIELD_1',
  CustomField_2 = 'CUSTOM_FIELD_2',
  CustomField_3 = 'CUSTOM_FIELD_3',
  CustomField_4 = 'CUSTOM_FIELD_4',
  Department = 'DEPARTMENT',
  Division = 'DIVISION',
  Email = 'EMAIL',
  EmployeeNumber = 'EMPLOYEE_NUMBER',
  EmployeeStartDate = 'EMPLOYEE_START_DATE',
  FirstName = 'FIRST_NAME',
  Groups = 'GROUPS',
  JobTitle = 'JOB_TITLE',
  LastName = 'LAST_NAME',
  LastSignInAt = 'LAST_SIGN_IN_AT',
  Location = 'LOCATION',
  ManagerEmail = 'MANAGER_EMAIL',
  ManagerName = 'MANAGER_NAME',
  Organization = 'ORGANIZATION',
  RiskScore = 'RISK_SCORE',
  TimeZone = 'TIME_ZONE',
}

export enum ReportsUserIdpDataColumns {
  CompanyName = 'COMPANY_NAME',
  Country = 'COUNTRY',
  EmployeeType = 'EMPLOYEE_TYPE',
  LastPasswordChangeDateTime = 'LAST_PASSWORD_CHANGE_DATE_TIME',
  MailNickname = 'MAIL_NICKNAME',
  OnPremisesSamAccountName = 'ON_PREMISES_SAM_ACCOUNT_NAME',
  OnPremisesSecurityIdentifier = 'ON_PREMISES_SECURITY_IDENTIFIER',
  UserPrincipalName = 'USER_PRINCIPAL_NAME',
}

/** Autogenerated return type of RerunDemoSeed. */
export type RerunDemoSeedPayload = {
  __typename?: 'RerunDemoSeedPayload'
  errors?: Maybe<Array<Error>>
  node: Scalars['Boolean']
}

/** Restricted domain */
export type RestrictedDomain = Identifier &
  Timestamps & {
    __typename?: 'RestrictedDomain'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The name of the restricted domain */
    name: Scalars['String']
    /** Additional notes related to the restricted domain */
    notes?: Maybe<Scalars['String']>
    /** Indicates if the domain is a top-level domain */
    tld: Scalars['Boolean']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type RestrictedDomainAttributes = {
  name?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
}

export enum RestrictedDomainSortFields {
  Name = 'NAME',
  Notes = 'NOTES',
  UpdatedAt = 'UPDATED_AT',
}

/** Autogenerated return type of RestrictedDomainsCreate. */
export type RestrictedDomainsCreatePayload = {
  __typename?: 'RestrictedDomainsCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<RestrictedDomain>
}

export type RestrictedDomainsCursor = {
  __typename?: 'RestrictedDomainsCursor'
  /** The paginated nodes */
  nodes: Array<RestrictedDomain>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of RestrictedDomainsDelete. */
export type RestrictedDomainsDeletePayload = {
  __typename?: 'RestrictedDomainsDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<RestrictedDomain>
}

/** Autogenerated return type of RestrictedDomainsUpdate. */
export type RestrictedDomainsUpdatePayload = {
  __typename?: 'RestrictedDomainsUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<RestrictedDomain>
}

/** A user event */
export type Risk = {
  __typename?: 'Risk'
  /** The mode of decay for the User Event API event risk level */
  decayMode?: Maybe<Scalars['String']>
  /** The date at which the User Event API Risk Level expires */
  expireDate?: Maybe<Scalars['String']>
  /** The risk level associated with the User Event API event */
  level?: Maybe<Scalars['Int']>
}

export enum RiskBoosterLevels {
  High = 'HIGH',
  Highest = 'HIGHEST',
  Low = 'LOW',
  Normal = 'NORMAL',
}

export type RiskScoreByUser = {
  __typename?: 'RiskScoreByUser'
  /** A CSV string representation of the report data, including users and their Risk Scores */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** A list of users along with their associated Risk Scores */
  users: Array<UserRiskScore>
}

/** Risk Score for a user */
export type RiskScoreHistory = Identifier & {
  __typename?: 'RiskScoreHistory'
  /** The timestamp when the record was created */
  createdAt: Scalars['ISO8601DateTime']
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The calculated Risk Score */
  riskScore?: Maybe<Scalars['Float']>
  /** The user associated with this Risk Score */
  user: User
}

/** The account Risk Score stats */
export type RiskScoreStats = {
  __typename?: 'RiskScoreStats'
  /** Historical Risk Score data points */
  riskHistories?: Maybe<Array<TimeData>>
  /** Current Risk Score of an entity */
  riskScore?: Maybe<Scalars['Float']>
}

export type RiskScoreV2ByGroup = {
  __typename?: 'RiskScoreV2ByGroup'
  partnerAccountSettings?: Maybe<Array<PartnerAccountSettings>>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  zenkaToken: Scalars['String']
  zenkaUrl: Scalars['String']
}

export type RiskScoreV2ByManager = {
  __typename?: 'RiskScoreV2ByManager'
  partnerAccountSettings?: Maybe<Array<PartnerAccountSettings>>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  zenkaToken: Scalars['String']
  zenkaUrl: Scalars['String']
}

export type RiskScoreV2ByUser = {
  __typename?: 'RiskScoreV2ByUser'
  partnerAccountSettings?: Maybe<Array<PartnerAccountSettings>>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  zenkaToken: Scalars['String']
  zenkaUrl: Scalars['String']
}

export type RiskStatistics = {
  __typename?: 'RiskStatistics'
  /** Average number of detections per user */
  detectionsPerUser?: Maybe<Scalars['Int']>
  /** The total number of categories */
  totalCategories?: Maybe<Scalars['Int']>
  /** The total number of detections */
  totalDetections?: Maybe<Scalars['Int']>
  /** The total number of unique users */
  uniqueUsers?: Maybe<Scalars['Int']>
}

/** Attributes for creating or updating a real-time coaching campaign */
export type RtcCampaignAttributes = {
  activateFromEmail?: Maybe<Scalars['Boolean']>
  criterions: Array<GroupCriterionAttributes>
  customContentId?: Maybe<Scalars['Int']>
  deliveryMethod?: Maybe<DeliveryMethods>
  fallbackEmail: Scalars['Boolean']
  limitDeliveries: Scalars['Boolean']
  name: Scalars['String']
  notificationId: Scalars['String']
  notificationType: NotificationTypes
  storeItemUuid?: Maybe<Scalars['String']>
  testMode: Scalars['Boolean']
  testModeEmail?: Maybe<Scalars['String']>
  useGif: Scalars['Boolean']
}

export type RuleCampaignsCount = {
  __typename?: 'RuleCampaignsCount'
  count: Scalars['Int']
  ruleId: Scalars['String']
}

export enum RuleDetectionCountFilters {
  Any = 'ANY',
  Eq1 = 'eq1',
  Gt1 = 'gt1',
  Gt5 = 'gt5',
}

export enum RuleDisputeStatus {
  AnalysisInProgress = 'analysisInProgress',
  Expired = 'expired',
  New = 'new',
  Rejected = 'rejected',
  Resolved = 'resolved',
}

export type RuleOptionData = {
  __typename?: 'RuleOptionData'
  /** The list of available delimiters for mapping */
  delimiters: Array<SelectOption>
  /** The left-hand options available for mapping */
  leftHand: Array<SelectOption>
  /** The right-hand options available for mapping */
  rightHand: Array<SelectOption>
}

export enum RuleStatus {
  Disabled = 'disabled',
  Enabled = 'enabled',
  Maintenance = 'maintenance',
}

export enum RuleVerb {
  Contains = 'contains',
  Is = 'is',
}

export type RulesSummary = {
  __typename?: 'RulesSummary'
  /** The count of active rules */
  activeRulesCount?: Maybe<Scalars['Int']>
  /** The count of available rules */
  availableRulesCount?: Maybe<Scalars['Int']>
  /** The number of rule detections */
  ruleDetections?: Maybe<Scalars['Int']>
  /** The total number of users */
  totalUsers?: Maybe<Scalars['Int']>
}

/** A message campaign run */
export type Run = Identifier &
  Timestamps & {
    __typename?: 'Run'
    /** The recipients actively targeted by the run */
    activeRecipients?: Maybe<Array<Recipient>>
    /** Indicates if the message is intended for all users */
    allUsers: Scalars['Boolean']
    /** Indicates if the message run is archived */
    archived: Scalars['Boolean']
    /** The campaign this run belongs to */
    campaign?: Maybe<Campaign>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The email address from which the message is sent */
    fromEmail?: Maybe<Scalars['String']>
    /** Additional field information from the sender */
    fromField?: Maybe<Scalars['String']>
    /** The name from which the message is sent */
    fromName?: Maybe<Scalars['String']>
    /** Groups targeted by the message run */
    groups?: Maybe<Array<Group>>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The content of the message in this run */
    messageContents?: Maybe<Array<MessageContent>>
    /** The ID of the associated messages campaign */
    messagesCampaignsId?: Maybe<Scalars['Int']>
    /** The name of the message run */
    name?: Maybe<Scalars['String']>
    /** Topics associated with the message run */
    runTopics?: Maybe<Array<MessageTopic>>
    /** Indicates if an email should be sent for this run */
    sendEmail: Scalars['Boolean']
    /** Statistics related to the message run */
    statistics?: Maybe<Statistics>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type RunRecipientsCursor = {
  __typename?: 'RunRecipientsCursor'
  /** The paginated nodes */
  nodes: Array<Recipient>
  /** Information about the current paginated set */
  pagination: Pagination
}

export type RunsCursor = {
  __typename?: 'RunsCursor'
  /** The paginated nodes */
  nodes: Array<Run>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** The Salesforce account settings */
export type Salesforce = Identifier &
  Timestamps & {
    __typename?: 'Salesforce'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Indicates whether to also use the parent partner’s Salesforce ID to fetch subscriptions data */
    fetchFromBothSfid: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The timestamp of the last data fetch */
    lastFetchAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The partner admin who performed the last fetch */
    lastFetchBy?: Maybe<PartnerAdmin>
    /** The scheduled timestamp for the next data fetch */
    nextFetchAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The partner admin scheduled to perform the next fetch */
    nextFetchBy?: Maybe<PartnerAdmin>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type SalesforceAttributes = {
  fetchFromBothSfid?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['Int']>
  lastFetchAt?: Maybe<Scalars['ISO8601DateTime']>
  lastFetchById?: Maybe<Scalars['Int']>
}

/** SAML certificate info */
export type SamlCertificate = Identifier &
  Timestamps & {
    __typename?: 'SamlCertificate'
    certificate: Scalars['String']
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    expiresAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    issuedAt: Scalars['ISO8601DateTime']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type SamlSettingsAttributes = {
  allowAdminWithMfaLoginBypass?: Maybe<Scalars['Boolean']>
  certificateNotificationEnabled?: Maybe<Scalars['Boolean']>
  certificateNotificationRecipients?: Maybe<Scalars['String']>
  disableNonSamlLogins?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['Int']>
  signSamlRequests?: Maybe<Scalars['Boolean']>
}

export type Save = {
  __typename?: 'Save'
  error?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['String']>
}

/** Saved report */
export type SavedReport = ReportConfigurable & {
  __typename?: 'SavedReport'
  auditLogDisplay?: Maybe<Scalars['String']>
  /** The category to which the report belongs */
  category?: Maybe<ReportCategory>
  /** The ID of the report category */
  categoryId?: Maybe<Scalars['Int']>
  configuration: Configurations
  /** The timestamp when the record was created */
  createdAt: Scalars['ISO8601DateTime']
  /** The creator of the report */
  creator?: Maybe<User>
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** A flag indicating if the report was created under impersonation */
  impersonated?: Maybe<Scalars['Boolean']>
  /** The name of the saved report */
  name: Scalars['String']
  /** The type of report */
  type: DownloadablesTypes
  /** The timestamp when the record was last updated */
  updatedAt: Scalars['ISO8601DateTime']
}

/** Autogenerated return type of SavedReportsCategoriesCreate. */
export type SavedReportsCategoriesCreatePayload = {
  __typename?: 'SavedReportsCategoriesCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created saved report category */
  node?: Maybe<ReportCategory>
}

/** Autogenerated return type of SavedReportsCategoriesDelete. */
export type SavedReportsCategoriesDeletePayload = {
  __typename?: 'SavedReportsCategoriesDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The deleted saved report category */
  node?: Maybe<ReportCategory>
}

/** Autogenerated return type of SavedReportsCategoriesReorder. */
export type SavedReportsCategoriesReorderPayload = {
  __typename?: 'SavedReportsCategoriesReorderPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The reordered saved report categories */
  nodes?: Maybe<Array<ReportCategory>>
}

/** Autogenerated return type of SavedReportsCategoriesUpdate. */
export type SavedReportsCategoriesUpdatePayload = {
  __typename?: 'SavedReportsCategoriesUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated saved report category */
  node?: Maybe<ReportCategory>
}

/** Autogenerated return type of SavedReportsClone. */
export type SavedReportsClonePayload = {
  __typename?: 'SavedReportsClonePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<SavedReport>
}

/** Autogenerated return type of SavedReportsDelete. */
export type SavedReportsDeletePayload = {
  __typename?: 'SavedReportsDeletePayload'
  errors?: Maybe<Array<Error>>
  nodes: Array<Scalars['Int']>
}

/** Autogenerated return type of SavedReportsDownload. */
export type SavedReportsDownloadPayload = {
  __typename?: 'SavedReportsDownloadPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<SavedReport>
}

export type SavedReportsFiltersCursor = {
  __typename?: 'SavedReportsFiltersCursor'
  /** The paginated nodes */
  nodes: Array<Group>
  /** Information about the current paginated set */
  pagination: Pagination
}

export type SavedReportsList = {
  categoryId?: Maybe<Scalars['Int']>
  default?: Maybe<Scalars['Boolean']>
  groupIds?: Maybe<Array<Maybe<Scalars['Int']>>>
  includeOthers?: Maybe<Scalars['Boolean']>
  reportTypes?: Maybe<Array<ReportsTypes>>
  search?: Maybe<Scalars['String']>
  searchReportTypes?: Maybe<Array<Scalars['String']>>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<SavedReportsSortFields>
  type?: Maybe<ReportsTypes>
}

/** Autogenerated return type of SavedReportsMove. */
export type SavedReportsMovePayload = {
  __typename?: 'SavedReportsMovePayload'
  error?: Maybe<Error>
  nodes?: Maybe<Array<SavedReport>>
}

/** Autogenerated return type of SavedReportsSave. */
export type SavedReportsSavePayload = {
  __typename?: 'SavedReportsSavePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<SavedReport>
}

export enum SavedReportsSortFields {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  ReportCategoryId = 'REPORT_CATEGORY_ID',
  UpdatedAt = 'UPDATED_AT',
}

/** Security Coach metadata for an event */
export type SchMetadata = {
  __typename?: 'SchMetadata'
  /** The name of the campaign */
  campaignName?: Maybe<Scalars['String']>
  /** The ID of the custom content associated with this metadata */
  customContentId?: Maybe<Scalars['Int']>
  /** The source from which the metadata was derived */
  source?: Maybe<Scalars['String']>
  /** The unique identifier for the metadata */
  uuid?: Maybe<Scalars['String']>
}

export enum Schedulable {
  ExecReport = 'ExecReport',
  SavedReport = 'SavedReport',
}

/** Configuration for scheduling a report */
export type ScheduledReport = Identifier &
  Timestamps & {
    __typename?: 'ScheduledReport'
    auditLogDisplay?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The creator of the scheduled report */
    creator?: Maybe<User>
    /** The timestamp when the creator was changed */
    creatorChangedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** A list of executable reports associated with the scheduled report */
    execReports: Array<ExecReportsExecReport>
    /** The file format in which the report is to be generated */
    format: DownloadFileTypes
    /** The frequency period for the report generation */
    frequencyPeriod: ReportFrequencies
    /** A list of groups that have access to the scheduled report */
    groups: Array<Group>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Indicates if the report generation is being impersonated */
    impersonated?: Maybe<Scalars['Boolean']>
    /** The last time the report was sent */
    lastSentAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The name of the scheduled report */
    name: Scalars['String']
    /** The next scheduled time for sending the report */
    nextSendAt: Scalars['ISO8601DateTime']
    /** The original creator of the scheduled report */
    originalCreator?: Maybe<User>
    /** A list of partner admins associated with the scheduled report */
    partnerAdmins: Array<PartnerAdmin>
    /** A list of saved reports associated with the scheduled report */
    savedReports: Array<SavedReport>
    /** Indicates if the report should be sent to its creator */
    sendToCreator: Scalars['Boolean']
    /** The start date from which the report generation begins */
    startDate: Scalars['ISO8601Date']
    /** The current status of the scheduled report */
    status: ScheduledReportStatuses
    /** The specific time of the day when the report is generated */
    time: Scalars['Time']
    /** The timezone in which the report time is specified */
    timeZone: TimeZones
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type ScheduledReportAttributes = {
  format: DownloadFileTypes
  frequencyPeriod: ReportFrequencies
  name: Scalars['String']
  schedulableId?: Maybe<Scalars['String']>
  sendTo: Array<Scalars['Int']>
  sendToCreator?: Maybe<Scalars['Boolean']>
  startDate: Scalars['ISO8601Date']
  time: Scalars['Time']
  timeZone: TimeZones
}

export enum ScheduledReportStatuses {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Processing = 'PROCESSING',
  Ready = 'READY',
}

/** Autogenerated return type of ScheduledReportsDeactivate. */
export type ScheduledReportsDeactivatePayload = {
  __typename?: 'ScheduledReportsDeactivatePayload'
  errors?: Maybe<Array<Error>>
  node: ScheduledReport
}

/** Autogenerated return type of ScheduledReportsDelete. */
export type ScheduledReportsDeletePayload = {
  __typename?: 'ScheduledReportsDeletePayload'
  errors?: Maybe<Array<Error>>
  node: ScheduledReport
}

export type ScheduledReportsList = {
  accountIds?: Maybe<Array<Scalars['Int']>>
  includeOthers?: Maybe<Scalars['Boolean']>
  reportTypes?: Maybe<Array<ReportsTypes>>
  search?: Maybe<Scalars['String']>
  searchReportTypes?: Maybe<Array<ReportsTypes>>
  sortDirection?: Maybe<SortDirections>
  sortField?: Maybe<ScheduledReportsSortFields>
  status?: Maybe<ScheduledReportStatuses>
  type?: Maybe<ReportsTypes>
}

export type ScheduledReportsListCursor = {
  __typename?: 'ScheduledReportsListCursor'
  /** The paginated nodes */
  nodes: Array<ScheduledReport>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of ScheduledReportsSave. */
export type ScheduledReportsSavePayload = {
  __typename?: 'ScheduledReportsSavePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ScheduledReport>
}

/** Autogenerated return type of ScheduledReportsSend. */
export type ScheduledReportsSendPayload = {
  __typename?: 'ScheduledReportsSendPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ScheduledReport>
}

export enum ScheduledReportsSortFields {
  CreatedAt = 'CREATED_AT',
  Format = 'FORMAT',
  FrequencyPeriod = 'FREQUENCY_PERIOD',
  Name = 'NAME',
  NextSendAt = 'NEXT_SEND_AT',
  UpdatedAt = 'UPDATED_AT',
}

export enum ScimPurgeTypes {
  All = 'ALL',
  Groups = 'GROUPS',
  Users = 'USERS',
}

/** Autogenerated return type of SecondChanceAddToOrionList. */
export type SecondChanceAddToOrionListPayload = {
  __typename?: 'SecondChanceAddToOrionListPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** Whether the domains were added */
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecondChanceBulkDeleteDomains. */
export type SecondChanceBulkDeleteDomainsPayload = {
  __typename?: 'SecondChanceBulkDeleteDomainsPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** Whether the domains were deleted */
  node?: Maybe<Scalars['Boolean']>
}

/** A device from Second Chance */
export type SecondChanceDevice = Identifier & {
  __typename?: 'SecondChanceDevice'
  /** The count of aborted interactions with the device */
  abortedCount: Scalars['Int']
  /** The count of aborted interactions with the device in the last 24 hours */
  abortedCount24: Scalars['Int']
  /** The version of the agent running on the device */
  agentVersion: Scalars['String']
  /** The count of continued interactions with the device */
  continuedCount: Scalars['Int']
  /** The count of continued interactions with the device in the last 24 hours */
  continuedCount24: Scalars['Int']
  /** The date and time when the device was created */
  createdAt: Scalars['ISO8601DateTime']
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The date and time when the last action was taken on the device */
  lastActionDate?: Maybe<Scalars['ISO8601DateTime']>
  /** The ID of the last user who interacted with the device */
  lastUserId?: Maybe<Scalars['Int']>
  /** The name of the last user who interacted with the device */
  lastUserName?: Maybe<Scalars['String']>
  /** Name of the Second Chance device */
  name: Scalars['String']
  /** The number of days the device has been monitored */
  overviewDays: Scalars['Int']
  /** UUID of the device */
  uuid: Scalars['String']
}

export enum SecondChanceDeviceSortFields {
  AgentVersion = 'AGENT_VERSION',
  LastAction = 'LAST_ACTION',
  LastUser = 'LAST_USER',
  Name = 'NAME',
}

export type SecondChanceDevicesCursor = {
  __typename?: 'SecondChanceDevicesCursor'
  /** The paginated nodes */
  nodes: Array<SecondChanceDevice>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** A No Prompt Domain from Second Chance */
export type SecondChanceDomain = Identifier & {
  __typename?: 'SecondChanceDomain'
  /** The date and time when the domain was registered */
  createdAt: Scalars['ISO8601DateTime']
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The registered domain name */
  name: Scalars['String']
  /** The unicode format of the domain name */
  unicodeName?: Maybe<Scalars['String']>
}

/** A domain prompt from Second Chance */
export type SecondChanceDomainPrompt = {
  __typename?: 'SecondChanceDomainPrompt'
  /** The count of Second Chances for the domain */
  count: Scalars['Int']
  /** The name of the domain associated with the Second Chance */
  name: Scalars['String']
}

export type SecondChanceDomainPromptsCursor = {
  __typename?: 'SecondChanceDomainPromptsCursor'
  /** The paginated nodes */
  nodes: Array<SecondChanceDomainPrompt>
  /** Information about the current paginated set */
  pagination: Pagination
}

export enum SecondChanceDomainSortFields {
  DateAdded = 'DATE_ADDED',
  Name = 'NAME',
  UnicodeName = 'UNICODE_NAME',
}

export type SecondChanceDomainsCursor = {
  __typename?: 'SecondChanceDomainsCursor'
  /** The paginated nodes */
  nodes: Array<SecondChanceDomain>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of SecondChanceImportDomains. */
export type SecondChanceImportDomainsPayload = {
  __typename?: 'SecondChanceImportDomainsPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The number of domains imported */
  node?: Maybe<Scalars['Int']>
}

/** The stats and info for the Second Chance Overview page */
export type SecondChanceOverview = {
  __typename?: 'SecondChanceOverview'
  /** Count of licenses aborted during the Second cChance */
  abortedCount: Scalars['Int']
  /** Count of licenses aborted within 24 hours after the Second Chance */
  abortedCount24: Scalars['Int']
  /** Count of licenses continued after the Second Chance */
  continuedCount: Scalars['Int']
  /** Count of licenses continued within 24 hours after the Second Chance */
  continuedCount24: Scalars['Int']
  /** The number of devices covered by the license */
  deviceCount: Scalars['Int']
  /** URL to download the installer */
  installerDownloadUrl?: Maybe<Scalars['String']>
  /** Unique license key identifier */
  licenseKey: Scalars['String']
  /** The number of days the overview covers */
  overviewDays: Scalars['Int']
}

/** A post action from Second Chance */
export type SecondChancePostAction = {
  __typename?: 'SecondChancePostAction'
  /** The date when the action was taken */
  actionDate: Scalars['ISO8601DateTime']
  /** Description of the action taken */
  actionTaken: Scalars['String']
  /** The ID of the device used for the action */
  deviceId: Scalars['Int']
  /** The name of the device used for the action */
  deviceName: Scalars['String']
  /** The URL associated with the post action */
  url: Scalars['String']
  /** The ID of the user who took the action */
  userId: Scalars['Int']
  /** The displayed name of the user who took the action */
  userName: Scalars['String']
}

export enum SecondChancePostActionSortFields {
  Actiontaken = 'ACTIONTAKEN',
  Date = 'DATE',
  Device = 'DEVICE',
  Url = 'URL',
  User = 'USER',
}

export type SecondChancePostActionsCursor = {
  __typename?: 'SecondChancePostActionsCursor'
  /** The paginated nodes */
  nodes: Array<SecondChancePostAction>
  /** Information about the current paginated set */
  pagination: Pagination
}

export type SecondChanceSettingAttributes = {
  enabled?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['Int']>
  overviewDays?: Maybe<Scalars['Int']>
}

/** The settings for Second Chance */
export type SecondChanceSettings = {
  __typename?: 'SecondChanceSettings'
  /** The prompt text when dealing with attachments */
  attachmentPromptText: Scalars['String']
  /** Whether not to prompt for PSTs */
  doNotPromptForPhishingTests: Scalars['Boolean']
  /** Whether not to prompt on internal hosts */
  doNotPromptOnInternalHosts: Scalars['Boolean']
  /** The button text to allow an unknown action */
  unknownAllowButtonText: Scalars['String']
  /** The banner text for an unknown message */
  unknownMessageBannerText: Scalars['String']
  /** The prompt text for an unknown message */
  unknownMessagePromptText: Scalars['String']
  /** The text dealing with punycode for unknown cases */
  unknownPunycodeText: Scalars['String']
  /** The button text to turn back time for an unknown decision */
  unknownTurnBackTimeButtonText: Scalars['String']
  /** The prompt text when unwinding actions */
  unwindingPromptText: Scalars['String']
  /** Whether to unwrap URL rewriters */
  unwrapRewriters: Scalars['Boolean']
  /** Whether to unwrap URL shorteners */
  unwrapShorteners: Scalars['Boolean']
  /** Whether to use the No Prompt Domain list */
  useOrion: Scalars['Boolean']
}

export type SecondChanceSettingsAttributes = {
  /** The text of the attachment prompt */
  attachmentPromptText?: Maybe<Scalars['String']>
  /** Whether to not prompt for phishing tests */
  doNotPromptForPhishingTests?: Maybe<Scalars['Boolean']>
  /** Whether to not prompt on internal hosts */
  doNotPromptOnInternalHosts?: Maybe<Scalars['Boolean']>
  /** The text of the unknown allow button */
  unknownAllowButtonText?: Maybe<Scalars['String']>
  /** The text of the unknown message banner */
  unknownMessageBannerText?: Maybe<Scalars['String']>
  /** The text of the unknown message prompt */
  unknownMessagePromptText?: Maybe<Scalars['String']>
  /** The text of the unknown punycode */
  unknownPunycodeText?: Maybe<Scalars['String']>
  /** The text of the unknown turn back time button */
  unknownTurnBackTimeButtonText?: Maybe<Scalars['String']>
  /** The text of the unwinding prompt */
  unwindingPromptText?: Maybe<Scalars['String']>
  /** Whether to unwrap rewriters */
  unwrapRewriters?: Maybe<Scalars['Boolean']>
  /** Whether to unwrap shorteners */
  unwrapShorteners?: Maybe<Scalars['Boolean']>
  /** Whether to use Orion */
  useOrion?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecondChanceUpdateSettings. */
export type SecondChanceUpdateSettingsPayload = {
  __typename?: 'SecondChanceUpdateSettingsPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated settings */
  node?: Maybe<Scalars['Boolean']>
}

/** A URL from Second Chance */
export type SecondChanceUrl = {
  __typename?: 'SecondChanceUrl'
  /** Total number of times the abortion was chosen */
  abortedCount: Scalars['Int']
  /** Number of times the abortion was chosen in the last 24 hours */
  abortedCount24: Scalars['Int']
  /** Total number of times the continuation was chosen */
  continuedCount: Scalars['Int']
  /** Number of times the continuation was chosen in the last 24 hours */
  continuedCount24: Scalars['Int']
  /** An encoded version of the URL also known as ID */
  encodedUrl: Scalars['String']
  /** The date and time of the last visit */
  lastVisit: Scalars['ISO8601DateTime']
  /** Number of days in the overview */
  overviewDays: Scalars['Int']
  /** The primary URL for Second Chance */
  url: Scalars['String']
}

export enum SecondChanceUrlSortFields {
  Aborted = 'ABORTED',
  Continued = 'CONTINUED',
  LastVisitDate = 'LAST_VISIT_DATE',
  Url = 'URL',
}

export type SecondChanceUrlsCursor = {
  __typename?: 'SecondChanceUrlsCursor'
  /** The paginated nodes */
  nodes: Array<SecondChanceUrl>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** A user from Second Chance */
export type SecondChanceUser = Identifier & {
  __typename?: 'SecondChanceUser'
  /** Count of aborted actions by the Second Chance user */
  abortedCount: Scalars['Int']
  /** Count of aborted actions by the Second Chance user in the last 24 hours */
  abortedCount24: Scalars['Int']
  /** Total number of actions performed by the Second Chance user */
  actionCount: Scalars['Int']
  /** Count of continued actions by the Second Chance user */
  continuedCount: Scalars['Int']
  /** Count of continued actions by the Second Chance user in the last 24 hours */
  continuedCount24: Scalars['Int']
  /** Display name of the Second Chance user */
  displayName: Scalars['String']
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** Date and time of the last action performed by the Second Chance user */
  lastActionDate?: Maybe<Scalars['ISO8601DateTime']>
  /** ID of the last device used by the Second Chance user */
  lastDeviceId?: Maybe<Scalars['Int']>
  /** Name of the last device used by the Second Chance user */
  lastDeviceName?: Maybe<Scalars['String']>
  /** Total number of days the user has been overviewed */
  overviewDays: Scalars['Int']
}

export enum SecondChanceUserSortFields {
  ActionCount = 'ACTION_COUNT',
  DisplayName = 'DISPLAY_NAME',
  LastAction = 'LAST_ACTION',
  LastDevice = 'LAST_DEVICE',
}

export type SecondChanceUsersCursor = {
  __typename?: 'SecondChanceUsersCursor'
  /** The paginated nodes */
  nodes: Array<SecondChanceUser>
  /** Information about the current paginated set */
  pagination: Pagination
}

export type SecurityCoach = Identifier & {
  __typename?: 'SecurityCoach'
  /** Boolean value for the security coach criterion */
  boolean: Booleans
  /** Comparison type for the security coach criterion */
  comparison: EventComparisons
  /** Count for the security coach criterion */
  count: Scalars['Int']
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** List of rule IDs for the security coach criterion */
  ruleIds: Array<Scalars['String']>
  /** Timeframe for the security coach criterion */
  timeframe: Timeframe
}

export type SecurityCoachActivityRtcAggregate = {
  __typename?: 'SecurityCoachActivityRtcAggregate'
  /** Total number of detections */
  detectionCount?: Maybe<Scalars['Int']>
  /** Count of notifications sent via Email */
  emailNotificationCount?: Maybe<Scalars['Int']>
  /** Count of notifications sent via Google Chat */
  gchatNotificationCount?: Maybe<Scalars['Int']>
  /** Number of detections scheduled for review */
  scheduledDetectionCount?: Maybe<Scalars['Int']>
  /** Count of notifications sent via Slack */
  slackNotificationCount?: Maybe<Scalars['Int']>
  /** Count of notifications sent via Microsoft Teams */
  teamsNotificationCount?: Maybe<Scalars['Int']>
  /** Number of unique campaign detections */
  uniqueCampaignDetectionCount?: Maybe<Scalars['Int']>
  /** Associated user for this security coach activity */
  user?: Maybe<User>
}

export type SecurityCoachActivityRtcAggregateCsv = {
  __typename?: 'SecurityCoachActivityRtcAggregateCsv'
  /** CSV content for the aggregate report */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type SecurityCoachActivityRtcAggregateCursor = {
  __typename?: 'SecurityCoachActivityRtcAggregateCursor'
  columns?: Maybe<Columns>
  nodes: Array<SecurityCoachActivityRtcAggregate>
  pagination: Pagination
  timeframe?: Maybe<ReportTimeframe>
}

export type SecurityCoachActivityRtcRawCsv = {
  __typename?: 'SecurityCoachActivityRtcRawCsv'
  /** CSV format of the report data */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type SecurityCoachActivityRtcRawCursor = {
  __typename?: 'SecurityCoachActivityRtcRawCursor'
  columns?: Maybe<Columns>
  nodes: Array<SecurityCoachDetection>
  pagination: Pagination
  timeframe?: Maybe<ReportTimeframe>
}

/** Aggregate RTC Campaign Data */
export type SecurityCoachAggregateCampaignData = {
  __typename?: 'SecurityCoachAggregateCampaignData'
  detectionCount?: Maybe<Scalars['Int']>
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  groups?: Maybe<Array<Scalars['String']>>
  lastName?: Maybe<Scalars['String']>
  userId: Scalars['Int']
}

/** Autogenerated return type of SecurityCoachAnalyticsCreateRemapJobForUnmappedEvents. */
export type SecurityCoachAnalyticsCreateRemapJobForUnmappedEventsPayload = {
  __typename?: 'SecurityCoachAnalyticsCreateRemapJobForUnmappedEventsPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<RemapJobCreatedMessage>
}

/** Autogenerated return type of SecurityCoachApproveUserMappingRecommendation. */
export type SecurityCoachApproveUserMappingRecommendationPayload = {
  __typename?: 'SecurityCoachApproveUserMappingRecommendationPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

export type SecurityCoachArgument = {
  bool: Booleans
  count: Scalars['Int']
  countType: EventComparisons
  ruleIds: Array<Scalars['String']>
  timeframe: TimeframeArgument
}

/** A Real Time Coaching Campaign */
export type SecurityCoachCampaign = {
  __typename?: 'SecurityCoachCampaign'
  /** Content associated with the campaign */
  content?: Maybe<ContentTypeUnion>
  /** Date and time when the campaign was converted to active TODO: DOCS CHANGE THIS!!! */
  convertedToActiveCampaignAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The date and time when the campaign was created */
  createdAt: Scalars['ISO8601DateTime']
  /** Method for delivering the campaign content */
  deliveryMethod?: Maybe<DeliveryMethods>
  /** Indicates if fallback to email is enabled for the campaign delivery */
  fallbackEmail: Scalars['Boolean']
  /** Group associated with the campaign */
  group: Group
  /** Unique identifier of the campaign */
  id: Scalars['Int']
  /** Indicates if the deliveries for this campaign are limited */
  limitDeliveries: Scalars['Boolean']
  /** Name of the campaign */
  name: Scalars['String']
  /** Notification template for the campaign */
  notificationTemplate?: Maybe<SystemCustomNotification>
  /** Type of the notification template */
  notificationTemplateType: Scalars['String']
  /** Unique identifier for the notification template */
  notificationTemplateUuid: Scalars['String']
  /** Rules for teachable moments in the campaign */
  rules?: Maybe<Array<TeachableMomentRules>>
  /** Criteria for smart groups within the campaign */
  smartGroupCriterions: Array<GroupCriterion>
  /** Current status of the campaign */
  status: CampaignStatuses
  /** Indicates if the campaign is in test mode TODO: DOCS CHANGE THIS!!! */
  testMode: Scalars['Boolean']
  /** Email address for test mode TODO: DOCS CHANGE THIS!!! */
  testModeEmail?: Maybe<Scalars['String']>
  /** Start date and time for test mode TODO: DOCS CHANGE THIS!!! */
  testModeStart?: Maybe<Scalars['ISO8601DateTime']>
  /** Indicates if the campaign uses a gif */
  useGif: Scalars['Boolean']
}

/** RTC Campaign Data */
export type SecurityCoachCampaignData = {
  __typename?: 'SecurityCoachCampaignData'
  createdAt: Scalars['ISO8601DateTime']
  deliveryError?: Maybe<DeliveryErrors>
  deliveryMethod?: Maybe<DetectionDeliveryMethods>
  detectionRuleId?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  status: DeliveryStatus
  tmeId?: Maybe<Scalars['String']>
  userId: Scalars['Int']
  vendor?: Maybe<Scalars['String']>
}

/** Autogenerated return type of SecurityCoachCampaignsCreate. */
export type SecurityCoachCampaignsCreatePayload = {
  __typename?: 'SecurityCoachCampaignsCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

export type SecurityCoachCampaignsCursor = {
  __typename?: 'SecurityCoachCampaignsCursor'
  nodes: Array<SecurityCoachCampaign>
  pagination: Pagination
}

/** Autogenerated return type of SecurityCoachCampaignsDelete. */
export type SecurityCoachCampaignsDeletePayload = {
  __typename?: 'SecurityCoachCampaignsDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<SecurityCoachCampaign>
}

/** Autogenerated return type of SecurityCoachCampaignsSetStatus. */
export type SecurityCoachCampaignsSetStatusPayload = {
  __typename?: 'SecurityCoachCampaignsSetStatusPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<SecurityCoachCampaign>
}

/** Autogenerated return type of SecurityCoachCampaignsUpdate. */
export type SecurityCoachCampaignsUpdatePayload = {
  __typename?: 'SecurityCoachCampaignsUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<SecurityCoachCampaign>
}

/** Autogenerated return type of SecurityCoachCdrmsCreateCategory. */
export type SecurityCoachCdrmsCreateCategoryPayload = {
  __typename?: 'SecurityCoachCdrmsCreateCategoryPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachCdrmsCreateRule. */
export type SecurityCoachCdrmsCreateRulePayload = {
  __typename?: 'SecurityCoachCdrmsCreateRulePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachCdrmsCreateTopic. */
export type SecurityCoachCdrmsCreateTopicPayload = {
  __typename?: 'SecurityCoachCdrmsCreateTopicPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachCdrmsCreateVendor. */
export type SecurityCoachCdrmsCreateVendorPayload = {
  __typename?: 'SecurityCoachCdrmsCreateVendorPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachCdrmsDeleteCategory. */
export type SecurityCoachCdrmsDeleteCategoryPayload = {
  __typename?: 'SecurityCoachCdrmsDeleteCategoryPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachCdrmsDeleteRule. */
export type SecurityCoachCdrmsDeleteRulePayload = {
  __typename?: 'SecurityCoachCdrmsDeleteRulePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachCdrmsDeleteTopic. */
export type SecurityCoachCdrmsDeleteTopicPayload = {
  __typename?: 'SecurityCoachCdrmsDeleteTopicPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachCdrmsDeleteVendor. */
export type SecurityCoachCdrmsDeleteVendorPayload = {
  __typename?: 'SecurityCoachCdrmsDeleteVendorPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachCdrmsReleaseRule. */
export type SecurityCoachCdrmsReleaseRulePayload = {
  __typename?: 'SecurityCoachCdrmsReleaseRulePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachCdrmsReleaseVendorToProduction. */
export type SecurityCoachCdrmsReleaseVendorToProductionPayload = {
  __typename?: 'SecurityCoachCdrmsReleaseVendorToProductionPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachCdrmsUpdateCategory. */
export type SecurityCoachCdrmsUpdateCategoryPayload = {
  __typename?: 'SecurityCoachCdrmsUpdateCategoryPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachCdrmsUpdateDetectionRuleDispute. */
export type SecurityCoachCdrmsUpdateDetectionRuleDisputePayload = {
  __typename?: 'SecurityCoachCdrmsUpdateDetectionRuleDisputePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachCdrmsUpdatePartialCategory. */
export type SecurityCoachCdrmsUpdatePartialCategoryPayload = {
  __typename?: 'SecurityCoachCdrmsUpdatePartialCategoryPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachCdrmsUpdatePartialRule. */
export type SecurityCoachCdrmsUpdatePartialRulePayload = {
  __typename?: 'SecurityCoachCdrmsUpdatePartialRulePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachCdrmsUpdatePartialTopic. */
export type SecurityCoachCdrmsUpdatePartialTopicPayload = {
  __typename?: 'SecurityCoachCdrmsUpdatePartialTopicPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachCdrmsUpdatePartialVendor. */
export type SecurityCoachCdrmsUpdatePartialVendorPayload = {
  __typename?: 'SecurityCoachCdrmsUpdatePartialVendorPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachCdrmsUpdateRule. */
export type SecurityCoachCdrmsUpdateRulePayload = {
  __typename?: 'SecurityCoachCdrmsUpdateRulePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachCdrmsUpdateTopic. */
export type SecurityCoachCdrmsUpdateTopicPayload = {
  __typename?: 'SecurityCoachCdrmsUpdateTopicPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachCdrmsUpdateVendor. */
export type SecurityCoachCdrmsUpdateVendorPayload = {
  __typename?: 'SecurityCoachCdrmsUpdateVendorPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** SecurityCoach content type */
export type SecurityCoachContent = {
  __typename?: 'SecurityCoachContent'
  /** A list of options related to the content */
  contentOptions?: Maybe<Array<AssetProperty>>
  /** The URL to the cover image of the content */
  coverImage: Scalars['String']
  /** The unique identifier of the content */
  id: Scalars['String']
  /** The ranking of the content */
  ranking?: Maybe<Scalars['Int']>
  /** The title of the content */
  title: Scalars['String']
}

export enum SecurityCoachCsvUploadStatus {
  Failed = 'failed',
  PartiallyFailed = 'partially_failed',
  Processing = 'processing',
  Successful = 'successful',
}

/** Autogenerated return type of SecurityCoachCustomContentArchive. */
export type SecurityCoachCustomContentArchivePayload = {
  __typename?: 'SecurityCoachCustomContentArchivePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<CustomContent>
}

/** Autogenerated return type of SecurityCoachCustomContentCreate. */
export type SecurityCoachCustomContentCreatePayload = {
  __typename?: 'SecurityCoachCustomContentCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<CustomContent>
}

/** Autogenerated return type of SecurityCoachCustomContentDelete. */
export type SecurityCoachCustomContentDeletePayload = {
  __typename?: 'SecurityCoachCustomContentDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<CustomContent>
}

/** Autogenerated return type of SecurityCoachCustomContentTranslationsCreate. */
export type SecurityCoachCustomContentTranslationsCreatePayload = {
  __typename?: 'SecurityCoachCustomContentTranslationsCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<CustomContentTranslation>
}

/** Autogenerated return type of SecurityCoachCustomContentTranslationsDelete. */
export type SecurityCoachCustomContentTranslationsDeletePayload = {
  __typename?: 'SecurityCoachCustomContentTranslationsDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<CustomContentTranslation>
}

/** Autogenerated return type of SecurityCoachCustomContentTranslationsPublish. */
export type SecurityCoachCustomContentTranslationsPublishPayload = {
  __typename?: 'SecurityCoachCustomContentTranslationsPublishPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<CustomContentTranslation>
}

/** Autogenerated return type of SecurityCoachCustomContentTranslationsUpdate. */
export type SecurityCoachCustomContentTranslationsUpdatePayload = {
  __typename?: 'SecurityCoachCustomContentTranslationsUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<CustomContentTranslation>
}

/** Autogenerated return type of SecurityCoachCustomContentUpdate. */
export type SecurityCoachCustomContentUpdatePayload = {
  __typename?: 'SecurityCoachCustomContentUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<CustomContent>
}

/** Autogenerated return type of SecurityCoachCustomNotificationsClone. */
export type SecurityCoachCustomNotificationsClonePayload = {
  __typename?: 'SecurityCoachCustomNotificationsClonePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<CustomNotification>
}

/** Autogenerated return type of SecurityCoachCustomNotificationsCreate. */
export type SecurityCoachCustomNotificationsCreatePayload = {
  __typename?: 'SecurityCoachCustomNotificationsCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<CustomNotification>
}

/** Autogenerated return type of SecurityCoachCustomNotificationsDelete. */
export type SecurityCoachCustomNotificationsDeletePayload = {
  __typename?: 'SecurityCoachCustomNotificationsDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<CustomNotification>
}

/** Autogenerated return type of SecurityCoachCustomNotificationsUpdate. */
export type SecurityCoachCustomNotificationsUpdatePayload = {
  __typename?: 'SecurityCoachCustomNotificationsUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<CustomNotification>
}

/** Autogenerated return type of SecurityCoachCustomTranslationsCreate. */
export type SecurityCoachCustomTranslationsCreatePayload = {
  __typename?: 'SecurityCoachCustomTranslationsCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<CustomTranslation>
}

/** Autogenerated return type of SecurityCoachCustomTranslationsDelete. */
export type SecurityCoachCustomTranslationsDeletePayload = {
  __typename?: 'SecurityCoachCustomTranslationsDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<CustomTranslation>
}

/** Autogenerated return type of SecurityCoachCustomTranslationsUpdate. */
export type SecurityCoachCustomTranslationsUpdatePayload = {
  __typename?: 'SecurityCoachCustomTranslationsUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<CustomTranslation>
}

/** Autogenerated return type of SecurityCoachDeliveryGchatDeleteDomain. */
export type SecurityCoachDeliveryGchatDeleteDomainPayload = {
  __typename?: 'SecurityCoachDeliveryGchatDeleteDomainPayload'
  deleted?: Maybe<Scalars['Boolean']>
  errors?: Maybe<Array<Error>>
}

/** Autogenerated return type of SecurityCoachDeliveryGchatSendSampleMessage. */
export type SecurityCoachDeliveryGchatSendSampleMessagePayload = {
  __typename?: 'SecurityCoachDeliveryGchatSendSampleMessagePayload'
  error?: Maybe<Scalars['String']>
  messageData?: Maybe<MessageSentData>
}

/** Autogenerated return type of SecurityCoachDeliveryPreview. */
export type SecurityCoachDeliveryPreviewPayload = {
  __typename?: 'SecurityCoachDeliveryPreviewPayload'
  error?: Maybe<Scalars['String']>
}

/** Autogenerated return type of SecurityCoachDeliverySlackSendSampleMessage. */
export type SecurityCoachDeliverySlackSendSampleMessagePayload = {
  __typename?: 'SecurityCoachDeliverySlackSendSampleMessagePayload'
  error?: Maybe<Scalars['String']>
  messageData?: Maybe<MessageSentData>
}

export type SecurityCoachDeliverySlackSetup = {
  __typename?: 'SecurityCoachDeliverySlackSetup'
  /** The URL for setting up slack integration */
  setupUrl: Scalars['String']
}

/** Autogenerated return type of SecurityCoachDeliverySlackWorkspacesDelete. */
export type SecurityCoachDeliverySlackWorkspacesDeletePayload = {
  __typename?: 'SecurityCoachDeliverySlackWorkspacesDeletePayload'
  deleted?: Maybe<Scalars['Boolean']>
  errors?: Maybe<Array<Error>>
}

/** Autogenerated return type of SecurityCoachDeliveryTeamsDeleteFromTeam. */
export type SecurityCoachDeliveryTeamsDeleteFromTeamPayload = {
  __typename?: 'SecurityCoachDeliveryTeamsDeleteFromTeamPayload'
  deleted?: Maybe<Scalars['Boolean']>
  errors?: Maybe<Array<Error>>
}

/** Autogenerated return type of SecurityCoachDeliveryTeamsDeleteIntegrationCallback. */
export type SecurityCoachDeliveryTeamsDeleteIntegrationCallbackPayload = {
  __typename?: 'SecurityCoachDeliveryTeamsDeleteIntegrationCallbackPayload'
  deleted?: Maybe<Scalars['Boolean']>
  errors?: Maybe<Array<Error>>
}

/** Autogenerated return type of SecurityCoachDeliveryTeamsDeleteTeamsFromOrg. */
export type SecurityCoachDeliveryTeamsDeleteTeamsFromOrgPayload = {
  __typename?: 'SecurityCoachDeliveryTeamsDeleteTeamsFromOrgPayload'
  deleted?: Maybe<Scalars['Boolean']>
  errors?: Maybe<Array<Error>>
}

/** Autogenerated return type of SecurityCoachDeliveryTeamsRegister. */
export type SecurityCoachDeliveryTeamsRegisterPayload = {
  __typename?: 'SecurityCoachDeliveryTeamsRegisterPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachDeliveryTeamsReviewPermissionsCallback. */
export type SecurityCoachDeliveryTeamsReviewPermissionsCallbackPayload = {
  __typename?: 'SecurityCoachDeliveryTeamsReviewPermissionsCallbackPayload'
  errors?: Maybe<Array<Error>>
  registered?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachDeliveryTeamsSendSampleMessage. */
export type SecurityCoachDeliveryTeamsSendSampleMessagePayload = {
  __typename?: 'SecurityCoachDeliveryTeamsSendSampleMessagePayload'
  error?: Maybe<Scalars['String']>
  messageData?: Maybe<MessageSentData>
}

export type SecurityCoachDeliveryTeamsSetup = {
  __typename?: 'SecurityCoachDeliveryTeamsSetup'
  /** The URL for team setup */
  setupUrl: Scalars['String']
}

/** Autogenerated return type of SecurityCoachDeliveryTeamsUploadBotCallback. */
export type SecurityCoachDeliveryTeamsUploadBotCallbackPayload = {
  __typename?: 'SecurityCoachDeliveryTeamsUploadBotCallbackPayload'
  errors?: Maybe<Array<Error>>
  registered?: Maybe<Scalars['Boolean']>
}

/** Detection in the account */
export type SecurityCoachDetection = Timestamps & {
  __typename?: 'SecurityCoachDetection'
  /** The creation date and time of the detection */
  createdAt: Scalars['ISO8601DateTime']
  /** The error that occurred during delivery */
  deliveryError?: Maybe<DeliveryErrors>
  /** The method through which the detection was delivered */
  deliveryMethod?: Maybe<DetectionDeliveryMethods>
  /** The real-time campaign associated with this detection */
  rtcCampaign?: Maybe<SecurityCoachCampaign>
  /** The current status of the detection */
  status?: Maybe<Scalars['String']>
  /** The last update date and time of the detection */
  updatedAt: Scalars['ISO8601DateTime']
  /** The user this detection is associated with */
  user?: Maybe<User>
}

export type SecurityCoachDetectionRulesActivityAggregateColumns = {
  __typename?: 'SecurityCoachDetectionRulesActivityAggregateColumns'
  /** Columns related to aggregate detection rule activity */
  aggregateDetectionRuleColumns?: Maybe<
    Array<ReportsSecurityCoachDetectionRulesActivityAggregateDetectionRuleColumns>
  >
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** Columns related to user data */
  userColumns?: Maybe<Array<ReportsUserColumns>>
  /** Columns related to user identity provider data */
  userIdpDataColumns?: Maybe<Array<ReportsUserIdpDataColumns>>
}

export type SecurityCoachDetectionRulesActivityAggregateCsv = {
  __typename?: 'SecurityCoachDetectionRulesActivityAggregateCsv'
  /** CSV format of the aggregate report data */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type SecurityCoachDetectionRulesActivityRawColumns = {
  __typename?: 'SecurityCoachDetectionRulesActivityRawColumns'
  /** Columns related to the detection rules activities */
  detectionRuleColumns?: Maybe<
    Array<ReportsSecurityCoachDetectionRulesActivityDetectionRuleColumns>
  >
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** Columns related to the user information */
  userColumns?: Maybe<Array<ReportsUserColumns>>
  /** Columns related to the user identity provider data */
  userIdpDataColumns?: Maybe<Array<ReportsUserIdpDataColumns>>
}

export type SecurityCoachDetectionRulesActivityRawCsv = {
  __typename?: 'SecurityCoachDetectionRulesActivityRawCsv'
  /** The CSV representation of the Security Coach Detection Rules Activity report */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

/** SecurityCoach packages */
export type SecurityCoachDownloadablePackages = {
  __typename?: 'SecurityCoachDownloadablePackages'
  /** Details about the assets included in the package */
  assetDetails?: Maybe<Scalars['String']>
  /** The path to the preview image of the package */
  previewImagePath: Scalars['String']
}

/** Autogenerated return type of SecurityCoachEditUserMapping. */
export type SecurityCoachEditUserMappingPayload = {
  __typename?: 'SecurityCoachEditUserMappingPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** SecurityCoach Event Type */
export type SecurityCoachEvent = EventTypeInterface & {
  __typename?: 'SecurityCoachEvent'
  description?: Maybe<Scalars['String']>
  /** The name of the security coach event */
  eventName: SecurityCoachTimelineEventTypes
  id: Scalars['BigInt']
}

/** SecurityCoach language */
export type SecurityCoachLanguage = {
  __typename?: 'SecurityCoachLanguage'
  /** The unique code representing the language */
  code: Scalars['String']
}

/** SecurityCoach notification type */
export type SecurityCoachNotification = {
  __typename?: 'SecurityCoachNotification'
  /** The unique identifier of the notification */
  id: Scalars['String']
  /** The name of the notification */
  name: Scalars['String']
  /** A list of template translations for the notification */
  templateTranslations: Array<SecurityCoachNotificationTranslation>
}

/** SecurityCoach notification type */
export type SecurityCoachNotificationTranslation = {
  __typename?: 'SecurityCoachNotificationTranslation'
  /** Filename of the attachment if any */
  attachmentFilename?: Maybe<Scalars['String']>
  /** HTML content of the notification */
  contentHtml: Scalars['String']
  /** Email address of the sender */
  from?: Maybe<Scalars['String']>
  /** Display name of the sender */
  fromDisplayName?: Maybe<Scalars['String']>
  /** Unique identifier of the notification translation */
  id: Scalars['String']
  /** Language of the notification translation */
  language?: Maybe<Language>
  /** Name of the notification translation */
  name: Scalars['String']
  /** Array of email addresses for reply-to field */
  replyTo?: Maybe<Array<Scalars['String']>>
  /** Display name for the reply-to addresses */
  replyToDisplayName?: Maybe<Scalars['String']>
  /** Status of the notification translation */
  status?: Maybe<Scalars['String']>
  /** Subject of the notification */
  subject?: Maybe<Scalars['String']>
  /** Universally Unique Identifier of the notification translation */
  uuid: Scalars['String']
}

/** Autogenerated return type of SecurityCoachOrchestrationCloneTmr. */
export type SecurityCoachOrchestrationCloneTmrPayload = {
  __typename?: 'SecurityCoachOrchestrationCloneTmrPayload'
  errors?: Maybe<Array<Error>>
  results?: Maybe<CloneRuleResponse>
}

/** Autogenerated return type of SecurityCoachOrchestrationCreateTeachableMomentRule. */
export type SecurityCoachOrchestrationCreateTeachableMomentRulePayload = {
  __typename?: 'SecurityCoachOrchestrationCreateTeachableMomentRulePayload'
  errors?: Maybe<Array<Error>>
  results?: Maybe<TeachableMomentRules>
}

/** Autogenerated return type of SecurityCoachOrchestrationDeleteTeachableMomentRule. */
export type SecurityCoachOrchestrationDeleteTeachableMomentRulePayload = {
  __typename?: 'SecurityCoachOrchestrationDeleteTeachableMomentRulePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachOrchestrationEditTeachableMomentRule. */
export type SecurityCoachOrchestrationEditTeachableMomentRulePayload = {
  __typename?: 'SecurityCoachOrchestrationEditTeachableMomentRulePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachOrchestrationEnableTeachableMomentRule. */
export type SecurityCoachOrchestrationEnableTeachableMomentRulePayload = {
  __typename?: 'SecurityCoachOrchestrationEnableTeachableMomentRulePayload'
  errors?: Maybe<Array<Error>>
  rule?: Maybe<TeachableMomentRules>
}

/** Autogenerated return type of SecurityCoachOrchestrationMarkFalsePositiveRule. */
export type SecurityCoachOrchestrationMarkFalsePositiveRulePayload = {
  __typename?: 'SecurityCoachOrchestrationMarkFalsePositiveRulePayload'
  errors?: Maybe<Array<Error>>
  workflowId?: Maybe<Scalars['String']>
}

/** Autogenerated return type of SecurityCoachPreview. */
export type SecurityCoachPreviewPayload = {
  __typename?: 'SecurityCoachPreviewPayload'
  errors?: Maybe<Array<Error>>
  preview?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachQuote. */
export type SecurityCoachQuotePayload = {
  __typename?: 'SecurityCoachQuotePayload'
  errors?: Maybe<Array<Error>>
  quote?: Maybe<Scalars['Boolean']>
}

export type SecurityCoachRealTimeCoaching = Identifier & {
  __typename?: 'SecurityCoachRealTimeCoaching'
  /** Boolean value to determine the condition */
  boolean: Booleans
  /** Comparison type for the Real-Time Coaching event */
  comparison: EventComparisons
  /** Defines the count for the event */
  count: Scalars['Int']
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** List of Real-Time Coaching campaign IDs */
  rtcCampaignIds: Array<Scalars['Int']>
  /** Timeframe for the SecurityCoach Real-Time Coaching criterion */
  timeframe: Timeframe
}

export type SecurityCoachRealTimeCoachingArgument = {
  bool: Booleans
  count: Scalars['Int']
  countType: EventComparisons
  rtcCampaignIds: Array<Scalars['Int']>
  timeframe: TimeframeArgument
}

/** Autogenerated return type of SecurityCoachRejectUserMappingRecommendation. */
export type SecurityCoachRejectUserMappingRecommendationPayload = {
  __typename?: 'SecurityCoachRejectUserMappingRecommendationPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachResetPreview. */
export type SecurityCoachResetPreviewPayload = {
  __typename?: 'SecurityCoachResetPreviewPayload'
  reset?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachRulesCreate. */
export type SecurityCoachRulesCreatePayload = {
  __typename?: 'SecurityCoachRulesCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Int']>
}

/** Autogenerated return type of SecurityCoachRulesDelete. */
export type SecurityCoachRulesDeletePayload = {
  __typename?: 'SecurityCoachRulesDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachRulesToggle. */
export type SecurityCoachRulesTogglePayload = {
  __typename?: 'SecurityCoachRulesTogglePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachSetupStatusUpdate. */
export type SecurityCoachSetupStatusUpdatePayload = {
  __typename?: 'SecurityCoachSetupStatusUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<SetupStatus>
}

export enum SecurityCoachTimelineEventTypes {
  GrapheneRtcFailed = 'graphene_rtc_failed',
  GrapheneRtcReceived = 'graphene_rtc_received',
  GrapheneTmEvent = 'graphene_tm_event',
}

/** Autogenerated return type of SecurityCoachToggleEngineStatus. */
export type SecurityCoachToggleEngineStatusPayload = {
  __typename?: 'SecurityCoachToggleEngineStatusPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<EngineStatus>
}

/** SecurityCoach content type */
export type SecurityCoachTranslatedSecurityTip = {
  __typename?: 'SecurityCoachTranslatedSecurityTip'
  /** Alternative text for accessibility purposes */
  altText?: Maybe<Scalars['String']>
  /** A list of downloadable packages related to the security tip */
  downloadablePackages: Array<SecurityCoachDownloadablePackages>
  /** The language of the security tip */
  language: SecurityCoachLanguage
  /** The title of the security tip */
  title: Scalars['String']
}

/** Report download */
export type SecurityCoachUnmappedEventsReport = {
  __typename?: 'SecurityCoachUnmappedEventsReport'
  /** Category of the downloadable content */
  category: DownloadablesCategories
  /** Configuration details for the SecurityCoach Unmapped Events Report */
  configuration: SecurityCoachUnmappedEventsReportConfiguration
  /** The creation date and time of the report */
  createdAt: Scalars['ISO8601DateTime']
  /** Unique identifier of the SecurityCoach Unmapped Events Report */
  id: Scalars['Int']
  /** User impersonating for accessing the report, if any */
  impersonatingUser?: Maybe<User>
  /** The type of downloadable content */
  type: DownloadablesTypes
  /** The last update date and time of the report */
  updatedAt: Scalars['ISO8601DateTime']
}

export type SecurityCoachUnmappedEventsReportConfiguration = {
  __typename?: 'SecurityCoachUnmappedEventsReportConfiguration'
  /** The end date up to which the report should be generated */
  endDate?: Maybe<Scalars['ISO8601Date']>
  /** The start date from when to begin generating the report */
  startDate: Scalars['ISO8601Date']
  /** Code names for vendors associated with this configuration */
  vendorCodeNames?: Maybe<Scalars['String']>
}

/** Autogenerated return type of SecurityCoachUserMappingCsvUpload. */
export type SecurityCoachUserMappingCsvUploadPayload = {
  __typename?: 'SecurityCoachUserMappingCsvUploadPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SecurityCoachVendorsBitdefenderConnect. */
export type SecurityCoachVendorsBitdefenderConnectPayload = {
  __typename?: 'SecurityCoachVendorsBitdefenderConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsBitdefenderUpdate. */
export type SecurityCoachVendorsBitdefenderUpdatePayload = {
  __typename?: 'SecurityCoachVendorsBitdefenderUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsCarbonBlackConnect. */
export type SecurityCoachVendorsCarbonBlackConnectPayload = {
  __typename?: 'SecurityCoachVendorsCarbonBlackConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsCarbonBlackUpdate. */
export type SecurityCoachVendorsCarbonBlackUpdatePayload = {
  __typename?: 'SecurityCoachVendorsCarbonBlackUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsCiscoConnect. */
export type SecurityCoachVendorsCiscoConnectPayload = {
  __typename?: 'SecurityCoachVendorsCiscoConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsCiscoUmbrellaConnect. */
export type SecurityCoachVendorsCiscoUmbrellaConnectPayload = {
  __typename?: 'SecurityCoachVendorsCiscoUmbrellaConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsCiscoUmbrellaUpdate. */
export type SecurityCoachVendorsCiscoUmbrellaUpdatePayload = {
  __typename?: 'SecurityCoachVendorsCiscoUmbrellaUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsCiscoUpdate. */
export type SecurityCoachVendorsCiscoUpdatePayload = {
  __typename?: 'SecurityCoachVendorsCiscoUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsCloudflareArea1Connect. */
export type SecurityCoachVendorsCloudflareArea1ConnectPayload = {
  __typename?: 'SecurityCoachVendorsCloudflareArea1ConnectPayload'
  errors?: Maybe<Array<Error>>
  key?: Maybe<Scalars['String']>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsCloudflareArea1Update. */
export type SecurityCoachVendorsCloudflareArea1UpdatePayload = {
  __typename?: 'SecurityCoachVendorsCloudflareArea1UpdatePayload'
  errors?: Maybe<Array<Error>>
  key?: Maybe<Scalars['String']>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsCloudflareZeroTrustConnect. */
export type SecurityCoachVendorsCloudflareZeroTrustConnectPayload = {
  __typename?: 'SecurityCoachVendorsCloudflareZeroTrustConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsCloudflareZeroTrustUpdate. */
export type SecurityCoachVendorsCloudflareZeroTrustUpdatePayload = {
  __typename?: 'SecurityCoachVendorsCloudflareZeroTrustUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsCodeFourTwoConnect. */
export type SecurityCoachVendorsCodeFourTwoConnectPayload = {
  __typename?: 'SecurityCoachVendorsCodeFourTwoConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsCodeFourTwoUpdate. */
export type SecurityCoachVendorsCodeFourTwoUpdatePayload = {
  __typename?: 'SecurityCoachVendorsCodeFourTwoUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsCrowdstrikeConnect. */
export type SecurityCoachVendorsCrowdstrikeConnectPayload = {
  __typename?: 'SecurityCoachVendorsCrowdstrikeConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsCrowdstrikeUpdate. */
export type SecurityCoachVendorsCrowdstrikeUpdatePayload = {
  __typename?: 'SecurityCoachVendorsCrowdstrikeUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsCylanceConnect. */
export type SecurityCoachVendorsCylanceConnectPayload = {
  __typename?: 'SecurityCoachVendorsCylanceConnectPayload'
  errors?: Maybe<Array<Error>>
  key?: Maybe<Scalars['String']>
}

/** Autogenerated return type of SecurityCoachVendorsDelete. */
export type SecurityCoachVendorsDeletePayload = {
  __typename?: 'SecurityCoachVendorsDeletePayload'
  deleted?: Maybe<Scalars['Boolean']>
  errors?: Maybe<Array<Error>>
}

/** Autogenerated return type of SecurityCoachVendorsDismissAlert. */
export type SecurityCoachVendorsDismissAlertPayload = {
  __typename?: 'SecurityCoachVendorsDismissAlertPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<VendorAlert>
}

/** Autogenerated return type of SecurityCoachVendorsFortigateCloudConnect. */
export type SecurityCoachVendorsFortigateCloudConnectPayload = {
  __typename?: 'SecurityCoachVendorsFortigateCloudConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsFortigateCloudUpdate. */
export type SecurityCoachVendorsFortigateCloudUpdatePayload = {
  __typename?: 'SecurityCoachVendorsFortigateCloudUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsGoogleConnect. */
export type SecurityCoachVendorsGoogleConnectPayload = {
  __typename?: 'SecurityCoachVendorsGoogleConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsGoogleUpdate. */
export type SecurityCoachVendorsGoogleUpdatePayload = {
  __typename?: 'SecurityCoachVendorsGoogleUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsKmsatConnect. */
export type SecurityCoachVendorsKmsatConnectPayload = {
  __typename?: 'SecurityCoachVendorsKmsatConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsMalwarebytesConnect. */
export type SecurityCoachVendorsMalwarebytesConnectPayload = {
  __typename?: 'SecurityCoachVendorsMalwarebytesConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsMalwarebytesUpdate. */
export type SecurityCoachVendorsMalwarebytesUpdatePayload = {
  __typename?: 'SecurityCoachVendorsMalwarebytesUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsMicrosoftConnect. */
export type SecurityCoachVendorsMicrosoftConnectPayload = {
  __typename?: 'SecurityCoachVendorsMicrosoftConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsMicrosoftUpdate. */
export type SecurityCoachVendorsMicrosoftUpdatePayload = {
  __typename?: 'SecurityCoachVendorsMicrosoftUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsMimecastConnect. */
export type SecurityCoachVendorsMimecastConnectPayload = {
  __typename?: 'SecurityCoachVendorsMimecastConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsMimecastUpdate. */
export type SecurityCoachVendorsMimecastUpdatePayload = {
  __typename?: 'SecurityCoachVendorsMimecastUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsNetskopeConnect. */
export type SecurityCoachVendorsNetskopeConnectPayload = {
  __typename?: 'SecurityCoachVendorsNetskopeConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsNetskopeUpdate. */
export type SecurityCoachVendorsNetskopeUpdatePayload = {
  __typename?: 'SecurityCoachVendorsNetskopeUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsOktaConnect. */
export type SecurityCoachVendorsOktaConnectPayload = {
  __typename?: 'SecurityCoachVendorsOktaConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsOktaUpdate. */
export type SecurityCoachVendorsOktaUpdatePayload = {
  __typename?: 'SecurityCoachVendorsOktaUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsPaloAltoNgfwConnect. */
export type SecurityCoachVendorsPaloAltoNgfwConnectPayload = {
  __typename?: 'SecurityCoachVendorsPaloAltoNgfwConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsPaloAltoNgfwUpdate. */
export type SecurityCoachVendorsPaloAltoNgfwUpdatePayload = {
  __typename?: 'SecurityCoachVendorsPaloAltoNgfwUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsPaloaltoConnect. */
export type SecurityCoachVendorsPaloaltoConnectPayload = {
  __typename?: 'SecurityCoachVendorsPaloaltoConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsProofpointConnect. */
export type SecurityCoachVendorsProofpointConnectPayload = {
  __typename?: 'SecurityCoachVendorsProofpointConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsProofpointUpdate. */
export type SecurityCoachVendorsProofpointUpdatePayload = {
  __typename?: 'SecurityCoachVendorsProofpointUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsSentinelOneConnect. */
export type SecurityCoachVendorsSentinelOneConnectPayload = {
  __typename?: 'SecurityCoachVendorsSentinelOneConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsSentinelOneUpdate. */
export type SecurityCoachVendorsSentinelOneUpdatePayload = {
  __typename?: 'SecurityCoachVendorsSentinelOneUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsSonicwallConnect. */
export type SecurityCoachVendorsSonicwallConnectPayload = {
  __typename?: 'SecurityCoachVendorsSonicwallConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsSonicwallUpdate. */
export type SecurityCoachVendorsSonicwallUpdatePayload = {
  __typename?: 'SecurityCoachVendorsSonicwallUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsSophosConnect. */
export type SecurityCoachVendorsSophosConnectPayload = {
  __typename?: 'SecurityCoachVendorsSophosConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsSophosUpdate. */
export type SecurityCoachVendorsSophosUpdatePayload = {
  __typename?: 'SecurityCoachVendorsSophosUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Registration>
}

/** Autogenerated return type of SecurityCoachVendorsZscalerConnect. */
export type SecurityCoachVendorsZscalerConnectPayload = {
  __typename?: 'SecurityCoachVendorsZscalerConnectPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<ZscalerKeys>
}

/** A Security Role */
export type SecurityRole = Identifier &
  Timestamps & {
    __typename?: 'SecurityRole'
    auditLogDisplay?: Maybe<Scalars['String']>
    auditLogName?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** List of groups associated with this Security Role */
    groups: Array<Group>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The name of the Security Role */
    name: Scalars['String']
    /** The permissions granted to this Security Role */
    permissions: SecurityRolePermission
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The number of users associated with this Security Role */
    userCount: Scalars['Int']
  }

export type SecurityRoleAttributes = {
  /** The IDs of the groups to assign the Security Role to */
  groupIds: Array<Scalars['Int']>
  /** The name of the Security Role */
  name: Scalars['String']
  /** The permissions to assign to the Security Role */
  permissions: SecurityRolePermissionAttributes
}

/** A Security Role permission */
export type SecurityRolePermission = Identifier &
  Timestamps & {
    __typename?: 'SecurityRolePermission'
    /** Permission level for accessing account settings */
    accountSettings: Scalars['Int']
    /** Permission level for accessing advanced reporting features */
    advancedReporting: Scalars['Int']
    /** Permission level for ASAP */
    asap: Scalars['Int']
    /** Permission level for viewing the audit log */
    auditLog: Scalars['Int']
    /** Permission level for accessing branded content features */
    brandedContents: Scalars['Int']
    /** Permission to add users and groups to training campaigns */
    canAddUsersAndGroupsToTrainingCampaign: Scalars['Int']
    /** Permission to view training campaigns with restricted users */
    canSeeTrainingCampaignsWithRestrictedUsers: Scalars['Int']
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Permission level for phishing dashboard access */
    dashboardPhishing: Scalars['Int']
    /** Permission level for training dashboard access */
    dashboardTraining: Scalars['Int']
    /** Permission level for accessing executive reports */
    executiveReports: Scalars['Int']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Permission level for Password IQ feature access */
    passwordIq: Scalars['Int']
    /** Permission level for creating and managing phishing campaigns */
    phishingCampaigns: Scalars['Int']
    /** Permission level for creating and managing phishing landing pages */
    phishingLandingPages: Scalars['Int']
    /** Permission level for accessing phishing reports */
    phishingReports: Scalars['Int']
    /** Permission level for creating and managing phishing templates */
    phishingTemplates: Scalars['Int']
    /** Permission level for managing physical QR code campaigns */
    physicalQrCampaigns: Scalars['Int']
    /** Permission level for provisioning */
    provisioning: Scalars['Int']
    /** Permission level for accessing Detection Rules Activity Reports */
    reportsDetectionRulesActivity: Scalars['Int']
    /** Permission level for reports on sent email templates */
    reportsEmailTemplatesSent: Scalars['Int']
    /** Permission level for accessing reports on failure types */
    reportsFailureTypes: Scalars['Int']
    /** Permission level for failure reports by phishing template */
    reportsFailuresByPhishingTemplate: Scalars['Int']
    /** Permission level for accessing Group and User Report Cards */
    reportsGroupAndUserCards: Scalars['Int']
    /** Permission level for accessing learner platform reports */
    reportsLearnerPlatform: Scalars['Int']
    /** Permission level for accessing Phish-failure Percentage reports */
    reportsPhishFailurePercentage: Scalars['Int']
    /** Permission level for accessing Phish-prone Percentage reports */
    reportsPhishPronePercentage: Scalars['Int']
    /** Permission level for accessing phishing activity reports */
    reportsPhishingActivity: Scalars['Int']
    /** Permission level for user-specific phishing results reports */
    reportsPhishingResultsByUser: Scalars['Int']
    /** Permission level for accessing quiz interaction reports */
    reportsQuizInteractions: Scalars['Int']
    /** Permission level for Real-Time Coaching Activity reports */
    reportsRealTimeCoachingActivity: Scalars['Int']
    /** Permission level for accessing risk score reports */
    reportsRiskScore: Scalars['Int']
    /** Permission level for SAPA eports */
    reportsSecurityAwarenessProficiencyAssessment: Scalars['Int']
    /** Permission level for Security Culture Survey reports */
    reportsSecurityCultureSurvey: Scalars['Int']
    /** Permission level for accessing Training Activity reports */
    reportsTrainingActivity: Scalars['Int']
    /** Permission level for accessing training comparison reports */
    reportsTrainingComparison: Scalars['Int']
    /** Permission level for accessing training Content Survey reports */
    reportsTrainingContentSurvey: Scalars['Int']
    /** Permission level for accessing training hours reports */
    reportsTrainingHours: Scalars['Int']
    /** Permission level for accessing training status by campaign reports */
    reportsTrainingStatusByCampaign: Scalars['Int']
    /** Permission level for accessing training status overview reports */
    reportsTrainingStatusOverview: Scalars['Int']
    /** Permission level for unphished and untrained user reports */
    reportsUnphishedAndUntrainedUser: Scalars['Int']
    /** Permission level for accessing user count and login activity reports */
    reportsUserCountAndLogin: Scalars['Int']
    /** List of permission scopes available to the Security Role */
    scopes: Array<SecurityRolePermissionScope>
    /** Permission level for accessing Second Chance settings */
    secondChanceSettings: Scalars['Int']
    /** Permission level for accessing SecurityCoach dashboard and reports */
    securityCoachDashboardAndReports: Scalars['Int']
    /** Permission level for managing Security Coach detection rules */
    securityCoachDetectionRules: Scalars['Int']
    /** Permission for managing SecurityCoach Real-Time Coaching and catalog */
    securityCoachRtcAndCatalog: Scalars['Int']
    /** Permission level for SecurityCoach setup */
    securityCoachSetup: Scalars['Int']
    /** Permission level for sending reports */
    sendReports: Scalars['Int']
    /** Permission level for accessing store items */
    storeItems: Scalars['Int']
    /** Permission level for creating and managing training campaigns */
    trainingCampaigns: Scalars['Int']
    /** Permission level for creating and managing training policies */
    trainingPolicies: Scalars['Int']
    /** Permission level for purchasing training content */
    trainingPurchases: Scalars['Int']
    /** Permission level for accessing training reports */
    trainingReports: Scalars['Int']
    /** Permission level for creating and managing training templates */
    trainingTemplates: Scalars['Int']
    /** Permission level for uploading training content */
    trainingUploadedContent: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** Permission level for managing USB drive test campaigns */
    usbCampaigns: Scalars['Int']
    /** Permission level for user management */
    users: Scalars['Int']
  }

export type SecurityRolePermissionAttributes = {
  /** The account settings permission */
  accountSettings: Scalars['Int']
  /** The advanced reporting permission */
  advancedReporting: Scalars['Int']
  /** The ASAP permission */
  asap: Scalars['Int']
  /** The audit log permission */
  auditLog: Scalars['Int']
  /** The branded contents permission */
  brandedContents: Scalars['Int']
  /** The permission to add users and groups to a training campaign */
  canAddUsersAndGroupsToTrainingCampaign: Scalars['Int']
  /** The permission to see training campaigns with restricted users */
  canSeeTrainingCampaignsWithRestrictedUsers: Scalars['Int']
  /** The dashboard phishing permission */
  dashboardPhishing: Scalars['Int']
  /** The dashboard training permission */
  dashboardTraining: Scalars['Int']
  /** The executive reports permission */
  executiveReports: Scalars['Int']
  /** The Password IQ permission */
  passwordIq: Scalars['Int']
  /** The phishing campaigns permission */
  phishingCampaigns: Scalars['Int']
  /** The phishing landing pages permission */
  phishingLandingPages: Scalars['Int']
  /** The phishing reports permission */
  phishingReports: Scalars['Int']
  /** The phishing templates permission */
  phishingTemplates: Scalars['Int']
  /** The physical QR code campaigns permission */
  physicalQrCampaigns: Scalars['Int']
  /** The provisioning permission */
  provisioning: Scalars['Int']
  /** The Detection Rules Activity Report permission */
  reportsDetectionRulesActivity: Scalars['Int']
  /** The email templates sent report permission */
  reportsEmailTemplatesSent: Scalars['Int']
  /** The failure types report permission */
  reportsFailureTypes: Scalars['Int']
  /** The failures by phishing template report permission */
  reportsFailuresByPhishingTemplate: Scalars['Int']
  /** The Group and User Report Card permission */
  reportsGroupAndUserCards: Scalars['Int']
  /** The Learner Platform Report permission */
  reportsLearnerPlatform: Scalars['Int']
  /** The Phish failure Percentage report permission */
  reportsPhishFailurePercentage: Scalars['Int']
  /** The Phish-prone Percentage report permission */
  reportsPhishPronePercentage: Scalars['Int']
  /** The phishing activity report permission */
  reportsPhishingActivity: Scalars['Int']
  /** The phishing results by user report permission */
  reportsPhishingResultsByUser: Scalars['Int']
  /** The quiz interactions report permission */
  reportsQuizInteractions: Scalars['Int']
  /** The Real-Time Coaching activity report permission */
  reportsRealTimeCoachingActivity: Scalars['Int']
  /** The Risk Score report permission */
  reportsRiskScore: Scalars['Int']
  /** The SAPA report permission */
  reportsSecurityAwarenessProficiencyAssessment: Scalars['Int']
  /** The Security Culture Survey report permission */
  reportsSecurityCultureSurvey: Scalars['Int']
  /** The Training Activity Reports permission */
  reportsTrainingActivity: Scalars['Int']
  /** The training comparison report permission */
  reportsTrainingComparison: Scalars['Int']
  /** The training content survey report permission */
  reportsTrainingContentSurvey: Scalars['Int']
  /** The training hours report permission */
  reportsTrainingHours: Scalars['Int']
  /** The training status by campaign report permission */
  reportsTrainingStatusByCampaign: Scalars['Int']
  /** The training status overview report permission */
  reportsTrainingStatusOverview: Scalars['Int']
  /** The unphished and untrained user report permission */
  reportsUnphishedAndUntrainedUser: Scalars['Int']
  /** The user count and login report permission */
  reportsUserCountAndLogin: Scalars['Int']
  /** The scopes of the Security Role */
  scopes: Array<SecurityRoleScopeAttributes>
  /** The Second Chance settings permission */
  secondChanceSettings: Scalars['Int']
  /** The SecurityCoach dashboard and reports permission */
  securityCoachDashboardAndReports: Scalars['Int']
  /** The SecurityCoach detection rules permission */
  securityCoachDetectionRules: Scalars['Int']
  /** The SecurityCoach Real-Time Coaching and catalog permission */
  securityCoachRtcAndCatalog: Scalars['Int']
  /** The SecurityCoach setup permission */
  securityCoachSetup: Scalars['Int']
  /** The send reports permission */
  sendReports: Scalars['Int']
  /** The store items permission */
  storeItems: Scalars['Int']
  /** The training campaigns permission */
  trainingCampaigns: Scalars['Int']
  /** The training policies permission */
  trainingPolicies: Scalars['Int']
  /** The training purchases permission */
  trainingPurchases: Scalars['Int']
  /** The training reports permission */
  trainingReports: Scalars['Int']
  /** The training templates permission */
  trainingTemplates: Scalars['Int']
  /** The training uploaded content permission */
  trainingUploadedContent: Scalars['Int']
  /** The USB campaigns permission */
  usbCampaigns: Scalars['Int']
  /** The users permission */
  users: Scalars['Int']
}

/** A Security Role permission */
export type SecurityRolePermissionScope = Identifier & {
  __typename?: 'SecurityRolePermissionScope'
  /** The groups associated with the specific permission scope */
  groups: Array<Group>
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The specific permission related to a Security Role */
  permission: SecurityRoleScopeables
}

export type SecurityRoleScopeAttributes = {
  /** The IDs of the groups to assign the Security Role to */
  groups: Array<Scalars['Int']>
  /** The permission to assign to the Security Role */
  permission: SecurityRoleScopeables
}

export enum SecurityRoleScopeables {
  AdvancedReporting = 'ADVANCED_REPORTING',
  ExecutiveReports = 'EXECUTIVE_REPORTS',
  PhishingCampaigns = 'PHISHING_CAMPAIGNS',
  PhishingReports = 'PHISHING_REPORTS',
  TrainingCampaigns = 'TRAINING_CAMPAIGNS',
  TrainingReports = 'TRAINING_REPORTS',
  Users = 'USERS',
}

/** Autogenerated return type of SecurityRolesCreate. */
export type SecurityRolesCreatePayload = {
  __typename?: 'SecurityRolesCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created Security Role */
  node?: Maybe<SecurityRole>
}

export type SecurityRolesCursor = {
  __typename?: 'SecurityRolesCursor'
  /** The paginated nodes */
  nodes: Array<SecurityRole>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of SecurityRolesDelete. */
export type SecurityRolesDeletePayload = {
  __typename?: 'SecurityRolesDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The deleted Security Role */
  node?: Maybe<SecurityRole>
}

/** Autogenerated return type of SecurityRolesUpdate. */
export type SecurityRolesUpdatePayload = {
  __typename?: 'SecurityRolesUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated Security Role */
  node?: Maybe<SecurityRole>
}

export type SelectOption = {
  __typename?: 'SelectOption'
  /** The unique identifier of the select option */
  id: Scalars['ID']
  /** The text displayed for the select option */
  text: Scalars['String']
  /** The code name of the vendor associated with this option, if applicable */
  vendorCodeName?: Maybe<Scalars['String']>
}

/** SecurityCoach setup status type */
export type SetupStatus = {
  __typename?: 'SetupStatus'
  /** The creation date and time of the setup status */
  createdAt: Scalars['ISO8601DateTime']
  /** Indicates if a delivery method has been added */
  deliveryMethodAdded: Scalars['Boolean']
  /** Indicates if detection rules have been reviewed */
  detectionRulesReviewed: Scalars['Boolean']
  /** The unique identifier of the setup status */
  id: Scalars['String']
  /** Indicates if an RTC campaign has been created */
  rtcCampaignCreated: Scalars['Boolean']
  /** The last update date and time of the setup status */
  updatedAt: Scalars['ISO8601DateTime']
  /** Indicates if a vendor has been integrated */
  vendorIntegrated: Scalars['Boolean']
}

export enum SetupStatusActions {
  DeliveryMethodAdded = 'delivery_method_added',
  DetectionRulesReviewed = 'detection_rules_reviewed',
  RtcCampaignCreated = 'rtc_campaign_created',
  VendorIntegrated = 'vendor_integrated',
}

/** Autogenerated return type of SignInAsAccountAdmin. */
export type SignInAsAccountAdminPayload = {
  __typename?: 'SignInAsAccountAdminPayload'
  errors?: Maybe<Array<Error>>
  node: Scalars['Boolean']
}

/** Autogenerated return type of SignInAsAccount. */
export type SignInAsAccountPayload = {
  __typename?: 'SignInAsAccountPayload'
  errors?: Maybe<Array<Error>>
  node: Scalars['Boolean']
}

/** Autogenerated return type of SignInAsAccountUser. */
export type SignInAsAccountUserPayload = {
  __typename?: 'SignInAsAccountUserPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['String']>
}

/** Autogenerated return type of SignInAsChildPartner. */
export type SignInAsChildPartnerPayload = {
  __typename?: 'SignInAsChildPartnerPayload'
  errors?: Maybe<Array<Error>>
  node: Scalars['Boolean']
}

/** Autogenerated return type of SignInAsPartnerAdmin. */
export type SignInAsPartnerAdminPayload = {
  __typename?: 'SignInAsPartnerAdminPayload'
  errors?: Maybe<Array<Error>>
  node: Scalars['Boolean']
}

/** Current site admin account */
export type SiteAdmin = Timestamps & {
  __typename?: 'SiteAdmin'
  /** The timestamp when the site admin confirmed their account */
  confirmedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The timestamp when the record was created */
  createdAt: Scalars['ISO8601DateTime']
  /** The timestamp of the current sign-in */
  currentSignInAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Default landing page for previewing templates with QR codes */
  defaultLandingPage: LandingPage
  /** Indicates if the site admin account is disabled */
  disabled: Scalars['Boolean']
  /** The email address of the site admin */
  email: Scalars['String']
  /** The first name of the site admin */
  firstName?: Maybe<Scalars['String']>
  /** Indicates if the site admin has full administrative permissions */
  fullAdmin: Scalars['Boolean']
  /** The ID of a site admin */
  id: Scalars['Int']
  /** The last name of the site admin */
  lastName?: Maybe<Scalars['String']>
  /** Indicates if MFA is enabled for the site admin */
  mfaEnabled?: Maybe<Scalars['Boolean']>
  /** One-time password code for the site admin */
  otpCode: Scalars['String']
  /** Secret key used for generating one-time password for the site admin */
  otpSecretKey: Scalars['String']
  /** QR code image data associated with the site admin */
  qrCodeImageData: Scalars['String']
  /** The duration of the site admin session before timeout */
  sessionTimeout: Scalars['Int']
  /** Permissions assigned to the site admin */
  siteAdminPermission?: Maybe<SiteAdminPermission>
  /** The timezone of the site admin */
  timeZone?: Maybe<TimeZones>
  /** The timestamp when the record was last updated */
  updatedAt: Scalars['ISO8601DateTime']
}

export type SiteAdminAttributes = {
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  fullAdmin?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  mfaEnabled?: Maybe<Scalars['Boolean']>
  password?: Maybe<Scalars['String']>
  passwordConfirmation?: Maybe<Scalars['String']>
  sessionTimeout?: Maybe<Scalars['Int']>
  siteAdminPermission?: Maybe<SiteAdminPermissionAttributes>
  timeZone?: Maybe<TimeZones>
}

/** Permissions for a site admin */
export type SiteAdminPermission = Timestamps & {
  __typename?: 'SiteAdminPermission'
  /** Number of accounts */
  accounts: Scalars['Int']
  /** Number of callback phishing phone numbers */
  callbackPhishingPhoneNumbers: Scalars['Int']
  /** Number of centralized detection rules */
  centralizedDetectionRules: Scalars['Int']
  /** Number of clickwrap agreements */
  clickwrap: Scalars['Int']
  /** Number of courses */
  courses: Scalars['Int']
  /** The timestamp when the record was created */
  createdAt: Scalars['ISO8601DateTime']
  /** Number of demo seeds */
  demoSeeds: Scalars['Int']
  /** Number of domains */
  domains: Scalars['Int']
  /** Number of email templates */
  emailTemplates: Scalars['Int']
  /** ID */
  id: Scalars['Int']
  /** Number of IP ranges */
  ipRanges: Scalars['Int']
  /** Number of ModStore items */
  modStore: Scalars['Int']
  /** Number of online stores */
  ols: Scalars['Int']
  /** Number of partners */
  partners: Scalars['Int']
  /** Number of privileged ModStore items */
  privilegedModstore: Scalars['Int']
  /** Number of Second Chance options */
  secondChance: Scalars['Int']
  /** Number of subscriptions */
  subscriptions: Scalars['Int']
  /** Number of trademarks */
  trademarks: Scalars['Int']
  /** The timestamp when the record was last updated */
  updatedAt: Scalars['ISO8601DateTime']
}

export type SiteAdminPermissionAttributes = {
  accounts?: Maybe<Scalars['Int']>
  callbackPhishingPhoneNumbers?: Maybe<Scalars['Int']>
  centralizedDetectionRules?: Maybe<Scalars['Int']>
  clickwrap?: Maybe<Scalars['Int']>
  courses?: Maybe<Scalars['Int']>
  demoSeeds?: Maybe<Scalars['Int']>
  domains?: Maybe<Scalars['Int']>
  emailTemplates?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
  ipRanges?: Maybe<Scalars['Int']>
  modStore?: Maybe<Scalars['Int']>
  ols?: Maybe<Scalars['Int']>
  partners?: Maybe<Scalars['Int']>
  privilegedModstore?: Maybe<Scalars['Int']>
  secondChance?: Maybe<Scalars['Int']>
  subscriptions?: Maybe<Scalars['Int']>
  trademarks?: Maybe<Scalars['Int']>
}

/** Autogenerated return type of SiteAdminsCreate. */
export type SiteAdminsCreatePayload = {
  __typename?: 'SiteAdminsCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<SiteAdmin>
}

export type SiteAdminsCursor = {
  __typename?: 'SiteAdminsCursor'
  /** The paginated nodes */
  nodes: Array<SiteAdmin>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of SiteAdminsDeactivateMfa. */
export type SiteAdminsDeactivateMfaPayload = {
  __typename?: 'SiteAdminsDeactivateMfaPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<SiteAdmin>
}

/** Autogenerated return type of SiteAdminsDelete. */
export type SiteAdminsDeletePayload = {
  __typename?: 'SiteAdminsDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<SiteAdmin>
}

/** Autogenerated return type of SiteAdminsDisableMfa. */
export type SiteAdminsDisableMfaPayload = {
  __typename?: 'SiteAdminsDisableMfaPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<SiteAdmin>
}

/** Autogenerated return type of SiteAdminsDisable. */
export type SiteAdminsDisablePayload = {
  __typename?: 'SiteAdminsDisablePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<SiteAdmin>
}

/** Autogenerated return type of SiteAdminsEnableMfa. */
export type SiteAdminsEnableMfaPayload = {
  __typename?: 'SiteAdminsEnableMfaPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<SiteAdmin>
}

export enum SiteAdminsSortFields {
  ConfirmedAt = 'CONFIRMED_AT',
  CurrentSignInAt = 'CURRENT_SIGN_IN_AT',
  Email = 'EMAIL',
  FullAdmin = 'FULL_ADMIN',
  FullName = 'FULL_NAME',
  MfaEnabled = 'MFA_ENABLED',
}

/** Autogenerated return type of SiteAdminsToggleFullAdmin. */
export type SiteAdminsToggleFullAdminPayload = {
  __typename?: 'SiteAdminsToggleFullAdminPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<SiteAdmin>
}

/** Autogenerated return type of SiteAdminsUpdate. */
export type SiteAdminsUpdatePayload = {
  __typename?: 'SiteAdminsUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<SiteAdmin>
}

/** Autogenerated return type of SiteAdminsUpdateSelf. */
export type SiteAdminsUpdateSelfPayload = {
  __typename?: 'SiteAdminsUpdateSelfPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<SiteAdmin>
}

/** The site settings */
export type SiteSettings = Timestamps & {
  __typename?: 'SiteSettings'
  /** The timestamp when the record was created */
  createdAt: Scalars['ISO8601DateTime']
  /** Disables logins through methods other than SAML */
  disableNonSamlLogins?: Maybe<Scalars['Boolean']>
  /** Ensure session persists from the same IP address */
  ensureSameSessionIp?: Maybe<Scalars['Boolean']>
  /** Fingerprint of the identity provider's certification */
  idpCertFingerprint?: Maybe<Scalars['String']>
  /** Format of the identity provider's certificate */
  idpCertFormat?: Maybe<IdpCertificationFormats>
  /** URL to initiate SSO authentication with the identity provider */
  idpSsoTargetUrl?: Maybe<Scalars['String']>
  /** Comma-separated list of IPs allowed for console access */
  ipAllowList?: Maybe<Scalars['String']>
  /** Restricts the console session to predefined IP addresses */
  restrictConsoleSession: Scalars['Boolean']
  /** Indicates if SAML authentication is enabled */
  samlEnabled?: Maybe<Scalars['Boolean']>
  /** Denotes if SAML requests should be signed */
  signSamlRequests?: Maybe<Scalars['Boolean']>
  /** URL endpoint for SSO callback responses */
  ssoCallback?: Maybe<Scalars['String']>
  /** Entity ID for SSO */
  ssoEntity?: Maybe<Scalars['String']>
  /** URL endpoint to initiate an SSO session */
  ssoIn?: Maybe<Scalars['String']>
  /** Metadata information for SSO configuration */
  ssoMetadata?: Maybe<Scalars['String']>
  /** URL endpoint to terminate an SSO session */
  ssoOut?: Maybe<Scalars['String']>
  /** The timestamp when the record was last updated */
  updatedAt: Scalars['ISO8601DateTime']
}

export type SiteSettingsAttributes = {
  disableNonSamlLogins?: Maybe<Scalars['Boolean']>
  ensureSameSessionIp?: Maybe<Scalars['Boolean']>
  idpCertFingerprint?: Maybe<Scalars['String']>
  idpCertFormat?: Maybe<IdpCertificationFormats>
  idpSsoTargetUrl?: Maybe<Scalars['String']>
  ipAllowList?: Maybe<Scalars['String']>
  restrictConsoleSession?: Maybe<Scalars['Boolean']>
  samlEnabled?: Maybe<Scalars['Boolean']>
  signSamlRequests?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SiteSettingsUpdate. */
export type SiteSettingsUpdatePayload = {
  __typename?: 'SiteSettingsUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<SiteSettings>
}

/** Smart host from PSM */
export type SmartHost = {
  __typename?: 'SmartHost'
  auditLogDisplay?: Maybe<Scalars['String']>
  host: Scalars['String']
  id: Scalars['Int']
  password?: Maybe<Scalars['String']>
  port: Scalars['Int']
  priority: Scalars['Int']
  requireTLS?: Maybe<Scalars['Boolean']>
  username?: Maybe<Scalars['String']>
}

export type SmartHostAttributes = {
  host: Scalars['String']
  password?: Maybe<Scalars['String']>
  port: Scalars['Int']
  priority?: Maybe<Scalars['Int']>
  requireTLS?: Maybe<Scalars['Boolean']>
  username?: Maybe<Scalars['String']>
}

/** Smart host domain from PSM */
export type SmartHostDomain = {
  __typename?: 'SmartHostDomain'
  domain: Scalars['String']
  smartHosts: Array<SmartHost>
}

export type SmartHostDomainAttributes = {
  domain: Scalars['String']
  smartHosts: Array<SmartHostAttributes>
}

export type SmartHostDomainsCursor = {
  __typename?: 'SmartHostDomainsCursor'
  /** The paginated nodes */
  nodes: Array<SmartHostDomain>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of SmartHostDomainsDelete. */
export type SmartHostDomainsDeletePayload = {
  __typename?: 'SmartHostDomainsDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SmartHostDomainsSendTest. */
export type SmartHostDomainsSendTestPayload = {
  __typename?: 'SmartHostDomainsSendTestPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SmartHostDomainsUpdate. */
export type SmartHostDomainsUpdatePayload = {
  __typename?: 'SmartHostDomainsUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Account>
}

export type SmartHostMultiInstanceAccountsCursor = {
  __typename?: 'SmartHostMultiInstanceAccountsCursor'
  /** The paginated nodes */
  nodes: Array<Account>
  /** Information about the current paginated set */
  pagination: Pagination
}

export type SnapshotReference = {
  __typename?: 'SnapshotReference'
  /** The unique identifier of the snapshot reference */
  id: Scalars['Int']
  /** The name of the snapshot reference */
  name?: Maybe<Scalars['String']>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type SocialMediaLink = {
  __typename?: 'SocialMediaLink'
  /** The creation time of the social media link */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The icon representing the social media platform */
  icon?: Maybe<Scalars['String']>
  /** The ID of the social media link */
  id?: Maybe<Scalars['Int']>
  /** The text description of the social media link */
  linkText?: Maybe<Scalars['String']>
  /** The URL of the social media link */
  linkUrl?: Maybe<Scalars['String']>
  /** The social media platform of the link */
  platform?: Maybe<Scalars['String']>
  /** The last update time of the social media link */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>
}

export type SocialMediaLinkAttributes = {
  icon?: Maybe<Scalars['String']>
  linkText: Scalars['String']
  linkUrl: Scalars['String']
  platform: SocialMediaPlatforms
}

export enum SocialMediaPlatforms {
  Facebook = 'facebook',
  Instagram = 'instagram',
  Linkedin = 'linkedin',
  Pinterest = 'pinterest',
  Slack = 'slack',
  Snapchat = 'snapchat',
  Twitter = 'twitter',
  Youtube = 'youtube',
}

export enum SortDirections {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export enum SortFields {
  AdPwClearText = 'AD_PW_CLEAR_TEXT',
  AdPwEmpty = 'AD_PW_EMPTY',
  AdPwFoundInBreach = 'AD_PW_FOUND_IN_BREACH',
  AdPwNeverExpires = 'AD_PW_NEVER_EXPIRES',
  AdPwNotReqd = 'AD_PW_NOT_REQD',
  AdPwShared = 'AD_PW_SHARED',
  AdPwWeak = 'AD_PW_WEAK',
  AdUserAesEncryptionNotSet = 'AD_USER_AES_ENCRYPTION_NOT_SET',
  AdUserDesOnlyEncryption = 'AD_USER_DES_ONLY_ENCRYPTION',
  AdUserHasPreauthentication = 'AD_USER_HAS_PREAUTHENTICATION',
  AdUserUsesLmHash = 'AD_USER_USES_LM_HASH',
  Email = 'EMAIL',
}

/** Message run statistics */
export type Statistics = {
  __typename?: 'Statistics'
  /** The percentage of messages read among all messages sent */
  percentageRead?: Maybe<Scalars['Int']>
  /** The total number of active recipients */
  totalActiveRecipients?: Maybe<Scalars['Int']>
  /** The total number of messages that have been read */
  totalRead?: Maybe<Scalars['Int']>
}

/** User Event API usage stats */
export type Stats = {
  __typename?: 'Stats'
  /** The annual quota for the User Event API */
  annualQuota: Scalars['Int']
  /** An array representing the usage statistics */
  usage: Array<Scalars['Int']>
}

/** User Event API call status */
export type Status = {
  __typename?: 'Status'
  /** The API key associated with the status, if applicable */
  apiKey?: Maybe<Scalars['String']>
  /** Detailed information about the status */
  details: StatusDetails
  /** Unique identifier of the status */
  id: Scalars['String']
  /** The timestamp when the status was processed */
  processedAt: Scalars['ISO8601DateTime']
}

/** User Event API call status details */
export type StatusDetails = {
  __typename?: 'StatusDetails'
  /** The events that have occurred */
  events: Array<Scalars['String']>
  /** The failures that have occurred */
  failures: Array<StatusError>
}

/** User Event API status error */
export type StatusError = {
  __typename?: 'StatusError'
  /** A JSON structure containing details about the error */
  errors: Scalars['JSON']
  /** An optional JSON structure containing additional information about the error */
  payload?: Maybe<Scalars['JSON']>
}

/** User Event API call status */
export type Statuses = {
  __typename?: 'Statuses'
  /** Pagination details for the list of statuses */
  pagination: Pagination
  /** A list of statuses for the User Event API */
  statuses: Array<Status>
}

/** ModStore item */
export type StoreItem = StoreItemInterface & {
  __typename?: 'StoreItem'
  /** The access level of the store item */
  accessLevel: Scalars['String']
  /** Alternative versions of the store item */
  alternates: Array<StoreItem>
  /** Artwork associated with the store item */
  artwork: Artwork
  /** Options related to the content of the store item */
  contentOptions?: Maybe<Array<AssetProperty>>
  /** Indicates if the content of the item is themable */
  contentThemable: Scalars['Boolean']
  /** The content type of the store item */
  contentType?: Maybe<Content>
  /** Cover image of the store item */
  coverImage?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  expectedDurationInMinutes?: Maybe<Scalars['Int']>
  /** The expiration date and time of the item */
  expiresAt?: Maybe<Scalars['ISO8601DateTime']>
  /** A message related to the expiry of the item */
  expiryMessage?: Maybe<Scalars['String']>
  id: Scalars['Int']
  /** Indicates if the item is part of CMP */
  isCmp?: Maybe<Scalars['Boolean']>
  /** JWT associated with the store item */
  jwt?: Maybe<Scalars['String']>
  /** Number of translations available for the store item */
  numTranslations?: Maybe<Scalars['Int']>
  /** The publication date and time of the item */
  publishedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The publisher of the store item */
  publisher?: Maybe<Publisher>
  /** The status of the store item */
  status: Scalars['String']
  /** The subscription level required to access the item */
  subscriptionLevel?: Maybe<Scalars['String']>
  /** Subscriptions associated with the store item */
  subscriptions?: Maybe<Array<Subscription>>
  /** Indicates if the item is suitable for downloading to mobile devices */
  suitableForMobileDownload: Scalars['Boolean']
  title: Scalars['String']
  /** Topics related to the store item */
  topics?: Maybe<Array<Topic>>
  translations: Array<Translation>
  /** The type of store item */
  type: Scalars['String']
  uuid: Scalars['String']
}

/** Common fields between CCM and ModStore store items */
export type StoreItemInterface = {
  artwork: Artwork
  description?: Maybe<Scalars['String']>
  expectedDurationInMinutes?: Maybe<Scalars['Int']>
  id: Scalars['Int']
  publishedAt?: Maybe<Scalars['ISO8601DateTime']>
  title: Scalars['String']
  translations: Array<Translation>
  type: Scalars['String']
  uuid: Scalars['String']
}

export enum StoreItemSource {
  Ccm = 'CCM',
  Kmsat = 'KMSAT',
  Modstore = 'MODSTORE',
}

export enum StoreItemTypes {
  Assessment = 'ASSESSMENT',
  CoachingContent = 'COACHING_CONTENT',
  Course = 'COURSE',
  Game = 'GAME',
  Image = 'IMAGE',
  MobileFirst = 'MOBILE_FIRST',
  Newsletter = 'NEWSLETTER',
  ScormVideo = 'SCORM_VIDEO',
  Video = 'VIDEO',
}

/** An enrollment item */
export type StoreItemUnion = CcmItem | StoreItem

/** Store purchase */
export type StorePurchase = Identifier &
  Timestamps & {
    __typename?: 'StorePurchase'
    auditLogDisplay?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The number of enrollments for the purchased course */
    enrollmentCount: Scalars['Int']
    /** Indicates if there are any associated soft-deleted training campaigns */
    hasAssociatedSoftDeletedTrainingCampaigns?: Maybe<Scalars['Boolean']>
    /** Indicates if the purchase is hidden */
    hidden?: Maybe<Scalars['Boolean']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The location of the policy for the purchase */
    policyLocation?: Maybe<Scalars['String']>
    /** Indicates if a policy is required for the purchase */
    policyRequired?: Maybe<Scalars['Boolean']>
    /** The date and time when the item was purchased */
    purchasedAt?: Maybe<Scalars['ISO8601Date']>
    /** The options for content that was purchased */
    purchasedContentOptions?: Maybe<PurchasedContentOptions>
    /** The course that was purchased (deprecated) */
    purchasedCourse?: Maybe<PurchasedCourse>
    /** The ID of the purchased course */
    purchasedCourseId?: Maybe<Scalars['Int']>
    /** The item that was purchased in the store */
    storeItem?: Maybe<StoreItemUnion>
    /** The UUID of the store item */
    storeItemUuid?: Maybe<Scalars['String']>
    /** The training campaigns associated with the purchase */
    trainingCampaigns?: Maybe<Array<TrainingCampaign>>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type StorePurchaseAttributes = {
  /** The hidden status of the store purchase */
  hidden?: Maybe<Scalars['Boolean']>
  /** The policy location of the store purchase */
  policyLocation?: Maybe<Scalars['String']>
  /** The policy required status of the store purchase */
  policyRequired?: Maybe<Scalars['Boolean']>
  /** The purchased content options of the store purchase */
  purchasedContentOptionsAttributes?: Maybe<PurchasedContentOptionsAttributes>
}

/** Common fields between purchased courses and managed store purchases */
export type StorePurchaseInterface = {
  /** The alternatives for the purchased course */
  alternatives: Array<CourseAlternative>
  /** Indicates if the purchased course is archived */
  archived?: Maybe<Scalars['Boolean']>
  /** Indicates if the purchased course is an assessment */
  assessment?: Maybe<Scalars['Boolean']>
  /** The type of assessment associated with the purchased course */
  assessmentType?: Maybe<AssessmentTypes>
  /** The type of asset associated with the purchased course */
  assetType: Scalars['String']
  /** The description of the purchased course */
  description?: Maybe<Scalars['String']>
  /** The duration of the purchased course */
  duration?: Maybe<Scalars['Int']>
  /** The published date of the purchased course */
  publishedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Indicates if the purchased course is retired */
  retired?: Maybe<Scalars['Boolean']>
  /** Indicates if the purchased course is retiring soon */
  retiringSoon?: Maybe<Scalars['Boolean']>
  /** The title of the purchased course */
  title: Scalars['String']
  /** Indicates if the purchased course is uploaded */
  uploaded?: Maybe<Scalars['Boolean']>
}

export enum StorePurchaseSortFields {
  ContentTitle = 'CONTENT_TITLE',
  DateAdded = 'DATE_ADDED',
  Duration = 'DURATION',
  Id = 'ID',
}

/** Autogenerated return type of StorePurchasesCreate. */
export type StorePurchasesCreatePayload = {
  __typename?: 'StorePurchasesCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The store purchase that was created */
  node?: Maybe<StorePurchase>
}

export type StorePurchasesCursor = {
  __typename?: 'StorePurchasesCursor'
  /** The paginated nodes */
  nodes: Array<StorePurchase>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of StorePurchasesDownload. */
export type StorePurchasesDownloadPayload = {
  __typename?: 'StorePurchasesDownloadPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of StorePurchasesRemove. */
export type StorePurchasesRemovePayload = {
  __typename?: 'StorePurchasesRemovePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The store purchase that was removed */
  node?: Maybe<StorePurchase>
}

/** Autogenerated return type of StorePurchasesUpdate. */
export type StorePurchasesUpdatePayload = {
  __typename?: 'StorePurchasesUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The store purchase that was updated */
  node?: Maybe<StorePurchase>
}

/** A subscription */
export type Subscription = Identifier &
  Timestamps & {
    __typename?: 'Subscription'
    /** Indicates whether the subscription is currently active */
    active: Scalars['Boolean']
    /** Indicates if AIDA recommended training is enabled for this subscription */
    aidaRecommendedTrainingEnabled?: Maybe<Scalars['Boolean']>
    /** Indicates if AIDA selected is enabled for this subscription */
    aidaSelectedEnabled?: Maybe<Scalars['Boolean']>
    /** Indicates if audit logging is enabled for this subscription */
    auditLogEnabled: Scalars['Boolean']
    /** Indicates if callback phishing prevention is enabled for this subscription */
    callbackPhishingEnabled: Scalars['Boolean']
    /** The unique code identifying the subscription */
    code: Scalars['String']
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Indicates if EEC protection is enabled */
    eecEnabled?: Maybe<Scalars['Boolean']>
    /** A user-friendly name for the subscription */
    friendlyName?: Maybe<Scalars['String']>
    /** Indicates if the subscription has access to the Rest API */
    hasApi?: Maybe<Scalars['Boolean']>
    /** Indicates if the subscription has access to the GraphAPI */
    hasApiv2?: Maybe<Scalars['Boolean']>
    /** Indicates if the subscription includes PasswordIQ features */
    hasPasswordIq?: Maybe<Scalars['Boolean']>
    /** Indicates if the subscription includes custom permission settings */
    hasPermissions: Scalars['Boolean']
    /** Indicates if the subscription includes Second Chance features */
    hasSecondChance: Scalars['Boolean']
    /** Indicates if the subscription has access to the User Event API */
    hasUserEventApi: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The number of accounts associated with this subscription */
    numAccounts: Scalars['Int']
    /** Indicates whether the subscription is a paid plan */
    paid: Scalars['Boolean']
    /** Indicates if Phish Alert is enabled for this subscription */
    phishAlertEnabled: Scalars['Boolean']
    /** Indicates if phishing simulations are enabled for this subscription */
    phishingEnabled?: Maybe<Scalars['Boolean']>
    /** Indicates if physical QR code based training tools are enabled */
    physicalQrEnabled?: Maybe<Scalars['Boolean']>
    /** Indicates if SEI is enabled for this subscription */
    seiEnabled: Scalars['Boolean']
    /** Indicates if Smart Groups are enabled for user segmentation and training delivery */
    smartGroupsEnabled: Scalars['Boolean']
    /** The subscription level or tier */
    subscriptionLevel: Scalars['String']
    /** Indicates if security awareness training is enabled for this subscription */
    trainingEnabled?: Maybe<Scalars['Boolean']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** Indicates if USB-based attack simulations are enabled for this subscription */
    usbEnabled?: Maybe<Scalars['Boolean']>
  }

export type SubscriptionAttributes = {
  active?: Maybe<Scalars['Boolean']>
  aidaRecommendedTrainingEnabled?: Maybe<Scalars['Boolean']>
  aidaSelectedEnabled?: Maybe<Scalars['Boolean']>
  auditLogEnabled?: Maybe<Scalars['Boolean']>
  callbackPhishingEnabled?: Maybe<Scalars['Boolean']>
  eecEnabled?: Maybe<Scalars['Boolean']>
  friendlyName?: Maybe<Scalars['String']>
  hasApi?: Maybe<Scalars['Boolean']>
  hasApiv2?: Maybe<Scalars['Boolean']>
  hasPasswordIq?: Maybe<Scalars['Boolean']>
  hasPermissions?: Maybe<Scalars['Boolean']>
  hasSecondChance?: Maybe<Scalars['Boolean']>
  hasUserEventApi?: Maybe<Scalars['Boolean']>
  paid?: Maybe<Scalars['Boolean']>
  phishAlertEnabled?: Maybe<Scalars['Boolean']>
  phishingEnabled?: Maybe<Scalars['Boolean']>
  physicalQrEnabled?: Maybe<Scalars['Boolean']>
  seiEnabled?: Maybe<Scalars['Boolean']>
  smartGroupsEnabled?: Maybe<Scalars['Boolean']>
  subscriptionLevel?: Maybe<Scalars['String']>
  trainingEnabled?: Maybe<Scalars['Boolean']>
  usbEnabled?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of SubscriptionsCreate. */
export type SubscriptionsCreatePayload = {
  __typename?: 'SubscriptionsCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Subscription>
}

export type SubscriptionsCursor = {
  __typename?: 'SubscriptionsCursor'
  /** The paginated nodes */
  nodes: Array<Subscription>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of SubscriptionsDelete. */
export type SubscriptionsDeletePayload = {
  __typename?: 'SubscriptionsDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Subscription>
}

/** Autogenerated return type of SubscriptionsUpdate. */
export type SubscriptionsUpdatePayload = {
  __typename?: 'SubscriptionsUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Subscription>
}

/** Enrollment survey */
export type Survey = Identifier &
  Timestamps & {
    __typename?: 'Survey'
    /** Any comments provided by the participant about the survey */
    comments?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Participant rating of the survey length */
    lengthRating?: Maybe<Scalars['Int']>
    /** Participant rating of the survey style */
    styleRating?: Maybe<Scalars['Int']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** Participant rating of the survey value */
    valueRating?: Maybe<Scalars['Int']>
  }

export type SurveyAttributes = {
  comments?: Maybe<Scalars['String']>
  lengthRating?: Maybe<Scalars['Int']>
  styleRating?: Maybe<Scalars['Int']>
  valueRating?: Maybe<Scalars['Int']>
}

export enum SurveyDimensions {
  Attitudes = 'ATTITUDES',
  Behavior = 'BEHAVIOR',
  Cognition = 'COGNITION',
  Communication = 'COMMUNICATION',
  Compliance = 'COMPLIANCE',
  Norms = 'NORMS',
  Responsibility = 'RESPONSIBILITY',
}

/** Autogenerated return type of SurveysCreate. */
export type SurveysCreatePayload = {
  __typename?: 'SurveysCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Survey>
}

export type SystemCustomNotification =
  | CustomNotification
  | SecurityCoachNotification

export type TeachableMomentCategoriesPayload = {
  __typename?: 'TeachableMomentCategoriesPayload'
  /** The total number of teachable moment categories */
  count: Scalars['Int']
  /** The page number for the next page of results */
  next: Scalars['Int']
  /** The number of categories to return per page */
  pageSize: Scalars['Int']
  /** The page number for the previous page of results */
  previous: Scalars['Int']
  /** The list of teachable moment topics */
  results?: Maybe<Array<TeachableMomentTopics>>
  /** The total number of pages available */
  totalPages: Scalars['Int']
}

export type TeachableMomentCriteria = {
  field: Scalars['String']
  operator: Scalars['String']
  value: Array<Scalars['String']>
}

export type TeachableMomentCriteriaPayload = {
  AND?: Maybe<Array<TeachableMomentCriteria>>
  OR?: Maybe<Array<TeachableMomentCriteria>>
}

export type TeachableMomentRule = {
  __typename?: 'TeachableMomentRule'
  /** Configuration details for the teachable moment rule */
  config?: Maybe<TeachableMomentRuleConfig>
  /** Criteria details for triggering the teachable moment rule */
  criteria?: Maybe<TeachableMomentRuleCriteria>
}

export type TeachableMomentRuleConfig = {
  __typename?: 'TeachableMomentRuleConfig'
  /** Indicates if the occurrences should be aggregated */
  aggregate?: Maybe<Scalars['Boolean']>
  /** The total count of occurrences for this rule */
  count?: Maybe<Scalars['Int']>
  /** The number of days to look back for incidents */
  lookbackDays?: Maybe<Scalars['Int']>
}

export type TeachableMomentRuleCriteria = {
  __typename?: 'TeachableMomentRuleCriteria'
  /** A set of criteria that must all be met (logical AND operation) */
  AND?: Maybe<Array<TeachableMomentRuleCriterion>>
  /** A set of criteria where at least one must be met (logical OR operation) */
  OR?: Maybe<Array<TeachableMomentRuleCriterion>>
}

export type TeachableMomentRuleCriterion = {
  __typename?: 'TeachableMomentRuleCriterion'
  /** The specific field to apply the criterion on */
  field: Scalars['String']
  /** The operation to be performed on the field */
  operator: Scalars['String']
  /** The value(s) to be compared against the field using the operator */
  value: Array<Scalars['String']>
}

export type TeachableMomentRules = {
  __typename?: 'TeachableMomentRules'
  /** The categories associated with the teachable moment rule */
  categories?: Maybe<Array<TeachableMomentTopics>>
  description?: Maybe<Scalars['String']>
  /** Indicates if the teachable moment rule is enabled */
  enabled?: Maybe<Scalars['Boolean']>
  /** The unique identifier of the teachable moment rule */
  id?: Maybe<Scalars['String']>
  /** The last modification date of the teachable moment rule */
  modified: Scalars['ISO8601DateTime']
  /** The name of the teachable moment rule */
  name?: Maybe<Scalars['String']>
  /** The risk level of the teachable moment rule */
  riskLevel?: Maybe<TmrRiskLevels>
  /** The associated rule details */
  rule?: Maybe<TeachableMomentRule>
  /** The severity level of the teachable moment rule */
  severity?: Maybe<Scalars['String']>
  /** The stage of the teachable moment rule */
  stage?: Maybe<Scalars['String']>
  /** The current status of the teachable moment rule */
  status?: Maybe<Scalars['String']>
  /** The system workflow associated with the teachable moment rule */
  systemWorkflow?: Maybe<Scalars['String']>
  /** The type of teachable moment rule */
  tmrType?: Maybe<Tmr>
  /** The code name of the vendor for the teachable moment rule */
  vendorCodeName?: Maybe<Scalars['String']>
}

export type TeachableMomentRulesPayload = {
  __typename?: 'TeachableMomentRulesPayload'
  /** The total number of Teachable Moment Rules available */
  count: Scalars['Int']
  /** The page number of the next page of Teachable Moment Rules */
  next: Scalars['Int']
  /** The number of Teachable Moment Rules per page */
  pageSize: Scalars['Int']
  /** The page number of the previous page of Teachable Moment Rules */
  previous: Scalars['Int']
  /** The list of Teachable Moment Rules on the current page */
  results?: Maybe<Array<TeachableMomentRules>>
  /** The total number of pages available */
  totalPages: Scalars['Int']
}

export type TeachableMomentTopics = {
  __typename?: 'TeachableMomentTopics'
  /** The name of the teachable moment topic */
  name: Scalars['String']
  /** The unique slug for the teachable moment topic */
  slug: Scalars['String']
}

export type Team = {
  __typename?: 'Team'
  /** The unique identifier of the team */
  id?: Maybe<Scalars['String']>
  /** The timestamp of the last synchronization */
  lastSync?: Maybe<Scalars['String']>
  /** The name of the team */
  name?: Maybe<Scalars['String']>
  /** The current synchronization status of the team */
  syncStatus?: Maybe<Scalars['String']>
  /** The total number of users in the team */
  totalUserCount?: Maybe<Scalars['Int']>
  /** The total number of users in the team that are also in Microsoft Teams */
  totalUserCountInMicrosoftTeams?: Maybe<Scalars['Int']>
}

export type TeamAndId = {
  __typename?: 'TeamAndId'
  /** The display name of the team */
  displayName?: Maybe<Scalars['String']>
  /** The unique identifier of the team */
  id?: Maybe<Scalars['String']>
}

export type Teams = {
  __typename?: 'Teams'
  /** Indicates whether the operation was successful */
  ok?: Maybe<Scalars['Boolean']>
  /** A list of teams including their IDs */
  teams?: Maybe<Array<TeamAndId>>
}

export type Template = {
  __typename?: 'Template'
  attachmentName?: Maybe<Scalars['String']>
  contentHtml?: Maybe<Scalars['String']>
  subject?: Maybe<Scalars['String']>
  templateId?: Maybe<Scalars['String']>
}

export type TemplateLanguagesCursor = {
  __typename?: 'TemplateLanguagesCursor'
  /** The paginated nodes */
  nodes: Array<Language>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Shallow mirror of centralized topics for email t88emplates */
export type TemplateTopic = Identifier &
  Timestamps & {
    __typename?: 'TemplateTopic'
    auditLogDisplay?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Whether the template topic is disabled by default */
    disabledByDefault: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    modstoreId?: Maybe<Scalars['Int']>
    /** Name of the template topic */
    name: Scalars['String']
    /** The type of the template topic */
    topicType: TemplateTopicTypes
    translationKey?: Maybe<Scalars['String']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type TemplateTopicAttributes = {
  /** The name of the template topic */
  name: Scalars['String']
}

export enum TemplateTopicTypes {
  CallbackPhishing = 'CALLBACK_PHISHING',
  CallbackPhishingGreeting = 'CALLBACK_PHISHING_GREETING',
  CallbackPhishingGreetingSystem = 'CALLBACK_PHISHING_GREETING_SYSTEM',
  CallbackPhishingResponse = 'CALLBACK_PHISHING_RESPONSE',
  CallbackPhishingResponseSystem = 'CALLBACK_PHISHING_RESPONSE_SYSTEM',
  CallbackPhishingSystem = 'CALLBACK_PHISHING_SYSTEM',
  PhishingCommunity = 'PHISHING_COMMUNITY',
  PhishingManaged = 'PHISHING_MANAGED',
  PhishingSystem = 'PHISHING_SYSTEM',
  PhishingUser = 'PHISHING_USER',
  PhysicalQr = 'PHYSICAL_QR',
  PhysicalQrSystem = 'PHYSICAL_QR_SYSTEM',
  TrainingManaged = 'TRAINING_MANAGED',
  TrainingSystem = 'TRAINING_SYSTEM',
  TrainingUser = 'TRAINING_USER',
}

/** Autogenerated return type of TemplateTopicsCreate. */
export type TemplateTopicsCreatePayload = {
  __typename?: 'TemplateTopicsCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created phishing template topic */
  node?: Maybe<TemplateTopic>
}

export type TemplateTopicsCursor = {
  __typename?: 'TemplateTopicsCursor'
  /** The paginated nodes */
  nodes: Array<TemplateTopic>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of TemplateTopicsDelete. */
export type TemplateTopicsDeletePayload = {
  __typename?: 'TemplateTopicsDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The deleted phishing template topic */
  node?: Maybe<TemplateTopic>
}

/** Autogenerated return type of TemplateTopicsUpdate. */
export type TemplateTopicsUpdatePayload = {
  __typename?: 'TemplateTopicsUpdatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated phishing template topic */
  node?: Maybe<TemplateTopic>
}

export type TestEmail = Identifier & {
  __typename?: 'TestEmail'
  /** The code representing the reason for email bounce */
  bounceCode?: Maybe<Scalars['String']>
  /** The reason for the email bounce described in text */
  bounceReason?: Maybe<Scalars['String']>
  /** The bounce timestamp of the test email */
  bounced?: Maybe<Scalars['ISO8601DateTime']>
  /** The creation timestamp of the test email */
  createdAt: Scalars['ISO8601DateTime']
  /** The delivery timestamp of the test email */
  delivered?: Maybe<Scalars['ISO8601DateTime']>
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The subject of the test email */
  subject?: Maybe<Scalars['String']>
}

/** Autogenerated return type of TestEmailsCreate. */
export type TestEmailsCreatePayload = {
  __typename?: 'TestEmailsCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<TestEmail>
}

export type ThemeSlide = {
  __typename?: 'ThemeSlide'
  /** The time when the slide was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The main heading text of the slide */
  heading: Scalars['String']
  /** The ID of the theme slide */
  id?: Maybe<Scalars['Int']>
  /** The data URL of the slide image for embedding purposes */
  imageDataUrl: Scalars['String']
  /** The URL of the slide image */
  imageUrl: Scalars['String']
  /** The subheading text of the slide */
  subHeading: Scalars['String']
  /** Associated theme translation for the slide */
  themeTranslation: ThemeTranslation
  /** The last time the slide was updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>
}

export type ThemeSlideAttributes = {
  heading: Scalars['String']
  image?: Maybe<Scalars['File']>
  subHeading: Scalars['String']
}

export type ThemeTranslation = {
  __typename?: 'ThemeTranslation'
  /** The time the theme translation was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  /** A collection of document links related to the theme */
  documentLinks?: Maybe<Array<DocumentLink>>
  /** The contact email associated with the theme */
  email?: Maybe<Scalars['String']>
  /** The ID of the theme translation */
  id?: Maybe<Scalars['Int']>
  /** The introductory slide for the theme */
  introSlide?: Maybe<ThemeSlide>
  /** The language code for the theme translation */
  languageCode: Scalars['String']
  /** The concluding slide for the theme */
  outroSlide?: Maybe<ThemeSlide>
  /** The contact phone number associated with the theme */
  phoneNumber?: Maybe<Scalars['String']>
  /** A collection of social media links related to the theme */
  socialMediaLinks?: Maybe<Array<SocialMediaLink>>
  /** The last time the theme translation was updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>
}

export type ThreatCategory = {
  __typename?: 'ThreatCategory'
  /** The properties associated with the threat category */
  properties?: Maybe<ThreatProperties>
  /** The type of the threat category */
  type?: Maybe<Scalars['String']>
}

export type ThreatDistribution = {
  __typename?: 'ThreatDistribution'
  /** The percentage of the threat distribution */
  percentage?: Maybe<Scalars['Float']>
}

export type ThreatProperties = {
  __typename?: 'ThreatProperties'
  /** The count of events associated with the threat */
  eventsCount?: Maybe<Scalars['Int']>
  /** The number of unique users affected by the threat */
  uniqueUsers?: Maybe<Scalars['Int']>
}

export type ThreatRuleDropdown = {
  __typename?: 'ThreatRuleDropdown'
  /** The display name of the threat rule */
  displayName: Scalars['String']
  /** The unique identifier value of the threat rule */
  value: Scalars['String']
}

export type ThreatSeverity = {
  __typename?: 'ThreatSeverity'
  /** The label of the threat severity */
  label?: Maybe<Scalars['String']>
  /** The numerical value representing the threat severity */
  value?: Maybe<Scalars['Float']>
}

export type TimeData = {
  __typename?: 'TimeData'
  /** The X coordinate representing time */
  x: Scalars['BigInt']
  /** The Y coordinate representing data value */
  y: Scalars['Int']
}

export type TimePoint = {
  __typename?: 'TimePoint'
  /** The specific date associated with the time point */
  date?: Maybe<Scalars['ISO8601Date']>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** The numeric value recorded at this time point */
  value?: Maybe<Scalars['Float']>
}

export type TimePointCollection = {
  __typename?: 'TimePointCollection'
  /** Comma-separated values representation of the report data */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** A series of time points representing data over time */
  timeseries: Array<TimePoint>
}

export type TimeSeries = {
  __typename?: 'TimeSeries'
  /** The data points of the time series */
  data: Array<TimePoint>
  /** The name of the time series */
  name: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type TimeSeriesCollection = {
  __typename?: 'TimeSeriesCollection'
  /** CSV format of the time series data */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** A collection of time series data */
  timeseries: Array<TimeSeries>
}

export type TimeSeriesCollectionOverallPppWithCount = {
  __typename?: 'TimeSeriesCollectionOverallPppWithCount'
  /** CSV format of the time series data */
  csv: Scalars['String']
  /** The current Phish-prone Percentage */
  currentPpp: Scalars['Float']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** A collection of time series data */
  timeseries: Array<TimeSeries>
}

export type TimeSeriesCollectionWithOptionalDate = {
  __typename?: 'TimeSeriesCollectionWithOptionalDate'
  /** CSV format of the time series data */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** A collection of time series data */
  timeseries: Array<TimeSeries>
}

export enum TimeZones {
  AbuDhabi = 'ABU_DHABI',
  Adelaide = 'ADELAIDE',
  Alaska = 'ALASKA',
  Almaty = 'ALMATY',
  AmericanSamoa = 'AMERICAN_SAMOA',
  Amsterdam = 'AMSTERDAM',
  Arizona = 'ARIZONA',
  Astana = 'ASTANA',
  Athens = 'ATHENS',
  AtlanticTimeCanada = 'ATLANTIC_TIME_CANADA',
  Auckland = 'AUCKLAND',
  Azores = 'AZORES',
  Baghdad = 'BAGHDAD',
  Baku = 'BAKU',
  Bangkok = 'BANGKOK',
  Beijing = 'BEIJING',
  Belgrade = 'BELGRADE',
  Berlin = 'BERLIN',
  Bern = 'BERN',
  Bogota = 'BOGOTA',
  Brasilia = 'BRASILIA',
  Bratislava = 'BRATISLAVA',
  Brisbane = 'BRISBANE',
  Brussels = 'BRUSSELS',
  Bucharest = 'BUCHAREST',
  Budapest = 'BUDAPEST',
  BuenosAires = 'BUENOS_AIRES',
  Cairo = 'CAIRO',
  Canberra = 'CANBERRA',
  CapeVerdeIs = 'CAPE_VERDE_IS',
  Caracas = 'CARACAS',
  Casablanca = 'CASABLANCA',
  CentralAmerica = 'CENTRAL_AMERICA',
  CentralTimeUsCanada = 'CENTRAL_TIME_US_CANADA',
  ChathamIs = 'CHATHAM_IS',
  Chennai = 'CHENNAI',
  Chihuahua = 'CHIHUAHUA',
  Chongqing = 'CHONGQING',
  Copenhagen = 'COPENHAGEN',
  Darwin = 'DARWIN',
  Dhaka = 'DHAKA',
  Dublin = 'DUBLIN',
  EasternTimeUsCanada = 'EASTERN_TIME_US_CANADA',
  Edinburgh = 'EDINBURGH',
  Ekaterinburg = 'EKATERINBURG',
  Fiji = 'FIJI',
  Georgetown = 'GEORGETOWN',
  Greenland = 'GREENLAND',
  Guadalajara = 'GUADALAJARA',
  Guam = 'GUAM',
  Hanoi = 'HANOI',
  Harare = 'HARARE',
  Hawaii = 'HAWAII',
  Helsinki = 'HELSINKI',
  Hobart = 'HOBART',
  HongKong = 'HONG_KONG',
  IndianaEast = 'INDIANA_EAST',
  InternationalDateLineWest = 'INTERNATIONAL_DATE_LINE_WEST',
  Irkutsk = 'IRKUTSK',
  Islamabad = 'ISLAMABAD',
  Istanbul = 'ISTANBUL',
  Jakarta = 'JAKARTA',
  Jerusalem = 'JERUSALEM',
  Kabul = 'KABUL',
  Kaliningrad = 'KALININGRAD',
  Kamchatka = 'KAMCHATKA',
  Karachi = 'KARACHI',
  Kathmandu = 'KATHMANDU',
  Kolkata = 'KOLKATA',
  Krasnoyarsk = 'KRASNOYARSK',
  KualaLumpur = 'KUALA_LUMPUR',
  Kuwait = 'KUWAIT',
  Kyiv = 'KYIV',
  LaPaz = 'LA_PAZ',
  Lima = 'LIMA',
  Lisbon = 'LISBON',
  Ljubljana = 'LJUBLJANA',
  London = 'LONDON',
  Madrid = 'MADRID',
  Magadan = 'MAGADAN',
  MarshallIs = 'MARSHALL_IS',
  Mazatlan = 'MAZATLAN',
  Melbourne = 'MELBOURNE',
  MexicoCity = 'MEXICO_CITY',
  MidwayIsland = 'MIDWAY_ISLAND',
  MidAtlantic = 'MID_ATLANTIC',
  Minsk = 'MINSK',
  Monrovia = 'MONROVIA',
  Monterrey = 'MONTERREY',
  Montevideo = 'MONTEVIDEO',
  Moscow = 'MOSCOW',
  MountainTimeUsCanada = 'MOUNTAIN_TIME_US_CANADA',
  Mumbai = 'MUMBAI',
  Muscat = 'MUSCAT',
  Nairobi = 'NAIROBI',
  Newfoundland = 'NEWFOUNDLAND',
  NewCaledonia = 'NEW_CALEDONIA',
  NewDelhi = 'NEW_DELHI',
  Novosibirsk = 'NOVOSIBIRSK',
  NukuAlofa = 'NUKU_ALOFA',
  Osaka = 'OSAKA',
  PacificTimeUsCanada = 'PACIFIC_TIME_US_CANADA',
  Paris = 'PARIS',
  Perth = 'PERTH',
  PortMoresby = 'PORT_MORESBY',
  Prague = 'PRAGUE',
  Pretoria = 'PRETORIA',
  PuertoRico = 'PUERTO_RICO',
  Quito = 'QUITO',
  Rangoon = 'RANGOON',
  Riga = 'RIGA',
  Riyadh = 'RIYADH',
  Rome = 'ROME',
  Samara = 'SAMARA',
  Samoa = 'SAMOA',
  Santiago = 'SANTIAGO',
  Sapporo = 'SAPPORO',
  Sarajevo = 'SARAJEVO',
  Saskatchewan = 'SASKATCHEWAN',
  Seoul = 'SEOUL',
  Singapore = 'SINGAPORE',
  Skopje = 'SKOPJE',
  Sofia = 'SOFIA',
  SolomonIs = 'SOLOMON_IS',
  Srednekolymsk = 'SREDNEKOLYMSK',
  SriJayawardenepura = 'SRI_JAYAWARDENEPURA',
  Stockholm = 'STOCKHOLM',
  StPetersburg = 'ST_PETERSBURG',
  Sydney = 'SYDNEY',
  Taipei = 'TAIPEI',
  Tallinn = 'TALLINN',
  Tashkent = 'TASHKENT',
  Tbilisi = 'TBILISI',
  Tehran = 'TEHRAN',
  Tijuana = 'TIJUANA',
  TokelauIs = 'TOKELAU_IS',
  Tokyo = 'TOKYO',
  Ulaanbaatar = 'ULAANBAATAR',
  Urumqi = 'URUMQI',
  Utc = 'UTC',
  Vienna = 'VIENNA',
  Vilnius = 'VILNIUS',
  Vladivostok = 'VLADIVOSTOK',
  Volgograd = 'VOLGOGRAD',
  Warsaw = 'WARSAW',
  Wellington = 'WELLINGTON',
  WestCentralAfrica = 'WEST_CENTRAL_AFRICA',
  Yakutsk = 'YAKUTSK',
  Yerevan = 'YEREVAN',
  Zagreb = 'ZAGREB',
  Zurich = 'ZURICH',
}

/** A group criterion argument */
export type Timeframe = Ever | Latest | Range | Relative

export type TimeframeArgument = {
  endDate?: Maybe<Scalars['ISO8601Date']>
  rangeType?: Maybe<RangeTypes>
  relativeUnit?: Maybe<DateUnits>
  relativeValue?: Maybe<Scalars['Int']>
  startDate?: Maybe<Scalars['ISO8601Date']>
  type: TimeframeTypes
}

export enum TimeframeTypes {
  Absolute = 'ABSOLUTE',
  Ever = 'EVER',
  Latest = 'LATEST',
  Relative = 'RELATIVE',
}

export enum TimelineEventTypes {
  KmsatAllEnrolled = 'kmsat_all_enrolled',
  KmsatAssessmentCompleted = 'kmsat_assessment_completed',
  KmsatAssessmentEnrolled = 'kmsat_assessment_enrolled',
  KmsatAssessmentRemoved = 'kmsat_assessment_removed',
  KmsatAssessmentStarted = 'kmsat_assessment_started',
  KmsatAuditLog = 'kmsat_audit_log',
  KmsatBreach = 'kmsat_breach',
  KmsatCampaignAttachmentOpen = 'kmsat_campaign_attachment_open',
  KmsatCampaignBounced = 'kmsat_campaign_bounced',
  KmsatCampaignCallbackDataEntered = 'kmsat_campaign_callback_data_entered',
  KmsatCampaignCalled = 'kmsat_campaign_called',
  KmsatCampaignClicked = 'kmsat_campaign_clicked',
  KmsatCampaignDataEntered = 'kmsat_campaign_data_entered',
  KmsatCampaignDelivered = 'kmsat_campaign_delivered',
  KmsatCampaignMacroEnabled = 'kmsat_campaign_macro_enabled',
  KmsatCampaignPassed = 'kmsat_campaign_passed',
  KmsatCampaignQrCodeScanned = 'kmsat_campaign_qr_code_scanned',
  KmsatCampaignReplied = 'kmsat_campaign_replied',
  KmsatCampaignReported = 'kmsat_campaign_reported',
  KmsatKnowledgeRefresherCompleted = 'kmsat_knowledge_refresher_completed',
  KmsatPolicyCompleted = 'kmsat_policy_completed',
  KmsatPolicyEnrolled = 'kmsat_policy_enrolled',
  KmsatPolicyRemoved = 'kmsat_policy_removed',
  KmsatPolicyStarted = 'kmsat_policy_started',
  KmsatPurchaseCompleted = 'kmsat_purchase_completed',
  KmsatPurchaseEnrolled = 'kmsat_purchase_enrolled',
  KmsatPurchaseRemoved = 'kmsat_purchase_removed',
  KmsatPurchaseStarted = 'kmsat_purchase_started',
  KmsatUserCreated = 'kmsat_user_created',
}

/** Timeline events */
export type TimelineEvents = {
  __typename?: 'TimelineEvents'
  /** The account associated with the timeline events */
  account: Account
  /** A collection of user events within the User Event API */
  events: Array<UserEvent>
  /** Pagination details for the event list */
  pagination: EventPagination
}

/** Autogenerated return type of TimelineExternalEventDelete. */
export type TimelineExternalEventDeletePayload = {
  __typename?: 'TimelineExternalEventDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<UserEvent>
}

/** The database level timestamps for tracking created and updated */
export type Timestamps = {
  /** The timestamp when the record was created */
  createdAt: Scalars['ISO8601DateTime']
  /** The timestamp when the record was last updated */
  updatedAt: Scalars['ISO8601DateTime']
}

export enum Tmr {
  Custom = 'custom',
  System = 'system',
}

export enum TmrRiskLevels {
  High = 'high',
  Medium = 'medium',
  VeryHigh = 'very_high',
}

export enum TmrSortFields {
  Modified = 'modified',
}

export type TopDetectionRules = {
  __typename?: 'TopDetectionRules'
  workflowCount: Scalars['Int']
  workflowId: Scalars['String']
  workflowName: Scalars['String']
}

export type TopRiskyGroups = {
  __typename?: 'TopRiskyGroups'
  /** The count of members in the risky group */
  count?: Maybe<Scalars['Int']>
  /** The name of the risky group */
  name?: Maybe<Scalars['String']>
}

export type TopRiskyUsers = {
  __typename?: 'TopRiskyUsers'
  /** The count of risky activities associated with the user */
  count?: Maybe<Scalars['Int']>
  /** The email address of the user */
  email?: Maybe<Scalars['String']>
  /** The unique identifier for the user */
  userId?: Maybe<Scalars['Int']>
}

/** ModStore topic */
export type Topic = {
  __typename?: 'Topic'
  /** The ID of the topic */
  id: Scalars['Int']
  /** The name of the topic */
  name?: Maybe<Scalars['String']>
  /** The category this topic belongs to */
  topicCategory?: Maybe<TopicCategory>
  /** The key used for translations of the topic name */
  translationKey?: Maybe<Scalars['String']>
}

/** ModStore topic category */
export type TopicCategory = {
  __typename?: 'TopicCategory'
  /** The unique identifier of the topic category */
  id: Scalars['Int']
  /** The name of the topic category */
  name?: Maybe<Scalars['String']>
  /** The key used for translation of the topic category name */
  translationKey?: Maybe<Scalars['String']>
}

export type Trademark = Identifier &
  Timestamps & {
    __typename?: 'Trademark'
    /** A list of all keywords associated with this trademark */
    allTrademarkKeywords: Array<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The name of the trademark */
    name: Scalars['String']
    /** The number of open matches for this trademark */
    openMatches: Scalars['Int']
    /** The ordering index of the trademark */
    ordering: Scalars['Int']
    /** A list of trademark keywords associated with this trademark */
    trademarkKeywords: Array<TrademarkKeyword>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type TrademarkAttributes = {
  name: Scalars['String']
  trademarkKeywords?: Maybe<Array<TrademarkKeywordAttributes>>
}

export type TrademarkKeyword = Identifier &
  Timestamps & {
    __typename?: 'TrademarkKeyword'
    /** Flag to mark the trademark keyword for destruction */
    _destroy: Scalars['Boolean']
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The value of the trademark keyword */
    value: Scalars['String']
  }

export type TrademarkKeywordAttributes = {
  _destroy?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['Int']>
  value: Scalars['String']
}

export type TrademarkMatch = Identifier &
  Timestamps & {
    __typename?: 'TrademarkMatch'
    /** The ID of the linked account */
    accountId: Scalars['Int']
    /** The name of the associated account */
    accountName: Scalars['String']
    /** The total number of campaigns associated with this trademark match */
    campaignCount: Scalars['Int']
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Associated phishing email template */
    emailTemplate: PhishingTemplate
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** A list of keywords associated with the trademark match */
    keywords: Array<Scalars['String']>
    /** The timestamp of the last campaign */
    lastCampaignAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The current status of the trademark match */
    status: TrademarkMatchStatuses
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export enum TrademarkMatchSortFields {
  CreatedAt = 'CREATED_AT',
  Status = 'STATUS',
  Template = 'TEMPLATE',
  UpdatedAt = 'UPDATED_AT',
}

export enum TrademarkMatchStatuses {
  All = 'ALL',
  Archived = 'ARCHIVED',
  Csm = 'CSM',
  FalsePositive = 'FALSE_POSITIVE',
  Open = 'OPEN',
  Resolved = 'RESOLVED',
}

export type TrademarkMatchesCursor = {
  __typename?: 'TrademarkMatchesCursor'
  /** The paginated nodes */
  nodes: Array<TrademarkMatch>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of TrademarkMatchesResolve. */
export type TrademarkMatchesResolvePayload = {
  __typename?: 'TrademarkMatchesResolvePayload'
  errors?: Maybe<Array<Error>>
  node: Scalars['Int']
}

/** Autogenerated return type of TrademarksCreate. */
export type TrademarksCreatePayload = {
  __typename?: 'TrademarksCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Trademark>
}

export type TrademarksCursor = {
  __typename?: 'TrademarksCursor'
  /** The paginated nodes */
  nodes: Array<Trademark>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of TrademarksDelete. */
export type TrademarksDeletePayload = {
  __typename?: 'TrademarksDeletePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Trademark>
}

/** Autogenerated return type of TrademarksUpdate. */
export type TrademarksUpdatePayload = {
  __typename?: 'TrademarksUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Trademark>
}

export type Training = Identifier & {
  __typename?: 'Training'
  /** Defines the boolean operation for the criteria */
  boolean: Booleans
  /** List of courses associated with the training criterion */
  courses: Array<Course>
  /** The event type tied to the training criteria */
  event: TrainingEvents
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** Specifies how courses are included in the criterion */
  inclusion: TrainingCourseInclusions
  /** Specifies the timeframe for the training criterion */
  timeframe: Timeframe
}

export type TrainingArgument = {
  bool: Booleans
  courses: Array<TrainingCourseArgument>
  event: TrainingEvents
  inclusionType: TrainingCourseInclusions
  timeframe: TimeframeArgument
}

export enum TrainingAssessmentInclusions {
  All = 'ALL',
  Any = 'ANY',
  Entire = 'ENTIRE',
}

export type TrainingAsset = {
  id: Scalars['Int']
  type: EnrollmentTypes
}

/** A training campaign */
export type TrainingCampaign = Identifier &
  Timestamps &
  TrainingCampaignInterface & {
    __typename?: 'TrainingCampaign'
    /** Indicates if the training campaign is active */
    active: Scalars['Boolean']
    /** Groups to which passed users are added in the training campaign */
    addPassedToGroups?: Maybe<Array<Scalars['Int']>>
    /** Flag indicating if all users are included in the training campaign */
    allUsers: Scalars['Boolean']
    /** Indicates if past due completions are allowed */
    allowPastDueCompletions: Scalars['Boolean']
    /** Indicates if survey comments are allowed */
    allowSurveyComments: Scalars['Boolean']
    /** Indicates if surveys are allowed */
    allowSurveys: Scalars['Boolean']
    /** Indicates if assessment scores are anonymized */
    anonymizeAssessmentScores?: Maybe<Scalars['Boolean']>
    /** The assets associated with the training campaign */
    assets: Array<TrainingCampaignAsset>
    auditLogDisplay?: Maybe<Scalars['String']>
    /** Indicates if users are auto-enrolled */
    autoEnroll?: Maybe<Scalars['Boolean']>
    /** Indicates if users are auto-unenrolled */
    autoUnenroll?: Maybe<Scalars['Boolean']>
    /** The content theme associated with the training campaign */
    contentTheme?: Maybe<ContentTheme>
    /** The content theme with default options for the training campaign */
    contentThemeWithDefault?: Maybe<ContentTheme>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Indicates if the training campaign is soft deleted */
    deleted: Scalars['Boolean']
    /** The delivery target for the training campaign */
    deliverTo: TrainingCampaignDeliverTo
    /** The type of duration for the training campaign */
    durationType: Scalars['String']
    endCampaignAtDate?: Maybe<Scalars['ISO8601Date']>
    endCampaignAtTime?: Maybe<Scalars['Time']>
    /** The end campaign option */
    endCampaignOption?: Maybe<TrainingCampaignEnd>
    /** The end date of the training campaign */
    endsAt?: Maybe<Scalars['ISO8601DateTime']>
    /** Flag indicating if enrollment via CSV is allowed in the training campaign */
    enrollViaCsv?: Maybe<Scalars['String']>
    /** The duration of the training campaign */
    enrollmentDuration?: Maybe<Scalars['Int']>
    /** The type of duration for the training campaign */
    enrollmentDurationType?: Maybe<TrainingCampaignDurations>
    /** The enrollment events occurred during the training campaign */
    enrollmentEvents: Array<EnrollmentEvent>
    /** The groups involved in the training campaign */
    groups: Array<Group>
    /** Flag indicating if there are CSV enrollments in the training campaign */
    hasCsvEnrollments: Scalars['Boolean']
    /** Flag indicating if there are individual enrollments in the training campaign */
    hasIndividualEnrollments: Scalars['Boolean']
    /** The ID of the training campaign */
    id: Scalars['Int']
    /** The delay in days for knowledge refreshers */
    knowledgeRefreshersDelayInDays?: Maybe<Scalars['Int']>
    /** Indicates if knowledge refreshers are enabled */
    knowledgeRefreshersEnabled?: Maybe<Scalars['Boolean']>
    /** The managed training campaign associated with this campaign */
    managedTrainingCampaign?: Maybe<ManagedTrainingCampaign>
    /** The name of the training campaign */
    name: Scalars['String']
    /** Notifications generated by the training campaign */
    notifications: Array<Notification>
    /** The percentage of the training campaign completed */
    percentComplete: Scalars['Int']
    /** Policies applied to the training campaign */
    policies: Array<Policy>
    /** The courses purchased for the training campaign */
    purchasedCourses: Array<PurchasedCourse>
    /** The relative duration of the training campaign */
    relativeDuration?: Maybe<Scalars['String']>
    /** Groups from which passed users are removed in the training campaign */
    removePassedFromGroups?: Maybe<Array<Scalars['Int']>>
    /** Indicates if auto-enrollments are reset */
    resetAutoEnrollments?: Maybe<Scalars['Boolean']>
    /** The selected content for the training campaign */
    selectedContent?: Maybe<Array<Scalars['String']>>
    /** The groups selected for the training campaign */
    selectedGroups?: Maybe<Array<Scalars['Int']>>
    startCampaignAtDate?: Maybe<Scalars['ISO8601Date']>
    startCampaignAtTime?: Maybe<Scalars['Time']>
    /** The start date of the training campaign */
    startsAt: Scalars['ISO8601DateTime']
    /** The status of the training campaign */
    status: TrainingCampaignStatuses
    /** Store purchases made for the training campaign */
    storePurchases: Array<StorePurchase>
    /** The time zone of the training campaign */
    timeZone?: Maybe<TimeZones>
    /** The total time required to complete the training campaign */
    totalTime: Scalars['Int']
    /** The total number of users in the training campaign */
    totalUsers: Scalars['Int']
    /** Indicates if scores are tracked */
    trackScores?: Maybe<Scalars['Boolean']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    userCounts: TrainingCampaignUserCount
    /** The enrollments in the training campaign */
    userEnrollments: Array<Enrollment>
    /** The training chart data for users in the training campaign */
    userTrainingChartData: Array<TimeData>
  }

/** A training campaign */
export type TrainingCampaignPurchasedCoursesArgs = {
  storeItemSources?: Maybe<Array<Scalars['String']>>
}

/** A training campaign */
export type TrainingCampaignUserCountsArgs = {
  asset?: Maybe<TrainingAsset>
  includeArchived?: Maybe<Scalars['Boolean']>
  search?: Maybe<Scalars['String']>
}

/** A training campaign */
export type TrainingCampaignUserEnrollmentsArgs = {
  userId: Scalars['Int']
}

/** A training campaign asset */
export type TrainingCampaignAsset =
  | KnowledgeRefresher
  | ManagedStorePurchase
  | Policy
  | PurchasedCourse

export type TrainingCampaignAttributes = {
  /** The IDs of the groups to add passed users to */
  addPassedToGroups?: Maybe<Array<Scalars['Int']>>
  /** Whether to enroll all users */
  allUsers: Scalars['Boolean']
  /** Whether to allow past due completions */
  allowPastDueCompletions: Scalars['Boolean']
  /** Whether to allow survey comments */
  allowSurveyComments: Scalars['Boolean']
  /** Whether to allow surveys */
  allowSurveys: Scalars['Boolean']
  /** Whether to anonymize assessment scores */
  anonymizeAssessmentScores?: Maybe<Scalars['Boolean']>
  /** Whether to auto enroll users */
  autoEnroll: Scalars['Boolean']
  /** Whether to auto unenroll users */
  autoUnenroll: Scalars['Boolean']
  /** The date to end the training campaign */
  endCampaignAtDate?: Maybe<Scalars['ISO8601Date']>
  /** The time to end the training campaign */
  endCampaignAtTime?: Maybe<Scalars['Time']>
  /** The file to enroll via CSV */
  enrollViaCsv?: Maybe<Scalars['File']>
  /** The duration of the enrollment */
  enrollmentDuration?: Maybe<Scalars['Int']>
  /** The type of duration for the enrollment */
  enrollmentDurationType?: Maybe<TrainingCampaignDurations>
  /** The delay in days for the knowledge refreshers */
  knowledgeRefreshersDelayInDays?: Maybe<Scalars['Int']>
  /** Whether knowledge refreshers are enabled */
  knowledgeRefreshersEnabled?: Maybe<Scalars['Boolean']>
  /** The name of the training campaign */
  name: Scalars['String']
  /** The notifications for the training campaign */
  notifications: Array<NotificationAttributes>
  /** The IDs of the groups to remove passed users from */
  removePassedFromGroups?: Maybe<Array<Scalars['Int']>>
  /** Whether to reset auto enrollments */
  resetAutoEnrollments?: Maybe<Scalars['Boolean']>
  /** The selected content */
  selectedContent?: Maybe<Array<Scalars['String']>>
  /** The selected groups */
  selectedGroups?: Maybe<Array<Scalars['Int']>>
  /** The date to start the training campaign */
  startCampaignAtDate?: Maybe<Scalars['ISO8601Date']>
  /** The time to start the training campaign */
  startCampaignAtTime?: Maybe<Scalars['Time']>
  /** The time zone for the training campaign */
  timeZone?: Maybe<TimeZones>
  /** Whether to track scores */
  trackScores?: Maybe<Scalars['Boolean']>
}

export enum TrainingCampaignDeliverTo {
  All = 'ALL',
  Specify = 'SPECIFY',
}

/** Training campaign event details */
export type TrainingCampaignDetails = {
  __typename?: 'TrainingCampaignDetails'
  /** The name of the training campaign */
  name?: Maybe<Scalars['String']>
}

export enum TrainingCampaignDurations {
  Days = 'DAYS',
  Months = 'MONTHS',
  Weeks = 'WEEKS',
}

export enum TrainingCampaignEnd {
  NoEndDate = 'NO_END_DATE',
  Relative = 'RELATIVE',
  SpecifyDate = 'SPECIFY_DATE',
}

export type TrainingCampaignEnrollments = {
  __typename?: 'TrainingCampaignEnrollments'
  /** The CSV representation of training campaign enrollments */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export enum TrainingCampaignFilters {
  Active = 'ACTIVE',
  All = 'ALL',
  Inactive = 'INACTIVE',
}

/** Common fields between training campaigns and managed training campaigns */
export type TrainingCampaignInterface = {
  /** Indicates if the training campaign is active */
  active: Scalars['Boolean']
  /** Indicates if past due completions are allowed */
  allowPastDueCompletions: Scalars['Boolean']
  /** Indicates if survey comments are allowed */
  allowSurveyComments: Scalars['Boolean']
  /** Indicates if surveys are allowed */
  allowSurveys: Scalars['Boolean']
  /** Indicates if assessment scores are anonymized */
  anonymizeAssessmentScores?: Maybe<Scalars['Boolean']>
  /** Indicates if users are auto-enrolled */
  autoEnroll?: Maybe<Scalars['Boolean']>
  /** Indicates if users are auto-unenrolled */
  autoUnenroll?: Maybe<Scalars['Boolean']>
  /** Indicates if the training campaign is soft deleted */
  deleted: Scalars['Boolean']
  endCampaignAtDate?: Maybe<Scalars['ISO8601Date']>
  endCampaignAtTime?: Maybe<Scalars['Time']>
  /** The end campaign option */
  endCampaignOption?: Maybe<TrainingCampaignEnd>
  /** The end date of the training campaign */
  endsAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The duration of the training campaign */
  enrollmentDuration?: Maybe<Scalars['Int']>
  /** The type of duration for the training campaign */
  enrollmentDurationType?: Maybe<TrainingCampaignDurations>
  /** The delay in days for knowledge refreshers */
  knowledgeRefreshersDelayInDays?: Maybe<Scalars['Int']>
  /** Indicates if knowledge refreshers are enabled */
  knowledgeRefreshersEnabled?: Maybe<Scalars['Boolean']>
  /** The name of the training campaign */
  name: Scalars['String']
  /** The percentage of the training campaign completed */
  percentComplete: Scalars['Int']
  /** Indicates if auto-enrollments are reset */
  resetAutoEnrollments?: Maybe<Scalars['Boolean']>
  /** The selected content for the training campaign */
  selectedContent?: Maybe<Array<Scalars['String']>>
  startCampaignAtDate?: Maybe<Scalars['ISO8601Date']>
  startCampaignAtTime?: Maybe<Scalars['Time']>
  /** The start date of the training campaign */
  startsAt: Scalars['ISO8601DateTime']
  /** The status of the training campaign */
  status: TrainingCampaignStatuses
  /** The time zone of the training campaign */
  timeZone?: Maybe<TimeZones>
  /** Indicates if scores are tracked */
  trackScores?: Maybe<Scalars['Boolean']>
}

export enum TrainingCampaignNotification {
  NotifyAfterEnrolled = 'notify_after_enrolled',
  NotifyBeforeDue = 'notify_before_due',
  NotifyOnCompletion = 'notify_on_completion',
  Onetime = 'onetime',
  PastDue = 'past_due',
  WelcomeEmail = 'welcome_email',
}

export enum TrainingCampaignSortFields {
  Name = 'name',
  PercentComplete = 'percentComplete',
}

export enum TrainingCampaignStatuses {
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Enrolling = 'ENROLLING',
  InProgress = 'IN_PROGRESS',
  Testmode = 'TESTMODE',
}

/** Survey results for training campaign course */
export type TrainingCampaignSurvey = Identifier &
  Timestamps & {
    __typename?: 'TrainingCampaignSurvey'
    /** The type of content included in the training campaign survey */
    contentType?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The duration of the training campaign survey in minutes */
    duration?: Maybe<Scalars['Int']>
    /** Indicates whether the training campaign survey includes surveys */
    hasSurveys: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The results of the survey associated with the training campaign */
    surveyResults: TrainingCampaignSurveyResults
    /** The title of the training campaign survey */
    title?: Maybe<Scalars['String']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

/** Aggregated training campaign survey results */
export type TrainingCampaignSurveyResults = {
  __typename?: 'TrainingCampaignSurveyResults'
  /** Score reflecting the appropriateness of the content length */
  contentLengthScore: Scalars['String']
  /** Average score of how helpful the training campaign was */
  helpfulnessScore: Scalars['String']
  /** Rating of the presentation quality */
  presentationScore: Scalars['String']
  /** The number of responses received for a survey */
  responseCount: Scalars['Int']
}

export type TrainingCampaignSurveys = {
  __typename?: 'TrainingCampaignSurveys'
  /** CSV format of the training campaign surveys report */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type TrainingCampaignSurveysCursor = {
  __typename?: 'TrainingCampaignSurveysCursor'
  /** The paginated nodes */
  nodes: Array<TrainingCampaignSurvey>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** A training campaign user */
export type TrainingCampaignUser = Identifier &
  Timestamps & {
    __typename?: 'TrainingCampaignUser'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** A list of enrollments for this user in the context of the training campaign */
    enrollments: Array<Enrollment>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** A list of notification receipts related to the training campaign for the user, if any */
    notificationReceipts?: Maybe<Array<NotificationReceipt>>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The associated user of this training campaign user entry */
    user: User
  }

/** A training campaign user count */
export type TrainingCampaignUserCount = Identifier &
  Timestamps & {
    __typename?: 'TrainingCampaignUserCount'
    /** The number of users who have completed their training */
    complete: Scalars['Int']
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The number of users who are currently in progress with their training */
    inProgress: Scalars['Int']
    /** The number of users who have incomplete training */
    incomplete: Scalars['Int']
    /** The number of users who have not started their training */
    notStarted: Scalars['Int']
    /** The number of users who are past due on their training */
    pastDue: Scalars['Int']
    /** The total number of users */
    total: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type TrainingCampaignUsersCursor = {
  __typename?: 'TrainingCampaignUsersCursor'
  /** The paginated nodes */
  nodes: Array<TrainingCampaignUser>
  /** Information about the current paginated set */
  pagination: Pagination
}

export enum TrainingCampaignUsersSortFields {
  CreatedAt = 'CREATED_AT',
  Email = 'EMAIL',
  ExpiresAt = 'EXPIRES_AT',
  FirstName = 'FIRST_NAME',
  Score = 'SCORE',
  StartedAt = 'STARTED_AT',
  Status = 'STATUS',
  TotalTimeInSeconds = 'TOTAL_TIME_IN_SECONDS',
}

/** Autogenerated return type of TrainingCampaignsAddUser. */
export type TrainingCampaignsAddUserPayload = {
  __typename?: 'TrainingCampaignsAddUserPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** Whether the user was added to the training campaign */
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of TrainingCampaignsBulkChange. */
export type TrainingCampaignsBulkChangePayload = {
  __typename?: 'TrainingCampaignsBulkChangePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** Whether the enrollments were changed */
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of TrainingCampaignsClose. */
export type TrainingCampaignsClosePayload = {
  __typename?: 'TrainingCampaignsClosePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The training campaign that was closed */
  node?: Maybe<TrainingCampaign>
}

/** Autogenerated return type of TrainingCampaignsCreate. */
export type TrainingCampaignsCreatePayload = {
  __typename?: 'TrainingCampaignsCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The training campaign that was created */
  node?: Maybe<TrainingCampaign>
}

/** Autogenerated return type of TrainingCampaignsCsvEnroll. */
export type TrainingCampaignsCsvEnrollPayload = {
  __typename?: 'TrainingCampaignsCsvEnrollPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
}

export type TrainingCampaignsCursor = {
  __typename?: 'TrainingCampaignsCursor'
  /** The paginated nodes */
  nodes: Array<TrainingCampaign>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of TrainingCampaignsDelete. */
export type TrainingCampaignsDeletePayload = {
  __typename?: 'TrainingCampaignsDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** Whether the training campaigns were deleted */
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of TrainingCampaignsEdit. */
export type TrainingCampaignsEditPayload = {
  __typename?: 'TrainingCampaignsEditPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated training campaign */
  node?: Maybe<TrainingCampaign>
}

/** Autogenerated return type of TrainingCampaignsExtendDueDate. */
export type TrainingCampaignsExtendDueDatePayload = {
  __typename?: 'TrainingCampaignsExtendDueDatePayload'
  /** Whether the due date was extended */
  extended: Scalars['Boolean']
}

/** Autogenerated return type of TrainingCampaignsRemoveUser. */
export type TrainingCampaignsRemoveUserPayload = {
  __typename?: 'TrainingCampaignsRemoveUserPayload'
  /** Whether the user was removed */
  removed: Scalars['Boolean']
}

/** Autogenerated return type of TrainingCampaignsReopen. */
export type TrainingCampaignsReopenPayload = {
  __typename?: 'TrainingCampaignsReopenPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

export type TrainingComparison = {
  __typename?: 'TrainingComparison'
  /** The name of the group */
  groupName: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** The percentage or number of trained individuals in the group */
  trained?: Maybe<Scalars['Float']>
  /** The percentage or number of untrained individuals in the group */
  untrained?: Maybe<Scalars['Float']>
}

export type TrainingComparisonSeries = {
  __typename?: 'TrainingComparisonSeries'
  /** CSV format of the training comparison data */
  csv: Scalars['String']
  /** Array of training comparison data */
  data: Array<TrainingComparison>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type TrainingCourseArgument = {
  id?: Maybe<Scalars['Int']>
  label?: Maybe<Scalars['String']>
  type: CourseTypes
  uuid?: Maybe<Scalars['String']>
}

export enum TrainingCourseInclusions {
  All = 'ALL',
  Any = 'ANY',
  Available = 'AVAILABLE',
}

export enum TrainingEvents {
  Completed = 'COMPLETED',
  Enrolled = 'ENROLLED',
  Started = 'STARTED',
}

export type TrainingInteractionsAllInteractions = {
  __typename?: 'TrainingInteractionsAllInteractions'
  /** The creation date and time of the interaction */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The group associated with the training interaction */
  group?: Maybe<Group>
  /** A list of questions associated with the training interaction */
  questions?: Maybe<Array<TrainingInteractionsQuestions>>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type TrainingInteractionsAnswers = {
  __typename?: 'TrainingInteractionsAnswers'
  /** Indicates whether this answer is correct */
  correct?: Maybe<Scalars['Boolean']>
  /** The label of the training interaction answer */
  label?: Maybe<Scalars['String']>
  /** The number of learners who selected this answer */
  learnersWhoPickedThis?: Maybe<Scalars['Int']>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type TrainingInteractionsAnswersByGroup = {
  __typename?: 'TrainingInteractionsAnswersByGroup'
  /** The unique identifier of the group */
  id: Scalars['Int']
  /** The name of the group */
  name: Scalars['String']
  /** The title of the question asked to the group */
  questionTitle: Scalars['String']
  /** A list of responses from the group to the question */
  responses?: Maybe<Array<TrainingInteractionsAnswers>>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type TrainingInteractionsByGroup = {
  __typename?: 'TrainingInteractionsByGroup'
  /** List of all training interactions by group */
  allInteractions?: Maybe<Array<TrainingInteractionsDetails>>
  /** The title of the content */
  contentTitle?: Maybe<Scalars['String']>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type TrainingInteractionsByGroupCsv = {
  __typename?: 'TrainingInteractionsByGroupCsv'
  /** A CSV representation of training interactions by group */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type TrainingInteractionsByPurchasedCourse = {
  __typename?: 'TrainingInteractionsByPurchasedCourse'
  /** All interactions associated with the purchased course */
  allInteractions?: Maybe<TrainingInteractionsAllInteractions>
  /** The title of the content associated with training interactions */
  contentTitle?: Maybe<Scalars['String']>
  /** Statistics related to enrollments in the training */
  enrollmentStats?: Maybe<EnrollmentStats>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type TrainingInteractionsCsv = {
  __typename?: 'TrainingInteractionsCsv'
  /** A CSV representation of training interactions data */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type TrainingInteractionsDetails = {
  __typename?: 'TrainingInteractionsDetails'
  /** The date and time when the training interaction was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The group associated with the training interaction */
  group?: Maybe<Group>
  /** A list of questions related to the training interactions */
  questions?: Maybe<Array<TrainingInteractionsQuestions>>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type TrainingInteractionsQuestions = {
  __typename?: 'TrainingInteractionsQuestions'
  /** The number of correct responses */
  numberOfCorrect?: Maybe<Scalars['Int']>
  /** The number of incorrect responses */
  numberOfIncorrect?: Maybe<Scalars['Int']>
  /** The title of the training question */
  questionTitle?: Maybe<Scalars['String']>
  /** A list of responses for the training question */
  responses?: Maybe<Array<TrainingInteractionsAnswers>>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type TrainingInteractionsResponses = {
  __typename?: 'TrainingInteractionsResponses'
  /** A list of responses to training interactions */
  responses?: Maybe<Array<TrainingInteractionsAnswers>>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type TrainingInteractionsResponsesByGroups = {
  __typename?: 'TrainingInteractionsResponsesByGroups'
  /** A list of groups with their respective training interactions responses */
  groups?: Maybe<Array<TrainingInteractionsAnswersByGroup>>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export enum TrainingNotificationDeliveryMethods {
  Email = 'email',
  Gchat = 'gchat',
  Slack = 'slack',
  Teams = 'teams',
}

/** A training report */
export type TrainingReport = Identifier &
  Timestamps & {
    __typename?: 'TrainingReport'
    /** A list of courses related to this training report */
    courses: Array<TrainingReportCourse>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The user associated with this training report */
    user: User
  }

/** A training report course */
export type TrainingReportCourse = {
  __typename?: 'TrainingReportCourse'
  /** Indicates if the course can be acknowledged by the user */
  acknowledgeable: Scalars['Boolean']
  /** Indicates if the course has been acknowledged by the user */
  acknowledged: Scalars['Boolean']
  /** Indicates if the course has been passed */
  passed: Scalars['Boolean']
  /** Indicates if the course is past its due date */
  pastDue: Scalars['Boolean']
  /** The current enrollment status of the course */
  status: EnrollmentStatuses
  /** The UUID for the course */
  uuid: Scalars['String']
}

export type TrainingReportCursor = {
  __typename?: 'TrainingReportCursor'
  /** The paginated nodes */
  nodes: Array<TrainingReport>
  /** Information about the current paginated set */
  pagination: Pagination
}

export enum TrainingReportTypes {
  AcknowledgedPolicy = 'ACKNOWLEDGED_POLICY',
  Completed = 'COMPLETED',
  IncompleteCourses = 'INCOMPLETE_COURSES',
  NotAcknowledgedPolicy = 'NOT_ACKNOWLEDGED_POLICY',
  NotStartedCourses = 'NOT_STARTED_COURSES',
  NotStartedOrFinished = 'NOT_STARTED_OR_FINISHED',
  StartedCourses = 'STARTED_COURSES',
}

/** User in the account */
export type TrainingStatFailureTypes = {
  __typename?: 'TrainingStatFailureTypes'
  /** Percentage of recipients affected by this type of failure */
  percent: Scalars['Float']
  /** The type of training stat failure */
  type: CampaignRecipientStatuses
}

/** User in the account */
export type TrainingStats = {
  __typename?: 'TrainingStats'
  /** The total number of trainings reported via email */
  emailReported: Scalars['Int']
  /** The total number of trainings delivered */
  totalDelivered: Scalars['Int']
  /** The total number of failed trainings */
  totalFailures: Scalars['Int']
  /** A list of specific training failures */
  trainingFailures: Array<TrainingStatFailureTypes>
}

export type TrainingStatuses = {
  __typename?: 'TrainingStatuses'
  /** The CSV format of the training statuses report */
  csv: Scalars['String']
  /** The data for training statuses report */
  data: Array<TrainingStatusesData>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type TrainingStatusesByCampaign = {
  __typename?: 'TrainingStatusesByCampaign'
  /** A CSV representation of the training statuses by campaign */
  csv: Scalars['String']
  /** An array of training statuses by campaign data */
  data: Array<TrainingStatusesByCampaignData>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type TrainingStatusesByCampaignData = {
  __typename?: 'TrainingStatusesByCampaignData'
  /** The total number of trainings assigned to the user */
  assigned?: Maybe<Scalars['Int']>
  /** The number of training sessions completed by the user */
  completed?: Maybe<Scalars['Int']>
  /** The date and time when the user completed their training */
  completedAt?: Maybe<Scalars['String']>
  /** The number of training sessions completed past the due date by the user */
  completedPastDue?: Maybe<Scalars['Int']>
  /** The date and time when the report was created */
  createdAt?: Maybe<Scalars['String']>
  /** The email of the user */
  email?: Maybe<Scalars['String']>
  /** Groups to which the user belongs */
  groups?: Maybe<Scalars['String']>
  /** The unique identifier of the report */
  id?: Maybe<Scalars['Int']>
  /** The number of training sessions currently in progress for the user */
  inProgress?: Maybe<Scalars['Int']>
  /** The location of the user */
  location?: Maybe<Scalars['String']>
  /** The name of the user */
  name?: Maybe<Scalars['String']>
  /** The number of training sessions not yet started by the user */
  notStarted?: Maybe<Scalars['Int']>
  /** The number of training sessions past due for the user */
  pastDue?: Maybe<Scalars['Int']>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
  /** The total time spent by the user on trainings */
  totalTimeSpent?: Maybe<Scalars['String']>
  /** The overall training status of the user */
  trainingStatus: Scalars['String']
}

export type TrainingStatusesData = {
  __typename?: 'TrainingStatusesData'
  /** The date and time when the account was last accessed */
  accountAccess?: Maybe<Scalars['ISO8601DateTime']>
  /** The unique identifier of the account */
  accountId?: Maybe<Scalars['Int']>
  /** The number of completed trainings */
  completed?: Maybe<Scalars['Int']>
  /** The number of completed trainings that were finished past their due date */
  completedPastDue?: Maybe<Scalars['Int']>
  /** The unique identifier of the training status record */
  id?: Maybe<Scalars['Int']>
  /** The number of trainings currently in progress */
  inProgress?: Maybe<Scalars['Int']>
  /** The name associated with the training status */
  name: Scalars['String']
  /** The number of trainings that have not been started */
  notStarted?: Maybe<Scalars['Int']>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type TransitData = {
  __typename?: 'TransitData'
  /** The confidence score of the transit data */
  confidenceScore?: Maybe<Scalars['Float']>
  /** The unique identifier of the user */
  id?: Maybe<Scalars['String']>
  /** The transit user data associated with the user */
  sabUser?: Maybe<TransitUserData>
}

export type TransitUserData = {
  __typename?: 'TransitUserData'
  /** User's score in recognizing phishing attempts */
  antiPhishingScore?: Maybe<Scalars['Int']>
  /** User's cyber awareness score */
  awarenessScore?: Maybe<Scalars['Int']>
  /** Date the user was created in the system */
  createdDate?: Maybe<Scalars['ISO8601DateTime']>
  /** Department of the user */
  department?: Maybe<Scalars['String']>
  /** Email address of the user */
  email?: Maybe<Scalars['String']>
  /** Job title of the user */
  employeeTitle?: Maybe<Scalars['String']>
  /** First name of the user */
  firstName?: Maybe<Scalars['String']>
  /** Unique identifier of the user */
  id?: Maybe<Scalars['Int']>
  /** Indicates whether the user is currently active */
  isActive?: Maybe<Scalars['Boolean']>
  /** Last name of the user */
  lastName?: Maybe<Scalars['String']>
  /** Geographical location of the user */
  location?: Maybe<Scalars['String']>
  /** Email of the user's manager */
  managerEmail?: Maybe<Scalars['String']>
  /** Identifier for the user's manager */
  managerId?: Maybe<Scalars['Int']>
  /** Last date the user's information was modified */
  modifiedDate?: Maybe<Scalars['ISO8601DateTime']>
  /** Organization-specific identifier for the user */
  orgUserId?: Maybe<Scalars['Int']>
  /** Identifier of the organization the user belongs to */
  organizationId?: Maybe<Scalars['Int']>
  /** Score based on the user's reporting of phishing attempts */
  phishingReportScore?: Maybe<Scalars['Int']>
  /** Source from which the user data was obtained */
  source?: Maybe<Scalars['String']>
  /** Score based on the user's participation in security trainings */
  trainingScore?: Maybe<Scalars['Int']>
}

/** ModStore translation */
export type Translation = {
  __typename?: 'Translation'
  /** The active package related to the translation */
  activePackage?: Maybe<Package>
  /** The alternative text for images related to the translation */
  altText?: Maybe<Scalars['String']>
  /** The description associated with the translation */
  description?: Maybe<Scalars['String']>
  /** The language of the translation */
  language?: Maybe<Language>
  /** The title associated with the translation */
  title?: Maybe<Scalars['String']>
}

export enum Types {
  Assessments = 'ASSESSMENTS',
  BreachEvent = 'BREACH_EVENT',
  CustomEvent = 'CUSTOM_EVENT',
  PasswordIqEvent = 'PASSWORD_IQ_EVENT',
  PasswordIqState = 'PASSWORD_IQ_STATE',
  PhishEvent = 'PHISH_EVENT',
  PhishFlipEvent = 'PHISH_FLIP_EVENT',
  PostTraining = 'POST_TRAINING',
  SecurityCoach = 'SECURITY_COACH',
  SecurityCoachRealTimeCoaching = 'SECURITY_COACH_REAL_TIME_COACHING',
  Training = 'TRAINING',
  UserDate = 'USER_DATE',
  UserField = 'USER_FIELD',
}

export type UnlistedUserData = {
  __typename?: 'UnlistedUserData'
  /** The alias of the unlisted user */
  alias?: Maybe<Scalars['String']>
  /** The creation date of the unlisted user */
  createdDate?: Maybe<Scalars['ISO8601DateTime']>
  /** Vendor data associated with the unlisted user */
  vendor?: Maybe<Array<UnlistedUserVendorData>>
}

export type UnlistedUserPayload = {
  __typename?: 'UnlistedUserPayload'
  /** The data containing unlisted users */
  data?: Maybe<Array<UnlistedUserData>>
  /** The number of records filtered by the query */
  recordsFiltered: Scalars['Int']
  /** The total number of records */
  recordsTotal: Scalars['Int']
}

export type UnlistedUserVendorData = {
  __typename?: 'UnlistedUserVendorData'
  /** The display name of the user */
  displayName?: Maybe<Scalars['String']>
  /** The code name of the vendor */
  vendorCodeName?: Maybe<Scalars['String']>
}

export type UnlistedUsersFilterData = {
  condition: Scalars['String']
  keyword: Scalars['String']
  value: Scalars['String']
}

export type UnmappedData = {
  __typename?: 'UnmappedData'
  /** An error message, if any, encountered while fetching unmapped data */
  error?: Maybe<Scalars['String']>
  /** The total number of unmapped data points */
  total?: Maybe<Scalars['Int']>
  /** A list of unmapped events */
  unmappedEvents?: Maybe<Array<UnmappedEvents>>
}

export type UnmappedEvents = {
  __typename?: 'UnmappedEvents'
  /** The date and time when the event was detected */
  detectionDatetime?: Maybe<Scalars['String']>
  /** The filepath of the detected event on the host device */
  detectionFilepath?: Maybe<Scalars['String']>
  /** The category of threat detected in the event */
  detectionThreatCategory?: Maybe<Scalars['String']>
  /** The user ID associated with the event detection */
  detectionUserId?: Maybe<Scalars['String']>
  /** The unique ID of the device where the event occurred */
  deviceId?: Maybe<Scalars['String']>
  /** The IP address of the host where the event was detected */
  hostIpAddress?: Maybe<Scalars['String']>
  /** The name of the host where the event was detected */
  hostName?: Maybe<Scalars['String']>
  /** The email of the user associated with the event */
  userEmail?: Maybe<Scalars['String']>
  /** The name of the user associated with the event */
  userName?: Maybe<Scalars['String']>
  /** The code name of the vendor associated with the event */
  vendorCodeName?: Maybe<Scalars['String']>
  /** The URL visited that was related to the event */
  visitedUrl?: Maybe<Scalars['String']>
}

export type UnphishedAndUntrainedUserNodes = {
  __typename?: 'UnphishedAndUntrainedUserNodes'
  /** The email address of the user */
  email?: Maybe<Scalars['String']>
  /** The groups the user belongs to */
  groups?: Maybe<Scalars['String']>
  /** The unique identifier of the user */
  id?: Maybe<Scalars['Int']>
  /** The name of the user */
  name?: Maybe<Scalars['String']>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type UnphishedAndUntrainedUsers = {
  __typename?: 'UnphishedAndUntrainedUsers'
  /** CSV representation of the report */
  csv: Scalars['String']
  /** A list of nodes representing unphished and untrained users */
  nodes: Array<UnphishedAndUntrainedUserNodes>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type Update = {
  __typename?: 'Update'
  error?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['String']>
}

export type UpdateRuleDisputeFilter = {
  orgIds?: Maybe<Array<Scalars['String']>>
  ruleIds?: Maybe<Array<Scalars['String']>>
  search?: Maybe<Scalars['String']>
  status?: Maybe<RuleDisputeStatus>
  vendorCodeNames?: Maybe<Array<Scalars['String']>>
}

export type UpdateRuleDisputeItem = {
  comment?: Maybe<Scalars['String']>
  id: Scalars['String']
  status?: Maybe<RuleDisputeStatus>
}

export type UploadedCsv = {
  __typename?: 'UploadedCsv'
  /** The date when the CSV file was uploaded */
  createdDate: Scalars['String']
  /** The name of the uploaded CSV file */
  fileName: Scalars['String']
  /** The unique identifier of the uploaded CSV file */
  id: Scalars['ID']
  /** The current status of the uploaded CSV file */
  status: SecurityCoachCsvUploadStatus
  /** The user who uploaded the CSV file */
  uploadedBy?: Maybe<UploadingUser>
}

export type UploadedCsvs = {
  __typename?: 'UploadedCsvs'
  /** A list of uploaded CSVs related to security mappings */
  nodes?: Maybe<Array<UploadedCsv>>
  /** Pagination details for navigating through the list of uploaded CSVs */
  pagination: Pagination
}

export type UploadingUser = {
  __typename?: 'UploadingUser'
  /** The email address of the uploading user */
  email?: Maybe<Scalars['String']>
  /** The first name of the uploading user */
  firstName?: Maybe<Scalars['String']>
  /** The unique identifier of the uploading user */
  id?: Maybe<Scalars['Int']>
  /** The last name of the uploading user */
  lastName?: Maybe<Scalars['String']>
}

/** A USB drive test campaign */
export type UsbCampaign = Identifier &
  Timestamps & {
    __typename?: 'UsbCampaign'
    auditLogDisplay?: Maybe<Scalars['String']>
    /** The completion time of the campaign, if applicable */
    completedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** A human-readable version of the campaign status */
    displayStatus?: Maybe<Scalars['String']>
    /** The types of files associated with the USB drive test campaign */
    fileTypes?: Maybe<Array<UsbCampaignFile>>
    /** Indicates if the USB stick is free or has been claimed */
    freeUst?: Maybe<Scalars['Boolean']>
    /** The geographical location associated with the campaign */
    geoLocation?: Maybe<UsbCampaignGeoLocation>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Location where the campaign is executed */
    location?: Maybe<Scalars['String']>
    /** The number of times macros have been enabled in docm files */
    macroEnabledCountDocm?: Maybe<Scalars['Int']>
    /** The number of times macros have been enabled in docx files */
    macroEnabledCountDocx?: Maybe<Scalars['Int']>
    /** The number of times macros have been enabled in pdf files */
    macroEnabledCountPdf?: Maybe<Scalars['Int']>
    /** The number of times macros have been enabled in ppsm files */
    macroEnabledCountPpsm?: Maybe<Scalars['Int']>
    /** The number of times macros have been enabled in pptx files */
    macroEnabledCountPptx?: Maybe<Scalars['Int']>
    /** The total number of times macros have been enabled in documents from USB sticks */
    macroEnabledCountTotal?: Maybe<Scalars['Int']>
    /** The number of times macros have been enabled in xlsm files */
    macroEnabledCountXlsm?: Maybe<Scalars['Int']>
    /** The number of times macros have been enabled in xlsx files */
    macroEnabledCountXlsx?: Maybe<Scalars['Int']>
    /** The name of the USB drive test campaign */
    name: Scalars['String']
    /** Any additional notes about the campaign */
    notes?: Maybe<Scalars['String']>
    /** The number of times docm files have been opened */
    openCountDocm?: Maybe<Scalars['Int']>
    /** The number of times docx files have been opened */
    openCountDocx?: Maybe<Scalars['Int']>
    /** The number of times pdf files have been opened */
    openCountPdf?: Maybe<Scalars['Int']>
    /** The number of times ppsm files have been opened */
    openCountPpsm?: Maybe<Scalars['Int']>
    /** The number of times pptx files have been opened */
    openCountPptx?: Maybe<Scalars['Int']>
    /** The number of times xlsm files have been opened */
    openCountXlsm?: Maybe<Scalars['Int']>
    /** The number of times xlsx files have been opened */
    openCountXlsx?: Maybe<Scalars['Int']>
    /** The total number of times USB sticks associated with the campaign have been opened */
    openedCountTotal?: Maybe<Scalars['Int']>
    /** A URL-friendly identifier for the campaign */
    slug?: Maybe<Scalars['String']>
    /** The start time of the campaign */
    startedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The current status of the USB drive test campaign */
    status: UsbCampaignStatuses
    /** The total number of failures associated with the USB drive test campaign */
    totalFailures: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The results associated with USB drive test campaign activities */
    usbCampaignResults?: Maybe<Array<UsbCampaignResult>>
  }

export type UsbCampaignAttributes = {
  /** The ID of the USB drive test */
  id?: Maybe<Scalars['Int']>
  /** The location of the USB drive test */
  location?: Maybe<Scalars['String']>
  /** The name of the USB drive test */
  name: Scalars['String']
  /** The notes for the USB drive test */
  notes?: Maybe<Scalars['String']>
  /** The status of the USB drive test */
  status?: Maybe<UsbCampaignStatuses>
}

/** A USB drive test campaign file type */
export type UsbCampaignFile = {
  __typename?: 'UsbCampaignFile'
  /** Metadata associated with the USB campaign file */
  meta: UsbCampaignFileTypeMeta
  /** The name of the USB drive test campaign file */
  name: Scalars['String']
}

export type UsbCampaignFileTypeMeta = {
  __typename?: 'UsbCampaignFileTypeMeta'
  /** The extension of the USB drive test campaign file */
  ext?: Maybe<Scalars['String']>
  /** The icon associated with the USB drive test campaign file */
  icon?: Maybe<Scalars['String']>
  /** The name identifier for the USB drive test campaign file */
  name?: Maybe<Scalars['String']>
}

/** Geolocation data for a USB drive test Campaign */
export type UsbCampaignGeoLocation = {
  __typename?: 'UsbCampaignGeoLocation'
  /** The data of the map used in the campaign */
  mapData: Scalars['String']
  /** The subtitle of the map used in the campaign */
  mapSubtitle: Scalars['String']
  /** The suffix of the map used in the campaign */
  mapSuffix: Scalars['String']
}

/** A USB drive test campaign result */
export type UsbCampaignResult = Identifier &
  Timestamps & {
    __typename?: 'UsbCampaignResult'
    /** The name of the computer where the file was accessed */
    computerName?: Maybe<Scalars['String']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The display name of the individual associated with the file interaction */
    displayName?: Maybe<Scalars['String']>
    /** The type of the file involved in the USB drive test campaign */
    fileType: Scalars['String']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The IP address from which the file interaction occurred */
    ipAddress?: Maybe<Scalars['String']>
    /** The geographical location of the IP address */
    ipLocation?: Maybe<Scalars['String']>
    /** Timestamp when macros were enabled in the document */
    macroEnabledAt?: Maybe<Scalars['ISO8601DateTime']>
    /** Timestamp when the file was first opened */
    openedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The username of the individual who interacted with the file */
    userName?: Maybe<Scalars['String']>
  }

export enum UsbCampaignStatuses {
  Completed = 'completed',
  Created = 'created',
  Started = 'started',
}

/** Autogenerated return type of UsbCampaignsCreate. */
export type UsbCampaignsCreatePayload = {
  __typename?: 'UsbCampaignsCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The created USB drive test */
  node?: Maybe<UsbCampaign>
}

export type UsbCampaignsCursor = {
  __typename?: 'UsbCampaignsCursor'
  /** The paginated nodes */
  nodes: Array<UsbCampaign>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of UsbCampaignsDestroy. */
export type UsbCampaignsDestroyPayload = {
  __typename?: 'UsbCampaignsDestroyPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The destroyed USB drive test */
  node?: Maybe<UsbCampaign>
}

/** Autogenerated return type of UsbCampaignsEnd. */
export type UsbCampaignsEndPayload = {
  __typename?: 'UsbCampaignsEndPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The ended USB drive test */
  node?: Maybe<UsbCampaign>
}

/** Autogenerated return type of UsbCampaignsRestart. */
export type UsbCampaignsRestartPayload = {
  __typename?: 'UsbCampaignsRestartPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The restarted USB drive test */
  node?: Maybe<UsbCampaign>
}

/** Autogenerated return type of UsbCampaignsStart. */
export type UsbCampaignsStartPayload = {
  __typename?: 'UsbCampaignsStartPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The started USB drive test */
  node?: Maybe<UsbCampaign>
}

/** Autogenerated return type of UsbCampaignsUpdate. */
export type UsbCampaignsUpdatePayload = {
  __typename?: 'UsbCampaignsUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<UsbCampaign>
}

/** User in the account */
export type User = Timestamps & {
  __typename?: 'User'
  account: Account
  accountDomain?: Maybe<Scalars['String']>
  accountId?: Maybe<Scalars['String']>
  accountLanguageSettings?: Maybe<AccountSettingsLanguageDefault>
  /** Determines if the user has admin privileges */
  admin: Scalars['Boolean']
  /** Admin locale settings of the user */
  adminLocale?: Maybe<Scalars['String']>
  /** Indicates if AIDA optional training is enabled for the user */
  aidaOptionalTrainingEnabled: Scalars['Boolean']
  /** Indicates if analytics are allowed for the user */
  allowAnalytics: Scalars['Boolean']
  /** Indicates if the user is already enrolled in a specific training campaign */
  alreadyEnrolled?: Maybe<Scalars['Boolean']>
  /** API tokens associated with the user */
  apiTokens?: Maybe<Array<ApiToken>>
  /** Indicates if the user can be archived */
  archivable: Scalars['Boolean']
  /** Indicates if the user is archived */
  archived?: Maybe<Scalars['Boolean']>
  /** The date and time when the user was archived */
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>
  auditLogDisplay?: Maybe<Scalars['String']>
  billingType?: Maybe<Scalars['String']>
  campaignRecipientsReportableActive: Array<PhishingCampaignRecipient>
  campaignRecipientsReportableDeliveredCount: Scalars['Int']
  /** Optional comment about the user */
  comment?: Maybe<Scalars['String']>
  /** Company name associated with the user's account */
  companyName?: Maybe<Scalars['String']>
  confirmationLink?: Maybe<Scalars['String']>
  /** The date and time when the confirmation link was sent to the user */
  confirmationSentAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The date and time when the user confirmed their account */
  confirmedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The date and time when the user account was created */
  createdAt: Scalars['ISO8601DateTime']
  /** Current Phish-prone Percentage of the user */
  currentPpp?: Maybe<Scalars['Float']>
  /** IP address of the user's current sign in */
  currentSignInIp?: Maybe<Scalars['String']>
  /** Custom date field 1 for the user */
  customDate1?: Maybe<Scalars['ISO8601Date']>
  /** Custom date field 2 for the user */
  customDate2?: Maybe<Scalars['ISO8601Date']>
  /** Custom text field 1 for the user */
  customField1?: Maybe<Scalars['String']>
  /** Custom text field 2 for the user */
  customField2?: Maybe<Scalars['String']>
  /** Custom text field 3 for the user */
  customField3?: Maybe<Scalars['String']>
  /** Custom text field 4 for the user */
  customField4?: Maybe<Scalars['String']>
  customHelpEnabled?: Maybe<Scalars['Boolean']>
  customHelpUrl?: Maybe<Scalars['String']>
  /** Delivery channels available to the user */
  deliveryChannels: Array<DeliveryChannel>
  /** Department of the user */
  department?: Maybe<Scalars['String']>
  deviceLinkToken?: Maybe<Scalars['String']>
  /** Indicates if non-SAML logins are disabled for the user */
  disableNonSamlLogins?: Maybe<Scalars['Boolean']>
  /** Division of the user */
  division?: Maybe<Scalars['String']>
  /** Domain associated with the user's email address */
  domain?: Maybe<Scalars['String']>
  /** Enrollment programs elected by the user */
  electedEnrollments?: Maybe<Array<Enrollment>>
  /** The email address of the user */
  email: Scalars['String']
  /** Email aliases associated with the user */
  emailAliases: Array<EmailAlias>
  /** Employee number of the user */
  employeeNumber?: Maybe<Scalars['String']>
  /** The start date of employment for the user */
  employeeStartDate?: Maybe<Scalars['ISO8601DateTime']>
  /** Language selections for enrollments made by the user */
  enrollmentLanguageSelections: Array<Scalars['String']>
  /** Counts of enrollments associated with the user */
  enrollmentsCount: UserEnrollmentsCount
  /** Phone extension number for the user */
  extension?: Maybe<Scalars['String']>
  /** The first name of the user */
  firstName?: Maybe<Scalars['String']>
  /** Gamification settings of the user */
  gamification?: Maybe<UserGamification>
  /** Indicates if Google Chat integration is enabled for the user */
  gchatEnabled?: Maybe<Scalars['Boolean']>
  /** IDs of the groups the user is a part of */
  groupIds: Array<Scalars['Int']>
  /** Groups that the user belongs to */
  groups: Array<Group>
  /** ID of the user */
  id: Scalars['Int']
  /** Identity provider data linked to the user */
  idpData?: Maybe<IdpData>
  /** Job title of the user */
  jobTitle?: Maybe<Scalars['String']>
  /** Knowledge refresher enrollments for the user */
  knowledgeRefresherEnrollments?: Maybe<Array<Enrollment>>
  /** Preferred language of the user */
  language?: Maybe<Scalars['String']>
  /** Language settings of the user */
  languageSettings?: Maybe<UserSettingsLanguages>
  /** The last name of the user */
  lastName?: Maybe<Scalars['String']>
  /** The date and time of the user's last sign-in */
  lastSignInAt?: Maybe<Scalars['ISO8601DateTime']>
  latestOutlookAddinActivity?: Maybe<OutlookAddinActivity>
  /** Indicates if LDAP is enabled for the user */
  ldapEnabled: Scalars['Boolean']
  /** LDAP ObjectGUID of the user */
  ldapObjectGuid?: Maybe<Scalars['String']>
  /** Messages related to the user's learning activities */
  learnerMessages?: Maybe<Array<LearnerMessage>>
  /** List of devices linked to the user's account */
  linkedDevices?: Maybe<Array<DeviceDetails>>
  /** Locale settings of the user */
  locale?: Maybe<Scalars['String']>
  /** Physical location of the user */
  location?: Maybe<Scalars['String']>
  /** Learning experiences that the user can continue */
  lxContinueLearning?: Maybe<Array<Enrollment>>
  /** Indicates if the learning experience tour is disabled for the user */
  lxDisableTour: Scalars['Boolean']
  /** Learning Experience recommendations for the user */
  lxRecommendations?: Maybe<Array<ModstoreRecommendation>>
  /** Email address of the user's manager */
  managerEmail?: Maybe<Scalars['String']>
  /** Name of the user's manager */
  managerName?: Maybe<Scalars['String']>
  /** Indicates if phishing training for managers is enabled */
  managerPhishingEnabled?: Maybe<Scalars['Boolean']>
  /** Reports related to the user as a manager */
  managerReport?: Maybe<ManagerReport>
  /** Indicates if the manager has access to view Risk Scores */
  managerRiskScoreEnabled?: Maybe<Scalars['Boolean']>
  /** Indicates if manager-specific training is enabled */
  managerTrainingEnabled?: Maybe<Scalars['Boolean']>
  /** Indicates if the manager has access to user information */
  managerUserInfoEnabled?: Maybe<Scalars['Boolean']>
  /** Mandatory enrollment programs for the user */
  mandatoryEnrollments?: Maybe<Array<Enrollment>>
  /** Membership details of the user */
  membership?: Maybe<Membership>
  /** Campaign message recipients linked to the user */
  messagesCampaignRecipients?: Maybe<Array<Recipient>>
  /** Indicates if messaging is enabled for the user */
  messagesEnabled?: Maybe<Scalars['Boolean']>
  /** Indicates if MFA is enabled for the user */
  mfaEnabled: Scalars['Boolean']
  /** Indicates if the mobile app is enabled for the user */
  mobileAppEnabled?: Maybe<Scalars['Boolean']>
  /** Mobile phone number of the user */
  mobilePhoneNumber?: Maybe<Scalars['String']>
  /** Optional enrollment programs for the user */
  optionalEnrollments?: Maybe<Array<Enrollment>>
  /** Indicates if optional training is enabled for the user */
  optionalTrainingEnabled: Scalars['Boolean']
  /** Organization of the user */
  organization?: Maybe<Scalars['String']>
  /** Date until which the user is marked as out of office */
  outOfOfficeUntil?: Maybe<Scalars['ISO8601Date']>
  outlookAddinActivityCount: Scalars['Int']
  /** Indicates if passless authentication is enabled for the user */
  passlessEnabled: Scalars['Boolean']
  /** Expiration time in seconds for the passless token */
  passlessExpiresIn: Scalars['Int']
  /** The date and time when the passless token was generated */
  passlessTokenGeneratedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Indicates if phishing protection is fully enabled for the user */
  phisherEnabled: Scalars['Boolean']
  /** Locale settings for phishing simulations for the user */
  phishingLocale?: Maybe<Scalars['String']>
  /** Phone number of the user */
  phoneNumber?: Maybe<Scalars['String']>
  popcornTheaterReduceMotion: Scalars['Boolean']
  /** Risk booster level assigned to the user */
  riskBooster?: Maybe<RiskBoosterLevels>
  /** Risk history data points for the user */
  riskHistories?: Maybe<Array<TimeData>>
  /** Risk Score associated with the user */
  riskScore?: Maybe<Scalars['Float']>
  /** Factors contributing to the user's risk score */
  riskScoreFactors?: Maybe<Array<Scalars['Int']>>
  /** Historical Risk Score data for the user */
  riskScoreHistories: Array<RiskScoreHistory>
  /** Role of the user */
  role: UserRoles
  /** Indicates if SAML authentication is enabled for the user */
  samlEnabled: Scalars['Boolean']
  /** Security Roles assigned to the user */
  securityRoles: Array<SecurityRole>
  /** Total number of sign-ins by the user */
  signInCount?: Maybe<Scalars['Int']>
  /** Indicates if Slack integration is enabled for the user */
  slackEnabled?: Maybe<Scalars['Boolean']>
  /** Statistical data related to the user */
  statistics?: Maybe<UserStatistics>
  /** Threshold for student account seat overage */
  studentAccountSeatOverageThreshold?: Maybe<Scalars['Int']>
  /** Number of Student Edition seats */
  studentEditionSeats?: Maybe<Scalars['Int']>
  /** Number of student users */
  studentUserCount?: Maybe<Scalars['Int']>
  subscriptionLevel?: Maybe<Scalars['String']>
  /** Indicates if Microsoft Teams integration is enabled for the user */
  teamsEnabled?: Maybe<Scalars['Boolean']>
  /** Timezone preference of the user */
  timeZone?: Maybe<TimeZones>
  /** Locale settings for training content for the user */
  trainingLocale?: Maybe<Scalars['String']>
  /** Training statistics of the user */
  trainingStats: TrainingStats
  /** The timestamp when the record was last updated */
  updatedAt: Scalars['ISO8601DateTime']
  /** URL to the user's profile */
  userUrl: Scalars['String']
}

/** User in the account */
export type UserAlreadyEnrolledArgs = {
  trainingCampaignId: Scalars['Int']
}

/** User in the account */
export type UserElectedEnrollmentsArgs = {
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}

/** User in the account */
export type UserLatestOutlookAddinActivityArgs = {
  activityType?: Maybe<OutlookAddinActivityActivityTypes>
}

/** User in the account */
export type UserLxRecommendationsArgs = {
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  query?: Maybe<Scalars['JSON']>
}

/** User in the account */
export type UserMembershipArgs = {
  groupId: Scalars['Int']
}

/** User in the account */
export type UserOptionalEnrollmentsArgs = {
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
}

/** User in the account */
export type UserOutlookAddinActivityCountArgs = {
  activityType?: Maybe<OutlookAddinActivityActivityTypes>
}

/** User in the account */
export type UserRiskScoreFactorsArgs = {
  date?: Maybe<Scalars['ISO8601Date']>
}

/** User in the account */
export type UserRiskScoreHistoriesArgs = {
  fullHistory?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of UserAcceptClickwrap. */
export type UserAcceptClickwrapPayload = {
  __typename?: 'UserAcceptClickwrapPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<User>
}

/** AIDA assignment count for a user */
export type UserAssignmentCount = {
  __typename?: 'UserAssignmentCount'
  email: Scalars['String']
  numberOfAssignments: Scalars['Int']
  userId: Scalars['Int']
}

export type UserAttributes = {
  /** The comment for the user */
  comment?: Maybe<Scalars['String']>
  /** The first custom date for the user */
  customDate1?: Maybe<Scalars['ISO8601Date']>
  /** The second custom date for the user */
  customDate2?: Maybe<Scalars['ISO8601Date']>
  /** The first custom field for the user */
  customField1?: Maybe<Scalars['String']>
  /** The second custom field for the user */
  customField2?: Maybe<Scalars['String']>
  /** The third custom field for the user */
  customField3?: Maybe<Scalars['String']>
  /** The fourth custom field for the user */
  customField4?: Maybe<Scalars['String']>
  /** The department of the user */
  department?: Maybe<Scalars['String']>
  /** The division of the user */
  division?: Maybe<Scalars['String']>
  /** The email of the user */
  email?: Maybe<Scalars['String']>
  /** The employee number of the user */
  employeeNumber?: Maybe<Scalars['String']>
  /** The employee start date of the user */
  employeeStartDate?: Maybe<Scalars['String']>
  /** The extension of the user */
  extension?: Maybe<Scalars['String']>
  /** The first name of the user */
  firstName?: Maybe<Scalars['String']>
  /** The IDP data of the user */
  idpData?: Maybe<UserIdpAttributes>
  /** The job title of the user */
  jobTitle?: Maybe<Scalars['String']>
  /** The language of the user */
  language?: Maybe<Scalars['String']>
  /** The language settings of the user */
  languageSettings?: Maybe<UserLanguages>
  /** The last name of the user */
  lastName?: Maybe<Scalars['String']>
  /** The LDAP enabled status of the user */
  ldapEnabled?: Maybe<Scalars['Boolean']>
  /** The locale of the user */
  locale?: Maybe<Scalars['String']>
  /** The location of the user */
  location?: Maybe<Scalars['String']>
  /** The manager email of the user */
  managerEmail?: Maybe<Scalars['String']>
  /** The manager name of the user */
  managerName?: Maybe<Scalars['String']>
  /** The MFA enabled status of the user */
  mfaEnabled?: Maybe<Scalars['Boolean']>
  /** The mobile phone number of the user, if applicable */
  mobilePhoneNumber?: Maybe<Scalars['String']>
  /** The organization of the user */
  organization?: Maybe<Scalars['String']>
  /** The out-of-office until date of the user */
  outOfOfficeUntil?: Maybe<Scalars['ISO8601Date']>
  /** The password of the user */
  password?: Maybe<Scalars['String']>
  /** The password confirmation of the user */
  passwordConfirmation?: Maybe<Scalars['String']>
  /** The PhishER enabled status of the user */
  phisherEnabled?: Maybe<Scalars['Boolean']>
  /** The phone number of the user */
  phoneNumber?: Maybe<Scalars['String']>
  /** The risk booster level of the user */
  riskBooster?: Maybe<RiskBoosterLevels>
  /** The role of the user */
  role?: Maybe<UserRoles>
  /** The time zone of the user */
  timeZone?: Maybe<TimeZones>
}

export type UserData = {
  __typename?: 'UserData'
  /** A collection of documents */
  docs?: Maybe<Array<Docs>>
}

export type UserDate = Identifier & {
  __typename?: 'UserDate'
  /** The boolean value to indicate condition state */
  boolean: Booleans
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The name of the date field for the group criterion */
  name: DateFields
  /** The timeframe for the date criterion */
  timeframe: Timeframe
}

export type UserDateArgument = {
  bool: Booleans
  field: DateFields
  timeframe: TimeframeArgument
}

/** Autogenerated return type of UserDeleteAll. */
export type UserDeleteAllPayload = {
  __typename?: 'UserDeleteAllPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Array<User>>
}

/** Autogenerated return type of UserDeleteByEmail. */
export type UserDeleteByEmailPayload = {
  __typename?: 'UserDeleteByEmailPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Array<User>>
}

export type UserDiscoveryTrendData = {
  __typename?: 'UserDiscoveryTrendData'
  /** An array of integers representing the trend data */
  data?: Maybe<Array<Scalars['Int']>>
  /** The start time for the trend data period */
  startTime?: Maybe<Scalars['String']>
}

/** A user enrollments count */
export type UserEnrollmentsCount = Identifier &
  Timestamps & {
    __typename?: 'UserEnrollmentsCount'
    /** The number of enrollments completed successfully */
    complete: Scalars['Int']
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The number of enrollments currently in progress */
    inProgress: Scalars['Int']
    /** The number of enrollments not started */
    notStarted: Scalars['Int']
    /** The number of enrollments that are past their due date */
    pastDue: Scalars['Int']
    /** The total number of enrollments */
    total: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

/** A user enrollments statistics */
export type UserEnrollmentsStatistics = Identifier &
  Timestamps & {
    __typename?: 'UserEnrollmentsStatistics'
    /** The number of enrollments that have been completed */
    complete: Scalars['Int']
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The number of enrollments currently in progress */
    inProgress: Scalars['Int']
    /** The last five enrollments */
    lastFiveEnrollments?: Maybe<Array<Enrollment>>
    /** The number of enrollments not yet started */
    notStarted: Scalars['Int']
    /** The number of enrollments that are past their due date */
    pastDue: Scalars['Int']
    /** The total number of enrollments */
    total: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

/** A user event */
export type UserEvent = {
  __typename?: 'UserEvent'
  /** The account ID associated with this user event */
  accountId: Scalars['Int']
  description?: Maybe<Scalars['String']>
  /** The type of the event */
  eventType: EventTypeUnion
  /** An external identifier for the user event if applicable */
  externalId?: Maybe<Scalars['String']>
  /** Indicates whether the event was flipped using PhishFlip */
  flipped?: Maybe<Scalars['Boolean']>
  /** The unique identifier of the user event */
  id: Scalars['String']
  /** Metadata associated with this user event */
  metadata: Metadata
  /** The date and time when the event occurred */
  occurredDate: Scalars['String']
  /** The risk level associated with this user event */
  risk: Risk
  /** The source from which the user event originated */
  source?: Maybe<Scalars['String']>
  /** The user associated with this event */
  user: EventUser
}

export type UserField = Identifier & {
  __typename?: 'UserField'
  /** Represents a boolean value for the criterion */
  boolean: Booleans
  /** The type of comparison to perform for the criterion */
  comparison: Comparisons
  /** Unique identifier for the object */
  id: Scalars['Int']
  /** The name of the field to apply the criterion */
  name: UserFields
  /** A list of values for the criterion comparison */
  values: Array<UserFieldValue>
}

export type UserFieldArgument = {
  bool: Booleans
  comparison: Comparisons
  name: UserFields
  values: Array<UserFieldValueArgument>
}

export type UserFieldValue = {
  __typename?: 'UserFieldValue'
  /** The unique identifier of the user field value */
  id: Scalars['String']
  /** The label of the user field value */
  label?: Maybe<Scalars['String']>
}

export type UserFieldValueArgument = {
  id: Scalars['String']
  label?: Maybe<Scalars['String']>
}

export enum UserFields {
  Comment = 'COMMENT',
  CompanyName = 'COMPANY_NAME',
  Country = 'COUNTRY',
  CustomField_1 = 'CUSTOM_FIELD_1',
  CustomField_2 = 'CUSTOM_FIELD_2',
  CustomField_3 = 'CUSTOM_FIELD_3',
  CustomField_4 = 'CUSTOM_FIELD_4',
  Department = 'DEPARTMENT',
  Division = 'DIVISION',
  Emails = 'EMAILS',
  EmployeeNumberLong = 'EMPLOYEE_NUMBER_LONG',
  EmployeeNumberString = 'EMPLOYEE_NUMBER_STRING',
  EmployeeType = 'EMPLOYEE_TYPE',
  Extension = 'EXTENSION',
  FirstName = 'FIRST_NAME',
  Groups = 'GROUPS',
  JobTitle = 'JOB_TITLE',
  Language = 'LANGUAGE',
  LastName = 'LAST_NAME',
  Location = 'LOCATION',
  MailNickname = 'MAIL_NICKNAME',
  ManagerEmail = 'MANAGER_EMAIL',
  ManagerName = 'MANAGER_NAME',
  MobilePhoneNumber = 'MOBILE_PHONE_NUMBER',
  OnPremisesSamAccountName = 'ON_PREMISES_SAM_ACCOUNT_NAME',
  OnPremisesSecurityIdentifier = 'ON_PREMISES_SECURITY_IDENTIFIER',
  Organization = 'ORGANIZATION',
  PhishPronePercentage = 'PHISH_PRONE_PERCENTAGE',
  PhoneNumber = 'PHONE_NUMBER',
  PrimaryEmail = 'PRIMARY_EMAIL',
  RiskBooster = 'RISK_BOOSTER',
  RiskScore = 'RISK_SCORE',
  Role = 'ROLE',
  UserPrincipalName = 'USER_PRINCIPAL_NAME',
}

/** The gamification for a user */
export type UserGamification = GamificationInterface & {
  __typename?: 'UserGamification'
  /** Indicates if badges are enabled */
  badges?: Maybe<Scalars['Boolean']>
  /** The badges earned by the user */
  badgesEarned?: Maybe<Array<Badges>>
  /** Indicates if badges are enabled */
  badgesEnabled?: Maybe<Scalars['Boolean']>
  /** List of badges that are enabled */
  badgesEnabledList: Array<Badges>
  /** List of badges that are enabled */
  badgesList?: Maybe<Array<Badge>>
  /** @deprecated To be deprecated once multiple leaderboards are supported */
  leaderboard?: Maybe<Leaderboard>
  /** The groups that are included in the leaderboard */
  leaderboardGroups: Array<Group>
  /** The time window for the leaderboard */
  leaderboardTimeWindow: LeaderboardPeriods
  /** @deprecated Please use the leaderboard field */
  leaderboardType?: Maybe<LeaderboardTypes>
  /** List of leaderboard types that are enabled */
  leaderboardTypes?: Maybe<Array<LeaderboardTypes>>
  /** List of leaderboards that are enabled */
  leaderboards?: Maybe<Array<LeaderboardNew>>
  /** Indicates if leaderboards are enabled */
  leaderboardsEnabled?: Maybe<Scalars['Boolean']>
}

export enum UserHasOutlookAddinActivityTypes {
  All = 'ALL',
  HasActivity = 'HAS_ACTIVITY',
  NoActivity = 'NO_ACTIVITY',
}

export type UserIdpAttributes = {
  companyName?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  employeeType?: Maybe<Scalars['String']>
  externalId?: Maybe<Scalars['String']>
  lastPasswordChangeDateTime?: Maybe<Scalars['ISO8601DateTime']>
  mailNickname?: Maybe<Scalars['String']>
  onPremisesSamAccountName?: Maybe<Scalars['String']>
  onPremisesSecurityIdentifier?: Maybe<Scalars['String']>
  userPrincipalName?: Maybe<Scalars['String']>
}

export type UserLanguages = {
  adminLocale?: Maybe<Scalars['String']>
  phishingLocale?: Maybe<Scalars['String']>
  trainingLocale?: Maybe<Scalars['String']>
}

export type UserMappingRecommendationData = {
  __typename?: 'UserMappingRecommendationData'
  /** List of AliasData associated with the User Mapping Recommendation */
  alias?: Maybe<Array<AliasData>>
  /** The unique ID of the User Mapping Recommendation Data */
  id?: Maybe<Scalars['String']>
  /** List of TransitData associated with the User Mapping Recommendation */
  transits?: Maybe<Array<TransitData>>
}

export type UserMappingRecommendationList = {
  __typename?: 'UserMappingRecommendationList'
  /** The list of user mapping recommendations */
  data: Array<UserMappingRecommendationData>
  /** The number of records after filtering */
  recordsFiltered: Scalars['Int']
  /** The total number of records available */
  recordsTotal: Scalars['Int']
}

export type UserMovesSettingAttributes = {
  autofixUserDupError?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['Int']>
}

export type UserPhishingCampaignsCsv = {
  __typename?: 'UserPhishingCampaignsCsv'
  /** A CSV representation of the user phishing campaign reports */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

/** Phishing statistics for a user */
export type UserPhishingStatistics = {
  __typename?: 'UserPhishingStatistics'
  /** The number of emails that have been reported */
  emailReported: Scalars['Int']
  /** The total number of successfully delivered items */
  totalDelivered: Scalars['Int']
  /** The total number of items that failed to be delivered */
  totalFailures: Scalars['Int']
  /** The total number of missed items */
  totalMisses: Scalars['Int']
}

/** User provisioning account settings */
export type UserProvisioning = Identifier &
  Timestamps & {
    __typename?: 'UserProvisioning'
    /** The user-specific token for ADI (Active Directory Integration) */
    adiToken?: Maybe<Scalars['String']>
    /** Indicates if the user is allowed to be provisioned */
    allowed?: Maybe<Scalars['Boolean']>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Indicates if user provisioning is enabled */
    enabled?: Maybe<Scalars['Boolean']>
    /** The Google Customer ID */
    googleCustomerId?: Maybe<Scalars['String']>
    /** The Google Subject Email */
    googleSubjectEmail?: Maybe<Scalars['String']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The last time SCIM data was cleared */
    scimLastClearedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The type of the last SCIM data clear */
    scimLastClearedType?: Maybe<ScimPurgeTypes>
    /** The type of the pending SCIM data clear */
    scimPendingClearType?: Maybe<ScimPurgeTypes>
    /** Indicates if a public key for SCIM exists */
    scimPublicKeyExists: Scalars['Boolean']
    /** The current SCIM status of user provisioning */
    scimStatus: UserProvisioningScimStatuses
    /** The timestamp when the SCIM status last changed */
    scimStatusChangedAt?: Maybe<Scalars['ISO8601DateTime']>
    /** The tenant URL for SCIM version 2 */
    scimV2TenantUrl: Scalars['String']
    /** Enabling this will show the AD Domain name in front of all synchronized groups. */
    showGroupDomain?: Maybe<Scalars['Boolean']>
    /** The source of user provisioning. Either adi, scim or scim_v2 */
    source: UserProvisioningSources
    /** Indicates if the user provisioning is in test mode */
    testMode?: Maybe<Scalars['Boolean']>
    /** A temporary SCIM token used during provisioning process */
    transientScimToken?: Maybe<Scalars['String']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type UserProvisioningAttributes = {
  allowed?: Maybe<Scalars['Boolean']>
  enabled?: Maybe<Scalars['Boolean']>
  scimVersion?: Maybe<Scalars['Int']>
  showGroupDomain?: Maybe<Scalars['Boolean']>
  source?: Maybe<UserProvisioningSources>
  testMode?: Maybe<Scalars['Boolean']>
}

/** User provisioning notification account settings */
export type UserProvisioningNotification = Identifier &
  Timestamps & {
    __typename?: 'UserProvisioningNotification'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The email address to be notified */
    emailToNotify?: Maybe<Scalars['String']>
    /** Indicates if the notification is enabled */
    enabled: Scalars['Boolean']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The duration of the timeframe for notifications */
    timeframeDuration?: Maybe<Scalars['Int']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export type UserProvisioningNotificationAttributes = {
  emailToNotify?: Maybe<Scalars['String']>
  enabled?: Maybe<Scalars['Boolean']>
  timeframeDuration?: Maybe<Scalars['Int']>
}

export enum UserProvisioningScimStatuses {
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
  Processing = 'PROCESSING',
}

export enum UserProvisioningSources {
  Adi = 'ADI',
  Google = 'GOOGLE',
  Scim = 'SCIM',
  ScimV2 = 'SCIM_V2',
}

export type UserReportCursor = {
  __typename?: 'UserReportCursor'
  /** The paginated nodes */
  nodes: Array<User>
  /** Information about the current paginated set */
  pagination: Pagination
}

export enum UserReportTypes {
  NotSignedUp = 'NOT_SIGNED_UP',
  SignedUp = 'SIGNED_UP',
}

export type UserRiskScore = {
  __typename?: 'UserRiskScore'
  /** Timestamp of the user’s last access to the account */
  accountAccess?: Maybe<Scalars['ISO8601DateTime']>
  /** Unique identifier of the user’s account */
  accountId: Scalars['Int']
  /** Name of the user’s account */
  accountName: Scalars['String']
  /** Email address of the user */
  email: Scalars['String']
  /** List of groups the user is a member of */
  groupNames: Array<Scalars['String']>
  /** Unique identifier of the user */
  id: Scalars['Int']
  /** Name of the user */
  name?: Maybe<Scalars['String']>
  /** Calculated Risk Score of the user */
  riskScore: Scalars['Float']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

/** Current user's risk statistics */
export type UserRiskStatistics = Identifier &
  Timestamps & {
    __typename?: 'UserRiskStatistics'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** A collection of risk history data points */
    riskHistories?: Maybe<Array<TimeData>>
    /** The current Risk Score of the user */
    riskScore?: Maybe<Scalars['Float']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export enum UserRoles {
  Admin = 'ADMIN',
  Student = 'STUDENT',
  User = 'USER',
}

export enum UserSearchTypes {
  All = 'ALL',
  Email = 'EMAIL',
  EmailAlias = 'EMAIL_ALIAS',
  FirstAndLastName = 'FIRST_AND_LAST_NAME',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
}

/** Autogenerated return type of UserSetDefaultExecReportsDashboard. */
export type UserSetDefaultExecReportsDashboardPayload = {
  __typename?: 'UserSetDefaultExecReportsDashboardPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<PartnerSettingsPreferences>
}

/** Autogenerated return type of UserSetDefaultPiqDashboard. */
export type UserSetDefaultPiqDashboardPayload = {
  __typename?: 'UserSetDefaultPiqDashboardPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<UserSettingsPreferences>
}

/** User languages */
export type UserSettingsLanguages = {
  __typename?: 'UserSettingsLanguages'
  /** Language locale for the admin UI */
  adminLocale?: Maybe<Scalars['String']>
  /** The most recent language setting sent from the browser */
  lastAcceptLanguageHeader?: Maybe<Scalars['String']>
  /** Language locale set at the operating system level */
  osLocale?: Maybe<Scalars['String']>
  /** Language locale used for PSTs */
  phishingLocale?: Maybe<Scalars['String']>
  /** Language locale used for security training materials */
  trainingLocale?: Maybe<Scalars['String']>
}

/** User preferences */
export type UserSettingsPreferences = {
  __typename?: 'UserSettingsPreferences'
  /** The default dashboard for executive reports settings */
  defaultExecReportsDashboard?: Maybe<ExecReportsExecReportsDashboard>
  /** The default dashboard for PasswordIQ settings */
  defaultPiqDashboard?: Maybe<PasswordIqPiqDashboard>
}

export type UserSignInData = {
  __typename?: 'UserSignInData'
  /** The name of the user */
  name: Scalars['String']
  /** The number of times the user has not signed in */
  notSignedIn: Scalars['Int']
  /** The number of times the user has signed in */
  signedIn: Scalars['Int']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type UserSignIns = {
  __typename?: 'UserSignIns'
  /** A CSV format of the user sign-in data */
  csv: Scalars['String']
  /** A series of user sign-in data reports */
  series: Array<UserSignInData>
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export enum UserSortFields {
  AddedOn = 'ADDED_ON',
  ArchivedAt = 'ARCHIVED_AT',
  CellPhone = 'CELL_PHONE',
  ConfirmationSentAt = 'CONFIRMATION_SENT_AT',
  ConfirmedAt = 'CONFIRMED_AT',
  CreatedAt = 'CREATED_AT',
  CurrentPpp = 'CURRENT_PPP',
  CustomDate_1 = 'CUSTOM_DATE_1',
  CustomDate_2 = 'CUSTOM_DATE_2',
  CustomField_1 = 'CUSTOM_FIELD_1',
  CustomField_2 = 'CUSTOM_FIELD_2',
  CustomField_3 = 'CUSTOM_FIELD_3',
  CustomField_4 = 'CUSTOM_FIELD_4',
  Department = 'DEPARTMENT',
  Division = 'DIVISION',
  EmployeeNumber = 'EMPLOYEE_NUMBER',
  Extension = 'EXTENSION',
  FirstName = 'FIRST_NAME',
  GchatEnabled = 'GCHAT_ENABLED',
  JobTitle = 'JOB_TITLE',
  Language = 'LANGUAGE',
  LastName = 'LAST_NAME',
  LastSignInAt = 'LAST_SIGN_IN_AT',
  LdapEnabled = 'LDAP_ENABLED',
  Location = 'LOCATION',
  ManagerEmail = 'MANAGER_EMAIL',
  ManagerName = 'MANAGER_NAME',
  MfaEnabled = 'MFA_ENABLED',
  Organization = 'ORGANIZATION',
  PhoneNumber = 'PHONE_NUMBER',
  RiskBooster = 'RISK_BOOSTER',
  RiskScore = 'RISK_SCORE',
  SlackEnabled = 'SLACK_ENABLED',
  StartDate = 'START_DATE',
  TeamsEnabled = 'TEAMS_ENABLED',
  User = 'USER',
}

export type UserStatesSort = {
  direction: SortDirections
  field: SortFields
}

/** Current user's statistics */
export type UserStatistics = Identifier &
  Timestamps & {
    __typename?: 'UserStatistics'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Statistics related to the enrollments of the user */
    enrollmentsStatistics?: Maybe<UserEnrollmentsStatistics>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Statistics related to the phishing attempts targeting the user */
    phishingStatistics?: Maybe<UserPhishingStatistics>
    /** Statistics related to the risk profile of the user */
    riskStatistics?: Maybe<UserRiskStatistics>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

export enum UserStatusFilters {
  Active = 'ACTIVE',
  All = 'ALL',
  Archived = 'ARCHIVED',
}

export type UserTrainingCampaignsCsv = {
  __typename?: 'UserTrainingCampaignsCsv'
  /** The CSV representation of user training campaigns */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

export type UserTrainingCampaignsCursor = {
  __typename?: 'UserTrainingCampaignsCursor'
  /** The paginated nodes */
  nodes: Array<TrainingCampaign>
  /** Information about the current paginated set */
  pagination: Pagination
}

export enum UserTypeFilters {
  Admin = 'ADMIN',
  AdminOrSr = 'ADMIN_OR_SR',
  All = 'ALL',
  Student = 'STUDENT',
  User = 'USER',
}

/** Users account settings */
export type Users = Identifier &
  Timestamps & {
    __typename?: 'Users'
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Disables automatic generation of passless email */
    disablePasslessEmailAutogen: Scalars['Boolean']
    /** Setting for enforcing MFA */
    forceMfa: ForceMfaOptions
    /** Requires users to change their password upon first login */
    forcePasswordChangeOnFirstLogin?: Maybe<Scalars['Boolean']>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** Defines how many days a device will be remembered for MFA */
    mfaRememberDeviceDays?: Maybe<Scalars['Int']>
    /** Enables or disables remembering the device for MFA */
    mfaRememberDeviceEnabled?: Maybe<Scalars['Boolean']>
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

/** Autogenerated return type of UsersAddToGroups. */
export type UsersAddToGroupsPayload = {
  __typename?: 'UsersAddToGroupsPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The users that were added to the groups */
  node?: Maybe<Array<User>>
}

/** Autogenerated return type of UsersArchive. */
export type UsersArchivePayload = {
  __typename?: 'UsersArchivePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The archived users */
  node?: Maybe<Array<User>>
}

export type UsersAttributes = {
  disablePasslessEmailAutogen?: Maybe<Scalars['Boolean']>
  forceMfa?: Maybe<ForceMfaOptions>
  forcePasswordChangeOnFirstLogin?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['Int']>
  mfaRememberDeviceDays?: Maybe<Scalars['Int']>
  mfaRememberDeviceEnabled?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of UsersBulkArchive. */
export type UsersBulkArchivePayload = {
  __typename?: 'UsersBulkArchivePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The archived users */
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of UsersBulkChangeDomainEmails. */
export type UsersBulkChangeDomainEmailsPayload = {
  __typename?: 'UsersBulkChangeDomainEmailsPayload'
  errors?: Maybe<Array<Error>>
  node: Array<Scalars['Int']>
}

/** Autogenerated return type of UsersBulkChangeDomain. */
export type UsersBulkChangeDomainPayload = {
  __typename?: 'UsersBulkChangeDomainPayload'
  errors?: Maybe<Array<Error>>
  node: Scalars['Int']
}

/** Autogenerated return type of UsersBulkDelete. */
export type UsersBulkDeletePayload = {
  __typename?: 'UsersBulkDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The deleted users */
  node?: Maybe<Scalars['Boolean']>
}

export type UsersByDomain = {
  __typename?: 'UsersByDomain'
  /** The limit on the number of users to return */
  limit?: Maybe<Scalars['Int']>
  /** The token for the next page of users */
  nextPageToken?: Maybe<Scalars['String']>
  /** A list of Gchat users */
  users?: Maybe<Array<GchatUser>>
}

/** Autogenerated return type of UsersCreate. */
export type UsersCreatePayload = {
  __typename?: 'UsersCreatePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The user that was created */
  node?: Maybe<User>
}

/** Autogenerated return type of UsersCreateUpsideDown. */
export type UsersCreateUpsideDownPayload = {
  __typename?: 'UsersCreateUpsideDownPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of UsersCsvImport. */
export type UsersCsvImportPayload = {
  __typename?: 'UsersCsvImportPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
}

export type UsersCursor = {
  __typename?: 'UsersCursor'
  /** The paginated nodes */
  nodes: Array<User>
  /** Information about the current paginated set */
  pagination: Pagination
}

/** Autogenerated return type of UsersDeleteMemberships. */
export type UsersDeleteMembershipsPayload = {
  __typename?: 'UsersDeleteMembershipsPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The users that were removed from the group */
  node?: Maybe<Array<User>>
}

/** Autogenerated return type of UsersDelete. */
export type UsersDeletePayload = {
  __typename?: 'UsersDeletePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The deleted users */
  node?: Maybe<Array<User>>
}

/** Autogenerated return type of UsersEdit. */
export type UsersEditPayload = {
  __typename?: 'UsersEditPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The updated user */
  node?: Maybe<User>
}

/** Autogenerated return type of UsersEditProfile. */
export type UsersEditProfilePayload = {
  __typename?: 'UsersEditProfilePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<User>
}

/** Autogenerated return type of UsersEditUpsideDown. */
export type UsersEditUpsideDownPayload = {
  __typename?: 'UsersEditUpsideDownPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of UsersEventResync. */
export type UsersEventResyncPayload = {
  __typename?: 'UsersEventResyncPayload'
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of UsersGrantAdmin. */
export type UsersGrantAdminPayload = {
  __typename?: 'UsersGrantAdminPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The users that were granted admin priviledges */
  node?: Maybe<Array<User>>
}

export type UsersList = {
  __typename?: 'UsersList'
  /** The CSV representation of the users list */
  csv: Scalars['String']
  /** The time period for the report */
  timeframe?: Maybe<ReportTimeframe>
}

/** A users merge preview */
export type UsersMergePreview = Identifier &
  Timestamps & {
    __typename?: 'UsersMergePreview'
    /** Recipient details for phishing campaigns the user is involved in */
    campaignRecipients?: Maybe<Array<PhishingCampaignRecipient>>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** The display name of the user combined with their email */
    displayNameWithEmail?: Maybe<Scalars['String']>
    /** Enrollment details for the user */
    enrollments?: Maybe<Array<Enrollment>>
    /** List of errors encountered during the user merge preview process */
    errors?: Maybe<Array<Error>>
    /** The groups that the user is a part of */
    groups?: Maybe<Array<Group>>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
  }

/** Autogenerated return type of UsersMergeProcess. */
export type UsersMergeProcessPayload = {
  __typename?: 'UsersMergeProcessPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of UsersQuickImport. */
export type UsersQuickImportPayload = {
  __typename?: 'UsersQuickImportPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The users that were imported */
  nodes?: Maybe<Array<User>>
}

/** Autogenerated return type of UsersResendConfirmationEmail. */
export type UsersResendConfirmationEmailPayload = {
  __typename?: 'UsersResendConfirmationEmailPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of UsersRevokeAdmin. */
export type UsersRevokeAdminPayload = {
  __typename?: 'UsersRevokeAdminPayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The users that were revoked admin priviledges */
  node?: Maybe<Array<User>>
}

/** Autogenerated return type of UsersSendMobileSignInLinkEmail. */
export type UsersSendMobileSignInLinkEmailPayload = {
  __typename?: 'UsersSendMobileSignInLinkEmailPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Scalars['Boolean']>
}

/** Autogenerated return type of UsersUnarchive. */
export type UsersUnarchivePayload = {
  __typename?: 'UsersUnarchivePayload'
  /** Errors encountered during the mutation */
  errors?: Maybe<Array<Error>>
  /** The unarchived users */
  node?: Maybe<Array<User>>
}

/** Autogenerated return type of UstCreate. */
export type UstCreatePayload = {
  __typename?: 'UstCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<UsbCampaign>
}

/** Autogenerated return type of UstStart. */
export type UstStartPayload = {
  __typename?: 'UstStartPayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<UsbCampaign>
}

export type ValidateUserMappingData = {
  __typename?: 'ValidateUserMappingData'
  /** List of error messages if the validation fails */
  errors?: Maybe<Array<Scalars['String']>>
  /** Indicates if the user mapping data validation was successful */
  success?: Maybe<Scalars['Boolean']>
}

export type VendorActivityData = {
  __typename?: 'VendorActivityData'
  /** A list of active vendors */
  activeVendors?: Maybe<Array<Scalars['String']>>
  /** A list of inactive vendors */
  inactiveVendors?: Maybe<Array<Scalars['String']>>
  /** A list of vendors that are integrated into the system */
  integratedVendors?: Maybe<Array<Scalars['String']>>
}

export type VendorAlert = {
  __typename?: 'VendorAlert'
  /** The type of alert raised for a vendor */
  alertType: Scalars['String']
  /** The timestamp when the alert was raised */
  alertedAt: Scalars['ISO8601DateTime']
  /** The timestamp when the vendor alert was created */
  createdAt: Scalars['ISO8601DateTime']
  /** The timestamp when the alert was dismissed, if it was dismissed */
  dismissedAt?: Maybe<Scalars['ISO8601DateTime']>
  /** The unique identifier of the vendor alert */
  id: Scalars['Int']
  /** The timestamp when the vendor alert was last updated */
  updatedAt: Scalars['ISO8601DateTime']
  /** The name of the vendor associated with the alert */
  vendor: Scalars['String']
}

export type VendorDetails = {
  __typename?: 'VendorDetails'
  /** The display name of the vendor */
  displayName?: Maybe<Scalars['String']>
  /** The unique identifier of the vendor */
  id: Scalars['Int']
  /** The type of log associated with the vendor */
  logType: VendorLog
  /** The code name of the vendor */
  vendorCodeName: Vendors
  /** The type of the vendor */
  vendorType?: Maybe<Scalars['String']>
}

export enum VendorLog {
  Email = 'email',
  Endpoint = 'endpoint',
  Web = 'web',
}

export type VendorRegistrationErrors = {
  __typename?: 'VendorRegistrationErrors'
  /** A descriptive message of the error encountered during vendor registration */
  errorMessage?: Maybe<Scalars['String']>
  /** The type of error encountered during vendor registration */
  errorType?: Maybe<Scalars['String']>
}

export type VendorRegistrationStatus = {
  __typename?: 'VendorRegistrationStatus'
  /** Indicates whether the vendor is fully configured */
  configured: Scalars['Boolean']
  /** The creation date and time of the vendor registration status */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>
  /** Errors encountered during the vendor registration process */
  errors?: Maybe<VendorRegistrationErrors>
  /** Unique identifier of the vendor registration status */
  id?: Maybe<Scalars['Int']>
  /** Name of the vendor registration status */
  name?: Maybe<Scalars['String']>
  /** Indicates whether an update is required for the vendor configuration */
  updateRequired: Scalars['Boolean']
}

export enum VendorStatus {
  Invalid = 'invalid',
  InvalidCredentials = 'invalid_credentials',
  Valid = 'valid',
}

export type VendorStatusData = {
  __typename?: 'VendorStatusData'
  /** The API key associated with the vendor */
  apiKey?: Maybe<Scalars['String']>
  /** The version of the API used by the vendor */
  apiVersion?: Maybe<Scalars['String']>
  /** The unique identifier of the vendor */
  id?: Maybe<Scalars['Int']>
  /** The current status of the vendor */
  status?: Maybe<VendorStatus>
}

export enum Vendors {
  BitdefenderGravityzone = 'bitdefender__gravityzone',
  Carbonblack = 'carbonblack',
  CiscoEsa = 'cisco__esa',
  CiscoUmbrella = 'cisco__umbrella',
  CloudflareArea1 = 'cloudflare__area1',
  CloudflareZeroTrust = 'cloudflare__zero_trust',
  Code42 = 'code42',
  Crowdstrike = 'crowdstrike',
  Cylance = 'cylance',
  FortinetFortigatecloud = 'fortinet__fortigatecloud',
  GoogleDrive = 'google__drive',
  GoogleGmail = 'google__gmail',
  GoogleIam = 'google__iam',
  Knowbe4Kmsat = 'knowbe4__kmsat',
  Malwarebytes = 'malwarebytes',
  MicrosoftAad = 'microsoft__aad',
  MicrosoftDefenderAtp = 'microsoft__defender_atp',
  MicrosoftMcas = 'microsoft__mcas',
  MicrosoftOffice365 = 'microsoft__office365',
  MimecastEmailSecurity = 'mimecast__email_security',
  Netskope = 'netskope',
  Okta = 'okta',
  PaloAltoNgfw = 'palo_alto__ngfw',
  Proofpoint = 'proofpoint',
  Sentinelone = 'sentinelone',
  SonicwallCaptureclient = 'sonicwall__captureclient',
  Sophos = 'sophos',
  Zscaler = 'zscaler',
}

/** A webhook definition */
export type Webhook = Identifier &
  Timestamps & {
    __typename?: 'Webhook'
    /** The authorization method used for the webhook */
    authMethod?: Maybe<AuthMethod>
    /** The timestamp when the record was created */
    createdAt: Scalars['ISO8601DateTime']
    /** Custom data to be sent with each webhook call */
    customData: Array<KeyValue>
    /** Custom headers to be included in each webhook call */
    customHeaders: Array<KeyValue>
    /** A comma-separated list of email addresses to notify on error */
    errorRecipients?: Maybe<Scalars['String']>
    /** A list of events the webhook is subscribed to */
    events: Array<Event>
    /** Unique identifier for the object */
    id: Scalars['Int']
    /** The name of the webhook */
    name: Scalars['String']
    /** Indicates if notifications should be sent on error */
    notifyOnError: Scalars['Boolean']
    /** The current status of the webhook */
    status: WebhookStatuses
    /** The timestamp when the record was last updated */
    updatedAt: Scalars['ISO8601DateTime']
    /** The URL the webhook will send data to */
    url: Scalars['String']
  }

export enum WebhookStatuses {
  Active = 'active',
  Disabled = 'disabled',
  Inactive = 'inactive',
}

export type WebhooksAuthApiKeyAttributes = {
  key: Scalars['String']
  type: ApiKeyTypes
  value: Scalars['String']
}

export type WebhooksAuthBasicAttributes = {
  password: Scalars['String']
  username: Scalars['String']
}

export type WebhooksAuthBearerAttributes = {
  token: Scalars['String']
}

export type WebhooksCreateAttributes = {
  apiKey?: Maybe<WebhooksAuthApiKeyAttributes>
  basic?: Maybe<WebhooksAuthBasicAttributes>
  bearer?: Maybe<WebhooksAuthBearerAttributes>
  customData: Array<WebhooksKeyValue>
  customHeaders: Array<WebhooksKeyValue>
  errorRecipients?: Maybe<Scalars['String']>
  events: Array<Scalars['CoercedInt']>
  name: Scalars['String']
  notifyOnError?: Maybe<Scalars['Boolean']>
  status: WebhookStatuses
  url: Scalars['Url']
}

/** Autogenerated return type of WebhooksCreate. */
export type WebhooksCreatePayload = {
  __typename?: 'WebhooksCreatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Webhook>
}

/** Autogenerated return type of WebhooksDelete. */
export type WebhooksDeletePayload = {
  __typename?: 'WebhooksDeletePayload'
  deleted?: Maybe<Scalars['Boolean']>
}

export type WebhooksKeyValue = {
  key: Scalars['String']
  value: Scalars['String']
}

/** Autogenerated return type of WebhooksUpdate. */
export type WebhooksUpdatePayload = {
  __typename?: 'WebhooksUpdatePayload'
  errors?: Maybe<Array<Error>>
  node?: Maybe<Webhook>
}

export enum Weekdays {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
}

export enum WidgetDetectionTypes {
  AdPwClearText = 'AD_PW_CLEAR_TEXT',
  AdPwEmpty = 'AD_PW_EMPTY',
  AdPwFoundInBreach = 'AD_PW_FOUND_IN_BREACH',
  AdPwNeverExpires = 'AD_PW_NEVER_EXPIRES',
  AdPwNotReqd = 'AD_PW_NOT_REQD',
  AdPwShared = 'AD_PW_SHARED',
  AdPwWeak = 'AD_PW_WEAK',
  AdUserAesEncryptionNotSet = 'AD_USER_AES_ENCRYPTION_NOT_SET',
  AdUserDesOnlyEncryption = 'AD_USER_DES_ONLY_ENCRYPTION',
  AdUserHasPreauthentication = 'AD_USER_HAS_PREAUTHENTICATION',
  AdUserUsesLmHash = 'AD_USER_USES_LM_HASH',
  AllDetectionTypes = 'ALL_DETECTION_TYPES',
}

export type Workspace = {
  __typename?: 'Workspace'
  /** The unique identifier of the Slack workspace */
  id?: Maybe<Scalars['String']>
  /** The name of the Slack workspace */
  workspace?: Maybe<Scalars['String']>
}

export type ZscalerKeys = {
  __typename?: 'ZscalerKeys'
  /** The gateway key for Zscaler access */
  gatewayKey?: Maybe<Scalars['String']>
  /** The organization key for Zscaler configuration */
  orgKey?: Maybe<Scalars['String']>
}

export type BadgePropertiesFragment = {
  __typename?: 'Badge'
  acknowledgedAt?: Maybe<any>
  description: string
  earnedAt?: Maybe<any>
  icon: string
  id?: Maybe<number>
  title: string
  type?: Maybe<string>
  viewedAt?: Maybe<any>
}

export type CcmItemPropertiesFragment = {
  __typename?: 'CcmItem'
  uuid: string
  title: string
  description?: Maybe<string>
  type: string
  expectedDurationInMinutes?: Maybe<number>
  publishedAt?: Maybe<any>
  artwork: { __typename?: 'Artwork'; coverImage?: Maybe<string> }
  translations: Array<{
    __typename?: 'Translation'
    title?: Maybe<string>
    description?: Maybe<string>
    language?: Maybe<{
      __typename?: 'Language'
      code: string
      name: string
      icon: string
    }>
    activePackage?: Maybe<{
      __typename?: 'Package'
      launchPath?: Maybe<string>
    }>
  }>
}

export type EnrollmentPropertiesFragment = {
  __typename?: 'Enrollment'
  id: number
  lastUsedLanguage?: Maybe<string>
  createdAt: any
  state?: Maybe<string>
  type: EnrollmentTypes
  expiresAt?: Maybe<any>
  policyAcknowledgedAt?: Maybe<any>
  completedAt?: Maybe<any>
  selectedLanguage?: Maybe<string>
  timeSpentInSeconds?: Maybe<string>
  user?: Maybe<{ __typename?: 'User'; id: number; companyName?: Maybe<string> }>
  trainingCampaign?: Maybe<{
    __typename?: 'TrainingCampaign'
    id: number
    name: string
    allowSurveys: boolean
    allowSurveyComments: boolean
  }>
  enrollmentItem:
    | {
        __typename: 'KnowledgeRefresher'
        storeItem?: Maybe<
          | { __typename: 'CcmItem' }
          | {
              __typename: 'StoreItem'
              uuid: string
              title: string
              description?: Maybe<string>
              type: string
              expectedDurationInMinutes?: Maybe<number>
              publishedAt?: Maybe<any>
              publisher?: Maybe<{ __typename?: 'Publisher'; name: string }>
              artwork: {
                __typename?: 'Artwork'
                coverImage?: Maybe<string>
                headerImage?: Maybe<string>
              }
              contentType?: Maybe<{
                __typename?: 'Content'
                icon?: Maybe<string>
                name?: Maybe<string>
                translationKey: string
              }>
              topics?: Maybe<
                Array<{
                  __typename?: 'Topic'
                  translationKey?: Maybe<string>
                  topicCategory?: Maybe<{
                    __typename?: 'TopicCategory'
                    translationKey?: Maybe<string>
                  }>
                }>
              >
              translations: Array<{
                __typename?: 'Translation'
                title?: Maybe<string>
                description?: Maybe<string>
                language?: Maybe<{
                  __typename?: 'Language'
                  code: string
                  name: string
                  icon: string
                }>
                activePackage?: Maybe<{
                  __typename?: 'Package'
                  launchPath?: Maybe<string>
                }>
              }>
              subscriptions?: Maybe<
                Array<{ __typename?: 'Subscription'; code: string }>
              >
            }
        >
      }
    | {
        __typename: 'Policy'
        title: string
        id: number
        description?: Maybe<string>
        minimumTime?: Maybe<number>
        publishedAt?: Maybe<any>
        type: PolicyTypes
        assets: Array<{
          __typename?: 'PolicyAsset'
          language: Languages
          url?: Maybe<string>
          acceptanceText: string
        }>
        translations: Array<{
          __typename?: 'Translation'
          title?: Maybe<string>
          description?: Maybe<string>
          language?: Maybe<{
            __typename?: 'Language'
            code: string
            name: string
            icon: string
          }>
          activePackage?: Maybe<{
            __typename?: 'Package'
            launchPath?: Maybe<string>
          }>
        }>
      }
    | {
        __typename: 'PurchasedCourse'
        userHasToAcknowledgePolicy?: Maybe<boolean>
        policyLocation?: Maybe<string>
        contentSource?: Maybe<string>
        storeItem?: Maybe<
          | {
              __typename: 'CcmItem'
              uuid: string
              title: string
              description?: Maybe<string>
              type: string
              expectedDurationInMinutes?: Maybe<number>
              publishedAt?: Maybe<any>
              artwork: { __typename?: 'Artwork'; coverImage?: Maybe<string> }
              translations: Array<{
                __typename?: 'Translation'
                title?: Maybe<string>
                description?: Maybe<string>
                language?: Maybe<{
                  __typename?: 'Language'
                  code: string
                  name: string
                  icon: string
                }>
                activePackage?: Maybe<{
                  __typename?: 'Package'
                  launchPath?: Maybe<string>
                }>
              }>
            }
          | {
              __typename: 'StoreItem'
              uuid: string
              title: string
              description?: Maybe<string>
              type: string
              expectedDurationInMinutes?: Maybe<number>
              publishedAt?: Maybe<any>
              publisher?: Maybe<{ __typename?: 'Publisher'; name: string }>
              artwork: {
                __typename?: 'Artwork'
                coverImage?: Maybe<string>
                headerImage?: Maybe<string>
              }
              contentType?: Maybe<{
                __typename?: 'Content'
                icon?: Maybe<string>
                name?: Maybe<string>
                translationKey: string
              }>
              topics?: Maybe<
                Array<{
                  __typename?: 'Topic'
                  translationKey?: Maybe<string>
                  topicCategory?: Maybe<{
                    __typename?: 'TopicCategory'
                    translationKey?: Maybe<string>
                  }>
                }>
              >
              translations: Array<{
                __typename?: 'Translation'
                title?: Maybe<string>
                description?: Maybe<string>
                language?: Maybe<{
                  __typename?: 'Language'
                  code: string
                  name: string
                  icon: string
                }>
                activePackage?: Maybe<{
                  __typename?: 'Package'
                  launchPath?: Maybe<string>
                }>
              }>
              subscriptions?: Maybe<
                Array<{ __typename?: 'Subscription'; code: string }>
              >
            }
        >
      }
}

export type EnrollmentPropertiesManagerFragment = {
  __typename?: 'Enrollment'
  id: number
  lastUsedLanguage?: Maybe<string>
  createdAt: any
  state?: Maybe<string>
  type: EnrollmentTypes
  expiresAt?: Maybe<any>
  policyAcknowledgedAt?: Maybe<any>
  completedAt?: Maybe<any>
  selectedLanguage?: Maybe<string>
  timeSpentInSeconds?: Maybe<string>
  user?: Maybe<{ __typename?: 'User'; id: number }>
  trainingCampaign?: Maybe<{ __typename?: 'TrainingCampaign'; id: number }>
  enrollmentItem:
    | { __typename: 'KnowledgeRefresher' }
    | {
        __typename: 'Policy'
        title: string
        id: number
        description?: Maybe<string>
        minimumTime?: Maybe<number>
        publishedAt?: Maybe<any>
        type: PolicyTypes
        assets: Array<{
          __typename?: 'PolicyAsset'
          language: Languages
          url?: Maybe<string>
          acceptanceText: string
        }>
        translations: Array<{
          __typename?: 'Translation'
          title?: Maybe<string>
          description?: Maybe<string>
          language?: Maybe<{
            __typename?: 'Language'
            code: string
            name: string
            icon: string
          }>
          activePackage?: Maybe<{
            __typename?: 'Package'
            launchPath?: Maybe<string>
          }>
        }>
      }
    | {
        __typename: 'PurchasedCourse'
        userHasToAcknowledgePolicy?: Maybe<boolean>
        policyLocation?: Maybe<string>
        contentSource?: Maybe<string>
        storeItem?: Maybe<
          | {
              __typename: 'CcmItem'
              uuid: string
              title: string
              description?: Maybe<string>
              type: string
              expectedDurationInMinutes?: Maybe<number>
              publishedAt?: Maybe<any>
              artwork: { __typename?: 'Artwork'; coverImage?: Maybe<string> }
              translations: Array<{
                __typename?: 'Translation'
                title?: Maybe<string>
                description?: Maybe<string>
                language?: Maybe<{
                  __typename?: 'Language'
                  code: string
                  name: string
                  icon: string
                }>
                activePackage?: Maybe<{
                  __typename?: 'Package'
                  launchPath?: Maybe<string>
                }>
              }>
            }
          | {
              __typename: 'StoreItem'
              uuid: string
              title: string
              description?: Maybe<string>
              type: string
              expectedDurationInMinutes?: Maybe<number>
              publishedAt?: Maybe<any>
              publisher?: Maybe<{ __typename?: 'Publisher'; name: string }>
              artwork: {
                __typename?: 'Artwork'
                coverImage?: Maybe<string>
                headerImage?: Maybe<string>
              }
              contentType?: Maybe<{
                __typename?: 'Content'
                icon?: Maybe<string>
                name?: Maybe<string>
                translationKey: string
              }>
              topics?: Maybe<
                Array<{
                  __typename?: 'Topic'
                  translationKey?: Maybe<string>
                  topicCategory?: Maybe<{
                    __typename?: 'TopicCategory'
                    translationKey?: Maybe<string>
                  }>
                }>
              >
              translations: Array<{
                __typename?: 'Translation'
                title?: Maybe<string>
                description?: Maybe<string>
                language?: Maybe<{
                  __typename?: 'Language'
                  code: string
                  name: string
                  icon: string
                }>
                activePackage?: Maybe<{
                  __typename?: 'Package'
                  launchPath?: Maybe<string>
                }>
              }>
              subscriptions?: Maybe<
                Array<{ __typename?: 'Subscription'; code: string }>
              >
            }
        >
      }
}

export type LearnerMessagePropertiesFragment = {
  __typename?: 'LearnerMessage'
  id: number
  subject: string
  content: string
  status: MessagesCampaignRecipientStatuses
  createdAt: any
}

export type PolicyPropertiesFragment = {
  __typename?: 'Policy'
  title: string
  id: number
  description?: Maybe<string>
  minimumTime?: Maybe<number>
  publishedAt?: Maybe<any>
  type: PolicyTypes
  assets: Array<{
    __typename?: 'PolicyAsset'
    language: Languages
    url?: Maybe<string>
    acceptanceText: string
  }>
  translations: Array<{
    __typename?: 'Translation'
    title?: Maybe<string>
    description?: Maybe<string>
    language?: Maybe<{
      __typename?: 'Language'
      code: string
      name: string
      icon: string
    }>
    activePackage?: Maybe<{
      __typename?: 'Package'
      launchPath?: Maybe<string>
    }>
  }>
}

export type StoreItemPropertiesFragment = {
  __typename?: 'StoreItem'
  uuid: string
  title: string
  description?: Maybe<string>
  type: string
  expectedDurationInMinutes?: Maybe<number>
  publishedAt?: Maybe<any>
  publisher?: Maybe<{ __typename?: 'Publisher'; name: string }>
  artwork: {
    __typename?: 'Artwork'
    coverImage?: Maybe<string>
    headerImage?: Maybe<string>
  }
  contentType?: Maybe<{
    __typename?: 'Content'
    icon?: Maybe<string>
    name?: Maybe<string>
    translationKey: string
  }>
  topics?: Maybe<
    Array<{
      __typename?: 'Topic'
      translationKey?: Maybe<string>
      topicCategory?: Maybe<{
        __typename?: 'TopicCategory'
        translationKey?: Maybe<string>
      }>
    }>
  >
  translations: Array<{
    __typename?: 'Translation'
    title?: Maybe<string>
    description?: Maybe<string>
    language?: Maybe<{
      __typename?: 'Language'
      code: string
      name: string
      icon: string
    }>
    activePackage?: Maybe<{
      __typename?: 'Package'
      launchPath?: Maybe<string>
    }>
  }>
  subscriptions?: Maybe<Array<{ __typename?: 'Subscription'; code: string }>>
}

export type UserProfileFieldsFragment = {
  __typename?: 'User'
  id: number
  email: string
  domain?: Maybe<string>
  language?: Maybe<string>
  firstName?: Maybe<string>
  lastName?: Maybe<string>
  jobTitle?: Maybe<string>
  subscriptionLevel?: Maybe<string>
  billingType?: Maybe<string>
  allowAnalytics: boolean
  companyName?: Maybe<string>
  accountId?: Maybe<string>
  accountDomain?: Maybe<string>
  popcornTheaterReduceMotion: boolean
  lxDisableTour: boolean
  deviceLinkToken?: Maybe<string>
  passlessEnabled: boolean
  samlEnabled: boolean
  disableNonSamlLogins?: Maybe<boolean>
  customHelpEnabled?: Maybe<boolean>
  customHelpUrl?: Maybe<string>
  optionalTrainingEnabled: boolean
  aidaOptionalTrainingEnabled: boolean
  enrollmentLanguageSelections: Array<string>
  mobileAppEnabled?: Maybe<boolean>
  adminLocale?: Maybe<string>
  messagesEnabled?: Maybe<boolean>
  managerPhishingEnabled?: Maybe<boolean>
  managerRiskScoreEnabled?: Maybe<boolean>
  managerTrainingEnabled?: Maybe<boolean>
  managerUserInfoEnabled?: Maybe<boolean>
  managerReport?: Maybe<{
    __typename?: 'ManagerReport'
    totalMembersCount: number
  }>
  linkedDevices?: Maybe<
    Array<{
      __typename?: 'DeviceDetails'
      id: number
      createdAt: any
      deviceId: string
      label?: Maybe<string>
    }>
  >
  gamification?: Maybe<{
    __typename?: 'UserGamification'
    badgesEnabled?: Maybe<boolean>
    badgesEarned?: Maybe<Array<Badges>>
    leaderboardTypes?: Maybe<Array<LeaderboardTypes>>
    leaderboards?: Maybe<
      Array<{
        __typename?: 'LeaderboardNew'
        leaderboardSize?: Maybe<number>
        leaders?: Maybe<
          Array<
            | {
                __typename?: 'GroupLeader'
                includesCurrentUser?: Maybe<boolean>
              }
            | {
                __typename?: 'IndividualLeader'
                includesCurrentUser?: Maybe<boolean>
              }
          >
        >
      }>
    >
    leaderboard?: Maybe<{
      __typename?: 'Leaderboard'
      leaderboardType?: Maybe<LeaderboardTypes>
      leadersIndividualByAccount?: Maybe<
        Array<{
          __typename?: 'LeaderboardIndividualAccount'
          includesCurrentUser?: Maybe<boolean>
        }>
      >
      leaders?: Maybe<
        Array<{
          __typename?: 'LeaderboardGroup'
          includesCurrentUser?: Maybe<boolean>
        }>
      >
    }>
  }>
  languageSettings?: Maybe<{
    __typename?: 'UserSettingsLanguages'
    trainingLocale?: Maybe<string>
  }>
  accountLanguageSettings?: Maybe<{
    __typename?: 'AccountSettingsLanguageDefault'
    trainingLocale?: Maybe<string>
  }>
  statistics?: Maybe<{ __typename?: 'UserStatistics'; id: number }>
  learnerMessages?: Maybe<
    Array<{
      __typename?: 'LearnerMessage'
      id: number
      subject: string
      content: string
      status: MessagesCampaignRecipientStatuses
      createdAt: any
    }>
  >
}

export type MarkEarnedBadgesAsAcknowledgedMutationVariables = Exact<{
  badgeIds: Array<Scalars['Int']> | Scalars['Int']
}>

export type MarkEarnedBadgesAsAcknowledgedMutation = {
  __typename?: 'Mutation'
  badgesMarkAsAcknowledged?: Maybe<{
    __typename?: 'BadgesAcknowledgePayload'
    nodes?: Maybe<
      Array<{
        __typename?: 'Badge'
        acknowledgedAt?: Maybe<any>
        description: string
        earnedAt?: Maybe<any>
        icon: string
        id?: Maybe<number>
        title: string
        type?: Maybe<string>
        viewedAt?: Maybe<any>
      }>
    >
    errors?: Maybe<
      Array<{ __typename?: 'Error'; placeholders?: Maybe<any>; reason: string }>
    >
  }>
}

export type EarnBadgeMutationVariables = Exact<{
  enrollmentId?: Maybe<Scalars['Int']>
}>

export type EarnBadgeMutation = {
  __typename?: 'Mutation'
  badgesEarn?: Maybe<{
    __typename?: 'BadgesEarnPayload'
    nodes?: Maybe<
      Array<{
        __typename?: 'Badge'
        acknowledgedAt?: Maybe<any>
        description: string
        earnedAt?: Maybe<any>
        icon: string
        id?: Maybe<number>
        title: string
        type?: Maybe<string>
        viewedAt?: Maybe<any>
      }>
    >
    errors?: Maybe<
      Array<{ __typename?: 'Error'; placeholders?: Maybe<any>; reason: string }>
    >
  }>
}

export type MarkEarnedBadgesAsViewedMutationVariables = Exact<{
  [key: string]: never
}>

export type MarkEarnedBadgesAsViewedMutation = {
  __typename?: 'Mutation'
  badgesMarkAsViewed?: Maybe<{
    __typename?: 'BadgesViewPayload'
    nodes?: Maybe<
      Array<{
        __typename?: 'Badge'
        acknowledgedAt?: Maybe<any>
        description: string
        earnedAt?: Maybe<any>
        icon: string
        id?: Maybe<number>
        title: string
        type?: Maybe<string>
        viewedAt?: Maybe<any>
      }>
    >
    errors?: Maybe<
      Array<{ __typename?: 'Error'; placeholders?: Maybe<any>; reason: string }>
    >
  }>
}

export type CreateEnrollmentMutationVariables = Exact<{
  id: Scalars['String']
  language: Scalars['String']
  storeItemSource?: Maybe<StoreItemSource>
}>

export type CreateEnrollmentMutation = {
  __typename?: 'Mutation'
  enrollmentCreate?: Maybe<{
    __typename?: 'EnrollmentCreatePayload'
    node?: Maybe<{
      __typename?: 'Enrollment'
      theme?: Maybe<any>
      id: number
      lastUsedLanguage?: Maybe<string>
      createdAt: any
      state?: Maybe<string>
      type: EnrollmentTypes
      expiresAt?: Maybe<any>
      policyAcknowledgedAt?: Maybe<any>
      completedAt?: Maybe<any>
      selectedLanguage?: Maybe<string>
      timeSpentInSeconds?: Maybe<string>
      user?: Maybe<{
        __typename?: 'User'
        id: number
        companyName?: Maybe<string>
      }>
      trainingCampaign?: Maybe<{
        __typename?: 'TrainingCampaign'
        id: number
        name: string
        allowSurveys: boolean
        allowSurveyComments: boolean
      }>
      enrollmentItem:
        | {
            __typename: 'KnowledgeRefresher'
            storeItem?: Maybe<
              | { __typename: 'CcmItem' }
              | {
                  __typename: 'StoreItem'
                  uuid: string
                  title: string
                  description?: Maybe<string>
                  type: string
                  expectedDurationInMinutes?: Maybe<number>
                  publishedAt?: Maybe<any>
                  publisher?: Maybe<{ __typename?: 'Publisher'; name: string }>
                  artwork: {
                    __typename?: 'Artwork'
                    coverImage?: Maybe<string>
                    headerImage?: Maybe<string>
                  }
                  contentType?: Maybe<{
                    __typename?: 'Content'
                    icon?: Maybe<string>
                    name?: Maybe<string>
                    translationKey: string
                  }>
                  topics?: Maybe<
                    Array<{
                      __typename?: 'Topic'
                      translationKey?: Maybe<string>
                      topicCategory?: Maybe<{
                        __typename?: 'TopicCategory'
                        translationKey?: Maybe<string>
                      }>
                    }>
                  >
                  translations: Array<{
                    __typename?: 'Translation'
                    title?: Maybe<string>
                    description?: Maybe<string>
                    language?: Maybe<{
                      __typename?: 'Language'
                      code: string
                      name: string
                      icon: string
                    }>
                    activePackage?: Maybe<{
                      __typename?: 'Package'
                      launchPath?: Maybe<string>
                    }>
                  }>
                  subscriptions?: Maybe<
                    Array<{ __typename?: 'Subscription'; code: string }>
                  >
                }
            >
          }
        | {
            __typename: 'Policy'
            title: string
            id: number
            description?: Maybe<string>
            minimumTime?: Maybe<number>
            publishedAt?: Maybe<any>
            type: PolicyTypes
            assets: Array<{
              __typename?: 'PolicyAsset'
              language: Languages
              url?: Maybe<string>
              acceptanceText: string
            }>
            translations: Array<{
              __typename?: 'Translation'
              title?: Maybe<string>
              description?: Maybe<string>
              language?: Maybe<{
                __typename?: 'Language'
                code: string
                name: string
                icon: string
              }>
              activePackage?: Maybe<{
                __typename?: 'Package'
                launchPath?: Maybe<string>
              }>
            }>
          }
        | {
            __typename: 'PurchasedCourse'
            userHasToAcknowledgePolicy?: Maybe<boolean>
            policyLocation?: Maybe<string>
            contentSource?: Maybe<string>
            storeItem?: Maybe<
              | {
                  __typename: 'CcmItem'
                  uuid: string
                  title: string
                  description?: Maybe<string>
                  type: string
                  expectedDurationInMinutes?: Maybe<number>
                  publishedAt?: Maybe<any>
                  artwork: {
                    __typename?: 'Artwork'
                    coverImage?: Maybe<string>
                  }
                  translations: Array<{
                    __typename?: 'Translation'
                    title?: Maybe<string>
                    description?: Maybe<string>
                    language?: Maybe<{
                      __typename?: 'Language'
                      code: string
                      name: string
                      icon: string
                    }>
                    activePackage?: Maybe<{
                      __typename?: 'Package'
                      launchPath?: Maybe<string>
                    }>
                  }>
                }
              | {
                  __typename: 'StoreItem'
                  uuid: string
                  title: string
                  description?: Maybe<string>
                  type: string
                  expectedDurationInMinutes?: Maybe<number>
                  publishedAt?: Maybe<any>
                  publisher?: Maybe<{ __typename?: 'Publisher'; name: string }>
                  artwork: {
                    __typename?: 'Artwork'
                    coverImage?: Maybe<string>
                    headerImage?: Maybe<string>
                  }
                  contentType?: Maybe<{
                    __typename?: 'Content'
                    icon?: Maybe<string>
                    name?: Maybe<string>
                    translationKey: string
                  }>
                  topics?: Maybe<
                    Array<{
                      __typename?: 'Topic'
                      translationKey?: Maybe<string>
                      topicCategory?: Maybe<{
                        __typename?: 'TopicCategory'
                        translationKey?: Maybe<string>
                      }>
                    }>
                  >
                  translations: Array<{
                    __typename?: 'Translation'
                    title?: Maybe<string>
                    description?: Maybe<string>
                    language?: Maybe<{
                      __typename?: 'Language'
                      code: string
                      name: string
                      icon: string
                    }>
                    activePackage?: Maybe<{
                      __typename?: 'Package'
                      launchPath?: Maybe<string>
                    }>
                  }>
                  subscriptions?: Maybe<
                    Array<{ __typename?: 'Subscription'; code: string }>
                  >
                }
            >
          }
    }>
    errors?: Maybe<
      Array<{
        __typename?: 'Error'
        field: string
        placeholders?: Maybe<any>
        reason: string
      }>
    >
  }>
}

export type DeleteDeviceDetailsMutationVariables = Exact<{
  deviceId: Scalars['String']
}>

export type DeleteDeviceDetailsMutation = {
  __typename?: 'Mutation'
  deviceDetailsDelete?: Maybe<{
    __typename?: 'DeviceDetailsDeletePayload'
    node?: Maybe<{ __typename?: 'DeviceDetails'; id: number }>
    errors?: Maybe<
      Array<{
        __typename?: 'Error'
        field: string
        reason: string
        placeholders?: Maybe<any>
      }>
    >
  }>
}

export type SignOutActorsMutationVariables = Exact<{
  actors: Array<Scalars['String']> | Scalars['String']
}>

export type SignOutActorsMutation = {
  __typename?: 'Mutation'
  currentActorsSignOut?: Maybe<{
    __typename?: 'CurrentActorsSignOutPayload'
    node?: Maybe<boolean>
    errors?: Maybe<
      Array<{
        __typename?: 'Error'
        field: string
        placeholders?: Maybe<any>
        reason: string
      }>
    >
  }>
}

export type StartEnrollmentMutationVariables = Exact<{
  id: Scalars['Int']
  language?: Maybe<Scalars['String']>
}>

export type StartEnrollmentMutation = {
  __typename?: 'Mutation'
  enrollmentStart?: Maybe<{
    __typename?: 'EnrollmentStartPayload'
    node?: Maybe<{
      __typename?: 'Enrollment'
      theme?: Maybe<any>
      id: number
      lastUsedLanguage?: Maybe<string>
      createdAt: any
      state?: Maybe<string>
      type: EnrollmentTypes
      expiresAt?: Maybe<any>
      policyAcknowledgedAt?: Maybe<any>
      completedAt?: Maybe<any>
      selectedLanguage?: Maybe<string>
      timeSpentInSeconds?: Maybe<string>
      user?: Maybe<{
        __typename?: 'User'
        id: number
        companyName?: Maybe<string>
      }>
      trainingCampaign?: Maybe<{
        __typename?: 'TrainingCampaign'
        id: number
        name: string
        allowSurveys: boolean
        allowSurveyComments: boolean
      }>
      enrollmentItem:
        | {
            __typename: 'KnowledgeRefresher'
            storeItem?: Maybe<
              | { __typename: 'CcmItem' }
              | {
                  __typename: 'StoreItem'
                  uuid: string
                  title: string
                  description?: Maybe<string>
                  type: string
                  expectedDurationInMinutes?: Maybe<number>
                  publishedAt?: Maybe<any>
                  publisher?: Maybe<{ __typename?: 'Publisher'; name: string }>
                  artwork: {
                    __typename?: 'Artwork'
                    coverImage?: Maybe<string>
                    headerImage?: Maybe<string>
                  }
                  contentType?: Maybe<{
                    __typename?: 'Content'
                    icon?: Maybe<string>
                    name?: Maybe<string>
                    translationKey: string
                  }>
                  topics?: Maybe<
                    Array<{
                      __typename?: 'Topic'
                      translationKey?: Maybe<string>
                      topicCategory?: Maybe<{
                        __typename?: 'TopicCategory'
                        translationKey?: Maybe<string>
                      }>
                    }>
                  >
                  translations: Array<{
                    __typename?: 'Translation'
                    title?: Maybe<string>
                    description?: Maybe<string>
                    language?: Maybe<{
                      __typename?: 'Language'
                      code: string
                      name: string
                      icon: string
                    }>
                    activePackage?: Maybe<{
                      __typename?: 'Package'
                      launchPath?: Maybe<string>
                    }>
                  }>
                  subscriptions?: Maybe<
                    Array<{ __typename?: 'Subscription'; code: string }>
                  >
                }
            >
          }
        | {
            __typename: 'Policy'
            title: string
            id: number
            description?: Maybe<string>
            minimumTime?: Maybe<number>
            publishedAt?: Maybe<any>
            type: PolicyTypes
            assets: Array<{
              __typename?: 'PolicyAsset'
              language: Languages
              url?: Maybe<string>
              acceptanceText: string
            }>
            translations: Array<{
              __typename?: 'Translation'
              title?: Maybe<string>
              description?: Maybe<string>
              language?: Maybe<{
                __typename?: 'Language'
                code: string
                name: string
                icon: string
              }>
              activePackage?: Maybe<{
                __typename?: 'Package'
                launchPath?: Maybe<string>
              }>
            }>
          }
        | {
            __typename: 'PurchasedCourse'
            userHasToAcknowledgePolicy?: Maybe<boolean>
            policyLocation?: Maybe<string>
            contentSource?: Maybe<string>
            storeItem?: Maybe<
              | {
                  __typename: 'CcmItem'
                  uuid: string
                  title: string
                  description?: Maybe<string>
                  type: string
                  expectedDurationInMinutes?: Maybe<number>
                  publishedAt?: Maybe<any>
                  artwork: {
                    __typename?: 'Artwork'
                    coverImage?: Maybe<string>
                  }
                  translations: Array<{
                    __typename?: 'Translation'
                    title?: Maybe<string>
                    description?: Maybe<string>
                    language?: Maybe<{
                      __typename?: 'Language'
                      code: string
                      name: string
                      icon: string
                    }>
                    activePackage?: Maybe<{
                      __typename?: 'Package'
                      launchPath?: Maybe<string>
                    }>
                  }>
                }
              | {
                  __typename: 'StoreItem'
                  uuid: string
                  title: string
                  description?: Maybe<string>
                  type: string
                  expectedDurationInMinutes?: Maybe<number>
                  publishedAt?: Maybe<any>
                  publisher?: Maybe<{ __typename?: 'Publisher'; name: string }>
                  artwork: {
                    __typename?: 'Artwork'
                    coverImage?: Maybe<string>
                    headerImage?: Maybe<string>
                  }
                  contentType?: Maybe<{
                    __typename?: 'Content'
                    icon?: Maybe<string>
                    name?: Maybe<string>
                    translationKey: string
                  }>
                  topics?: Maybe<
                    Array<{
                      __typename?: 'Topic'
                      translationKey?: Maybe<string>
                      topicCategory?: Maybe<{
                        __typename?: 'TopicCategory'
                        translationKey?: Maybe<string>
                      }>
                    }>
                  >
                  translations: Array<{
                    __typename?: 'Translation'
                    title?: Maybe<string>
                    description?: Maybe<string>
                    language?: Maybe<{
                      __typename?: 'Language'
                      code: string
                      name: string
                      icon: string
                    }>
                    activePackage?: Maybe<{
                      __typename?: 'Package'
                      launchPath?: Maybe<string>
                    }>
                  }>
                  subscriptions?: Maybe<
                    Array<{ __typename?: 'Subscription'; code: string }>
                  >
                }
            >
          }
    }>
    errors?: Maybe<
      Array<{
        __typename?: 'Error'
        field: string
        placeholders?: Maybe<any>
        reason: string
      }>
    >
  }>
}

export type SurveyCreateMutationVariables = Exact<{
  enrollmentId: Scalars['Int']
  attributes: SurveyAttributes
}>

export type SurveyCreateMutation = {
  __typename?: 'Mutation'
  surveyCreate?: Maybe<{
    __typename?: 'SurveysCreatePayload'
    node?: Maybe<{ __typename?: 'Survey'; id: number }>
    errors?: Maybe<Array<{ __typename?: 'Error'; reason: string }>>
  }>
}

export type UpdateMessageRecipientMutationVariables = Exact<{
  id: Scalars['Int']
  attributes: LearnerMessageAttributes
}>

export type UpdateMessageRecipientMutation = {
  __typename?: 'Mutation'
  updateMessageRecipient?: Maybe<{
    __typename?: 'MessagesUpdateMessageRecipientPayload'
    node?: Maybe<{
      __typename?: 'LearnerMessage'
      id: number
      subject: string
      content: string
      status: MessagesCampaignRecipientStatuses
      createdAt: any
    }>
    errors?: Maybe<
      Array<{
        __typename?: 'Error'
        field: string
        reason: string
        placeholders?: Maybe<any>
      }>
    >
  }>
}

export type UserProfileEditMutationVariables = Exact<{
  attributes: UserAttributes
}>

export type UserProfileEditMutation = {
  __typename?: 'Mutation'
  userProfileEdit?: Maybe<{
    __typename?: 'UsersEditProfilePayload'
    node?: Maybe<{
      __typename?: 'User'
      id: number
      email: string
      domain?: Maybe<string>
      language?: Maybe<string>
      firstName?: Maybe<string>
      lastName?: Maybe<string>
      jobTitle?: Maybe<string>
      subscriptionLevel?: Maybe<string>
      billingType?: Maybe<string>
      allowAnalytics: boolean
      companyName?: Maybe<string>
      accountId?: Maybe<string>
      accountDomain?: Maybe<string>
      popcornTheaterReduceMotion: boolean
      lxDisableTour: boolean
      deviceLinkToken?: Maybe<string>
      passlessEnabled: boolean
      samlEnabled: boolean
      disableNonSamlLogins?: Maybe<boolean>
      customHelpEnabled?: Maybe<boolean>
      customHelpUrl?: Maybe<string>
      optionalTrainingEnabled: boolean
      aidaOptionalTrainingEnabled: boolean
      enrollmentLanguageSelections: Array<string>
      mobileAppEnabled?: Maybe<boolean>
      adminLocale?: Maybe<string>
      messagesEnabled?: Maybe<boolean>
      managerPhishingEnabled?: Maybe<boolean>
      managerRiskScoreEnabled?: Maybe<boolean>
      managerTrainingEnabled?: Maybe<boolean>
      managerUserInfoEnabled?: Maybe<boolean>
      managerReport?: Maybe<{
        __typename?: 'ManagerReport'
        totalMembersCount: number
      }>
      linkedDevices?: Maybe<
        Array<{
          __typename?: 'DeviceDetails'
          id: number
          createdAt: any
          deviceId: string
          label?: Maybe<string>
        }>
      >
      gamification?: Maybe<{
        __typename?: 'UserGamification'
        badgesEnabled?: Maybe<boolean>
        badgesEarned?: Maybe<Array<Badges>>
        leaderboardTypes?: Maybe<Array<LeaderboardTypes>>
        leaderboards?: Maybe<
          Array<{
            __typename?: 'LeaderboardNew'
            leaderboardSize?: Maybe<number>
            leaders?: Maybe<
              Array<
                | {
                    __typename?: 'GroupLeader'
                    includesCurrentUser?: Maybe<boolean>
                  }
                | {
                    __typename?: 'IndividualLeader'
                    includesCurrentUser?: Maybe<boolean>
                  }
              >
            >
          }>
        >
        leaderboard?: Maybe<{
          __typename?: 'Leaderboard'
          leaderboardType?: Maybe<LeaderboardTypes>
          leadersIndividualByAccount?: Maybe<
            Array<{
              __typename?: 'LeaderboardIndividualAccount'
              includesCurrentUser?: Maybe<boolean>
            }>
          >
          leaders?: Maybe<
            Array<{
              __typename?: 'LeaderboardGroup'
              includesCurrentUser?: Maybe<boolean>
            }>
          >
        }>
      }>
      languageSettings?: Maybe<{
        __typename?: 'UserSettingsLanguages'
        trainingLocale?: Maybe<string>
      }>
      accountLanguageSettings?: Maybe<{
        __typename?: 'AccountSettingsLanguageDefault'
        trainingLocale?: Maybe<string>
      }>
      statistics?: Maybe<{ __typename?: 'UserStatistics'; id: number }>
      learnerMessages?: Maybe<
        Array<{
          __typename?: 'LearnerMessage'
          id: number
          subject: string
          content: string
          status: MessagesCampaignRecipientStatuses
          createdAt: any
        }>
      >
    }>
    errors?: Maybe<
      Array<{
        __typename?: 'Error'
        field: string
        reason: string
        placeholders?: Maybe<any>
      }>
    >
  }>
}

export type BadgesQueryVariables = Exact<{ [key: string]: never }>

export type BadgesQuery = {
  __typename?: 'Query'
  currentUser?: Maybe<{
    __typename?: 'User'
    gamification?: Maybe<{
      __typename?: 'UserGamification'
      badgesEnabled?: Maybe<boolean>
      badgesList?: Maybe<
        Array<{
          __typename?: 'Badge'
          acknowledgedAt?: Maybe<any>
          description: string
          earnedAt?: Maybe<any>
          icon: string
          id?: Maybe<number>
          title: string
          type?: Maybe<string>
          viewedAt?: Maybe<any>
        }>
      >
    }>
  }>
}

export type DashboardStatisticsQueryVariables = Exact<{ [key: string]: never }>

export type DashboardStatisticsQuery = {
  __typename?: 'Query'
  currentUser?: Maybe<{
    __typename?: 'User'
    statistics?: Maybe<{
      __typename?: 'UserStatistics'
      phishingStatistics?: Maybe<{
        __typename?: 'UserPhishingStatistics'
        emailReported: number
        totalDelivered: number
        totalFailures: number
        totalMisses: number
      }>
      riskStatistics?: Maybe<{
        __typename?: 'UserRiskStatistics'
        riskScore?: Maybe<number>
      }>
      enrollmentsStatistics?: Maybe<{
        __typename?: 'UserEnrollmentsStatistics'
        complete: number
        inProgress: number
        notStarted: number
        pastDue: number
        total: number
      }>
    }>
  }>
}

export type DeviceLinkTokenQueryVariables = Exact<{ [key: string]: never }>

export type DeviceLinkTokenQuery = {
  __typename?: 'Query'
  currentUser?: Maybe<{ __typename?: 'User'; deviceLinkToken?: Maybe<string> }>
}

export type DirectReportQueryVariables = Exact<{
  userId?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}>

export type DirectReportQuery = {
  __typename?: 'Query'
  directReports: {
    __typename?: 'DirectReportsCursor'
    nodes: Array<{
      __typename?: 'DirectReport'
      id: number
      name: string
      jobTitle?: Maybe<string>
      email: string
      hasDirectReports: boolean
    }>
  }
}

export type ElectedEnrollmentsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}>

export type ElectedEnrollmentsQuery = {
  __typename?: 'Query'
  currentUser?: Maybe<{
    __typename?: 'User'
    electedEnrollments?: Maybe<
      Array<{
        __typename?: 'Enrollment'
        id: number
        lastUsedLanguage?: Maybe<string>
        createdAt: any
        state?: Maybe<string>
        type: EnrollmentTypes
        expiresAt?: Maybe<any>
        policyAcknowledgedAt?: Maybe<any>
        completedAt?: Maybe<any>
        selectedLanguage?: Maybe<string>
        timeSpentInSeconds?: Maybe<string>
        user?: Maybe<{
          __typename?: 'User'
          id: number
          companyName?: Maybe<string>
        }>
        trainingCampaign?: Maybe<{
          __typename?: 'TrainingCampaign'
          id: number
          name: string
          allowSurveys: boolean
          allowSurveyComments: boolean
        }>
        enrollmentItem:
          | {
              __typename: 'KnowledgeRefresher'
              storeItem?: Maybe<
                | { __typename: 'CcmItem' }
                | {
                    __typename: 'StoreItem'
                    uuid: string
                    title: string
                    description?: Maybe<string>
                    type: string
                    expectedDurationInMinutes?: Maybe<number>
                    publishedAt?: Maybe<any>
                    publisher?: Maybe<{
                      __typename?: 'Publisher'
                      name: string
                    }>
                    artwork: {
                      __typename?: 'Artwork'
                      coverImage?: Maybe<string>
                      headerImage?: Maybe<string>
                    }
                    contentType?: Maybe<{
                      __typename?: 'Content'
                      icon?: Maybe<string>
                      name?: Maybe<string>
                      translationKey: string
                    }>
                    topics?: Maybe<
                      Array<{
                        __typename?: 'Topic'
                        translationKey?: Maybe<string>
                        topicCategory?: Maybe<{
                          __typename?: 'TopicCategory'
                          translationKey?: Maybe<string>
                        }>
                      }>
                    >
                    translations: Array<{
                      __typename?: 'Translation'
                      title?: Maybe<string>
                      description?: Maybe<string>
                      language?: Maybe<{
                        __typename?: 'Language'
                        code: string
                        name: string
                        icon: string
                      }>
                      activePackage?: Maybe<{
                        __typename?: 'Package'
                        launchPath?: Maybe<string>
                      }>
                    }>
                    subscriptions?: Maybe<
                      Array<{ __typename?: 'Subscription'; code: string }>
                    >
                  }
              >
            }
          | {
              __typename: 'Policy'
              title: string
              id: number
              description?: Maybe<string>
              minimumTime?: Maybe<number>
              publishedAt?: Maybe<any>
              type: PolicyTypes
              assets: Array<{
                __typename?: 'PolicyAsset'
                language: Languages
                url?: Maybe<string>
                acceptanceText: string
              }>
              translations: Array<{
                __typename?: 'Translation'
                title?: Maybe<string>
                description?: Maybe<string>
                language?: Maybe<{
                  __typename?: 'Language'
                  code: string
                  name: string
                  icon: string
                }>
                activePackage?: Maybe<{
                  __typename?: 'Package'
                  launchPath?: Maybe<string>
                }>
              }>
            }
          | {
              __typename: 'PurchasedCourse'
              userHasToAcknowledgePolicy?: Maybe<boolean>
              policyLocation?: Maybe<string>
              contentSource?: Maybe<string>
              storeItem?: Maybe<
                | {
                    __typename: 'CcmItem'
                    uuid: string
                    title: string
                    description?: Maybe<string>
                    type: string
                    expectedDurationInMinutes?: Maybe<number>
                    publishedAt?: Maybe<any>
                    artwork: {
                      __typename?: 'Artwork'
                      coverImage?: Maybe<string>
                    }
                    translations: Array<{
                      __typename?: 'Translation'
                      title?: Maybe<string>
                      description?: Maybe<string>
                      language?: Maybe<{
                        __typename?: 'Language'
                        code: string
                        name: string
                        icon: string
                      }>
                      activePackage?: Maybe<{
                        __typename?: 'Package'
                        launchPath?: Maybe<string>
                      }>
                    }>
                  }
                | {
                    __typename: 'StoreItem'
                    uuid: string
                    title: string
                    description?: Maybe<string>
                    type: string
                    expectedDurationInMinutes?: Maybe<number>
                    publishedAt?: Maybe<any>
                    publisher?: Maybe<{
                      __typename?: 'Publisher'
                      name: string
                    }>
                    artwork: {
                      __typename?: 'Artwork'
                      coverImage?: Maybe<string>
                      headerImage?: Maybe<string>
                    }
                    contentType?: Maybe<{
                      __typename?: 'Content'
                      icon?: Maybe<string>
                      name?: Maybe<string>
                      translationKey: string
                    }>
                    topics?: Maybe<
                      Array<{
                        __typename?: 'Topic'
                        translationKey?: Maybe<string>
                        topicCategory?: Maybe<{
                          __typename?: 'TopicCategory'
                          translationKey?: Maybe<string>
                        }>
                      }>
                    >
                    translations: Array<{
                      __typename?: 'Translation'
                      title?: Maybe<string>
                      description?: Maybe<string>
                      language?: Maybe<{
                        __typename?: 'Language'
                        code: string
                        name: string
                        icon: string
                      }>
                      activePackage?: Maybe<{
                        __typename?: 'Package'
                        launchPath?: Maybe<string>
                      }>
                    }>
                    subscriptions?: Maybe<
                      Array<{ __typename?: 'Subscription'; code: string }>
                    >
                  }
              >
            }
      }>
    >
  }>
}

export type EnrollmentQueryVariables = Exact<{
  id: Scalars['Int']
}>

export type EnrollmentQuery = {
  __typename?: 'Query'
  enrollment?: Maybe<{
    __typename?: 'Enrollment'
    id: number
    lastUsedLanguage?: Maybe<string>
    createdAt: any
    state?: Maybe<string>
    type: EnrollmentTypes
    expiresAt?: Maybe<any>
    policyAcknowledgedAt?: Maybe<any>
    completedAt?: Maybe<any>
    selectedLanguage?: Maybe<string>
    timeSpentInSeconds?: Maybe<string>
    survey?: Maybe<{
      __typename?: 'Survey'
      comments?: Maybe<string>
      createdAt: any
      lengthRating?: Maybe<number>
      styleRating?: Maybe<number>
      valueRating?: Maybe<number>
    }>
    user?: Maybe<{
      __typename?: 'User'
      id: number
      companyName?: Maybe<string>
    }>
    trainingCampaign?: Maybe<{
      __typename?: 'TrainingCampaign'
      id: number
      name: string
      allowSurveys: boolean
      allowSurveyComments: boolean
    }>
    enrollmentItem:
      | {
          __typename: 'KnowledgeRefresher'
          storeItem?: Maybe<
            | { __typename: 'CcmItem' }
            | {
                __typename: 'StoreItem'
                uuid: string
                title: string
                description?: Maybe<string>
                type: string
                expectedDurationInMinutes?: Maybe<number>
                publishedAt?: Maybe<any>
                publisher?: Maybe<{ __typename?: 'Publisher'; name: string }>
                artwork: {
                  __typename?: 'Artwork'
                  coverImage?: Maybe<string>
                  headerImage?: Maybe<string>
                }
                contentType?: Maybe<{
                  __typename?: 'Content'
                  icon?: Maybe<string>
                  name?: Maybe<string>
                  translationKey: string
                }>
                topics?: Maybe<
                  Array<{
                    __typename?: 'Topic'
                    translationKey?: Maybe<string>
                    topicCategory?: Maybe<{
                      __typename?: 'TopicCategory'
                      translationKey?: Maybe<string>
                    }>
                  }>
                >
                translations: Array<{
                  __typename?: 'Translation'
                  title?: Maybe<string>
                  description?: Maybe<string>
                  language?: Maybe<{
                    __typename?: 'Language'
                    code: string
                    name: string
                    icon: string
                  }>
                  activePackage?: Maybe<{
                    __typename?: 'Package'
                    launchPath?: Maybe<string>
                  }>
                }>
                subscriptions?: Maybe<
                  Array<{ __typename?: 'Subscription'; code: string }>
                >
              }
          >
        }
      | {
          __typename: 'Policy'
          title: string
          id: number
          description?: Maybe<string>
          minimumTime?: Maybe<number>
          publishedAt?: Maybe<any>
          type: PolicyTypes
          assets: Array<{
            __typename?: 'PolicyAsset'
            language: Languages
            url?: Maybe<string>
            acceptanceText: string
          }>
          translations: Array<{
            __typename?: 'Translation'
            title?: Maybe<string>
            description?: Maybe<string>
            language?: Maybe<{
              __typename?: 'Language'
              code: string
              name: string
              icon: string
            }>
            activePackage?: Maybe<{
              __typename?: 'Package'
              launchPath?: Maybe<string>
            }>
          }>
        }
      | {
          __typename: 'PurchasedCourse'
          userHasToAcknowledgePolicy?: Maybe<boolean>
          policyLocation?: Maybe<string>
          contentSource?: Maybe<string>
          storeItem?: Maybe<
            | {
                __typename: 'CcmItem'
                uuid: string
                title: string
                description?: Maybe<string>
                type: string
                expectedDurationInMinutes?: Maybe<number>
                publishedAt?: Maybe<any>
                artwork: { __typename?: 'Artwork'; coverImage?: Maybe<string> }
                translations: Array<{
                  __typename?: 'Translation'
                  title?: Maybe<string>
                  description?: Maybe<string>
                  language?: Maybe<{
                    __typename?: 'Language'
                    code: string
                    name: string
                    icon: string
                  }>
                  activePackage?: Maybe<{
                    __typename?: 'Package'
                    launchPath?: Maybe<string>
                  }>
                }>
              }
            | {
                __typename: 'StoreItem'
                uuid: string
                title: string
                description?: Maybe<string>
                type: string
                expectedDurationInMinutes?: Maybe<number>
                publishedAt?: Maybe<any>
                publisher?: Maybe<{ __typename?: 'Publisher'; name: string }>
                artwork: {
                  __typename?: 'Artwork'
                  coverImage?: Maybe<string>
                  headerImage?: Maybe<string>
                }
                contentType?: Maybe<{
                  __typename?: 'Content'
                  icon?: Maybe<string>
                  name?: Maybe<string>
                  translationKey: string
                }>
                topics?: Maybe<
                  Array<{
                    __typename?: 'Topic'
                    translationKey?: Maybe<string>
                    topicCategory?: Maybe<{
                      __typename?: 'TopicCategory'
                      translationKey?: Maybe<string>
                    }>
                  }>
                >
                translations: Array<{
                  __typename?: 'Translation'
                  title?: Maybe<string>
                  description?: Maybe<string>
                  language?: Maybe<{
                    __typename?: 'Language'
                    code: string
                    name: string
                    icon: string
                  }>
                  activePackage?: Maybe<{
                    __typename?: 'Package'
                    launchPath?: Maybe<string>
                  }>
                }>
                subscriptions?: Maybe<
                  Array<{ __typename?: 'Subscription'; code: string }>
                >
              }
          >
        }
  }>
}

export type EnrollmentsQueryVariables = Exact<{ [key: string]: never }>

export type EnrollmentsQuery = {
  __typename?: 'Query'
  currentUser?: Maybe<{
    __typename?: 'User'
    id: number
    mandatoryEnrollments?: Maybe<
      Array<{
        __typename?: 'Enrollment'
        id: number
        lastUsedLanguage?: Maybe<string>
        createdAt: any
        state?: Maybe<string>
        type: EnrollmentTypes
        expiresAt?: Maybe<any>
        policyAcknowledgedAt?: Maybe<any>
        completedAt?: Maybe<any>
        selectedLanguage?: Maybe<string>
        timeSpentInSeconds?: Maybe<string>
        user?: Maybe<{
          __typename?: 'User'
          id: number
          companyName?: Maybe<string>
        }>
        trainingCampaign?: Maybe<{
          __typename?: 'TrainingCampaign'
          id: number
          name: string
          allowSurveys: boolean
          allowSurveyComments: boolean
        }>
        enrollmentItem:
          | {
              __typename: 'KnowledgeRefresher'
              storeItem?: Maybe<
                | { __typename: 'CcmItem' }
                | {
                    __typename: 'StoreItem'
                    uuid: string
                    title: string
                    description?: Maybe<string>
                    type: string
                    expectedDurationInMinutes?: Maybe<number>
                    publishedAt?: Maybe<any>
                    publisher?: Maybe<{
                      __typename?: 'Publisher'
                      name: string
                    }>
                    artwork: {
                      __typename?: 'Artwork'
                      coverImage?: Maybe<string>
                      headerImage?: Maybe<string>
                    }
                    contentType?: Maybe<{
                      __typename?: 'Content'
                      icon?: Maybe<string>
                      name?: Maybe<string>
                      translationKey: string
                    }>
                    topics?: Maybe<
                      Array<{
                        __typename?: 'Topic'
                        translationKey?: Maybe<string>
                        topicCategory?: Maybe<{
                          __typename?: 'TopicCategory'
                          translationKey?: Maybe<string>
                        }>
                      }>
                    >
                    translations: Array<{
                      __typename?: 'Translation'
                      title?: Maybe<string>
                      description?: Maybe<string>
                      language?: Maybe<{
                        __typename?: 'Language'
                        code: string
                        name: string
                        icon: string
                      }>
                      activePackage?: Maybe<{
                        __typename?: 'Package'
                        launchPath?: Maybe<string>
                      }>
                    }>
                    subscriptions?: Maybe<
                      Array<{ __typename?: 'Subscription'; code: string }>
                    >
                  }
              >
            }
          | {
              __typename: 'Policy'
              title: string
              id: number
              description?: Maybe<string>
              minimumTime?: Maybe<number>
              publishedAt?: Maybe<any>
              type: PolicyTypes
              assets: Array<{
                __typename?: 'PolicyAsset'
                language: Languages
                url?: Maybe<string>
                acceptanceText: string
              }>
              translations: Array<{
                __typename?: 'Translation'
                title?: Maybe<string>
                description?: Maybe<string>
                language?: Maybe<{
                  __typename?: 'Language'
                  code: string
                  name: string
                  icon: string
                }>
                activePackage?: Maybe<{
                  __typename?: 'Package'
                  launchPath?: Maybe<string>
                }>
              }>
            }
          | {
              __typename: 'PurchasedCourse'
              userHasToAcknowledgePolicy?: Maybe<boolean>
              policyLocation?: Maybe<string>
              contentSource?: Maybe<string>
              storeItem?: Maybe<
                | {
                    __typename: 'CcmItem'
                    uuid: string
                    title: string
                    description?: Maybe<string>
                    type: string
                    expectedDurationInMinutes?: Maybe<number>
                    publishedAt?: Maybe<any>
                    artwork: {
                      __typename?: 'Artwork'
                      coverImage?: Maybe<string>
                    }
                    translations: Array<{
                      __typename?: 'Translation'
                      title?: Maybe<string>
                      description?: Maybe<string>
                      language?: Maybe<{
                        __typename?: 'Language'
                        code: string
                        name: string
                        icon: string
                      }>
                      activePackage?: Maybe<{
                        __typename?: 'Package'
                        launchPath?: Maybe<string>
                      }>
                    }>
                  }
                | {
                    __typename: 'StoreItem'
                    uuid: string
                    title: string
                    description?: Maybe<string>
                    type: string
                    expectedDurationInMinutes?: Maybe<number>
                    publishedAt?: Maybe<any>
                    publisher?: Maybe<{
                      __typename?: 'Publisher'
                      name: string
                    }>
                    artwork: {
                      __typename?: 'Artwork'
                      coverImage?: Maybe<string>
                      headerImage?: Maybe<string>
                    }
                    contentType?: Maybe<{
                      __typename?: 'Content'
                      icon?: Maybe<string>
                      name?: Maybe<string>
                      translationKey: string
                    }>
                    topics?: Maybe<
                      Array<{
                        __typename?: 'Topic'
                        translationKey?: Maybe<string>
                        topicCategory?: Maybe<{
                          __typename?: 'TopicCategory'
                          translationKey?: Maybe<string>
                        }>
                      }>
                    >
                    translations: Array<{
                      __typename?: 'Translation'
                      title?: Maybe<string>
                      description?: Maybe<string>
                      language?: Maybe<{
                        __typename?: 'Language'
                        code: string
                        name: string
                        icon: string
                      }>
                      activePackage?: Maybe<{
                        __typename?: 'Package'
                        launchPath?: Maybe<string>
                      }>
                    }>
                    subscriptions?: Maybe<
                      Array<{ __typename?: 'Subscription'; code: string }>
                    >
                  }
              >
            }
      }>
    >
    knowledgeRefresherEnrollments?: Maybe<
      Array<{
        __typename?: 'Enrollment'
        id: number
        lastUsedLanguage?: Maybe<string>
        createdAt: any
        state?: Maybe<string>
        type: EnrollmentTypes
        expiresAt?: Maybe<any>
        policyAcknowledgedAt?: Maybe<any>
        completedAt?: Maybe<any>
        selectedLanguage?: Maybe<string>
        timeSpentInSeconds?: Maybe<string>
        user?: Maybe<{
          __typename?: 'User'
          id: number
          companyName?: Maybe<string>
        }>
        trainingCampaign?: Maybe<{
          __typename?: 'TrainingCampaign'
          id: number
          name: string
          allowSurveys: boolean
          allowSurveyComments: boolean
        }>
        enrollmentItem:
          | {
              __typename: 'KnowledgeRefresher'
              storeItem?: Maybe<
                | { __typename: 'CcmItem' }
                | {
                    __typename: 'StoreItem'
                    uuid: string
                    title: string
                    description?: Maybe<string>
                    type: string
                    expectedDurationInMinutes?: Maybe<number>
                    publishedAt?: Maybe<any>
                    publisher?: Maybe<{
                      __typename?: 'Publisher'
                      name: string
                    }>
                    artwork: {
                      __typename?: 'Artwork'
                      coverImage?: Maybe<string>
                      headerImage?: Maybe<string>
                    }
                    contentType?: Maybe<{
                      __typename?: 'Content'
                      icon?: Maybe<string>
                      name?: Maybe<string>
                      translationKey: string
                    }>
                    topics?: Maybe<
                      Array<{
                        __typename?: 'Topic'
                        translationKey?: Maybe<string>
                        topicCategory?: Maybe<{
                          __typename?: 'TopicCategory'
                          translationKey?: Maybe<string>
                        }>
                      }>
                    >
                    translations: Array<{
                      __typename?: 'Translation'
                      title?: Maybe<string>
                      description?: Maybe<string>
                      language?: Maybe<{
                        __typename?: 'Language'
                        code: string
                        name: string
                        icon: string
                      }>
                      activePackage?: Maybe<{
                        __typename?: 'Package'
                        launchPath?: Maybe<string>
                      }>
                    }>
                    subscriptions?: Maybe<
                      Array<{ __typename?: 'Subscription'; code: string }>
                    >
                  }
              >
            }
          | {
              __typename: 'Policy'
              title: string
              id: number
              description?: Maybe<string>
              minimumTime?: Maybe<number>
              publishedAt?: Maybe<any>
              type: PolicyTypes
              assets: Array<{
                __typename?: 'PolicyAsset'
                language: Languages
                url?: Maybe<string>
                acceptanceText: string
              }>
              translations: Array<{
                __typename?: 'Translation'
                title?: Maybe<string>
                description?: Maybe<string>
                language?: Maybe<{
                  __typename?: 'Language'
                  code: string
                  name: string
                  icon: string
                }>
                activePackage?: Maybe<{
                  __typename?: 'Package'
                  launchPath?: Maybe<string>
                }>
              }>
            }
          | {
              __typename: 'PurchasedCourse'
              userHasToAcknowledgePolicy?: Maybe<boolean>
              policyLocation?: Maybe<string>
              contentSource?: Maybe<string>
              storeItem?: Maybe<
                | {
                    __typename: 'CcmItem'
                    uuid: string
                    title: string
                    description?: Maybe<string>
                    type: string
                    expectedDurationInMinutes?: Maybe<number>
                    publishedAt?: Maybe<any>
                    artwork: {
                      __typename?: 'Artwork'
                      coverImage?: Maybe<string>
                    }
                    translations: Array<{
                      __typename?: 'Translation'
                      title?: Maybe<string>
                      description?: Maybe<string>
                      language?: Maybe<{
                        __typename?: 'Language'
                        code: string
                        name: string
                        icon: string
                      }>
                      activePackage?: Maybe<{
                        __typename?: 'Package'
                        launchPath?: Maybe<string>
                      }>
                    }>
                  }
                | {
                    __typename: 'StoreItem'
                    uuid: string
                    title: string
                    description?: Maybe<string>
                    type: string
                    expectedDurationInMinutes?: Maybe<number>
                    publishedAt?: Maybe<any>
                    publisher?: Maybe<{
                      __typename?: 'Publisher'
                      name: string
                    }>
                    artwork: {
                      __typename?: 'Artwork'
                      coverImage?: Maybe<string>
                      headerImage?: Maybe<string>
                    }
                    contentType?: Maybe<{
                      __typename?: 'Content'
                      icon?: Maybe<string>
                      name?: Maybe<string>
                      translationKey: string
                    }>
                    topics?: Maybe<
                      Array<{
                        __typename?: 'Topic'
                        translationKey?: Maybe<string>
                        topicCategory?: Maybe<{
                          __typename?: 'TopicCategory'
                          translationKey?: Maybe<string>
                        }>
                      }>
                    >
                    translations: Array<{
                      __typename?: 'Translation'
                      title?: Maybe<string>
                      description?: Maybe<string>
                      language?: Maybe<{
                        __typename?: 'Language'
                        code: string
                        name: string
                        icon: string
                      }>
                      activePackage?: Maybe<{
                        __typename?: 'Package'
                        launchPath?: Maybe<string>
                      }>
                    }>
                    subscriptions?: Maybe<
                      Array<{ __typename?: 'Subscription'; code: string }>
                    >
                  }
              >
            }
      }>
    >
  }>
}

export type KnowledgeRefresherQuizQuestionDetailsQueryVariables = Exact<{
  enrollmentId: Scalars['ID']
  questionId: Scalars['ID']
}>

export type KnowledgeRefresherQuizQuestionDetailsQuery = {
  __typename?: 'Query'
  knowledgeRefresherQuiz: {
    __typename?: 'KnowledgeRefresherQuiz'
    question?: Maybe<{
      __typename?: 'KnowledgeRefresherQuestion'
      id: number
      correctFeedback: string
      incorrectFeedback: string
      correctOption?: Maybe<{
        __typename?: 'KnowledgeRefresherQuestionOption'
        id: number
      }>
    }>
  }
}

export type KnowledgeRefresherQuizQueryVariables = Exact<{
  enrollmentId: Scalars['ID']
}>

export type KnowledgeRefresherQuizQuery = {
  __typename?: 'Query'
  knowledgeRefresherQuiz: {
    __typename?: 'KnowledgeRefresherQuiz'
    questions: Array<{
      __typename?: 'KnowledgeRefresherQuestion'
      id: number
      label: string
      options?: Maybe<
        Array<{
          __typename?: 'KnowledgeRefresherQuestionOption'
          id: number
          label: string
        }>
      >
    }>
  }
}

export type LeaderboardsQueryVariables = Exact<{ [key: string]: never }>

export type LeaderboardsQuery = {
  __typename?: 'Query'
  currentUser?: Maybe<{
    __typename?: 'User'
    gamification?: Maybe<{
      __typename?: 'UserGamification'
      leaderboardTimeWindow: LeaderboardPeriods
      leaderboardTypes?: Maybe<Array<LeaderboardTypes>>
      leaderboards?: Maybe<
        Array<{
          __typename?: 'LeaderboardNew'
          leaderboardType?: Maybe<LeaderboardTypes>
          leaderboardSize?: Maybe<number>
          leaders?: Maybe<
            Array<
              | {
                  __typename?: 'GroupLeader'
                  id: number
                  name: string
                  score?: Maybe<number>
                  includesCurrentUser?: Maybe<boolean>
                }
              | {
                  __typename?: 'IndividualLeader'
                  id: number
                  name: string
                  score?: Maybe<number>
                  includesCurrentUser?: Maybe<boolean>
                  rank?: Maybe<number>
                }
            >
          >
        }>
      >
      leaderboard?: Maybe<{
        __typename?: 'Leaderboard'
        leaderboardType?: Maybe<LeaderboardTypes>
        leaders?: Maybe<
          Array<{
            __typename?: 'LeaderboardGroup'
            id: number
            name: string
            score?: Maybe<number>
            includesCurrentUser?: Maybe<boolean>
          }>
        >
        leadersIndividualByAccount?: Maybe<
          Array<{
            __typename?: 'LeaderboardIndividualAccount'
            id: number
            name: string
            score?: Maybe<number>
            includesCurrentUser?: Maybe<boolean>
            rank?: Maybe<number>
          }>
        >
      }>
    }>
  }>
}

export type ManagerReportEnrollmentsQueryVariables = Exact<{
  userId?: Maybe<Scalars['Int']>
  id: Scalars['Int']
  complete?: Maybe<Scalars['Boolean']>
  optional?: Maybe<Scalars['Boolean']>
  due_soon?: Maybe<Scalars['Boolean']>
  overdue?: Maybe<Scalars['Boolean']>
  phishing_failures?: Maybe<Scalars['Boolean']>
}>

export type ManagerReportEnrollmentsQuery = {
  __typename?: 'Query'
  managerReport: {
    __typename?: 'ManagerReport'
    directReports?: Maybe<
      Array<{
        __typename?: 'DirectReport'
        email: string
        name: string
        completeEnrollments?: Maybe<
          Array<{
            __typename?: 'Enrollment'
            id: number
            lastUsedLanguage?: Maybe<string>
            createdAt: any
            state?: Maybe<string>
            type: EnrollmentTypes
            expiresAt?: Maybe<any>
            policyAcknowledgedAt?: Maybe<any>
            completedAt?: Maybe<any>
            selectedLanguage?: Maybe<string>
            timeSpentInSeconds?: Maybe<string>
            user?: Maybe<{ __typename?: 'User'; id: number }>
            trainingCampaign?: Maybe<{
              __typename?: 'TrainingCampaign'
              id: number
            }>
            enrollmentItem:
              | { __typename: 'KnowledgeRefresher' }
              | {
                  __typename: 'Policy'
                  title: string
                  id: number
                  description?: Maybe<string>
                  minimumTime?: Maybe<number>
                  publishedAt?: Maybe<any>
                  type: PolicyTypes
                  assets: Array<{
                    __typename?: 'PolicyAsset'
                    language: Languages
                    url?: Maybe<string>
                    acceptanceText: string
                  }>
                  translations: Array<{
                    __typename?: 'Translation'
                    title?: Maybe<string>
                    description?: Maybe<string>
                    language?: Maybe<{
                      __typename?: 'Language'
                      code: string
                      name: string
                      icon: string
                    }>
                    activePackage?: Maybe<{
                      __typename?: 'Package'
                      launchPath?: Maybe<string>
                    }>
                  }>
                }
              | {
                  __typename: 'PurchasedCourse'
                  userHasToAcknowledgePolicy?: Maybe<boolean>
                  policyLocation?: Maybe<string>
                  contentSource?: Maybe<string>
                  storeItem?: Maybe<
                    | {
                        __typename: 'CcmItem'
                        uuid: string
                        title: string
                        description?: Maybe<string>
                        type: string
                        expectedDurationInMinutes?: Maybe<number>
                        publishedAt?: Maybe<any>
                        artwork: {
                          __typename?: 'Artwork'
                          coverImage?: Maybe<string>
                        }
                        translations: Array<{
                          __typename?: 'Translation'
                          title?: Maybe<string>
                          description?: Maybe<string>
                          language?: Maybe<{
                            __typename?: 'Language'
                            code: string
                            name: string
                            icon: string
                          }>
                          activePackage?: Maybe<{
                            __typename?: 'Package'
                            launchPath?: Maybe<string>
                          }>
                        }>
                      }
                    | {
                        __typename: 'StoreItem'
                        uuid: string
                        title: string
                        description?: Maybe<string>
                        type: string
                        expectedDurationInMinutes?: Maybe<number>
                        publishedAt?: Maybe<any>
                        publisher?: Maybe<{
                          __typename?: 'Publisher'
                          name: string
                        }>
                        artwork: {
                          __typename?: 'Artwork'
                          coverImage?: Maybe<string>
                          headerImage?: Maybe<string>
                        }
                        contentType?: Maybe<{
                          __typename?: 'Content'
                          icon?: Maybe<string>
                          name?: Maybe<string>
                          translationKey: string
                        }>
                        topics?: Maybe<
                          Array<{
                            __typename?: 'Topic'
                            translationKey?: Maybe<string>
                            topicCategory?: Maybe<{
                              __typename?: 'TopicCategory'
                              translationKey?: Maybe<string>
                            }>
                          }>
                        >
                        translations: Array<{
                          __typename?: 'Translation'
                          title?: Maybe<string>
                          description?: Maybe<string>
                          language?: Maybe<{
                            __typename?: 'Language'
                            code: string
                            name: string
                            icon: string
                          }>
                          activePackage?: Maybe<{
                            __typename?: 'Package'
                            launchPath?: Maybe<string>
                          }>
                        }>
                        subscriptions?: Maybe<
                          Array<{ __typename?: 'Subscription'; code: string }>
                        >
                      }
                  >
                }
          }>
        >
        completeOptionalEnrollments?: Maybe<
          Array<{
            __typename?: 'Enrollment'
            id: number
            lastUsedLanguage?: Maybe<string>
            createdAt: any
            state?: Maybe<string>
            type: EnrollmentTypes
            expiresAt?: Maybe<any>
            policyAcknowledgedAt?: Maybe<any>
            completedAt?: Maybe<any>
            selectedLanguage?: Maybe<string>
            timeSpentInSeconds?: Maybe<string>
            user?: Maybe<{ __typename?: 'User'; id: number }>
            trainingCampaign?: Maybe<{
              __typename?: 'TrainingCampaign'
              id: number
            }>
            enrollmentItem:
              | { __typename: 'KnowledgeRefresher' }
              | {
                  __typename: 'Policy'
                  title: string
                  id: number
                  description?: Maybe<string>
                  minimumTime?: Maybe<number>
                  publishedAt?: Maybe<any>
                  type: PolicyTypes
                  assets: Array<{
                    __typename?: 'PolicyAsset'
                    language: Languages
                    url?: Maybe<string>
                    acceptanceText: string
                  }>
                  translations: Array<{
                    __typename?: 'Translation'
                    title?: Maybe<string>
                    description?: Maybe<string>
                    language?: Maybe<{
                      __typename?: 'Language'
                      code: string
                      name: string
                      icon: string
                    }>
                    activePackage?: Maybe<{
                      __typename?: 'Package'
                      launchPath?: Maybe<string>
                    }>
                  }>
                }
              | {
                  __typename: 'PurchasedCourse'
                  userHasToAcknowledgePolicy?: Maybe<boolean>
                  policyLocation?: Maybe<string>
                  contentSource?: Maybe<string>
                  storeItem?: Maybe<
                    | {
                        __typename: 'CcmItem'
                        uuid: string
                        title: string
                        description?: Maybe<string>
                        type: string
                        expectedDurationInMinutes?: Maybe<number>
                        publishedAt?: Maybe<any>
                        artwork: {
                          __typename?: 'Artwork'
                          coverImage?: Maybe<string>
                        }
                        translations: Array<{
                          __typename?: 'Translation'
                          title?: Maybe<string>
                          description?: Maybe<string>
                          language?: Maybe<{
                            __typename?: 'Language'
                            code: string
                            name: string
                            icon: string
                          }>
                          activePackage?: Maybe<{
                            __typename?: 'Package'
                            launchPath?: Maybe<string>
                          }>
                        }>
                      }
                    | {
                        __typename: 'StoreItem'
                        uuid: string
                        title: string
                        description?: Maybe<string>
                        type: string
                        expectedDurationInMinutes?: Maybe<number>
                        publishedAt?: Maybe<any>
                        publisher?: Maybe<{
                          __typename?: 'Publisher'
                          name: string
                        }>
                        artwork: {
                          __typename?: 'Artwork'
                          coverImage?: Maybe<string>
                          headerImage?: Maybe<string>
                        }
                        contentType?: Maybe<{
                          __typename?: 'Content'
                          icon?: Maybe<string>
                          name?: Maybe<string>
                          translationKey: string
                        }>
                        topics?: Maybe<
                          Array<{
                            __typename?: 'Topic'
                            translationKey?: Maybe<string>
                            topicCategory?: Maybe<{
                              __typename?: 'TopicCategory'
                              translationKey?: Maybe<string>
                            }>
                          }>
                        >
                        translations: Array<{
                          __typename?: 'Translation'
                          title?: Maybe<string>
                          description?: Maybe<string>
                          language?: Maybe<{
                            __typename?: 'Language'
                            code: string
                            name: string
                            icon: string
                          }>
                          activePackage?: Maybe<{
                            __typename?: 'Package'
                            launchPath?: Maybe<string>
                          }>
                        }>
                        subscriptions?: Maybe<
                          Array<{ __typename?: 'Subscription'; code: string }>
                        >
                      }
                  >
                }
          }>
        >
        dueSoonEnrollments?: Maybe<
          Array<{
            __typename?: 'Enrollment'
            id: number
            lastUsedLanguage?: Maybe<string>
            createdAt: any
            state?: Maybe<string>
            type: EnrollmentTypes
            expiresAt?: Maybe<any>
            policyAcknowledgedAt?: Maybe<any>
            completedAt?: Maybe<any>
            selectedLanguage?: Maybe<string>
            timeSpentInSeconds?: Maybe<string>
            user?: Maybe<{ __typename?: 'User'; id: number }>
            trainingCampaign?: Maybe<{
              __typename?: 'TrainingCampaign'
              id: number
            }>
            enrollmentItem:
              | { __typename: 'KnowledgeRefresher' }
              | {
                  __typename: 'Policy'
                  title: string
                  id: number
                  description?: Maybe<string>
                  minimumTime?: Maybe<number>
                  publishedAt?: Maybe<any>
                  type: PolicyTypes
                  assets: Array<{
                    __typename?: 'PolicyAsset'
                    language: Languages
                    url?: Maybe<string>
                    acceptanceText: string
                  }>
                  translations: Array<{
                    __typename?: 'Translation'
                    title?: Maybe<string>
                    description?: Maybe<string>
                    language?: Maybe<{
                      __typename?: 'Language'
                      code: string
                      name: string
                      icon: string
                    }>
                    activePackage?: Maybe<{
                      __typename?: 'Package'
                      launchPath?: Maybe<string>
                    }>
                  }>
                }
              | {
                  __typename: 'PurchasedCourse'
                  userHasToAcknowledgePolicy?: Maybe<boolean>
                  policyLocation?: Maybe<string>
                  contentSource?: Maybe<string>
                  storeItem?: Maybe<
                    | {
                        __typename: 'CcmItem'
                        uuid: string
                        title: string
                        description?: Maybe<string>
                        type: string
                        expectedDurationInMinutes?: Maybe<number>
                        publishedAt?: Maybe<any>
                        artwork: {
                          __typename?: 'Artwork'
                          coverImage?: Maybe<string>
                        }
                        translations: Array<{
                          __typename?: 'Translation'
                          title?: Maybe<string>
                          description?: Maybe<string>
                          language?: Maybe<{
                            __typename?: 'Language'
                            code: string
                            name: string
                            icon: string
                          }>
                          activePackage?: Maybe<{
                            __typename?: 'Package'
                            launchPath?: Maybe<string>
                          }>
                        }>
                      }
                    | {
                        __typename: 'StoreItem'
                        uuid: string
                        title: string
                        description?: Maybe<string>
                        type: string
                        expectedDurationInMinutes?: Maybe<number>
                        publishedAt?: Maybe<any>
                        publisher?: Maybe<{
                          __typename?: 'Publisher'
                          name: string
                        }>
                        artwork: {
                          __typename?: 'Artwork'
                          coverImage?: Maybe<string>
                          headerImage?: Maybe<string>
                        }
                        contentType?: Maybe<{
                          __typename?: 'Content'
                          icon?: Maybe<string>
                          name?: Maybe<string>
                          translationKey: string
                        }>
                        topics?: Maybe<
                          Array<{
                            __typename?: 'Topic'
                            translationKey?: Maybe<string>
                            topicCategory?: Maybe<{
                              __typename?: 'TopicCategory'
                              translationKey?: Maybe<string>
                            }>
                          }>
                        >
                        translations: Array<{
                          __typename?: 'Translation'
                          title?: Maybe<string>
                          description?: Maybe<string>
                          language?: Maybe<{
                            __typename?: 'Language'
                            code: string
                            name: string
                            icon: string
                          }>
                          activePackage?: Maybe<{
                            __typename?: 'Package'
                            launchPath?: Maybe<string>
                          }>
                        }>
                        subscriptions?: Maybe<
                          Array<{ __typename?: 'Subscription'; code: string }>
                        >
                      }
                  >
                }
          }>
        >
        pastDueEnrollments?: Maybe<
          Array<{
            __typename?: 'Enrollment'
            id: number
            lastUsedLanguage?: Maybe<string>
            createdAt: any
            state?: Maybe<string>
            type: EnrollmentTypes
            expiresAt?: Maybe<any>
            policyAcknowledgedAt?: Maybe<any>
            completedAt?: Maybe<any>
            selectedLanguage?: Maybe<string>
            timeSpentInSeconds?: Maybe<string>
            user?: Maybe<{ __typename?: 'User'; id: number }>
            trainingCampaign?: Maybe<{
              __typename?: 'TrainingCampaign'
              id: number
            }>
            enrollmentItem:
              | { __typename: 'KnowledgeRefresher' }
              | {
                  __typename: 'Policy'
                  title: string
                  id: number
                  description?: Maybe<string>
                  minimumTime?: Maybe<number>
                  publishedAt?: Maybe<any>
                  type: PolicyTypes
                  assets: Array<{
                    __typename?: 'PolicyAsset'
                    language: Languages
                    url?: Maybe<string>
                    acceptanceText: string
                  }>
                  translations: Array<{
                    __typename?: 'Translation'
                    title?: Maybe<string>
                    description?: Maybe<string>
                    language?: Maybe<{
                      __typename?: 'Language'
                      code: string
                      name: string
                      icon: string
                    }>
                    activePackage?: Maybe<{
                      __typename?: 'Package'
                      launchPath?: Maybe<string>
                    }>
                  }>
                }
              | {
                  __typename: 'PurchasedCourse'
                  userHasToAcknowledgePolicy?: Maybe<boolean>
                  policyLocation?: Maybe<string>
                  contentSource?: Maybe<string>
                  storeItem?: Maybe<
                    | {
                        __typename: 'CcmItem'
                        uuid: string
                        title: string
                        description?: Maybe<string>
                        type: string
                        expectedDurationInMinutes?: Maybe<number>
                        publishedAt?: Maybe<any>
                        artwork: {
                          __typename?: 'Artwork'
                          coverImage?: Maybe<string>
                        }
                        translations: Array<{
                          __typename?: 'Translation'
                          title?: Maybe<string>
                          description?: Maybe<string>
                          language?: Maybe<{
                            __typename?: 'Language'
                            code: string
                            name: string
                            icon: string
                          }>
                          activePackage?: Maybe<{
                            __typename?: 'Package'
                            launchPath?: Maybe<string>
                          }>
                        }>
                      }
                    | {
                        __typename: 'StoreItem'
                        uuid: string
                        title: string
                        description?: Maybe<string>
                        type: string
                        expectedDurationInMinutes?: Maybe<number>
                        publishedAt?: Maybe<any>
                        publisher?: Maybe<{
                          __typename?: 'Publisher'
                          name: string
                        }>
                        artwork: {
                          __typename?: 'Artwork'
                          coverImage?: Maybe<string>
                          headerImage?: Maybe<string>
                        }
                        contentType?: Maybe<{
                          __typename?: 'Content'
                          icon?: Maybe<string>
                          name?: Maybe<string>
                          translationKey: string
                        }>
                        topics?: Maybe<
                          Array<{
                            __typename?: 'Topic'
                            translationKey?: Maybe<string>
                            topicCategory?: Maybe<{
                              __typename?: 'TopicCategory'
                              translationKey?: Maybe<string>
                            }>
                          }>
                        >
                        translations: Array<{
                          __typename?: 'Translation'
                          title?: Maybe<string>
                          description?: Maybe<string>
                          language?: Maybe<{
                            __typename?: 'Language'
                            code: string
                            name: string
                            icon: string
                          }>
                          activePackage?: Maybe<{
                            __typename?: 'Package'
                            launchPath?: Maybe<string>
                          }>
                        }>
                        subscriptions?: Maybe<
                          Array<{ __typename?: 'Subscription'; code: string }>
                        >
                      }
                  >
                }
          }>
        >
        failures?: Maybe<
          Array<{
            __typename?: 'PhishingCampaignRecipient'
            emailTemplate: {
              __typename?: 'PhishingTemplate'
              name?: Maybe<string>
            }
            failureDetails?: Maybe<
              Array<{
                __typename?: 'PhishingCampaignFailureDetails'
                type: string
                date: any
              }>
            >
          }>
        >
      }>
    >
  }
}

export type ManagerReportUsersQueryVariables = Exact<{
  userId?: Maybe<Scalars['Int']>
  complete?: Maybe<Scalars['Boolean']>
  optional?: Maybe<Scalars['Boolean']>
  due_soon?: Maybe<Scalars['Boolean']>
  overdue?: Maybe<Scalars['Boolean']>
  phishing_failures?: Maybe<Scalars['Boolean']>
  all?: Maybe<Scalars['Boolean']>
}>

export type ManagerReportUsersQuery = {
  __typename?: 'Query'
  managerReport: {
    __typename?: 'ManagerReport'
    usersWithCompleteOptionalEnrollments?: Maybe<
      Array<{
        __typename?: 'DirectReport'
        id: number
        email: string
        name: string
        trainingPercentComplete: number
        phishingStatistics?: Maybe<{
          __typename?: 'UserPhishingStatistics'
          totalFailures: number
          totalMisses: number
          emailReported: number
          totalDelivered: number
        }>
      }>
    >
    usersWithCompleteEnrollments?: Maybe<
      Array<{
        __typename?: 'DirectReport'
        id: number
        email: string
        name: string
        trainingPercentComplete: number
        phishingStatistics?: Maybe<{
          __typename?: 'UserPhishingStatistics'
          totalFailures: number
          totalMisses: number
          emailReported: number
          totalDelivered: number
        }>
      }>
    >
    directReports?: Maybe<
      Array<{
        __typename?: 'DirectReport'
        id: number
        email: string
        name: string
        trainingPercentComplete: number
        phishingStatistics?: Maybe<{
          __typename?: 'UserPhishingStatistics'
          totalFailures: number
          totalMisses: number
          emailReported: number
          totalDelivered: number
        }>
      }>
    >
    usersWithOverDueEnrollments?: Maybe<
      Array<{
        __typename?: 'DirectReport'
        id: number
        email: string
        name: string
        trainingPercentComplete: number
        phishingStatistics?: Maybe<{
          __typename?: 'UserPhishingStatistics'
          totalFailures: number
          totalMisses: number
          emailReported: number
          totalDelivered: number
        }>
      }>
    >
    usersWithDueSoonEnrollments?: Maybe<
      Array<{
        __typename?: 'DirectReport'
        id: number
        email: string
        name: string
        trainingPercentComplete: number
        phishingStatistics?: Maybe<{
          __typename?: 'UserPhishingStatistics'
          totalFailures: number
          totalMisses: number
          emailReported: number
          totalDelivered: number
        }>
      }>
    >
    usersWithPhishingFailures?: Maybe<
      Array<{
        __typename?: 'DirectReport'
        id: number
        email: string
        name: string
        trainingPercentComplete: number
        phishingStatistics?: Maybe<{
          __typename?: 'UserPhishingStatistics'
          totalFailures: number
          totalMisses: number
          emailReported: number
          totalDelivered: number
        }>
      }>
    >
  }
}

export type ManagerReportQueryVariables = Exact<{
  userId?: Maybe<Scalars['Int']>
}>

export type ManagerReportQuery = {
  __typename?: 'Query'
  managerReport: {
    __typename?: 'ManagerReport'
    phishingFailures?: Maybe<number>
    phishingNotReported?: Maybe<number>
    phishingReported?: Maybe<number>
    phishingTotal?: Maybe<number>
    riskScore?: Maybe<number>
    trainingPercentComplete: number
    mandatoryEnrollmentsCount: number
    mandatoryEnrollmentsCompleteCount: number
    usersWithCompleteOptionalEnrollmentsCount: number
    usersWithCompleteEnrollmentsCount: number
    usersWithDueSoonEnrollmentsCount: number
    usersWithOverDueEnrollmentsCount: number
    usersWithPhishingFailuresCount?: Maybe<number>
    totalMembersCount: number
  }
}

export type OptionalEnrollmentsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}>

export type OptionalEnrollmentsQuery = {
  __typename?: 'Query'
  currentUser?: Maybe<{
    __typename?: 'User'
    optionalEnrollments?: Maybe<
      Array<{
        __typename?: 'Enrollment'
        id: number
        lastUsedLanguage?: Maybe<string>
        createdAt: any
        state?: Maybe<string>
        type: EnrollmentTypes
        expiresAt?: Maybe<any>
        policyAcknowledgedAt?: Maybe<any>
        completedAt?: Maybe<any>
        selectedLanguage?: Maybe<string>
        timeSpentInSeconds?: Maybe<string>
        user?: Maybe<{
          __typename?: 'User'
          id: number
          companyName?: Maybe<string>
        }>
        trainingCampaign?: Maybe<{
          __typename?: 'TrainingCampaign'
          id: number
          name: string
          allowSurveys: boolean
          allowSurveyComments: boolean
        }>
        enrollmentItem:
          | {
              __typename: 'KnowledgeRefresher'
              storeItem?: Maybe<
                | { __typename: 'CcmItem' }
                | {
                    __typename: 'StoreItem'
                    uuid: string
                    title: string
                    description?: Maybe<string>
                    type: string
                    expectedDurationInMinutes?: Maybe<number>
                    publishedAt?: Maybe<any>
                    publisher?: Maybe<{
                      __typename?: 'Publisher'
                      name: string
                    }>
                    artwork: {
                      __typename?: 'Artwork'
                      coverImage?: Maybe<string>
                      headerImage?: Maybe<string>
                    }
                    contentType?: Maybe<{
                      __typename?: 'Content'
                      icon?: Maybe<string>
                      name?: Maybe<string>
                      translationKey: string
                    }>
                    topics?: Maybe<
                      Array<{
                        __typename?: 'Topic'
                        translationKey?: Maybe<string>
                        topicCategory?: Maybe<{
                          __typename?: 'TopicCategory'
                          translationKey?: Maybe<string>
                        }>
                      }>
                    >
                    translations: Array<{
                      __typename?: 'Translation'
                      title?: Maybe<string>
                      description?: Maybe<string>
                      language?: Maybe<{
                        __typename?: 'Language'
                        code: string
                        name: string
                        icon: string
                      }>
                      activePackage?: Maybe<{
                        __typename?: 'Package'
                        launchPath?: Maybe<string>
                      }>
                    }>
                    subscriptions?: Maybe<
                      Array<{ __typename?: 'Subscription'; code: string }>
                    >
                  }
              >
            }
          | {
              __typename: 'Policy'
              title: string
              id: number
              description?: Maybe<string>
              minimumTime?: Maybe<number>
              publishedAt?: Maybe<any>
              type: PolicyTypes
              assets: Array<{
                __typename?: 'PolicyAsset'
                language: Languages
                url?: Maybe<string>
                acceptanceText: string
              }>
              translations: Array<{
                __typename?: 'Translation'
                title?: Maybe<string>
                description?: Maybe<string>
                language?: Maybe<{
                  __typename?: 'Language'
                  code: string
                  name: string
                  icon: string
                }>
                activePackage?: Maybe<{
                  __typename?: 'Package'
                  launchPath?: Maybe<string>
                }>
              }>
            }
          | {
              __typename: 'PurchasedCourse'
              userHasToAcknowledgePolicy?: Maybe<boolean>
              policyLocation?: Maybe<string>
              contentSource?: Maybe<string>
              storeItem?: Maybe<
                | {
                    __typename: 'CcmItem'
                    uuid: string
                    title: string
                    description?: Maybe<string>
                    type: string
                    expectedDurationInMinutes?: Maybe<number>
                    publishedAt?: Maybe<any>
                    artwork: {
                      __typename?: 'Artwork'
                      coverImage?: Maybe<string>
                    }
                    translations: Array<{
                      __typename?: 'Translation'
                      title?: Maybe<string>
                      description?: Maybe<string>
                      language?: Maybe<{
                        __typename?: 'Language'
                        code: string
                        name: string
                        icon: string
                      }>
                      activePackage?: Maybe<{
                        __typename?: 'Package'
                        launchPath?: Maybe<string>
                      }>
                    }>
                  }
                | {
                    __typename: 'StoreItem'
                    uuid: string
                    title: string
                    description?: Maybe<string>
                    type: string
                    expectedDurationInMinutes?: Maybe<number>
                    publishedAt?: Maybe<any>
                    publisher?: Maybe<{
                      __typename?: 'Publisher'
                      name: string
                    }>
                    artwork: {
                      __typename?: 'Artwork'
                      coverImage?: Maybe<string>
                      headerImage?: Maybe<string>
                    }
                    contentType?: Maybe<{
                      __typename?: 'Content'
                      icon?: Maybe<string>
                      name?: Maybe<string>
                      translationKey: string
                    }>
                    topics?: Maybe<
                      Array<{
                        __typename?: 'Topic'
                        translationKey?: Maybe<string>
                        topicCategory?: Maybe<{
                          __typename?: 'TopicCategory'
                          translationKey?: Maybe<string>
                        }>
                      }>
                    >
                    translations: Array<{
                      __typename?: 'Translation'
                      title?: Maybe<string>
                      description?: Maybe<string>
                      language?: Maybe<{
                        __typename?: 'Language'
                        code: string
                        name: string
                        icon: string
                      }>
                      activePackage?: Maybe<{
                        __typename?: 'Package'
                        launchPath?: Maybe<string>
                      }>
                    }>
                    subscriptions?: Maybe<
                      Array<{ __typename?: 'Subscription'; code: string }>
                    >
                  }
              >
            }
      }>
    >
  }>
}

export type ProfileQueryVariables = Exact<{ [key: string]: never }>

export type ProfileQuery = {
  __typename?: 'Query'
  currentPartnerAdmin?: Maybe<{
    __typename?: 'PartnerAdmin'
    id: number
    email: string
    partnerId?: Maybe<number>
  }>
  currentAdmin?: Maybe<{ __typename?: 'SiteAdmin'; id: number; email: string }>
  currentImpersonatingUser?: Maybe<{
    __typename?: 'User'
    id: number
    email: string
  }>
  currentUser?: Maybe<{
    __typename?: 'User'
    id: number
    email: string
    domain?: Maybe<string>
    language?: Maybe<string>
    firstName?: Maybe<string>
    lastName?: Maybe<string>
    jobTitle?: Maybe<string>
    subscriptionLevel?: Maybe<string>
    billingType?: Maybe<string>
    allowAnalytics: boolean
    companyName?: Maybe<string>
    accountId?: Maybe<string>
    accountDomain?: Maybe<string>
    popcornTheaterReduceMotion: boolean
    lxDisableTour: boolean
    deviceLinkToken?: Maybe<string>
    passlessEnabled: boolean
    samlEnabled: boolean
    disableNonSamlLogins?: Maybe<boolean>
    customHelpEnabled?: Maybe<boolean>
    customHelpUrl?: Maybe<string>
    optionalTrainingEnabled: boolean
    aidaOptionalTrainingEnabled: boolean
    enrollmentLanguageSelections: Array<string>
    mobileAppEnabled?: Maybe<boolean>
    adminLocale?: Maybe<string>
    messagesEnabled?: Maybe<boolean>
    managerPhishingEnabled?: Maybe<boolean>
    managerRiskScoreEnabled?: Maybe<boolean>
    managerTrainingEnabled?: Maybe<boolean>
    managerUserInfoEnabled?: Maybe<boolean>
    managerReport?: Maybe<{
      __typename?: 'ManagerReport'
      totalMembersCount: number
    }>
    linkedDevices?: Maybe<
      Array<{
        __typename?: 'DeviceDetails'
        id: number
        createdAt: any
        deviceId: string
        label?: Maybe<string>
      }>
    >
    gamification?: Maybe<{
      __typename?: 'UserGamification'
      badgesEnabled?: Maybe<boolean>
      badgesEarned?: Maybe<Array<Badges>>
      leaderboardTypes?: Maybe<Array<LeaderboardTypes>>
      leaderboards?: Maybe<
        Array<{
          __typename?: 'LeaderboardNew'
          leaderboardSize?: Maybe<number>
          leaders?: Maybe<
            Array<
              | {
                  __typename?: 'GroupLeader'
                  includesCurrentUser?: Maybe<boolean>
                }
              | {
                  __typename?: 'IndividualLeader'
                  includesCurrentUser?: Maybe<boolean>
                }
            >
          >
        }>
      >
      leaderboard?: Maybe<{
        __typename?: 'Leaderboard'
        leaderboardType?: Maybe<LeaderboardTypes>
        leadersIndividualByAccount?: Maybe<
          Array<{
            __typename?: 'LeaderboardIndividualAccount'
            includesCurrentUser?: Maybe<boolean>
          }>
        >
        leaders?: Maybe<
          Array<{
            __typename?: 'LeaderboardGroup'
            includesCurrentUser?: Maybe<boolean>
          }>
        >
      }>
    }>
    languageSettings?: Maybe<{
      __typename?: 'UserSettingsLanguages'
      trainingLocale?: Maybe<string>
    }>
    accountLanguageSettings?: Maybe<{
      __typename?: 'AccountSettingsLanguageDefault'
      trainingLocale?: Maybe<string>
    }>
    statistics?: Maybe<{ __typename?: 'UserStatistics'; id: number }>
    learnerMessages?: Maybe<
      Array<{
        __typename?: 'LearnerMessage'
        id: number
        subject: string
        content: string
        status: MessagesCampaignRecipientStatuses
        createdAt: any
      }>
    >
  }>
}

export type RecommendationsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
  query?: Maybe<Scalars['JSON']>
}>

export type RecommendationsQuery = {
  __typename?: 'Query'
  currentUser?: Maybe<{
    __typename?: 'User'
    lxRecommendations?: Maybe<
      Array<{
        __typename?: 'ModstoreRecommendation'
        uuid: string
        rating: number
        storeItem: {
          __typename?: 'StoreItem'
          uuid: string
          title: string
          description?: Maybe<string>
          type: string
          expectedDurationInMinutes?: Maybe<number>
          publishedAt?: Maybe<any>
          publisher?: Maybe<{ __typename?: 'Publisher'; name: string }>
          artwork: {
            __typename?: 'Artwork'
            coverImage?: Maybe<string>
            headerImage?: Maybe<string>
          }
          contentType?: Maybe<{
            __typename?: 'Content'
            icon?: Maybe<string>
            name?: Maybe<string>
            translationKey: string
          }>
          topics?: Maybe<
            Array<{
              __typename?: 'Topic'
              translationKey?: Maybe<string>
              topicCategory?: Maybe<{
                __typename?: 'TopicCategory'
                translationKey?: Maybe<string>
              }>
            }>
          >
          translations: Array<{
            __typename?: 'Translation'
            title?: Maybe<string>
            description?: Maybe<string>
            language?: Maybe<{
              __typename?: 'Language'
              code: string
              name: string
              icon: string
            }>
            activePackage?: Maybe<{
              __typename?: 'Package'
              launchPath?: Maybe<string>
            }>
          }>
          subscriptions?: Maybe<
            Array<{ __typename?: 'Subscription'; code: string }>
          >
        }
        enrollment?: Maybe<{
          __typename?: 'Enrollment'
          id: number
          lastUsedLanguage?: Maybe<string>
          createdAt: any
          state?: Maybe<string>
          type: EnrollmentTypes
          expiresAt?: Maybe<any>
          policyAcknowledgedAt?: Maybe<any>
          completedAt?: Maybe<any>
          selectedLanguage?: Maybe<string>
          timeSpentInSeconds?: Maybe<string>
          user?: Maybe<{
            __typename?: 'User'
            id: number
            companyName?: Maybe<string>
          }>
          trainingCampaign?: Maybe<{
            __typename?: 'TrainingCampaign'
            id: number
            name: string
            allowSurveys: boolean
            allowSurveyComments: boolean
          }>
          enrollmentItem:
            | {
                __typename: 'KnowledgeRefresher'
                storeItem?: Maybe<
                  | { __typename: 'CcmItem' }
                  | {
                      __typename: 'StoreItem'
                      uuid: string
                      title: string
                      description?: Maybe<string>
                      type: string
                      expectedDurationInMinutes?: Maybe<number>
                      publishedAt?: Maybe<any>
                      publisher?: Maybe<{
                        __typename?: 'Publisher'
                        name: string
                      }>
                      artwork: {
                        __typename?: 'Artwork'
                        coverImage?: Maybe<string>
                        headerImage?: Maybe<string>
                      }
                      contentType?: Maybe<{
                        __typename?: 'Content'
                        icon?: Maybe<string>
                        name?: Maybe<string>
                        translationKey: string
                      }>
                      topics?: Maybe<
                        Array<{
                          __typename?: 'Topic'
                          translationKey?: Maybe<string>
                          topicCategory?: Maybe<{
                            __typename?: 'TopicCategory'
                            translationKey?: Maybe<string>
                          }>
                        }>
                      >
                      translations: Array<{
                        __typename?: 'Translation'
                        title?: Maybe<string>
                        description?: Maybe<string>
                        language?: Maybe<{
                          __typename?: 'Language'
                          code: string
                          name: string
                          icon: string
                        }>
                        activePackage?: Maybe<{
                          __typename?: 'Package'
                          launchPath?: Maybe<string>
                        }>
                      }>
                      subscriptions?: Maybe<
                        Array<{ __typename?: 'Subscription'; code: string }>
                      >
                    }
                >
              }
            | {
                __typename: 'Policy'
                title: string
                id: number
                description?: Maybe<string>
                minimumTime?: Maybe<number>
                publishedAt?: Maybe<any>
                type: PolicyTypes
                assets: Array<{
                  __typename?: 'PolicyAsset'
                  language: Languages
                  url?: Maybe<string>
                  acceptanceText: string
                }>
                translations: Array<{
                  __typename?: 'Translation'
                  title?: Maybe<string>
                  description?: Maybe<string>
                  language?: Maybe<{
                    __typename?: 'Language'
                    code: string
                    name: string
                    icon: string
                  }>
                  activePackage?: Maybe<{
                    __typename?: 'Package'
                    launchPath?: Maybe<string>
                  }>
                }>
              }
            | {
                __typename: 'PurchasedCourse'
                userHasToAcknowledgePolicy?: Maybe<boolean>
                policyLocation?: Maybe<string>
                contentSource?: Maybe<string>
                storeItem?: Maybe<
                  | {
                      __typename: 'CcmItem'
                      uuid: string
                      title: string
                      description?: Maybe<string>
                      type: string
                      expectedDurationInMinutes?: Maybe<number>
                      publishedAt?: Maybe<any>
                      artwork: {
                        __typename?: 'Artwork'
                        coverImage?: Maybe<string>
                      }
                      translations: Array<{
                        __typename?: 'Translation'
                        title?: Maybe<string>
                        description?: Maybe<string>
                        language?: Maybe<{
                          __typename?: 'Language'
                          code: string
                          name: string
                          icon: string
                        }>
                        activePackage?: Maybe<{
                          __typename?: 'Package'
                          launchPath?: Maybe<string>
                        }>
                      }>
                    }
                  | {
                      __typename: 'StoreItem'
                      uuid: string
                      title: string
                      description?: Maybe<string>
                      type: string
                      expectedDurationInMinutes?: Maybe<number>
                      publishedAt?: Maybe<any>
                      publisher?: Maybe<{
                        __typename?: 'Publisher'
                        name: string
                      }>
                      artwork: {
                        __typename?: 'Artwork'
                        coverImage?: Maybe<string>
                        headerImage?: Maybe<string>
                      }
                      contentType?: Maybe<{
                        __typename?: 'Content'
                        icon?: Maybe<string>
                        name?: Maybe<string>
                        translationKey: string
                      }>
                      topics?: Maybe<
                        Array<{
                          __typename?: 'Topic'
                          translationKey?: Maybe<string>
                          topicCategory?: Maybe<{
                            __typename?: 'TopicCategory'
                            translationKey?: Maybe<string>
                          }>
                        }>
                      >
                      translations: Array<{
                        __typename?: 'Translation'
                        title?: Maybe<string>
                        description?: Maybe<string>
                        language?: Maybe<{
                          __typename?: 'Language'
                          code: string
                          name: string
                          icon: string
                        }>
                        activePackage?: Maybe<{
                          __typename?: 'Package'
                          launchPath?: Maybe<string>
                        }>
                      }>
                      subscriptions?: Maybe<
                        Array<{ __typename?: 'Subscription'; code: string }>
                      >
                    }
                >
              }
        }>
      }>
    >
  }>
}

export type UploadedContentQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>
  per?: Maybe<Scalars['Int']>
}>

export type UploadedContentQuery = {
  __typename?: 'Query'
  ccmItems?: Maybe<
    Array<{
      __typename?: 'CcmItem'
      uuid: string
      title: string
      description?: Maybe<string>
      type: string
      expectedDurationInMinutes?: Maybe<number>
      publishedAt?: Maybe<any>
      artwork: { __typename?: 'Artwork'; coverImage?: Maybe<string> }
      translations: Array<{
        __typename?: 'Translation'
        title?: Maybe<string>
        description?: Maybe<string>
        language?: Maybe<{
          __typename?: 'Language'
          code: string
          name: string
          icon: string
        }>
        activePackage?: Maybe<{
          __typename?: 'Package'
          launchPath?: Maybe<string>
        }>
      }>
    }>
  >
}
