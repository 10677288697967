import { LeaderboardTypes, LeaderboardPeriods } from '../state/api/graphql'

export interface ILeaderboardGroup {
  id: number
  name: string
  score: number
  includesCurrentUser: boolean | null
}

export interface ILeaderboardIndividualByAccount {
  id: number
  name: string
  score: number
  includesCurrentUser: boolean | null
  rank: number
}

export class UnifiedLeaderboard {
  leaderboards?: (GroupLeaderboard | IndividualLeaderboard)[]

  constructor(data: any) {
    const gamification = data.currentUser?.gamification
    if (gamification?.leaderboards) {
      this.setup(
        gamification?.leaderboards,
        gamification?.leaderboardTimeWindow
      )
    } else {
      // Backward compatibility until leaderboard data is migrated
      const legacyLeaderboard = gamification?.leaderboard
      if (legacyLeaderboard) {
        this.setupLegacyLeaderboard(
          legacyLeaderboard,
          gamification?.leaderboardTimeWindow
        )
      }
    }
  }

  setupLegacyLeaderboard(leaderboard: any, timeWindow: LeaderboardPeriods) {
    if (leaderboard.leaderboardType === LeaderboardTypes.GroupLeaderboard) {
      this.setup(
        [
          {
            leaderboardType: LeaderboardTypes.GroupLeaderboard,
            leaders: leaderboard.leaders,
          },
        ],
        timeWindow
      )
    } else if (
      leaderboard.leaderboardType === LeaderboardTypes.IndividualLeaderboard
    ) {
      this.setup(
        [
          {
            leaderboardType: LeaderboardTypes.IndividualLeaderboard,
            leaders: leaderboard.leadersIndividualByAccount,
          },
        ],
        timeWindow
      )
    }
  }

  setup(leaderboards: any, timeWindow: LeaderboardPeriods) {
    this.leaderboards = leaderboards?.map(leaderboard => {
      const type = leaderboard.leaderboardType
      if (type === LeaderboardTypes.IndividualLeaderboard) {
        const leaders = leaderboard.leaders || []
        const leaderboardSize = leaderboard.leaderboardSize || leaders.length
        return new IndividualLeaderboard(leaders, leaderboardSize, timeWindow)
      } else {
        return new GroupLeaderboard(leaderboard.leaders || [])
      }
    })
  }

  get leaderboardTypes(): LeaderboardTypes[] {
    if (this.leaderboards === undefined) {
      return []
    }
    return this.leaderboards.map(leaderboard => leaderboard.leaderboardType)
  }
}

export class IndividualLeaderboard {
  leaderboardTimeWindow: LeaderboardPeriods
  leaderboardType = LeaderboardTypes.IndividualLeaderboard
  leaders: ILeaderboardIndividualByAccount[]
  leaderboardSize: number

  constructor(
    leaders: ILeaderboardIndividualByAccount[],
    leaderboardSize: number,
    timeWindow: LeaderboardPeriods
  ) {
    this.leaders = leaders
    this.leaderboardTimeWindow = timeWindow
    this.leaderboardSize = leaderboardSize
  }

  isEmpty() {
    return this.leaders.length === 0 || this.leaders.every(l => l.score === 0)
  }
}

export class GroupLeaderboard {
  leaderboardType = LeaderboardTypes.GroupLeaderboard
  leaders: ILeaderboardGroup[]

  constructor(leaders: ILeaderboardGroup[]) {
    this.leaders = leaders
  }

  get leaderboardSize() {
    return this.leaders.length
  }

  isEmpty() {
    return this.leaders.length === 0 || this.leaders.every(l => l.score === 0)
  }
}
