import {
  Enrollment as GqlEnrollment,
  StoreItem,
  Topic,
} from '@state/api/graphql'
import compact from 'lodash/compact'
import uniq from 'lodash/uniq'
import snakeCase from 'lodash/snakeCase'

interface ILanguageOption {
  title: string
  description: string
  code: string
  launchPath: string
  icon: string
  acceptanceText: string
}

export enum EnrollmentStatuses {
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  NOT_STARTED = 'not_started',
  DO_POLICY = 'do_policy',
}

enum EnrollmentIcon {
  POLICY = 'file',
  COURSE = 'graduation-cap',
  ASSESSMENT = 'list-alt',
  GAME = 'gamepad',
  MOBILE_FIRST_MODULE = 'mobile',
  TRAINING_MODULE = 'graduation-cap',
  VIDEO_MODULE = 'video',
}

interface IEnrollment {
  id: number
  uuid?: string
  title: string
  description: string
  enrollmentType: string
  coverImage?: string
  duration: number | null
  policyType?: string
  publisher: string
  contentSource: string
  originalLanguageOptions: ILanguageOption[] | any[]
  languageOrder: string[]
  userId: number | null
  enrollmentId: number | null
  campaigns: string[]
  hasTrainingCampaign: boolean
  expiresAt: Date | null
  completedAt: Date | null | string
  state: EnrollmentStatuses
  timeSpentInSeconds?: string
  certificateUrl?: string | null
  selectedLanguage?: string
  createdAt?: Date | null
  lastUsedLanguage: string

  // course only
  policyUrl?: string | null
  theme: any
  launchPath: string
  allowSurveys: boolean
  allowSurveyComments: boolean
  surveyCompletedAt?: string | null
  policyAcceptedAt?: string | null
  headerImage?: string | null
}

export default class Enrollment implements IEnrollment {
  id = 0
  uuid? = ''
  title = ''
  description = ''
  enrollmentType = ''
  coverImage? = ''
  duration: number | null = 0
  publisher = ''
  publishedAt = null
  contentSource = ''
  policyType = ''
  originalLanguageOptions: ILanguageOption[] = []
  userId: number | null = null
  enrollmentId: number | null = null
  campaigns: string[] = []
  hasTrainingCampaign = true
  languageOrder: string[] = []
  lastUsedLanguage: string = ''
  allowSurveys = false
  allowSurveyComments = false
  surveyCompletedAt = null
  policyAcceptedAt = null
  policyUrl? = ''
  theme = {}
  launchPath = ''
  createdAt = null
  expiresAt = null
  completedAt = null || ''
  state = EnrollmentStatuses.NOT_STARTED
  timeSpentInSeconds? = ''
  headerImage? = ''
  selectedLanguage? = ''
  rating? = 0
  storeItem = {} as StoreItem

  constructor(
    enrollment?: GqlEnrollment,
    languageOrder?: string[],
    storeItem?: StoreItem,
    rating?: number,
  ) {
    this.rating = rating
    if (enrollment) {
      this.id = enrollment.id
      this.userId = enrollment.user?.id || null
      this.enrollmentId = enrollment.id
      this.createdAt = enrollment.createdAt
      this.expiresAt = enrollment.expiresAt
      this.completedAt = enrollment.completedAt
      this.hasTrainingCampaign = !!enrollment.trainingCampaign
      this.lastUsedLanguage = enrollment.lastUsedLanguage || ''
      if (this.hasTrainingCampaign) {
        this.allowSurveyComments =
          enrollment.trainingCampaign?.allowSurveyComments || false
        this.allowSurveys = enrollment.trainingCampaign?.allowSurveys || false
      } else {
        this.allowSurveyComments = true
        this.allowSurveys = true
      }
      this.storeItem = storeItem || {} as StoreItem
      this.campaigns = [enrollment.trainingCampaign?.name || '']
      this.policyAcceptedAt = enrollment.policyAcknowledgedAt
      this.surveyCompletedAt = enrollment.survey?.createdAt
      // ensure languages are in correct format
      this.languageOrder = this.calculateLanguageOrder(
        enrollment.lastUsedLanguage,
        languageOrder,
      )
      this.state = this.mapState(enrollment.state)
      this.timeSpentInSeconds = enrollment.timeSpentInSeconds || undefined
      this.selectedLanguage = enrollment.selectedLanguage || undefined
    }
  }

  private calculateLanguageOrder(lastUsedLanguage, languageOrder) {
    const languages = uniq(compact([lastUsedLanguage, ...languageOrder]))
    return languages.map(l => l.toLowerCase().replace('_', '-'))
  }

  private mapState(state): EnrollmentStatuses {
    if (state === 'complete') {
      return EnrollmentStatuses.COMPLETED
    }

    return state
      ? EnrollmentStatuses[state.toUpperCase()]
      : EnrollmentStatuses.NOT_STARTED
  }

  get localizedTitle() {
    return this.languageOptions[0].title
  }

  get topics(): Topic[] {
    return []
  }

  // most enrollments don't have certificates
  get certificateUrl(): string | null {
    return null
  }

  get languageOptions() {
    const orderedLanguageOptions = this.languageOrder
      .map(language => {
        return this.originalLanguageOptions.find(tr => {
          return language === tr.code
        })
      })
      .filter(obj => obj) as ILanguageOption[]
    const unorderedLanguageOptions = this.originalLanguageOptions.filter(
      tr => !this.languageOrder.includes(tr.code),
    )
    return orderedLanguageOptions
      .concat(unorderedLanguageOptions)
      .filter(tr => tr.launchPath.length)
  }

  protected mapTranslation(tr, enrollment?): ILanguageOption {
    const languageOption: ILanguageOption = {
      title: tr.title,
      description: tr.description,
      launchPath: '',
      code: '',
      icon: '',
      acceptanceText: '',
    }

    if (tr.activePackage !== null) {
      languageOption.launchPath = tr.activePackage.launchPath
      // Not the neatest solution. But the policy has no idea what
      // enrollment it is attached to when graphql generates the url
      if (enrollment && languageOption.launchPath) {
        languageOption.launchPath = languageOption.launchPath.replace(
          /enrollment_id=\d*/g,
          `enrollment_id=${enrollment.id}`,
        )
      }
    }
    languageOption.code = tr.language.code
    languageOption.icon = tr.language.icon
    if (enrollment && enrollment.enrollmentItem.type === 'URL') {
      const asset = enrollment.enrollmentItem.assets.find(
        asset =>
          asset.language.toLowerCase().replace('_', '-') === tr.language.code,
      )
      if (asset) {
        languageOption.acceptanceText = asset.acceptanceText
        languageOption.launchPath = asset.url
      }
    }
    return languageOption
  }

  get icon() {
    return EnrollmentIcon[snakeCase(this.analyticsEnrollmentType).toUpperCase()]
  }

  get isMandatory() {
    return !!this.expiresAt
  }

  get hasNotEnrolled() {
    return false
  }

  get hasNotStarted() {
    return this.state === 'not_started'
  }

  get isComplete() {
    return this.state === 'completed'
  }

  get isInProgress() {
    return this.state === 'in_progress'
  }

  get isAssessment() {
    return this.enrollmentType === 'Assessment'
  }

  get isCourse() {
    return this.enrollmentType === 'Course'
  }

  get isPolicy() {
    return this.enrollmentType === 'Policy' && this.policyType != 'AIDA'
  }

  get isPolicyQuiz() {
    return this.enrollmentType === 'Policy' && this.policyType === 'AIDA'
  }

  get isKnowledgeRefresher() {
    return this.enrollmentType === 'KnowledgeRefresher'
  }

  get isUrlPolicy() {
    return this.policyType === 'URL'
  }

  get doPolicy() {
    return this.state === 'do_policy'
  }

  get showPolicyBox(): boolean {
    return !!(this.showPolicyAcceptance || this.policyAcceptedAt)
  }

  get showPolicyAcceptance(): boolean {
    return this.hasPolicy && this.isCourse && this.state === 'do_policy'
  }

  get hasPolicy(): boolean {
    return !!this.policyUrl
  }

  get analyticsEnrollmentType() {
    return ''
  }

  get typeTranslationKey(): string {
    return `enrollment.types.${snakeCase(
      this.analyticsEnrollmentType,
    ).toLowerCase()}`
  }

  get contentType() {
    return {
      icon: this.icon,
      translationKey: this.typeTranslationKey,
    }
  }

  get subscriptions() {
    return []
  }

  get mixPanelData() {
    return {
      Title: this.title,
      Uuid: this.uuid,
      Publisher: this.publisher,
      'Content Type': this.enrollmentType,
      'Store Item Type': this.analyticsEnrollmentType,
      Themed: !this.theme,
      'Content Source': this.contentSource,
      'Mandatory Training': this.isMandatory,
      'Store Item Subscriptions': this.subscriptions,
      'Content Language': this.selectedLanguage ?? this.languageOptions[0].code,
    }
  }
}
