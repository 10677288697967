<script>
import uniqueId from 'lodash/uniqueId'

export default {
  props: {
    value: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    rtl: { type: Boolean },
    tooltip: { type: String, default: '' },
  },
  computed: {
    checkboxId() {
      return `custom-checkbox-${uniqueId()}`
    },
    checkboxLabelId() {
      return `custom-checkbox-label-${uniqueId()}`
    },
  },
  methods: {
    clicked() {
      if (this.disabled) return
      this.$emit('input', !this.value)
    },
  },
}
</script>

<template>
  <div class="custom-checkbox-container">
    <div
      :id="`${checkboxId}`"
      class="custom-checkbox mx-2"
      :class="{ 'brand-highlight': value }"
      role="checkbox"
      :title="tooltip"
      :aria-labelledby="checkboxLabelId"
      :aria-checked="value"
      :aria-disabled="disabled"
      tabindex="0"
      @click="clicked()"
      @keydown.space.enter.prevent="clicked()" />
    <label
      :id="checkboxLabelId"
      :for="`${checkboxId}`"
      class="custom-checkbox-label">
      <slot />
    </label>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/design/components/global/_custom-checkbox.scss';
</style>
