<template lang="pug">
  div(style="overflow: hidden;" qa-id="personal-risk-score")
    div.risk-container
      div.risk-item.min-value.text-muted 0
      div.risk-item.chart-container
        gauge-chart(:score="score")
      div.risk-item.max-value.text-muted 100
    div.more-info.p-4.text-center
      a.text-muted(href="https://support.knowbe4.com/hc/en-us/articles/360051072254" target="_blank" @click="trackEvent()")
        p {{ $t(description) }}
</template>

<script>
import GaugeChart from '@components/dashboard/charts/gauge-chart.vue'
import { myDashboard } from '@src/lib/analytics/events/my-dashboard'

export default {
  components: { GaugeChart },
  props: {
    score: { type: Number, default: 0 },
    description: {
      type: String,
      default: 'dashboard.vulnerability_score.description',
    },
  },
  methods: {
    trackEvent() {
      this.$analytics.trackEvent(myDashboard.personalRiskScoreLink)
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/design/components/dashboard/_risk-score.scss';
</style>
