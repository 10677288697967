<script>
import Vue2Filters from 'vue2-filters'
import { myDashboard } from '@src/lib/analytics/events/my-dashboard'

export default {
  mixins: [Vue2Filters.mixin],
  props: {
    available: { type: Number, default: 0 },
  },
  methods: {
    routeTo() {
      this.$analytics.trackEvent(myDashboard.learnHowToBadgesButton)
      this.$router.push({ name: 'knowledge_refreshers' })
    },
  },
}
</script>

<template>
  <div class="p-4 h-100 flex-grow-1" qa-id="krs-available">
    <div class="d-flex flex-column align-items-middle h-100">
      <div class="row h-100">
        <div class="col align-self-center">
          <div class="pb-2 mx-auto position-relative krs-container">
            <b-badge v-if="available" class="position-absolute badge-primary">
              {{ available }}
            </b-badge>
            <base-inline-svg
              :id="'krs'"
              :svg="require('@assets/images/training/kr-icon-empty.svg?inline')"
              :branded="true" />
          </div>
          <p v-if="available" class="text-center">
            {{
              $tc(
                'knowledge_refreshers.knowledge_refreshers_available',
                available,
                {
                  available: available,
                }
              )
            }}
          </p>
          <p v-else class="text-center">
            {{ $t('knowledge_refreshers.no_knowledge_refreshers_available') }}
          </p>
        </div>
      </div>
      <div class="mt-auto">
        <b-button
          block
          qa-id="navigate-to-krs"
          variant="primary"
          @click="routeTo()">
          {{ $t('knowledge_refreshers.krBtn') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/design/components/dashboard/_krs-available.scss';
</style>
