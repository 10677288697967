<template lang="pug">
  div(id="network-error")
    div(v-if="networkError")
      reload-button(message="A network error has occurred.")
</template>

<script>
import ReloadButton from '@components/global/reload-button'

export default {
  components: { ReloadButton },
  data() {
    return {
      networkError: false,
    }
  },
  mounted() {
    window.addEventListener('message', this.postMessage)
  },
  methods: {
    postMessage(event) {
      if (event.data === 'NETWORK_ERROR') {
        this.networkError = true
      }
    },
  },
}
</script>
