












import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import SessionStore from '@state/modules/session'
import { myDashboard } from '@src/lib/analytics/events/my-dashboard'

@Component({})
export default class DashboardNavigation extends Vue {
  dashboardRoutes: {
    name: string
    path: string
    active: boolean
    locale: string
    hasPermissions?: boolean
  }[] = []

  mounted() {
    if (this.myDashboardEnabled) {
      this.dashboardRoutes.push({
        name: 'dashboard',
        path: '/dashboard',
        active: this.$route.name === 'dashboard',
        locale: this.$tc('navigation.my_dashboard'),
      })
    }
    if (this.teamDashboardEnabled) {
      this.dashboardRoutes.push({
        name: 'team',
        path: '/dashboard/team',
        active: this.$route.name === 'team',
        locale: this.$tc('navigation.manager_dashboard'),
      })
    }
  }

  routeTo(path: string) {
    this.$analytics.trackEvent(myDashboard.navigationButton, {
      navigation: path,
    })
    this.$router.push({ path })
  }

  get teamDashboardEnabled(): boolean {
    return SessionStore.teamDashboardEnabled
  }

  get myDashboardEnabled(): boolean {
    return SessionStore.myDashboardEnabled
  }
}
