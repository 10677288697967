import { MessagesCampaignRecipientStatuses } from '@state/api/graphql'

export default class Message {
  id: number
  subject: string
  content: string // body
  status: MessagesCampaignRecipientStatuses
  date: Date

  constructor(data: any) {
    this.id = data.id
    this.subject = data.subject
    this.content = data.content
    this.status = data.status
    this.date = new Date(data.createdAt)
  }

  isRead() {
    return this.status === MessagesCampaignRecipientStatuses.Read
  }

  markAsRead() {
    this.status = MessagesCampaignRecipientStatuses.Read
  }

  get isUnread() {
    return this.status !== MessagesCampaignRecipientStatuses.Read
  }
}
