export default class RespondedRequest {
  readonly USER_SESSION_ERROR_CODES: number[] = [401, 403]
  readonly LEARNER_SESSION_ERROR_CODES: number[] = [409]

  statusCode: number
  error: string

  constructor(request: XMLHttpRequest) {
    this.statusCode = request.status
    this.error = JSON.parse(request.responseText).error
  }

  isSessionError() {
    return this.isUserSessionError() || this.isLearnerSessionError()
  }

  isUserSessionError() {
    return this.USER_SESSION_ERROR_CODES.includes(this.statusCode)
  }

  isLearnerSessionError() {
    return this.LEARNER_SESSION_ERROR_CODES.includes(this.statusCode)
  }
}
