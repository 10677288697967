























































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import DashboardTile from '@components/dashboard/dashboard-tile.vue'
import SessionStore from '@state/modules/session'
import { authComputed, createUserName } from '@/src/state/helpers'
import { managerDirectReports } from '@gql/client/team-dashboard'
import { DirectReport, DirectReportsCursor } from '@gql/graphql'
import { teamDashboard } from '@src/lib/analytics/events/team-dashboard'
// @ts-ignore
import { Manager } from '@types/team-dashboard'
import { LocaleMessages } from 'vue-i18n'
import { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'

@Component({ components: { DashboardTile }, computed: { ...authComputed } })
export default class TeamSelect extends Vue {
  @Prop({ required: false }) loading!: boolean
  emits!: ['loadManagerStatistics']
  createUserName: Function = Function
  private userName
  MAX_DIRECT_REPORTS_TO_DISPLAY: number = 100
  MAX_ALLOWED_LEVELS: number = 3

  currentUserId: number | undefined | null = null
  selectOptions: Manager[] = []
  selectedManager: Manager | null = null
  selectedManagerId: number | null = null
  alertManager: Manager | null = null

  async mounted() {
    if (SessionStore?.currentUser?.id) {
      const { id, jobTitle } = SessionStore.currentUser
      const directReports = this.cleanDirectReports(
        this.userName,
        await this.directReports(id),
        1
      )
      this.selectOptions = [
        {
          id,
          level: 0,
          label: this.userName,
          jobTitle,
          isDisabled: true,
          children: directReports,
        },
      ]
      const hasDirectReports = directReports?.filter(r => !r.isDisabled)
      if (!directReports || !hasDirectReports?.length) {
        this.currentUserId = null
      } else {
        this.currentUserId = id
      }
    } else {
      this.currentUserId = null
    }
  }

  async loadOptions({ action, parentNode, callback }) {
    let overrideProps: any = {}
    if (parentNode.level === this.MAX_ALLOWED_LEVELS) {
      overrideProps = { isDisabled: true, children: undefined }
    }
    if (action === LOAD_CHILDREN_OPTIONS) {
      const reports = this.cleanDirectReports(
        parentNode.label,
        await this.directReports(parentNode.id),
        parentNode.level + 1,
        overrideProps
      )
      parentNode.children = reports
      callback()
    }
  }

  async directReports(
    managerId?: number | null
  ): Promise<DirectReportsCursor['nodes']> {
    const {
      data: {
        data: {
          directReports: { nodes },
        },
      },
    } = await managerDirectReports({
      userId: managerId,
      per: this.MAX_DIRECT_REPORTS_TO_DISPLAY,
    })
    return nodes
  }

  async reset() {
    this.$analytics.trackEvent(teamDashboard.teamSelectResetButton)
    this.$emit('loadManagerStatistics', this.currentUserId)
    this.selectedManager = null
    this.selectedManagerId = null
    this.alertManager = null
  }

  async view() {
    this.$analytics.trackEvent(teamDashboard.teamSelectViewButton, {
      totalDirectReports: this.selectedManager?.children?.length,
    })
    this.$emit('loadManagerStatistics', this.selectedManager?.id)
    this.alertManager = this.selectedManager
  }

  cleanDirectReports(
    manager: Manager['label'],
    directReports: DirectReport[],
    level: number,
    overrideProps?: any
  ): Manager[] | null {
    if (!directReports) return []
    return directReports.map(report => {
      const { id, name, jobTitle, hasDirectReports, email } = report
      const children = hasDirectReports ? null : undefined
      const reportLabel = createUserName(email, name)
      return {
        id,
        level,
        label: reportLabel,
        jobTitle,
        manager,
        isDisabled: !hasDirectReports,
        ...(children === null && { children }),
        ...overrideProps,
      }
    })
  }

  setSelectedManager(val: Manager) {
    if (val?.id === this.selectedManager?.id) return
    this.selectedManager = val
  }

  viewButtonIsDisabled(): boolean {
    if (!this.selectedManagerId) return true
    if (this.selectedManagerId === this.alertManager?.id) return true
    if (this.loading) return true
    return false
  }

  getViewButtonCopy(): string | LocaleMessages {
    if (this.alertManager && this.selectedManagerId === this.alertManager?.id) {
      return this.$t(
        'manager_dashboard.team_select.view_button.title_disabled_loaded',
        { name: this.alertManager?.label }
      )
    }
    if (this.viewButtonIsDisabled()) {
      return this.$tc(
        'manager_dashboard.team_select.view_button.title_disabled'
      )
    }
    return this.$tc('manager_dashboard.team_select.view_button.title')
  }

  resetButtonIsDisabled(): boolean {
    if (this.loading || !this.alertManager) return true
    return false
  }

  getResetButtonCopy(): string {
    if (this.resetButtonIsDisabled()) {
      return this.$tc(
        'manager_dashboard.team_select.reset_button.title_disabled'
      )
    }
    return this.$tc('manager_dashboard.team_select.reset_button.title')
  }
}
