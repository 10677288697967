import { graphqlAxios } from '@state/api/client/graphqlAxiosClient'

/** GQL Queries **/
import BadgesQuery from '@gql/queries/badges.gql'

/** GQL Mutations **/
import BadgesAcknowledgeMutation from '@gql/mutations/badges_acknowledge.gql'
import BadgesEarnMutation from '@gql/mutations/badges_earn.gql'
import BadgesViewMutation from '@gql/mutations/badges_view.gql'

export function badges() {
  return graphqlAxios.post('/graphql', {
    query: BadgesQuery,
  })
}

export function earnBadges(enrollmentId) {
  return graphqlAxios.post('/graphql', {
    query: BadgesEarnMutation,
    variables: { enrollmentId: enrollmentId },
  })
}

export function markAcknowledgedBadgesAsViewed() {
  return graphqlAxios.post('/graphql', {
    query: BadgesViewMutation,
  })
}

export function markBadgeAsAcknowledged(badgeIds) {
  return graphqlAxios.post('/graphql', {
    query: BadgesAcknowledgeMutation,
    variables: { badgeIds: badgeIds },
  })
}
