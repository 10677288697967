











import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { LX } from '@src/lib/analytics/events/LearnerExperience'
import SessionStore from '@state/modules/session'

@Component
export default class HelpButton extends Vue {
  get customHelpEnabled() {
    return SessionStore.customHelpEnabled
  }

  get helpUrl() {
    return this.customHelpEnabled
      ? SessionStore.customHelpUrl
      : 'https://support.knowbe4.com/hc/en-us/sections/360000156628-End-User-Support'
  }

  helpButton() {
    this.$analytics.trackEvent(LX.useHelpButton)
  }
}
