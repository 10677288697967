<script>
export default {
  components: {},
  props: {
    gamificationEnabled: { type: Boolean, default: false },
  },
}
</script>

<template>
  <div class="complete-wrapper" qa-id="no-training-assigned">
    <div
      class="w-100 h-100 d-flex align-items-center justify-content-center position-absolute light-brand">
      <div class="text-center">
        <div class="training-complete mx-auto mb-3">
          <base-inline-svg
            :id="'no-training'"
            :svg="require('@assets/images/dashboard/no-training.svg?inline')"
            :branded="true" />
        </div>
        <p class="px-5 text-container">
          {{ $t('welcome.no_training') }}
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/design/components/dashboard/_no-training-assigned.scss';
</style>
