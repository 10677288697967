<script>
import { Chart } from 'highcharts-vue'
export default {
  components: { highcharts: Chart },
  props: { score: { type: Number, default: 0 } },
  computed: {
    getChartOptions() {
      return {
        chart: {
          type: 'gauge',
          height: 200,
          spacingTop: 0,
          spacingRight: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          plotBorderWidth: 0,
          margin: 0,
        },

        pane: {
          center: ['50%', '50%'],
          size: '100%',
          innerSize: '100%',
          height: 200,
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: 'rgba(0,0,0,0)',
            borderWidth: 0,
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc',
            height: 200,
          },
        },
        plotOptions: {
          gauge: {
            dataLabels: {
              borderWidth: 0,
              y: -22,
              style: {
                fontSize: '18px',
              },
            },
            dial: {
              backgroundColor: '#CCD2D8',
              baseLength: '100%',
              baseWidth: 4,
              radius: '105%',
              borderWidth: 1,
              rearLength: -30,
            },
            lineWidth: 20,
            pivot: {
              radius: 0,
            },
            wrap: false,
          },
        },

        // the value axis
        yAxis: {
          min: 0,
          max: 100,
          minorTickWidth: 0,
          minorTickLength: 0,
          tickWidth: 0,
          tickPosition: 'outside',
          tickLength: 0,
          labels: {
            step: 100,
            rotation: 'auto',
            enabled: false,
          },
          plotBands: [
            {
              from: 0,
              to: 20,
              color: '#6db281', // green
              thickness: 25,
            },
            {
              from: 20,
              to: 40,
              color: '#f0c32e', // yellow
              thickness: 25,
            },
            {
              from: 40,
              to: 60,
              color: '#e58920', // red
              thickness: 25,
            },
            {
              from: 60,
              to: 80,
              color: '#d06244', // red
              thickness: 25,
            },
            {
              from: 80,
              to: 100,
              color: '#a42904', // red
              thickness: 25,
            },
          ],
        },
        xAxis: {
          enabled: false,
        },
        series: [
          {
            name: 'Score',
            data: [this.score],
            tooltip: {
              enabled: false,
            },
          },
        ],
        tooltip: { enabled: false },
        title: {
          text: null,
        },
        credits: {
          enabled: false,
        },
      }
    },
  },
}
</script>

<template>
  <div qa-id="risk-score-graph">
    <highcharts
      :options="getChartOptions"
      role="img"
      alt="Risk Score"
      aria-label="Risk Score" />
  </div>
</template>
