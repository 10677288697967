import { graphqlAxios } from '@state/api/client/graphqlAxiosClient'
import { axios } from '@state/api/client/axiosClient'

/** GQL Queries **/
import DeviceLinkTokenQuery from '@gql/queries/device-link-token.gql'

/** GQL Mutations **/
import DeleteDeviceDetailsMutation from '@gql/mutations/delete_device_details.gql'
import UserProfileEditMutation from '@gql/mutations/user_profile_edit.gql'

export function updateUser(user) {
  return axios.post('/me', user)
}

export function updateUserProfile(attributes) {
  return graphqlAxios.post('/graphql', {
    query: UserProfileEditMutation,
    variables: { attributes: attributes },
  })
}

export function updatePassword(password, newPassword) {
  return axios.post('/me/password', {
    current_password: password,
    new_password: newPassword,
  })
}

export function disableMfa(password) {
  return axios.post('/me/mfa/disable', {
    current_password: password,
  })
}

export function enableMfa(otp) {
  return axios.post('/me/mfa/enable', {
    otp: otp,
  })
}

export function deleteDeviceDetails(deviceDetailsId) {
  return graphqlAxios.post('/graphql', {
    query: DeleteDeviceDetailsMutation,
    variables: { deviceId: deviceDetailsId },
  })
}

export function deviceLinkToken() {
  return graphqlAxios.post('/graphql', {
    query: DeviceLinkTokenQuery,
  })
}
