<script>
import Vue2Filters from 'vue2-filters'
import { myDashboard } from '@src/lib/analytics/events/my-dashboard'

export default {
  mixins: [Vue2Filters.mixin],
  props: {
    badgesEarned: { type: Number, default: 0 },
  },
  methods: {
    routeTo() {
      this.$analytics.trackEvent(myDashboard.learnHowToBadgesButton)
      this.$router.push({ name: 'badges' })
    },
  },
}
</script>

<template>
  <div class="p-4 h-100 flex-grow-1" qa-id="badges-complete">
    <div class="d-flex flex-column align-items-middle h-100">
      <div class="row h-100">
        <div class="col align-self-center">
          <div class="pb-2 mx-auto position-relative badge-container">
            <b-badge class="position-absolute badge-primary">
              {{ badgesEarned }}
            </b-badge>
            <base-inline-svg
              :id="'badges'"
              :svg="require('@assets/images/dashboard/badges.svg?inline')"
              :branded="true" />
          </div>
          <p v-if="badgesEarned" class="text-center">
            {{
              $tc('dashboard.badges.badges_earned', badgesEarned, {
                badge_amount: badgesEarned,
              })
            }}
          </p>
          <p v-else class="text-center">
            {{ $t('dashboard.badges.no_badges_earned') }}
          </p>
        </div>
      </div>
      <div class="mt-auto">
        <b-button block qa-id="navigate-to-badges" @click="routeTo()">
          {{ $t('dashboard.badges.button') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/design/components/dashboard/_badges-earned.scss';
</style>
