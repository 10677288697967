import { graphqlAxios } from '@state/api/client/graphqlAxiosClient'
import { axios } from '@state/api/client/axiosClient'

/** Types from the generated schema **/
import { StoreItemSource } from '@gql/graphql'

/** GQL Queries **/
import enrollmentsQuery from '@gql/queries/enrollments.gql'
import enrollmentQuery from '@gql/queries/enrollment.gql'
import electedEnrollmentsQuery from '@gql/queries/elected-enrollments.gql'
import optionalEnrollmentsQuery from '@gql/queries/optional-enrollments.gql'
import recommendationsQuery from '@gql/queries/recommendations.gql'
import uploadedContentQuery from '@gql/queries/uploaded-content.gql'
import knowledgeRefresherQuizQuery from '@gql/queries/knowledge-refresher-quiz.gql'
import KnowledgeRefresherQuizQuestionDetailsQuery from '@gql/queries/knowledge-refresher-quiz-question-details.gql'

/** GQL Mutations **/
import createEnrollmentsMutation from '@gql/mutations/create_enrollment.gql'
import CreateSurveyMutation from '@gql/mutations/survey_create.gql'
import startEnrollmentsMutation from '@gql/mutations/start_enrollment.gql'

export let enrollmentsRecentlyFetched = false
export const statisticsRecentlyFetched = false

export function enrollments() {
  enrollmentsRecentlyFetched = true
  window.setTimeout(() => {
    enrollmentsRecentlyFetched = false
  }, 60000)
  return graphqlAxios.post('/graphql', { query: enrollmentsQuery })
}

export function optionalEnrollments(page, per) {
  return graphqlAxios.post('/graphql', {
    query: optionalEnrollmentsQuery,
    variables: { page: page, per: per },
  })
}

export function knowledgeRefresherQuiz(enrollmentId) {
  return graphqlAxios.post('/graphql', {
    query: knowledgeRefresherQuizQuery,
    variables: { enrollmentId },
  })
}

export function knowledgeRefresherQuizQuestionDetails(
  enrollmentId,
  questionId
) {
  return graphqlAxios.post('/graphql', {
    query: KnowledgeRefresherQuizQuestionDetailsQuery,
    variables: { enrollmentId, questionId },
  })
}

export function recommendations(page, per, query) {
  return graphqlAxios.post('/graphql', {
    query: recommendationsQuery,
    variables: { page: page, per: per, query: query },
  })
}

export function electedEnrollments(page, per) {
  return graphqlAxios.post('/graphql', {
    query: electedEnrollmentsQuery,
    variables: { page: page, per: per },
  })
}

export function uploadedContent(page, per) {
  return graphqlAxios.post(`/graphql`, {
    query: uploadedContentQuery,
    variables: { page: page, per: per },
  })
}

/*
  This query is being used in the library detail view to fetch the enrollment if not available
  and will cause issues if we change the queries to contain less fields than what is needs
  for the "enrollments" api call above
*/
export function fetchEnrollment(enrollmentId) {
  return graphqlAxios.post('/graphql', {
    query: enrollmentQuery,
    variables: { id: enrollmentId },
  })
}

export function startEnrollment(enrollmentId, languageCode) {
  return graphqlAxios.post('/graphql', {
    query: startEnrollmentsMutation,
    variables: { id: enrollmentId, language: languageCode },
  })
}

export function createEnrollment(enrollmentUUID, languageCode, contentSource) {
  const variables = { id: enrollmentUUID, language: languageCode }

  if (contentSource === 'ccm') {
    Object.assign(variables, { storeItemSource: StoreItemSource.Ccm })
  }

  return graphqlAxios.post('/graphql', {
    query: createEnrollmentsMutation,
    variables,
  })
}

export function acceptPolicy(enrollmentId) {
  return axios.post('/accept_policy', {
    id: enrollmentId,
  })
}

export function acceptUrlPolicy(enrollmentId) {
  return axios.post('/accept_url_policy', { id: enrollmentId })
}

export function submitSurvey({
  enrollmentId,
  valueRating,
  lengthRating,
  styleRating,
  comments,
}) {
  return graphqlAxios.post('/graphql', {
    query: CreateSurveyMutation,
    variables: {
      enrollmentId: enrollmentId,
      attributes: {
        valueRating: valueRating,
        lengthRating: lengthRating,
        styleRating: styleRating,
        comments: comments,
      },
    },
  })
}
