<script>
import DashboardTileHeading from '@components/dashboard/dashboard-tile-heading.vue'

export default {
  components: { DashboardTileHeading },
  props: {
    columnSize: { type: Number, default: 12 },
    title: { type: String, default: '' },
    icon: { type: String, default: '' },
  },
}
</script>

<template lang="pug">
  b-col(:md="columnSize" sm="12")
    b-card.shadow.h-100.position-relative(:no-body="true")
      b-card-body.d-flex.flex-column.justify-content-between
        div(v-if="title && icon")
          dashboard-tile-heading(
            :title="title"
            :icon="icon"
          )
        slot.flex-fill.h-100
</template>

<style lang="scss" scoped>
@import 'src/design/components/dashboard/_dashboard-tile.scss';
</style>
