export default function loginRedirectPath() {
  // This will get the host from VUE_APP_API_HOST on dev,
  // or get the host name in prod since PT gets served from the same host
  const matchHost = /^(https?):\/\/([A-Z\d.-]{2,})(:\d{2,4})?/i
  return (
    (process.env.VUE_APP_API_HOST &&
      process.env.VUE_APP_API_HOST.match(matchHost)[0]) ||
    window.location.href.match(matchHost)[0] // We don't use window.location.origin because IE
  )
}
