<script>
import TopArrow from '@components/global/top-arrow.vue'
import TimelineCircle from './timeline-circle.vue'
export default {
  components: {
    TopArrow,
    TimelineCircle,
  },
  props: {
    index: { type: Number, default: 0 },
    enrollmentComplete: { type: Boolean, default: false },
  },
  computed: {
    showTopArrow() {
      return this.index === 0
    },
  },
}
</script>

<template>
  <div class="timeline d-none d-lg-block ml-3 mr-0 p-0">
    <top-arrow v-if="showTopArrow" />
    <timeline-circle :enrollment-complete="enrollmentComplete" class="circle" />
  </div>
</template>

<style lang="scss" scoped>
@import 'src/design/components/enrollments/_top-arrow.scss';
</style>
