




















































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { LX } from '@src/lib/analytics/events/LearnerExperience'

@Component<Vue>({
  page: {
    title: 'Badges',
    meta: [{ name: 'description', content: 'Your Badges' }],
  },
})
export default class Badges extends Vue {
  @Prop({ type: Array, required: true }) badges
  @Prop({ type: Array, required: true }) earnedBadges

  mounted() {
    this.$analytics.trackEvent(LX.viewBadges)
  }

  get noBadgesEarned(): boolean {
    return this.earnedBadges.length === 0
  }

  get allBadgesEarned(): boolean {
    return this.earnedBadges.length === this.badges.length
  }
}
