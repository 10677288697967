<template lang="pug">
  b-modal(
    id="language-selection-modal"
    v-model="showModal"
    lazy
    hide-footer
    :title="this.$t('enrollment.language_options.preferred_language')"
    size="xl"
    role="document"
    @hidden="hideModal")
    template(slot="modal-header")
      h5.m-0 {{ $t('enrollment.language_options.preferred_language')}}
      b-button.m-0.p-0.close(type="button" aria-label="Close" @click="hideModal()")
        fa.fa-xs(icon='times' size="small")
    language-selection-options-list(
      :display-locale="localeCode"
      :language-options="languageOptions"
      :selected-language="selectedLanguage"
      @languageSelected="setLanguageAndCloseModal")
</template>

<script>
import chunk from 'lodash/chunk'
import orderBy from 'lodash/orderBy'
import LanguageSelectionOptionsList from '@components/global/language-selection-options-list'
import { getLocale } from '@src/locale'

export default {
  components: { LanguageSelectionOptionsList },
  props: {
    languageOptions: { type: Array, required: true },
    selectedLanguage: { type: Object, required: true, default: () => ({}) },
    hasPreviousSelection: { type: Boolean, default: false },
  },
  data() {
    return {
      showModal: true,
    }
  },
  computed: {
    groupedAlphabeticalLanguages: function () {
      const sortedLanguages = orderBy(this.languageOptions, 'desc')
      const groupSize = Math.ceil(sortedLanguages.length / 2)
      return chunk(sortedLanguages, groupSize)
    },
    localeCode() {
      return getLocale().code
    },
  },
  methods: {
    setLanguageAndCloseModal(lang) {
      this.$emit('setLanguageAndCloseModal', lang)
    },
    hideModal() {
      this.$emit('hideModal')
    },
  },
}
</script>

<style lang="scss">
@import 'src/design/components/enrollments/_language-selection-modal.scss';
</style>
