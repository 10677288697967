import { graphqlAxios } from '@state/api/client/graphqlAxiosClient'

/** GQL Queries **/
import ManagerReportQuery from '@gql/queries/manager-report.gql'
import ManagerReportEnrollmentsQuery from '@gql/queries/manager-report-enrollments.gql'
import ManagerReportUsersQuery from '@gql/queries/manager-report-users.gql'
import ManagerDirectReportsQuery from '@gql/queries/direct-reports.gql'

export function managerStatistics(variables) {
  return graphqlAxios.post('/graphql', {
    query: ManagerReportQuery,
    variables: variables,
  })
}

export function managerUserStatistics(variables) {
  return graphqlAxios.post('/graphql', {
    query: ManagerReportUsersQuery,
    variables: variables,
  })
}

export function managerUserEnrollments(variables) {
  return graphqlAxios.post('/graphql', {
    query: ManagerReportEnrollmentsQuery,
    variables: variables,
  })
}

export function managerDirectReports(variables) {
  return graphqlAxios.post('/graphql', {
    query: ManagerDirectReportsQuery,
    variables: variables,
  })
}
