const enUs = require('./en-us.json')
const enDup = JSON.parse(JSON.stringify(enUs))

function parseObject(object) {
  Object.keys(object).forEach(key => {
    object[key] = parseValue(object[key])
  })
  return object
}

function parseValue(value) {
  if (typeof value === 'string') {
    return value
      .split('|')
      .map(s => {
        const fraction = s.length * 0.25
        const index = Math.floor(Math.random() * fraction)
        const padding = '@'.repeat(fraction).split('')
        padding.splice(index, 0, ' ')
        return `x${s}${padding.join('')}𠜎𨳒x`
      })
      .join('|')
  } else if (typeof value === 'object') {
    return parseObject(value)
  }
}

export default parseObject(enDup)
