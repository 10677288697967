
























































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import AcceptPolicy from '@components/enrollments/accept-policy.vue'
import EnrollmentActions from '@components/enrollments/enrollment-actions.vue'
import EnrollmentDetails from '@components/enrollments/enrollment-details.vue'
import formatDateRelative from '@utils/format-date-relative'
import secondsToTimeDistance from '@utils/seconds-to-time-distance'
import differenceInHours from 'date-fns/differenceInHours'
import toDate from 'date-fns/toDate'
import parseISO from 'date-fns/parseISO'
import truncate from '@mixins/truncate'

import Course from '@src/models/course'
import Policy from '@src/models/policy'

@Component({
  components: {
    EnrollmentActions,
    AcceptPolicy,
    EnrollmentDetails,
  },
  mixins: [truncate],
})
export default class EnrollmentTimelineCard extends Vue {
  @Prop() enrollment!: Course | Policy
  @Prop({ default: false }) firstEnrollment!: boolean
  @Prop() selectedLanguage

  private truncate

  get showScore() {
    // @gregk: that score should be hidden for now
    // plan to be able to show it in the future at some point
    // but right now that value isn't reliable/accurate/valuable
    return false
  }

  get coursesAsString() {
    return (
      (this.enrollment?.campaigns && this.enrollment?.campaigns.join(', ')) ||
      ''
    )
  }

  get defaultImage() {
    return this.enrollment?.defaultImage
  }

  get enrollmentTitleTruncated() {
    return this.truncate(
      this.selectedLanguage.title || this.enrollment?.title,
      100
    )
  }

  get enrollmentDescriptionTruncated() {
    return this.enrollment?.isPolicy
      ? null
      : this.truncate(
          this.selectedLanguage.description || this.enrollment?.description,
          200
        )
  }

  get outcomeAsPercentage() {
    return `${this.score}%`
  }

  get score() {
    // We don't currently support displaying scores in LX
    // Scores are available from KMSAT - displaying them can be implemented in the future if desired
    return 0
  }

  get progressBarStyle() {
    return {
      width: `${this.score}%`,
    }
  }

  get enrollmentDurationCopy(): any {
    return this.$t('enrollment.time.duration', {
      duration_time: this.enrollmentDuration,
      enrollment_type: this.$t(
        `enrollment.types.${this.enrollment.enrollmentType.toLowerCase()}_lowercase`
      ),
    })
  }

  get enrollmentDuration(): any {
    return this.enrollment?.duration
      ? secondsToTimeDistance(this.enrollment?.duration * 60)
      : null // KMSAT Course durations are in minutes!
  }

  get enrollmentReviewTime(): any {
    return this.$t('enrollment.time.required_review_time', {
      time: this.minimumPolicyReviewTime,
      required: this.$options?.filters?.pluralize(
        this.minimumPolicyReviewTime,
        [this.$t('common.minute'), this.$t('common.minute_plural')]
      ),
    })
  }

  get enrollmentReviewTimeSpent(): any {
    return this.$t('enrollment.time.time_spent', {
      time: this.enrollmentTimeSpent,
      enrollment_type: this.$t(
        `enrollment.types.${this.enrollment.enrollmentType.toLowerCase()}_lowercase`
      ),
    })
  }

  get enrollmentRequiredReviewTimeSpent(): any {
    return this.$t('enrollment.time.required_time_spent', {
      time: this.enrollmentTimeSpent,
      required_time: this.minimumPolicyReviewTime,
      time_units: this.$options?.filters?.pluralize(
        this.minimumPolicyReviewTime,
        [this.$t('common.minute'), this.$t('common.minute_plural')]
      ),
      enrollment_type: this.$t(
        `enrollment.types.${this.enrollment.enrollmentType.toLowerCase()}_lowercase`
      ),
    })
  }

  get enrollmentTimeLeft() {
    const sentences = {
      overdue: this.$t('enrollment.time.overdue'),
      time_left: this.$t('enrollment.time.time_left'),
    }

    return formatDateRelative(
      new Date(),
      this.enrollment?.expiresAt || new Date(),
      sentences
    )
  }

  get enrollmentTimeSpent(): any {
    return this.enrollment?.timeSpentInSeconds
      ? secondsToTimeDistance(this.enrollment?.timeSpentInSeconds)
      : null // KMSAT Course time spent is in seconds
  }

  get enrollmentExpiresSoon() {
    const timeNow = toDate(new Date())
    const timeExpires = parseISO(
      this.enrollment?.expiresAt ?? new Date().toString()
    )

    return differenceInHours(timeExpires, timeNow) < 25
  }

  get minimumPolicyReviewTime() {
    return this.enrollment?.duration
  }

  get policyHasReviewTime() {
    return this.enrollment?.duration && this.enrollment?.isPolicy
  }

  changeLanguage(lang) {
    this.$emit('changeLanguage', lang)
  }

  startCourse() {
    this.$emit('startCourse')
  }

  startSurvey() {
    this.$emit('startSurvey')
  }

  /* @Watch('enrollment', { immediate: true })
  handler(val) {
    if (val.languageOptions && val.languageOptions.length) {
      this.selectedLanguage = val.languageOptions[0]
    }
  } */
}
