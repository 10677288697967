






























import Vue from 'vue'
import EnrollmentStore from '@state/modules/enrollments'
import formatDate from '@utils/format-date'
import { Component, Prop, Inject } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import OptionalEnrollment from '@/src/models/optional-enrollment'

@Component({})
export default class KrBadgesBanner extends Vue {
  @Prop() readonly refresher!: OptionalEnrollment
  @Prop() completed!: boolean
  emits!: ['openKr']
  @Inject('isRTL') rtl: boolean | undefined

  get refresherTitle(): string {
    const topics = this.refresher.storeItem?.topics || []
    return topics.map(t => this.$t(t.translationKey)).join(', ')
  }

  get refresherSubtitle() {
    return [this.knowledgeRefresherCourseTitle, this.formattedCompletedAtDate]
      .filter(Boolean)
      .join(' • ')
  }

  get formattedCompletedAtDate(): string {
    const original = this.storeItemCompletedAtDate
    return original ? formatDate(original) : ''
  }

  get knowledgeRefresherCourseTitle(): string {
    return (
      this.refresher.storeItem.translations?.find(
        t => t.language.code === this.refresher.lastUsedLanguage
      )?.title || this.refresher.storeItem.title
    )
  }

  get storeItemCompletedAtDate(): string {
    const { uuid } = this.refresher.storeItem
    if (!uuid) return ''

    const findEnrollmentMandatory = EnrollmentStore.enrollments.find(
      e => e.storeItem.uuid === uuid
    )
    if (findEnrollmentMandatory) {
      return findEnrollmentMandatory.completedAt || ''
    }

    const findEnrollmentOptional = EnrollmentStore.allEnrollments.find(
      e => e.storeItem.uuid === uuid
    )
    if (findEnrollmentOptional) {
      return findEnrollmentOptional.completedAt || ''
    }

    return ''
  }

  get tooltipTitle(): TranslateResult {
    if (!this.refresher.completedAt) {
      return this.$t('knowledge_refreshers.completedTooltip')
    }

    return `${this.$t('knowledge_refreshers.completedTooltipOn', {
      date: formatDate(this.refresher.completedAt),
    })}`
  }

  launchKr() {
    if (this.completed) return

    this.$emit('openKr', {
      ...this.refresher,
      title: this.refresherTitle,
      storeItemCompletedAtDate: this.storeItemCompletedAtDate,
    })
  }
}
