



































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import SessionStore from '@state/modules/session'

@Component
export default class Footer extends Vue {
  get currentYear() {
    return new Date().getFullYear()
  }

  get customHelpEnabled() {
    return SessionStore.customHelpEnabled
  }

  get helpUrl() {
    return this.customHelpEnabled
      ? SessionStore.customHelpUrl
      : 'https://support.knowbe4.com'
  }
}
