import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { messages, AvailableLanguages } from '@src/available_languages'
import { getLocale } from '@src/locale'
import enDev from '@src/locales/en-dev'

Vue.use(VueI18n)

messages['en-dev'] = enDev

export function setDocumentDirectionPerLocale(locale) {
  const hasRtlLanguage = AvailableLanguages.filter(x => x.rtl).find(
    x => x.code === locale
  )
  document.dir = hasRtlLanguage ? 'rtl' : 'ltr'
}

export function setDocumentLang(lang) {
  document.documentElement.lang = lang
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || getLocale().code,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-us',
  messages: messages,
})
