const prefix = 'Team Dashboard:'
export const teamDashboard = {
  betweenFiftyAndAHundredUsersWarning: `${prefix} has between between 50 & 100 users warning.`,
  moreThanAHundredUsersWarning: `${prefix} has more than 100 users warning.`,
  teamSelectViewButton: `${prefix} Team Select | Clicked the view button.`,
  teamSelectResetButton: `${prefix} Team Select | Clicked the reset button.`,
  toggleShowAllButton: `${prefix} Team Table | Toggled the 'View/Hide All' button.`,
  selectFilter: `${prefix} Team Table | Clicked on a filter in the table.`,
  expandedTeamMemberRow: `${prefix} Team Table | Toggled a team member's details in the table.`,
}
