












import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import PieChart from '@components/dashboard/charts/pie-chart.vue'

@Component({
  components: {
    PieChart,
  },
})
export default class Ppp extends Vue {
  @Prop({ required: true }) reported!: number
  @Prop({ required: true }) failed!: number
  @Prop({ required: true }) notReported!: number
  @Prop({ required: true }) ppp!: number

  get total() {
    return this.failed + this.notReported + this.reported
  }

  get noPhishing() {
    return this.total === 0
  }

  get percent() {
    return ((this.failed / this.total) * 100).toFixed()
  }

  get chartData() {
    return [
      {
        name: this.$t('manager_dashboard.reported', {
          reported: this.reported,
        }),
        y: this.reported,
      },
      {
        name: this.$t('manager_dashboard.failed', {
          failed: this.failed,
        }),
        y: this.failed,
      },
      {
        name: this.$t('manager_dashboard.not_reported', {
          notReported: this.notReported,
        }),
        y: this.notReported,
      },
    ]
  }
}
