import axios from 'axios'
import format from 'date-fns/format'
import loginRedirectPath from '@utils/login-redirect-path'
import { print } from 'graphql/language/printer'

const graphqlAxios = axios.create()

// Allow base URL to be configured at build time (only VUE_APP_ vars are inlined)
if (process.env.VUE_APP_API_HOST) {
  graphqlAxios.defaults.baseURL = `${process.env.VUE_APP_API_HOST}/`
} else if (process.env.NODE_ENV === 'production') {
  graphqlAxios.defaults.baseURL = '/'
}

// If we get a 401 or 403 response from the API server, redirect to KMSAT login
function handleReject(error) {
  if (error.response && [401, 403].includes(error.response.status)) {
    return (window.location = loginRedirectPath())
  }
  if (error.message === 'Network Error') {
    return window.postMessage('NETWORK_ERROR', '*')
  }
  return Promise.reject(error)
}

graphqlAxios.defaults.withCredentials = true

graphqlAxios.interceptors.request.use(config => {
  config.headers.CurrentTime = format(
    new Date(),
    "yyyy-MM-dd'T'HH:mm:ss.sss'Z'"
  ) // ISO8601 date
  config.headers['X-Requested-With'] = 'XMLHttpRequest'
  config.headers['X-CSRF-TOKEN'] = window.authenticity_token
  return config
})

graphqlAxios.interceptors.response.use(response => response, handleReject)
graphqlAxios.interceptors.request.use(request => {
  request.data.query = print(request.data.query)
  return request
})

export { graphqlAxios }
